import React, { useEffect, useState, useRef, Fragment } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Form,
  InputGroup,
  InputGroupAddon,
  Spinner,
  UncontrolledTooltip,
  Container,
  Alert,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import Logo from "../../../assets/img/SAPC-Logo-red.png";
import { authActions } from "../../../services/AuthService";
import {
  BrowserView,
  MobileView,
  isDesktop,
  isMobile,
  isTablet,
  isIOS,
} from "react-device-detect";
import { useNavigate } from "react-router-dom";
import LoginModal from "./LoginModal";

const Login = (props) => {
  const {
    control,
    handleSubmit,
    register,
    errors,
    reset,
    getValues,
    setValue,
  } = useForm();
  const navigate = useNavigate();
  const [authenticated, setAuthenticated] = useState(false);
  const [buttonSpinner, setButtonSpinner] = useState(false);

  const [isActive, setIsActive] = useState(false);
  const activeButton = () => setIsActive(!isActive);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [modalReset, setModalReset] = useState(false);
  const toggleReset = () => setModalReset(!modalReset);

  const [check, setCheck] = useState();

  const [disabler, setDisabler] = useState(false);

  const onSubmit = (data) => {
    setButtonSpinner(true);
    authActions.login(data, setAuthenticated, setButtonSpinner, navigate);
  };

  useEffect(() => {
    if (modalReset) {
      setModal(!modal);
      setIsActive(true);
      setCheck(true);
      setDisabler(true);
    }
  }, [modalReset]);

  useEffect(() => {
    document.title = "LOGIN | San Antonio de Padua College";
  }, []);

  return (
    <Row
      className={isIOS ? "LoginPageSafari px-0 mx-0" : "LoginPage px-0 mx-0"}
    >
      {/* Login Dashboard */}
      <Col md={isTablet ? "12" : "4"} xs="12">
        <Col
          md="12"
          xs="12"
          className={
            isDesktop
              ? "mx-4"
              : isTablet
              ? "flex-column justify-content-center align-items-center"
              : ""
          }
        >
          <Button
            onClick={() => navigate("/")}
            className={
              isDesktop
                ? "bg-danger-edit text-white font-weight-bolder rounded-pill button-transition back-button border-0"
                : isTablet
                ? "bg-danger-edit text-white font-weight-bolder rounded-pill border-0 remember-forgot py-3 mt-5 w-100 button-transition back-button-mobile"
                : "bg-danger-edit text-white font-weight-bolder rounded-pill border-0 remember-forgot py-3 button-transition back-button-mobile"
            }
          >
            Back to Homepage
          </Button>
        </Col>

        <Row
          className={
            isDesktop
              ? "h-100 flex-column justify-content-center w-100 get-to-center-login"
              : "h-100 flex-column align-items-center text-center justify-content-center mx-3"
          }
        >
          <Form onSubmit={handleSubmit(onSubmit)}>
            <h2 className="text-danger-edit mb-5 mt-5">Login to Dashboard</h2>
            <Row>
              <Col xs="12" md="9" className="bg-light mb-4">
                <Label
                  htmlFor="email_address"
                  className="text-secondary font-weight-bolder "
                >
                  Email
                </Label>
                <FormGroup>
                  <Controller
                    as={
                      <Input className="form-control bg-light signIn rounded-0" />
                    }
                    onChange={([event]) => {
                      return event;
                    }}
                    value=""
                    defaultValue=""
                    type="email"
                    id="email_address"
                    name="email_address"
                    control={control}
                    rules={{ required: true }}
                    invalid={errors.email_address ? true : false}
                  />
                  {errors.email_address && (
                    <div
                      style={{
                        marginTop: "0.25rem",
                        fontSize: "80%",
                        color: "#f86c6b",
                      }}
                    >
                      Email is required!
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col xs="12" md="9" className="bg-light">
                <Label
                  htmlFor="password"
                  className="text-secondary font-weight-bolder"
                >
                  Password
                </Label>
                <FormGroup>
                  <Controller
                    as={
                      <Input className="form-control signIn bg-light rounded-0" />
                    }
                    onChange={([event]) => {
                      return event;
                    }}
                    value=""
                    defaultValue=""
                    type="password"
                    id="password"
                    name="password"
                    control={control}
                    rules={{ required: true }}
                    invalid={errors.password ? true : false}
                  />
                  {errors.password && (
                    <div
                      style={{
                        marginTop: "0.25rem",
                        fontSize: "80%",
                        color: "#f86c6b",
                      }}
                    >
                      Password is required!
                    </div>
                  )}
                </FormGroup>
              </Col>
            </Row>

            <Col className="mt-4">
              <p
                className="text-warning"
                onClick={() => navigate("/forgot-password")}
                style={{ cursor: "pointer" }}
              >
                Forgot Password?
              </p>
            </Col>
            <Col>
              {isDesktop ? (
                <FormGroup
                  check
                  className="mt-4 mr-4 align-items-center justify-content"
                >
                  <Label check>
                    <Input
                      type="checkbox"
                      name="remember"
                      onChange={activeButton}
                      checked={check}
                      disabled={disabler}
                    />{" "}
                    By checking this box, you agree to our
                  </Label>
                  <br />
                  <a className="hand-pointer text-primary" onClick={toggle}>
                    Data Privacy Consent.
                  </a>
                </FormGroup>
              ) : (
                <FormGroup check className="">
                  <Label check>
                    <Input
                      type="checkbox"
                      name="remember"
                      onChange={activeButton}
                      checked={check}
                      disabled={disabler}
                    />{" "}
                    By checking this box, you agree to our
                  </Label>
                  <br />
                  <a className="hand-pointer text-primary" onClick={toggle}>
                    Data Privacy Consent.
                  </a>
                </FormGroup>
              )}
            </Col>

            <Modal
              isOpen={modal}
              toggle={toggle}
              className={
                isIOS
                  ? isMobile
                    ? "modal-center"
                    : "landingPage modal-center"
                  : "modal-terms-desktop-position landingPage"
              }
              contentClassName={isDesktop ? "modal-terms-desktop" : ""}
              backdrop="static"
            >
              <ModalBody className={isMobile ? "modal-terms" : ""}>
                <div
                  class={isDesktop ? "mt-5 mb-5 mx-5" : "text-center mt-5 mb-5"}
                >
                  <p className="text-justify mb-5">
                    <span className="text-danger-edit font-weight-bolder">
                      DATA PRIVACY CONSENT
                    </span>
                    <br />
                    <br />
                    This consent enables the Office of Student Welfare Services
                    (OSWS) to comply with R.A. 10173, otherwise known as the
                    Data Privacy Act of 2012.
                    <br />
                    <br />
                    I consent to the processing of my personal and sensitive
                    personal information contained in this form and in documents
                    submitted for my scholarship application for the purpose of
                    enabling the school system including all the relevant System
                    and Constituent College Offices to verify my identity,
                    prevent fraud, process my application, determine whether I
                    am qualified to avail of any scholarship or other similar
                    financial or other assistance, conduct research using non
                    identifiable information in order to study the effectiveness
                    of the College scholarships and other financial assistance
                    programs and assess how to improve the systems for the
                    selection and execution of scholarship programs.
                    <br />
                    <br />
                    I further expressly agree that the concerned System and/or
                    College offices may directly obtain all my relevant student
                    records whether in electronic or paper based format in order
                    to verify the information contained in my application for
                    the purpose of determining my eligibility for the
                    scholarship or other financial assistance from the relevant
                    SAPC Offices like Registrar, disciplinary board or tribunal
                    and other College offices.
                    <br />
                    <br />
                    I expressly authorize the school to provide information
                    required by the scholarship funders or sponsors for the
                    purpose of enabling the latter to determine whether or not
                    to continue to provide financial and other assistance with
                    the assurance that the College will require such parties to
                    observe strict compliance with the Philippine Data Privacy
                    Act and other related laws and issuances when they process
                    my personal and sensitive personal information.
                    <br />
                    <br />
                    I understand that San Antonio de Padua College including
                    others offices are authorized to process my personal and
                    sensitive personal information without need of my consent
                    pursuant to the relevant portions of Sections 4, 12 and 13
                    of the Philippine Data Privacy Act.
                    <br />
                    <br />I certify that the information given is{" "}
                    <strong>true and correct</strong>.
                  </p>
                  <Row className="justify-content-center align-items-center">
                    <Button
                      className="bg-warning-edit text-white font-weight-bolder border-0 w-25 button-transition"
                      onClick={toggleReset}
                    >
                      I AGREE
                    </Button>
                  </Row>
                </div>
              </ModalBody>
            </Modal>

            <Row className="align-items-center justify-content-between w-100">
              <Col md="12" xs="12" className="pb-5 mt-3">
                {isActive ? (
                  <Button
                    type="submit"
                    id="save-card-type"
                    disabled={buttonSpinner}
                    className={
                      isDesktop
                        ? "bg-warning-edit text-white font-weight-bolder rounded-pill border-0 py-3 px-5 mt-4 button-transition"
                        : "bg-warning-edit text-white font-weight-bolder rounded-pill border-0 remember-forgot py-3 px-5 mt-4 mb-5 button-transition"
                    }
                  >
                    {buttonSpinner ? (
                      <Fragment>
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        &nbsp;Processing...
                      </Fragment>
                    ) : (
                      "Sign In"
                    )}
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    id="save-card-type"
                    disabled={true}
                    className={
                      isDesktop
                        ? "bg-warning-edit text-white font-weight-bolder rounded-pill border-0 py-3 px-5 mt-4 button-transition"
                        : "bg-warning-edit text-white font-weight-bolder rounded-pill border-0 remember-forgot py-3 px-5 mt-4 mb-5 button-transition"
                    }
                  >
                    Sign In
                  </Button>
                )}
              </Col>
            </Row>
          </Form>
        </Row>
      </Col>

      {/* SignUp */}
      <Col md={isTablet ? "12" : "8"} xs="12" className="login-image">
        <Row className="h-100 flex-column justify-content-center align-items-center text-center">
          <img
            src={Logo}
            style={{ height: "5rem", width: "5rem", marginBottom: "4rem" }}
          />
          <h1 className="text-white w-50 mb-5 pb-5">Are you a new student?</h1>
          <Button
            onClick={() => navigate("/sign-up")}
            className={
              isDesktop
                ? "bg-white text-danger-edit font-weight-bolder rounded-pill border-0 py-3 px-5 w-25 button-transition"
                : "bg-white text-danger-edit font-weight-bolder rounded-pill border-0 py-3 px-5 w-50 button-transition"
            }
          >
            Sign Up
          </Button>
        </Row>
      </Col>
    </Row>
  );
};

export default Login;
