import { ENDPOINT } from "../constants/api";
import axios from "axios";
import { notifyError, notifySuccess } from "./Toaster";
import moment from "moment";

export const subjectActions = {
  getSubjects,
  addSubject,
  updateSubject
};

const token = localStorage.getItem("jwtToken")
  ? localStorage.getItem("jwtToken")
  : "";
const headers = {
  Authorization: `Bearer ${token}`,
  "Content-Type": "application/json",
  "X-Requested-With": "XMLHttpRequest",
};

async function getSubjects(setData, setLoading) {
  try {
    let res = await axios.get(ENDPOINT + `/get-all-subject`, {
      headers: headers,
    });
    
    setData(res.data.data.subjects);
    setLoading(false);
  } catch (err) {
    if (err.response) {
      notifyError("Error while fetching Data");
    }
  }
}

async function addSubject(dataToSubmit, setButtonSpinner, setUpdateTable, updateTable, setAddOrEditModal) {
  try {
    let res = await axios.post(ENDPOINT + `/add-subject`, dataToSubmit, {
      headers: headers,
    });
    
    if(res.data.success){
        notifySuccess(res.data.message);
        setUpdateTable(!updateTable);
        setAddOrEditModal(false);
    }else{
      notifyError("Error while adding subject.");
    }
    setButtonSpinner(false)
  } catch (err) {
    if (err.response) {
      notifyError("Error while adding subject.");
    }
    setButtonSpinner(false)
  }
}

async function updateSubject(dataToSubmit, setButtonSpinner, setUpdateTable, updateTable, setAddOrEditModal) {
    try {
      let res = await axios.post(ENDPOINT + `/update-subject`, dataToSubmit, {
        headers: headers,
      });
      
      if(res.data.success){
          notifySuccess(res.data.message);
          setUpdateTable(!updateTable);
          setAddOrEditModal(false);
      }else{
        notifyError("Error while updating user.");
      }
      setButtonSpinner(false)
    } catch (err) {
      if (err.response) {
        notifyError("Error while updating user.");
      }
      setButtonSpinner(false)
    }
  }