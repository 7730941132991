import { ENDPOINT } from "../constants/api";
import axios from "axios";
import { notifyError, notifySuccess } from "./Toaster";
import moment from "moment";

export const studentActions = {
  getEnrolledStudents,
  getStudent,
  downloadCor,
  downloadAof,
  downloadStudentDetails
};

const token = localStorage.getItem("jwtToken")
  ? localStorage.getItem("jwtToken")
  : "";
const headers = {
  Authorization: `Bearer ${token}`,
  "Content-Type": "application/json",
  "X-Requested-With": "XMLHttpRequest",
};

async function getEnrolledStudents(
    setData,
    setLoading,
    setTotalSize,
    tab,
    setUpdateTable
  ) {
    try {
      let filter = `?sortField=id&sortOrder=asc&sizePerPage=25`;
      let res = await axios.get(ENDPOINT + `/get-application/${tab}` + filter, {
        headers: headers,
      });
      let displayData = [];
  
      if (res.data && res.data.data && res.data.data.length) {
        let data = res.data.data;
        if (data && data.length >= 1) {
          displayData = data.map((d) => {
            return {
              id: d.id,
              ref_no: d.ref_no,
              last_name: d.last_name,
              first_name: d.given_name,
              created_at: d.date_filed,
              grade_level: d.grade_level,
              status: d.status,
              remarks: d.remarks,
              student_id: d.student_id ? d.student_id : "",
            };
          });
        }
      }
      setLoading(false);
      setData(displayData);
      setTotalSize(res.data.total);
    } catch (err) {
      if (err.response) {
        notifyError("Invalid Data.");
        // if (err.response.Error) {
        //     notifyError(err.response.Error);
        // }
        // if (err.response.data && err.response.data.data.email_address) {
        //     notifyError(err.response.data.data.email_address);
        // }
      } else {
        notifyError("Network Error.");
      }
    }
  }

  async function getStudent(dataToSubmit, setViewDetails, viewDetails, setDetails)
  {
    try{
      let res = await axios.post(ENDPOINT + `/student-info`, dataToSubmit, {
        headers: headers,
      });
      let info = res.data.data.student_info[0];
      let studentInfo = info.student_info;
      let studentSchedule = info.student_schedule.length >= 1 ? info.student_schedule[0].bucket_link : null;
      let studentSection = info.student_info.section_id ? info.student_info.section_id.name : null;
      let newInfo = {
        id: studentInfo.id,
        last_name: studentInfo && studentInfo.student_detail_id ? studentInfo.student_detail_id.last_name : "-",
        given_name: studentInfo && studentInfo.student_detail_id ? studentInfo.student_detail_id.given_name : "-",
        middle_name: studentInfo && studentInfo.student_detail_id ? studentInfo.student_detail_id.middle_name : "-",
        religion: studentInfo && studentInfo.student_detail_id ? studentInfo.student_detail_id.religion : "-",
        lrn: studentInfo && studentInfo.student_detail_id ? studentInfo.student_detail_id.lrn : "-",
        suffix: studentInfo && studentInfo.student_detail_id ? studentInfo.student_detail_id.suffix : "-",
        birth_date: studentInfo && studentInfo.student_detail_id ? studentInfo.student_detail_id.birth_date : "-",
        birth_place: studentInfo && studentInfo.student_detail_id ? studentInfo.student_detail_id.birth_place : "-",
        house_bldg_street: studentInfo && studentInfo.student_detail_id ? studentInfo.student_detail_id.house_bldg_street : "-",
        city_municipality: studentInfo && studentInfo.student_detail_id ? studentInfo.student_detail_id.city_municipality : "-",
        barangay: studentInfo && studentInfo.student_detail_id ? studentInfo.student_detail_id.barangay : "-",
        province: studentInfo && studentInfo.student_detail_id ? studentInfo.student_detail_id.province : "-",
        ref_no: studentInfo.ref_no,
        student_id: studentInfo && studentInfo.student_detail_id ? studentInfo.student_detail_id.student_id : "-",
        scheduleImage: studentSchedule,
        grade_year_level: studentInfo.health_additional_info && studentInfo.health_additional_info.admissionAnswers && studentInfo.health_additional_info.admissionAnswers.grade_year_level ? studentInfo.health_additional_info.admissionAnswers.grade_year_level.label : "-",
        course: studentInfo.health_additional_info && studentInfo.health_additional_info.admissionAnswers && studentInfo.health_additional_info.admissionAnswers.tertiary_course ? studentInfo.health_additional_info.admissionAnswers.tertiary_course.label : "-",
        semester: studentInfo.health_additional_info && studentInfo.health_additional_info.admissionAnswers && studentInfo.health_additional_info.admissionAnswers.semester ? studentInfo.health_additional_info.admissionAnswers.semester.label : "-",
        student_section: studentSection,
        grade_type_id: studentInfo.grade_level_id && studentInfo.grade_level_id.grade_type_id ? studentInfo.grade_level_id.grade_type_id.id : "",
        student_grades: studentInfo.approved_grade
      }

      setDetails(newInfo)
      setViewDetails(true)
    }catch(err){
      if(err.response){
        notifyError("Error while fetching student details");
      }
    }
  }

async function downloadCor(dataToSubmit, setButtonSpinner)
{
  try{
    let res = await axios.post(ENDPOINT + `/download-cor`, dataToSubmit, {
      headers: headers,
    });

    setButtonSpinner(false)
    const exportUrl = res.data;
    let win = window.open(exportUrl, "_blank");
    win.focus();
  }catch(err){
    if(err.response){
      notifyError("Error while downloading COR")
    }
    setButtonSpinner(false)
  }
}

async function downloadAof(dataToSubmit, setButtonSpinner)
{
  try{
    let res = await axios.post(ENDPOINT + `/download-aof`, dataToSubmit, {
      headers: headers,
    });

    setButtonSpinner(false)
    const exportUrl = res.data;
    let win = window.open(exportUrl, "_blank");
    win.focus();
  }catch(err){
    if(err.response){
      notifyError("Error while downloading AOF")
    }
    setButtonSpinner(false)
  }
}

async function downloadStudentDetails(dataToSubmit, setButtonSpinner)
{
  try{
    let res = await axios.post(ENDPOINT + `/download-student-details`, dataToSubmit, {
      headers: headers,
    });

    setButtonSpinner(false)
    const exportUrl = res.data;
    let win = window.open(exportUrl, "_blank");
    win.focus();
  }catch(err){
    if(err.response){
      notifyError("Error while downloading Student Details")
    }
    setButtonSpinner(false)
  }
}