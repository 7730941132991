export default {
   tertiary_courses: [
      { value: 1, label: "AB in Psychology" },
      { value: 2, label: "BS in Business Administration" },
      { value: 3, label: "BS in Hospitality Management" },
      { value: 4, label: "Associate in Office Management" },
   ],
   semester: [
      { value: 0, label: "First" },
      { value: 1, label: "Second" },
   ],
   is_esc: [
      { value: 0, label: "No" },
      { value: 1, label: "Yes" },
   ],
   esc_type: [
      { value: 0, label: "Public" },
      { value: 1, label: "Private" },
      { value: 2, label: "Private Non-ESC" },
   ],
   esc_qvr: [
      { value: 0, label: "ESC" },
      { value: 1, label: "QVR" },
   ],
   student_status: [
      { value: 0, label: 'Regular' },
      { value: 1, label: 'Irregular' },
  ],
    gender: [
        { value: "male", label: 'Male' },
        { value: "female", label: 'Female' },
    ],
    civil_status: [
      { value: 1, label: 'Single' },
      { value: 2, label: 'Married' },
  ],
    living_status: [
        { value: 0, label: 'Living with both parents' },
        { value: 1, label: 'Living with 1 parent' },
        { value: 2, label: 'Living with guardians' },
    ],
    father_status: [
        { value: 1, label: 'Living' },
        { value: 2, label: 'Deceased' },
    ],
    highest_educational_attainment: [
        { value: 0, label: 'Primary' },
        { value: 1, label: 'Secondary' },
        { value: 2, label: 'Tertiary' },
        { value: 3, label: 'Masters Degree' },
        { value: 4, label: 'Doctorate Degree' },
    ],
    occupation: [
        { value: 0, label: 'Locally Employed' },
        { value: 1, label: 'OFW' },
        { value: 2, label: 'Self-Employed' },
        { value: 3, label: 'Unemployed' },
    ],
    monthly_income: [
        { value: 0, label: '0 - 10,000' },
        { value: 1, label: '11,000 - 20,000' },
        { value: 2, label: '21,000 - 50,000' },
        { value: 3, label: '51,000 and Up' },
    ],
    boolean: [
        { value: 0, label: 'No' },
        { value: 1, label: 'Yes' },
    ],
    health_history: [
        { value: 1, label: "Allergy"},
        { value: 2, label: "Skin infections"},
        { value: 3, label: "Joint pain / rheumatism"},
        { value: 4, label: "Sex organ"},
        { value: 5, label: "Difficulty in urination"},
        { value: 6, label: "Frequent abdominal pains"},
        { value: 7, label: "Frequent sore throat"},
        { value: 8, label: "Hearing / Ears"},
        { value: 9, label: "Grades / failures in exams"},
        { value: 10, label: "Nervousness"},
        { value: 11, label: "Poor eating habits"},
        { value: 12, label: "Frequent loneliness / depression"},
        { value: 13, label: "Weight loss"},
        { value: 14, label: "Pallor / Anemia"},
        { value: 15, label: "Forgetfulness / Memory lapses"},
        { value: 16, label: "Chest pain"},
        { value: 17, label: "Pallor / Anemia"},
        { value: 18, label: "Social problems / relationship"},
        { value: 19, label: "Reading / Eyes"},
        { value: 20, label: "Adjustments to school"},
        { value: 21, label: "Frequent Headaches"},
        { value: 22, label: "None of the above"},
 ],
 family_health_history: [
    { value: 1, label: "Cancer"},
    { value: 2, label: "Asthma"},
    { value: 3, label: "Allergy"},
    { value: 4, label: "Hepatitis / Liver diseases"},
    { value: 5, label: "Mental illness"},
    { value: 6, label: "Tubercolosis"},
    { value: 7, label: "Heart disease"},
    { value: 8, label: "High blood presure"},
    { value: 9, label: "Diabetes"},
    { value: 10, label: "None"},
 ],
 evaluate_self: [
    { value: 1, label: "Healthy and can do all school activities"},
    { value: 2, label: "Healthy but with limited activities"},
    { value: 3, label: "Does not know"},
    { value: 4, label: "Cannot answer except on consulting with doctors"},
 ],
 financial_support: [
    { value: 1, label: "Family"},
    { value: 2, label: "Scholarship"},
    { value: 3, label: "Relatives"},
    { value: 4, label: "Self"},
    { value: 5, label: "Guardian"},
    { value: 6, label: "Others"},
 ],
 grew_up: [
    { value: 1, label: "City"},
    { value: 2, label: "Town"},
    { value: 3, label: "Barrio"},
 ],
 language_home: [
    { value: 1, label: "English"},
    { value: 2, label: "Filipino"},
    { value: 3, label: "Others"},
 ],
 concerns: [
    { value: 1, label: "Relationship with family members / relatives"},
    { value: 2, label: "Lack of concentration / focus"},
    { value: 3, label: "Relationship with friends / classmates"},
    { value: 4, label: "Money for financial support"},
    { value: 5, label: "Difficulties in submitting tasks on time"},
    { value: 6, label: "Excessive fears and/or anxieties"},
    { value: 7, label: "Struggling to attend virtual class more often"},
    { value: 8, label: "Poor study habits / time management"},
    { value: 9, label: "Others"},
 ],
 problem_solution: [
    { value: 1, label: "Keep it to yourself"},
    { value: 2, label: "Seek help from others"},
    { value: 3, label: "Personal Prayer"},
    { value: 4, label: "Engage in leisure activities"},
    { value: 5, label: "Others"}
 ],
 cope_up_stress: [
    { value: 1, label: "Attending mass"},
    { value: 2, label: "Personal Prayer"},
    { value: 3, label: "Indulging to recreational activities"},
    { value: 4, label: "Talking to a family member / friend"},
    { value: 5, label: "Engaging in healthy lifestyle activities"},
    { value: 6, label: "Seek help from adviser/ school counselor"},
    { value: 7, label: "Others"},
 ],
 how_to_know_sapc: [
    { value: 1, label: "School to school campaign / Orientation"},
    { value: 2, label: "Referral of SAPC personel"},
    { value: 3, label: "Poster / Tarpaulin / Print ads"},
    { value: 4, label: "Relatives / Friends"},
    { value: 5, label: "School Website"},
    { value: 6, label: "Facebook page"},
    { value: 7, label: "Others"},
 ],
 searchType: [
    {value: 1, label: "Reference Number"},
    {value: 2, label: "More Filters"}
 ],
 subjectType: [
   {value: "core", label: "CORE"},
   {value: "applied", label: "APPLIED"},
   {value: "specialized", label: "SPECIALIZED"}
 ]
}