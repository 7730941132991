import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import overlayFactory from "react-bootstrap-table2-overlay";
import { isBrowser, isDesktop, isMobile } from "react-device-detect";
import {
  Button,
  Col,
  Container,
  InputGroup,
  InputGroupAddon,
  Row,
  Input,
  UncontrolledTooltip,
  Label,
  FormGroup,
  Form,
} from "reactstrap";
import { registrarActions } from "../../../services/RegistrarServices";
import View from "../../../assets/img/view.png";
import Edit from "../../../assets/img/edit.png";
import File from "../../../assets/img/UPLOAD.png";
import ViewStudentDetails from "./ViewStudentDetails";
import EditStudentDetails from "./EditStudentDetails";
import { applicationActions } from "../../../services/ApplicationServices";
import MyUploadedFiles from "../Admission/MyUploadedFiles";
import Select from "react-select";
import states from "../States/States";
import { educationActions } from "../../../services/EducationSelectionService.js";
import MobileSideBar from "../Admission/MobileSidebar";
import { AiFillEye } from "react-icons/ai";
import { FaEye } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useForm, Controller } from "react-hook-form";
import moment from "moment";
import optionList from "../Admission/optionList";
import filterFactory from "react-bootstrap-table2-filter";

const EnrolledStudents = () => {
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [totalSize, setTotalSize] = useState(0);
  const [page1, setPage1] = useState(1);
  const [sizePerPageQuery, setSizePerPageQuery] = useState(10);
  const [sortFieldQuery, setSortFieldQuery] = useState("id");
  const [sortOrderQuery, setSortOrderQuery] = useState("desc");
  const [loading, setLoading] = useState(false);
  const [gradeLevelFilter, setGradeLevelFilter] = useState("");
  const [coursesFilter, setCoursesFilter] = useState("");
  const [updateTable, setUpdateTable] = useState(false);
  const [summary, setSummary] = useState(states.blankSummary);
  const [gradeLevels, setGradeLevels] = useState([]);
  const [courses, setCourses] = useState([]);
  const { register, handleSubmit, errors, control, setValue, getValues } =
    useForm();
  const [buttonSpinner, setButtonSpinner] = useState(false);

  const [filters, setFilters] = useState({
    syFrom: "",
    syTo: "",
    student_status: "",
    gradeLevel: "",
    semester: "",
    course: "",
  });

  const [selectOptions, setSelectOptions] = useState({
    education_types: [],
    student_types: [],
    grade_types: [],
    grade_levels: [],
    gradeLevelSelection: [],
    coursesSelection: [],
    collegeSubjSelection: [],
    subjects: [],
    allSubjects: [],
    collegeCourses: [],
    student_status: [
      { value: "cancelled", label: "Cancelled" },
      { value: "pending", label: "Pending" },
      { value: "certified", label: "Certified" },
      { value: "enrolled", label: "Enrolled" },
    ],
    enrollee_type: [
      { value: 0, label: "Regular Student" },
      { value: 1, label: "Manually select subjects" },
    ],
    payment_mode: [
      { value: 1, label: "Annual" },
      { value: 2, label: "Semestral" },
      { value: 3, label: "Monthly A" },
      { value: 4, label: "Monthly B" },
    ],
    payment_mode_tertiary: [
      { value: 1, label: "Full Payment" },
      { value: 3, label: "Monthly A" },
      { value: 4, label: "Monthly B" },
    ],
    student_status: [
      { value: "cancelled", label: "Cancelled" },
      { value: "pending", label: "Pending" },
      { value: "certified", label: "Certified" },
      { value: "enrolled", label: "Enrolled" },
    ],
  });

  const [details, setDetails] = useState(states.blankDetails);
  const [gradeLevelSelection, setGradeLevelSelection] = useState([]);
  const [coursesSelection, setCoursesSelection] = useState([]);
  const withSemester = [
    "Grade 11",
    "Grade 12",
    "First year",
    "Second year",
    "Third year",
    "Fourth year",
  ];

  useEffect(() => {
    educationActions.getApplications(
      null,
      null,
      null,
      setGradeLevels,
      null,
      setCourses,
      null,
      null
    );
  }, []);

  useEffect(() => {
    if (data.length) {
      setLoading(true);
      let newFilters = {
        sy_from: moment(filters.syFrom).format("YYYY"),
        sy_to: moment(filters.syTo).format("YYYY"),
        student_status: filters.student_status,
        gradeLevel: filters.gradeLevel,
        semester: filters.semester,
        course: filters.course,
      };
      registrarActions.getEnrolledStudents(
        setData,
        setSummary,
        setLoading,
        setTotalSize,
        page1,
        sizePerPageQuery,
        searchText,
        newFilters,
        setButtonSpinner
      );
    }
  }, [page1, sizePerPageQuery, updateTable]);

  useEffect(() => {
    if (gradeLevels.length >= 1) {
      let level = gradeLevels.map((grade) => {
        return { value: grade.id, label: grade.name };
      });
      setGradeLevelSelection(level);
    }
  }, [gradeLevels]);

  useEffect(() => {
    if (courses.length >= 1) {
      let c = courses.map((course) => {
        return { value: course.id, label: course.name };
      });
      setCoursesSelection(c);
    }
  }, [courses]);

  const columns = [
    {
      dataField: "ref_no",
      text: "Reference No",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
      formatter: (cell) => {
        return cell.toUpperCase();
      },
    },
    {
      dataField: "student_id",
      text: "Student Id",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "grade_level",
      text: "Grade Level",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "last_name",
      text: "Last Name",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "given_name",
      text: "First Name",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "email_address",
      text: "Email",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "course",
      text: "Course",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "semester",
      text: "Semester",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "id",
      text: "Action",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
      formatter: (cell, row) => {
        return (
          <div className="px-2 text-center" style={{ minWidth: "50px" }}>
            <Button
              id={"view-btn" + cell}
              onClick={() => toggleViewDetails(cell, row)}
              className="btn-warning mr-2"
            >
              <FaEye style={{ fontSize: "1.5rem", color: "#FFF" }} />
            </Button>
            <UncontrolledTooltip placement="top" target={"view-btn" + cell}>
              View Details
            </UncontrolledTooltip>

            <Button
              id={"edit-btn" + cell}
              onClick={() => toggleEditDetails(cell, row)}
              className="btn-warning mr-2"
            >
              <img
                src={Edit}
                style={{
                  height: isDesktop ? "1.5rem" : "1.1rem",
                  objectFit: "fill",
                }}
              />
            </Button>
            <UncontrolledTooltip placement="top" target={"edit-btn" + cell}>
              Edit Details
            </UncontrolledTooltip>

            <Button
              id={"upload-file-btn" + cell}
              onClick={() => toggleUploadFile(row.application_id)}
              className="btn-warning"
            >
              <img
                src={File}
                style={{
                  height: isDesktop ? "1.5rem" : "1.1rem",
                  objectFit: "fill",
                }}
              />
            </Button>
            <UncontrolledTooltip
              placement="top"
              target={"upload-file-btn" + cell}
            >
              Upload Requirements
            </UncontrolledTooltip>
          </div>
        );
      },
    },
  ];

  const [viewDetails, setViewDetails] = useState(false);
  const toggleViewDetails = (id, details) => {
    setDetails(states.blankDetails);
    if (!viewDetails) {
      setDetails(details);
      setViewDetails(!viewDetails);
    } else {
      setViewDetails(!viewDetails);
    }
  };

  const [editDetails, setEditDetails] = useState(false);
  const toggleEditDetails = (id, details) => {
    setDetails(states.blankDetails);
    if (!editDetails) {
      setDetails(details);
      setEditDetails(!editDetails);
    } else {
      setEditDetails(!editDetails);
    }
  };

  const getOptionLabelByValue = (options, value) => {
    const option = options.find((option) => option.value == value);
    return option ? option.label : "";
  };

  const [viewMyApplication, setViewMyApplication] = useState(false);
  const [myUploadedFileOpen, setMyUploadedFileOpen] = useState(false);
  const [myBasicForm, setMyBasicForm] = useState({});
  const [myScholarShipForm, setMyScholarShipForm] = useState({});
  const [myTesdaForm, setMyTesdaForm] = useState({});
  const [myEducationSelected, setMyEducationSelected] = useState({});
  const [mySelectedEducationType, setMySelectedEducationType] = useState({});
  const [myPaymentForm, setMyPaymentForm] = useState({});
  const [mySubjectForm, setMySubjectForm] = useState({});
  const [mySubjects, setMySubjects] = useState({});
  const [mySelectedSubjects, setMySelectedSubjects] = useState({});
  const [mySchoolFees, setMySchoolFees] = useState({});
  const toggleUploadFile = (id) => {
    if (!myUploadedFileOpen) {
      applicationActions.getMyApplication(
        id,
        setMyBasicForm,
        setMyScholarShipForm,
        setMyTesdaForm,
        setMyEducationSelected,
        setMySelectedEducationType,
        selectOptions,
        getOptionLabelByValue,
        myPaymentForm,
        setMyPaymentForm,
        setMySubjectForm,
        setMySubjects,
        setMySelectedSubjects,
        mySchoolFees,
        setViewMyApplication,
        setMyUploadedFileOpen,
        "viewMyUploadedFiles"
      );
    } else {
      setMyUploadedFileOpen(!myUploadedFileOpen);
    }
  };

  const changeQuery = (e) => {
    setSearchText(e.target.value);
  };

  const NoDataIndication = () => <div>No Data</div>;

  const onSubmit = (data) => {
    setButtonSpinner(true);
    let newFilters = {
      sy_from: moment(data.sy_from).format("YYYY"),
      sy_to: moment(data.sy_to).format("YYYY"),
      student_status: data.student_status ? data.student_status.value : "",
      gradeLevel: filters.gradeLevel,
      semester: filters.semester,
      course: filters.course,
    };
    registrarActions.getEnrolledStudents(
      setData,
      setSummary,
      setLoading,
      setTotalSize,
      page1,
      sizePerPageQuery,
      searchText,
      newFilters,
      setButtonSpinner
    );
  };

  const RemoteAll = ({
    loading,
    data,
    page,
    sizePerPage,
    onTableChange,
    totalSize,
  }) => (
    <div>
      <BootstrapTable
        wrapperClasses="table-responsive text-center"
        bootstrap4
        striped
        remote
        loading={loading}
        keyField="id"
        data={data}
        columns={columns}
        defaultSorted={defaultSorted}
        filter={filterFactory()}
        pagination={paginationFactory({ page, sizePerPage, totalSize })}
        onTableChange={onTableChange}
        overlay={overlayFactory({
          spinner: true,
          styles: {
            overlay: (base) => ({
              ...base,
              background: "rgba(0, 0, 0, 0.5)",
            }),
          },
        })}
        noDataIndication={() => <NoDataIndication />}
      />
    </div>
  );

  // DATA TABLE
  const handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder }
  ) => {
    if (page !== page1) {
      setPage1(page);

      setSortFieldQuery(sortField ? sortField : sortFieldQuery);
      setSortOrderQuery(sortOrder ? sortOrder : sortOrderQuery);
      setLoading(true);
    }

    if (
      (sortField != sortFieldQuery && sortField != undefined) ||
      (sortOrder != sortOrderQuery && sortOrder != undefined)
    ) {
      setSortFieldQuery(sortField ? sortField : sortFieldQuery);
      setSortOrderQuery(sortOrder ? sortOrder : sortOrderQuery);
      setLoading(true);
    }

    if (sizePerPage != sizePerPageQuery) {
      setSizePerPageQuery(sizePerPage);
      setLoading(true);
    }
  };

  const noDataIndication = () => <div>No Data</div>;
  const defaultSorted = [
    {
      dataField: sortFieldQuery,
      order: sortOrderQuery,
    },
  ];

  return (
    <div className="p-4">
      {viewDetails ? (
        <ViewStudentDetails
          viewDetails={viewDetails}
          details={details}
          toggleViewDetails={toggleViewDetails}
        />
      ) : (
        ""
      )}
      {editDetails ? (
        <EditStudentDetails
          editDetails={editDetails}
          setEditDetails={setEditDetails}
          details={details}
          setDetails={setDetails}
          toggleEditDetails={toggleEditDetails}
          updateTable={updateTable}
          setUpdateTable={setUpdateTable}
        />
      ) : (
        ""
      )}
      {myUploadedFileOpen ? (
        <MyUploadedFiles
          myUploadedFileOpen={myUploadedFileOpen}
          toggleUploadFile={toggleUploadFile}
          basicForm={myBasicForm}
          setBasicForm={setMyBasicForm}
          tesdaForm={myTesdaForm}
          setTesdaForm={setMyTesdaForm}
          scholarshipForm={myScholarShipForm}
        />
      ) : (
        ""
      )}

      <Row className="jumbotron mx-auto">
        <Col md="7" xs="12" className="mb-3">
          <p className="mb-1 font-weight-bolder">Summary: </p>
          <p className="mb-1">
            Last Update:{" "}
            <span className="font-weight-bolder">{summary.last_update}</span>
          </p>
          <p className="mb-1">
            Updated At:{" "}
            <span className="font-weight-bolder">{summary.updated_at}</span>
          </p>
        </Col>
        <Col md="3" xs="12" className="mb-3">
          <div className="d-flex">
            <div
              className="text-white d-flex align-items-center justify-content-center bg-secondary-edit rounded-start"
              style={{
                width: "120px",
                minHeight: "80px",
                border: "3px solid #a6a6a6",
              }}
            >
              <h2 className="font-weight-bolder">{summary.enrolled_count}</h2>
            </div>
            <div
              className="text-secondary-edit d-flex align-items-center justify-content-center bg-light p-2 rounded-end"
              style={{
                width: "200px",
                minHeight: "80px",
                border: "3px solid #a6a6a6",
              }}
            >
              <Row className="flex-column">
                <Row>
                  <Col xs="6" md="6">
                    <small className="font-weight-bolder">Cancelled</small>
                  </Col>
                  <Col xs="6" md="6">
                    <small className="font-weight-bolder">
                      : {summary.cancel_count}
                    </small>
                  </Col>
                </Row>
                <Row>
                  <Col xs="6" md="6">
                    <small className="font-weight-bolder">Pending</small>
                  </Col>
                  <Col xs="6" md="6">
                    <small className="font-weight-bolder">
                      : {summary.pending_count}
                    </small>
                  </Col>
                </Row>
                <Row>
                  <Col xs="6" md="6">
                    <small className="font-weight-bolder">Certified</small>
                  </Col>
                  <Col xs="6" md="6">
                    <small className="font-weight-bolder">
                      : {summary.certify_count}
                    </small>
                  </Col>
                </Row>
                <Row>
                  <Col xs="6" md="6">
                    <small className="font-weight-bolder">Enrolled</small>
                  </Col>
                  <Col xs="6" md="6">
                    <small className="font-weight-bolder">
                      : {summary.enrolled_count}
                    </small>
                  </Col>
                </Row>
              </Row>
            </div>
          </div>
        </Col>
        <Col
          md="2"
          xs="12"
          className="align-items-center justify-content-center d-flex"
        >
          <Row>
            <Col md="2" xs="2">
              <small
                className="rounded bg-secondary-edit"
                style={{ padding: "0px 12px", border: "2px solid #a6a6a6" }}
              ></small>
            </Col>
            <Col md="9" xs="9">
              <small className="text-dark-edit font-weight-bolder">
                Total Number of Students
              </small>
            </Col>
            <Col md="2" xs="2">
              <small
                className="rounded bg-light"
                style={{ padding: "0px 12px", border: "2px solid #a6a6a6" }}
              ></small>
            </Col>
            <Col md="9" xs="9">
              <small className="text-dark-edit font-weight-bolder">
                Enrollment Status
              </small>
            </Col>
          </Row>
        </Col>
      </Row>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md="2" xs="12">
            <FormGroup>
              <Label>
                <small className="font-weight-bolder">S.Y. From</small>
              </Label>
              <Controller
                control={control}
                name="sy_from"
                defaultValue=""
                rules={{ required: true }}
                invalid={errors.sy_from ? true : false}
                render={({ onChange, value, onBlur, name }) => (
                  <DatePicker
                    className="form-control bg-light"
                    selected={
                      moment(filters.syFrom).isValid()
                        ? moment(filters.syFrom).toDate()
                        : filters.syFrom
                    }
                    onChange={(date) => {
                      // setStartDate(date);
                      setValue("sy_from", date);
                      setFilters({ ...filters, syFrom: date });
                      return date;
                    }}
                    showYearPicker
                    dateFormat="yyyy"
                    yearItemNumber={10}
                    name="sy_from"
                    defaultValue=""
                  />
                )}
              />
              {errors.sy_from && errors.sy_from.type == "required" && (
                <div
                  style={{
                    marginTop: "0.25rem",
                    fontSize: "80%",
                    color: "#f86c6b",
                  }}
                >
                  Schoo Year From is required!
                </div>
              )}
            </FormGroup>
          </Col>
          <Col md="2" xs="12">
            <FormGroup>
              <Label>
                <small className="font-weight-bolder">S.Y. To</small>
              </Label>
              <Controller
                control={control}
                name="sy_to"
                defaultValue=""
                rules={{
                  required: true,
                  validate: (value) => value > getValues("sy_from"),
                }}
                invalid={errors.sy_to ? true : false}
                render={({ onChange, value, onBlur, name }) => (
                  <DatePicker
                    className="form-control bg-light"
                    selected={
                      moment(filters.syTo).isValid()
                        ? moment(filters.syTo).toDate()
                        : filters.syTo
                    }
                    onChange={(date) => {
                      setValue("sy_to", date);
                      setFilters({ ...filters, syTo: date });
                      return date;
                    }}
                    showYearPicker
                    dateFormat="yyyy"
                    yearItemNumber={10}
                    name="sy_to"
                    defaultValue=""
                  />
                )}
              />
              {errors.sy_to && errors.sy_to.type == "required" && (
                <div
                  style={{
                    marginTop: "0.25rem",
                    fontSize: "80%",
                    color: "#f86c6b",
                  }}
                >
                  School Year To is required!
                </div>
              )}
              {errors.sy_to && errors.sy_to.type == "validate" && (
                <div
                  style={{
                    marginTop: "0.25rem",
                    fontSize: "80%",
                    color: "#f86c6b",
                  }}
                >
                  School Year To cannot be lower or equal to S.Y. From!
                </div>
              )}
            </FormGroup>
          </Col>
          <Col md="2" xs="12">
            <FormGroup>
              <Label>
                <small className="font-weight-bolder">
                  <i className="fa fa-lightbulb-o"></i>&nbsp;Filter by Student
                  Status
                </small>
              </Label>
              <Controller
                control={control}
                name="student_status"
                rules={{ required: true }}
                invalid={errors.student_status ? true : false}
                defaultValue=""
                render={({ onChange, value, onBlur, name }) => (
                  <Select
                    options={selectOptions.student_status}
                    onChange={(e) => {
                      setValue("student_status", e);
                      setFilters({ ...filters, student_status: e.value });
                      return e;
                    }}
                    defaultValue=""
                  />
                )}
              />
              {errors.student_status && (
                <div
                  style={{
                    marginTop: "0.25rem",
                    fontSize: "80%",
                    color: "#f86c6b",
                  }}
                >
                  Please select student status!
                </div>
              )}
            </FormGroup>
          </Col>
          <Col md="4" xs="12" className="ml-auto mb-2">
            <Label>
              <small className="font-weight-bolder">
                <i className="fa fa-lightbulb-o"></i>&nbsp;Search by Last Name
              </small>
            </Label>
            <InputGroup>
              <InputGroupAddon addonType="prepend" style={{ zIndex: "0" }}>
                <Button type="button" color="primary" id="search-button">
                  <i className="fa fa-search"></i>
                </Button>
              </InputGroupAddon>
              <Input
                type="text"
                id="search-text"
                name="search-text"
                placeholder="Search"
                onChange={changeQuery}
              />
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col md="2" xs="12" className="mb-2">
            <Label>
              <small className="font-weight-bolder">
                <i className="fa fa-lightbulb-o"></i>&nbsp;Filter by Grade Level
              </small>
            </Label>
            <Controller
              control={control}
              name="gradeLevel"
              rules={{ required: false }}
              invalid={errors.gradeLevel ? true : false}
              defaultValue=""
              render={({ onChange, value, onBlur, name }) => (
                <Select
                  options={gradeLevelSelection}
                  onChange={(e) => {
                    setValue("semester", "");
                    setValue("course", "");
                    setGradeLevelFilter(e.label);
                    setFilters({
                      ...filters,
                      gradeLevel: e.value,
                      course: "",
                      semester: "",
                    });
                    return e;
                  }}
                  defaultValue=""
                />
              )}
            />
          </Col>
          {withSemester.includes(gradeLevelFilter) ? (
            <>
              <Col md="2" xs="12" className="mb-2">
                <Label>
                  <small className="font-weight-bolder">
                    <i className="fa fa-lightbulb-o"></i>&nbsp;Filter by Courses
                  </small>
                </Label>
                <Controller
                  control={control}
                  name="course"
                  rules={{ required: false }}
                  invalid={errors.course ? true : false}
                  defaultValue={
                    filters.course != "" ? { value: filters.course } : ""
                  }
                  render={({ onChange, value, onBlur, name }) => (
                    <Select
                      options={coursesSelection}
                      onChange={(e) => {
                        setFilters({ ...filters, course: e.value });
                        return e;
                      }}
                      defaultValue={
                        filters.course != ""
                          ? {
                              value: filters.course,
                              label: getOptionLabelByValue(
                                coursesSelection,
                                filters.course
                              ),
                            }
                          : ""
                      }
                    />
                  )}
                />
              </Col>
              <Col md="2" xs="12" className="mb-2">
                <Label>
                  <small className="font-weight-bolder">
                    <i className="fa fa-lightbulb-o"></i>&nbsp;Filter by
                    Semester
                  </small>
                </Label>
                <Controller
                  control={control}
                  name="semester"
                  rules={{ required: false }}
                  invalid={errors.semester ? true : false}
                  defaultValue=""
                  render={({ onChange, value, onBlur, name }) => (
                    <Select
                      options={optionList.semester}
                      onChange={(e) => {
                        setFilters({ ...filters, semester: e.value });
                        return e;
                      }}
                      defaultValue=""
                    />
                  )}
                />
              </Col>
            </>
          ) : (
            ""
          )}
        </Row>
        <Row className="pb-5 pt-4">
          <Col xs="12" md="12">
            <Button
              disabled={buttonSpinner}
              className="bg-danger-edit text-white border-0"
            >
              Filter
            </Button>
          </Col>
        </Row>
      </Form>
      <RemoteAll
        loading={loading}
        data={data}
        page={page1}
        sizePerPage={sizePerPageQuery}
        totalSize={totalSize}
        onTableChange={handleTableChange}
        register={register}
      />
    </div>
  );
};
export default EnrolledStudents;
