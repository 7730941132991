import React, { useState } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
  Container,
  Label,
  FormGroup,
  Form,
  Input,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import classnames from "classnames";
import moment from "moment";
import { useForm, Controller } from "react-hook-form";
import { paymentActions } from "../../../services/PaymentServices";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

const CashierPaidConfirm = ({
  statusModalChange,
  setStatusModalChange,
  togglePaidStatus,
  billingId,
  billings,
  setBillings,
}) => {
  const { register, handleSubmit, errors, control, setValue, getValues } =
    useForm();
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const submit = () => {
    setButtonSpinner(!buttonSpinner);
    const dataSubmit = {
      billing_id: billingId,
    };

    paymentActions.updateToPaidStatus(
      dataSubmit,
      setButtonSpinner,
      setStatusModalChange,
      billings,
      setBillings
    );
  };

  return (
    <Modal isOpen={statusModalChange} toggle={togglePaidStatus}>
      <ModalHeader> </ModalHeader>
      <ModalBody className="text-center">
        <h5>Confirm Payment Update?</h5>
        <small>Note: Payment status will update from Pending to Paid</small>
      </ModalBody>
      <ModalFooter>
        <Button
          color="warning"
          className="text-white"
          disabled={buttonSpinner ? true : false}
          onClick={submit}
        >
          {buttonSpinner ? (
            <>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              &nbsp;Processing...
            </>
          ) : (
            "Confirm"
          )}
        </Button>
        <Button
          disabled={buttonSpinner ? true : false}
          color="secondary"
          onClick={togglePaidStatus}
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CashierPaidConfirm;
