import { ENDPOINT } from "../constants/api";
import axios from "axios";
import { notifyError, notifySuccess } from "./Toaster";
import moment from "moment";

export const teacherActions = {
  getSubjects,
  getStudentList,
  saveStudentGrade
};

const token = localStorage.getItem("jwtToken")
  ? localStorage.getItem("jwtToken")
  : "";
const headers = {
  Authorization: `Bearer ${token}`,
  "Content-Type": "application/json",
  "X-Requested-With": "XMLHttpRequest",
};

async function getSubjects(setData, setLoading) {
  try {
    let res = await axios.post(ENDPOINT + `/get-data`, {}, {
      headers: headers,
    });
    
    const data = res.data.data.teacher_data[0].list_of_subjects ? res.data.data.teacher_data[0].list_of_subjects : [];
    setData(data)
    setLoading(false);
  } catch (err) {
    if (err.response) {
      notifyError("Error while fetching Data");
    }
  }
}

async function getStudentList(newData, setStudentList, setButtonSpinner, setLoading, setUpdateGrade)
{
  try{
    let res = await axios.post(ENDPOINT + `/get-student-list`, newData, {
      headers: headers,
    });
    console.log(res.data.data.student_list)
    setStudentList(res.data.data.student_list)
    setButtonSpinner(false)
    setLoading(false)
    setUpdateGrade(false)
  }catch(err){
    setLoading(false)
    notifyError("Error while fetching Student List");
  }
}

async function saveStudentGrade(dataToSubmit, setButtonSpinner, setUpdateGrade)
{
  try{
    let res = await axios.post(ENDPOINT + `/store-student-grade`, dataToSubmit, {
      headers: headers,
    });
    notifySuccess(res.data.message);
    setButtonSpinner(false)
    setUpdateGrade(true)
  }catch(err){
    notifyError("Error while fetching saving student grade");
    setButtonSpinner(false)
  }
}
