import React, { useState, useEffect, Fragment } from "react";
import {
  Container,
  Collapse,
  Navbar,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Label,
  Card,
  Button,
  CardImg,
  CardText,
  CardDeck,
  CardBody,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  Modal,
  ModalBody,
  Spinner,
  Fade,
  ModalHeader,
  Input,
  ModalFooter,
} from "reactstrap";

import { useNavigate } from "react-router-dom";
import landingPageLogo from "../../../../assets/img/landingPageLogo.png";
import carousel1 from "../../../../assets/img/carousel-1.jpg";
import carousel2 from "../../../../assets/img/carousel-2.jpg";
import carousel3 from "../../../../assets/img/carousel-3.jpg";
import carouselMobile1 from "../../../../assets/img/carousel-mobile-1.jpg";
import carouselMobile2 from "../../../../assets/img/carousel-mobile-2.jpg";
import carouselMobile3 from "../../../../assets/img/carousel-mobile-3.jpg";
import basic1 from "../../../../assets/img/basic-1.jpg";
import basicGif from "../../../../assets/img/basic-ed-gif.gif";
import tertiary1 from "../../../../assets/img/tertiary-1.jpg";
import tertiaryGif from "../../../../assets/img/tertiary-ed-gif.gif";
import tesda1 from "../../../../assets/img/tesda-1.jpg";
import tesdaGif from "../../../../assets/img/technical-ed-gif.gif";
import news1 from "../../../../assets/img/news-1.png";
import news2 from "../../../../assets/img/news-2.png";
import news3 from "../../../../assets/img/news-3.png";
import news4 from "../../../../assets/img/news-4.png";
import news5 from "../../../../assets/img/news-5.png";
import Select from "react-select";
import {
  isBrowser,
  isDesktop,
  isIOS,
  isTablet,
  isMobile,
} from "react-device-detect";
import { Slide } from "react-toastify";
import { WithContext as ReactTags } from "react-tag-input";
import HomepageCarousel from "./HomepageCarousel";
import HomepageCourses from "./HomepageCourses";
import HomepageNews from "./HomepageNews";
import { dynamicHomepageActions } from "../../../../services/DynamiHomepageServices";

const Homepage = () => {
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [openArrangeModal, setOpenArrangeModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [buttonSpinner2, setButtonSpinner2] = useState(false);
  const [currentVersion, setCurrentVersion] = useState("");
  const [versionSelection, setVersionSelection] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState("");
  const [animating, setAnimating] = useState(false);

  const navigate = useNavigate();

  const KeyCodes = { enter: 13 };
  const delimiters = [KeyCodes.enter];

  const [slidesMobile, setSlidesMobile] = useState([]);
  const [slidesDesktop, setSlidesDesktop] = useState([]);

  const [homepage, setHomepage] = useState({
    desktopCarousel: [
      {
        src: carousel1,
        imageFile: "",
        altText: "",
      },
      {
        src: carousel2,
        imageFile: "",
        altText: "",
      },
      {
        src: carousel3,
        imageFile: "",
        altText: "",
      },
    ],
    mobileCarousel: [
      {
        src: carouselMobile1,
        imageFile: "",
        altText: "",
      },
      {
        src: carouselMobile2,
        imageFile: "",
        altText: "",
      },
      {
        src: carouselMobile3,
        imageFile: "",
        altText: "",
      },
    ],
    courses: {
      basic: {
        image: basic1,
        imageFile: "",
        gif: basicGif,
        gifFile: "",
        videoUrl:
          "https://www.youtube.com/embed/c4T5BcVuoIQ?rel=0&amp;autoplay=1&mute=0",
        data: [
          {
            title: "",
            data: [
              {
                title: "Grade School",
                subtitle: "(Kinder to Grade 6)",
              },
              {
                title: "Junior High School",
                subtitle: "(Grade 7 to Grade 10)",
              },
              {
                title: "Senior High School",
                subtitle: "(Grade 11 to Grade 12)",
              },
            ],
          },
        ],
      },
      tertiary: {
        image: tertiary1,
        imageFile: "",
        gif: tertiaryGif,
        gifFile: "",
        videoUrl:
          "https://www.youtube.com/embed/LM74lJGfvHw?rel=0&amp;autoplay=1&mute=0",
        data: [
          {
            title: "",
            subtitle: "",
            data: [
              {
                title: "AB in Psychology",
                subtitle: "",
              },
              {
                title: "BS in Business Administration",
                subtitle: "",
              },
              {
                title: "BS in Hospitality Management",
                subtitle: "",
              },
              {
                title: "Associate in Office Management",
                subtitle: "",
              },
            ],
          },
        ],
      },
      technical: {
        image: tesda1,
        imageFile: "",
        gif: tesdaGif,
        gifFile: "",
        videoUrl:
          "https://www.youtube.com/embed/FxrptX88sk4?rel=0&amp;autoplay=1&mute=0",
        data: [
          {
            title: "Training Center",
            subtitle: "",
            data: [
              {
                title: "Bread and Pastry Production NC II",
                subtitle: "",
              },
              {
                title: "Caregiving NC II",
                subtitle: "",
              },
              {
                title: "Cookery NC II",
                subtitle: "",
              },
              {
                title: "Housekeeping NC II",
                subtitle: "",
              },
              {
                title: "Food and Beverage Services NC II",
                subtitle: "",
              },
              {
                title: "Shielded Metal Arc Welding (SMAW) NC I",
                subtitle: "",
              },
              {
                title: "Shielded Metal Arc Welding (SMAW) NC II",
                subtitle: "",
              },
            ],
          },
          {
            title: "Accredited Competency Assessment Center",
            subtitle: "",
            data: [
              {
                title: "Bread and Pastry Production NC II",
                subtitle: "",
              },
              {
                title: "Caregiving NC II",
                subtitle: "",
              },
              {
                title: "Cookery NC II",
                subtitle: "",
              },
              {
                title: "Housekeeping NC II",
                subtitle: "",
              },
              {
                title: "Food and Beverage Services NC II",
                subtitle: "",
              },
              {
                title: "Shielded Metal Arc Welding (SMAW) NC I",
                subtitle: "",
              },
              {
                title: "Shielded Metal Arc Welding (SMAW) NC II",
                subtitle: "",
              },
            ],
          },
        ],
      },
    },
    news: [
      {
        title: "Our Students Our pride!",
        image: news5,
        imageFile: "",
        url_link:
          "https://www.facebook.com/san.antonio.de.padua.college/posts/6485304558206706",
      },
      {
        title: "Activities for the Month of November",
        image: news4,
        imageFile: "",
        url_link:
          "https://www.facebook.com/327167530960899/posts/1568869503457356/",
      },
      {
        title: "TES Release",
        image: news3,
        imageFile: "",
        url_link:
          "https://www.facebook.com/163764627027429/posts/6366270160110147/",
      },
      {
        title: "Virtual Earthquake Drill",
        image: news2,
        imageFile: "",
        url_link:
          "https://www.facebook.com/163764627027429/posts/6314412225295941/",
      },
      {
        title: "Ms. World Tourism Philippines 2021!",
        image: news1,
        imageFile: "",
        url_link:
          "https://www.facebook.com/163764627027429/posts/6218140968256401/",
      },
    ],
  });

  useEffect(() => {
    setLoading(true);
    setCurrentVersion("");
    dynamicHomepageActions.getCurrentTemplate(
      setHomepage,
      homepage,
      setCurrentVersion,
      setSelectedVersion,
      setLoading,
      setVersionSelection,
      carousel1,
      carousel2,
      carousel3,
      carouselMobile1,
      carouselMobile2,
      carouselMobile3,
      basic1,
      basicGif,
      tertiary1,
      tertiaryGif,
      tesda1,
      tesdaGif,
      news5,
      news4,
      news3,
      news2,
      news1
    );
  }, []);

  useEffect(() => {
    if (selectedVersion != "") {
      dynamicHomepageActions.getSelectedTemplate(
        setHomepage,
        homepage,
        setCurrentVersion,
        selectedVersion,
        setLoading,
        setVersionSelection,
        carousel1,
        carousel2,
        carousel3,
        carouselMobile1,
        carouselMobile2,
        carouselMobile3,
        basic1,
        basicGif,
        tertiary1,
        tertiaryGif,
        tesda1,
        tesdaGif,
        news5,
        news4,
        news3,
        news2,
        news1
      );
    }
  }, [selectedVersion]);

  const getOptionLabelByValue = (options, value) => {
    const option = options.find((option) => option.value == value);
    return option ? option.label : "";
  };

  const handlePublishTemplate = () => {
    setButtonSpinner2(true);
    dynamicHomepageActions.publishTemplate(
      selectedVersion,
      setButtonSpinner,
      setLoading,
      setButtonSpinner2
    );
  };

  useEffect(() => {
    if (homepage.desktopCarousel.length >= 1) {
      let desktopSlides = homepage.desktopCarousel.map((item) => {
        return (
          <CarouselItem
            onExiting={() => setAnimating(true)}
            onExited={() => setAnimating(false)}
            key={item.src}
          >
            <img
              src={item.src}
              alt={item.altText}
              className="w-100"
              style={{ height: "80vh", objectFit: "cover" }}
            />
            {/* <CarouselCaption captionText={item.caption} captionHeader={item.caption} /> */}
          </CarouselItem>
        );
      });
      setSlidesDesktop(desktopSlides);
    }
  }, [homepage.desktopCarousel]);

  useEffect(() => {
    if (homepage.mobileCarousel.length >= 1) {
      let mobileSlides = homepage.mobileCarousel.map((item) => {
        return (
          <CarouselItem
            onExiting={() => setAnimating(true)}
            onExited={() => setAnimating(false)}
            key={item.src}
          >
            <img
              src={item.src}
              alt={item.altText}
              className="w-100"
              style={{ height: "100vh", objectFit: "cover" }}
            />
            {/* <CarouselCaption captionText={item.caption} captionHeader={item.caption} /> */}
          </CarouselItem>
        );
      });
      setSlidesMobile(mobileSlides);
    }
  }, [homepage.mobileCarousel]);

  const [type, setType] = useState("");
  const handleChangeModal = (e) => {
    if (!openUpdateModal) {
      setType(e);
      setOpenUpdateModal(!openUpdateModal);
    } else {
      setOpenUpdateModal(!openUpdateModal);
    }
  };

  const handleArrangeModal = (e) => {
    if (!openArrangeModal) {
      setType(e);
      setOpenArrangeModal(!openArrangeModal);
    } else {
      setOpenArrangeModal(!openArrangeModal);
    }
  };

  const handleSaveTemplate = async () => {
    setButtonSpinner(true);

    let hompageDesktopCarousel = homepage.desktopCarousel;
    let hompageMobileCarousel = homepage.mobileCarousel;
    let hompageNews = homepage.news;
    let homepageCourses = homepage.courses;

    const dataToSubmit = {
      desktopCarousel: JSON.stringify(hompageDesktopCarousel),
      mobileCarousel: JSON.stringify(hompageMobileCarousel),
      news: JSON.stringify(hompageNews),
      courses: JSON.stringify(homepageCourses),
    };

    dynamicHomepageActions.saveTemplate(
      dataToSubmit,
      setLoading,
      setButtonSpinner,
      setButtonSpinner2
    );
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  return (
    <div className="p-4">
      <Row>
        <Col md="6" xs="12" className="my-4" style={{ zIndex: "99999" }}>
          <Select
            options={versionSelection}
            name="version"
            className={isMobile ? "w-100" : isTablet ? "w-50" : "w-25"}
            onChange={(e) => {
              setLoading(true);
              setSelectedVersion(e.value);
              return e;
            }}
            defaultValue={
              selectedVersion != ""
                ? {
                    value: selectedVersion,
                    label: getOptionLabelByValue(
                      versionSelection,
                      selectedVersion
                    ),
                  }
                : ""
            }
            value={
              selectedVersion != ""
                ? {
                    value: selectedVersion,
                    label: getOptionLabelByValue(
                      versionSelection,
                      selectedVersion
                    ),
                  }
                : ""
            }
          />
          <Label className="text-secondary">Select Version</Label>
        </Col>
        <Col md="6" xs="12" className="w-100 ml-auto text-right my-4">
          <div>
            <Button
              className="mr-3 border-0 bg-danger-edit"
              onClick={handleSaveTemplate}
              disabled={buttonSpinner || buttonSpinner2 || loading}
            >
              {buttonSpinner ? (
                <>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  &nbsp;Processing...
                </>
              ) : (
                "Save"
              )}
            </Button>
            <Button
              onClick={handlePublishTemplate}
              color="success"
              disabled={buttonSpinner || buttonSpinner2 || loading}
            >
              {buttonSpinner2 ? (
                <>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  &nbsp;Processing...
                </>
              ) : (
                "Publish"
              )}
            </Button>
          </div>
          <Label className="font-weight-bolder text-secondary">
            Latest Version: {currentVersion}
          </Label>
        </Col>
      </Row>
      <Container
        fluid={true}
        className={isMobile ? "px-0" : "px-0 landingPage"}
      >
        <HomepageCarousel
          homepage={homepage}
          setHomepage={setHomepage}
          handleChangeModal={handleChangeModal}
          handleArrangeModal={handleArrangeModal}
          slidesDesktop={slidesDesktop}
          setSlidesDesktop={setSlidesDesktop}
          slidesMobile={slidesMobile}
          setSlidesMobile={setSlidesMobile}
        />

        <HomepageCourses
          homepage={homepage}
          setHomepage={setHomepage}
          handleChangeModal={handleChangeModal}
          handleArrangeModal={handleArrangeModal}
        />

        <HomepageNews
          homepage={homepage}
          setHomepage={setHomepage}
          handleChangeModal={handleChangeModal}
          handleArrangeModal={handleArrangeModal}
        />
      </Container>
    </div>
  );
};

export default Homepage;
