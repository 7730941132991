import React, { useState, useEffect } from "react";
import {
  Container,
  Collapse,
  Navbar,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Label,
  Card,
  Button,
  CardImg,
  CardText,
  CardDeck,
  CardBody,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  Modal,
  ModalBody,
} from "reactstrap";

import landingPageLogo from "../../../assets/img/landingPageLogo.png";
import carousel1 from "../../../assets/img/carousel-1.jpg";
import carousel2 from "../../../assets/img/carousel-2.jpg";
import carousel3 from "../../../assets/img/carousel-3.jpg";
import carouselMobile1 from "../../../assets/img/carousel-mobile-1.jpg";
import carouselMobile2 from "../../../assets/img/carousel-mobile-2.jpg";
import carouselMobile3 from "../../../assets/img/carousel-mobile-3.jpg";
import basic1 from "../../../assets/img/basic-1.jpg";
import basicGif from "../../../assets/img/basic-ed-gif.gif";
import tertiary1 from "../../../assets/img/tertiary-1.jpg";
import tertiaryGif from "../../../assets/img/tertiary-ed-gif.gif";
import tesda1 from "../../../assets/img/tesda-1.jpg";
import tesdaGif from "../../../assets/img/technical-ed-gif.gif";
import news1 from "../../../assets/img/news-1.png";
import news2 from "../../../assets/img/news-2.png";
import news3 from "../../../assets/img/news-3.png";
import news4 from "../../../assets/img/news-4.png";
import news5 from "../../../assets/img/news-5.png";
import NavbarMobile from "./NavbarMobile.js";
import NavbarMobileAuth from "./NavbarMobileAuth.js";
import Footer from "./Components/Footer";
import NavbarComponent from "./Components/Navbar.js";
import {
  isDesktop,
  isMobile,
  isTablet,
  isIOS,
  isAndroid,
} from "react-device-detect";
import { Fade, Slide } from "react-reveal";
import { dynamicHomepageActions } from "../../../services/DynamiHomepageServices";
import HomepageCarousel from "./HomepageCarousel";
import HomepageNews from "./HomepageNews";
import HomepageCourses from "./HomepageCourses";
import Loading from "../Forms/Loading";
import { useNavigate } from "react-router-dom";

const LandingPage = ({ authenticated }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [navbarMobile, setNavbarMobile] = useState(false);
  const [seeMore, setSeeMore] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDrop = (e) => setDropdownOpen(!dropdownOpen);
  const toggleDropClose = (e) => setDropdownOpen(false);

  const [loginDropdownOpen, setLoginDropdownOpen] = useState(false);
  const toggleDrop2 = (e) => setLoginDropdownOpen(!loginDropdownOpen);
  const toggleDropClose2 = (e) => setLoginDropdownOpen(false);

  const [lmsDropdownOpen, setLmsDropdownOpen] = useState(false);
  const toggleDrop3 = (e) => setLmsDropdownOpen(!lmsDropdownOpen);
  const toggleDropClose3 = (e) => setLmsDropdownOpen(false);

  const [basicModal, setBasicModal] = useState(false);
  const basicToggle = () => setBasicModal(!basicModal);

  const [tertiaryModal, setTertiaryModal] = useState(false);
  const tertiaryToggle = () => setTertiaryModal(!tertiaryModal);

  const [tesdaModal, setTesdaModal] = useState(false);
  const tesdaToggle = () => setTesdaModal(!tesdaModal);

  const [type, setType] = useState("");
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [openArrangeModal, setOpenArrangeModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [buttonSpinner2, setButtonSpinner2] = useState(false);
  const [currentVersion, setCurrentVersion] = useState("");
  const [versionSelection, setVersionSelection] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState("");

  const KeyCodes = { enter: 13 };
  const delimiters = [KeyCodes.enter];

  const [slidesMobile, setSlidesMobile] = useState([]);
  const [slidesDesktop, setSlidesDesktop] = useState([]);

  const [homepage, setHomepage] = useState({
    desktopCarousel: [
      {
        src: carousel1,
        imageFile: "",
        altText: "",
      },
      {
        src: carousel2,
        imageFile: "",
        altText: "",
      },
      {
        src: carousel3,
        imageFile: "",
        altText: "",
      },
    ],
    mobileCarousel: [
      {
        src: carouselMobile1,
        imageFile: "",
        altText: "",
      },
      {
        src: carouselMobile2,
        imageFile: "",
        altText: "",
      },
      {
        src: carouselMobile3,
        imageFile: "",
        altText: "",
      },
    ],
    courses: {
      basic: {
        image: basic1,
        imageFile: "",
        gif: basicGif,
        gifFile: "",
        videoUrl:
          "https://www.youtube.com/embed/c4T5BcVuoIQ?rel=0&amp;autoplay=1&mute=0",
        data: [
          {
            title: "",
            data: [
              {
                title: "Grade School",
                subtitle: "(Kinder to Grade 6)",
              },
              {
                title: "Junior High School",
                subtitle: "(Grade 7 to Grade 10)",
              },
              {
                title: "Senior High School",
                subtitle: "(Grade 11 to Grade 12)",
              },
            ],
          },
        ],
      },
      tertiary: {
        image: tertiary1,
        imageFile: "",
        gif: tertiaryGif,
        gifFile: "",
        videoUrl:
          "https://www.youtube.com/embed/LM74lJGfvHw?rel=0&amp;autoplay=1&mute=0",
        data: [
          {
            title: "",
            subtitle: "",
            data: [
              {
                title: "AB in Psychology",
                subtitle: "",
              },
              {
                title: "BS in Business Administration",
                subtitle: "",
              },
              {
                title: "BS in Hospitality Management",
                subtitle: "",
              },
              {
                title: "Associate in Office Management",
                subtitle: "",
              },
            ],
          },
        ],
      },
      technical: {
        image: tesda1,
        imageFile: "",
        gif: tesdaGif,
        gifFile: "",
        videoUrl:
          "https://www.youtube.com/embed/FxrptX88sk4?rel=0&amp;autoplay=1&mute=0",
        data: [
          {
            title: "Training Center",
            subtitle: "",
            data: [
              {
                title: "Bread and Pastry Production NC II",
                subtitle: "",
              },
              {
                title: "Caregiving NC II",
                subtitle: "",
              },
              {
                title: "Cookery NC II",
                subtitle: "",
              },
              {
                title: "Housekeeping NC II",
                subtitle: "",
              },
              {
                title: "Food and Beverage Services NC II",
                subtitle: "",
              },
              {
                title: "Shielded Metal Arc Welding (SMAW) NC I",
                subtitle: "",
              },
              {
                title: "Shielded Metal Arc Welding (SMAW) NC II",
                subtitle: "",
              },
            ],
          },
          {
            title: "Accredited Competency Assessment Center",
            subtitle: "",
            data: [
              {
                title: "Bread and Pastry Production NC II",
                subtitle: "",
              },
              {
                title: "Caregiving NC II",
                subtitle: "",
              },
              {
                title: "Cookery NC II",
                subtitle: "",
              },
              {
                title: "Housekeeping NC II",
                subtitle: "",
              },
              {
                title: "Food and Beverage Services NC II",
                subtitle: "",
              },
              {
                title: "Shielded Metal Arc Welding (SMAW) NC I",
                subtitle: "",
              },
              {
                title: "Shielded Metal Arc Welding (SMAW) NC II",
                subtitle: "",
              },
            ],
          },
        ],
      },
    },
    news: [
      {
        title: "Our Students Our pride!",
        image: news5,
        imageFile: "",
        url_link:
          "https://www.facebook.com/san.antonio.de.padua.college/posts/6485304558206706",
      },
      {
        title: "Activities for the Month of November",
        image: news4,
        imageFile: "",
        url_link:
          "https://www.facebook.com/327167530960899/posts/1568869503457356/",
      },
      {
        title: "TES Release",
        image: news3,
        imageFile: "",
        url_link:
          "https://www.facebook.com/163764627027429/posts/6366270160110147/",
      },
      {
        title: "Virtual Earthquake Drill",
        image: news2,
        imageFile: "",
        url_link:
          "https://www.facebook.com/163764627027429/posts/6314412225295941/",
      },
      {
        title: "Ms. World Tourism Philippines 2021!",
        image: news1,
        imageFile: "",
        url_link:
          "https://www.facebook.com/163764627027429/posts/6218140968256401/",
      },
    ],
  });

  useEffect(() => {
    setLoading(true);
    setCurrentVersion("");
    dynamicHomepageActions.getCurrentTemplateFrontPage(
      setHomepage,
      homepage,
      setCurrentVersion,
      setSelectedVersion,
      setLoading,
      setVersionSelection,
      carousel1,
      carousel2,
      carousel3,
      carouselMobile1,
      carouselMobile2,
      carouselMobile3,
      basic1,
      basicGif,
      tertiary1,
      tertiaryGif,
      tesda1,
      tesdaGif,
      news5,
      news4,
      news3,
      news2,
      news1
    );
  }, []);

  useEffect(() => {
    if (homepage.desktopCarousel.length >= 1) {
      let desktopSlides = homepage.desktopCarousel.map((item) => {
        return (
          <CarouselItem
            onExiting={() => setAnimating(true)}
            onExited={() => setAnimating(false)}
            key={item.src}
          >
            <img
              src={item.src}
              alt={item.altText}
              className="w-100"
              style={{ height: "100vh", objectFit: "cover" }}
            />
            {/* <CarouselCaption captionText={item.caption} captionHeader={item.caption} /> */}
          </CarouselItem>
        );
      });
      setSlidesDesktop(desktopSlides);
    }
  }, [homepage.desktopCarousel]);

  useEffect(() => {
    if (homepage.mobileCarousel.length >= 1) {
      let mobileSlides = homepage.mobileCarousel.map((item) => {
        return (
          <CarouselItem
            onExiting={() => setAnimating(true)}
            onExited={() => setAnimating(false)}
            key={item.src}
          >
            <img
              src={item.src}
              alt={item.altText}
              className="w-100"
              style={{ height: "100vh", objectFit: "cover" }}
            />
            {/* <CarouselCaption captionText={item.caption} captionHeader={item.caption} /> */}
          </CarouselItem>
        );
      });
      setSlidesMobile(mobileSlides);
    }
  }, [homepage.mobileCarousel]);

  useEffect(() => {
    document.title = "HOME | San Antonio de Padua College";
  }, []);

  const handleScroll = () => {
    setNavbarMobile(true);
  };

  return (
    <Container fluid={true} className={isIOS ? "px-0" : "px-0 landingPage"}>
      {loading ? <Loading /> : ""}
      {isDesktop ? (
        <Navbar expand="md lg" className="w-100 py-0 navbarClass">
          <Collapse isOpen={isOpen} navbar>
            <Nav
              navbar
              className="mr-auto justify-content-between w-100 landingPageNav"
            >
              <NavItem className="w-100 text-center h-100">
                <NavLink
                  className="navlinkButton font-weight-bolder px-5"
                  onClick={() => navigate("/")}
                >
                  HOME
                </NavLink>
              </NavItem>
              <NavItem className="w-100 text-center h-100">
                <NavLink
                  className="navlinkButton font-weight-bolder px-4"
                  onClick={() => navigate("/about-us")}
                >
                  ABOUT US
                </NavLink>
              </NavItem>
              <NavItem className="w-100 text-center h-100">
                <NavLink
                  className="navlinkButton font-weight-bolder px-5"
                  onClick={() => navigate("/academics")}
                >
                  ACADEMICS
                </NavLink>
              </NavItem>
              <div className="w-100 d-flex justify-content-center">
                <img
                  src={landingPageLogo}
                  className="landingPageLogo"
                  style={{ width: "10rem" }}
                />
              </div>
              <Dropdown
                nav
                isOpen={dropdownOpen}
                onMouseEnter={toggleDrop}
                onMouseLeave={toggleDropClose}
                className="w-100 text-center h-100"
              >
                <DropdownToggle
                  nav
                  caret
                  className="navlinkButton font-weight-bolder px-5"
                >
                  ADMISSION
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem
                    className="font-weight-bolder text-danger-edit"
                    onClick={() => navigate("/admission-page")}
                  >
                    REQUIREMENTS
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem
                    className="font-weight-bolder text-danger-edit"
                    onClick={() => navigate("/scholarship-page")}
                  >
                    SCHOLARSHIP
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
              <Dropdown
                nav
                isOpen={lmsDropdownOpen}
                onMouseEnter={toggleDrop3}
                onMouseLeave={toggleDropClose3}
                className="w-100 text-center h-100"
              >
                <DropdownToggle
                  nav
                  caret
                  className="navlinkButton font-weight-bolder px-5"
                >
                  LMS
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem
                    className="font-weight-bolder text-danger-edit"
                    onClick={() =>
                      navigate(
                        window.open(
                          "http://52.74.221.30/sapc/?fbclid=IwAR0Cb6NDp56odfRENGOl1Tr47ztbf0Ac38ZyBHCWCfIIyg2-ZcbmTvePYtw",
                          "_blank"
                        )
                      )
                    }
                  >
                    LIKHA ONLINE
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem
                    className="font-weight-bolder text-danger-edit"
                    onClick={() =>
                      navigate(
                        window.open(
                          "https://app.seesaw.me/#/student/login?fbclid=IwAR2GIW7rGIvu4SWjVDB2gmPe7riyw3smZnfGhWYOE-fDhw22Fr_RI3KUXX8",
                          "_blank"
                        )
                      )
                    }
                  >
                    SEESAW
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem
                    className="font-weight-bolder text-danger-edit"
                    onClick={() =>
                      navigate(window.open("https://www.canva.com/", "_blank"))
                    }
                  >
                    CANVA FOR EDUCATION
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>

              <Dropdown
                nav
                isOpen={loginDropdownOpen}
                onMouseEnter={toggleDrop2}
                onMouseLeave={toggleDropClose2}
                className="w-100 text-center h-100"
              >
                <DropdownToggle
                  nav
                  caret
                  className="navlinkButton font-weight-bolder px-5"
                >
                  LOGIN
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem
                    className="font-weight-bolder text-danger-edit"
                    onClick={() =>
                      navigate(window.open("http://mail.sapc.edu.ph/"))
                    }
                  >
                    UGNAI
                  </DropdownItem>
                  <DropdownItem divider />
                  {authenticated ? (
                    <DropdownItem
                      className="font-weight-bolder text-danger-edit"
                      onClick={() => navigate("/portal")}
                    >
                      PORTAL
                    </DropdownItem>
                  ) : (
                    <DropdownItem
                      className="font-weight-bolder text-danger-edit"
                      onClick={() => navigate("/login")}
                    >
                      SINAG
                    </DropdownItem>
                  )}
                  <DropdownItem divider />
                  <DropdownItem
                    className="font-weight-bolder text-danger-edit"
                    onClick={() =>
                      navigate(window.open("https://sapc.ec1schoolsystems.com"))
                    }
                  >
                    SASS
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </Nav>
          </Collapse>
        </Navbar>
      ) : (
        ""
      )}

      {/* <NavbarComponent/> */}

      {isMobile ? (
        navbarMobile ? (
          authenticated ? (
            <NavbarMobileAuth
              setNavbarMobile={setNavbarMobile}
              navbarMobile={navbarMobile}
            />
          ) : (
            <NavbarMobile
              setNavbarMobile={setNavbarMobile}
              navbarMobile={navbarMobile}
            />
          )
        ) : (
          ""
        )
      ) : (
        ""
      )}
      {isMobile ? (
        <div
          onClick={handleScroll}
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            zIndex: "9999",
          }}
        >
          <div className="bar-menu"></div>
          <div className="bar-menu"></div>
          <div className="bar-menu"></div>
        </div>
      ) : isTablet ? (
        <div
          onClick={handleScroll}
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            zIndex: "9999",
          }}
        >
          <div className="bar-menu"></div>
          <div className="bar-menu"></div>
          <div className="bar-menu"></div>
        </div>
      ) : (
        ""
      )}

      <Fade>
        <Row
          lg="12"
          md="12"
          xs="12"
          className="px-0 h-100 mx-0 w-100 justify-content-center align-items-center d-flex"
          style={{ objectFit: "cover" }}
        >
          <Col className="px-0 h-100 mx-0 w-100">
            <HomepageCarousel
              homepage={homepage}
              slidesDesktop={slidesDesktop}
              slidesMobile={slidesMobile}
            />

            <HomepageCourses
              homepage={homepage}
              authenticated={authenticated}
            />

            <HomepageNews homepage={homepage} />
          </Col>
        </Row>
      </Fade>
      <Footer />
    </Container>
  );
};

export default LandingPage;
