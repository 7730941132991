import React, { useState } from 'react';
import { Button, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, FormGroup, Form, Spinner, Input, CardBody, CardTitle, Card, CardText, Table } from 'reactstrap';
import "@kenshooui/react-multi-select/dist/style.css";

const ViewUser = ({
    viewModal, 
    toggleView,
    fee,
}) => {
    
    const basic = [1,2,3,4,5,6,7,8,9,10,11,12,13,14];

    return (
        <Modal isOpen={viewModal} toggle={toggleView} size="lg" backdrop="static">
        <ModalHeader toggle={toggleView} className="bg-danger-edit text-white" charCode="">View Fee</ModalHeader>
        <ModalBody>
            <Card>
              <CardBody>
                <CardTitle tag="h5" className="mb-3 text-danger-edit">{fee.grade_level}</CardTitle>
                <CardText><span className="font-weight-bolder">Tuition Fee</span> {fee.tuition_fee}</CardText>
                <CardText><span className="font-weight-bolder">Miscellaneous Fee:</span> {fee.miscellaneous_fee}</CardText>
                <CardText><span className="font-weight-bolder">Other Fee:</span> {fee.other_fee}</CardText>
              </CardBody>
              <Table striped bordered>
                <thead>
                  <tr>
                    <th>Payment Mode</th>
                    <th>Tuition Fee</th>
                    <th>Miscellaneous Fee</th>
                    <th>Other Fee</th>
                    <th>Payment Upon Enrollment</th>
                    <th>Payment Per Billing</th>
                    <th>Payment Due</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                      <td>{basic.includes(fee.grade_level_id) ? "Annual" : "Full Payment"}</td>
                      <td>{fee.payment_mode.annual ? fee.payment_mode.annual.tuition_fee : "--"}</td>
                      <td>{fee.payment_mode.annual ? fee.payment_mode.annual.miscellaneous_fee : "--"}</td>
                      <td>{fee.payment_mode.annual ? fee.payment_mode.annual.other_fee : "--"}</td>
                      <td>{fee.payment_mode.annual ? fee.payment_mode.annual.payment_upon_enrollment : "--"}</td>
                      <td>{fee.payment_mode.annual ? fee.payment_mode.annual.per_billing : "--"}</td>
                      <td>{fee.payment_mode.annual ? fee.payment_mode.annual.payment_due : "--"}</td>
                  </tr>
                  {basic.includes(fee.grade_level_id) ?
                    <tr>
                        <td>Semestral</td>
                        <td>{fee.payment_mode.semestral ? fee.payment_mode.semestral.tuition_fee : "--"}</td>
                        <td>{fee.payment_mode.semestral ? fee.payment_mode.semestral.miscellaneous_fee : "--"}</td>
                        <td>{fee.payment_mode.semestral ? fee.payment_mode.semestral.other_fee : "--"}</td>
                        <td>{fee.payment_mode.semestral ? fee.payment_mode.semestral.payment_upon_enrollment : "--"}</td>
                        <td>{fee.payment_mode.semestral ? fee.payment_mode.semestral.per_billing : "--"}</td>
                        <td>{fee.payment_mode.semestral ? fee.payment_mode.semestral.payment_due : "--"}</td>
                    </tr>
                    : <tr></tr>
                  }
                  <tr>
                      <td>Monthly A</td>
                      <td>{fee.payment_mode.monthly_a ? fee.payment_mode.monthly_a.tuition_fee : "--"}</td>
                      <td>{fee.payment_mode.monthly_a ? fee.payment_mode.monthly_a.miscellaneous_fee : "--"}</td>
                      <td>{fee.payment_mode.monthly_a ? fee.payment_mode.monthly_a.other_fee : "--"}</td>
                      <td>{fee.payment_mode.monthly_a ? fee.payment_mode.monthly_a.payment_upon_enrollment : "--"}</td>
                      <td>{fee.payment_mode.monthly_a ? fee.payment_mode.monthly_a.per_billing : "--"}</td>
                      <td>{fee.payment_mode.monthly_a ? fee.payment_mode.monthly_a.payment_due : "--"}</td>
                  </tr>
                    <tr>
                        <td>Monthly B</td>
                        <td>{fee.payment_mode.monthly_b ? fee.payment_mode.monthly_b.tuition_fee : "--"}</td>
                        <td>{fee.payment_mode.monthly_b ? fee.payment_mode.monthly_b.miscellaneous_fee : "--"}</td>
                        <td>{fee.payment_mode.monthly_b ? fee.payment_mode.monthly_b.other_fee : "--"}</td>
                        <td>{fee.payment_mode.monthly_b ? fee.payment_mode.monthly_b.payment_upon_enrollment : "--"}</td>
                        <td>{fee.payment_mode.monthly_b ? fee.payment_mode.monthly_b.per_billing : "--"}</td>
                        <td>{fee.payment_mode.monthly_b ? fee.payment_mode.monthly_b.payment_due : "--"}</td>
                    </tr>
                </tbody>
              </Table>
            </Card>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleView}>Close</Button>
        </ModalFooter>
      </Modal>
    )
}
export default ViewUser;