import React, { useEffect, useState } from "react";
import {
  Container,
  Collapse,
  Navbar,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Label,
  Card,
  Button,
  CardImg,
  CardText,
  CardDeck,
  CardBody,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  Modal,
  ModalBody,
  Spinner,
  Fade,
  ModalHeader,
  Input,
  ModalFooter,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import landingPageLogo from "../../../../assets/img/landingPageLogo.png";
import sapcYellow from "../../../../assets/img/SAPC-Logo-yellow.png";
import flatEllipse from "../../../../assets/img/flat-ellipse.png";
import facebookIcon from "../../../../assets/img/facebook-icon.png";
import twitterIcon from "../../../../assets/img/twitter-icon.png";
import {
  isBrowser,
  isDesktop,
  isIOS,
  isTablet,
  isMobile,
} from "react-device-detect";
import Select from "react-select";
import { dynamicFooterActions } from "../../../../services/DynamicFooterServices";

const Footer = () => {
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [buttonSpinner2, setButtonSpinner2] = useState(false);
  const [currentVersion, setCurrentVersion] = useState("");
  const [versionSelection, setVersionSelection] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState("");

  const getOptionLabelByValue = (options, value) => {
    const option = options.find((option) => option.value == value);
    return option ? option.label : "";
  };

  useEffect(() => {
    setLoading(true);
    setCurrentVersion("");
    dynamicFooterActions.getCurrentTemplate(
      setFooter,
      footer,
      setCurrentVersion,
      setSelectedVersion,
      setLoading,
      setVersionSelection
    );
  }, []);

  useEffect(() => {
    if (selectedVersion != "") {
      dynamicFooterActions.getSelectedTemplate(
        setFooter,
        footer,
        setCurrentVersion,
        selectedVersion,
        setLoading,
        setVersionSelection
      );
    }
  }, [selectedVersion]);

  const toggleUpdateFooter = () => {
    if (!openUpdateModal) {
      setOpenUpdateModal(!openUpdateModal);
    } else {
      setOpenUpdateModal(!openUpdateModal);
    }
  };

  const handlePublishTemplate = () => {
    setButtonSpinner2(true);
    dynamicFooterActions.publishTemplate(
      selectedVersion,
      setButtonSpinner,
      setLoading,
      setButtonSpinner2
    );
  };

  const [footer, setFooter] = useState({
    school_name: "SAN ANTONIO DE PADUA COLLEGE",
    sub_school_name: "Foundation of Pila, Laguna, Inc.",
    inquiries_title: "For inquiries and information contact",
    inquiries_mobile_no: "",
    inquiries_mobile_no_2: "",
    inquiries_mobile_no_3: "",
    inquiries_email: "info@sapc.edu.ph",
    inquiries_phone_no: "(049)559-0501",
    school_registrar_title: "School registrar",
    school_registrar_mobile_no: "",
    school_registrar_mobile_no_2: "",
    school_registrar_mobile_no_3: "",
    school_registrar_email: "registrar@sapc.edu.ph",
    school_registrar_phone_no: "(049)566-7258",
    billing_title: "Billing and payments",
    billing_mobile_no: "0917-703-9130",
    billing_mobile_no_2: "",
    billing_mobile_no_3: "",
    billing_email: "cashier@sapc.edu.ph",
    billing_phone_no: "(049)559-0501",
    security_title: "Security services",
    security_mobile_no: "0917-144-6920 (calls only)",
    security_mobile_no_2: "",
    security_mobile_no_3: "",
    security_email: "",
    security_phone_no: "",
    admission_title: "Admissions",
    admission_mobile_no: "0920-903-1564",
    admission_mobile_no_2: "0956-816-3185",
    admission_mobile_no_3: "",
    admission_email: "admissions@sapc.edu.ph",
    admission_phone_no: "(049)831-7954",
    mailing_address_title: "Mailing address",
    mailing_address_address:
      "National Highway, Barangay Santa Clara Sur, Pila, Laguna, Philippines 4010",
    social_media_link_facebook:
      "https://www.facebook.com/san.antonio.de.padua.college",
    social_media_link_twitter: "https://twitter.com/sapcfoundation",
    sapc_domain: "sapc.edu.ph",
    sapc_description:
      "All website content is owned and copyrighted by San Antonio De Padua College Foundation, Inc. and/or its respective source unless otherwise stated.",
  });

  const handleSaveTemplate = () => {
    setButtonSpinner(true);
    let dataToSubmit = { footer: JSON.stringify(footer) };
    dynamicFooterActions.saveTemplate(
      dataToSubmit,
      setLoading,
      setButtonSpinner,
      setButtonSpinner2
    );
  };

  return (
    <div className="p-4">
      <Row>
        <Col md="6" xs="12" className="my-4" style={{ zIndex: "9" }}>
          <Select
            options={versionSelection}
            name="version"
            className={isMobile ? "w-100" : isTablet ? "w-50" : "w-25"}
            onChange={(e) => {
              setLoading(true);
              setSelectedVersion(e.value);
              return e;
            }}
            defaultValue={
              selectedVersion != ""
                ? {
                    value: selectedVersion,
                    label: getOptionLabelByValue(
                      versionSelection,
                      selectedVersion
                    ),
                  }
                : ""
            }
            value={
              selectedVersion != ""
                ? {
                    value: selectedVersion,
                    label: getOptionLabelByValue(
                      versionSelection,
                      selectedVersion
                    ),
                  }
                : ""
            }
          />
          <Label className="text-secondary">Select Version</Label>
        </Col>
        <Col md="6" xs="12" className="w-100 ml-auto text-right my-4">
          <div>
            <Button
              className="mr-3 border-0 bg-danger-edit"
              onClick={handleSaveTemplate}
              disabled={buttonSpinner || buttonSpinner2 || loading}
            >
              {buttonSpinner ? (
                <>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  &nbsp;Processing...
                </>
              ) : (
                "Save"
              )}
            </Button>
            <Button
              onClick={handlePublishTemplate}
              color="success"
              disabled={buttonSpinner || buttonSpinner2 || loading}
            >
              {buttonSpinner2 ? (
                <>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  &nbsp;Processing...
                </>
              ) : (
                "Publish"
              )}
            </Button>
          </div>
          <Label className="font-weight-bolder text-secondary">
            Latest Version: {currentVersion}
          </Label>
        </Col>
      </Row>

      <div>
        {isDesktop ? (
          <img
            src={landingPageLogo}
            className="footerLogoSAPC"
            style={{ width: "265px", marginLeft: "-12px" }}
          />
        ) : (
          ""
        )}
        <Row className="mx-0 px-0 bg-danger-edit h-100 justify-content-center d-flex align-items-center text-center py-5">
          {isMobile ? (
            <img
              src={sapcYellow}
              className={isMobile ? "footerLogo pb-4" : "footerLogo"}
              style={isMobile ? { width: "100px" } : { width: "150px" }}
            />
          ) : (
            ""
          )}

          <Col lg="3" md="3" xs="12" className={isDesktop ? "ml-5" : "pb-5"}>
            <div className="d-flex flex-column align-items-center justify-content-center">
              <p className="footer-sapc mb-0 text-light">
                {footer.school_name}
              </p>
              <img src={flatEllipse} className="w-100" />
              <p className="footer-foundation text-light my-0">
                {footer.sub_school_name}
              </p>
            </div>
          </Col>

          <Col lg="2" md="2" xs="12" className={isDesktop ? "pb-3" : "pb-5"}>
            <span className="footer-foundation text-light my-0 font-weight-bolder">
              <strong>GET IN TOUCH</strong>
            </span>
            <br />
            <a href={footer.social_media_link_facebook} target="_blank">
              <img
                src={facebookIcon}
                style={{ width: "40px" }}
                className="my-1 mx-1 zooming-transition"
              />
            </a>
            <a href={footer.social_media_link_twitter} target="_blank">
              <img
                src={twitterIcon}
                style={{ width: "40px" }}
                className="my-1 mx-1 zooming-transition"
              />
            </a>
          </Col>

          <Col
            lg="4"
            md="4"
            xs="12"
            className={isDesktop ? "text-left py-2" : "text-center py-2"}
          >
            <span className="footer-foundation text-warning-edit my-0">
              <strong>&#169; {footer.sapc_domain}</strong>
            </span>
            <br />
            <span className="footer-foundation text-light my-0">
              {footer.sapc_description}
            </span>
          </Col>
        </Row>

        <Row className="mx-0 px-0 bg-danger-edit h-100 justify-content-center d-flex">
          <Col lg="2" md="2" xs="12" className={isDesktop ? "pb-3" : "pb-3"}>
            <span className="footer-foundation text-light my-0 font-weight-bolder ">
              <strong>CONTACT US:</strong>
            </span>
            <br />
            <br />
            <span className="footer-foundation text-light my-0">
              <strong>{footer.inquiries_title}:</strong>
              {footer.inquiries_email ? (
                <p className="mb-0">{footer.inquiries_email}</p>
              ) : (
                ""
              )}
              {footer.inquiries_mobile_no ? (
                <p className="mb-0">{footer.inquiries_mobile_no}</p>
              ) : (
                ""
              )}
              {footer.inquiries_mobile_no_2 ? (
                <p className="mb-0">{footer.inquiries_mobile_no_2}</p>
              ) : (
                ""
              )}
              {footer.inquiries_mobile_no_3 ? (
                <p className="mb-0">{footer.inquiries_mobile_no_3}</p>
              ) : (
                ""
              )}
              {footer.inquiries_phone_no ? (
                <p>{footer.inquiries_phone_no}</p>
              ) : (
                ""
              )}

              <strong>{footer.school_registrar_title}:</strong>
              {footer.school_registrar_email ? (
                <p className="mb-0">{footer.school_registrar_email}</p>
              ) : (
                ""
              )}
              {footer.school_registrar_mobile_no ? (
                <p className="mb-0">{footer.school_registrar_mobile_no}</p>
              ) : (
                ""
              )}
              {footer.school_registrar_mobile_no_2 ? (
                <p className="mb-0">{footer.school_registrar_mobile_no_2}</p>
              ) : (
                ""
              )}
              {footer.school_registrar_mobile_no_3 ? (
                <p className="mb-0">{footer.school_registrar_mobile_no_3}</p>
              ) : (
                ""
              )}
              {footer.school_registrar_phone_no ? (
                <p>{footer.school_registrar_phone_no}</p>
              ) : (
                ""
              )}
            </span>
          </Col>

          <Col lg="2" md="2" xs="12" className={isDesktop ? "pb-3" : "pb-3"}>
            <br />
            <br />
            <span className="footer-foundation text-light my-0">
              <strong>{footer.billing_title}:</strong>
              {footer.billing_email ? (
                <p className="mb-0">{footer.billing_email}</p>
              ) : (
                ""
              )}
              {footer.billing_mobile_no ? (
                <p className="mb-0">{footer.billing_mobile_no}</p>
              ) : (
                ""
              )}
              {footer.billing_mobile_no_2 ? (
                <p className="mb-0">{footer.billing_mobile_no_2}</p>
              ) : (
                ""
              )}
              {footer.billing_mobile_no_3 ? (
                <p className="mb-0">{footer.billing_mobile_no_3}</p>
              ) : (
                ""
              )}
              {footer.billing_phone_no ? <p>{footer.billing_phone_no}</p> : ""}

              <strong>{footer.security_title}:</strong>
              {footer.security_email ? (
                <p className="mb-0">{footer.security_email}</p>
              ) : (
                ""
              )}
              {footer.security_mobile_no ? (
                <p className="mb-0">{footer.security_mobile_no}</p>
              ) : (
                ""
              )}
              {footer.security_mobile_no_2 ? (
                <p className="mb-0">{footer.security_mobile_no_2}</p>
              ) : (
                ""
              )}
              {footer.security_mobile_no_3 ? (
                <p className="mb-0">{footer.security_mobile_no_3}</p>
              ) : (
                ""
              )}
              {footer.security_phone_no ? (
                <p>{footer.security_phone_no}</p>
              ) : (
                ""
              )}
            </span>
          </Col>

          <Col lg="2" md="2" xs="12" className={isDesktop ? "pb-3" : "pb-3"}>
            <br />
            <br />
            <span className="footer-foundation text-light my-0">
              <strong>{footer.admission_title}:</strong>
              {footer.admission_email ? (
                <p className="mb-0">{footer.admission_email}</p>
              ) : (
                ""
              )}
              {footer.admission_mobile_no ? (
                <p className="mb-0">{footer.admission_mobile_no}</p>
              ) : (
                ""
              )}
              {footer.admission_mobile_no_2 ? (
                <p className="mb-0">{footer.admission_mobile_no_2}</p>
              ) : (
                ""
              )}
              {footer.admission_mobile_no_3 ? (
                <p className="mb-0">{footer.admission_mobile_no_3}</p>
              ) : (
                ""
              )}
              {footer.admission_phone_no ? (
                <p>{footer.admission_phone_no}</p>
              ) : (
                ""
              )}
            </span>
          </Col>

          <Col lg="2" md="2" xs="12" className={isDesktop ? "pb-3" : "pb-5"}>
            <br />
            <br />
            <span className="footer-foundation text-light my-0">
              {footer.mailing_address_title ? (
                <strong>{footer.mailing_address_title}</strong>
              ) : (
                ""
              )}
              {footer.mailing_address_address ? (
                <p>{footer.mailing_address_address}</p>
              ) : (
                ""
              )}
            </span>
          </Col>
        </Row>
      </div>
      <div className="my-4">
        <Button onClick={() => toggleUpdateFooter()}>UPDATE FOOTER</Button>
      </div>
      <Modal isOpen={openUpdateModal} backdrop="static" size="xl">
        <ModalHeader>Update Footer</ModalHeader>
        <ModalBody>
          <Row>
            <Col xs="12" md="12">
              <h5 className="font-weight-bolder">School Information</h5>
            </Col>
            <Col xs="12" md="12" className="mb-3">
              <Label>School Name</Label>
              <Input
                defaultValue={footer.school_name}
                onChange={(e) =>
                  setFooter({ ...footer, school_name: e.target.value })
                }
              />
            </Col>
            <Col xs="12" md="12" className="mb-5">
              <Label>Sub School Name</Label>
              <Input
                defaultValue={footer.sub_school_name}
                onChange={(e) =>
                  setFooter({ ...footer, sub_school_name: e.target.value })
                }
              />
            </Col>

            <Col xs="12" md="12">
              <h5 className="font-weight-bolder">Social Media Link</h5>
            </Col>
            <Col xs="12" md="12" className="mb-3">
              <Label>Facebook</Label>
              <Input
                defaultValue={footer.social_media_link_facebook}
                onChange={(e) =>
                  setFooter({
                    ...footer,
                    social_media_link_facebook: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs="12" md="12" className="mb-5">
              <Label>Twitter</Label>
              <Input
                defaultValue={footer.social_media_link_twitter}
                onChange={(e) =>
                  setFooter({
                    ...footer,
                    social_media_link_twitter: e.target.value,
                  })
                }
              />
            </Col>

            <Col xs="12" md="12">
              <h5 className="font-weight-bolder">SAPC Domain</h5>
            </Col>
            <Col xs="12" md="12" className="mb-3">
              <Label>Domain</Label>
              <Input
                defaultValue={footer.sapc_domain}
                onChange={(e) =>
                  setFooter({ ...footer, sapc_domain: e.target.value })
                }
              />
            </Col>
            <Col xs="12" md="12" className="mb-5">
              <Label>Description</Label>
              <Input
                type="textarea"
                defaultValue={footer.sapc_description}
                onChange={(e) =>
                  setFooter({ ...footer, sapc_description: e.target.value })
                }
              />
            </Col>

            <Col xs="12" md="12">
              <h5 className="font-weight-bolder mb-3">Contact Us</h5>
            </Col>
            <Col xs="12" md="12" className="mb-3">
              <Label>Inquiries Title</Label>
              <Input
                defaultValue={footer.inquiries_title}
                onChange={(e) =>
                  setFooter({ ...footer, inquiries_title: e.target.value })
                }
              />
            </Col>
            <Col xs="12" md="12" className="mb-3">
              <Label>Inquiries Email</Label>
              <Input
                defaultValue={footer.inquiries_email}
                onChange={(e) =>
                  setFooter({ ...footer, inquiries_email: e.target.value })
                }
              />
            </Col>
            <Col xs="12" md="12" className="mb-3">
              <Label>Inquiries Mobile Number 1</Label>
              <Input
                defaultValue={footer.inquiries_mobile_no}
                onChange={(e) =>
                  setFooter({ ...footer, inquiries_mobile_no: e.target.value })
                }
              />
            </Col>
            <Col xs="12" md="12" className="mb-3">
              <Label>Inquiries Mobile Number 2</Label>
              <Input
                defaultValue={footer.inquiries_mobile_no_2}
                onChange={(e) =>
                  setFooter({
                    ...footer,
                    inquiries_mobile_no_2: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs="12" md="12" className="mb-3">
              <Label>Inquiries Mobile Number 3</Label>
              <Input
                defaultValue={footer.inquiries_mobile_no_3}
                onChange={(e) =>
                  setFooter({
                    ...footer,
                    inquiries_mobile_no_3: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs="12" md="12" className="mb-5">
              <Label>Inquiries Phone number</Label>
              <Input
                defaultValue={footer.inquiries_phone_no}
                onChange={(e) =>
                  setFooter({ ...footer, inquiries_phone_no: e.target.value })
                }
              />
            </Col>

            <Col xs="12" md="12" className="mb-3">
              <Label>Registrar Title</Label>
              <Input
                defaultValue={footer.school_registrar_title}
                onChange={(e) =>
                  setFooter({
                    ...footer,
                    school_registrar_title: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs="12" md="12" className="mb-3">
              <Label>Registrar Email</Label>
              <Input
                defaultValue={footer.school_registrar_email}
                onChange={(e) =>
                  setFooter({
                    ...footer,
                    school_registrar_email: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs="12" md="12" className="mb-3">
              <Label>Registrar Mobile Number 1</Label>
              <Input
                defaultValue={footer.school_registrar_mobile_no}
                onChange={(e) =>
                  setFooter({
                    ...footer,
                    school_registrar_mobile_no: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs="12" md="12" className="mb-3">
              <Label>Registrar Mobile Number 2</Label>
              <Input
                defaultValue={footer.school_registrar_mobile_no_2}
                onChange={(e) =>
                  setFooter({
                    ...footer,
                    school_registrar_mobile_no_2: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs="12" md="12" className="mb-3">
              <Label>Registrar Mobile Number 3</Label>
              <Input
                defaultValue={footer.school_registrar_mobile_no_3}
                onChange={(e) =>
                  setFooter({
                    ...footer,
                    school_registrar_mobile_no_3: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs="12" md="12" className="mb-5">
              <Label>Registrar Phone number</Label>
              <Input
                defaultValue={footer.school_registrar_phone_no}
                onChange={(e) =>
                  setFooter({
                    ...footer,
                    school_registrar_phone_no: e.target.value,
                  })
                }
              />
            </Col>

            <Col xs="12" md="12" className="mb-3">
              <Label>Billing Title</Label>
              <Input
                defaultValue={footer.billing_title}
                onChange={(e) =>
                  setFooter({ ...footer, billing_title: e.target.value })
                }
              />
            </Col>
            <Col xs="12" md="12" className="mb-3">
              <Label>Billing Email</Label>
              <Input
                defaultValue={footer.billing_email}
                onChange={(e) =>
                  setFooter({ ...footer, billing_email: e.target.value })
                }
              />
            </Col>
            <Col xs="12" md="12" className="mb-3">
              <Label>Billing Mobile Number 1</Label>
              <Input
                defaultValue={footer.billing_mobile_no}
                onChange={(e) =>
                  setFooter({ ...footer, billing_mobile_no: e.target.value })
                }
              />
            </Col>
            <Col xs="12" md="12" className="mb-3">
              <Label>Billing Mobile Number 2</Label>
              <Input
                defaultValue={footer.billing_mobile_no_2}
                onChange={(e) =>
                  setFooter({ ...footer, billing_mobile_no_2: e.target.value })
                }
              />
            </Col>
            <Col xs="12" md="12" className="mb-3">
              <Label>Billing Mobile Number 3</Label>
              <Input
                defaultValue={footer.billing_mobile_no_3}
                onChange={(e) =>
                  setFooter({ ...footer, billing_mobile_no_3: e.target.value })
                }
              />
            </Col>
            <Col xs="12" md="12" className="mb-5">
              <Label>Billing Phone number</Label>
              <Input
                defaultValue={footer.billing_phone_no}
                onChange={(e) =>
                  setFooter({ ...footer, billing_phone_no: e.target.value })
                }
              />
            </Col>

            <Col xs="12" md="12" className="mb-3">
              <Label>Security Title</Label>
              <Input
                defaultValue={footer.security_title}
                onChange={(e) =>
                  setFooter({ ...footer, security_title: e.target.value })
                }
              />
            </Col>
            <Col xs="12" md="12" className="mb-3">
              <Label>Security Email</Label>
              <Input
                defaultValue={footer.security_email}
                onChange={(e) =>
                  setFooter({ ...footer, security_email: e.target.value })
                }
              />
            </Col>
            <Col xs="12" md="12" className="mb-3">
              <Label>Security Mobile Number 1</Label>
              <Input
                defaultValue={footer.security_mobile_no}
                onChange={(e) =>
                  setFooter({ ...footer, security_mobile_no: e.target.value })
                }
              />
            </Col>
            <Col xs="12" md="12" className="mb-3">
              <Label>Security Mobile Number 2</Label>
              <Input
                defaultValue={footer.security_mobile_no_2}
                onChange={(e) =>
                  setFooter({ ...footer, security_mobile_no_2: e.target.value })
                }
              />
            </Col>
            <Col xs="12" md="12" className="mb-3">
              <Label>Security Mobile Number 3</Label>
              <Input
                defaultValue={footer.security_mobile_no_3}
                onChange={(e) =>
                  setFooter({ ...footer, security_mobile_no_3: e.target.value })
                }
              />
            </Col>
            <Col xs="12" md="12" className="mb-5">
              <Label>Billing Phone number</Label>
              <Input
                defaultValue={footer.billing_phone_no}
                onChange={(e) =>
                  setFooter({ ...footer, billing_phone_no: e.target.value })
                }
              />
            </Col>

            <Col xs="12" md="12" className="mb-3">
              <Label>Admission Title</Label>
              <Input
                defaultValue={footer.security_title}
                onChange={(e) =>
                  setFooter({ ...footer, security_title: e.target.value })
                }
              />
            </Col>
            <Col xs="12" md="12" className="mb-3">
              <Label>Admission Email</Label>
              <Input
                defaultValue={footer.admission_email}
                onChange={(e) =>
                  setFooter({ ...footer, admission_email: e.target.value })
                }
              />
            </Col>
            <Col xs="12" md="12" className="mb-3">
              <Label>Admission Mobile Number 1</Label>
              <Input
                defaultValue={footer.admission_mobile_no}
                onChange={(e) =>
                  setFooter({ ...footer, admission_mobile_no: e.target.value })
                }
              />
            </Col>
            <Col xs="12" md="12" className="mb-3">
              <Label>Admission Mobile Number 2</Label>
              <Input
                defaultValue={footer.admission_mobile_no_2}
                onChange={(e) =>
                  setFooter({
                    ...footer,
                    admission_mobile_no_2: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs="12" md="12" className="mb-3">
              <Label>Admission Mobile Number 3</Label>
              <Input
                defaultValue={footer.admission_mobile_no_3}
                onChange={(e) =>
                  setFooter({
                    ...footer,
                    admission_mobile_no_3: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs="12" md="12" className="mb-5">
              <Label>Admission Phone number</Label>
              <Input
                defaultValue={footer.admission_phone_no}
                onChange={(e) =>
                  setFooter({ ...footer, admission_phone_no: e.target.value })
                }
              />
            </Col>

            <Col xs="12" md="12" className="mb-3">
              <Label>SAPC Mailing Address Title</Label>
              <Input
                defaultValue={footer.mailing_address_title}
                onChange={(e) =>
                  setFooter({
                    ...footer,
                    mailing_address_title: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs="12" md="12" className="mb-3">
              <Label>SAPC Mailing Address</Label>
              <Input
                type="textarea"
                defaultValue={footer.mailing_address_address}
                onChange={(e) =>
                  setFooter({
                    ...footer,
                    mailing_address_address: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => setOpenUpdateModal(!openUpdateModal)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Footer;
