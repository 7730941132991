import { ENDPOINT } from "../constants/api";
import axios from "axios";
import { notifyError, notifySuccess } from "./Toaster";
import moment from "moment";
import Subjects from "../views/Pages/Forms/Subjects";

export const teacherSubjectsActions = {
  getTeachers,
  updateTeacherSubject,
  getTeacherSubjectSelection
};

const token = localStorage.getItem("jwtToken")
  ? localStorage.getItem("jwtToken")
  : "";
const headers = {
  Authorization: `Bearer ${token}`,
  "Content-Type": "application/json",
  "X-Requested-With": "XMLHttpRequest",
};

async function getTeachers(setData, setLoading, setTeachersSelection, setSectionSelection, setSubjectSelection) {
  try {
    let res = await axios.get(ENDPOINT + `/get-teacher-subject`, {
      headers: headers,
    });
    const resData = (res.data.data.teacher_subjects[0]);
    const teacher_subject = resData.teacher_subject;
    const allSubjects = resData.subject;
    const sections = resData.sections;

    let teachersArr = teacher_subject.map(user => {
      return {
        created_at: user.user_id.created_at,
        email_address: user.user_id.email_address,
        enabled: user.user_id.enabled,
        given_name: user.user_id.given_name,
        id: user.id,
        is_verify: user.user_id.is_verify,
        last_name: user.user_id.last_name,
        role: user.user_id.role.name,
        status: user.user_id.status,
        suffix: user.user_id.suffix,
        sys_role_id: user.user_id.sys_role_id,
        list_of_subjects: user.list_of_subjects ? user.list_of_subjects : ""
      }
    });

    // const subjectSelectionArr =  allSubjects.map(type => {
    //   return {value: type.id, label: type.description, grade_level_id: type.year_level_id, semester: type.semester}
    // })
    const sectionSelections = sections.map(t => {
      return { value: t.id, label: t.name, course_type_id: t.course_type_id, grade_level_id: t.grade_level_id.length >= 1 ? t.grade_level_id[0].id : "" };
    })
    setData(teachersArr);
    setSectionSelection(sectionSelections);
    // setSubjectSelection(subjectSelectionArr);
    setLoading(false);
  } catch (err) {
    notifyError("Error while fetching Teachers");
  }
}

async function updateTeacherSubject(newData, setButtonSpinner, setUpdateTable, updateTable, setAddOrEditModal) {
  try {
    let res = await axios.post(ENDPOINT + `/update-teacher-subject`, newData, {
      headers: headers,
    });
    if (res.data.success) {
      setUpdateTable(!updateTable)
      if (setAddOrEditModal) {
        notifySuccess(res.data.message);
        setAddOrEditModal(false)
      }
    } else {
      notifyError(res.data.message);
    }
    setButtonSpinner(false)
  } catch (err) {
    notifyError("Error while updating teacher subjects");
    setButtonSpinner(false)
  }
}

async function getTeacherSubjectSelection(grade_level_id, grade_type_id, semester, course_type_id, setNewSubjectSelection) {
  try {
    let filter = `?grade_level_id=${grade_level_id}&grade_type_id=${grade_type_id}&semester=${semester}&course_type_id=${course_type_id}`
    let res = await axios.get(ENDPOINT + `/subject-teacher/get-subject-list` + filter, {
      headers: headers,
    });
    const subjects = res.data.data.subject_list.map(sub => {
      return { value: sub.id, label: sub.course_code, subject_type: sub.subject_type }
    })
    setNewSubjectSelection(subjects)
  } catch (err) {
    notifyError("Error while getting subjects");
  }
}