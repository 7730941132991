import React, { useEffect, useState } from 'react';
import { Button, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, FormGroup, Form, Spinner, Input } from 'reactstrap';
import "@kenshooui/react-multi-select/dist/style.css";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import {subjectActions} from '../../../../services/SubjectServices'
import optionList from '../../Admission/optionList'

const AddEdit = ({
    addOrEditModal, 
    setAddOrEditModal, 
    toggleAddOrEdit, 
    subject,
    action,
    setUpdateTable,
    updateTable,
    selectOptions,
    gradeTypeSelection,
    gradeLevelSelection,
    coursesSelection
}) => {
    const { handleSubmit, errors, control, setValue, getValues, watch } = useForm();
    const [buttonSpinner, setButtonSpinner] = useState(false);
    const [gradeTypeSelected, setGradeTypeSelected] = useState('')
    const [newGradeLevel, setNewGradeLevel] = useState([])
    const [newCourseSelection, setNewCourseSelection] = useState([])

    const getOptionLabelByValue = (options, value) => {
        const option = options.find((option) => option.value == value);
        return option ? option.label : "";
    };

    useEffect(()=>{
      let newSelection = gradeLevelSelection.filter(level => {
        return level.grade_type_id == gradeTypeSelected
      })

      let newCourses = coursesSelection.filter(course=>{
        return course.grade_type_id == gradeTypeSelected
      })

      setNewGradeLevel(newSelection)
      setNewCourseSelection(newCourses)

    }, [gradeTypeSelected])

    useEffect(()=>{
      if(subject.id !== ""){
        setGradeTypeSelected(subject.grade_type_id)
      }
    }, [subject]);

    const onSubmit = (data) => {
        setButtonSpinner(true);
        let dataToSubmit = {
          grade_type_id: data.grade_type_id ? data.grade_type_id.value : "",
          year_level_id: data.year_level_id ? data.year_level_id.value : "",
          semester: data.semester ? data.semester.value : "",
          description: data.description,
          unit: data.unit,
          lab_unit_price: data.lab_unit_price,
          lab_unit: 0,
          enabled: data.enabled ? data.enabled.value : "",
          course_type_id: data.course_type_id ? data.course_type_id.value : "",
          course_code: data.course_code,
          subject_type: data.grade_type_id && data.grade_type_id.value == 4 && data.subject_type && data.subject_type.value ? data.subject_type.value : null,
        }

        if(action == "add"){
          subjectActions.addSubject(dataToSubmit, setButtonSpinner, setUpdateTable, updateTable, setAddOrEditModal)
        }else if("edit"){
          dataToSubmit.subject_id = subject.id;
          subjectActions.updateSubject(dataToSubmit, setButtonSpinner, setUpdateTable, updateTable, setAddOrEditModal)
        }
    }

    return (
        <Modal isOpen={addOrEditModal} toggle={toggleAddOrEdit} size="lg" backdrop="static">
        <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader toggle={toggleAddOrEdit} className="bg-danger-edit text-white" charCode="">{action == "add" ? "Add Subject" : "Edit Subject"}</ModalHeader>
        <ModalBody>
            <Row>
            <Col md="12" xs="12">
                    <FormGroup>
                      <Label
                        htmlFor="grade_type_id"
                        className="text-secondary w-100 font-weight-bolder"
                      >
                        Select Grade Type
                      </Label>
                      <Controller
                        control={control}
                        name="grade_type_id"
                        defaultValue={
                            subject.grade_type_id != ""
                              ? {
                                value: subject.grade_type_id,
                                label: getOptionLabelByValue(
                                  gradeTypeSelection,
                                  subject.grade_type_id
                                ),
                              }
                              : ""
                            }
                        rules={{ required: true }}
                        invalid={errors.grade_type_id ? true : false}
                        render={({ onChange, value, onBlur, name }) => (
                          <Select
                            options={gradeTypeSelection}
                            name="grade_type_id"
                            onChange={(e) => {
                              setValue("grade_type_id", e);
                              setGradeTypeSelected(e.value)
                              return e;
                            }}
                            defaultValue={
                                subject.grade_type_id != ""
                              ? {
                                  value: subject.grade_type_id,
                                  label: getOptionLabelByValue(
                                    gradeTypeSelection,
                                    subject.grade_type_id
                                  ),
                                }
                              : ""
                            }
                          />
                        )}
                      />
                      {errors.grade_type_id &&
                        errors.grade_type_id.type == "required" && (
                          <small className="text-danger">
                            Grade Type is required!
                          </small>
                        )}
                    </FormGroup>
                </Col>
                <Col md="12" xs="12">
                    <FormGroup>
                      <Label
                        htmlFor="year_level_id"
                        className="text-secondary w-100 font-weight-bolder"
                      >
                        Select Grade/Year Level
                      </Label>
                      <Controller
                        control={control}
                        name="year_level_id"
                        defaultValue={
                            subject.year_level_id != ""
                              ? {
                                value: subject.year_level_id,
                                label: getOptionLabelByValue(
                                  gradeLevelSelection,
                                  subject.year_level_id
                                ),
                              }
                              : ""
                            }
                        rules={{ required: true }}
                        invalid={errors.year_level_id ? true : false}
                        render={({ onChange, value, onBlur, name }) => (
                          <Select
                            options={newGradeLevel}
                            name="year_level_id"
                            onChange={(e) => {
                              setValue("year_level_id", e);
                              return e;
                            }}
                            defaultValue={
                                subject.year_level_id != ""
                              ? {
                                  value: subject.year_level_id,
                                  label: getOptionLabelByValue(
                                    gradeLevelSelection,
                                    subject.year_level_id
                                  ),
                                }
                              : ""
                            }
                          />
                        )}
                      />
                      {errors.year_level_id &&
                        errors.year_level_id.type == "required" && (
                          <small className="text-danger">
                            Grade/Year Level is required!
                          </small>
                        )}
                    </FormGroup>
                </Col>
            
                {gradeTypeSelected == 5 || gradeTypeSelected == 4 ?
                <>
                  <Col md="12" xs="12">
                <FormGroup>
                  <Label
                    htmlFor="semester"
                    className="text-secondary w-100 font-weight-bolder"
                  >
                    Select Semester
                  </Label>
                  <Controller
                    control={control}
                    name="semester"
                    defaultValue={
                        subject.semester !== ""
                          ? {
                            value: subject.semester,
                            label: getOptionLabelByValue(
                              selectOptions.semester,
                              subject.semester
                            ),
                          }
                          : ""
                        }
                    rules={{ required: true }}
                    invalid={errors.semester ? true : false}
                    render={({ onChange, value, onBlur, name }) => (
                      <Select
                        options={selectOptions.semester}
                        name="semester"
                        onChange={(e) => {
                          setValue("semester", e);
                          return e;
                        }}
                        defaultValue={
                            subject.semester !== ""
                          ? {
                              value: subject.semester,
                              label: getOptionLabelByValue(
                                selectOptions.semester,
                                subject.semester
                              ),
                            }
                          : ""
                        }
                      />
                    )}
                  />
                  {errors.semester &&
                    errors.semester.type == "required" && (
                      <small className="text-danger">
                        Semester is required!
                      </small>
                    )}
                </FormGroup>
            </Col>
                  <Col md="12" xs="12">
                  <FormGroup>
                    <Label
                      htmlFor="course_type_id"
                      className="text-secondary w-100 font-weight-bolder"
                    >
                      Select Course
                    </Label>
                    <Controller
                      control={control}
                      name="course_type_id"
                      defaultValue={
                          subject.course_type_id != ""
                            ? {
                              value: subject.course_type_id,
                              label: getOptionLabelByValue(
                                coursesSelection,
                                subject.course_type_id
                              ),
                            }
                            : ""
                          }
                      rules={{ required: true }}
                      invalid={errors.course_type_id ? true : false}
                      render={({ onChange, value, onBlur, name }) => (
                        <Select
                          options={newCourseSelection}
                          name="course_type_id"
                          onChange={(e) => {
                            setValue("course_type_id", e);
                            return e;
                          }}
                          defaultValue={
                              subject.course_type_id != ""
                            ? {
                                value: subject.course_type_id,
                                label: getOptionLabelByValue(
                                  coursesSelection,
                                  subject.course_type_id
                                ),
                              }
                            : ""
                          }
                        />
                      )}
                    />
                    {errors.course_type_id &&
                      errors.course_type_id.type == "required" && (
                        <small className="text-danger">
                          Course is required!
                        </small>
                      )}
                  </FormGroup>
                </Col>
                </>
                :
                ""}
                <Col md="12" xs="12">
                    <Label className="text-secondary font-weight-bolder">
                        Subject Name
                    </Label>
                    <FormGroup>
                        <Controller
                          control={control}
                          name="course_code"
                          rules={{ required: true }}
                          invalid={errors.course_code ? true : false}
                          defaultValue={subject.course_code}
                          render={({ onChange, value, onBlur, name }) => (
                            <Input
                              className="form-control bg-light"
                              name="course_code"
                              type="text"
                              onChange={(event) => {
                                setValue("course_code", event.target.value);
                                return event.target.value;
                              }}
                              defaultValue={subject.course_code}
                            />
                          )}
                        />
                        {errors.course_code && errors.course_code.type == "required" && (
                          <small className="text-danger">
                            Course Code is required!
                          </small>
                        )}
                    </FormGroup>
                </Col>
                <Col md="12" xs="12">
                    <Label className="text-secondary font-weight-bolder">
                        Description
                    </Label>
                    <FormGroup>
                        <Controller
                          control={control}
                          name="description"
                          rules={{ required: true }}
                          invalid={errors.description ? true : false}
                          defaultValue={subject.description}
                          render={({ onChange, value, onBlur, name }) => (
                            <Input
                              className="form-control bg-light"
                              name="description"
                              type="text"
                              onChange={(event) => {
                                setValue("description", event.target.value);
                                return event.target.value;
                              }}
                              defaultValue={subject.description}
                            />
                          )}
                        />
                        {errors.description && errors.description.type == "required" && (
                          <small className="text-danger">
                            Description is required!
                          </small>
                        )}
                    </FormGroup>
                </Col>
                <Col md="6" xs="12">
                    <Label className="text-secondary font-weight-bolder">
                        Unit
                    </Label>
                    <FormGroup>
                        <Controller
                          control={control}
                          name="unit"
                          rules={{ required: true, pattern: /^\d{1,}(\.\d{0,4})?$/ }}
                          invalid={errors.unit ? true : false}
                          defaultValue={subject.unit}
                          render={({ onChange, value, onBlur, name }) => (
                            <Input
                              className="form-control bg-light"
                              name="unit"
                              type="text"
                              onChange={(event) => {
                                setValue("unit", event.target.value);
                                return event.target.value;
                              }}
                              defaultValue={subject.unit}
                            />
                          )}
                        />
                        {errors.unit && errors.unit.type == "required" && (
                          <small className="text-danger">
                            Unit is required!
                          </small>
                        )}
                        {errors.unit &&
                          errors.unit.type == "pattern" && (
                            <div
                              style={{
                                marginTop: "0.25rem",
                                fontSize: "80%",
                                color: "#f86c6b",
                              }}
                            >
                              Invalid unit value!
                            </div>
                          )}
                    </FormGroup>
                </Col>
                <Col md="6" xs="12">
                    <Label className="text-secondary font-weight-bolder">
                        Lab Unit Price
                    </Label>
                    <FormGroup>
                        <Controller
                          control={control}
                          name="lab_unit_price"
                          rules={{ required: true, min: 0 }}
                          invalid={errors.lab_unit_price ? true : false}
                          defaultValue={subject.lab_unit_price}
                          render={({ onChange, value, onBlur, name }) => (
                            <Input
                              className="form-control bg-light"
                              name="lab_unit_price"
                              type="number"
                              onChange={(event) => {
                                setValue("lab_unit_price", event.target.value);
                                return event.target.value;
                              }}
                              defaultValue={subject.lab_unit_price}
                            />
                          )}
                        />
                        {errors.lab_unit_price && errors.lab_unit_price.type == "required" && (
                          <small className="text-danger">
                            Lab unit price is required!
                          </small>
                        )}
                        {errors.lab_unit_price && errors.lab_unit_price.type == "min" && (
                          <small className="text-danger">
                            Invalid lab unit price!
                          </small>
                        )}
                    </FormGroup>
                </Col>
                <Col md="6" xs="12">
                    <FormGroup>
                      <Label
                        htmlFor="enabled"
                        className="text-secondary w-100 font-weight-bolder"
                      >
                        Status
                      </Label>
                      <Controller
                        control={control}
                        name="enabled"
                        defaultValue={
                            subject.enabled !== ""
                              ? {
                                value: subject.enabled,
                                label: getOptionLabelByValue(
                                  selectOptions.status,
                                  subject.enabled
                                ),
                              }
                              : ""
                            }
                        rules={{ required: true }}
                        invalid={errors.enabled ? true : false}
                        render={({ onChange, value, onBlur, name }) => (
                          <Select
                            options={selectOptions.status}
                            name="enabled"
                            onChange={(e) => {
                              setValue("enabled", e);
                              return e;
                            }}
                            defaultValue={
                                subject.enabled !== ""
                              ? {
                                  value: subject.enabled,
                                  label: getOptionLabelByValue(
                                    selectOptions.status,
                                    subject.enabled
                                  ),
                                }
                              : ""
                            }
                          />
                        )}
                      />
                      {errors.enabled &&
                        errors.enabled.type == "required" && (
                          <small className="text-danger">
                            Status is required!
                          </small>
                        )}
                    </FormGroup>
                </Col>
                {subject.grade_type_id == 4 ? 
                <Col md="6" xs="12">
                  <FormGroup>
                    <Label
                      htmlFor="subject_type"
                      className="text-secondary w-100 font-weight-bolder"
                    >
                      Subject Type
                    </Label>
                    <Controller
                      control={control}
                      name="subject_type"
                      defaultValue={
                          subject.subject_type !== ""
                            ? {
                              value: subject.subject_type,
                              label: getOptionLabelByValue(
                                optionList.subjectType,
                                subject.subject_type
                              ),
                            }
                            : ""
                          }
                      rules={{ required: true }}
                      invalid={errors.subject_type ? true : false}
                      render={({ onChange, value, onBlur, name }) => (
                        <Select
                          options={optionList.subjectType}
                          name="subject_type"
                          onChange={(e) => {
                            setValue("subject_type", e);
                            return e;
                          }}
                          defaultValue={
                              subject.subject_type !== ""
                            ? {
                                value: subject.subject_type,
                                label: getOptionLabelByValue(
                                  optionList.subjectType,
                                  subject.subject_type
                                ),
                              }
                            : ""
                          }
                        />
                      )}
                    />
                    {errors.subject_type &&
                      errors.subject_type.type == "required" && (
                        <small className="text-danger">
                          Subject type is required!
                        </small>
                      )}
                  </FormGroup>
                </Col>
                : ""}
            </Row>
        </ModalBody>
        <ModalFooter>
        <Button className="bg-warning-edit border-0" disabled={buttonSpinner}>
            {buttonSpinner ? (
              <>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </>
            ) : (
              "Save"
            )}
          </Button>
          <Button color="secondary" disabled={buttonSpinner} onClick={toggleAddOrEdit}>Close</Button>
        </ModalFooter>
        </Form>
      </Modal>
    )
}
export default AddEdit;