import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  FormGroup,
  Form,
  Spinner,
  Input,
  Card,
  CardBody,
} from "reactstrap";
import "@kenshooui/react-multi-select/dist/style.css";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { feesActions } from "../../../../services/FeesServices";

const AddEdit = ({
  addOrEditModal,
  setAddOrEditModal,
  toggleAddOrEdit,
  fee,
  action,
  setUpdateTable,
  updateTable,
  selectOptions
}) => {
  const { handleSubmit, errors, control, setValue } = useForm();
  const [buttonSpinner, setButtonSpinner] = useState(false);

  const getOptionLabelByValue = (options, value) => {
    const option = options.find((option) => option.value == value);
    return option ? option.label : "";
  };

  const basic = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

  const onSubmit = (data) => {
    setButtonSpinner(true);
    let dataToSubmit = {
      grade_level_id: data.grade_level_id ? data.grade_level_id.value : "",
      enabled: data.enabled ? data.enabled.value : "",
      student_fee: {
        tuition_fee: data.tuition_fee,
        miscellaneous_fee: data.miscellaneous_fee,
        other_fee: data.other_fee,
        payment_mode: {
          annual: {
            tuition_fee: data.annual_tuition_fee,
            miscellaneous_fee: data.annual_miscellaneous_fee,
            other_fee: data.annual_other_fee,
            payment_upon_enrollment: data.annual_payment_upon_enrollment,
            per_billing: data.annual_payment_per_billing,
            payment_due: data.annual_payment_due,
          },
          semestral: {
            tuition_fee: basic.includes(fee.grade_level_id)
              ? data.semestral_tuition_fee
              : 0,
            miscellaneous_fee: basic.includes(fee.grade_level_id)
              ? data.semestral_miscellaneous_fee
              : 0,
            other_fee: basic.includes(fee.grade_level_id)
              ? data.semestral_other_fee
              : 0,
            payment_upon_enrollment: basic.includes(fee.grade_level_id)
              ? data.semestral_payment_upon_enrollment
              : 0,
            per_billing: basic.includes(fee.grade_level_id)
              ? data.semestral_payment_per_billing
              : 0,
            payment_due: basic.includes(fee.grade_level_id)
              ? data.semestral_payment_due
              : "",
          },
          monthly_a: {
            tuition_fee: data.monthly_a_tuition_fee,
            miscellaneous_fee: data.monthly_a_miscellaneous_fee,
            other_fee: data.monthly_a_other_fee,
            payment_upon_enrollment: data.monthly_a_payment_upon_enrollment,
            per_billing: data.monthly_a_payment_per_billing,
            payment_due: data.monthly_a_payment_due,
          },
          monthly_b: {
            tuition_fee: data.monthly_b_tuition_fee,
            miscellaneous_fee: data.monthly_b_miscellaneous_fee,
            other_fee: data.monthly_b_other_fee,
            payment_upon_enrollment: data.monthly_b_payment_upon_enrollment,
            per_billing: data.monthly_b_payment_per_billing,
            payment_due: data.monthly_b_payment_due,
          },
        },
      },
    };
    // if(data.password !== ""){
    //   dataToSubmit.password = data.password;
    // }
    // if(action == "add"){
    //   userActions.addUser(dataToSubmit, setButtonSpinner, setUpdateTable, updateTable, setAddOrEditModal)
    // }else
    if (action == "add") {
      feesActions.addFee(
        dataToSubmit,
        setButtonSpinner,
        setUpdateTable,
        updateTable,
        setAddOrEditModal
      );
      }else if (action == "edit") {
      dataToSubmit.fee_id = fee.id;
      feesActions.updateFee(
        dataToSubmit,
        setButtonSpinner,
        setUpdateTable,
        updateTable,
        setAddOrEditModal
      );
    }
  };

  return (
    <Modal
      isOpen={addOrEditModal}
      toggle={toggleAddOrEdit}
      size="lg"
      backdrop="static"
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader
          toggle={toggleAddOrEdit}
          className="bg-danger-edit text-white"
          charCode=""
        >
          {action == "add" ? "Add Fee " : "Edit Fee "}
        </ModalHeader>
        <ModalBody>
          <Card className="mb-3">
            <CardBody>
              <Row>
              <Col md="12" xs="12">
                    <FormGroup>
                      <Label
                        htmlFor="grade_level_id"
                        className="text-secondary w-100 font-weight-bolder"
                      >
                        Select Grade/Year Level
                      </Label>
                      <Controller
                        control={control}
                        name="grade_level_id"
                        defaultValue={
                            fee.grade_level_id != ""
                              ? {
                                value: fee.grade_level_id,
                                label: getOptionLabelByValue(
                                  selectOptions.gradeLevels,
                                  fee.grade_level_id
                                ),
                              }
                              : ""
                            }
                        rules={{ required: true }}
                        invalid={errors.grade_level_id ? true : false}
                        render={({ onChange, value, onBlur, name }) => (
                          <Select
                            options={selectOptions.gradeLevels}
                            name="grade_level_id"
                            onChange={(e) => {
                              setValue("grade_level_id", e);
                              return e;
                            }}
                            defaultValue={
                                fee.grade_level_id != ""
                              ? {
                                  value: fee.grade_level_id,
                                  label: getOptionLabelByValue(
                                    selectOptions.gradeLevels,
                                    fee.grade_level_id
                                  ),
                                }
                              : ""
                            }
                          />
                        )}
                      />
                      {errors.grade_level_id &&
                        errors.grade_level_id.type == "required" && (
                          <small className="text-danger">
                            Grade/Year Level is required!
                          </small>
                        )}
                    </FormGroup>
                </Col>
                <Col md="6" xs="12">
                  <Label className="text-secondary font-weight-bolder">
                    Tuition Fee
                  </Label>
                  <FormGroup>
                    <Controller
                      control={control}
                      name="tuition_fee"
                      rules={{ required: true, min: 0 }}
                      invalid={errors.tuition_fee ? true : false}
                      defaultValue={fee.tuition_fee}
                      render={({ onChange, value, onBlur, name }) => (
                        <Input
                          className="form-control bg-light"
                          name="tuition_fee"
                          type="number"
                          step="0.01"
                          onChange={(event) => {
                            setValue("tuition_fee", event.target.value);
                            return event.target.value;
                          }}
                          defaultValue={fee.tuition_fee}
                        />
                      )}
                    />
                    {errors.tuition_fee &&
                      errors.tuition_fee.type == "required" && (
                        <small className="text-danger">
                          Tuition Fee is required!
                        </small>
                      )}
                    {errors.tuition_fee && errors.tuition_fee.type == "min" && (
                      <small className="text-danger">
                        Invalid tuition fee value!
                      </small>
                    )}
                  </FormGroup>
                </Col>
                <Col md="6" xs="12">
                  <Label className="text-secondary font-weight-bolder">
                    Miscellaneous Fee
                  </Label>
                  <FormGroup>
                    <Controller
                      control={control}
                      name="miscellaneous_fee"
                      rules={{ required: true, min: 0 }}
                      invalid={errors.miscellaneous_fee ? true : false}
                      defaultValue={fee.miscellaneous_fee}
                      render={({ onChange, value, onBlur, name }) => (
                        <Input
                          className="form-control bg-light"
                          name="miscellaneous_fee"
                          type="number"
                          step="0.01"
                          onChange={(event) => {
                            setValue("miscellaneous_fee", event.target.value);
                            return event.target.value;
                          }}
                          defaultValue={fee.miscellaneous_fee}
                        />
                      )}
                    />
                    {errors.miscellaneous_fee &&
                      errors.miscellaneous_fee.type == "required" && (
                        <small className="text-danger">
                          Miscellaneous Fee is required!
                        </small>
                      )}
                    {errors.miscellaneous_fee &&
                      errors.miscellaneous_fee.type == "min" && (
                        <small className="text-danger">
                          Invalid miscellaneous fee value!
                        </small>
                      )}
                  </FormGroup>
                </Col>
                <Col md="6" xs="12">
                  <Label className="text-secondary font-weight-bolder">
                    Other fee
                  </Label>
                  <FormGroup>
                    <Controller
                      control={control}
                      name="other_fee"
                      rules={{ required: true, min: 0 }}
                      invalid={errors.other_fee ? true : false}
                      defaultValue={fee.other_fee}
                      render={({ onChange, value, onBlur, name }) => (
                        <Input
                          className="form-control bg-light"
                          name="other_fee"
                          type="number"
                          step="0.01"
                          onChange={(event) => {
                            setValue("other_fee", event.target.value);
                            return event.target.value;
                          }}
                          defaultValue={fee.other_fee}
                        />
                      )}
                    />
                    {errors.other_fee &&
                      errors.other_fee.type == "required" && (
                        <small className="text-danger">
                          Other fee is required!
                        </small>
                      )}
                    {errors.other_fee && errors.other_fee.type == "min" && (
                      <small className="text-danger">
                        Invalid other fee value!
                      </small>
                    )}
                  </FormGroup>
                </Col>
                <Col md="6" xs="12">
                    <FormGroup>
                      <Label
                        htmlFor="enabled"
                        className="text-secondary w-100 font-weight-bolder"
                      >
                        Status
                      </Label>
                      <Controller
                        control={control}
                        name="enabled"
                        defaultValue={
                            fee.enabled !== ""
                              ? {
                                value: fee.enabled,
                                label: getOptionLabelByValue(
                                  selectOptions.status,
                                  fee.enabled
                                ),
                              }
                              : ""
                            }
                        rules={{ required: true }}
                        invalid={errors.enabled ? true : false}
                        render={({ onChange, value, onBlur, name }) => (
                          <Select
                            options={selectOptions.status}
                            name="enabled"
                            onChange={(e) => {
                              setValue("enabled", e);
                              return e;
                            }}
                            defaultValue={
                                fee.enabled !== ""
                              ? {
                                  value: fee.enabled,
                                  label: getOptionLabelByValue(
                                    selectOptions.status,
                                    fee.enabled
                                  ),
                                }
                              : ""
                            }
                          />
                        )}
                      />
                      {errors.enabled &&
                        errors.enabled.type == "required" && (
                          <small className="text-danger">
                            Status is required!
                          </small>
                        )}
                    </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card className="mb-3">
            <CardBody>
              <Row>
                <Col md="12" xs="12">
                  <h4 className="text-danger-edit">
                    {basic.includes(fee.grade_level_id)
                      ? "Annual"
                      : "Full Payment"}
                  </h4>
                </Col>
                <Col md="6" xs="12">
                  <Label className="text-secondary font-weight-bolder">
                    Tuition Fee
                  </Label>
                  <FormGroup>
                    <Controller
                      control={control}
                      name="annual_tuition_fee"
                      rules={{ required: true, min: 0 }}
                      invalid={errors.annual_tuition_fee ? true : false}
                      defaultValue={fee.payment_mode.annual.tuition_fee}
                      render={({ onChange, value, onBlur, name }) => (
                        <Input
                          className="form-control bg-light"
                          name="annual_tuition_fee"
                          type="number"
                          step="0.01"
                          onChange={(event) => {
                            setValue("annual_tuition_fee", event.target.value);
                            return event.target.value;
                          }}
                          defaultValue={fee.payment_mode.annual.tuition_fee}
                        />
                      )}
                    />
                    {errors.annual_tuition_fee &&
                      errors.annual_tuition_fee.type == "required" && (
                        <small className="text-danger">
                          Tuition Fee is required!
                        </small>
                      )}
                    {errors.annual_tuition_fee &&
                      errors.annual_tuition_fee.type == "min" && (
                        <small className="text-danger">
                          Invalid Tuition Fee value!
                        </small>
                      )}
                  </FormGroup>
                </Col>
                <Col md="6" xs="12">
                  <Label className="text-secondary font-weight-bolder">
                    Miscellaneous Fee
                  </Label>
                  <FormGroup>
                    <Controller
                      control={control}
                      name="annual_miscellaneous_fee"
                      rules={{ required: true, min: 0 }}
                      invalid={errors.annual_miscellaneous_fee ? true : false}
                      defaultValue={fee.payment_mode.annual.miscellaneous_fee}
                      render={({ onChange, value, onBlur, name }) => (
                        <Input
                          className="form-control bg-light"
                          name="annual_miscellaneous_fee"
                          type="number"
                          step="0.01"
                          onChange={(event) => {
                            setValue(
                              "annual_miscellaneous_fee",
                              event.target.value
                            );
                            return event.target.value;
                          }}
                          defaultValue={
                            fee.payment_mode.annual.miscellaneous_fee
                          }
                        />
                      )}
                    />
                    {errors.annual_miscellaneous_fee &&
                      errors.annual_miscellaneous_fee.type == "required" && (
                        <small className="text-danger">
                          Miscellaneous Fee is required!
                        </small>
                      )}
                    {errors.annual_miscellaneous_fee &&
                      errors.annual_miscellaneous_fee.type == "min" && (
                        <small className="text-danger">
                          Invalid Miscellaneous Fee value!
                        </small>
                      )}
                  </FormGroup>
                </Col>
                <Col md="6" xs="12">
                  <Label className="text-secondary font-weight-bolder">
                    Other Fee
                  </Label>
                  <FormGroup>
                    <Controller
                      control={control}
                      name="annual_other_fee"
                      rules={{ required: true, min: 0 }}
                      invalid={errors.annual_other_fee ? true : false}
                      defaultValue={fee.payment_mode.annual.other_fee}
                      render={({ onChange, value, onBlur, name }) => (
                        <Input
                          className="form-control bg-light"
                          name="annual_other_fee"
                          type="number"
                          step="0.01"
                          onChange={(event) => {
                            setValue("annual_other_fee", event.target.value);
                            return event.target.value;
                          }}
                          defaultValue={fee.payment_mode.annual.other_fee}
                        />
                      )}
                    />
                    {errors.annual_other_fee &&
                      errors.annual_other_fee.type == "required" && (
                        <small className="text-danger">
                          Other Fee is required!
                        </small>
                      )}
                    {errors.annual_other_fee &&
                      errors.annual_other_fee.type == "min" && (
                        <small className="text-danger">
                          Invalid Other Fee value!
                        </small>
                      )}
                  </FormGroup>
                </Col>
                <Col md="6" xs="12">
                  <Label className="text-secondary font-weight-bolder">
                    Payment Upon Enrollment
                  </Label>
                  <FormGroup>
                    <Controller
                      control={control}
                      name="annual_payment_upon_enrollment"
                      rules={{ required: true, min: 0 }}
                      invalid={
                        errors.annual_payment_upon_enrollment ? true : false
                      }
                      defaultValue={
                        fee.payment_mode.annual.payment_upon_enrollment
                      }
                      render={({ onChange, value, onBlur, name }) => (
                        <Input
                          className="form-control bg-light"
                          name="annual_payment_upon_enrollment"
                          type="number"
                          step="0.01"
                          onChange={(event) => {
                            setValue(
                              "annual_payment_upon_enrollment",
                              event.target.value
                            );
                            return event.target.value;
                          }}
                          defaultValue={
                            fee.payment_mode.annual.payment_upon_enrollment
                          }
                        />
                      )}
                    />
                    {errors.annual_payment_upon_enrollment &&
                      errors.annual_payment_upon_enrollment.type ==
                        "required" && (
                        <small className="text-danger">
                          Payment Upon Enrollment is required!
                        </small>
                      )}
                    {errors.annual_payment_upon_enrollment &&
                      errors.annual_payment_upon_enrollment.type == "min" && (
                        <small className="text-danger">
                          Invalid Payment Upon Enrollment value!
                        </small>
                      )}
                  </FormGroup>
                </Col>
                <Col md="6" xs="12">
                  <Label className="text-secondary font-weight-bolder">
                    Payment Per Billing
                  </Label>
                  <FormGroup>
                    <Controller
                      control={control}
                      name="annual_payment_per_billing"
                      rules={{ required: true, min: 0 }}
                      invalid={errors.annual_payment_per_billing ? true : false}
                      defaultValue={fee.payment_mode.annual.per_billing || 0}
                      render={({ onChange, value, onBlur, name }) => (
                        <Input
                          className="form-control bg-light"
                          name="annual_payment_per_billing"
                          type="number"
                          step="0.01"
                          onChange={(event) => {
                            setValue(
                              "annual_payment_per_billing",
                              event.target.value
                            );
                            return event.target.value;
                          }}
                          defaultValue={
                            fee.payment_mode.annual.per_billing || 0
                          }
                        />
                      )}
                    />
                    {errors.annual_payment_per_billing &&
                      errors.annual_payment_per_billing.type == "required" && (
                        <small className="text-danger">
                          Payment Per Billing is required!
                        </small>
                      )}
                    {errors.annual_payment_per_billing &&
                      errors.annual_payment_per_billing.type == "min" && (
                        <small className="text-danger">
                          Invalid Payment Per Billing value!
                        </small>
                      )}
                  </FormGroup>
                </Col>
                <Col md="6" xs="12">
                  <Label className="text-secondary font-weight-bolder">
                    Payment Due
                  </Label>
                  <FormGroup>
                    <Controller
                      control={control}
                      name="annual_payment_due"
                      rules={{ required: true, min: 0 }}
                      invalid={errors.annual_payment_due ? true : false}
                      defaultValue={fee.payment_mode.annual.payment_due || 0}
                      render={({ onChange, value, onBlur, name }) => (
                        <Input
                          className="form-control bg-light"
                          name="annual_payment_due"
                          type="text"
                          onChange={(event) => {
                            setValue("annual_payment_due", event.target.value);
                            return event.target.value;
                          }}
                          defaultValue={fee.payment_mode.annual.payment_due}
                        />
                      )}
                    />
                    {errors.annual_payment_due &&
                      errors.annual_payment_due.type == "required" && (
                        <small className="text-danger">
                          Payment Due is required!
                        </small>
                      )}
                    {errors.annual_payment_due &&
                      errors.annual_payment_due.type == "min" && (
                        <small className="text-danger">
                          Invalid Payment Due Enrollment value!
                        </small>
                      )}
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>

          {basic.includes(fee.grade_level_id) ? (
            <Card className="mb-3">
              <CardBody>
                <Row>
                  <Col md="12" xs="12">
                    <h4 className="text-danger-edit">Semestral</h4>
                  </Col>
                  <Col md="6" xs="12">
                    <Label className="text-secondary font-weight-bolder">
                      Tuition Fee
                    </Label>
                    <FormGroup>
                      <Controller
                        control={control}
                        name="semestral_tuition_fee"
                        rules={{ required: true, min: 0 }}
                        invalid={errors.semestral_tuition_fee ? true : false}
                        defaultValue={fee.payment_mode.semestral.tuition_fee}
                        render={({ onChange, value, onBlur, name }) => (
                          <Input
                            className="form-control bg-light"
                            name="semestral_tuition_fee"
                            type="number"
                            step="0.01"
                            onChange={(event) => {
                              setValue(
                                "semestral_tuition_fee",
                                event.target.value
                              );
                              return event.target.value;
                            }}
                            defaultValue={
                              fee.payment_mode.semestral.tuition_fee
                            }
                          />
                        )}
                      />
                      {errors.semestral_tuition_fee &&
                        errors.semestral_tuition_fee.type == "required" && (
                          <small className="text-danger">
                            Tuition Fee is required!
                          </small>
                        )}
                      {errors.semestral_tuition_fee &&
                        errors.semestral_tuition_fee.type == "min" && (
                          <small className="text-danger">
                            Invalid Tuition Fee value!
                          </small>
                        )}
                    </FormGroup>
                  </Col>
                  <Col md="6" xs="12">
                    <Label className="text-secondary font-weight-bolder">
                      Miscellaneous Fee
                    </Label>
                    <FormGroup>
                      <Controller
                        control={control}
                        name="semestral_miscellaneous_fee"
                        rules={{ required: true, min: 0 }}
                        invalid={
                          errors.semestral_miscellaneous_fee ? true : false
                        }
                        defaultValue={
                          fee.payment_mode.semestral.miscellaneous_fee
                        }
                        render={({ onChange, value, onBlur, name }) => (
                          <Input
                            className="form-control bg-light"
                            name="semestral_miscellaneous_fee"
                            type="number"
                            step="0.01"
                            onChange={(event) => {
                              setValue(
                                "semestral_miscellaneous_fee",
                                event.target.value
                              );
                              return event.target.value;
                            }}
                            defaultValue={
                              fee.payment_mode.semestral.miscellaneous_fee
                            }
                          />
                        )}
                      />
                      {errors.semestral_miscellaneous_fee &&
                        errors.semestral_miscellaneous_fee.type ==
                          "required" && (
                          <small className="text-danger">
                            Miscellaneous Fee is required!
                          </small>
                        )}
                      {errors.semestral_miscellaneous_fee &&
                        errors.semestral_miscellaneous_fee.type == "min" && (
                          <small className="text-danger">
                            Invalid Miscellaneous Fee value!
                          </small>
                        )}
                    </FormGroup>
                  </Col>
                  <Col md="6" xs="12">
                    <Label className="text-secondary font-weight-bolder">
                      Other Fee
                    </Label>
                    <FormGroup>
                      <Controller
                        control={control}
                        name="semestral_other_fee"
                        rules={{ required: true, min: 0 }}
                        invalid={errors.semestral_other_fee ? true : false}
                        defaultValue={fee.payment_mode.semestral.other_fee}
                        render={({ onChange, value, onBlur, name }) => (
                          <Input
                            className="form-control bg-light"
                            name="semestral_other_fee"
                            type="number"
                            step="0.01"
                            onChange={(event) => {
                              setValue(
                                "semestral_other_fee",
                                event.target.value
                              );
                              return event.target.value;
                            }}
                            defaultValue={fee.payment_mode.semestral.other_fee}
                          />
                        )}
                      />
                      {errors.semestral_other_fee &&
                        errors.semestral_other_fee.type == "required" && (
                          <small className="text-danger">
                            Other Fee is required!
                          </small>
                        )}
                      {errors.semestral_other_fee &&
                        errors.semestral_other_fee.type == "min" && (
                          <small className="text-danger">
                            Invalid Other Fee value!
                          </small>
                        )}
                    </FormGroup>
                  </Col>
                  <Col md="6" xs="12">
                    <Label className="text-secondary font-weight-bolder">
                      Payment Upon Enrollment
                    </Label>
                    <FormGroup>
                      <Controller
                        control={control}
                        name="semestral_payment_upon_enrollment"
                        rules={{ required: true, min: 0 }}
                        invalid={
                          errors.semestral_payment_upon_enrollment
                            ? true
                            : false
                        }
                        defaultValue={
                          fee.payment_mode.semestral.payment_upon_enrollment
                        }
                        render={({ onChange, value, onBlur, name }) => (
                          <Input
                            className="form-control bg-light"
                            name="semestral_payment_upon_enrollment"
                            type="number"
                            step="0.01"
                            onChange={(event) => {
                              setValue(
                                "semestral_payment_upon_enrollment",
                                event.target.value
                              );
                              return event.target.value;
                            }}
                            defaultValue={
                              fee.payment_mode.semestral.payment_upon_enrollment
                            }
                          />
                        )}
                      />
                      {errors.semestral_payment_upon_enrollment &&
                        errors.semestral_payment_upon_enrollment.type ==
                          "required" && (
                          <small className="text-danger">
                            Payment Upon Enrollment is required!
                          </small>
                        )}
                      {errors.semestral_payment_upon_enrollment &&
                        errors.semestral_payment_upon_enrollment.type ==
                          "min" && (
                          <small className="text-danger">
                            Invalid Payment Upon Enrollment value!
                          </small>
                        )}
                    </FormGroup>
                  </Col>
                  <Col md="6" xs="12">
                    <Label className="text-secondary font-weight-bolder">
                      Payment Per Billing
                    </Label>
                    <FormGroup>
                      <Controller
                        control={control}
                        name="semestral_payment_per_billing"
                        rules={{ required: true, min: 0 }}
                        invalid={
                          errors.semestral_payment_per_billing ? true : false
                        }
                        defaultValue={
                          fee.payment_mode.semestral.per_billing || 0
                        }
                        render={({ onChange, value, onBlur, name }) => (
                          <Input
                            className="form-control bg-light"
                            name="semestral_payment_per_billing"
                            type="number"
                            step="0.01"
                            onChange={(event) => {
                              setValue(
                                "semestral_payment_per_billing",
                                event.target.value
                              );
                              return event.target.value;
                            }}
                            defaultValue={
                              fee.payment_mode.semestral.per_billing || 0
                            }
                          />
                        )}
                      />
                      {errors.semestral_payment_per_billing &&
                        errors.semestral_payment_per_billing.type ==
                          "required" && (
                          <small className="text-danger">
                            Payment Per Billing is required!
                          </small>
                        )}
                      {errors.semestral_payment_per_billing &&
                        errors.semestral_payment_per_billing.type == "min" && (
                          <small className="text-danger">
                            Invalid Payment Per Billing value!
                          </small>
                        )}
                    </FormGroup>
                  </Col>
                  <Col md="6" xs="12">
                    <Label className="text-secondary font-weight-bolder">
                      Payment Due
                    </Label>
                    <FormGroup>
                      <Controller
                        control={control}
                        name="semestral_payment_due"
                        rules={{ required: true, min: 0 }}
                        invalid={errors.semestral_payment_due ? true : false}
                        defaultValue={
                          fee.payment_mode.semestral.payment_due || 0
                        }
                        render={({ onChange, value, onBlur, name }) => (
                          <Input
                            className="form-control bg-light"
                            name="semestral_payment_due"
                            type="text"
                            onChange={(event) => {
                              setValue(
                                "semestral_payment_due",
                                event.target.value
                              );
                              return event.target.value;
                            }}
                            defaultValue={
                              fee.payment_mode.semestral.payment_due
                            }
                          />
                        )}
                      />
                      {errors.semestral_payment_due &&
                        errors.semestral_payment_due.type == "required" && (
                          <small className="text-danger">
                            Payment Due is required!
                          </small>
                        )}
                      {errors.semestral_payment_due &&
                        errors.semestral_payment_due.type == "min" && (
                          <small className="text-danger">
                            Invalid Payment Due Enrollment value!
                          </small>
                        )}
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          ) : (
            ""
          )}

          <Card className="mb-3">
            <CardBody>
              <Row>
                <Col md="12" xs="12">
                  <h4 className="text-danger-edit">Monthly A</h4>
                </Col>
                <Col md="6" xs="12">
                  <Label className="text-secondary font-weight-bolder">
                    Tuition Fee
                  </Label>
                  <FormGroup>
                    <Controller
                      control={control}
                      name="monthly_a_tuition_fee"
                      rules={{ required: true, min: 0 }}
                      invalid={errors.monthly_a_tuition_fee ? true : false}
                      defaultValue={fee.payment_mode.monthly_a.tuition_fee}
                      render={({ onChange, value, onBlur, name }) => (
                        <Input
                          className="form-control bg-light"
                          name="monthly_a_tuition_fee"
                          type="number"
                          step="0.01"
                          onChange={(event) => {
                            setValue(
                              "monthly_a_tuition_fee",
                              event.target.value
                            );
                            return event.target.value;
                          }}
                          defaultValue={fee.payment_mode.monthly_a.tuition_fee}
                        />
                      )}
                    />
                    {errors.monthly_a_tuition_fee &&
                      errors.monthly_a_tuition_fee.type == "required" && (
                        <small className="text-danger">
                          Tuition Fee is required!
                        </small>
                      )}
                    {errors.monthly_a_tuition_fee &&
                      errors.monthly_a_tuition_fee.type == "min" && (
                        <small className="text-danger">
                          Invalid Tuition Fee value!
                        </small>
                      )}
                  </FormGroup>
                </Col>
                <Col md="6" xs="12">
                  <Label className="text-secondary font-weight-bolder">
                    Miscellaneous Fee
                  </Label>
                  <FormGroup>
                    <Controller
                      control={control}
                      name="monthly_a_miscellaneous_fee"
                      rules={{ required: true, min: 0 }}
                      invalid={
                        errors.monthly_a_miscellaneous_fee ? true : false
                      }
                      defaultValue={
                        fee.payment_mode.monthly_a.miscellaneous_fee
                      }
                      render={({ onChange, value, onBlur, name }) => (
                        <Input
                          className="form-control bg-light"
                          name="monthly_a_miscellaneous_fee"
                          type="number"
                          step="0.01"
                          onChange={(event) => {
                            setValue(
                              "monthly_a_miscellaneous_fee",
                              event.target.value
                            );
                            return event.target.value;
                          }}
                          defaultValue={
                            fee.payment_mode.monthly_a.miscellaneous_fee
                          }
                        />
                      )}
                    />
                    {errors.monthly_a_miscellaneous_fee &&
                      errors.monthly_a_miscellaneous_fee.type == "required" && (
                        <small className="text-danger">
                          Miscellaneous Fee is required!
                        </small>
                      )}
                    {errors.monthly_a_miscellaneous_fee &&
                      errors.monthly_a_miscellaneous_fee.type == "min" && (
                        <small className="text-danger">
                          Invalid Miscellaneous Fee value!
                        </small>
                      )}
                  </FormGroup>
                </Col>
                <Col md="6" xs="12">
                  <Label className="text-secondary font-weight-bolder">
                    Other Fee
                  </Label>
                  <FormGroup>
                    <Controller
                      control={control}
                      name="monthly_a_other_fee"
                      rules={{ required: true, min: 0 }}
                      invalid={errors.monthly_a_other_fee ? true : false}
                      defaultValue={fee.payment_mode.monthly_a.other_fee}
                      render={({ onChange, value, onBlur, name }) => (
                        <Input
                          className="form-control bg-light"
                          name="monthly_a_other_fee"
                          type="number"
                          step="0.01"
                          onChange={(event) => {
                            setValue("monthly_a_other_fee", event.target.value);
                            return event.target.value;
                          }}
                          defaultValue={fee.payment_mode.monthly_a.other_fee}
                        />
                      )}
                    />
                    {errors.monthly_a_other_fee &&
                      errors.monthly_a_other_fee.type == "required" && (
                        <small className="text-danger">
                          Other Fee is required!
                        </small>
                      )}
                    {errors.monthly_a_other_fee &&
                      errors.monthly_a_other_fee.type == "min" && (
                        <small className="text-danger">
                          Invalid Other Fee value!
                        </small>
                      )}
                  </FormGroup>
                </Col>
                <Col md="6" xs="12">
                  <Label className="text-secondary font-weight-bolder">
                    Payment Upon Enrollment
                  </Label>
                  <FormGroup>
                    <Controller
                      control={control}
                      name="monthly_a_payment_upon_enrollment"
                      rules={{ required: true, min: 0 }}
                      invalid={
                        errors.monthly_a_payment_upon_enrollment ? true : false
                      }
                      defaultValue={
                        fee.payment_mode.monthly_a.payment_upon_enrollment
                      }
                      render={({ onChange, value, onBlur, name }) => (
                        <Input
                          className="form-control bg-light"
                          name="monthly_a_payment_upon_enrollment"
                          type="number"
                          step="0.01"
                          onChange={(event) => {
                            setValue(
                              "monthly_a_payment_upon_enrollment",
                              event.target.value
                            );
                            return event.target.value;
                          }}
                          defaultValue={
                            fee.payment_mode.monthly_a.payment_upon_enrollment
                          }
                        />
                      )}
                    />
                    {errors.monthly_a_payment_upon_enrollment &&
                      errors.monthly_a_payment_upon_enrollment.type ==
                        "required" && (
                        <small className="text-danger">
                          Payment Upon Enrollment is required!
                        </small>
                      )}
                    {errors.monthly_a_payment_upon_enrollment &&
                      errors.monthly_a_payment_upon_enrollment.type ==
                        "min" && (
                        <small className="text-danger">
                          Invalid Payment Upon Enrollment value!
                        </small>
                      )}
                  </FormGroup>
                </Col>
                <Col md="6" xs="12">
                  <Label className="text-secondary font-weight-bolder">
                    Payment Per Billing
                  </Label>
                  <FormGroup>
                    <Controller
                      control={control}
                      name="monthly_a_payment_per_billing"
                      rules={{ required: true, min: 0 }}
                      invalid={
                        errors.monthly_a_payment_per_billing ? true : false
                      }
                      defaultValue={fee.payment_mode.monthly_a.per_billing || 0}
                      render={({ onChange, value, onBlur, name }) => (
                        <Input
                          className="form-control bg-light"
                          name="monthly_a_payment_per_billing"
                          type="number"
                          step="0.01"
                          onChange={(event) => {
                            setValue(
                              "monthly_a_payment_per_billing",
                              event.target.value
                            );
                            return event.target.value;
                          }}
                          defaultValue={
                            fee.payment_mode.monthly_a.per_billing || 0
                          }
                        />
                      )}
                    />
                    {errors.monthly_a_payment_per_billing &&
                      errors.monthly_a_payment_per_billing.type ==
                        "required" && (
                        <small className="text-danger">
                          Payment Per Billing is required!
                        </small>
                      )}
                    {errors.monthly_a_payment_per_billing &&
                      errors.monthly_a_payment_per_billing.type == "min" && (
                        <small className="text-danger">
                          Invalid Payment Per Billing value!
                        </small>
                      )}
                  </FormGroup>
                </Col>
                <Col md="6" xs="12">
                  <Label className="text-secondary font-weight-bolder">
                    Payment Due
                  </Label>
                  <FormGroup>
                    <Controller
                      control={control}
                      name="monthly_a_payment_due"
                      rules={{ required: true, min: 0 }}
                      invalid={errors.monthly_a_payment_due ? true : false}
                      defaultValue={fee.payment_mode.monthly_a.payment_due || 0}
                      render={({ onChange, value, onBlur, name }) => (
                        <Input
                          className="form-control bg-light"
                          name="monthly_a_payment_due"
                          type="text"
                          onChange={(event) => {
                            setValue(
                              "monthly_a_payment_due",
                              event.target.value
                            );
                            return event.target.value;
                          }}
                          defaultValue={fee.payment_mode.monthly_a.payment_due}
                        />
                      )}
                    />
                    {errors.monthly_a_payment_due &&
                      errors.monthly_a_payment_due.type == "required" && (
                        <small className="text-danger">
                          Payment Due is required!
                        </small>
                      )}
                    {errors.monthly_a_payment_due &&
                      errors.monthly_a_payment_due.type == "min" && (
                        <small className="text-danger">
                          Invalid Payment Due Enrollment value!
                        </small>
                      )}
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card className="mb-3">
            <CardBody>
              <Row>
                <Col md="12" xs="12">
                  <h4 className="text-danger-edit">Monthly B</h4>
                </Col>
                <Col md="6" xs="12">
                  <Label className="text-secondary font-weight-bolder">
                    Tuition Fee
                  </Label>
                  <FormGroup>
                    <Controller
                      control={control}
                      name="monthly_b_tuition_fee"
                      rules={{ required: true, min: 0 }}
                      invalid={errors.monthly_b_tuition_fee ? true : false}
                      defaultValue={fee.payment_mode.monthly_b.tuition_fee}
                      render={({ onChange, value, onBlur, name }) => (
                        <Input
                          className="form-control bg-light"
                          name="monthly_b_tuition_fee"
                          type="number"
                          step="0.01"
                          onChange={(event) => {
                            setValue(
                              "monthly_b_tuition_fee",
                              event.target.value
                            );
                            return event.target.value;
                          }}
                          defaultValue={fee.payment_mode.monthly_b.tuition_fee}
                        />
                      )}
                    />
                    {errors.monthly_b_tuition_fee &&
                      errors.monthly_b_tuition_fee.type == "required" && (
                        <small className="text-danger">
                          Tuition Fee is required!
                        </small>
                      )}
                    {errors.monthly_b_tuition_fee &&
                      errors.monthly_b_tuition_fee.type == "min" && (
                        <small className="text-danger">
                          Invalid Tuition Fee value!
                        </small>
                      )}
                  </FormGroup>
                </Col>
                <Col md="6" xs="12">
                  <Label className="text-secondary font-weight-bolder">
                    Miscellaneous Fee
                  </Label>
                  <FormGroup>
                    <Controller
                      control={control}
                      name="monthly_b_miscellaneous_fee"
                      rules={{ required: true, min: 0 }}
                      invalid={
                        errors.monthly_b_miscellaneous_fee ? true : false
                      }
                      defaultValue={
                        fee.payment_mode.monthly_b.miscellaneous_fee
                      }
                      render={({ onChange, value, onBlur, name }) => (
                        <Input
                          className="form-control bg-light"
                          name="monthly_b_miscellaneous_fee"
                          type="number"
                          step="0.01"
                          onChange={(event) => {
                            setValue(
                              "monthly_b_miscellaneous_fee",
                              event.target.value
                            );
                            return event.target.value;
                          }}
                          defaultValue={
                            fee.payment_mode.monthly_b.miscellaneous_fee
                          }
                        />
                      )}
                    />
                    {errors.monthly_b_miscellaneous_fee &&
                      errors.monthly_b_miscellaneous_fee.type == "required" && (
                        <small className="text-danger">
                          Miscellaneous Fee is required!
                        </small>
                      )}
                    {errors.monthly_b_miscellaneous_fee &&
                      errors.monthly_b_miscellaneous_fee.type == "min" && (
                        <small className="text-danger">
                          Invalid Miscellaneous Fee value!
                        </small>
                      )}
                  </FormGroup>
                </Col>
                <Col md="6" xs="12">
                  <Label className="text-secondary font-weight-bolder">
                    Other Fee
                  </Label>
                  <FormGroup>
                    <Controller
                      control={control}
                      name="monthly_b_other_fee"
                      rules={{ required: true, min: 0 }}
                      invalid={errors.monthly_b_other_fee ? true : false}
                      defaultValue={fee.payment_mode.monthly_b.other_fee}
                      render={({ onChange, value, onBlur, name }) => (
                        <Input
                          className="form-control bg-light"
                          name="monthly_b_other_fee"
                          type="number"
                          step="0.01"
                          onChange={(event) => {
                            setValue("monthly_b_other_fee", event.target.value);
                            return event.target.value;
                          }}
                          defaultValue={fee.payment_mode.monthly_b.other_fee}
                        />
                      )}
                    />
                    {errors.monthly_b_other_fee &&
                      errors.monthly_b_other_fee.type == "required" && (
                        <small className="text-danger">
                          Other Fee is required!
                        </small>
                      )}
                    {errors.monthly_b_other_fee &&
                      errors.monthly_b_other_fee.type == "min" && (
                        <small className="text-danger">
                          Invalid Other Fee value!
                        </small>
                      )}
                  </FormGroup>
                </Col>
                <Col md="6" xs="12">
                  <Label className="text-secondary font-weight-bolder">
                    Payment Upon Enrollment
                  </Label>
                  <FormGroup>
                    <Controller
                      control={control}
                      name="monthly_b_payment_upon_enrollment"
                      rules={{ required: true, min: 0 }}
                      invalid={
                        errors.monthly_b_payment_upon_enrollment ? true : false
                      }
                      defaultValue={
                        fee.payment_mode.monthly_b.payment_upon_enrollment
                      }
                      render={({ onChange, value, onBlur, name }) => (
                        <Input
                          className="form-control bg-light"
                          name="monthly_b_payment_upon_enrollment"
                          type="number"
                          step="0.01"
                          onChange={(event) => {
                            setValue(
                              "monthly_b_payment_upon_enrollment",
                              event.target.value
                            );
                            return event.target.value;
                          }}
                          defaultValue={
                            fee.payment_mode.monthly_b.payment_upon_enrollment
                          }
                        />
                      )}
                    />
                    {errors.monthly_b_payment_upon_enrollment &&
                      errors.monthly_b_payment_upon_enrollment.type ==
                        "required" && (
                        <small className="text-danger">
                          Payment Upon Enrollment is required!
                        </small>
                      )}
                    {errors.monthly_b_payment_upon_enrollment &&
                      errors.monthly_b_payment_upon_enrollment.type ==
                        "min" && (
                        <small className="text-danger">
                          Invalid Payment Upon Enrollment value!
                        </small>
                      )}
                  </FormGroup>
                </Col>
                <Col md="6" xs="12">
                  <Label className="text-secondary font-weight-bolder">
                    Payment Per Billing
                  </Label>
                  <FormGroup>
                    <Controller
                      control={control}
                      name="monthly_b_payment_per_billing"
                      rules={{ required: true, min: 0 }}
                      invalid={
                        errors.monthly_b_payment_per_billing ? true : false
                      }
                      defaultValue={fee.payment_mode.monthly_b.per_billing || 0}
                      render={({ onChange, value, onBlur, name }) => (
                        <Input
                          className="form-control bg-light"
                          name="monthly_b_payment_per_billing"
                          type="number"
                          step="0.01"
                          onChange={(event) => {
                            setValue(
                              "monthly_b_payment_per_billing",
                              event.target.value
                            );
                            return event.target.value;
                          }}
                          defaultValue={
                            fee.payment_mode.monthly_b.per_billing || 0
                          }
                        />
                      )}
                    />
                    {errors.monthly_b_payment_per_billing &&
                      errors.monthly_b_payment_per_billing.type ==
                        "required" && (
                        <small className="text-danger">
                          Payment Per Billing is required!
                        </small>
                      )}
                    {errors.monthly_b_payment_per_billing &&
                      errors.monthly_b_payment_per_billing.type == "min" && (
                        <small className="text-danger">
                          Invalid Payment Per Billing value!
                        </small>
                      )}
                  </FormGroup>
                </Col>
                <Col md="6" xs="12">
                  <Label className="text-secondary font-weight-bolder">
                    Payment Due
                  </Label>
                  <FormGroup>
                    <Controller
                      control={control}
                      name="monthly_b_payment_due"
                      rules={{ required: true, min: 0 }}
                      invalid={errors.monthly_b_payment_due ? true : false}
                      defaultValue={fee.payment_mode.monthly_b.payment_due || "-"}
                      render={({ onChange, value, onBlur, name }) => (
                        <Input
                          className="form-control bg-light"
                          name="monthly_b_payment_due"
                          type="text"
                          onChange={(event) => {
                            setValue(
                              "monthly_b_payment_due",
                              event.target.value
                            );
                            return event.target.value;
                          }}
                          defaultValue={fee.payment_mode.monthly_b.payment_due || "-"}
                        />
                      )}
                    />
                    {errors.monthly_b_payment_due &&
                      errors.monthly_b_payment_due.type == "required" && (
                        <small className="text-danger">
                          Payment Due is required!
                        </small>
                      )}
                    {errors.monthly_b_payment_due &&
                      errors.monthly_b_payment_due.type == "min" && (
                        <small className="text-danger">
                          Invalid Payment Due Enrollment value!
                        </small>
                      )}
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </ModalBody>
        <ModalFooter>
          <Button className="bg-warning-edit border-0" disabled={buttonSpinner}>
            {buttonSpinner ? (
              <>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </>
            ) : (
              "Save"
            )}
          </Button>
          <Button
            color="secondary"
            disabled={buttonSpinner}
            onClick={toggleAddOrEdit}
          >
            Close
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};
export default AddEdit;
