import React, { Fragment } from "react";
import "./App.css";
import LandingPage from "./views/Pages/LandingPage/LandingPage.js";
import Login from "./views/Pages/Login/Login.js";
import Signup from "./views/Pages/Signup/Signup.js";
import AboutUs from "./views/Pages/AboutUs/AboutUs.js";
import ForgotPassword from "./views/Pages/ForgotPassword/ForgotPassword.js";
import AdmissionPage from "./views/Pages/AdmissionPage/AdmissionPage.js";
import ScholarshipPage from "./views/Pages/AdmissionPage/ScholarshipPage.js";
import Academics from "./views/Pages/Academics/Academics.js";
import VerifyEmail from "./views/Pages/Signup/VerifyEmail.js";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Portal from "./Portal";
import PageLayout from "./components/PageLayout.js";
import PrivateRoutes from "./components/PrivateRoutes.js";
import { sidebarData } from "./components/SidebarData.js";

function App() {
  const authenticated = localStorage.getItem("jwtToken") ? true : false;

  return (
    <Router>
      <Routes>
        <Route
          exact
          path="/"
          element={<LandingPage authenticated={authenticated} />}
        />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/sign-up" element={<Signup />} />
        <Route path="/verify" element={<VerifyEmail />} />
        <Route exact path="/about-us" element={<AboutUs />} />
        <Route exact path="/admission-page" element={<AdmissionPage />} />
        <Route exact path="/scholarship-page" element={<ScholarshipPage />} />
        <Route exact path="/academics" element={<Academics />} />
        <Route exact path="/forgot-password" element={<ForgotPassword />} />
        <Route element={<PrivateRoutes />}>
          <Route
            exact
            path="/portal"
            element={<PageLayout title={""} children={<Portal />} />}
          />
          {sidebarData.map((route) => {
            return (
              <Fragment key={route.title}>
                <Route
                  exact
                  path={route.path}
                  element={
                    <PageLayout
                      title={route.title}
                      children={route.component}
                    />
                  }
                />
              </Fragment>
            );
          })}
        </Route>
        <Route path="*" />
      </Routes>
    </Router>
  );
}

export default App;
