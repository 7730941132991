import React, { useEffect, useState } from "react";
import {
  Input,
  InputGroupAddon,
  InputGroup,
  Col,
  Button,
  Table,
  Row,
  Spinner,
  FormGroup,
  Label,
  Form,
} from "reactstrap";
import Loading from "../Forms/Loading.js";
import { useForm, Controller } from "react-hook-form";
import { paymentActions } from "../../../services/PaymentServices";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import overlayFactory from "react-bootstrap-table2-overlay";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { educationActions } from "../../../services/EducationSelectionService.js";
import Select from "react-select";
import optionList from "./optionList.js";

const PaymentHistory = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [totalSize, setTotalSize] = useState(0);
  const [sortFieldQuery, setSortFieldQuery] = useState("id");
  const [sortOrderQuery, setSortOrderQuery] = useState("desc");
  const [sizePerPageQuery, setSizePerPageQuery] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [page1, setPage1] = useState(1);
  const { register, handleSubmit, errors, control, setValue, getValues } =
    useForm();
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [buttonSpinnerDownload, setButtonSpinnerDownload] = useState(false);
  const [paymentApplication, setPaymentApplication] = useState({});
  const [exportUrl, setExportUrl] = useState("");
  const Cashier = ["Cashier", "Administrator"];
  const role = localStorage.getItem("role") ? localStorage.getItem("role") : "";
  const [dateFromStartDate, setDateFromStartDate] = useState("");
  const [dateToDate, setDateToDate] = useState("");
  const [gradeLevels, setGradeLevels] = useState([]);
  const [gradeLevelSelection, setGradeLevelSelection] = useState([]);
  const [gradeLevelFilter, setGradeLevelFilter] = useState("");
  const withSemester = [
    "Grade 11",
    "Grade 12",
    "First year",
    "Second year",
    "Third year",
    "Fourth year",
  ];
  const [filters, setFilters] = useState({
    gradeLevel: "",
    semester: "",
  });

  useEffect(() => {
    educationActions.getApplications(
      null,
      null,
      null,
      setGradeLevels,
      null,
      null,
      null,
      null
    );
  }, []);

  useEffect(() => {
    if (gradeLevels.length >= 1) {
      let level = gradeLevels.map((grade) => {
        let grade_type_id = grade.grade_type_id.id;
        return {
          value: grade.id,
          label: grade.name,
          grade_type: grade_type_id,
        };
      });
      setGradeLevelSelection(level);
    }
  }, [gradeLevels]);

  const columns = [
    {
      dataField: "ref_no",
      text: "Reference Number",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
      formatter: (cell) => {
        return cell.toUpperCase();
      },
    },
    {
      dataField: "transaction_no",
      text: "Transaction Number",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
      formatter: (cell) => {
        return cell ? cell.toUpperCase() : "-";
      },
    },
    {
      dataField: "fullname",
      text: "Full Name",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
      formatter: (cell) => {
        return cell.toUpperCase();
      },
    },
    {
      dataField: "billing",
      text: "Description",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
      formatter: (cell, row) => {
        let billing = "";
        if (row.billing == cell) {
          if (cell && !row.payment_due) {
            billing = "Downpayment";
          } else {
            billing = "Billing " + row.billing;
          }
        }
        return billing;
      },
    },
    {
      dataField: "payment_due",
      text: "Payment Due Date",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
      formatter: (cell) => {
        return cell ? cell : "-";
      },
    },
    {
      dataField: "total_amount",
      text: "Total Amount",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "status",
      text: "Status",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "payment_proof",
      text: "Payment Proof",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
      formatter: (cell) => {
        if (cell) {
          return (
            <a href={cell} target="_blank" className="text-dark">
              {"Click to Download"}
            </a>
          );
        } else {
          return "-";
        }
      },
    },
    {
      dataField: "or_number",
      text: "OR Number",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "amount_paid",
      text: "Amount Paid",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "date_posted",
      text: "Date Posted",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "remarks",
      text: "Remarks",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "uploaded_by",
      text: "Uploaded By",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "created_at",
      text: "Date Uploaded",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
  ];

  const expandRow = {
    parentClassName: "parent-expand-foo",
    showExpandColumn: true,
    renderer: (row) => {
      return (
        <div className="p-3">
          <Table bordered striped>
            <thead>
              <tr>
                <th
                  style={{ color: "white", backgroundColor: "rgb(128, 0, 0)" }}
                >
                  #
                </th>
                <th
                  style={{ color: "white", backgroundColor: "rgb(128, 0, 0)" }}
                ></th>
                <th
                  style={{ color: "white", backgroundColor: "rgb(128, 0, 0)" }}
                ></th>
                <th
                  style={{ color: "white", backgroundColor: "rgb(128, 0, 0)" }}
                ></th>
                <th
                  style={{ color: "white", backgroundColor: "rgb(128, 0, 0)" }}
                >
                  Date Posted
                </th>
                <th
                  style={{ color: "white", backgroundColor: "rgb(128, 0, 0)" }}
                >
                  Remarks
                </th>
                <th
                  style={{ color: "white", backgroundColor: "rgb(128, 0, 0)" }}
                >
                  Uploaded By
                </th>
                <th
                  style={{ color: "white", backgroundColor: "rgb(128, 0, 0)" }}
                >
                  Date Created
                </th>
              </tr>
            </thead>
            <tbody>
              {row.payment_proof && row.payment_proof.length ? (
                row.payment_proof.map((proof, key) => {
                  return (
                    <tr key={key}>
                      <td>{key + 1}</td>
                      <td>
                        <a
                          href={proof.bucket_link}
                          target="_blank"
                          className="text-dark"
                        >
                          {"Click to Download"}
                        </a>
                      </td>
                      <td>{proof.or_number}</td>
                      <td>{proof.amount}</td>
                      <td>{proof.date_posted}</td>
                      <td>{proof.remarks}</td>
                      <td>{proof.uploaded_by ? proof.uploaded_by : "-"}</td>
                      <td>{proof.created_at}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="8">No Data</td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      );
    },
  };

  const NoDataIndication = () => <div>No Data</div>;

  const changeQuery = (e) => {
    setSearchText(e.target.value);
  };

  const [exportFilterError, setExportFilterError] = useState(false);

  const handleExportPayment = () => {
    setExportFilterError(false);
    if (dateFromStartDate === "" || dateToDate === "") {
      setExportFilterError(true);
    } else {
      let exportFilter = {
        date_from: dateFromStartDate
          ? moment(dateFromStartDate).format("YYYY-MM-DD")
          : null,
        date_to: dateToDate ? moment(dateToDate).format("YYYY-MM-DD") : null,
        q: searchText,
        year_level: filters.gradeLevel,
        semester:
          filters.semester !== "" && withSemester.includes(gradeLevelFilter)
            ? filters.semester
            : "",
      };
      setButtonSpinner(true);
      setExportUrl("");
      paymentActions.exportPaymentHistory(
        setButtonSpinner,
        setExportUrl,
        exportFilter
      );
    }
  };

  const handleDownloadFile = () => {
    let win = window.open(exportUrl);
    win.focus();
  };

  const search = (data) => {
    console.log(data);
    let dateFilter = {
      date_from: data.date_from
        ? moment(data.date_from).format("YYYY-MM-DD")
        : "",
      date_to: data.date_to ? moment(data.date_to).format("YYYY-MM-DD") : "",
      year_level: data.gradeLevel,
      semester:
        data.semester !== "" && withSemester.includes(gradeLevelFilter)
          ? data.semester
          : "",
    };
    setLoading(true);
    paymentActions.getPaymentHistory(
      setData,
      setLoading,
      setTotalSize,
      page1,
      sizePerPageQuery,
      searchText,
      dateFilter
    );
  };

  useEffect(() => {
    if (dateFromStartDate && dateToDate) {
      let dateFilter = {
        date_from: dateFromStartDate
          ? moment(dateFromStartDate).format("YYYY-MM-DD")
          : "",
        date_to: dateToDate ? moment(dateToDate).format("YYYY-MM-DD") : "",
        year_level: filters.gradeLevel,
        semester:
          filters.semester !== "" && withSemester.includes(gradeLevelFilter)
            ? filters.semester
            : "",
        searchText: searchText,
      };
      setLoading(true);
      paymentActions.getPaymentHistory(
        setData,
        setLoading,
        setTotalSize,
        page1,
        sizePerPageQuery,
        searchText,
        dateFilter
      );
    }
  }, [page1, sizePerPageQuery]);

  const RemoteAll = ({
    loading,
    data,
    page,
    sizePerPage,
    onTableChange,
    totalSize,
  }) => (
    <div>
      <BootstrapTable
        wrapperClasses="table-responsive text-center"
        bootstrap4
        striped
        remote
        loading={loading}
        keyField="id"
        data={data}
        columns={columns}
        defaultSorted={defaultSorted}
        filter={filterFactory()}
        pagination={paginationFactory({ page, sizePerPage, totalSize })}
        onTableChange={onTableChange}
        overlay={overlayFactory({
          spinner: true,
          styles: {
            overlay: (base) => ({
              ...base,
              background: "rgba(0, 0, 0, 0.5)",
            }),
          },
        })}
        noDataIndication={() => <NoDataIndication />}
      />
    </div>
  );

  // DATA TABLE
  const handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder }
  ) => {
    if (page !== page1) {
      setPage1(page);

      setSortFieldQuery(sortField ? sortField : sortFieldQuery);
      setSortOrderQuery(sortOrder ? sortOrder : sortOrderQuery);
      setLoading(true);
    }

    if (
      (sortField != sortFieldQuery && sortField != undefined) ||
      (sortOrder != sortOrderQuery && sortOrder != undefined)
    ) {
      setSortFieldQuery(sortField ? sortField : sortFieldQuery);
      setSortOrderQuery(sortOrder ? sortOrder : sortOrderQuery);
      setLoading(true);
    }

    if (sizePerPage != sizePerPageQuery) {
      setSizePerPageQuery(sizePerPage);
      setLoading(true);
    }
  };

  const defaultSorted = [
    {
      dataField: sortFieldQuery,
      order: sortOrderQuery,
    },
  ];

  return (
    <div className="p-4">
      {loading ? <Loading /> : ""}
      <Form onSubmit={handleSubmit(search)}>
        <Row>
          <Col md="3" xs="12">
            <FormGroup>
              <Label className="text-secondary font-weight-bolder">
                Date From
              </Label>
              <Controller
                control={control}
                name="date_from"
                defaultValue=""
                rules={{ required: true }}
                invalid={errors.date_from ? true : false}
                render={({ onChange, value, onBlur, name }) => (
                  <DatePicker
                    className="form-control bg-light"
                    selected={
                      moment(dateFromStartDate).isValid()
                        ? moment(dateFromStartDate).toDate()
                        : ""
                    }
                    onChange={(date) => {
                      setDateFromStartDate(date);
                      setValue("date_from", date);
                      return date;
                    }}
                    showYearDropdown
                    placeholderText="MM/DD/YYYY"
                    name="date_from"
                  />
                )}
              />
              {errors.date_from && errors.date_from.type == "required" && (
                <div
                  style={{
                    marginTop: "0.25rem",
                    fontSize: "80%",
                    color: "#f86c6b",
                  }}
                >
                  Date From is required!
                </div>
              )}
            </FormGroup>
          </Col>
          <Col md="3" xs="12">
            <FormGroup>
              <Label className="text-secondary font-weight-bolder">
                Date To
              </Label>
              <Controller
                control={control}
                name="date_to"
                defaultValue=""
                rules={{ required: true }}
                invalid={errors.date_to ? true : false}
                render={({ onChange, value, onBlur, name }) => (
                  <DatePicker
                    className="form-control bg-light"
                    selected={
                      moment(dateToDate).isValid()
                        ? moment(dateToDate).toDate()
                        : ""
                    }
                    onChange={(date) => {
                      setDateToDate(date);
                      setValue("date_to", date);
                      return date;
                    }}
                    showYearDropdown
                    placeholderText="MM/DD/YYYY"
                    name="date_to"
                  />
                )}
              />
              {errors.date_to && errors.date_to.type == "required" && (
                <div
                  style={{
                    marginTop: "0.25rem",
                    fontSize: "80%",
                    color: "#f86c6b",
                  }}
                >
                  Date To is required!
                </div>
              )}
            </FormGroup>
          </Col>
          <Col md="4" xs="12" className="ml-auto mb-2">
            <p className="mb-1">
              <small>
                <i className="fa fa-lightbulb-o"></i>&nbsp;Search by Reference
                Number, OR Number, Given Name and Last Name
              </small>
            </p>
            <InputGroup>
              <InputGroupAddon addonType="prepend" style={{ zIndex: "0" }}>
                <Button type="button" color="primary" id="search-button">
                  <i className="fa fa-search"></i>
                </Button>
              </InputGroupAddon>
              <Input
                type="text"
                id="search-text"
                name="search-text"
                placeholder="Search"
                onChange={changeQuery}
              />
            </InputGroup>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md="2" xs="12" className="mb-2">
            <Label>
              <small className="font-weight-bolder">
                <i className="fa fa-lightbulb-o"></i>&nbsp;Filter by Grade Level
              </small>
            </Label>
            <Controller
              control={control}
              name="gradeLevel"
              rules={{ required: true }}
              invalid={errors.gradeLevel ? true : false}
              defaultValue=""
              render={({ onChange, value, onBlur, name }) => (
                <Select
                  options={gradeLevelSelection}
                  onChange={(e) => {
                    setValue("gradeLevel", e.value);
                    setGradeLevelFilter(e.label);
                    setFilters({
                      ...filters,
                      gradeLevel: e.value,
                      course: "",
                      semester: "",
                    });
                    return e;
                  }}
                  defaultValue=""
                />
              )}
            />
            {errors.gradeLevel && errors.gradeLevel.type == "required" && (
              <div
                style={{
                  marginTop: "0.25rem",
                  fontSize: "80%",
                  color: "#f86c6b",
                }}
              >
                Grade Level is required!
              </div>
            )}
          </Col>
          {withSemester.includes(gradeLevelFilter) ? (
            <Col md="2" xs="12" className="mb-2">
              <Label>
                <small className="font-weight-bolder">
                  <i className="fa fa-lightbulb-o"></i>&nbsp;Filter by Semester
                </small>
              </Label>
              <Controller
                control={control}
                name="semester"
                rules={{ required: true }}
                invalid={errors.semester ? true : false}
                defaultValue=""
                render={({ onChange, value, onBlur, name }) => (
                  <Select
                    options={optionList.semester}
                    onChange={(e) => {
                      setValue("semester", e.value);
                      setFilters({ ...filters, semester: e.value });
                      return e;
                    }}
                    defaultValue=""
                  />
                )}
              />
              {errors.semester && errors.semester.type == "required" && (
                <div
                  style={{
                    marginTop: "0.25rem",
                    fontSize: "80%",
                    color: "#f86c6b",
                  }}
                >
                  Semester is required!
                </div>
              )}
            </Col>
          ) : (
            ""
          )}
        </Row>
        <Row>
          <Col md="12" xs="12" className="mb-2">
            <Button className="bg-danger-edit text-white border-0">
              Filter History
            </Button>
          </Col>
        </Row>
      </Form>
      <Row>
        {Cashier.includes(role) ? (
          <>
            <Col md="3" xs="12">
              <Button
                color="warning"
                className="text-white mb-4"
                disabled={buttonSpinner ? true : false}
                onClick={handleExportPayment}
              >
                {buttonSpinner ? (
                  <>
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    &nbsp;Processing...
                  </>
                ) : (
                  "Export Payment History"
                )}
              </Button>
            </Col>
            {exportUrl ? (
              <Col md="2" xs="12">
                <Button
                  className="text-white btn"
                  style={{
                    backgroundColor: "#800000",
                    borderColor: "#800000",
                  }}
                  onClick={handleDownloadFile}
                >
                  Download File
                </Button>
              </Col>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}
        {exportFilterError ? (
          <Col md="12" xs="12" className="mb-2">
            <small className="text-danger">Dates are required</small>
          </Col>
        ) : (
          ""
        )}
      </Row>
      <RemoteAll
        loading={loading}
        data={data}
        page={page1}
        sizePerPage={sizePerPageQuery}
        totalSize={totalSize}
        onTableChange={handleTableChange}
        register={register}
      />
    </div>
  );
};

export default PaymentHistory;
