import React from "react";
import { Col, Container, Row } from "reactstrap";
import { isDesktop } from "react-device-detect";
import MobileSideBar from "./views/Pages/Admission/MobileSidebar.js";
import LoginImage from "./assets/img/LoginImage.jpg";
import Mobile from "./assets/img/mobile.jpg";
import SideBar from "./components/SideBar.js";

const Portal = () => {
  return (
    <>
      {isDesktop ? (
        <img
          src={LoginImage}
          style={{ height: "100%", width: "100%", objectFit: "cover" }}
          alt="LoginImage"
        />
      ) : (
        <img
          src={Mobile}
          style={{ height: "100%", width: "100%", objectFit: "cover" }}
          alt="MobileImage"
        />
      )}
    </>
  );
};

export default Portal;
