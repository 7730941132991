import React, { useState, useEffect } from "react";
import { courseActions } from "../../../../services/CoursesServices";
import {
  Button,
  Col,
  Container,
  InputGroup,
  InputGroupAddon,
  Row,
  UncontrolledTooltip,
  Input,
} from "reactstrap";
import View from "../../../../assets/img/view.png";
import Edit from "../../../../assets/img/edit.png";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import overlayFactory from "react-bootstrap-table2-overlay";
import { isBrowser, isDesktop, isMobile } from "react-device-detect";
import Select from "react-select";
import AddEdit from "./AddEdit";
import Add from "../../../../assets/img/ADMISSION.png";
import states from "../../States/States";
import { educationActions } from "../../../../services/EducationSelectionService";

const Courses = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [gradeTypes, setGradeTypes] = useState([]);
  const [gradeTypeSelection, setGradeTypeSelection] = useState([]);
  const [gradeTypeFilter, setGradeTypeFilter] = useState("");
  const [action, setAction] = useState("");
  const [updateTable, setUpdateTable] = useState(false);
  const [selectOptions, setSelectOptions] = useState({
    status: [
      { value: 0, label: "InActive" },
      { value: 1, label: "Active" },
    ],
  });

  const [course, setCourse] = useState(states.blankCourse);

  useEffect(() => {
    educationActions.getApplications(
      null,
      null,
      setGradeTypes,
      null,
      null,
      null,
      null,
      null
    );
  }, []);

  useEffect(() => {
    setLoading(true);
    courseActions.getCourses(setData, setLoading);
  }, [updateTable]);

  useEffect(() => {
    if (gradeTypes.length >= 1) {
      let grTypes = gradeTypes.filter((type) => {
        return type.id == 4 || type.id == 5;
      });

      let newGrTypes = grTypes.map((gt) => {
        return { value: gt.id, label: gt.name };
      });
      setGradeTypeSelection(newGrTypes);
    }
  }, [gradeTypes]);

  const columns = [
    {
      dataField: "grade_type_id",
      text: "Grade Type",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
      formatter: (cell) => {
        if (gradeTypes.length) {
          const gradeType = gradeTypes.filter((gt) => {
            return cell == gt.id;
          });
          return gradeType[0].name;
        }
      },
    },
    {
      dataField: "name",
      text: "Name",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "description",
      text: "Description",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "enabled",
      text: "Status",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
      formatter: (cell) => {
        return cell ? "Active" : "InActive";
      },
    },
    {
      dataField: "created_at",
      text: "Date Created",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "id",
      text: "Action",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
      formatter: (cell, row) => {
        return (
          <div
            className="px-2 justify-content-center d-flex"
            style={{ minWidth: "50px" }}
          >
            <Button
              id={"upload-btn" + cell}
              onClick={() => toggleAddOrEdit(row, "edit")}
              className="btn-warning mr-2"
            >
              <img src={Edit} style={{ height: "1.5rem", objectFit: "fill" }} />
            </Button>
            <UncontrolledTooltip placement="top" target={"upload-btn" + cell}>
              Edit
            </UncontrolledTooltip>
          </div>
        );
      },
    },
  ];

  const [addOrEditModal, setAddOrEditModal] = useState(false);
  const toggleAddOrEdit = (details, action) => {
    setCourse(states.blankCourse);
    setAction("");
    if (!addOrEditModal) {
      setAction(action);
      if (action == "edit") {
        setCourse(details);
      }
      setAddOrEditModal(!addOrEditModal);
    } else {
      setAddOrEditModal(!addOrEditModal);
    }
  };

  const NoDataIndication = () => <div>No Data</div>;

  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (data.length >= 1) {
      let newArr = [];
      //search INPUT
      let filteredSections = data.filter((d) => {
        return (
          d.name.toLowerCase().includes(searchText.toLowerCase()) ||
          d.description.toLowerCase().includes(searchText.toLowerCase())
        );
      });

      newArr = filteredSections;
      //filter
      if (gradeTypeFilter !== "") {
        let filteredGradeTypes = newArr.filter((f) => {
          return f.grade_type_id == gradeTypeFilter;
        });

        newArr = filteredGradeTypes;
      }

      setFilteredData(newArr);
    }
  }, [gradeTypeFilter, searchText, data]);

  return (
    <div className="p-4">
      {addOrEditModal ? (
        <AddEdit
          addOrEditModal={addOrEditModal}
          setAddOrEditModal={setAddOrEditModal}
          toggleAddOrEdit={toggleAddOrEdit}
          course={course}
          action={action}
          setUpdateTable={setUpdateTable}
          updateTable={updateTable}
          selectOptions={selectOptions}
          gradeTypeSelection={gradeTypeSelection}
        />
      ) : (
        ""
      )}
      <Row>
        <Col md="12" xs="12" className="mb-3">
          <Button
            className="btn bg-danger-edit"
            onClick={() => toggleAddOrEdit("", "add")}
          >
            <img src={Add} style={{ height: "1rem" }} />
          </Button>
        </Col>
        <Col md="4" xs="12">
          <Select
            options={gradeTypeSelection}
            onChange={(e) => {
              setGradeTypeFilter(e.value);
              return e;
            }}
            defaultValue=""
          />
          <p>
            <small className="font-weight-bolder">
              <i className="fa fa-lightbulb-o"></i>&nbsp;Filter by Grade Type
            </small>
          </p>
        </Col>
        <Col md="4" xs="12">
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <Button
                type="button"
                color="primary"
                id="search-button"
                style={{ zIndex: "0" }}
              >
                <i className="fa fa-search"></i>
              </Button>
            </InputGroupAddon>
            <Input
              type="text"
              id="search-text"
              name="search-text"
              placeholder="Search"
              onChange={(e) => setSearchText(e.target.value)}
            />
          </InputGroup>
          <p>
            <small className="font-weight-bolder">
              <i className="fa fa-lightbulb-o"></i>&nbsp;Search by Name and
              Description
            </small>
          </p>
        </Col>
      </Row>

      <BootstrapTable
        wrapperClasses="table-responsive mb-3 text-center"
        bootstrap4
        striped
        keyField="id"
        data={filteredData}
        columns={columns}
        loading={loading}
        classes={isMobile ? "mobileTable" : "desktopTable"}
        pagination={paginationFactory()}
        overlay={overlayFactory({
          spinner: true,
          styles: {
            overlay: (base) => ({
              ...base,
              background: "rgba(0, 0, 0, 0.5)",
            }),
          },
        })}
        noDataIndication={() => <NoDataIndication />}
      />
    </div>
  );
};

export default Courses;
