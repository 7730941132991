import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Row,
  Label,
  Input,
  Form,
  Spinner,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { isDesktop } from "react-device-detect";
import { applicationActions } from "../../../services/ApplicationServices";

const MyUploadedFiles = ({
  myUploadedFileOpen,
  toggleUploadFile,
  basicForm,
  setBasicForm,
  tesdaForm,
  setTesdaForm,
  scholarshipForm,
}) => {
  const admissionAnswers = localStorage.getItem("admissionAnswers")
    ? JSON.parse(localStorage.getItem("admissionAnswers"))
    : null;
  const { register, handleSubmit, errors, control, setValue, getValues } =
    useForm();
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const role = localStorage.getItem("role") ? localStorage.getItem("role") : "";
  const onSubmit = async (data) => {
    let files = [];

    if (basicForm.form138 && basicForm.form138.name) {
      files.push({
        filename: basicForm.form138.name,
        document_type_id: 1,
        document: await convertBase64(basicForm.form138),
        id: basicForm.form138_id ? basicForm.form138_id : "",
      });
    }

    if (basicForm.form137 && basicForm.form137.name) {
      files.push({
        filename: basicForm.form137.name,
        document_type_id: 2,
        document: await convertBase64(basicForm.form137),
        id: basicForm.form137_id ? basicForm.form137_id : "",
      });
    }

    if (basicForm.good_moral && basicForm.good_moral.name) {
      files.push({
        filename: basicForm.good_moral.name,
        document_type_id: 3,
        document: await convertBase64(basicForm.good_moral),
        id: basicForm.good_moral_id ? basicForm.good_moral_id : "",
      });
    }

    if (basicForm.ncae && basicForm.ncae.name) {
      files.push({
        filename: basicForm.ncae.name,
        document_type_id: 4,
        document: await convertBase64(basicForm.ncae),
        id: basicForm.ncae_id ? basicForm.ncae_id : "",
      });
    }

    if (basicForm.birth_certificate && basicForm.birth_certificate.name) {
      files.push({
        filename: basicForm.birth_certificate.name,
        document_type_id: 5,
        document: await convertBase64(basicForm.birth_certificate),
        id: basicForm.birth_certificate_id
          ? basicForm.birth_certificate_id
          : "",
      });
    }

    if (basicForm.esc_file && basicForm.esc_file.name) {
      files.push({
        filename: basicForm.esc_file.name,
        document_type_id: 6,
        document: await convertBase64(basicForm.esc_file),
        id: basicForm.esc_file_id ? basicForm.esc_file_id : "",
      });
    }

    if (basicForm.picture && basicForm.picture.name) {
      files.push({
        filename: basicForm.picture.name,
        document_type_id: 7,
        document: await convertBase64(basicForm.picture),
        id: basicForm.picture_id ? basicForm.picture_id : "",
      });
    }

    if (
      scholarshipForm.academic_scholarship_proof &&
      scholarshipForm.academic_scholarship_proof.name
    ) {
      files.push({
        filename: scholarshipForm.academic_scholarship_proof.name,
        document_type_id: 8,
        document: await convertBase64(
          scholarshipForm.academic_scholarship_proof
        ),
        id: basicForm.academic_scholarship_proof_id
          ? basicForm.academic_scholarship_proof_id
          : "",
      });
    }

    if (basicForm.cert_of_residency && basicForm.cert_of_residency.name) {
      files.push({
        filename: basicForm.cert_of_residency.name,
        document_type_id: 10,
        document: await convertBase64(basicForm.cert_of_residency),
        id: basicForm.cert_of_residency_id
          ? basicForm.cert_of_residency_id
          : "",
      });
    }

    if (basicForm.copy_of_grades && basicForm.copy_of_grades.name) {
      files.push({
        filename: basicForm.copy_of_grades.name,
        document_type_id: 11,
        document: await convertBase64(basicForm.copy_of_grades),
        id: basicForm.copy_of_grades_id ? basicForm.copy_of_grades_id : "",
      });
    }

    if (basicForm.shs_diploma && basicForm.shs_diploma.name) {
      files.push({
        filename: basicForm.shs_diploma.name,
        document_type_id: 12,
        document: await convertBase64(basicForm.shs_diploma),
        id: basicForm.shs_diploma_id ? basicForm.shs_diploma_id : "",
      });
    }

    if (basicForm.qvr_file && basicForm.qvr_file.name) {
      files.push({
        filename: basicForm.qvr_file.name,
        document_type_id: 13,
        document: await convertBase64(basicForm.qvr_file),
        id: basicForm.qvr_file_id ? basicForm.qvr_file_id : "",
      });
    }

    if (tesdaForm.tesda_form && tesdaForm.tesda_form.name) {
      files.push({
        filename: tesdaForm.tesda_form.name,
        document_type_id: 9,
        document: await convertBase64(tesdaForm.tesda_form),
        id: tesdaForm.tesda_form ? tesdaForm.tesda_form : "",
      });
    }

    let dataToSubmit = {};
    if (
      admissionAnswers.education_type == "Basic Education" ||
      admissionAnswers.education_type == "Tertiary Education"
    ) {
      dataToSubmit.application_id = basicForm.id ? basicForm.id : "";
      dataToSubmit.attachments = files;
    } else {
      dataToSubmit.application_id = tesdaForm.id ? tesdaForm.id : "";
      dataToSubmit.attachments = files;
    }

    setButtonSpinner(true);
    applicationActions.uploadFiles(dataToSubmit, setButtonSpinner);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const escGradeLevelsArr = [
    "Grade 7",
    "Grade 8",
    "Grade 9",
    "Grade 10",
    "Grade 11",
    "Grade 12",
  ];

  const qvrGradeLevelsArr = ["Grade 11", "Grade 12"];

  return (
    <Modal
      isOpen={myUploadedFileOpen}
      toggle={toggleUploadFile}
      size="lg"
      backdrop="static"
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader charCode={role == "Registrar" ? "" : "X"} className={role == "Registrar" ? "bg-danger-edit text-white" : ""} toggle={toggleUploadFile}>Upload Documents</ModalHeader>
        <ModalBody>
          {admissionAnswers &&
          admissionAnswers.education_type == "Basic Education" ? (
            <>
              <Col
                md="12"
                xs="12"
                className={isDesktop ? "pl-5" : "px-3 w-100"}
              >
                <Row className="mb-5">
                  <div className="w-100 px-3">
                    <Label
                      className="text-danger-edit  font-weight-bolder mb-1"
                      style={{ backgroundColor: "white" }}
                    >
                      Admission Requirements
                    </Label>
                  </div>
                  <div className="px-3">
                    <p className="text-secondary">
                      <i>
                        Make sure to label the attachments properly, File must
                        be less than 3 MB of .jpg, png or pdf file.
                      </i>
                    </p>
                    <hr />
                    <Row className="flex-column">
                      <div className="d-flex mb-3">
                        <Col md="6" xs="6">
                          <Label className="text-secondary">
                            Form 138 (Grade 6-10 and Card if incoming Grade
                            7-11)
                          </Label>
                        </Col>
                        <Col md="6" xs="6" className="px-3">
                          <div className="d-flex flex-column">
                            <div
                              className="fileUpload btn btn-warning text-white rounded-pill p-2"
                              style={{ width: "110px" }}
                            >
                              <span>Upload</span>
                              <Controller
                                control={control}
                                name="form138"
                                defaultValue={basicForm.form138}
                                rules={{ required: false }}
                                invalid={errors.form138 ? true : false}
                                render={({ onChange, value, onBlur, name }) => (
                                  <Input
                                    type="file"
                                    name="form138"
                                    id="uploadBtn"
                                    type="file"
                                    className="upload"
                                    onChange={(e) =>
                                      setBasicForm({
                                        ...basicForm,
                                        form138: e.target.files[0],
                                      })
                                    }
                                    defaultValue={[]}
                                    // style={{ width: "110px" }}
                                  />
                                )}
                              />
                            </div>
                            <Label className="text-secondary">
                              {basicForm.form138 ? basicForm.form138.name : ""}
                            </Label>
                          </div>
                        </Col>
                      </div>

                      <hr />
                      <div className="d-flex mb-3">
                        <Col md="6" xs="6">
                          <Label className="text-secondary">
                            Form 137 (School Student Permanent Record Grade
                            6-10)
                          </Label>
                        </Col>
                        <Col md="6" xs="6" className="mx-auto px-auto">
                          <div className="d-flex flex-column">
                            <div
                              className="fileUpload btn btn-warning text-white rounded-pill p-2"
                              style={{ width: "110px" }}
                            >
                              <span>Upload</span>
                              <Controller
                                control={control}
                                name="form137"
                                defaultValue={basicForm.form137}
                                rules={{ required: false }}
                                invalid={errors.form137 ? true : false}
                                render={({ onChange, value, onBlur, name }) => (
                                  <Input
                                    type="file"
                                    name="form137"
                                    id="uploadBtn"
                                    type="file"
                                    className="upload"
                                    onChange={(e) =>
                                      setBasicForm({
                                        ...basicForm,
                                        form137: e.target.files[0],
                                      })
                                    }
                                    defaultValue={[]}
                                    // style={{ width: "110px" }}
                                  />
                                )}
                              />
                            </div>
                            <Label className="text-secondary">
                              {basicForm.form137 ? basicForm.form137.name : ""}
                            </Label>
                          </div>
                        </Col>
                      </div>
                      <div className="d-flex mb-3">
                        <Col md="6" xs="6">
                          <Label className="text-secondary">
                            (2"x2") picture (Colored with white background and
                            name tag)
                          </Label>
                        </Col>
                        <Col md="6" xs="6">
                          <div className="d-flex flex-column">
                            <div
                              className="fileUpload btn btn-warning text-white rounded-pill p-2"
                              style={{ width: "110px" }}
                            >
                              <span>Upload</span>
                              <Controller
                                control={control}
                                name="picture"
                                defaultValue={basicForm.picture}
                                rules={{ required: false }}
                                invalid={errors.picture ? true : false}
                                render={({ onChange, value, onBlur, name }) => (
                                  <Input
                                    type="file"
                                    name="picture"
                                    id="uploadBtn"
                                    type="file"
                                    className="upload"
                                    onChange={(e) =>
                                      setBasicForm({
                                        ...basicForm,
                                        picture: e.target.files[0],
                                      })
                                    }
                                    defaultValue={[]}
                                    // style={{ width: "110px" }}
                                  />
                                )}
                              />
                            </div>
                            <Label className="text-secondary">
                              {basicForm.picture ? basicForm.picture.name : ""}
                            </Label>
                          </div>
                        </Col>
                      </div>
                      <div className="d-flex mb-3">
                        <Col md="6" xs="6">
                          <Label className="text-secondary">
                            Certificate of Good Moral Character signed by School
                            Principal/Guidance Officer with documentary stamp.
                          </Label>
                        </Col>
                        <Col md="6" xs="6" className="mx-auto px-auto">
                          <div className="d-flex flex-column">
                            <div
                              className="fileUpload btn btn-warning text-white rounded-pill p-2"
                              style={{ width: "110px" }}
                            >
                              <span>Upload</span>
                              <Controller
                                control={control}
                                name="good_moral"
                                defaultValue={basicForm.good_moral}
                                rules={{ required: false }}
                                invalid={errors.good_moral ? true : false}
                                render={({ onChange, value, onBlur, name }) => (
                                  <Input
                                    type="file"
                                    name="good_moral"
                                    id="uploadBtn"
                                    type="file"
                                    className="upload"
                                    onChange={(e) =>
                                      setBasicForm({
                                        ...basicForm,
                                        good_moral: e.target.files[0],
                                      })
                                    }
                                    defaultValue={[]}
                                    // style={{ width: "110px" }}
                                  />
                                )}
                              />
                            </div>
                            <Label className="text-secondary">
                              {basicForm.good_moral
                                ? basicForm.good_moral.name
                                : ""}
                            </Label>
                          </div>
                        </Col>
                      </div>
                      <hr />
                      <div className="d-flex mb-3">
                        <Col md="6" xs="6">
                          <Label className="text-secondary">
                            National Career Assessment Examination (NCAE) result
                            for Grades 10-12.
                          </Label>
                        </Col>
                        <Col md="6" xs="6" className="mx-auto px-auto">
                          <div className="d-flex flex-column">
                            <div
                              className="fileUpload btn btn-warning text-white rounded-pill p-2"
                              style={{ width: "110px" }}
                            >
                              <span>Upload</span>
                              <Controller
                                control={control}
                                name="ncae"
                                defaultValue={basicForm.ncae}
                                rules={{ required: false }}
                                invalid={errors.ncae ? true : false}
                                render={({ onChange, value, onBlur, name }) => (
                                  <Input
                                    type="file"
                                    name="ncae"
                                    id="uploadBtn"
                                    type="file"
                                    className="upload"
                                    onChange={(e) =>
                                      setBasicForm({
                                        ...basicForm,
                                        ncae: e.target.files[0],
                                      })
                                    }
                                    defaultValue={[]}
                                    // style={{ width: "110px" }}
                                  />
                                )}
                              />
                            </div>
                            <Label className="text-secondary">
                              {basicForm.ncae ? basicForm.ncae.name : ""}
                            </Label>
                          </div>
                        </Col>
                      </div>
                      <div className="d-flex mb-3">
                        <Col md="6" xs="6">
                          <Label className="text-secondary">
                            Photocopy of PSA authenticated Birth Certificate.
                          </Label>
                        </Col>
                        <Col md="6" xs="6" className="mx-auto px-auto">
                          <div className="d-flex flex-column">
                            <div
                              className="fileUpload btn btn-warning text-white rounded-pill p-2"
                              style={{ width: "110px" }}
                            >
                              <span>Upload</span>
                              <Controller
                                control={control}
                                name="birth_certificate"
                                defaultValue={basicForm.birth_certificate}
                                rules={{ required: false }}
                                invalid={
                                  errors.birth_certificate ? true : false
                                }
                                render={({ onChange, value, onBlur, name }) => (
                                  <Input
                                    type="file"
                                    name="birth_certificate"
                                    id="uploadBtn"
                                    type="file"
                                    className="upload"
                                    onChange={(e) =>
                                      setBasicForm({
                                        ...basicForm,
                                        birth_certificate: e.target.files[0],
                                      })
                                    }
                                    defaultValue={[]}
                                    // style={{ width: "110px" }}
                                  />
                                )}
                              />
                            </div>
                            <Label className="text-secondary">
                              {basicForm.birth_certificate
                                ? basicForm.birth_certificate.name
                                : ""}
                            </Label>
                          </div>
                        </Col>
                      </div>
                      {admissionAnswers.grade_year_level &&
                      escGradeLevelsArr.includes(
                        admissionAnswers.grade_year_level.label
                      ) ? (
                        <>
                          <div className="d-flex mb-3">
                            <Col md="6" xs="6">
                              <Label
                                htmlFor="is_esc"
                                className="text-secondary"
                              >
                                ESC Certificate
                              </Label>
                            </Col>
                            <Col md="6" xs="12">
                              <div className="d-flex flex-column">
                                <div
                                  className="fileUpload btn btn-warning text-white rounded-pill p-2"
                                  style={{ width: "110px" }}
                                >
                                  <span>Upload</span>
                                  <Controller
                                    control={control}
                                    name="esc_file"
                                    defaultValue={basicForm.esc_file}
                                    rules={{ required: false }}
                                    invalid={errors.esc_file ? true : false}
                                    render={({
                                      onChange,
                                      value,
                                      onBlur,
                                      name,
                                    }) => (
                                      <Input
                                        type="file"
                                        name="esc_file"
                                        id="uploadBtn"
                                        className="upload"
                                        onChange={(e) =>
                                          setBasicForm({
                                            ...basicForm,
                                            esc_file: e.target.files[0],
                                          })
                                        }
                                        defaultValue={[]}
                                        // style={{ width: "110px" }}
                                      />
                                    )}
                                  />
                                </div>
                                <Label className="text-secondary">
                                  {basicForm.esc_file
                                    ? basicForm.esc_file.name
                                    : ""}
                                </Label>
                              </div>
                            </Col>
                          </div>
                          {admissionAnswers.grade_year_level &&
                          qvrGradeLevelsArr.includes(
                            admissionAnswers.grade_year_level.label
                          ) ? (
                            <div className="d-flex mb-3">
                              <Col md="6" xs="6">
                                <Label
                                  htmlFor="is_esc"
                                  className="text-secondary"
                                >
                                  QVR Certificate
                                </Label>
                              </Col>
                              <Col md="6" xs="12">
                                <div className="d-flex flex-column">
                                  <div
                                    className="fileUpload btn btn-warning text-white rounded-pill p-2"
                                    style={{ width: "110px" }}
                                  >
                                    <span>Upload</span>
                                    <Controller
                                      control={control}
                                      name="qvr_file"
                                      defaultValue={basicForm.qvr_file}
                                      rules={{ required: false }}
                                      invalid={errors.qvr_file ? true : false}
                                      render={({
                                        onChange,
                                        value,
                                        onBlur,
                                        name,
                                      }) => (
                                        <Input
                                          type="file"
                                          name="qvr_file"
                                          id="uploadBtn"
                                          className="upload"
                                          onChange={(e) =>
                                            setBasicForm({
                                              ...basicForm,
                                              qvr_file: e.target.files[0],
                                            })
                                          }
                                          defaultValue={[]}
                                          // style={{ width: "110px" }}
                                        />
                                      )}
                                    />
                                  </div>
                                  <Label className="text-secondary">
                                    {basicForm.qvr_file
                                      ? basicForm.qvr_file.name
                                      : ""}
                                  </Label>
                                </div>
                              </Col>
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </Row>
                  </div>
                </Row>
                {basicForm.id != "" ? (
                  <>
                    <Row className="mb-5">
                      <div className="w-100 px-3">
                        <Label
                          className="text-danger-edit  font-weight-bolder mb-2"
                          style={{ backgroundColor: "white" }}
                        >
                          Submitted Documents
                        </Label>
                      </div>
                      <Col md="12" xs="12">
                        <Label className="text-secondary">
                          <strong>Form138 - </strong>{" "}
                          {basicForm.id != "" && basicForm.form138_file_name
                            ? basicForm.form138_file_name
                            : ""}
                        </Label>
                      </Col>
                      <Col md="12" xs="12">
                        <Label className="text-secondary">
                          <strong>Form137 - </strong>{" "}
                          {basicForm.id != "" && basicForm.form137_file_name
                            ? basicForm.form137_file_name
                            : ""}
                        </Label>
                      </Col>
                      <Col md="12" xs="12">
                        <Label className="text-secondary">
                          <strong>2"x2" Picture - </strong>{" "}
                          {basicForm.id != "" && basicForm.picture_file_name
                            ? basicForm.picture_file_name
                            : ""}
                        </Label>
                      </Col>
                      <Col md="12" xs="12">
                        <Label className="text-secondary">
                          <strong>Good Moral - </strong>{" "}
                          {basicForm.id != "" && basicForm.good_moral_file_name
                            ? basicForm.good_moral_file_name
                            : ""}
                        </Label>
                      </Col>
                      <Col md="12" xs="12">
                        <Label className="text-secondary">
                          <strong>NCAE - </strong>{" "}
                          {basicForm.id != "" && basicForm.ncae_file_name
                            ? basicForm.ncae_file_name
                            : ""}
                        </Label>
                      </Col>
                      <Col md="12" xs="12">
                        <Label className="text-secondary">
                          <strong>PSA (Birth Certificate) - </strong>{" "}
                          {basicForm.id != "" &&
                          basicForm.birth_certificate_file_name
                            ? basicForm.birth_certificate_file_name
                            : ""}
                        </Label>
                      </Col>
                      {admissionAnswers.grade_year_level &&
                      escGradeLevelsArr.includes(
                        admissionAnswers.grade_year_level.label
                      ) ? (
                        <>
                          {admissionAnswers.grade_year_level &&
                          qvrGradeLevelsArr.includes(
                            admissionAnswers.grade_year_level.label
                          ) ? (
                            <Col md="12" xs="12">
                              <Label className="text-secondary">
                                <strong>QVR Certificate - </strong>{" "}
                                {basicForm.id != "" && basicForm.qvr_file_name
                                  ? basicForm.qvr_file_name
                                  : ""}
                              </Label>
                            </Col>
                          ) : (
                            ""
                          )}
                          <Col md="12" xs="12">
                            <Label className="text-secondary">
                              <strong>ESC Certificate - </strong>{" "}
                              {basicForm.id != "" && basicForm.esc_file_name
                                ? basicForm.esc_file_name
                                : ""}
                            </Label>
                          </Col>
                        </>
                      ) : (
                        ""
                      )}
                    </Row>
                  </>
                ) : (
                  ""
                )}
              </Col>
            </>
          ) : admissionAnswers &&
            admissionAnswers.education_type == "Tertiary Education" ? (
            <>
              <Row className="mb-5 justify-content-center text-center">
                <div className="w-100 px-3">
                  <Label
                    className="text-danger-edit  font-weight-bolder mb-1"
                    style={{ backgroundColor: "white" }}
                  >
                    Admission Requirements
                  </Label>
                </div>
                <div className="px-3">
                  <p className="text-secondary">
                    <i>
                      Make sure to label the attachments properly, File must be
                      less than 3 MB of .jpg, png or pdf file.
                    </i>
                  </p>
                  <hr />
                  <Row className="flex-column">
                    <div className="d-flex mb-3">
                      <Col md="6" xs="6">
                        <Label className="text-secondary">
                          Form 138 (Grade 12 Senior High School Card)
                        </Label>
                      </Col>
                      <Col md="6" xs="6" className="px-3">
                        <div className="d-flex flex-column">
                          <div
                            className="fileUpload btn btn-warning text-white rounded-pill p-2"
                            style={{ width: "110px" }}
                          >
                            <span>Upload</span>
                            <Controller
                              control={control}
                              name="form138"
                              defaultValue={basicForm.form138}
                              rules={{ required: false }}
                              invalid={errors.form138 ? true : false}
                              render={({ onChange, value, onBlur, name }) => (
                                <Input
                                  type="file"
                                  name="form138"
                                  id="uploadBtn"
                                  type="file"
                                  className="upload"
                                  onChange={(e) =>
                                    setBasicForm({
                                      ...basicForm,
                                      form138: e.target.files[0],
                                    })
                                  }
                                  defaultValue={[]}
                                  // style={{ width: "110px" }}
                                />
                              )}
                            />
                          </div>
                          <Label className="text-secondary">
                            {basicForm.form138 ? basicForm.form138.name : ""}
                          </Label>
                        </div>
                      </Col>
                    </div>

                    <hr />
                    <div className="d-flex mb-3">
                      <Col md="6" xs="6">
                        <Label className="text-secondary">
                          Form 137 (Senior High School (SHS) Permanent Record)
                          with annotation "Eligible to Enter College Education")
                        </Label>
                      </Col>
                      <Col md="6" xs="6" className="mx-auto px-auto">
                        <div className="d-flex flex-column">
                          <div
                            className="fileUpload btn btn-warning text-white rounded-pill p-2"
                            style={{ width: "110px" }}
                          >
                            <span>Upload</span>
                            <Controller
                              control={control}
                              name="form137"
                              defaultValue={basicForm.form137}
                              rules={{ required: false }}
                              invalid={errors.form137 ? true : false}
                              render={({ onChange, value, onBlur, name }) => (
                                <Input
                                  type="file"
                                  name="form137"
                                  id="uploadBtn"
                                  type="file"
                                  className="upload"
                                  onChange={(e) => {
                                    setBasicForm({
                                      ...basicForm,
                                      form137: e.target.files[0],
                                    });
                                  }}
                                  defaultValue={[]}
                                  // style={{ width: "110px" }}
                                />
                              )}
                            />
                          </div>
                          <Label className="text-secondary">
                            {basicForm.form137 ? basicForm.form137.name : ""}
                          </Label>
                        </div>
                      </Col>
                    </div>

                    <hr />
                    <div className="d-flex mb-3">
                      <Col md="6" xs="6">
                        <Label className="text-secondary">
                          Copy of grades (if College Undergraduate)
                        </Label>
                      </Col>
                      <Col md="6" xs="6" className="mx-auto px-auto">
                        <div className="d-flex flex-column">
                          <div
                            className="fileUpload btn btn-warning text-white rounded-pill p-2"
                            style={{ width: "110px" }}
                          >
                            <span>Upload</span>
                            <Controller
                              control={control}
                              name="copy_of_grades"
                              defaultValue={[]}
                              rules={{ required: false }}
                              invalid={errors.copy_of_grades ? true : false}
                              render={({ onChange, value, onBlur, name }) => (
                                <Input
                                  type="file"
                                  name="copy_of_grades"
                                  id="uploadBtn"
                                  type="file"
                                  className="upload"
                                  onChange={(e) =>
                                    setBasicForm({
                                      ...basicForm,
                                      copy_of_grades: e.target.files[0],
                                    })
                                  }
                                  defaultValue={[]}
                                  // style={{ width: "110px" }}
                                />
                              )}
                            />
                          </div>
                          <Label className="text-secondary">
                            {basicForm.copy_of_grades
                              ? basicForm.copy_of_grades.name
                              : ""}
                          </Label>
                        </div>
                      </Col>
                    </div>

                    <hr />
                    <div className="d-flex mb-3">
                      <Col md="6" xs="6">
                        <Label className="text-secondary">
                          (2"x2") picture (Colored with white background and
                          name tag)
                        </Label>
                      </Col>
                      <Col md="6" xs="6">
                        <div className="d-flex flex-column">
                          <div
                            className="fileUpload btn btn-warning text-white rounded-pill p-2"
                            style={{ width: "110px" }}
                          >
                            <span>Upload</span>
                            <Controller
                              control={control}
                              name="picture"
                              defaultValue={basicForm.picture}
                              rules={{ required: false }}
                              invalid={errors.picture ? true : false}
                              render={({ onChange, value, onBlur, name }) => (
                                <Input
                                  type="file"
                                  name="picture"
                                  id="uploadBtn"
                                  type="file"
                                  className="upload"
                                  onChange={(e) =>
                                    setBasicForm({
                                      ...basicForm,
                                      picture: e.target.files[0],
                                    })
                                  }
                                  defaultValue={[]}
                                  // style={{ width: "110px" }}
                                />
                              )}
                            />
                          </div>
                          <Label className="text-secondary">
                            {basicForm.picture ? basicForm.picture.name : ""}
                          </Label>
                        </div>
                      </Col>
                    </div>
                    <div className="d-flex mb-3">
                      <Col md="6" xs="6">
                        <Label className="text-secondary">
                          Certificate of Good Moral Character signed by School
                          Principal/Guidance Officer with documentary stamp.
                        </Label>
                      </Col>
                      <Col md="6" xs="6" className="mx-auto px-auto">
                        <div className="d-flex flex-column">
                          <div
                            className="fileUpload btn btn-warning text-white rounded-pill p-2"
                            style={{ width: "110px" }}
                          >
                            <span>Upload</span>
                            <Controller
                              control={control}
                              name="good_moral"
                              defaultValue={basicForm.good_moral}
                              rules={{ required: false }}
                              invalid={errors.good_moral ? true : false}
                              render={({ onChange, value, onBlur, name }) => (
                                <Input
                                  type="file"
                                  name="good_moral"
                                  id="uploadBtn"
                                  type="file"
                                  className="upload"
                                  onChange={(e) =>
                                    setBasicForm({
                                      ...basicForm,
                                      good_moral: e.target.files[0],
                                    })
                                  }
                                  defaultValue={[]}
                                  // style={{ width: "110px" }}
                                />
                              )}
                            />
                          </div>
                          <Label className="text-secondary">
                            {basicForm.good_moral
                              ? basicForm.good_moral.name
                              : ""}
                          </Label>
                        </div>
                      </Col>
                    </div>
                    <div className="d-flex mb-3">
                      <Col md="6" xs="6">
                        <Label className="text-secondary">
                          Certified photocopy of SHS Diploma with LRN, Track and
                          indicated and/or National Assessment Test (NAT) for
                          Grade 12.
                        </Label>
                      </Col>
                      <Col md="6" xs="6" className="mx-auto px-auto">
                        <div className="d-flex flex-column">
                          <div
                            className="fileUpload btn btn-warning text-white rounded-pill p-2"
                            style={{ width: "110px" }}
                          >
                            <span>Upload</span>
                            <Controller
                              control={control}
                              name="shs_diploma"
                              defaultValue={basicForm.shs_diploma}
                              rules={{ required: false }}
                              invalid={errors.shs_diploma ? true : false}
                              render={({ onChange, value, onBlur, name }) => (
                                <Input
                                  type="file"
                                  name="shs_diploma"
                                  id="uploadBtn"
                                  type="file"
                                  className="upload"
                                  onChange={(e) =>
                                    setBasicForm({
                                      ...basicForm,
                                      shs_diploma: e.target.files[0],
                                    })
                                  }
                                  defaultValue={[]}
                                  // style={{ width: "110px" }}
                                />
                              )}
                            />
                          </div>
                          <Label className="text-secondary">
                            {basicForm.shs_diploma
                              ? basicForm.shs_diploma.name
                              : ""}
                          </Label>
                        </div>
                      </Col>
                    </div>
                    <hr />
                    <div className="d-flex mb-3">
                      <Col md="6" xs="6">
                        <Label className="text-secondary">
                          National Career Assessment Examination (NCAE) result
                          for Grades 10-12.
                        </Label>
                      </Col>
                      <Col md="6" xs="6" className="mx-auto px-auto">
                        <div className="d-flex flex-column">
                          <div
                            className="fileUpload btn btn-warning text-white rounded-pill p-2"
                            style={{ width: "110px" }}
                          >
                            <span>Upload</span>
                            <Controller
                              control={control}
                              name="ncae"
                              defaultValue={basicForm.ncae}
                              rules={{ required: false }}
                              invalid={errors.ncae ? true : false}
                              render={({ onChange, value, onBlur, name }) => (
                                <Input
                                  type="file"
                                  name="ncae"
                                  id="uploadBtn"
                                  type="file"
                                  className="upload"
                                  onChange={(e) =>
                                    setBasicForm({
                                      ...basicForm,
                                      ncae: e.target.files[0],
                                    })
                                  }
                                  defaultValue={[]}
                                  // style={{ width: "110px" }}
                                />
                              )}
                            />
                          </div>
                          <Label className="text-secondary">
                            {basicForm.ncae ? basicForm.ncae.name : ""}
                          </Label>
                        </div>
                      </Col>
                    </div>
                    <div className="d-flex mb-3">
                      <Col md="6" xs="6">
                        <Label className="text-secondary">
                          Photocopy of PSA authenticated Birth Certificate.
                        </Label>
                      </Col>
                      <Col md="6" xs="6" className="mx-auto px-auto">
                        <div className="d-flex flex-column">
                          <div
                            className="fileUpload btn btn-warning text-white rounded-pill p-2"
                            style={{ width: "110px" }}
                          >
                            <span>Upload</span>
                            <Controller
                              control={control}
                              name="birth_certificate"
                              defaultValue={basicForm.birth_certificate}
                              rules={{ required: false }}
                              invalid={errors.birth_certificate ? true : false}
                              render={({ onChange, value, onBlur, name }) => (
                                <Input
                                  type="file"
                                  name="birth_certificate"
                                  id="uploadBtn"
                                  type="file"
                                  className="upload"
                                  onChange={(e) =>
                                    setBasicForm({
                                      ...basicForm,
                                      birth_certificate: e.target.files[0],
                                    })
                                  }
                                  defaultValue={[]}
                                  // style={{ width: "110px" }}
                                />
                              )}
                            />
                          </div>
                          <Label className="text-secondary">
                            {basicForm.birth_certificate
                              ? basicForm.birth_certificate.name
                              : ""}
                          </Label>
                        </div>
                      </Col>
                    </div>
                    <div className="d-flex mb-3">
                      <Col md="6" xs="6">
                        <Label className="text-secondary">
                          For TES applicants, Certificate of Residency (for
                          Pila, Laguna Residents)
                        </Label>
                      </Col>
                      <Col md="6" xs="6" className="mx-auto px-auto">
                        <div className="d-flex flex-column">
                          <div
                            className="fileUpload btn btn-warning text-white rounded-pill p-2"
                            style={{ width: "110px" }}
                          >
                            <span>Upload</span>
                            <Controller
                              control={control}
                              name="cert_of_residency"
                              defaultValue={[]}
                              rules={{ required: false }}
                              invalid={errors.cert_of_residency ? true : false}
                              render={({ onChange, value, onBlur, name }) => (
                                <Input
                                  type="file"
                                  name="cert_of_residency"
                                  id="uploadBtn"
                                  type="file"
                                  className="upload"
                                  onChange={(e) =>
                                    setBasicForm({
                                      ...basicForm,
                                      cert_of_residency: e.target.files[0],
                                    })
                                  }
                                  defaultValue={[]}
                                  // style={{ width: "110px" }}
                                />
                              )}
                            />
                          </div>
                          <Label className="text-secondary">
                            {basicForm.cert_of_residency
                              ? basicForm.cert_of_residency.name
                              : ""}
                          </Label>
                        </div>
                      </Col>
                    </div>
                  </Row>
                </div>
              </Row>
              {basicForm.id != "" ? (
                <>
                  <Row className="mb-5">
                    <div className="w-100 px-3">
                      <Label
                        className="text-danger-edit  font-weight-bolder mb-2"
                        style={{ backgroundColor: "white" }}
                      >
                        Submitted Documents
                      </Label>
                    </div>
                    <Col md="12" xs="12">
                      <Label className="text-secondary">
                        <strong>Form138 - </strong>{" "}
                        {basicForm.id != "" && basicForm.form138_file_name
                          ? basicForm.form138_file_name
                          : ""}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label className="text-secondary">
                        <strong>Form137 - </strong>{" "}
                        {basicForm.id != "" && basicForm.form137_file_name
                          ? basicForm.form137_file_name
                          : ""}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label className="text-secondary">
                        <strong>Copy of Grades - </strong>{" "}
                        {basicForm.id != "" &&
                        basicForm.copy_of_grades_file_name
                          ? basicForm.copy_of_grades_file_name
                          : ""}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label className="text-secondary">
                        <strong>2"x2" Picture - </strong>{" "}
                        {basicForm.id != "" && basicForm.picture_file_name
                          ? basicForm.picture_file_name
                          : ""}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label className="text-secondary">
                        <strong>Good Moral - </strong>{" "}
                        {basicForm.id != "" && basicForm.good_moral_file_name
                          ? basicForm.good_moral_file_name
                          : ""}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label className="text-secondary">
                        <strong>SHS Diploma - </strong>{" "}
                        {basicForm.id != "" && basicForm.shs_diploma_file_name
                          ? basicForm.shs_diploma_file_name
                          : ""}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label className="text-secondary">
                        <strong>NCAE - </strong>{" "}
                        {basicForm.id != "" && basicForm.ncae_file_name
                          ? basicForm.ncae_file_name
                          : ""}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label className="text-secondary">
                        <strong>PSA (Birth Certificate) - </strong>{" "}
                        {basicForm.id != "" &&
                        basicForm.birth_certificate_file_name
                          ? basicForm.birth_certificate_file_name
                          : ""}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label className="text-secondary">
                        <strong>Certificate of Residency</strong>{" "}
                        {basicForm.id != "" &&
                        basicForm.cert_of_residency_file_name
                          ? basicForm.cert_of_residency_file_name
                          : ""}
                      </Label>
                    </Col>
                  </Row>
                </>
              ) : (
                ""
              )}
            </>
          ) : (
            <>
              <div className="w-100 px-3">
                <Label
                  className="text-danger-edit  font-weight-bolder mb-1"
                  style={{ backgroundColor: "white" }}
                >
                  Admission Requirements
                </Label>
              </div>
              <div className="px-3">
                <p className="text-secondary">
                  <i>
                    Make sure to label the attachments properly, File must be
                    less than 3 MB of .jpg, png or pdf file.
                  </i>
                </p>
                <hr />
                <div className="d-flex mb-3">
                  <Col md="6" xs="12">
                    <Label className="text-secondary">Tesda Form</Label>
                  </Col>
                  <Col md="6" xs="12">
                    <div className="d-flex flex-column">
                      <div
                        className="fileUpload btn btn-warning text-white rounded-pill p-2"
                        style={{ width: "110px" }}
                      >
                        <span>Upload</span>
                        <Controller
                          control={control}
                          name="tesda_form"
                          defaultValue={tesdaForm.tesda_form}
                          rules={{ required: false }}
                          invalid={errors.tesda_form ? true : false}
                          render={({ onChange, value, onBlur, name }) => (
                            <Input
                              type="file"
                              name="tesda_form"
                              id="uploadBtn"
                              type="file"
                              className="upload"
                              onChange={(e) =>
                                setTesdaForm({
                                  ...tesdaForm,
                                  tesda_form: e.target.files[0],
                                })
                              }
                              // defaultValue={[]}
                              // style={{ width: "110px" }}
                            />
                          )}
                        />
                      </div>
                      <Label className="text-secondary">
                        {tesdaForm.tesda_form ? tesdaForm.tesda_form.name : ""}
                      </Label>
                    </div>
                  </Col>
                </div>

                {tesdaForm.tesda_form_file_name ? (
                  <Col md="12" xs="12">
                    <Label className="text-secondary">
                      <strong>Current File uploaded: </strong>
                      {tesdaForm.tesda_form_file_name}
                    </Label>
                  </Col>
                ) : (
                  ""
                )}
              </div>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            className="bg-warning text-white border-0"
            disabled={buttonSpinner ? true : false}
          >
            {buttonSpinner ? (
              <>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp;Processing...
              </>
            ) : (
              "Submit"
            )}
          </Button>
          <Button
            color="secondary"
            disabled={buttonSpinner ? true : false}
            onClick={toggleUploadFile}
          >
            Close
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default MyUploadedFiles;
