import {
    Container,
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavbarText,
    Row,
    Col,
    Label,
    Card,
    Button,
    CardImg,
    CardTitle,
    CardText,
    CardDeck,
    CardSubtitle,
    CardBody,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    TabContent,
    TabPane,
    UncontrolledTooltip
} from 'reactstrap';
import {
    BrowserView,
    MobileView,
    isDesktop,
    isMobile,
    isTablet
} from "react-device-detect";
import back from '../../../../assets/img/acads-tertiary/back.jpg';
import { Fade, Slide } from "react-reveal";
import { Fragment } from 'react';

const TertiaryAcads = ({
    handleChangeModal,
    tertiaryEducation
}) => {

    return (
        <>
            <Row className={isDesktop ? "ml-4 pl-2" : isTablet ? "ml-4 pl-2" : "text-center"} xs="12" md="12" lg="12">
                <Col className="mt-5" xs="12" md="1" lg="1">
                    <h3 className="font-weight-bolder text-danger-edit" id="tertiaryEducationMainTitle" onClick={() => handleChangeModal("Tertiary Education Main Title")} style={{ cursor: "pointer" }}>{tertiaryEducation.mainTitle || "<No Content>"}</h3>
                    <h3 className={isDesktop ? "font-weight-bolder text-danger-edit text-left" : "font-weight-bolder text-danger-edit mr-5 ml-5 mt-3 mb-2"} style={{ fontSize: "40px", borderTop: "4px solid" }}></h3>
                    <UncontrolledTooltip placement="top" target="tertiaryEducationMainTitle">
                        <h5>Click to Update</h5>
                    </UncontrolledTooltip>
                </Col>
                {/* <Col className={isDesktop ? "mt-5 pt-3 ml-5 pr-5" : "text-center"} xs="12" md="1" lg="1">
                        <h3 className={isDesktop ? "font-weight-bolder text-danger-edit text-left" : "font-weight-bolder text-danger-edit mr-5 ml-5 mt-3 mb-2"} style={{ fontSize: "40px", borderTop: "4px solid" }}></h3>
                    </Col> */}
            </Row>


            <Row className={isDesktop ? "d-flex mt-3 ml-5" : "d-flex mt-3 ml-5 mr-5 text-justify"} xs="12" md="12" lg="12">
                <p className="text-secondary font-weight-bolder w-75" id="tertiaryEducationMainDescription1" onClick={() => handleChangeModal("Tertiary Education Main Description", 'first')} style={{ cursor: "pointer" }}>{tertiaryEducation.mainDescription1 || "<No Content>"}</p>
                <UncontrolledTooltip placement="top" target="tertiaryEducationMainDescription1">
                    <h5>Click to Update</h5>
                </UncontrolledTooltip>

                <p className="text-secondary font-weight-bolder w-75" id="tertiaryEducationMainDescription2" onClick={() => handleChangeModal("Tertiary Education Main Description", 'second')} style={{ cursor: "pointer" }}>{tertiaryEducation.mainDescription2 || "<No Content>"}</p>
                <UncontrolledTooltip placement="top" target="tertiaryEducationMainDescription2">
                    <h5>Click to Update</h5>
                </UncontrolledTooltip>
            </Row>

            <Container fluid={true} className="bg-light">
                <Row lg="12" md="12" xs="12" className={isDesktop ? "align-items-center justify-content-center d-flex mt-5 pt-3 mb-5 ml-3" : "align-items-center justify-content-center d-flex mt-5 pt-3 mb-5 ml-3 mr-2"}>
                    <Col lg="8" md="8" xs="12" className="text-left mt-5">
                        <h4 className="text-danger-edit font-weight-bolder" id="tertiaryEducationFirstTitle" style={{ cursor: "pointer" }} onClick={() => handleChangeModal("Tertiary Education Title", 'first')}>{tertiaryEducation.firstTitle || "<No Content>"}</h4>
                        <UncontrolledTooltip placement="top" target="tertiaryEducationFirstTitle">
                            <h5>Click to Update</h5>
                        </UncontrolledTooltip>
                        <Label className={isDesktop ? "text-secondary mr-2" : "text-secondary mr-2 text-justify"} style={{ fontSize: "14px" }}>
                            <strong style={{ cursor: "pointer" }} id="tertiaryEducationFirstDescription" onClick={() => handleChangeModal("Tertiary Education Description", 'first')}>{tertiaryEducation.firstDescription || "<No Content>"}</strong>
                            <UncontrolledTooltip placement="top" target="tertiaryEducationFirstDescription">
                                <h5>Click to Update</h5>
                            </UncontrolledTooltip>
                            <br /><br />
                            <span id="tertiaryEducationFirsContentTitle" style={{ cursor: "pointer" }} onClick={() => handleChangeModal("Tertiary Education Content Title", 'first')}>{tertiaryEducation.firstContentTitle || "<No Content>"}</span>
                            <UncontrolledTooltip placement="top" target="tertiaryEducationFirsContentTitle">
                                <h5>Click to Update</h5>
                            </UncontrolledTooltip>
                            <ol id="tertiaryEducationFirsContents" style={{ cursor: "pointer" }} onClick={() => handleChangeModal("Tertiary Education Contents", 'first')}>
                                {tertiaryEducation.firstContents.length >= 1 ? tertiaryEducation.firstContents.map((content, key) => {
                                    return (
                                        <Fragment key={key}>
                                            <li>{key + 1 + ". "} {content.text}</li>
                                        </Fragment>
                                    )
                                }) : <li>{"<no content>"}</li>}
                            </ol>
                            <UncontrolledTooltip placement="top" target="tertiaryEducationFirsContents">
                                <h5>Click to Update</h5>
                            </UncontrolledTooltip>
                        </Label>
                    </Col>

                    <Col lg="4" md="4" xs="12">
                        {isDesktop ?
                            <Container className="acads-tertiary-cont">
                                <Slide right>
                                    <img src={back} style={{ width: "350px" }} className="tertiary-image back-image" />
                                    <img src={tertiaryEducation.firstImage} className="tertiary-image front-image" id="tertiaryEducationFirstImage" style={{ width: "350px", objectFit: "cover", height: "280px", cursor: "pointer" }} onClick={() => handleChangeModal("Tertiary Education Image", 'first')} />
                                </Slide>
                                <UncontrolledTooltip placement="top" target="tertiaryEducationFirstImage">
                                    <h5>Click to Update</h5>
                                </UncontrolledTooltip>
                            </Container>
                            :
                            <img src={tertiaryEducation.firstImage} style={{ width: "350px", objectFit: "cover", height: "280px" }} className="w-100" />
                        }
                    </Col>
                </Row>

                {isDesktop ?
                    <Row lg="12" md="12" xs="12" className="align-items-center justify-content-center d-flex pt-3">
                        <Col lg="4" md="4">
                            <Container className="acads-tertiary-cont2">
                                <Row>
                                    <Slide left>
                                        <img src={back} style={{ width: "350px" }} className="tertiary-image back-image2" />
                                        <img src={tertiaryEducation.secondImage} id="tertiaryEducationSecondImage" style={{ width: "350px", objectFit: "cover", height: "280px", cursor: "pointer" }} className="tertiary-image front-image2" onClick={() => handleChangeModal("Tertiary Education Image", 'second')} />
                                    </Slide>
                                    <UncontrolledTooltip placement="top" target="tertiaryEducationSecondImage">
                                        <h5>Click to Update</h5>
                                    </UncontrolledTooltip>
                                </Row>
                            </Container>

                            <Container className="acads-tertiary-cont2">
                                <Row>
                                    <Slide left>
                                        <img src={back} style={{ width: "350px" }} className="tertiary-image back-image2-1" />
                                        <img src={tertiaryEducation.thirdImage} id="tertiaryEducationThirdImage" style={{ width: "350px", objectFit: "cover", height: "280px", cursor: "pointer" }} className="tertiary-image front-image2-1" onClick={() => handleChangeModal("Tertiary Education Image", 'third')}/>
                                    </Slide>
                                    <UncontrolledTooltip placement="top" target="tertiaryEducationThirdImage">
                                        <h5>Click to Update</h5>
                                    </UncontrolledTooltip>
                                </Row>
                            </Container>
                        </Col>

                        <Col lg="8" md="8" className="text-left">
                            <h4 className="text-danger-edit font-weight-bolder" id="tertiaryEducationSecondTitle" style={{ cursor: "pointer" }} onClick={() => handleChangeModal("Tertiary Education Title", 'second')}>{tertiaryEducation.secondTitle || "<No Content>"}</h4>
                            <UncontrolledTooltip placement="top" target="tertiaryEducationSecondTitle">
                                <h5>Click to Update</h5>
                            </UncontrolledTooltip>

                            <Label className="text-secondary mr-2" style={{ fontSize: "14px" }}>
                                <strong id="tertiaryEducationSecondDescription" style={{ cursor: "pointer" }} onClick={() => handleChangeModal("Tertiary Education Description", 'second')}>{tertiaryEducation.secondDescription || "<No Content>"}</strong>
                                <UncontrolledTooltip placement="top" target="tertiaryEducationSecondDescription">
                                    <h5>Click to Update</h5>
                                </UncontrolledTooltip>
                                <br /><br />

                                <span id="tertiaryEducationSecondContentTitle" style={{ cursor: "pointer" }} onClick={() => handleChangeModal("Tertiary Education Content Title", 'second')}>{tertiaryEducation.secondContentTitle || "<No Content>"}</span>
                                <UncontrolledTooltip placement="top" target="tertiaryEducationSecondContentTitle">
                                    <h5>Click to Update</h5>
                                </UncontrolledTooltip>

                                <ol id="tertiaryEducationSecondContents" style={{ cursor: "pointer" }} onClick={() => handleChangeModal("Tertiary Education Contents", 'second')}>
                                    {tertiaryEducation.secondContents.length >= 1 ? tertiaryEducation.secondContents.map((content, key) => {
                                        return (
                                            <Fragment key={key}>
                                                <li>{key + 1 + ". "} {content.text}</li>
                                            </Fragment>
                                        )
                                    }) : <li>{"<no content>"}</li>}
                                </ol>
                                <UncontrolledTooltip placement="top" target="tertiaryEducationSecondContents">
                                    <h5>Click to Update</h5>
                                </UncontrolledTooltip>

                                <span id="tertiaryEducationSecondContentTitle2" style={{ cursor: "pointer" }} onClick={() => handleChangeModal("Tertiary Education Content Title", 'second2')}>{tertiaryEducation.secondContentTitle2 || "<No Content>"}</span>
                                <UncontrolledTooltip placement="top" target="tertiaryEducationSecondContentTitle2">
                                    <h5>Click to Update</h5>
                                </UncontrolledTooltip>

                                <ol id="tertiaryEducationSecondContents2" style={{ cursor: "pointer" }} onClick={() => handleChangeModal("Tertiary Education Contents", 'second2')}>
                                    {tertiaryEducation.secondContents2.length >= 1 ? tertiaryEducation.secondContents2.map((content, key) => {
                                        return (
                                            <Fragment key={key}>
                                                <li>{key + 1 + ". "} {content.text}</li>
                                            </Fragment>
                                        )
                                    }) : <li>{"<no content>"}</li>}
                                </ol>
                                <UncontrolledTooltip placement="top" target="tertiaryEducationSecondContents2">
                                    <h5>Click to Update</h5>
                                </UncontrolledTooltip>
                            </Label>
                        </Col>
                    </Row>
                    :
                    <Row xs="12" className="align-items-center justify-content-center d-flex mt-5 pt-3 mb-5 ml-3 mr-2">
                        <Col xs="12" md="8" className="text-left">
                            <h4 className="text-danger-edit font-weight-bolder">{tertiaryEducation.secondTitle || "<No Content>"}</h4>
                            <Label className="text-secondary text-justify" style={{ fontSize: "14px" }}>
                                <strong>{tertiaryEducation.secondDescription || "<No Content>"}</strong>
                                <br /><br />
                                <span>{tertiaryEducation.secondContentTitle || "<No Content>"}</span>
                                <ol>
                                    {tertiaryEducation.secondContents.length >= 1 ? tertiaryEducation.secondContents.map((content, key) => {
                                        return (
                                            <Fragment key={key}>
                                                <li>{key + 1 + ". "} {content.text}</li>
                                            </Fragment>
                                        )
                                    }) : <li>{"<no content>"}</li>}
                                </ol>
                                <span>{tertiaryEducation.secondContentTitle2 || "<No Content>"}</span>
                                <ol>
                                    {tertiaryEducation.secondContents2.length >= 1 ? tertiaryEducation.secondContents2.map((content, key) => {
                                        return (
                                            <Fragment key={key}>
                                                <li>{key + 1 + ". "} {content.text}</li>
                                            </Fragment>
                                        )
                                    }) : <li>{"<no content>"}</li>}
                                </ol>
                            </Label>
                        </Col>

                        <Col md="4" xs="12">
                            <Slide left>
                                <img src={tertiaryEducation.thirdImage} style={{ width: "350px", objectFit: "cover", height: "280px" }} className="w-100 mb-3" />
                            </Slide>
                            <Slide left>
                                <img src={tertiaryEducation.secondImage} style={{ width: "350px", objectFit: "cover", height: "280px" }} className="w-100" />
                            </Slide>
                        </Col>
                    </Row>
                }


                <Row lg="12" md="12" xs="12" className={isDesktop ? "align-items-center justify-content-center d-flex ml-3 mr-3 mt-5 pt-3" : "align-items-center justify-content-center d-flex ml-3 mr-3"}>
                    <Col lg="8" md="8" xs="12" className={isDesktop ? "text-left mb-5" : "text-justify"}>
                        <h4 className="text-danger-edit font-weight-bolder text-left" id="tertiaryEducationThirdTitle" style={{ cursor: "pointer" }} onClick={() => handleChangeModal("Tertiary Education Title", 'third')}>{tertiaryEducation.thirdTitle || "<No Content>"}</h4>
                        <UncontrolledTooltip placement="top" target="tertiaryEducationThirdTitle">
                            <h5>Click to Update</h5>
                        </UncontrolledTooltip>
                        <Label className="text-secondary" style={{ fontSize: "14px" }}>
                            <strong id="tertiaryEducationThirdDescription" onClick={() => handleChangeModal("Tertiary Education Description", 'third')} style={{ cursor: "pointer" }}>{tertiaryEducation.thirdDescription || "<No Content>"}</strong>
                            <UncontrolledTooltip placement="top" target="tertiaryEducationThirdDescription">
                                <h5>Click to Update</h5>
                            </UncontrolledTooltip>
                            <br /><br />

                            <span id="tertiaryEducationThirdContentTitle" onClick={() => handleChangeModal("Tertiary Education Content Title", 'third')} style={{ cursor: "pointer" }}>{tertiaryEducation.thirdContentTitle || "<No Content>"}</span>
                            <UncontrolledTooltip placement="top" target="tertiaryEducationThirdContentTitle">
                                <h5>Click to Update</h5>
                            </UncontrolledTooltip>
                            <ol id="tertiaryEducationThirdContents" onClick={() => handleChangeModal("Tertiary Education Contents", 'third')} style={{ cursor: "pointer" }}>
                                {tertiaryEducation.thirdContents.length >= 1 ? tertiaryEducation.thirdContents.map((content, key) => {
                                    return (
                                        <Fragment key={key}>
                                            <li>{key + 1 + ". "} {content.text}</li>
                                        </Fragment>
                                    )
                                }) : <li>{"<no content>"}</li>}
                            </ol>
                            <UncontrolledTooltip placement="top" target="tertiaryEducationThirdContents">
                                <h5>Click to Update</h5>
                            </UncontrolledTooltip>
                        </Label>
                    </Col>

                    <Col lg="4" md="4" xs="12">
                        {isDesktop ?
                            <Container className="acads-tertiary-cont">
                                <Slide right>
                                    <img src={back} style={{ width: "350px" }} className="tertiary-image back-image2-2" />
                                    <img id="tertiaryEducationFourthImage" src={tertiaryEducation.fourthImage} style={{ width: "350px", objectFit: "cover", height: "280px", cursor: "pointer" }} className="tertiary-image front-image2-2" onClick={() => handleChangeModal("Tertiary Education Image", 'fourth')} />
                                </Slide>
                                <UncontrolledTooltip placement="top" target="tertiaryEducationFourthImage">
                                    <h5>Click to Update</h5>
                                </UncontrolledTooltip>
                            </Container>
                            :
                            <Slide right>
                                <img src={tertiaryEducation.fourthImage} style={{ width: "350px", objectFit: "cover", height: "280px" }} className="w-100 mb-5" />
                            </Slide>
                        }
                    </Col>
                </Row>

                <Row className={isDesktop ? "align-items-center justify-content-center d-flex ml-3 mr-3 mt-4 pt-2" : "align-items-center justify-content-center d-flex ml-3 mr-3"}>
                    <Col md="8" className={isDesktop ? "text-left mb-5 pb-5" : "text-justify"}>
                        <h4 className="text-danger-edit font-weight-bolder text-left" id="tertiaryEducationFourthTitle" style={{ cursor: "pointer" }} onClick={() => handleChangeModal("Tertiary Education Title", "fourth")}>{tertiaryEducation.fourthTitle || "<No Content>"}</h4>
                        <UncontrolledTooltip placement="top" target="tertiaryEducationFourthTitle">
                            <h5>Click to Update</h5>
                        </UncontrolledTooltip>
                        <Label className="text-secondary mr-2" style={{ fontSize: "14px" }}>
                            <strong id="tertiaryEducationFourthDescription" style={{ cursor: "pointer" }} onClick={() => handleChangeModal("Tertiary Education Description", "fourth")}>{tertiaryEducation.fourthDescription || "<No Content>"}</strong>
                            <UncontrolledTooltip placement="top" target="tertiaryEducationFourthDescription">
                                <h5>Click to Update</h5>
                            </UncontrolledTooltip>
                            <br /><br />
                            <span id="tertiaryEducationFourthContentTitle" style={{ cursor: "pointer" }} onClick={() => handleChangeModal("Tertiary Education Content Title", "fourth")}>{tertiaryEducation.fourthContentTitle || "<No Content>"}</span>
                            <UncontrolledTooltip placement="top" target="tertiaryEducationFourthContentTitle">
                                <h5>Click to Update</h5>
                            </UncontrolledTooltip>
                            <ol id="tertiaryEducationFourthContents" style={{ cursor: "pointer" }} onClick={() => handleChangeModal("Tertiary Education Contents", "fourth")}>
                                {tertiaryEducation.fourthContents.length >= 1 ? tertiaryEducation.fourthContents.map((content, key) => {
                                    return (
                                        <Fragment key={key}>
                                            <li>{key + 1 + ". "} {content.text}</li>
                                        </Fragment>
                                    )
                                }) : <li>{"<no content>"}</li>}
                            </ol>
                            <UncontrolledTooltip placement="top" target="tertiaryEducationFourthContents">
                                <h5>Click to Update</h5>
                            </UncontrolledTooltip>
                        </Label>
                    </Col>

                    <Col md="4" className="pb-5">
                        {isDesktop ?
                            <Container className="acads-tertiary-cont">
                                <Slide right>
                                    <img src={back} style={{ width: "350px" }} className="tertiary-image back-image2-3" />
                                    <img id="tertiaryEducationFifthImage" src={tertiaryEducation.fifthImage} style={{ width: "350px", objectFit: "cover", height: "280px", cursor: "pointer" }} className="tertiary-image front-image2-3" onClick={() => handleChangeModal("Tertiary Education Image", 'fifth')} />
                                </Slide>
                                <UncontrolledTooltip placement="top" target="tertiaryEducationFifthImage">
                                    <h5>Click to Update</h5>
                                </UncontrolledTooltip>
                            </Container>
                            :
                            <Slide right>
                                <img src={tertiaryEducation.fifthImage} style={{ width: "350px", objectFit: "cover", height: "280px" }} className="w-100 mb-3" />
                            </Slide>
                        }
                    </Col>

                </Row>
            </Container>
        </>
    );
}

export default TertiaryAcads;
