import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Row, Col, Label, Form, Spinner } from 'reactstrap';
import { useForm, Controller } from "react-hook-form";
import { registrarActions } from "../../../services/RegistrarServices";

const StudentSchedules = ({
    uploadModal,
    setUploadModal,
    toggleUploadFile,
    sectionId,
    sectionDetails,
    setSectionDetails,
    updateTable,
    setUpdateTable
}) => {
    const { register, handleSubmit, errors, control, setValue, getValues } = useForm();
    const [buttonSpinner, setButtonSpinner] = useState(false);
    const [imageError, setImageError] = useState(false)
    const onSubmit = async (data) => {
        setImageError(false);
        const documentArr = data.document.split(".");
        const extension = documentArr.pop();
          if(extension.toLowerCase().includes('jpg') || extension.toLowerCase().includes('png') || extension.toLowerCase().includes('jpeg')){
          setButtonSpinner(true)
            let files = [];

            if (sectionDetails.document && sectionDetails.document.name) {
                files.push({
                    filename: sectionDetails.document.name,
                    document_type_id: 14,
                    document: await convertBase64(sectionDetails.document),
                    id: data.document
                })
            }

            let dataToSubmit = {
                grade_level_id: sectionDetails.grade_level_id,
                section_id: sectionId,
                file_upload: files
            }
            
            registrarActions.uploadSchedule(dataToSubmit, setUploadModal, setButtonSpinner, updateTable, setUpdateTable)
        }else{
          setImageError(true);
        }
    }

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    return(
        <Modal isOpen={uploadModal} toggle={toggleUploadFile} backdrop="static" size="md">
        <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader toggle={toggleUploadFile} className="bg-danger-edit text-white" charCode="">Upload Schedule</ModalHeader>
        <ModalBody style={{overflow: "auto"}} className="mr-3">
            <Row className="mb-2">
                <Col md="6" xs="12" className="mr-auto mb-2">
                        <p className="text-danger-edit">Grade Level : {sectionDetails.grade_level}</p>
                </Col>
                <Col md="6" xs="12" className="mb-2">
                        <p className="text-danger-edit">Section Name : {sectionDetails.section}</p>
                </Col>
            </Row>
            <Row className="mb-3">
                      <Col md="12" xs="12">
                          <div
                            className="fileUpload btn btn-warning text-white shadow-sm p-2 w-100"
                          >
                            <span>Upload (File must be image type png, jpg and jpeg)</span>
                            <Controller
                              control={control}
                              name="document"
                              defaultValue={sectionDetails.document ? sectionDetails.document.name : ""}
                              rules={{ required: true }}
                              invalid={errors.document ? true : false}
                              render={({ onChange, value, onBlur, name }) => (
                                <Input
                                  type="file"
                                  name="document"
                                  id="document"
                                  className="upload mb-3"
                                  onChange={(e) =>
                                    {
                                        setSectionDetails({
                                            ...sectionDetails,
                                            document: e.target.files[0],
                                          })
                                          setValue('document', e.target.files[0].name)
                                    }
                                  }
                                  defaultValue={sectionDetails.document ? sectionDetails.document.name : ""}
                                  // style={{ width: "110px" }}
                                />
                              )}
                            />
                          </div>
                          {errors.document ? (
                                <small className="text-danger">
                                    File is required
                                </small>
                            ) : ""}
                      </Col>
                        <Col xs="6" lg="12">
                        <Label className="text-secondary">
                                {sectionDetails.document
                                ? sectionDetails.document.name
                                : ""}
                            </Label>
                        </Col>
                        <Col xs="12" md="12">
                        {imageError ?
                            <small className="text-danger">
                                    Invalid image file format
                                </small> 
                          : ""}
                        </Col>
                    </Row>
        </ModalBody>
        <ModalFooter>
        <Button className="bg-warning-edit border-0" disabled={buttonSpinner}>
            {buttonSpinner ? (
              <>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </>
            ) : (
              "Save"
            )}
          </Button>
          <Button color="secondary" onClick={toggleUploadFile}>Cancel</Button>
        </ModalFooter>
        </Form>
      </Modal>
    )
}
export default StudentSchedules;