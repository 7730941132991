import React, { useState } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
  Container,
  Label,
  FormGroup,
  Form,
  Input,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import classnames from "classnames";
import moment from "moment";
import { useForm, Controller } from "react-hook-form";
import { paymentActions } from "../../../services/PaymentServices";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CashierUpdateTxn = ({
  updateTxnOpen,
  setUpdateTxnOpen,
  toggleUploadFile,
  billingId,
  billings,
  setBillings,
  action,
  updateTable,
  setUpdateTable,
  txnStatus,
  setTxnStatus,
}) => {
  const { register, handleSubmit, errors, control, setValue, getValues } =
    useForm();
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [datePosted, setDatePosted] = useState("");
  const onSubmit = async (data) => {
    setButtonSpinner(!buttonSpinner);
    if (action == "create") {
      const dataSubmit = {
        billing_id: billingId,
        remarks: txnStatus.remarks,
        or_number: txnStatus.or_number,
        amount: txnStatus.amount,
        date_posted: moment(txnStatus.date_posted).format("YYYY-MM-DD"),
        cashier_document: txnStatus.cashier_document_name
          ? {
              cashier_document: await convertBase64(txnStatus.cashier_document),
              filename: txnStatus.cashier_document_name,
            }
          : "",
      };

      paymentActions.updateTxn(
        dataSubmit,
        setButtonSpinner,
        setUpdateTxnOpen,
        updateTable,
        setUpdateTable
      );
    } else if (action == "update") {
      const dataSubmit = {
        proof_id: billingId,
        remarks: txnStatus.remarks,
        amount: txnStatus.amount,
        date_posted: moment(txnStatus.date_posted).format("YYYY-MM-DD"),
      };

      paymentActions.updatePaymentProof(
        dataSubmit,
        setButtonSpinner,
        setUpdateTxnOpen,
        updateTable,
        setUpdateTable
      );
    } else if (action == "void") {
      const dataSubmit = {
        proof_id: billingId,
        remarks: txnStatus.remarks,
      };

      paymentActions.voidPaymentProof(
        dataSubmit,
        setButtonSpinner,
        setUpdateTxnOpen,
        updateTable,
        setUpdateTable
      );
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  return (
    <Modal isOpen={updateTxnOpen} toggle={toggleUploadFile}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>
          {action == "void" ? "Void Billing" : "Update Billing"}
        </ModalHeader>
        <ModalBody>
          <Row>
            {action == "create" ? (
              <>
                <Col md="12" xs="12">
                  <FormGroup>
                    <Label
                      htmlFor="or_number"
                      className="text-secondary font-weight-bolder"
                    >
                      OR Number
                    </Label>
                    <Controller
                      control={control}
                      name="or_number"
                      rules={{ required: true }}
                      invalid={errors.or_number ? true : false}
                      defaultValue=""
                      render={({ onChange, value, onBlur, name }) => (
                        <Input
                          type="textarea"
                          className="form-control bg-light"
                          name="or_number"
                          onChange={(event) => {
                            setTxnStatus({
                              ...txnStatus,
                              or_number: event.target.value,
                            });
                            setValue("or_number", event.target.value);
                            return event.target.value;
                          }}
                          defaultValue=""
                        />
                      )}
                    />
                    {errors.or_number &&
                      errors.or_number.type == "required" && (
                        <div
                          style={{
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#f86c6b",
                          }}
                        >
                          OR Number is required!
                        </div>
                      )}
                  </FormGroup>
                </Col>
              </>
            ) : (
              ""
            )}
            {action !== "void" ? (
              <>
                <Col md="12" xs="12">
                  <FormGroup>
                    <Label
                      htmlFor="amount"
                      className="text-secondary font-weight-bolder"
                    >
                      Amount
                    </Label>
                    <Controller
                      control={control}
                      name="amount"
                      rules={{
                        required: true,
                        pattern: /^\d{1,}(\.\d{0,4})?$/,
                      }}
                      invalid={errors.amount ? true : false}
                      defaultValue={txnStatus.amount}
                      render={({ onChange, value, onBlur, name }) => (
                        <Input
                          type="text"
                          className="form-control bg-light"
                          name="amount"
                          onChange={(event) => {
                            setTxnStatus({
                              ...txnStatus,
                              amount: event.target.value,
                            });
                            setValue("amount", event.target.value);
                            return event.target.value;
                          }}
                          defaultValue={txnStatus.amount}
                        />
                      )}
                    />
                    {errors.amount && errors.amount.type == "required" && (
                      <div
                        style={{
                          marginTop: "0.25rem",
                          fontSize: "80%",
                          color: "#f86c6b",
                        }}
                      >
                        Amount is required!
                      </div>
                    )}
                    {errors.amount && errors.amount.type == "pattern" && (
                      <div
                        style={{
                          marginTop: "0.25rem",
                          fontSize: "80%",
                          color: "#f86c6b",
                        }}
                      >
                        Invalid amount!
                      </div>
                    )}
                  </FormGroup>
                </Col>
                <Col md="12" xs="12">
                  <FormGroup>
                    <Label className="text-secondary font-weight-bolder">
                      Date Posted
                    </Label>
                    <Controller
                      control={control}
                      name="date_posted"
                      defaultValue={
                        moment(txnStatus.date_posted).isValid()
                          ? moment(txnStatus.date_posted).toDate()
                          : ""
                      }
                      rules={{ required: true }}
                      invalid={errors.date_posted ? true : false}
                      render={({ onChange, value, onBlur, name }) => (
                        <DatePicker
                          className="form-control bg-light"
                          selected={
                            moment(txnStatus.date_posted).isValid()
                              ? moment(txnStatus.date_posted).toDate()
                              : ""
                          }
                          onChange={(date) => {
                            setTxnStatus({
                              ...txnStatus,
                              date_posted: date,
                            });
                            setValue("date_posted", date);
                            return date;
                          }}
                          showYearDropdown
                          placeholderText="MM/DD/YYYY"
                          name="date_posted"
                        />
                      )}
                    />
                    {errors.date_posted &&
                      errors.date_posted.type == "required" && (
                        <div
                          style={{
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#f86c6b",
                          }}
                        >
                          Date Posted is required!
                        </div>
                      )}
                  </FormGroup>
                </Col>
              </>
            ) : (
              ""
            )}
            <Col md="12" xs="12">
              <FormGroup>
                <Label
                  htmlFor="remarks"
                  className="text-secondary font-weight-bolder"
                >
                  Remarks
                </Label>
                <Controller
                  control={control}
                  name="remarks"
                  rules={{ required: true }}
                  invalid={errors.remarks ? true : false}
                  defaultValue=""
                  render={({ onChange, value, onBlur, name }) => (
                    <Input
                      type="textarea"
                      className="form-control bg-light"
                      name="remarks"
                      onChange={(event) => {
                        setTxnStatus({
                          ...txnStatus,
                          remarks: event.target.value,
                        });
                        setValue("remarks", event.target.value);
                        return event.target.value;
                      }}
                      defaultValue=""
                    />
                  )}
                />
                {errors.remarks && errors.remarks.type == "required" && (
                  <div
                    style={{
                      marginTop: "0.25rem",
                      fontSize: "80%",
                      color: "#f86c6b",
                    }}
                  >
                    Remarks is required!
                  </div>
                )}
                {action == "void" ? (
                  <small>
                    <span className="text-danger font-weight-bolder">
                      WARNING
                    </span>
                    : Clicking the CONFIRM botton will delete this payment proof
                    on student payment history...
                  </small>
                ) : (
                  ""
                )}
              </FormGroup>
            </Col>
            {action == "create" ? (
              <Col md="12" xs="12">
                <FormGroup>
                  <Label
                    htmlFor="cashier_document_name"
                    className="text-secondary font-weight-bolder"
                  >
                    Proof of Payment
                  </Label>
                  <div className="d-flex flex-column">
                    <div
                      className="fileUpload btn btn-warning text-white rounded-pill p-2"
                      style={{ width: "110px" }}
                    >
                      <span>Upload</span>
                      <Controller
                        control={control}
                        name="cashier_document_name"
                        defaultValue=""
                        rules={{ required: false }}
                        invalid={errors.cashier_document_name ? true : false}
                        render={({ onChange, value, onBlur, name }) => (
                          <Input
                            type="file"
                            name="cashier_document_name"
                            id="uploadBtn"
                            type="file"
                            className="upload"
                            onChange={(e) => {
                              setValue(
                                "cashier_document_name",
                                e.target.files[0].name
                              );
                              setTxnStatus({
                                ...txnStatus,
                                cashier_document: e.target.files[0],
                                cashier_document_name: e.target.files[0].name,
                              });
                              return e.target.files[0].name;
                            }}
                            defaultValue=""
                          />
                        )}
                      />
                    </div>
                    {/* {console.log(errors), errors.cashier_document_name &&
                  errors.cashier_document_name.type == "required" && (
                    <div
                      style={{
                        marginTop: "0.25rem",
                        fontSize: "80%",
                        color: "#f86c6b",
                      }}
                    >
                      Proof of payment is required!
                    </div>
                  )} */}
                    <Label className="text-secondary">
                      {txnStatus.cashier_document
                        ? txnStatus.cashier_document.name
                        : ""}
                    </Label>
                  </div>
                </FormGroup>
              </Col>
            ) : (
              ""
            )}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="warning"
            className="text-white"
            disabled={buttonSpinner ? true : false}
          >
            {buttonSpinner ? (
              <>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp;Processing...
              </>
            ) : (
              "Confirm"
            )}
          </Button>
          <Button
            color="secondary"
            onClick={toggleUploadFile}
            disabled={buttonSpinner}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default CashierUpdateTxn;
