import {
    Container,
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavbarText,
    Row,
    Col,
    Label,
    Card,
    Button,
    CardImg,
    CardTitle,
    CardText,
    CardDeck,
    CardSubtitle,
    CardBody,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    TabContent,
    TabPane,
    ListGroup,
    ListGroupItem,
    List,
    UncontrolledTooltip
} from 'reactstrap';
import {
    BrowserView,
    MobileView,
    isDesktop,
    isMobile,
    isTablet
} from "react-device-detect";
import { Fade, Slide } from "react-reveal";
import { Fragment } from 'react';

const BasicAcads = ({
    handleChangeModal,
    basicEducation
}) => {
    return (
        <>
            <Row className="align-items-center justify-content-center d-flex mt-5" xs="12" md="12" lg="12">
                <Col lg="2" md="2" xs="12" className="text-center text-danger-edit">
                    <h3 className="font-weight-bolder" id="mainTitle" onClick={() => handleChangeModal("Basic Education Title")} style={{ cursor: "pointer" }}>{basicEducation.mainTitle || "<no content>"}</h3>
                    <UncontrolledTooltip placement="top" target="mainTitle">
                        <h5>Click to Update Basic Education Main Title</h5>
                    </UncontrolledTooltip>
                </Col>

                {isDesktop ?
                    <Col lg="1" md="1" xs="1" className="text-center text-danger-edit">
                        <h1 style={{ fontSize: "85px" }}>|</h1>
                    </Col>
                    :
                    <Col className="text-center" xs="12" md="1" lg="1">
                        <h3 className={isDesktop ? "font-weight-bolder text-danger-edit text-left" : "font-weight-bolder text-danger-edit mr-5 ml-5 mt-3 mb-4"} style={{ fontSize: "40px", borderTop: "4px solid" }}></h3>
                    </Col>
                }

                <Col lg="7" md="7" xs="12" className="text-secondary font-weight-bolder">
                    <p className={isDesktop ? "" : "text-justify mx-5"} id="basicEducationMainDescription" onClick={() => handleChangeModal("Basic Education Main Description")} style={{ cursor: "pointer" }}>
                        {basicEducation.mainDescription || "<no content>"}
                    </p>
                    <UncontrolledTooltip placement="top" target="basicEducationMainDescription">
                        <h5>Click to Update Basic Education Main Description</h5>
                    </UncontrolledTooltip>
                </Col>
            </Row>

            <Container fluid={true} className="bg-light pb-5">
                <Row className="align-items-center justify-content-center d-flex mt-5 mb-3" xs="12" md="12" lg="12">
                    <Col lg="4" md="4" xs="12">
                        <Slide left>
                            <img src={basicEducation.first} style={{ cursor: "pointer", width: "350px", objectFit: "cover", height: "280px" }}
                                className={isDesktop ? "" : "w-100 mb-5"}
                                id="basicEducationImage1"
                                onClick={() => handleChangeModal("Basic Education Image", "first")}
                            />
                        </Slide>
                        <UncontrolledTooltip placement="top" target="basicEducationImage1">
                            <h5>Click to Update Basic Education Image</h5>
                        </UncontrolledTooltip>
                    </Col>

                    <Col lg="8" md="8" xs="12" className={isDesktop ? "text-left" : "text-center"}>
                        <h4 className="text-danger-edit font-weight-bolder" style={{ cursor: "pointer" }} onClick={() => handleChangeModal("Basic Education First Title")} id="basicEducationFirstTitle">{basicEducation.firstTitle || "<no content>"}</h4>
                        <UncontrolledTooltip placement="top" target="basicEducationFirstTitle">
                            <h5>Click to Update</h5>
                        </UncontrolledTooltip>

                        <Label className="text-secondary mr-2" style={{ cursor: "pointer", fontSize: "14px" }}>
                            <strong id="basicEducationFirstDescription" onClick={() => handleChangeModal("Basic Education First Description")}>{basicEducation.firstDescription || "<no content>"}</strong>
                            <UncontrolledTooltip placement="top" target="basicEducationFirstDescription">
                                <h5>Click to Update</h5>
                            </UncontrolledTooltip>
                            <br /><br />
                            <ul className="text-left" id="basicEducationFirstContents" onClick={() => handleChangeModal("Basic Education First Contents")}>
                                {basicEducation.firstContents.length >= 1 ? basicEducation.firstContents.map((content, key) => {
                                    return (
                                        <Fragment key={key}>
                                            <li>{content.text}</li>
                                        </Fragment>
                                    )
                                }) : <li>{"<no content>"}</li>}
                            </ul>
                            <UncontrolledTooltip placement="top" target="basicEducationFirstContents">
                                <h5>Click to Update</h5>
                            </UncontrolledTooltip>
                        </Label>
                    </Col>
                </Row>

                <Row className="align-items-center justify-content-center d-flex" xs="12" md="12" lg="12">
                    <Col lg={isTablet ? "4" : "7"} md={isTablet ? "4" : "7"} xs="12">
                        <Slide left>
                            <img src={basicEducation.second} style={{ cursor: "pointer", width: "350px", objectFit: "cover", height: "280px" }}
                                className={isDesktop ? "mr-3" : "w-100 mb-3"}
                                id="basicEducationImage2"
                                onClick={() => handleChangeModal("Basic Education Image", "second")}
                            />
                        </Slide>
                        <UncontrolledTooltip placement="top" target="basicEducationImage2">
                            <h5>Click to Update Basic Education Image</h5>
                        </UncontrolledTooltip>
                        <Slide left>
                            <img src={basicEducation.third} style={{ cursor: "pointer", width: "350px", height: "280px", objectFit: "cover" }}
                                className={isDesktop ? "" : "w-100 mb-5"}
                                id="basicEducationImage3"
                                onClick={() => handleChangeModal("Basic Education Image", "third")}
                            />
                        </Slide>
                        <UncontrolledTooltip placement="top" target="basicEducationImage3">
                            <h5>Click to Update Basic Education Image</h5>
                        </UncontrolledTooltip>
                    </Col>

                    <Col lg="5" md="5" xs="12" className={isDesktop ? "text-left" : "text-center"}>
                        <h4 className="text-danger-edit font-weight-bolder" style={{ cursor: "pointer" }} onClick={() => handleChangeModal("Basic Education Second Title")} id="basicEducationSecondTitle">{basicEducation.secondTitle || "<no content>"}</h4>
                        <UncontrolledTooltip placement="top" target="basicEducationSecondTitle">
                            <h5>Click to Update</h5>
                        </UncontrolledTooltip>
                        <Label className="text-secondary mr-2" style={{ fontSize: "14px" }} >
                            <strong id="secondDescription" style={{ cursor: "pointer" }} onClick={() => handleChangeModal("Basic Education Second Description")}>{basicEducation.secondDescription || "<no content>"}</strong>
                            <UncontrolledTooltip placement="top" target="secondDescription">
                                <h5>Click to Update</h5>
                            </UncontrolledTooltip>
                            <br /><br />
                            <ul className="text-left" style={{ cursor: "pointer" }} id="basicEducatioSecondContents" onClick={() => handleChangeModal("Basic Education Second Contents")}>
                                {basicEducation.secondContents.length >= 1 ? basicEducation.secondContents.map((content, key) => {
                                    return (
                                        <Fragment key={key}>
                                            <li>{content.text}</li>
                                        </Fragment>
                                    )
                                }) : <li>{"<no content>"}</li>}
                            </ul>
                            <UncontrolledTooltip placement="top" target="basicEducatioSecondContents">
                                <h5>Click to Update</h5>
                            </UncontrolledTooltip>
                        </Label>
                    </Col>
                </Row>

                <Row className={isDesktop ? "align-items-center justify-content-center d-flex mr-3 pr-2 mt-3" : "align-items-center justify-content-center d-flex mt-3"} xs="12" md="12" lg="12">
                    <Col lg={isTablet ? "4" : "7"} md={isTablet ? "4" : "7"} xs="12" className="text-center">
                        <Slide left>
                            <img src={basicEducation.fourth}
                                style={{ cursor: "pointer", width: "350px", height: "280px", objectFit: "cover" }}
                                className={isDesktop ? "mr-3" : "w-100 mb-3"}
                                id="basicEducationImage4"
                                onClick={() => handleChangeModal("Basic Education Image", "fourth")}
                            />
                        </Slide>
                        <UncontrolledTooltip placement="top" target="basicEducationImage4">
                            <h5>Click to Update Basic Education Image</h5>
                        </UncontrolledTooltip>
                        <Slide right>
                            <img src={basicEducation.fifth}
                                style={{ cursor: "pointer", width: "350px", height: "280px", objectFit: "cover" }}
                                className={isDesktop ? "" : "w-100 mb-5"}
                                id="basicEducationImage5"
                                onClick={() => handleChangeModal("Basic Education Image", "fifth")}
                            />
                            <UncontrolledTooltip placement="top" target="basicEducationImage5">
                                <h5>Click to Update Basic Education Image</h5>
                            </UncontrolledTooltip>
                        </Slide>
                    </Col>
                </Row>

                <Row className="align-items-center justify-content-center d-flex mt-3" xs="12" md="12" lg="12">
                    <Col lg="5" md="5" xs="12" className={isDesktop ? "text-left" : "text-center"}>
                        <h4 id="basicEducationThirdTitle" className="text-danger-edit font-weight-bolder" style={{ cursor: "pointer" }} onClick={() => handleChangeModal("Basic Education Third Title")}>{basicEducation.thirdTitle || "<no content>"}</h4>
                        <UncontrolledTooltip placement="top" target="basicEducationThirdTitle">
                            <h5>Click to Update</h5>
                        </UncontrolledTooltip>
                        <Label className="text-secondary" style={{ fontSize: "14px" }}>
                            <strong id="thirdDescription" style={{ cursor: "pointer" }} onClick={() => handleChangeModal("Basic Education Third Description")}>{basicEducation.thirdDescription || "<no content>"}</strong>
                            <UncontrolledTooltip placement="top" target="thirdDescription">
                                <h5>Click to Update</h5>
                            </UncontrolledTooltip>
                            <br /><br />
                            <List className="text-left" style={{ cursor: "pointer" }} id="basicEducatioThirdContents" onClick={() => handleChangeModal("Basic Education Third Contents")}>
                            {basicEducation.thirdContents.length >= 1 ? basicEducation.thirdContents.map((content, key) => {
                                    return (
                                        <Fragment key={key}>
                                            <li>{content.text}</li>
                                        </Fragment>
                                    )
                                }) : <li>{"<no content>"}</li>}
                            </List>
                            <UncontrolledTooltip placement="top" target="basicEducatioThirdContents">
                                <h5>Click to Update</h5>
                            </UncontrolledTooltip>
                        </Label>
                    </Col>

                    <Col lg={isTablet ? "4" : "6"} md={isTablet ? "4" : "6"} xs="12" className={isDesktop ? "ml-5 pl-4" : "text-center mt-3"}>
                        <Slide right>
                            <img src={basicEducation.sixth}
                                style={{ cursor: "pointer", width: "350px", height: "280px", objectFit: "cover" }}
                                className={isDesktop ? "ml-1 mr-3" : "w-100 mb-3"}
                                id="basicEducationImage6"
                                onClick={() => handleChangeModal("Basic Education Image", "sixth")}
                            />
                        </Slide>
                        <UncontrolledTooltip placement="top" target="basicEducationImage6">
                            <h5>Click to Update Basic Education Image</h5>
                        </UncontrolledTooltip>
                        <Slide right>
                            <img src={basicEducation.seventh}
                                style={{ cursor: "pointer", width: "350px", height: "280px", objectFit: "cover" }}
                                className={isDesktop ? "" : "w-100 mb-5"}
                                id="basicEducationImage7"
                                onClick={() => handleChangeModal("Basic Education Image", "seventh")}
                            />
                        </Slide>
                        <UncontrolledTooltip placement="top" target="basicEducationImage7">
                            <h5>Click to Update Basic Education Image</h5>
                        </UncontrolledTooltip>
                    </Col>

                </Row>
            </Container>
        </>
    );

}

export default BasicAcads;
