import { ENDPOINT } from "../constants/api";
import axios from "axios";
import { notifyError } from "./Toaster";

export const educationActions = {
  getApplications,
};
const token = localStorage.getItem("jwtToken")
  ? localStorage.getItem("jwtToken")
  : "";
const headers = {
  Authorization: `Bearer ${token}`,
  "Content-Type": "application/json",
  "X-Requested-With": "XMLHttpRequest",
};

async function getApplications(
  setEducationTypes,
  setStudentTypes,
  setGradeTypes,
  setGradeLevels,
  setSchoolFees,
  setCourses,
  setAllSubjects,
  setEscDiscounts,
  setSections
) {
  try {
    let res = await axios.get(ENDPOINT + `/application`, { headers: headers });
    const data = res?.data?.data;
    if (!data) return;

    if (setStudentTypes) {
      setStudentTypes(data?.student_types);
    }

    if (setEducationTypes) {
      setEducationTypes(data?.education_types);
    }

    if (setGradeTypes) {
      setGradeTypes(data?.grade_types);
    }

    if (setGradeLevels) {
      setGradeLevels(data?.grade_level);
    }

    if (setSchoolFees) {
      let school_fees = data?.school_fee;
      if (school_fees && school_fees.length >= 1) {
        school_fees.map((fee) => {
          if (fee) {
            let parse_student_fee = fee.student_fee
              ? JSON.parse(fee.student_fee)
              : null;
            fee.tuition_fee = parse_student_fee
              ? parseFloat(parse_student_fee.tuition_fee)
              : 0;
            fee.miscellaneous_fee = parse_student_fee
              ? parseFloat(parse_student_fee.miscellaneous_fee)
              : 0;
            fee.other_fee = parse_student_fee
              ? parseFloat(parse_student_fee.other_fee)
              : 0;
            fee.details = parse_student_fee ? parse_student_fee.details : null;
            fee.payment_mode = parse_student_fee
              ? parse_student_fee.payment_mode
              : 0;
            fee.paymentMode = "";
            fee.paymentModeDetails = "";
            let subtotal = parseFloat(fee.tuition_fee) * 0.03;
            let total = parseFloat(fee.tuition_fee) - subtotal.toFixed(2);
            fee.discounted_tuituion_fee = total;
            return fee;
          }
        });
      }
      setSchoolFees(school_fees);
    }
    if (setCourses) {
      setCourses(data?.student_course);
    }
    if (setAllSubjects) {
      let studentCurriculum = data?.student_curriculum;
      if (studentCurriculum && studentCurriculum.length >= 1) {
        studentCurriculum.map((curriculum) => {
          curriculum.value = curriculum.id;
          curriculum.label = curriculum.description;

          return curriculum;
        });
      }

      setAllSubjects(studentCurriculum);
    }

    if (setEscDiscounts) {
      setEscDiscounts(data?.esc_discount);
    }

    if (setSections) {
      setSections(
        data?.section_list.map((section) => {
          section.grade_level_id = section.grade_level_id[0].id;
          section.value = section.id;
          section.label = section.name;
          return section;
        })
      );
    }
  } catch (err) {
    notifyError("Error while fetching data");
  }
}

// async function getStudentTypes(setStudentTypes) {
//     try {
//         let res = await axios.get(STUDENT_TYPE_URL, { headers: headers });
//         if (res.data.data && res.data.data.student_types) {
//             setStudentTypes(res.data.data.student_types)
//         }
//     } catch (err) {
//         console.log(err)
//         if (err.response) {
//           if (err.response.Error) {
//             notifyError(err.response.Error);
//           }
//           if (err.response.data && err.response.data.data.email_address) {
//             notifyError(err.response.data.data.email_address);
//           }
//         } else {
//           notifyError('Registration Failed!');
//         }
//         setButtonSpinner(false)
//     }
// }

// async function getGradeTypes(setGradeTypes) {
//     try {
//         let res = await axios.get(GRADE_TYPE_URL, { headers: headers });
//         if (res.data.data && res.data.data.grade_types) {
//             setGradeTypes(res.data.data.grade_types)
//         }
//     } catch (err) {
//         console.log(err)
//         if (err.response) {
//           if (err.response.Error) {
//             notifyError(err.response.Error);
//           }
//           if (err.response.data && err.response.data.data.email_address) {
//             notifyError(err.response.data.data.email_address);
//           }
//         } else {
//           notifyError('Registration Failed!');
//         }
//         setButtonSpinner(false)
//     }
// }

// async function getGradeLevel(gradeType, setSelectOptions, selectOptions, setGradeTypeSelected) {
//     try {
//         let data = { grade_type_id: gradeType };
//         let res = await axios.post(GRADE_LEVEL_URL, data, { headers: headers });
//         if(res.data.success){
//             let gradeTypes = res.data.data.grade_level.map(grade=>{
//                 return {value: grade.id, label: grade.name}
//             })
//             setSelectOptions({...selectOptions, gradeLevelSelection: gradeTypes})

//         }
//     } catch (err) {
//         console.log(err)
//         if (err.response) {
//           if (err.response.Error) {
//             notifyError(err.response.Error);
//           }
//           if (err.response.data && err.response.data.data.email_address) {
//             notifyError(err.response.data.data.email_address);
//           }
//         } else {
//           notifyError('Registration Failed!');
//         }
//         setButtonSpinner(false)
//     }
// }
