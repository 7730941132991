import React, { useEffect, useState } from 'react';
import { isDesktop, isMobile, isTablet } from 'react-device-detect';
import { Button, Carousel, CarouselControl, CarouselIndicators, CarouselItem, Col, Fade, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap';
import carousel1 from '../../../assets/img/carousel-1.jpg';
import carousel2 from '../../../assets/img/carousel-2.jpg';
import carousel3 from '../../../assets/img/carousel-3.jpg';
import carouselMobile1 from '../../../assets/img/carousel-mobile-1.jpg';
import carouselMobile2 from '../../../assets/img/carousel-mobile-2.jpg';
import carouselMobile3 from '../../../assets/img/carousel-mobile-3.jpg';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import { notifyError } from '../../../services/Toaster';

const HomepageCarousel = ({ homepage, slidesDesktop, slidesMobile }) => {

    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === (isDesktop || isTablet ? homepage.desktopCarousel.length : homepage.mobileCarousel.length)  - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? (isDesktop || isTablet ? homepage.desktopCarousel.length : homepage.mobileCarousel.length)  - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    }

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    }

    return (
        <>
            <Fade>
                <Row className="px-0 h-100 mx-0 w-100 justify-content-center align-items-center">
                    <Col lg="12" md="12" xs="12" className="px-0 h-100 mx-0 w-100">
                        <Carousel
                            activeIndex={activeIndex}
                            next={next}
                            previous={previous}
                            interval={4000}
                            slide={true}
                        >
                            <CarouselIndicators items={isDesktop ? slidesDesktop : slidesMobile} activeIndex={activeIndex} onClickHandler={goToIndex} />
                            {isDesktop ? slidesDesktop : slidesMobile}
                            <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
                            <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
                        </Carousel>
                    </Col>
                </Row>
            </Fade>
        </>
    )
}

export default HomepageCarousel;