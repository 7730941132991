import React, { useEffect, useState } from "react";
import { adminActions } from "../../../services/EducationTypeServices";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import overlayFactory from "react-bootstrap-table2-overlay";
import { isMobile } from "react-device-detect";
import Edit from "../../../assets/img/edit.png";
import Add from "../../../assets/img/ADMISSION.png";
import {
  Button,
  Col,
  InputGroup,
  InputGroupAddon,
  Row,
  Input,
} from "reactstrap";
import ModalEditor from "./EducationTypeModal";

const NoDataIndication = () => <div>No Data</div>;

const EducationType = () => {
  const [data, setData] = useState([]);
  const [updateTable, setUpdateTable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const selectOptions = {
    status: [
      { value: 0, label: "InActive" },
      { value: 1, label: "Active" },
    ],
  };
  useEffect(() => {
    setLoading(true);
    adminActions.getEducationType(setData, setLoading);
  }, [updateTable]);

  // console.log(data)

  const columns = [
    {
      dataField: "name",
      text: "Education Type",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "id",
      text: "Action",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
      formatter: (cell, row) => {
        return (
          <Button
            className="btn btn-warning"
            onClick={() => handleEditEducationType(cell, row, "edit")}
          >
            <img src={Edit} style={{ height: "1rem" }} alt="img" />
          </Button>
        );
      },
    },
  ];

  const [modal, setModal] = useState(false);
  const blankEducationType = {
    id: "",
    name: "",
    enabled: "",
  };
  const [editEducationType, setEditEducationType] =
    useState(blankEducationType);
  const [action, setAction] = useState("");

  const handleEditEducationType = (cell, row, action) => {
    setEditEducationType(blankEducationType);
    setAction("");

    if (!modal) {
      setAction(action);
      setModal(!modal);
      setEditEducationType(row);
    } else {
      setModal(!modal);
    }
  };

  const handleAddEducType = (action) => {
    setAction("");
    setEditEducationType(blankEducationType);

    if (!modal) {
      setAction(action);
      setModal(!modal);
    } else {
      setModal(!modal);
    }
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  let filteredEducationType = data.filter((d) => {
    return d.name.toLowerCase().includes(searchText.toLowerCase());
  });

  return (
    <div className="p-4">
      <ModalEditor
        modal={modal}
        setModal={setModal}
        handleEditEducationType={handleEditEducationType}
        editEducationType={editEducationType}
        updateTable={updateTable}
        setUpdateTable={setUpdateTable}
        action={action}
        selectOptions={selectOptions}
      />
      <Row className="mb-3">
        <Col md="2" xs="12" className="mb-3">
          <Button
            className="btn bg-danger-edit"
            onClick={() => handleAddEducType("add")}
          >
            <img src={Add} style={{ height: "1rem" }} alt="img" />
          </Button>
        </Col>
        <Col md="4" xs="12" className="ml-auto mb-3">
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <Button type="button" color="primary" id="search-button">
                <i className="fa fa-search"></i>
              </Button>
            </InputGroupAddon>
            <Input
              className="form-control"
              placeholder="Search..."
              onChange={handleSearch}
            />
          </InputGroup>
          <p>Searchable Column: Education Type</p>
        </Col>
      </Row>
      <BootstrapTable
        wrapperClasses="table-responsive mb-3 text-center"
        bootstrap4
        striped
        keyField="id"
        data={filteredEducationType}
        columns={columns}
        loading={loading}
        classes={isMobile ? "mobileTable" : "desktopTable"}
        pagination={paginationFactory()}
        overlay={overlayFactory({
          spinner: true,
          styles: {
            overlay: (base) => ({
              ...base,
              background: "rgba(0, 0, 0, 0.5)",
            }),
          },
        })}
        noDataIndication={() => <NoDataIndication />}
      />
    </div>
  );
};

export default EducationType;
