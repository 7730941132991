import { ENDPOINT } from "../constants/api";
import axios from "axios";
import { notifyError, notifySuccess } from "./Toaster";

export const studentGradeImportActions = {
  downloadTemplate,
  uploadGrades,
};

const token = localStorage.getItem("jwtToken")
  ? localStorage.getItem("jwtToken")
  : "";
const headers = {
  Authorization: `Bearer ${token}`,
  "Content-Type": "application/json",
  "X-Requested-With": "XMLHttpRequest",
};

async function downloadTemplate(filters, setButtonSpinner) {
  try {
    const newFilters = `?sy_from=${filters.sy_from}&sy_to=${filters.sy_to}&grade_level=${filters.grade_level}&grade_type=${filters.grade_type}&semester=${filters.semester}&course_id=${filters.course_id}&section_id=${filters.section_id}`;
    let res = await axios.get(
      ENDPOINT + `/admin/download-template` + newFilters,
      {
        headers: headers,
      }
    );
    const base64Data = res.data.file; // Replace with your base64 data
    let a = document.createElement("a");
    a.href = base64Data;
    a.download = res.data.filename;
    a.target = "_blank";
    a.click();

    setButtonSpinner(false);
  } catch (err) {
    if (err.response) {
      notifyError("Error while fetching import template");
    }
  }
}

async function uploadGrades(formData, setButtonSpinner, setUploadError) {
  try {
    await axios.post(ENDPOINT + `/admin/import-grade`, formData, {
      headers: headers,
    });
    notifySuccess("Import Success");
  } catch (err) {
    setUploadError(err?.response?.data?.data[0]?.error);
  }
  setButtonSpinner(false);
}
