import React from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Button} from 'reactstrap';
import Gcash from "../../../assets/img/gcash.png";
import { isDesktop } from "react-device-detect";

const PaymentModeModal = ({paymentOptionModal, togglePaymentOptions}) => {

    return(
        <Modal isOpen={paymentOptionModal} toggle={togglePaymentOptions} size="lg">
          <ModalHeader toggle={togglePaymentOptions}>
            SAPC Payment Options
          </ModalHeader>
          <ModalBody className="text-center">
            <Row>
              <Col md="12" xs="12">
                <p>
                  <strong>BPI STA CRUZ BRANCH</strong>
                  <br />
                  3420 0032 76
                  <br />
                  SAN ANTONIO DE PADUA COLLEGE FOUNDATION
                </p>
              </Col>
              <Col md="12" xs="12" className="mb-3">
                <p>
                  <strong>SAPC GCASH</strong>
                  <br />
                  Nelsie Maria De Vera
                  <br />
                  GCash 09276306987
                </p>
                {isDesktop ? (
                  <img
                    src={Gcash}
                    style={{
                      height: "30rem",
                      width: "100%",
                      objectFit: "contain",
                    }}
                  />
                ) : (
                  <img
                    src={Gcash}
                    style={{
                      height: "20rem",
                      width: "100%",
                      objectFit: "fill",
                    }}
                  />
                )}
              </Col>
              <Col md="12" xs="12" className="text-left">
                <p>
                  <small>For manual payments visit:</small>
                  <br />
                  <strong>SAN ANTONIO DE PADUA COLLEGE FOUNDATION</strong>
                  <br />
                  <br />
                  <strong>Email:</strong> cashier@sapc.edu.ph
                  <br />
                  <strong>Address:</strong> National Highway, Barangay Santa
                  Clara Sur, Pila, Laguna, Philippines 4010
                  <br />
                  <strong>Contact Number:</strong> (049)559-0501, 0917-703-9130
                </p>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={togglePaymentOptions}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
    )
}

export default PaymentModeModal;