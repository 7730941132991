import { ENDPOINT } from "../constants/api";
import axios from "axios";
import { notifyError, notifySuccess } from "./Toaster";
import moment from "moment";

export const userActions = {
  getUsers,
  addUser,
  getRoles,
  updateUser
};

const token = localStorage.getItem("jwtToken")
  ? localStorage.getItem("jwtToken")
  : "";
const headers = {
  Authorization: `Bearer ${token}`,
  "Content-Type": "application/json",
  "X-Requested-With": "XMLHttpRequest",
};

async function getUsers(setData, setLoading) {
  try {
    let res = await axios.get(ENDPOINT + `/get-system-user`, {
      headers: headers,
    });
    let users = res.data.data.system_user;
    let usersArr = users.map(user => {
        return {
            created_at: user.created_at,
            email_address: user.email_address,
            enabled: user.enabled,
            given_name: user.given_name,
            id: user.id,
            is_verify: user.is_verify,
            last_name: user.last_name,
            middle_name: user.middle_name,
            role: user.role.name,
            status: user.status,
            suffix: user.suffix,
            sys_role_id: user.sys_role_id,
        }
    });

    setData(usersArr);
    setLoading(false);
  } catch (err) {
    if (err.response) {
      notifyError("Error while fetching Data");
    }
  }
}

async function addUser(dataToSubmit, setButtonSpinner, setUpdateTable, updateTable, setAddOrEditModal) {
  try {
    let res = await axios.post(ENDPOINT + `/add-system-user`, dataToSubmit, {
      headers: headers,
    });
    
    if(res.data.success){
        notifySuccess(res.data.message);
        setUpdateTable(!updateTable);
        setAddOrEditModal(false);
    }else{
      notifyError("Error while adding user.");
    }
    setButtonSpinner(false)
  } catch (err) {
    if (err.response) {
      notifyError("Error while adding user.");
    }
    setButtonSpinner(false)
  }
}

async function updateUser(dataToSubmit, setButtonSpinner, setUpdateTable, updateTable, setAddOrEditModal) {
    try {
      let res = await axios.post(ENDPOINT + `/update-system-user`, dataToSubmit, {
        headers: headers,
      });
      if(res.data.success){
          notifySuccess(res.data.message);
          setUpdateTable(!updateTable);
          setAddOrEditModal(false);
      }else{
        notifyError("Error while updating user.");
      }
      setButtonSpinner(false)
    } catch (err) {
      if (err.response) {
        notifyError("Error while updating user.");
      }
      setButtonSpinner(false)
    }
  }

async function getRoles(selectOptions, setSelectOptions) {
    try {
      let res = await axios.get(ENDPOINT + `/get-system-role`, {
        headers: headers,
      });
      let roles = res.data.data.system_role;
      // let rolesArr = roles.filter(role=>{
      //     return role.name !== "Guest" && role.name !== "Student" && role.enabled;
      // })
      
      let roleSelections = roles.map((role)=>{
        return {value: role.id, label: role.name}
      })
      
      setSelectOptions({...selectOptions, role: roleSelections})
    } catch (err) {
      if (err.response) {
        notifyError("Error while fetching Data");
      }
    }
  }
  