import React, { useState, useEffect, Fragment } from "react";
import {
  Container,
  Collapse,
  Navbar,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { isDesktop, isMobile, isTablet, isIOS } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import landingPageLogo from "../../../assets/img/landingPageLogo.png";
import NavbarMobile from "../LandingPage/NavbarMobile.js";
import NavbarMobileAuth from "../LandingPage/NavbarMobileAuth.js";
import Footer from "../LandingPage/Components/Footer.js";
import { Fade, Slide } from "react-reveal";
import scholarshipImage from "../../../assets/img/admission-base.jpg";
import { dynamicScholarshipsActions } from "../../../services/DynamicScholarshipServices";
import Loading from "../Forms/Loading";

const ScholarshipPage = ({ authenticated }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [navbarMobile, setNavbarMobile] = useState(false);
  const [seeMore, setSeeMore] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDrop = (e) => setDropdownOpen(!dropdownOpen);
  const toggleDropClose = (e) => setDropdownOpen(false);
  const [loading, setLoading] = useState(false);
  const [currentVersion, setCurrentVersion] = useState("");
  const [versionSelection, setVersionSelection] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState("");

  const [loginDropdownOpen, setLoginDropdownOpen] = useState(false);
  const toggleDrop2 = (e) => setLoginDropdownOpen(!loginDropdownOpen);
  const toggleDropClose2 = (e) => setLoginDropdownOpen(false);

  const [lmsDropdownOpen, setLmsDropdownOpen] = useState(false);
  const toggleDrop3 = (e) => setLmsDropdownOpen(!lmsDropdownOpen);
  const toggleDropClose3 = (e) => setLmsDropdownOpen(false);

  const handleScroll = () => {
    setNavbarMobile(true);
  };

  useEffect(() => {
    document.title = "SCHOLARSHIP | San Antonio de Padua College";
  }, []);

  const [scholarships, setScholarships] = useState({
    baseImage: scholarshipImage,
    baseImageFile: "",
    data: [
      {
        name: "Sanghaya",
        description: "Academic Scholarships",
        scholarshipData: [
          {
            title: "1st Honors - 100% discount on tuition fees",
            data: [],
          },
          {
            title: "2nd Honors - 50% discount on tuition fees",
            data: [],
          },
          {
            title: "3rd Honors - 20% discount on tuition fees",
            data: [],
          },
        ],
      },
      {
        name: "Kapamilya Discount",
        description:
          "Loyalty discounts for alumni, SAPC employee benefit, family discount",
        scholarshipData: [
          {
            title: "Alumni Discount - 20% discount on tuition fees",
            data: [],
          },
          {
            title:
              "Family Discount - When 2 or more siblings are enrolled in SAPC in the same term:",
            data: ["20% - 2nd child", "40% - 3rd child", "60% - 4th child"],
          },
          {
            title:
              "SAPC Employee Benefit - for SAPC employees with children enrolled at SAPC",
            data: [
              "1st child - 100% discount on tuition fees",
              "2nd child - 80%",
              "2.2.3 3rd child - 60%",
            ],
          },
        ],
      },
      {
        name: "Kumpas Scholarships",
        description: "For the performing arts (50% discount on tuition fees)",
        scholarshipData: [
          {
            title: "Instruments",
            data: [],
          },
          {
            title: "Voice",
            data: [],
          },
          {
            title: "Dance",
            data: [],
          },
        ],
      },
      {
        name: "SAPC Raiders",
        description: "Athletic Scholarship for student athletes",
        scholarshipData: [
          {
            title: "Palarong Pambansa Athlete - 100% discount",
            data: [],
          },
          {
            title: "STCAA Athlete - 75% discount",
            data: [],
          },
          {
            title: "One Laguna Athlete - 50% discount",
            data: [],
          },
          {
            title: "Provincial Meet Athlete - 20% discount",
            data: [],
          },
        ],
      },
    ],
  });

  useEffect(() => {
    setLoading(true);
    setCurrentVersion("");
    dynamicScholarshipsActions.getCurrentTemplateFrontPage(
      setScholarships,
      setLoading,
      scholarshipImage
    );
  }, []);

  return (
    <Container
      fluid={true}
      className={
        isIOS ? "w-100 px-0 overflow-hidden" : "w-100 px-0 landingPage"
      }
      style={{
        backgroundImage: `url(${scholarships.baseImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "54%",
        overflowX: "hidden",
        position: "absolute",
        backgroundPosition: "right top",
        backgroundAttachment: "fixed",
      }}
    >
      {loading ? <Loading /> : ""}
      {isDesktop ? (
        <Navbar expand="md lg" className="w-100 py-0 navbarClass">
          {/* <NavbarToggler onClick={toggle} /> */}
          <Collapse isOpen={isOpen} navbar>
            <Nav
              navbar
              className="mr-auto justify-content-between w-100 landingPageNav"
            >
              <NavItem className="w-100 text-center h-100">
                <NavLink
                  className="navlinkButton font-weight-bolder px-5"
                  onClick={() => navigate("/")}
                >
                  HOME
                </NavLink>
              </NavItem>
              <NavItem className="w-100 text-center h-100">
                <NavLink
                  className="navlinkButton font-weight-bolder px-4"
                  onClick={() => navigate("/about-us")}
                >
                  ABOUT US
                </NavLink>
              </NavItem>
              <NavItem className="w-100 text-center h-100">
                <NavLink
                  className="navlinkButton font-weight-bolder px-5"
                  onClick={() => navigate("/academics")}
                >
                  ACADEMICS
                </NavLink>
              </NavItem>
              <div className="w-100 d-flex justify-content-center">
                <img
                  src={landingPageLogo}
                  className="landingPageLogo"
                  style={{ width: "10rem" }}
                />
              </div>
              <Dropdown
                nav
                isOpen={dropdownOpen}
                onMouseEnter={toggleDrop}
                onMouseLeave={toggleDropClose}
                className="w-100 text-center h-100"
              >
                <DropdownToggle
                  nav
                  caret
                  className="navlinkButton font-weight-bolder px-5"
                >
                  ADMISSION
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem
                    className="font-weight-bolder text-danger-edit"
                    onClick={() => navigate("/admission-page")}
                  >
                    REQUIREMENTS
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem
                    className="font-weight-bolder text-danger-edit"
                    onClick={() => navigate("/scholarship-page")}
                  >
                    SCHOLARSHIP
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
              <Dropdown
                nav
                isOpen={lmsDropdownOpen}
                onMouseEnter={toggleDrop3}
                onMouseLeave={toggleDropClose3}
                className="w-100 text-center h-100"
              >
                <DropdownToggle
                  nav
                  caret
                  className="navlinkButton font-weight-bolder px-5"
                >
                  LMS
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem
                    className="font-weight-bolder text-danger-edit"
                    onClick={() =>
                      navigate(
                        window.open(
                          "http://52.74.221.30/sapc/?fbclid=IwAR0Cb6NDp56odfRENGOl1Tr47ztbf0Ac38ZyBHCWCfIIyg2-ZcbmTvePYtw",
                          "_blank"
                        )
                      )
                    }
                  >
                    LIKHA ONLINE
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem
                    className="font-weight-bolder text-danger-edit"
                    onClick={() =>
                      navigate(
                        window.open(
                          "https://app.seesaw.me/#/student/login?fbclid=IwAR2GIW7rGIvu4SWjVDB2gmPe7riyw3smZnfGhWYOE-fDhw22Fr_RI3KUXX8",
                          "_blank"
                        )
                      )
                    }
                  >
                    SEESAW
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem
                    className="font-weight-bolder text-danger-edit"
                    onClick={() =>
                      navigate(window.open("https://www.canva.com/", "_blank"))
                    }
                  >
                    CANVA FOR EDUCATION
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
              <Dropdown
                nav
                isOpen={loginDropdownOpen}
                onMouseEnter={toggleDrop2}
                onMouseLeave={toggleDropClose2}
                className="w-100 text-center h-100"
              >
                <DropdownToggle
                  nav
                  caret
                  className="navlinkButton font-weight-bolder px-5"
                >
                  LOGIN
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem
                    className="font-weight-bolder text-danger-edit"
                    onClick={() =>
                      navigate(window.open("http://mail.sapc.edu.ph/"))
                    }
                  >
                    UGNAI
                  </DropdownItem>
                  <DropdownItem divider />
                  {authenticated ? (
                    <DropdownItem
                      className="font-weight-bolder text-danger-edit"
                      onClick={() => navigate("/portal")}
                    >
                      PORTAL
                    </DropdownItem>
                  ) : (
                    <DropdownItem
                      className="font-weight-bolder text-danger-edit"
                      onClick={() => navigate("/login")}
                    >
                      SINAG
                    </DropdownItem>
                  )}
                  <DropdownItem divider />
                  <DropdownItem
                    className="font-weight-bolder text-danger-edit"
                    onClick={() =>
                      navigate(window.open("https://sapc.ec1schoolsystems.com"))
                    }
                  >
                    SASS
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </Nav>
          </Collapse>
        </Navbar>
      ) : (
        ""
      )}

      {isMobile ? (
        navbarMobile ? (
          authenticated ? (
            <NavbarMobileAuth
              setNavbarMobile={setNavbarMobile}
              navbarMobile={navbarMobile}
            />
          ) : (
            <NavbarMobile
              setNavbarMobile={setNavbarMobile}
              navbarMobile={navbarMobile}
            />
          )
        ) : (
          ""
        )
      ) : (
        ""
      )}
      {isMobile ? (
        <div
          onClick={handleScroll}
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            zIndex: "9999",
          }}
        >
          <div className="bar-menu"></div>
          <div className="bar-menu"></div>
          <div className="bar-menu"></div>
        </div>
      ) : isTablet ? (
        <div
          onClick={handleScroll}
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            zIndex: "9999",
          }}
        >
          <div className="bar-menu"></div>
          <div className="bar-menu"></div>
          <div className="bar-menu"></div>
        </div>
      ) : (
        ""
      )}

      <Container className="pt-5 mt-5 mb-5">
        <h3
          className={
            isDesktop
              ? "font-weight-bolder text-danger-edit mb-5"
              : "font-weight-bolder text-danger-edit mb-5 text-left"
          }
        >
          TYPES OF SCHOLARSHIP
        </h3>
        <Fade left>
          {scholarships.data.length >= 1 ? (
            scholarships.data.map((scholarship, index) => {
              let currentIndex = index + 1;
              return (
                <div key={index}>
                  <p className="mb-1">
                    <strong>
                      {currentIndex}. {scholarship.name}
                    </strong>
                  </p>
                  <p>{scholarship.description}</p>
                  <ol>
                    {scholarship.scholarshipData.map((schoData, index2) => {
                      let currentIndex2 = index2 + 1;
                      return (
                        <Fragment key={index2}>
                          <li>
                            {currentIndex}.{currentIndex2} {schoData.title}
                          </li>
                          <ol>
                            {schoData.data.map((d, index3) => {
                              let currentIndex3 = index3 + 1;
                              return (
                                <Fragment key={index3}>
                                  <li>
                                    {currentIndex}.{currentIndex2}.
                                    {currentIndex3} {d}
                                  </li>
                                </Fragment>
                              );
                            })}
                          </ol>
                        </Fragment>
                      );
                    })}
                  </ol>
                </div>
              );
            })
          ) : (
            <div className="text-center">
              <h5>No Scholarships Available</h5>
            </div>
          )}
        </Fade>
      </Container>
      <Footer />
    </Container>
  );
};

export default ScholarshipPage;
