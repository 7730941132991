import { ENDPOINT } from "../constants/api";
import axios from "axios";
import { notifyError, notifySuccess } from "./Toaster";

export const userRoleActions = {
  getUserRoles,
  addUserRole,
  updateUserRole
};

const token = localStorage.getItem("jwtToken")
  ? localStorage.getItem("jwtToken")
  : "";
const headers = {
  Authorization: `Bearer ${token}`,
  "Content-Type": "application/json",
  "X-Requested-With": "XMLHttpRequest",
};

async function getUserRoles(setData, setLoading) {
  try {
    let res = await axios.get(ENDPOINT + `/get-system-role`, {
      headers: headers,
    });

    setData(res.data.data.system_role)
    setLoading(false);
  } catch (err) {
    if (err.response) {
      notifyError("Error while fetching roles");
    }
  }
}

async function addUserRole(data, setButtonSpinner, setUpdateTable, updateTable, setAddOrEditModal) {
  try {
    let res = await axios.post(ENDPOINT + `/add-system-role`, data, {
      headers: headers,
    });
    
    if(res.data.success){
        notifySuccess(res.data.message);
        setUpdateTable(!updateTable);
        setAddOrEditModal(false);
    }else{
      notifyError("Error while adding user role.");
    }
    setButtonSpinner(false)
  } catch (err) {
    if (err.response) {
      notifyError("Error while adding user role.");
    }
    setButtonSpinner(false)
  }
}

async function updateUserRole(data, setButtonSpinner, setUpdateTable, updateTable, setAddOrEditModal) {
    try {
      let res = await axios.post(ENDPOINT + `/update-system-role`, data, {
        headers: headers,
      });
      if(res.data.success){
          notifySuccess(res.data.message);
          setUpdateTable(!updateTable);
          setAddOrEditModal(false);
      }else{
        notifyError("Error while updating user.");
      }
      setButtonSpinner(false)
    } catch (err) {
      if (err.response) {
        notifyError("Error while updating user.");
      }
      setButtonSpinner(false)
    }
  }

async function getRoles(selectOptions, setSelectOptions) {
    try {
      let res = await axios.get(ENDPOINT + `/get-system-role`, {
        headers: headers,
      });
      let roles = res.data.data.system_role;
      let rolesArr = roles.filter(role=>{
          return role.name !== "Guest" && role.name !== "Teacher" && role.name !== "Student";
      })
      
      let roleSelections = rolesArr.map((role)=>{
        return {value: role.id, label: role.name}
      })
      
      setSelectOptions({...selectOptions, role: roleSelections})
    } catch (err) {
      if (err.response) {
        notifyError("Error while fetching Data");
      }
    }
  }
  