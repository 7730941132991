import React, { useState, useEffect } from "react";
import { feesActions } from "../../../../services/FeesServices";
import {
  Button,
  Col,
  Container,
  InputGroup,
  InputGroupAddon,
  Row,
  UncontrolledTooltip,
  Input,
} from "reactstrap";
import View from "../../../../assets/img/view.png";
import Edit from "../../../../assets/img/edit.png";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import overlayFactory from "react-bootstrap-table2-overlay";
import { isBrowser, isDesktop, isMobile } from "react-device-detect";
import Select from "react-select";
import AddEdit from "./AddEdit";
import ViewUser from "./ViewFee";
import Add from "../../../../assets/img/ADMISSION.png";
import { educationActions } from "../../../../services/EducationSelectionService";

const Fees = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [action, setAction] = useState("");
  const [updateTable, setUpdateTable] = useState(false);
  const [gradeLevels, setGradeLevels] = useState([]);
  const [escDiscounts, setEscDiscounts] = useState([]);
  const [selectOptions, setSelectOptions] = useState({
    gradeLevels: [],
    status: [
      { value: 0, label: "InActive" },
      { value: 1, label: "Active" },
    ],
  });

  useEffect(() => {
    educationActions.getApplications(
      null,
      null,
      null,
      setGradeLevels,
      null,
      null,
      null,
      setEscDiscounts
    );
  }, []);

  useEffect(() => {
    if (gradeLevels.length >= 1) {
      let grLevels = gradeLevels.map((type) => {
        return {
          value: type.id,
          label: type.name,
        };
      });
      setSelectOptions({ ...selectOptions, gradeLevels: grLevels });
    }
  }, [gradeLevels]);

  const blankFee = {
    fee_id: "",
    grade_level_id: "",
    enabled: "",
    tuition_fee: "",
    miscellaneous_fee: "",
    other_fee: "",
    payment_mode: {
      annual: {
        tuition_fee: "",
        miscellaneous_fee: "",
        other_fee: "",
        payment_upon_enrollment: "",
        per_billing: "",
        payment_due: "",
      },
      semestral: {
        tuition_fee: "",
        miscellaneous_fee: "",
        other_fee: "",
        payment_upon_enrollment: "",
        per_billing: "",
        payment_due: "",
      },
      monthly_a: {
        tuition_fee: "",
        miscellaneous_fee: "",
        other_fee: "",
        payment_upon_enrollment: "",
        per_billing: "",
        payment_due: "",
      },
      monthly_b: {
        tuition_fee: "",
        miscellaneous_fee: "",
        other_fee: "",
        payment_upon_enrollment: "",
        per_billing: "",
        payment_due: "",
      },
    },
  };
  const [fee, setFee] = useState(blankFee);

  useEffect(() => {
    setLoading(true);
    feesActions.getFees(setData, setLoading, gradeLevels, escDiscounts);
  }, [updateTable]);

  const columns = [
    {
      dataField: "grade_level_id",
      text: "Grade/Year Level",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
      formatter: (row, data) => {
        if (gradeLevels.length) {
          let level = gradeLevels.filter((l) => {
            return l.id == row;
          });
          return level[0].name;
        }
      },
    },
    {
      dataField: "tuition_fee",
      text: "Tuition Fee",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "miscellaneous_fee",
      text: "Miscellaneous Fee",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "other_fee",
      text: "Other Fee",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "enabled",
      text: "Status",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
      formatter: (cell) => {
        return cell ? "Active" : "InActive";
      },
    },
    {
      dataField: "id",
      text: "Action",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
      formatter: (cell, row) => {
        return (
          <div
            className="px-2 justify-content-center d-flex"
            style={{ minWidth: "50px" }}
          >
            <Button
              id={"paid-btn" + cell}
              onClick={() => toggleView(row, "view")}
              className="btn-warning mr-2"
            >
              <img
                alt="img"
                src={View}
                style={{ height: "1.5rem", objectFit: "fill" }}
              />
            </Button>
            <UncontrolledTooltip placement="top" target={"paid-btn" + cell}>
              View
            </UncontrolledTooltip>

            <Button
              id={"upload-btn" + cell}
              onClick={() => toggleAddOrEdit(row, "edit")}
              className="btn-warning mr-2"
            >
              <img
                alt="img"
                src={Edit}
                style={{ height: "1.5rem", objectFit: "fill" }}
              />
            </Button>
            <UncontrolledTooltip placement="top" target={"upload-btn" + cell}>
              Edit
            </UncontrolledTooltip>
          </div>
        );
      },
    },
  ];

  const [viewModal, setViewModal] = useState(false);
  const toggleView = (details, action) => {
    setFee(blankFee);
    setAction("");
    if (!viewModal) {
      setAction(action);
      setFee(details);
      setViewModal(!viewModal);
    } else {
      setViewModal(!viewModal);
    }
  };

  const [addOrEditModal, setAddOrEditModal] = useState(false);
  const toggleAddOrEdit = (details, action) => {
    setFee(blankFee);
    setAction("");
    if (!addOrEditModal) {
      setAction(action);
      if (action == "edit") {
        setFee(details);
      }
      setAddOrEditModal(!addOrEditModal);
    } else {
      setAddOrEditModal(!addOrEditModal);
    }
  };

  const NoDataIndication = () => <div>No Data</div>;

  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    if (data.length >= 1) {
      //search INPUT
      let filteredFees = data.filter((d) => {
        return d.grade_level.toLowerCase().includes(searchText.toLowerCase());
      });
      setFilteredData(filteredFees);
    }
  }, [searchText, data]);

  return (
    <div className="p-4">
      {addOrEditModal ? (
        <AddEdit
          addOrEditModal={addOrEditModal}
          setAddOrEditModal={setAddOrEditModal}
          toggleAddOrEdit={toggleAddOrEdit}
          fee={fee}
          action={action}
          setUpdateTable={setUpdateTable}
          updateTable={updateTable}
          selectOptions={selectOptions}
        />
      ) : (
        ""
      )}

      {viewModal ? (
        <ViewUser
          viewModal={viewModal}
          toggleView={toggleView}
          fee={fee}
          gradeLevels={gradeLevels}
        />
      ) : (
        ""
      )}
      <Row>
        <Col md="4" xs="12" className="mb-3">
          <Button
            className="btn bg-danger-edit"
            onClick={() => toggleAddOrEdit("", "add")}
          >
            <img alt="img" src={Add} style={{ height: "1rem" }} />
          </Button>
        </Col>
        <Col md="4" xs="12" className="ml-auto mb-2">
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <Button type="button" color="primary" id="search-button">
                <i className="fa fa-search"></i>
              </Button>
            </InputGroupAddon>
            <Input
              type="text"
              id="search-text"
              name="search-text"
              placeholder="Search"
              onChange={(e) => setSearchText(e.target.value)}
            />
          </InputGroup>
          <p>
            <small className="font-weight-bolder">
              <i className="fa fa-lightbulb-o"></i>&nbsp;Search by Grade/Year
              Level
            </small>
          </p>
        </Col>
      </Row>

      <BootstrapTable
        wrapperClasses="table-responsive mb-3 text-center"
        bootstrap4
        striped
        keyField="id"
        data={filteredData}
        columns={columns}
        loading={loading}
        classes={isMobile ? "mobileTable" : "desktopTable"}
        pagination={paginationFactory()}
        overlay={overlayFactory({
          spinner: true,
          styles: {
            overlay: (base) => ({
              ...base,
              background: "rgba(0, 0, 0, 0.5)",
            }),
          },
        })}
        noDataIndication={() => <NoDataIndication />}
      />
    </div>
  );
};

export default Fees;
