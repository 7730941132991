import React, { useState, useEffect, Fragment } from "react";
import {
  Container,
  Collapse,
  Navbar,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Card,
  CardImg,
  CardText,
  CardDeck,
  CardBody,
  Col,
  Row,
  Label,
  Button,
  Spinner,
  CardFooter,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from "reactstrap";
import {
  isDesktop,
  isMobile,
  isTablet,
  isIOS,
  isBrowser,
} from "react-device-detect";
import { useNavigate } from "react-router-dom";
import landingPageLogo from "../../../../assets/img/landingPageLogo.png";
import basic1 from "../../../../assets/img/basic-1.jpg";
import tertiary1 from "../../../../assets/img/tertiary-1.jpg";
import tesda1 from "../../../../assets/img/tesda-1.jpg";
import Select from "react-select";
import { WithContext as ReactTags } from "react-tag-input";
import { notifyError } from "../../../../services/Toaster";
import { dynamicRequiermentsActions } from "../../../../services/DynamicRequirementsServices";

const Requirements = () => {
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [buttonSpinner2, setButtonSpinner2] = useState(false);
  const [currentVersion, setCurrentVersion] = useState("");
  const [versionSelection, setVersionSelection] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState("");

  const getOptionLabelByValue = (options, value) => {
    const option = options.find((option) => option.value == value);
    return option ? option.label : "";
  };

  const KeyCodes = {
    enter: 13,
  };

  const delimiters = [KeyCodes.enter];

  useEffect(() => {
    setLoading(true);
    setCurrentVersion("");
    dynamicRequiermentsActions.getCurrentTemplate(
      basicRequirements,
      setBasicRequirements,
      tertiaryRequirements,
      setTertiaryRequirements,
      technicalRequirements,
      setTechnicalRequirements,
      setCurrentVersion,
      setSelectedVersion,
      setLoading,
      setVersionSelection,
      basic1,
      tertiary1,
      tesda1
    );
  }, []);

  useEffect(() => {
    if (selectedVersion != "") {
      dynamicRequiermentsActions.getSelectedTemplate(
        basicRequirements,
        setBasicRequirements,
        tertiaryRequirements,
        setTertiaryRequirements,
        technicalRequirements,
        setTechnicalRequirements,
        setCurrentVersion,
        selectedVersion,
        setLoading,
        setVersionSelection,
        basic1,
        tertiary1,
        tesda1
      );
    }
  }, [selectedVersion]);

  const [basicRequirements, setBasicRequirements] = useState({
    image: basic1,
    imageFile: "",
    title: "For High School / Grade School Students",
    subtitle_1: "Applicant should undergo Interview and Assessment",
    subtitle_2: "The following documents are required:",
    data: [
      {
        id: "Original and Photocopy of Form 138 (Grade 6/10 Card if incoming Gr. 7/11)",
        text: "Original and Photocopy of Form 138 (Grade 6/10 Card if incoming Gr. 7/11)",
      },
      {
        id: "Original and Photocopy of Form 137 (School Student Permanent Record Gr. 6/10)",
        text: "Original and Photocopy of Form 137 (School Student Permanent Record Gr. 6/10)",
      },
      {
        id: "Two (2) (2”x2”) picture (Colored with white background and name tag).",
        text: "Two (2) (2”x2”) picture (Colored with white background and name tag).",
      },
      {
        id: "Certificate of Good Moral Character signed by School Principal/Guidance Officer with documentary stamp.",
        text: "Certificate of Good Moral Character signed by School Principal/Guidance Officer with documentary stamp.",
      },
      {
        id: "National Career Assessment Examination (NCAE) result for Grades 10-12.",
        text: "National Career Assessment Examination (NCAE) result for Grades 10-12.",
      },
      {
        id: "Three (3) Photocopies of PSA authenticated Birth Certificate.",
        text: "Three (3) Photocopies of PSA authenticated Birth Certificate.",
      },
      {
        id: "Two (2) pieces heavy duty long brown envelope.",
        text: "Two (2) pieces heavy duty long brown envelope.",
      },
      {
        id: "Four (4) pieces of BIR Documentary Stamp (for F137 GR12 & Diploma / Grade 10 Certificate of Completion for JHS).",
        text: "Four (4) pieces of BIR Documentary Stamp (for F137 GR12 & Diploma / Grade 10 Certificate of Completion for JHS).",
      },
    ],
  });

  const [tertiaryRequirements, setTertiaryRequirements] = useState({
    image: tertiary1,
    imageFile: "",
    title: "For College Students",
    subtitle_1: "Applicant should undergo Interview and Assessment",
    subtitle_2: "The following documents are required:",
    data: [
      {
        id: "Original and Photocopy of Form 138 (Grade 12 Senior High School Card)",
        text: "Original and Photocopy of Form 138 (Grade 12 Senior High School Card)",
      },
      {
        id: "Original and Photocopy of Form 137 (Senior High School (SHS) Permanent record) with annotation “Eligible to Enter College education”.",
        text: "Original and Photocopy of Form 137 (Senior High School (SHS) Permanent record) with annotation “Eligible to Enter College education”.",
      },
      {
        id: "Copy of Grades (if College Undergraduate)",
        text: "Copy of Grades (if College Undergraduate)",
      },
      {
        id: "Two (3) (2”x2”) picture (Colored with white background and name tag).",
        text: "Two (3) (2”x2”) picture (Colored with white background and name tag).",
      },
      {
        id: "Certificate of Good Moral Character signed by School Principal/Guidance Officer with documentary stamp.",
        text: "Certificate of Good Moral Character signed by School Principal/Guidance Officer with documentary stamp.",
      },
      {
        id: "Certified photocopy of SHS Diploma with LRN, Track & Strand indicated and/or National Assessment Test (NAT) for Grade 12",
        text: "Certified photocopy of SHS Diploma with LRN, Track & Strand indicated and/or National Assessment Test (NAT) for Grade 12",
      },
      {
        id: "Three (3) Photocopies of PSA authenticated Birth Certificate.",
        text: "Three (3) Photocopies of PSA authenticated Birth Certificate.",
      },
      {
        id: "Two (2) pieces heavy duty long brown envelope.",
        text: "Two (2) pieces heavy duty long brown envelope.",
      },
      {
        id: "Four (4) pieces of BIR Documentary Stamp (for F137).",
        text: "Four (4) pieces of BIR Documentary Stamp (for F137).",
      },
      {
        id: "For TES Applicants: Certificate of Residency (for Pila, Laguna Residents)",
        text: "For TES Applicants: Certificate of Residency (for Pila, Laguna Residents)",
      },
    ],
  });

  const [technicalRequirements, setTechnicalRequirements] = useState({
    image: tesda1,
    imageFile: "",
    title: "For TESDA Students",
    subtitle_1:
      "Applicant should schedule an appointment in San Antonio De Padua College",
    subtitle_2: "The following documents are required:",
    data: [
      { id: "Form 137/TOR", text: "Form 137/TOR" },
      {
        id: "PSA copy of Birth Certificate",
        text: "PSA copy of Birth Certificate",
      },
      {
        id: "Photo Copy of Marriage Certificate of Wife if married.",
        text: "Photo Copy of Marriage Certificate of Wife if married.",
      },
      { id: "4 pcs 1x1 picture", text: "4 pcs 1x1 picture" },
      {
        id: "4 pcs passport size chemical print white background, with name tag and with collar.",
        text: "4 pcs passport size chemical print white background, with name tag and with collar.",
      },
    ],
  });

  const blankData = {
    image: "",
    imageFile: "",
    title: "",
    subtitle_1: "",
    subtitle_2: "",
    data: [],
  };

  const [tempData, setTempData] = useState(blankData);
  const [actionType, setActionTye] = useState("");
  const toggleUpdateModal = (actType, data) => {
    setTempData(blankData);
    setActionTye("");
    if (!openUpdateModal) {
      setActionTye(actType);
      setTempData(data);
      setOpenUpdateModal(!openUpdateModal);
    } else {
      setOpenUpdateModal(!openUpdateModal);
    }
  };

  const handleDelete = (i) => {
    const filteredReq = tempData.data.filter((tag, index) => index !== i);
    setTempData({ ...tempData, data: filteredReq });
  };

  const handleAddition = (tag) => {
    let currentData = tempData.data;
    currentData.push(tag);
    setTempData({ ...tempData, data: currentData });
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tempData.data.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTempData({ ...tempData, data: newTags });
  };

  const acceptedImageExtentions = ["jpg", "jpeg", "png"];
  const handleUploadImage = async (e) => {
    let extension = e[0].name.split(".")[1];
    if (acceptedImageExtentions.includes(extension)) {
      let imageFile = {
        filename: e[0].name,
        document: await convertBase64(e[0]),
      };
      setTempData({
        ...tempData,
        image: URL.createObjectURL(e[0]),
        imageFile: imageFile,
      });
    } else {
      notifyError("Invalid file type for image");
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleSaveRequirements = () => {
    if (actionType == "basic") {
      setBasicRequirements(tempData);
    }

    if (actionType == "tertiary") {
      setTertiaryRequirements(tempData);
    }

    if (actionType == "tesda") {
      setTechnicalRequirements(tempData);
    }

    setOpenUpdateModal(!openUpdateModal);
    setTempData(blankData);
  };

  const handlePublishTemplate = () => {
    setButtonSpinner2(true);
    dynamicRequiermentsActions.publishTemplate(
      selectedVersion,
      setButtonSpinner,
      setLoading,
      setButtonSpinner2
    );
  };

  const handleSaveTemplate = () => {
    setButtonSpinner(true);
    let dataToSubmit = {
      basic: JSON.stringify(basicRequirements),
      tertiary: JSON.stringify(tertiaryRequirements),
      technical: JSON.stringify(technicalRequirements),
    };
    dynamicRequiermentsActions.saveTemplate(
      dataToSubmit,
      setLoading,
      setButtonSpinner,
      setButtonSpinner2
    );
  };

  return (
    <div className="p-4">
      <Row>
        <Col md="6" xs="12" className="my-4" style={{ zIndex: "9" }}>
          <Select
            options={versionSelection}
            name="version"
            className={isMobile ? "w-100" : isTablet ? "w-50" : "w-25"}
            onChange={(e) => {
              setLoading(true);
              setSelectedVersion(e.value);
              return e;
            }}
            defaultValue={
              selectedVersion != ""
                ? {
                    value: selectedVersion,
                    label: getOptionLabelByValue(
                      versionSelection,
                      selectedVersion
                    ),
                  }
                : ""
            }
            value={
              selectedVersion != ""
                ? {
                    value: selectedVersion,
                    label: getOptionLabelByValue(
                      versionSelection,
                      selectedVersion
                    ),
                  }
                : ""
            }
          />
          <Label className="text-secondary">Select Version</Label>
        </Col>
        <Col md="6" xs="12" className="w-100 ml-auto text-right my-4">
          <div>
            <Button
              className="mr-3 border-0 bg-danger-edit"
              onClick={handleSaveTemplate}
              disabled={buttonSpinner || buttonSpinner2 || loading}
            >
              {buttonSpinner ? (
                <>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  &nbsp;Processing...
                </>
              ) : (
                "Save"
              )}
            </Button>
            <Button
              onClick={handlePublishTemplate}
              color="success"
              disabled={buttonSpinner || buttonSpinner2 || loading}
            >
              {buttonSpinner2 ? (
                <>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  &nbsp;Processing...
                </>
              ) : (
                "Publish"
              )}
            </Button>
          </div>
          <Label className="font-weight-bolder text-secondary">
            Latest Version: {currentVersion}
          </Label>
        </Col>
      </Row>
      <CardDeck className="px-5">
        <Card className="shadow">
          <CardImg
            style={{ height: "13rem" }}
            top
            width="100%"
            src={basicRequirements.image}
            alt="Card image cap"
          />
          <CardBody className="text-left py-5">
            <p style={{ fontSize: "15px" }}>
              <strong>{basicRequirements.title}</strong>
            </p>
            <p className="mb-1">{basicRequirements.subtitle_1}</p>
            <p className="mb-3">{basicRequirements.subtitle_2}</p>
            <ul className="mx-2">
              {basicRequirements.data.length >= 1
                ? basicRequirements.data.map((d, i) => {
                    return (
                      <Fragment key={i}>
                        <li className="mb-2">{d.text}</li>
                      </Fragment>
                    );
                  })
                : ""}
            </ul>
          </CardBody>
          <CardFooter className="text-center py-5">
            <Button
              color="success"
              onClick={() => toggleUpdateModal("basic", basicRequirements)}
            >
              UPDATE
            </Button>
          </CardFooter>
        </Card>

        <Card className="shadow">
          <CardImg
            style={{ height: "13rem" }}
            top
            width="100%"
            src={tertiaryRequirements.image}
            alt="Card image cap"
          />
          <CardBody className="text-left py-5">
            <p style={{ fontSize: "15px" }}>
              <strong>{tertiaryRequirements.title}</strong>
            </p>
            <p className="mb-1">{tertiaryRequirements.subtitle_1}</p>
            <p className="mb-3">{tertiaryRequirements.subtitle_2}</p>
            <ul className="mx-2">
              {tertiaryRequirements.data.length >= 1
                ? tertiaryRequirements.data.map((d, i) => {
                    return (
                      <Fragment key={i}>
                        <li className="mb-2">{d.text}</li>
                      </Fragment>
                    );
                  })
                : ""}
            </ul>
          </CardBody>
          <CardFooter className="text-center py-5">
            <Button
              color="success"
              onClick={() =>
                toggleUpdateModal("tertiary", tertiaryRequirements)
              }
            >
              UPDATE
            </Button>
          </CardFooter>
        </Card>

        <Card className="shadow">
          <CardImg
            style={{ height: "13rem" }}
            top
            width="100%"
            src={technicalRequirements.image}
            alt="Card image cap"
          />
          <CardBody className="text-left py-5">
            <p style={{ fontSize: "15px" }}>
              <strong>{technicalRequirements.title}</strong>
            </p>
            <p className="mb-1">{technicalRequirements.subtitle_1}</p>
            <p className="mb-3">{technicalRequirements.subtitle_2}</p>
            <ul className="mx-2">
              {technicalRequirements.data.length >= 1
                ? technicalRequirements.data.map((d, i) => {
                    return (
                      <Fragment key={i}>
                        <li className="mb-2">{d.text}</li>
                      </Fragment>
                    );
                  })
                : ""}
            </ul>
          </CardBody>
          <CardFooter className="text-center py-5">
            <Button
              color="success"
              onClick={() => toggleUpdateModal("tesda", technicalRequirements)}
            >
              UPDATE
            </Button>
          </CardFooter>
        </Card>
      </CardDeck>
      <Modal isOpen={openUpdateModal} backdrop="static" size="lg">
        <ModalHeader>Update Requirements</ModalHeader>
        <ModalBody>
          <Row>
            <Col md="12" xs="12" className="mb-4">
              <img
                src={tempData.image}
                style={{ height: "200px", width: "300px" }}
              />
              <Input
                type="file"
                onChange={(e) => handleUploadImage(e.target.files)}
              />
            </Col>
            <Col md="12" xs="12" className="mb-4">
              <Label>Title</Label>
              <Input
                defaultValue={tempData.title}
                onChange={(e) =>
                  setTempData({ ...tempData, title: e.target.value })
                }
              />
            </Col>
            <Col md="12" xs="12" className="mb-4">
              <Label>SubTitle 1</Label>
              <Input
                type="textarea"
                defaultValue={tempData.subtitle_1}
                onChange={(e) =>
                  setTempData({ ...tempData, subtitle_1: e.target.value })
                }
              />
            </Col>
            <Col md="12" xs="12" className="mb-4">
              <Label>SubTitle 2</Label>
              <Input
                type="textarea"
                defaultValue={tempData.subtitle_2}
                onChange={(e) =>
                  setTempData({ ...tempData, subtitle_2: e.target.value })
                }
              />
            </Col>
            <Col md="12" xs="12" className="mb-4">
              <Label>List of requirements: </Label>
              <ReactTags
                tags={tempData.data}
                handleDelete={handleDelete}
                handleAddition={handleAddition}
                handleDrag={handleDrag}
                inputFieldPosition="top"
                placeholder="Press enter to add new requirements"
                labelField="text"
                delimiters={delimiters}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => handleSaveRequirements()} color="success">
            Save
          </Button>
          <Button onClick={() => setOpenUpdateModal(!openUpdateModal)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Requirements;
