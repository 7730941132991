import React, { useState } from "react";
import {
  Container,
  Collapse,
  Navbar,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Label,
  Card,
  Button,
  CardImg,
  CardText,
  CardDeck,
  CardBody,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  Modal,
  ModalBody,
  Spinner,
  Fade,
  ModalHeader,
  Input,
  ModalFooter,
  Table,
} from "reactstrap";
import {
  isBrowser,
  isDesktop,
  isIOS,
  isTablet,
  isMobile,
} from "react-device-detect";
import news1 from "../../../assets/img/news-1.png";
import news2 from "../../../assets/img/news-2.png";
import news3 from "../../../assets/img/news-3.png";
import news4 from "../../../assets/img/news-4.png";
import news5 from "../../../assets/img/news-5.png";
import { useNavigate } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { notifyError } from "../../../services/Toaster";

const HomepageNews = ({ homepage }) => {
  const navigate = useNavigate();

  const [newsAll, setNewsAll] = useState(false);
  const handleSeeAllNews = () => {
    if (!newsAll) {
      setNewsAll(!newsAll);
    } else {
      setNewsAll(!newsAll);
    }
  };

  return (
    <>
      {/* <Slide left> */}
      <Row lg="12" md="12" xs="12" className="w-100 lastSection mx-0">
        <Col
          lg="3"
          md="3"
          xs="12"
          className="bg-danger-edit d-flex justify-content-center"
        >
          <Label
            className="font-weight-bolder p-4 text-warning-edit"
            style={{ fontSize: "1.3rem" }}
          >
            SAPC LATEST NEWS
          </Label>
        </Col>
      </Row>
      {/* </Slide> */}

      <Row
        lg="12"
        md="12"
        xs="12"
        className="lastSectionImage lastSectionImage2 w-100 mx-0"
      >
        <Container className="mt-5 pt-5 text-center">
          {homepage.news.length > 6 ? (
            !newsAll ? (
              <div
                className="text-right mb-3"
                style={{ color: "#800000", cursor: "pointer" }}
              >
                <h5
                  className="font-weight-bolder"
                  onClick={() => handleSeeAllNews()}
                >
                  See All News
                </h5>
              </div>
            ) : (
              <div
                className="text-right mb-3"
                style={{ color: "#800000", cursor: "pointer" }}
              >
                <h5
                  className="font-weight-bolder"
                  onClick={() => handleSeeAllNews()}
                >
                  See Less
                </h5>
              </div>
            )
          ) : (
            ""
          )}
          <CardDeck>
            {homepage.news.length >= 1
              ? homepage.news.map((news, index) => {
                  if (!newsAll) {
                    if (index <= 5) {
                      return (
                        <Col md="4" xs="12" className="mb-5" key={index}>
                          <Card
                            className={
                              isDesktop
                                ? "shadow zooming-transition border-0"
                                : "shadow border-0"
                            }
                          >
                            <CardImg
                              style={{ height: "13rem" }}
                              top
                              width="100%"
                              src={news.image}
                              alt="Card image cap"
                            />
                            <CardBody
                              style={{
                                height: "13rem",
                                backgroundColor: "#f0f0f0",
                              }}
                              className="text-center py-5 d-flex flex-column align-items-center"
                            >
                              <CardText className="mb-4 text-danger-edit">
                                <strong>{news.title}</strong>
                              </CardText>
                              <Button
                                className="mt-auto bg-danger-edit border border-0"
                                onClick={() =>
                                  navigate(window.open(news.url_link, "_blank"))
                                }
                              >
                                See More
                              </Button>
                            </CardBody>
                          </Card>
                        </Col>
                      );
                    }
                  } else {
                    return (
                      <Col md="4" xs="12" className="mb-5" key={index}>
                        <Card
                          className={
                            isDesktop
                              ? "shadow zooming-transition border-0"
                              : "shadow border-0"
                          }
                        >
                          <CardImg
                            style={{ height: "13rem" }}
                            top
                            width="100%"
                            src={news.image}
                            alt="Card image cap"
                          />
                          <CardBody
                            style={{
                              height: "13rem",
                              backgroundColor: "#f0f0f0",
                            }}
                            className="text-center py-5 d-flex flex-column align-items-center"
                          >
                            <CardText className="mb-4 text-danger-edit">
                              <strong>{news.title}</strong>
                            </CardText>
                            <Button
                              className="mt-auto bg-danger-edit border border-0"
                              onClick={() =>
                                navigate(window.open(news.url_link, "_blank"))
                              }
                            >
                              See More
                            </Button>
                          </CardBody>
                        </Card>
                      </Col>
                    );
                  }
                })
              : ""}
          </CardDeck>
          {homepage.news.length == 0 ? (
            <h3 className="text-danger-edit">No available SAPC NEWS</h3>
          ) : (
            ""
          )}
        </Container>
      </Row>
    </>
  );
};

export default HomepageNews;
