import { ENDPOINT } from "../constants/api";
import axios from "axios";
import { notifyError } from "./Toaster";

export const admissionActions = {
  validateAdmission,
  submitForm,
  updateForm,
};
const token = localStorage.getItem("jwtToken")
  ? localStorage.getItem("jwtToken")
  : "";
const headers = {
  Authorization: `Bearer ${token}`,
  "Content-Type": "application/json",
  "X-Requested-With": "XMLHttpRequest",
};

async function validateAdmission(
  newData,
  data,
  setCurrentStep,
  setEducationSelected,
  setModal,
  setLoading,
  setSubjects,
  navigate,
  schoolFees,
  setPaymentForm,
  setBasicForm,
  setCurrentPage
) {
  try {
    console.log(newData);
    let res = await axios.post(ENDPOINT + `/new-application`, newData, {
      headers: headers,
    });
    if (res.data && res.data.message == "Selection") {
      localStorage.setItem(
        "admissionAnswers",
        JSON.stringify({
          education_type: data.education_type,
          grade_type: newData.grade_type_id ? newData.grade_type_id : "",
          grade_year_level: data.grade_year_level,
          student_type: data.student_type,
          tertiary_course: data.tertiary_course ? data.tertiary_course : "",
          semester: data.semester ? data.semester : "",
        })
      );
      if (schoolFees && schoolFees.length >= 1) {
        schoolFees.map((fee) => {
          if (
            data.grade_year_level &&
            data.grade_year_level.value == fee.grade_level_id
          ) {
            fee.base_tuition = fee.tuition_fee;
            setPaymentForm(fee);
            sessionStorage.setItem("payform", JSON.stringify(fee));
          }
        });
      }
      if (res.data.data.student_application) {
        const data = res.data.data.student_application;
        // data.student_subject = data.student_subject ? JSON.parse(data.student_subject) : null
        let basic = {
          id: "",
          student_id: data.student_detail_id.student_id,
          application_id: "",
          programs: "",
          last_name: data.student_detail_id.last_name,
          suffix: data.student_detail_id.suffix,
          first_name: data.student_detail_id.given_name,
          middle_name: data.student_detail_id.middle_name,
          birth_place: data.student_detail_id.birth_place,
          birth_date: data.student_detail_id.birth_date,
          gender: data.student_detail_id.gender,
          age: data.student_detail_id.age,
          religion: data.student_detail_id.religion,
          lrn: data.student_detail_id.lrn,
          civil_status: data.student_detail_id.civil_status,
          semester: data.student_detail_id.semester,
          street: data.student_detail_id.house_bldg_street,
          province: data.student_detail_id.province,
          city: data.student_detail_id.city_municipality,
          barangay: data.student_detail_id.barangay,
          elementary_school_id:
            data.education_background &&
            data.education_background.length &&
            data.education_background[0].id
              ? data.education_background[0].id
              : "",
          elementary_school_name:
            data.education_background && data.education_background.length
              ? data.education_background[0].school_name
              : "",
          elementary_year_graduated:
            data.education_background && data.education_background.length
              ? data.education_background[0].year_graduated
              : "",
          elementary_school_address:
            data.education_background && data.education_background.length
              ? data.education_background[0].school_address
              : "",
          junior_high_school_id:
            data.education_background &&
            data.education_background.length &&
            data.education_background[1].id
              ? data.education_background[1].id
              : "",
          junior_high_school_name:
            data.education_background && data.education_background.length
              ? data.education_background[1].school_name
              : "",
          junior_high_year_graduated:
            data.education_background && data.education_background.length
              ? data.education_background[1].year_graduated
              : "",
          junior_high_school_address:
            data.education_background && data.education_background.length
              ? data.education_background[1].school_address
              : "",
          senior_high_school_id:
            data.education_background &&
            data.education_background.length &&
            data.education_background[2].id
              ? data.education_background[2].id
              : "",
          senior_high_school_name:
            data.education_background && data.education_background.length
              ? data.education_background[2].school_name
              : "",
          senior_high_year_graduated:
            data.education_background && data.education_background.length
              ? data.education_background[2].year_graduated
              : "",
          senior_high_school_address:
            data.education_background && data.education_background.length
              ? data.education_background[2].school_address
              : "",
          form138: "",
          form137: "",
          picture: "",
          good_moral: "",
          ncae: "",
          birth_certificate: "",
          cert_of_residency: "",
          copy_of_grades: "",
          shs_diploma: "",
          selected_program: "",
          living_status:
            data.health_additional_info &&
            data.health_additional_info.living_status
              ? data.health_additional_info.living_status
              : "",
          no_of_siblings:
            data.health_additional_info &&
            data.health_additional_info.no_of_siblings
              ? data.health_additional_info.no_of_siblings
              : "",
          father_name: "",
          father_age: "",
          father_birth_date: "",
          father_birth_place: "",
          father_home_address: "",
          father_contact_no: "",
          father_highest_education: "",
          father_occupation: "",
          father_company: "",
          father_monthly_income: "",
          mother_name: "",
          mother_age: "",
          mother_birth_date: "",
          mother_birth_place: "",
          mother_home_address: "",
          mother_contact_no: "",
          mother_highest_education: "",
          mother_occupation: "",
          mother_company: "",
          mother_monthly_income: "",
          guardian_name: "",
          guardian_age: "",
          guardian_birth_date: "",
          guardian_birth_place: "",
          guardian_home_address: "",
          guardian_contact_no: "",
          guardian_highest_education: "",
          guardian_occupation: "",
          guardian_company: "",
          guardian_monthly_income: "",
          health_student_boolean:
            data.health_additional_info &&
            data.health_additional_info.health_student_boolean
              ? data.health_additional_info.health_student_boolean
              : "",
          health_student_specific:
            data.health_additional_info &&
            data.health_additional_info.health_student_specific
              ? data.health_additional_info.health_student_specific
              : "",
          consulting_doctor:
            data.health_additional_info &&
            data.health_additional_info.consulting_doctor
              ? data.health_additional_info.consulting_doctor
              : "",
          important_illness:
            data.health_additional_info &&
            data.health_additional_info.important_illness
              ? data.health_additional_info.important_illness
              : "",
          hospitalized_in_past:
            data.health_additional_info &&
            data.health_additional_info.hospitalized_in_past
              ? data.health_additional_info.hospitalized_in_past
              : "",
          specific_hospitalized_in_past:
            data.health_additional_info &&
            data.health_additional_info.specific_hospitalized_in_past
              ? data.health_additional_info.specific_hospitalized_in_past
              : "",
          difficulty:
            data.health_additional_info &&
            data.health_additional_info.difficulty
              ? data.health_additional_info.difficulty
              : "",
          family_health_history:
            data.health_additional_info &&
            data.health_additional_info.family_health_history
              ? data.health_additional_info.family_health_history
              : "",
          family_member_with_sickness:
            data.health_additional_info &&
            data.health_additional_info.family_member_with_sickness
              ? data.health_additional_info.family_member_with_sickness
              : "",
          vaccination:
            data.health_additional_info &&
            data.health_additional_info.vaccination
              ? data.health_additional_info.vaccination
              : "",
          self_evaluation:
            data.health_additional_info &&
            data.health_additional_info.self_evaluation
              ? data.health_additional_info.self_evaluation
              : "",
          financial_support:
            data.health_additional_info &&
            data.health_additional_info.financial_support
              ? data.health_additional_info.financial_support
              : "",
          other_financial_support:
            data.health_additional_info &&
            data.health_additional_info.other_financial_support
              ? data.health_additional_info.other_financial_support
              : "",
          grew_up:
            data.health_additional_info && data.health_additional_info.grew_up
              ? data.health_additional_info.grew_up
              : "",
          language_home:
            data.health_additional_info &&
            data.health_additional_info.language_home
              ? data.health_additional_info.language_home
              : "",
          other_language_home:
            data.health_additional_info &&
            data.health_additional_info.other_language_home
              ? data.health_additional_info.other_language_home
              : "",
          concerns:
            data.health_additional_info && data.health_additional_info.concerns
              ? data.health_additional_info.concerns
              : "",
          other_concern:
            data.health_additional_info &&
            data.health_additional_info.other_concern
              ? data.health_additional_info.other_concern
              : "",
          problem_solution:
            data.health_additional_info &&
            data.health_additional_info.problem_solution
              ? data.health_additional_info.problem_solution
              : "",
          other_problem_solution:
            data.health_additional_info &&
            data.health_additional_info.other_problem_solution
              ? data.health_additional_info.other_problem_solution
              : "",
          cope_up_stress:
            data.health_additional_info &&
            data.health_additional_info.cope_up_stress
              ? data.health_additional_info.cope_up_stress
              : "",
          other_cope_up_stress:
            data.health_additional_info &&
            data.health_additional_info.other_cope_up_stress
              ? data.health_additional_info.other_cope_up_stress
              : "",
          how_to_know_sapc:
            data.health_additional_info &&
            data.health_additional_info.how_to_know_sapc
              ? data.health_additional_info.how_to_know_sapc
              : "",
          other_how_to_know_sapc:
            data.health_additional_info &&
            data.health_additional_info.other_how_to_know_sapc
              ? data.health_additional_info.other_how_to_know_sapc
              : "",
          listahan:
            data.health_additional_info && data.health_additional_info.listahan
              ? data.health_additional_info.listahan
              : "",
          household_number:
            data.health_additional_info &&
            data.health_additional_info.household_number
              ? data.health_additional_info.household_number
              : "",
          disability:
            data.health_additional_info &&
            data.health_additional_info.disability
              ? data.health_additional_info.disability
              : "",
          pwd_number:
            data.health_additional_info &&
            data.health_additional_info.pwd_number
              ? data.health_additional_info.pwd_number
              : "",
          subsidy:
            data.health_additional_info && data.health_additional_info.subsidy
              ? data.health_additional_info.subsidy
              : "",
          subsidy_details:
            data.health_additional_info &&
            data.health_additional_info.subsidy_details
              ? data.health_additional_info.subsidy_details
              : "",
          education_type: data.education_type_id,
          student_type: data.student_type_id,
          remarks: data.remarks,
          subjects: data.student_subject,
          old_attachments: data.student_document ? data.student_document : [],
          date_of_appointment:
            data.health_additional_info &&
            data.health_additional_info.date_of_appointment
              ? data.health_additional_info.date_of_appointment
              : "",
          is_esc: "",
          esc_type: "",
          esc_qvr: "",
          approve_esc: "",
          scholarship_name: "",
          scholarship_discount: "",
          guardian_toggle: data.health_additional_info
            ? data.health_additional_info.guardian_toggle
            : true,
        };

        if (data.family_info && data.family_info.length >= 1) {
          if (data.family_info[0].family_role == 0) {
            basic.father_name = data.family_info[0].name;
            basic.father_age = data.family_info[0].age;
            basic.father_birth_date = data.family_info[0].date_of_birth;
            basic.father_birth_place = data.family_info[0].place_of_birth;
            basic.father_home_address = data.family_info[0].home_address;
            basic.father_contact_no = data.family_info[0].contact_number;
            basic.father_highest_education =
              data.family_info[0].education_attainment;
            basic.father_occupation = data.family_info[0].occupation;
            basic.father_company = data.family_info[0].company_name;
            basic.father_monthly_income = data.family_info[0].monthly_income;
          }

          if (data.family_info[1].family_role == 1) {
            basic.mother_name = data.family_info[1].name;
            basic.mother_age = data.family_info[1].age;
            basic.mother_birth_date = data.family_info[1].date_of_birth;
            basic.mother_birth_place = data.family_info[1].place_of_birth;
            basic.mother_home_address = data.family_info[1].home_address;
            basic.mother_contact_no = data.family_info[1].contact_number;
            basic.mother_highest_education =
              data.family_info[1].education_attainment;
            basic.mother_occupation = data.family_info[1].occupation;
            basic.mother_company = data.family_info[1].company_name;
            basic.mother_monthly_income = data.family_info[1].monthly_income;
          }

          if (data.family_info[2].family_role == 2) {
            basic.guardian_name = data.family_info[2].name;
            basic.guardian_age = data.family_info[2].age;
            basic.guardian_birth_date = data.family_info[2].date_of_birth;
            basic.guardian_birth_place = data.family_info[2].place_of_birth;
            basic.guardian_home_address = data.family_info[2].home_address;
            basic.guardian_contact_no = data.family_info[2].contact_number;
            basic.guardian_highest_education =
              data.family_info[2].education_attainment;
            basic.guardian_occupation = data.family_info[2].occupation;
            basic.guardian_company = data.family_info[2].company_name;
            basic.guardian_monthly_income = data.family_info[2].monthly_income;
          }
        }
        setBasicForm(basic);
      }
      setCurrentPage("admission");
      setSubjects(res.data.data.student_curriculum);
      setLoading(false);
      sessionStorage.removeItem("form");
      sessionStorage.removeItem("scholarshipForm");
      sessionStorage.removeItem("paymentForm");
      setCurrentStep(0);
      // navigate('/portal/create-application')
      setEducationSelected(true);

      setModal(false);
    }
  } catch (err) {
    console.log(err);
    notifyError("Invalid Selection Please try again.");
    setLoading(false);
  }
}

async function submitForm(
  data,
  setButtonSpinner,
  setSubmissionModal,
  setReferenceNo
) {
  try {
    let res = await axios.post(ENDPOINT + "/store", data, { headers: headers });
    setReferenceNo(res.data.data.ref_no);
    setButtonSpinner(false);
    // setSubmissionModal(true)
    if (res.data.success) {
      setSubmissionModal(true);
    }
    // notifySuccess('Successfully submit application form!');
  } catch (err) {
    setSubmissionModal(true);
    if (err.response) {
      if (err.response.data && err.response.data.error) {
        notifyError(err.response.data.error.message);
      } else {
        notifyError("Submission Failed!");
      }
    }
    setButtonSpinner(false);
  }
}

async function updateForm(data, setButtonSpinner, setSubmissionModal) {
  try {
    let res = await axios.post(
      ENDPOINT + `/student/application-details/${data.id}/update`,
      data,
      { headers: headers }
    );
    //   setReferenceNo(res.data.data.ref_no)
    setButtonSpinner(false);
    setSubmissionModal(true);
    // notifySuccess('Successfully submit application form!');
  } catch (err) {
    if (err.response) {
      if (err.response.data && err.response.data.error) {
        notifyError(err.response.data.error.message);
      } else {
        notifyError("Submission Failed!");
      }
    }
    setButtonSpinner(false);
  }
}
