import React from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as BiIcons from "react-icons/bi";
import * as BsIcons from "react-icons/bs";
import * as RiIcons from "react-icons/ri";
import * as I5Icons from "react-icons/io5";
import * as SiIcons from "react-icons/si";
import * as MdIcons from "react-icons/md";
import * as CgIcons from "react-icons/cg";
import * as GiIcons from "react-icons/gi";
import * as HiIcons from "react-icons/hi";
import * as VscIcons from "react-icons/vsc";
import * as Fa6Icons from "react-icons/fa6";
import MyApplication from "../views/Pages/Admission/MyApplication";
import Admission from "../views/Pages/Admission/Admission";
import StudentPortal from "../views/Pages/StudentPortal/StudentPortal";
import PaymentHistory from "../views/Pages/Admission/PaymentHistory";
import CashierView from "../views/Pages/Admission/CashierView";
import EnrolledStudents from "../views/Pages/Registrar/EnrolledStudents";
import StudentSections from "../views/Pages/Registrar/StudentSections";
import StudentReport from "../views/Pages/AdminPage/StudentReport";
import StudentsGrading from "../views/Pages/Teacher/StudentsGrading";
import Users from "../views/Pages/Users/Users";
import UserRole from "../views/Pages/UserRole/UserRole";
import SubjectsList from "../views/Pages/AdminPage/Subjects/Subjects";
import EducationType from "../views/Pages/AdminPage/EducationType";
import GradeType from "../views/Pages/AdminPage/GradeType";
import GradeLevel from "../views/Pages/AdminPage/GradeLevel";
import TeacherSubjects from "../views/Pages/AdminPage/TeacherSubjects/TeacherSubjects";
import Fees from "../views/Pages/AdminPage/Fees/Fees";
import Sections from "../views/Pages/AdminPage/Sections/Sections";
import Courses from "../views/Pages/AdminPage/Courses/Courses";
import GlobalFilters from "../views/Pages/AdminPage/GlobalFilters/GlobalFilters";
import Homepage from "../views/Pages/DynamicPages/Homepage/Homepage";
import DynamicAboutUs from "../views/Pages/DynamicPages/AboutUs/AboutUs";
import DynamicAcademics from "../views/Pages/DynamicPages/Academics/Academics";
import Requirements from "../views/Pages/DynamicPages/AdmissionRequirements/Requirements";
import AdmissionScholarship from "../views/Pages/DynamicPages/Scholarship/AdmissionScholarship";
import Footer from "../views/Pages/DynamicPages/Footer/Footer";
import { StudentGradeImport } from "../views/Pages/Imports/StudentGradeImport";

export const sidebarData = [
  {
    path: "/portal/applications",
    title: "Applications",
    icon: (
      <AiIcons.AiOutlineSchedule
        style={{ fontSize: "1.3rem", marginRight: "5px" }}
      />
    ),
    cName: "nav-text",
    access: ["Guest", "Student", "Assesor", "Administrator"],
    component: <MyApplication />,
  },
  {
    path: "/portal/admission",
    title: "Admission",
    icon: (
      <AiIcons.AiFillDatabase
        style={{ fontSize: "1.3rem", marginRight: "5px" }}
      />
    ),
    cName: "nav-text",
    access: ["Guest", "Student", "Administrator"],
    component: <Admission />,
  },
  {
    path: "/portal/student-portal",
    title: "Student Portal",
    icon: (
      <BsIcons.BsFillPersonCheckFill
        style={{ fontSize: "1.3rem", marginRight: "5px" }}
      />
    ),
    cName: "nav-text",
    access: ["Guest", "Student", "Administrator"],
    component: <StudentPortal />,
  },
  {
    path: "/portal/payment",
    title: "Payment",
    icon: (
      <FaIcons.FaMoneyCheck
        style={{ fontSize: "1.3rem", marginRight: "5px" }}
      />
    ),
    cName: "nav-text",
    access: ["Cashier", "Administrator"],
    component: <CashierView />,
  },
  {
    path: "/portal/payment-history",
    title: "Payment History",
    icon: (
      <RiIcons.RiFolderHistoryFill
        style={{ fontSize: "1.3rem", marginRight: "5px" }}
      />
    ),
    cName: "nav-text",
    access: ["Cashier", "Administrator"],
    component: <PaymentHistory />,
  },
  {
    path: "/portal/students",
    title: "Students",
    icon: (
      <I5Icons.IoPeopleSharp
        style={{ fontSize: "1.3rem", marginRight: "5px" }}
      />
    ),
    cName: "nav-text",
    access: ["Registrar", "Administrator"],
    component: <EnrolledStudents />,
  },
  {
    path: "/portal/student-sections",
    title: "Student Sections",
    icon: (
      <SiIcons.SiGoogleclassroom
        style={{ fontSize: "1.3rem", marginRight: "5px" }}
      />
    ),
    cName: "nav-text",
    access: ["Registrar", "Administrator"],
    component: <StudentSections />,
  },
  {
    path: "/portal/student-grading",
    title: "Student Grading",
    icon: (
      <MdIcons.MdGrade style={{ fontSize: "1.3rem", marginRight: "5px" }} />
    ),
    cName: "nav-text",
    access: ["Teacher"],
    component: <StudentsGrading />,
  },
  {
    path: "/portal/student-report",
    title: "Student Report",
    icon: (
      <MdIcons.MdGrade style={{ fontSize: "1.3rem", marginRight: "5px" }} />
    ),
    cName: "nav-text",
    access: ["Administrator", "Registrar"],
    component: <StudentReport />,
  },
  {
    path: "/portal/users",
    title: "Users",
    icon: (
      <FaIcons.FaUsers style={{ fontSize: "1.3rem", marginRight: "5px" }} />
    ),
    cName: "nav-text",
    access: ["Administrator"],
    component: <Users />,
  },
  {
    path: "/portal/user-roles",
    title: "User Roles",
    icon: (
      <CgIcons.CgAssign style={{ fontSize: "1.3rem", marginRight: "5px" }} />
    ),
    cName: "nav-text",
    access: ["Administrator"],
    component: <UserRole />,
  },
  {
    path: "/portal/subject-lists",
    title: "Subject Lists",
    icon: (
      <MdIcons.MdSubject style={{ fontSize: "1.3rem", marginRight: "5px" }} />
    ),
    cName: "nav-text",
    access: ["Administrator"],
    component: <SubjectsList />,
  },
  {
    path: "/portal/education-type",
    title: "Education Types",
    icon: (
      <MdIcons.MdCastForEducation
        style={{ fontSize: "1.3rem", marginRight: "5px" }}
      />
    ),
    cName: "nav-text",
    access: ["Administrator"],
    component: <EducationType />,
  },
  {
    path: "/portal/grade-type",
    title: "Grade Types",
    icon: (
      <GiIcons.GiTeamUpgrade
        style={{ fontSize: "1.3rem", marginRight: "5px" }}
      />
    ),
    cName: "nav-text",
    access: ["Administrator"],
    component: <GradeType />,
  },
  {
    path: "/portal/grade-level",
    title: "Grade Levels",
    icon: (
      <GiIcons.GiLevelEndFlag
        style={{ fontSize: "1.3rem", marginRight: "5px" }}
      />
    ),
    cName: "nav-text",
    access: ["Administrator"],
    component: <GradeLevel />,
  },
  {
    path: "/portal/teacher-subjects",
    title: "Teacher Subjects",
    icon: (
      <GiIcons.GiTeacher style={{ fontSize: "1.3rem", marginRight: "5px" }} />
    ),
    cName: "nav-text",
    access: ["Administrator"],
    component: <TeacherSubjects />,
  },
  {
    path: "/portal/school-fees",
    title: "School Fees",
    icon: (
      <MdIcons.MdPayment style={{ fontSize: "1.3rem", marginRight: "5px" }} />
    ),
    cName: "nav-text",
    access: ["Administrator"],
    component: <Fees />,
  },
  {
    path: "/portal/sections",
    title: "Sections",
    icon: (
      <SiIcons.SiGoogleclassroom
        style={{ fontSize: "1.3rem", marginRight: "5px" }}
      />
    ),
    cName: "nav-text",
    access: ["Administrator"],
    component: <Sections />,
  },
  {
    path: "/portal/courses",
    title: "Courses",
    icon: (
      <SiIcons.SiCoursera style={{ fontSize: "1.3rem", marginRight: "5px" }} />
    ),
    cName: "nav-text",
    access: ["Administrator"],
    component: <Courses />,
  },
  {
    path: "/portal/dynamic-homepage",
    title: "Homepage",
    icon: (
      <AiIcons.AiOutlineHome
        style={{ fontSize: "1.3rem", marginRight: "5px" }}
      />
    ),
    cName: "nav-text",
    access: ["Administrator"],
    component: <Homepage />,
  },
  {
    path: "/portal/dynamic-aboutus",
    title: "AboutUs",
    icon: (
      <BsIcons.BsPeople style={{ fontSize: "1.3rem", marginRight: "5px" }} />
    ),
    cName: "nav-text",
    access: ["Administrator"],
    component: <DynamicAboutUs />,
  },
  {
    path: "/portal/dynamic-academics",
    title: "Academics",
    icon: (
      <HiIcons.HiAcademicCap
        style={{ fontSize: "1.3rem", marginRight: "5px" }}
      />
    ),
    cName: "nav-text",
    access: ["Administrator"],
    component: <DynamicAcademics />,
  },
  {
    path: "/portal/dynamic-admission-requirements",
    title: "Admission Requirements",
    icon: (
      <VscIcons.VscChecklist
        style={{ fontSize: "1.3rem", marginRight: "5px" }}
      />
    ),
    cName: "nav-text",
    access: ["Administrator"],
    component: <Requirements />,
  },
  {
    path: "/portal/dynamic-admission-scholarships",
    title: "Admission Scholarships",
    icon: (
      <FaIcons.FaUserGraduate
        style={{ fontSize: "1.3rem", marginRight: "5px" }}
      />
    ),
    cName: "nav-text",
    access: ["Administrator"],
    component: <AdmissionScholarship />,
  },
  {
    path: "/portal/dynamic-footer",
    title: "Footer",
    icon: (
      <BiIcons.BiDockBottom
        style={{ fontSize: "1.3rem", marginRight: "5px" }}
      />
    ),
    cName: "nav-text",
    access: ["Administrator"],
    component: <Footer />,
  },
  {
    path: "/portal/general-grading-regulars",
    title: "General Grading",
    icon: (
      <GiIcons.GiTeamUpgrade
        style={{ fontSize: "1.3rem", marginRight: "5px" }}
      />
    ),
    cName: "nav-text",
    access: ["Course Coordinator"],
  },
  {
    path: "/portal/global-filters",
    title: "Global Filters",
    icon: (
      <AiIcons.AiOutlineGlobal
        style={{ fontSize: "1.3rem", marginRight: "5px" }}
      />
    ),
    cName: "nav-text",
    access: ["Administrator"],
    component: <GlobalFilters />,
  },
  {
    path: "/portal/import-grades",
    title: "Import Grades",
    icon: (
      <Fa6Icons.FaFileImport
        style={{ fontSize: "1.3rem", marginRight: "5px" }}
      />
    ),
    cName: "nav-text",
    access: ["Administrator"],
    component: <StudentGradeImport />,
  },
];
