export default {
    blankBasicEducationForm: {
        id: "",
        programs: "",
        last_name: "",
        suffix: "",
        first_name: "",
        middle_name: "",
        birth_place: "",
        birth_date: "",
        gender: "",
        age: "",
        religion: "",
        civil_status: "",
        semester: "",
        street: "",
        province: "",
        city: "",
        barangay: "",
        elementary_school_name: "",
        elementary_year_graduated: "",
        elementary_school_address: "",
        junior_high_school_name: "",
        junior_high_year_graduated: "",
        junior_high_school_address: "",
        senior_high_school_name: "",
        senior_high_year_graduated: "",
        senior_high_school_address: "",
        form138: "",
        form137: "",
        picture: "",
        good_moral: "",
        ncae: "",
        birth_certificate: "",
        selected_program: "",
        living_status: "",
        no_of_siblings: "",
        father_name: "",
        father_age: "",
        father_birth_date: "",
        father_birth_place: "",
        father_home_address: "",
        father_contact_no: "",
        father_highest_education: "",
        father_occupation: "",
        father_company: "",
        father_monthly_income: "",
        mother_name: "",
        mother_age: "",
        mother_birth_date: "",
        mother_birth_place: "",
        mother_home_address: "",
        mother_contact_no: "",
        mother_highest_education: "",
        mother_occupation: "",
        mother_company: "",
        mother_monthly_income: "",
        guardian_name: "",
        guardian_age: "",
        guardian_birth_date: "",
        guardian_birth_place: "",
        guardian_home_address: "",
        guardian_contact_no: "",
        guardian_highest_education: "",
        guardian_occupation: "",
        guardian_company: "",
        guardian_monthly_income: "",
        health_student_boolean: "",
        health_student_specific: "",
        consulting_doctor: "",
        important_illness: "",
        hospitalized_in_past: "",
        specific_hospitalized_in_past: "",
        difficulty: "",
        family_health_history: "",
        family_member_with_sickness: "",
        vaccination: "",
        self_evaluation: "",
        financial_support: "",
        other_financial_support: "",
        grew_up: "",
        language_home: "",
        other_language_home: "",
        concerns: "",
        other_concern: "",
        problem_solution: "",
        other_problem_solution: "",
        cope_up_stress: "",
        other_cope_up_stress: "",
        how_to_know_sapc: "",
        other_how_to_know_sapc: "",
        listahan: "",
        household_number: "",
        disability: "",
        pwd_number: "",
        subsidy: "",
        subsidy_details: "",
        remarks: "",
        is_esc: "",
        esc_type: "",
        esc_file: "",
        cert_of_residency: "",
        copy_of_grades: "",
        shs_diploma: "",
        esc_qvr: "",
        qvr_file: "",
        esc_info: "",
        approve_esc: "",
        is_scholar: "",
        scholarship_name: "",
        scholarship_discount: "",
        guardian_toggle: false,
        lrn: "",
        student_id: "",
        student_status: "",
        sy_from: "",
        sy_to: "",
    },
    blankScholarshipForm: {
        scholarship_student_name: "",
        scholarhip_date: new Date(),
        scholarship_academic_year_from: "",
        scholarship_academic_year_to: "",
        scholarship_semester: "",
        scholarship_grade_level: "",
        scholarship_course: "",
        scholarship_year: "",
        scholarship_application_type: "",
        scholarship_previous: "",
        family_discount: "",
        family_discount_siblings: "",
        academic_scholarship: "",
        academic_scholarship_rank: "",
        academic_scholarship_proof: "",
        athletic_scholarship: "",
        athletic_level: "",
        athletic_varsity_scholarship: "",
        scholarship_specific_sports: "",
        loyalty_discount: "",
        loyalty_discount_specific_year: "",
        sapc_employee: "",
        sapc_employee_scholarship_specific: "",
        others: "",
        other_scholarship_specific: "",
        esc_switch: "",
        esc_file: "",
        scholarship: "",
    },
    blankTesdaForm: {
        id: "",
        last_name: "",
        suffix: "",
        first_name: "",
        middle_name: "",
        contact_no: "",
        street: "",
        province: "",
        city: "",
        barangay: "",
        father_name: "",
        father_contact_no: "",
        father_home_address: "",
        mother_name: "",
        mother_contact_no: "",
        mother_home_address: "",
        guardian_name: "",
        guardian_contact_no: "",
        guardian_home_address: "",
        tesda_form: "",
        date_of_appointment: "",
        sy_from: "",
        sy_to: "",
    },
    blankPaymentForm: {
        paymentMode: "",
        paymentMethod: "",
        tuition_fee: "",
        discounted_tuituion_fee: "",
        paymentModeDetails: "",
        total_tuition: "",
        no_of_units: "",
        subjects_with_lab_units: "",
        base_tuition: "",
        esc_discount: ""
    },
    blankSubjectForm: {
        enrollee_type: "",
        subjects: []
    },
    blankDetails: {
        id: "",
        application_id: "",
        ref_no: "",
        student_id: "",
        last_name: "",
        given_name: "",
        middle_name: "",
        grade_level: "",
        email_address: "",
        suffix: "",
        birth_place: "",
        birth_date: "",
        gender: "",
        religion: "",
        lrn: "",
        age: "",
        civil_status: "",
        house_bldg_street: "",
        province: "",
        city_municipality: "",
        barangay: "",
        living_status: "",
        no_of_siblings: "",
        student_status: "",
    },
    blankSummary: {
        cancel_count: 0,
        pending_count: 0,
        certify_count: 0,
        enrolled_count: 0,
        last_update: "",
        updated_at: "",
    },
    blankSection: {
        id: "",
        grade_level_id: "",
        grade_level: "",
        section: "",
        allStudents: [],
        withSection: [],
        teacher: "",
        coordinator: "",
        course_type_id: ""
    },
    blankSectionDetails: {
        grade_level_id: "",
        section_id: "",
        document: "",
        courseCodeArr: "",
        semester: ""
    },
    blankTeacherSubjects: {
        teacher_id: "",
        grade_level_id: "",
        education_type_id: "",
        grade_type_id: "",
        section_id: "",
        subjects: [],
        semester: "",
    },
    blankUser: {
        email: "",
        given_name: "",
        last_name: "",
        middle_name: "",
        email_address: "",
        suffix: "",
        sys_role_id: "",
        status: "",
    },
    blankSubject: {
        id: "",
        grade_type_id: "",
        year_level_id: "",
        semester: "",
        description: "",
        unit: "",
        lab_unit_price: "",
        enabled: "",
        course_type_id: "",
        course_code: "",
        subject_type: "",
    },
    blankGradeLevel: {
        id: "",
        name: "",
        education_type_id: "",
        grade_type_id: "",
        enabled: ""
    },
    blankSingleSection: {
        id: "",
        grade_level_id: "",
        name: "",
        enabled: "",
        course_type_id: "",
        grade_type_id: "",
        adviser_id: "",
        coordinator_id: "",
    },
    blankCourse: {
        id: "",
        grade_type_id: "",
        name: "",
        description: "",
        enabled: "",
    }
}