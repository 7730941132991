import { ENDPOINT, S3_URL } from "../constants/api";
import axios from "axios";
import { notifyError, notifySuccess } from "./Toaster";
import moment from "moment";

export const dynamicHomepageActions = {
  saveTemplate,
  getCurrentTemplate,
  getSelectedTemplate,
  publishTemplate,
  getCurrentTemplateFrontPage
  // updateUser
};

const token = localStorage.getItem("jwtToken")
  ? localStorage.getItem("jwtToken")
  : "";
const headers = {
  Authorization: `Bearer ${token}`,
  "Content-Type": "application/json",
  "X-Requested-With": "XMLHttpRequest",
};

async function saveTemplate(data, setLoading, setButtonSpinner, setButtonSpinner2) {
  try {
    let res = await axios.post(ENDPOINT + `/dynamic-homepage`, data, {
      headers: headers,
    });

    notifySuccess("Successfully created new template");
    setLoading(false)
    setButtonSpinner(false)
    setButtonSpinner2(false)
    setTimeout(() => window.location.reload(), 2000);
  } catch (err) {
    if (err.response && err.response.data && err.response.data.error) {
      notifyError(err.response.data.error);
    } else if (err.response) {
      notifyError("Error while saving template");
    }
    setLoading(false)
    setButtonSpinner(false)
    setButtonSpinner2(false)
  }
}

async function getCurrentTemplate(
  setHomepage,
  homepage,
  setCurrentVersion,
  setSelectedVersion,
  setLoading,
  setVersionSelection,
  carousel1,
  carousel2,
  carousel3,
  carouselMobile1,
  carouselMobile2,
  carouselMobile3,
  basic1,
  basicGif,
  tertiary1,
  tertiaryGif,
  tesda1,
  tesdaGif,
  news5,
  news4,
  news3,
  news2,
  news1,
) {
  try {
    let res = await axios.get(ENDPOINT + `/dynamic-homepage`, {
      headers: headers,
    });

    const desktopCarousel = res.data.current.desktop_carousel;
    const mobileCarousel = res.data.current.mobile_carousel;
    const news = res.data.current.news;
    const courses = res.data.current.courses;
    const versions = res.data.versions;
    setCurrentVersion(res.data.current.version)
    setVersionSelection(versions)
    setSelectedVersion(res.data.current.version)

    let newDesktopCarousel = [];
    let newMobileCarousel = [];
    let newsData = [];
    
    if (desktopCarousel.length >= 1) {
      desktopCarousel.map(desk => {
        let image = "";
        if (desk.src.includes("static/media/carousel-1")) {
          image = carousel1;
        } else if (desk.src.includes("static/media/carousel-2")) {
          image = carousel2;
        } else if (desk.src.includes("static/media/carousel-3")) {
          image = carousel3;
        } else {
          image = desk.src;
        }

        desk.src = image;
        newDesktopCarousel.push(desk)
      })
    }

    if (mobileCarousel.length >= 1) {
      mobileCarousel.map(mobi => {
        let image = "";
        if (mobi.src.includes("static/media/carousel-1")) {
          image = carouselMobile1;
        } else if (mobi.src.includes("static/media/carousel-2")) {
          image = carouselMobile2;
        } else if (mobi.src.includes("static/media/carousel-3")) {
          image = carouselMobile3;
        } else {
          image = mobi.src;
        }

        mobi.src = image;
        newMobileCarousel.push(mobi)
      })
    }

    let basic = courses.basic;
    let tertiary = courses.tertiary;
    let technical = courses.technical;

    if(basic.image.includes("static/media")){
      basic.image = basic1
    }else if(basic.gif.includes("static/media")){
      basic.gif = basicGif
    }else if(tertiary.image.includes("static/media")){
      tertiary.image = tertiary1
    }else if(tertiary.gif.includes("static/media")){
      tertiary.gif = tertiaryGif
    }else if(technical.image.includes("static/media")){
      technical.image = tesda1
    }else if(technical.gif.includes("static/media")){
      technical.gif = tesdaGif
    }

    if(news.length >= 1){
      news.map(nws => {
        let image = "";
        if (nws.image.includes("/static/media/news-5")) {
          image = news5;
        } else if (nws.image.includes("/static/media/news-4")) {
          image = news4;
        } else if (nws.image.includes("static/media/news-3")) {
          image = news3;
        } else if (nws.image.includes("static/media/news-2")) {
          image = news2;
        } else if (nws.image.includes("static/media/news-1")) {
          image = news1;
        } else {
          image = nws.image;
        }

        nws.image = image;
        newsData.push(nws)
      })
    }

    setHomepage({
      ...homepage,
      desktopCarousel: newDesktopCarousel,
      mobileCarousel: newMobileCarousel,
      courses: {
        basic: basic,
        tertiary: tertiary,
        technical: technical,
      },
      news: newsData
    })

    setLoading(false)
  } catch (err) {
    if (err.response) {
      notifyError("Error while getting latest template.");
    }
    setLoading(false)
  }
}

async function getSelectedTemplate(
  setHomepage,
  homepage,
  setCurrentVersion,
  selectedVersion,
  setLoading,
  setVersionSelection,
  carousel1,
  carousel2,
  carousel3,
  carouselMobile1,
  carouselMobile2,
  carouselMobile3,
  basic1,
  basicGif,
  tertiary1,
  tertiaryGif,
  tesda1,
  tesdaGif,
  news5,
  news4,
  news3,
  news2,
  news1,
) {
  try {
    let res = await axios.get(ENDPOINT + `/get-dynamic-homepage/${selectedVersion}`, {
      headers: headers,
    });

    const desktopCarousel = res.data.desktop_carousel;
    const mobileCarousel = res.data.mobile_carousel;
    const news = res.data.news;
    const courses = res.data.courses;
    let newDesktopCarousel = [];
    let newMobileCarousel = [];
    let newsData = [];
    
    if (desktopCarousel.length >= 1) {
      desktopCarousel.map(desk => {
        let image = "";
        if (desk.src.includes("static/media/carousel-1")) {
          image = carousel1;
        } else if (desk.src.includes("static/media/carousel-2")) {
          image = carousel2;
        } else if (desk.src.includes("static/media/carousel-3")) {
          image = carousel3;
        } else {
          image = desk.src;
        }

        desk.src = image;
        newDesktopCarousel.push(desk)
      })
    }

    if (mobileCarousel.length >= 1) {
      mobileCarousel.map(mobi => {
        let image = "";
        if (mobi.src.includes("static/media/carousel-1")) {
          image = carouselMobile1;
        } else if (mobi.src.includes("static/media/carousel-2")) {
          image = carouselMobile2;
        } else if (mobi.src.includes("static/media/carousel-3")) {
          image = carouselMobile3;
        } else {
          image = mobi.src;
        }

        mobi.src = image;
        newMobileCarousel.push(mobi)
      })
    }

    let basic = courses.basic;
    let tertiary = courses.tertiary;
    let technical = courses.technical;

    if(basic.image.includes("static/media")){
      basic.image = basic1
    }else if(basic.gif.includes("static/media")){
      basic.gif = basicGif
    }else if(tertiary.image.includes("static/media")){
      tertiary.image = tertiary1
    }else if(tertiary.gif.includes("static/media")){
      tertiary.gif = tertiaryGif
    }else if(technical.image.includes("static/media")){
      technical.image = tesda1
    }else if(technical.gif.includes("static/media")){
      technical.gif = tesdaGif
    }

    if(news.length >= 1){
      news.map(nws => {
        let image = "";
        if (nws.image.includes("/static/media/news-5")) {
          image = news5;
        } else if (nws.image.includes("/static/media/news-4")) {
          image = news4;
        } else if (nws.image.includes("static/media/news-3")) {
          image = news3;
        } else if (nws.image.includes("static/media/news-2")) {
          image = news2;
        } else if (nws.image.includes("static/media/news-1")) {
          image = news1;
        } else {
          image = nws.image;
        }

        nws.image = image;
        newsData.push(nws)
      })
    }

    setHomepage({
      ...homepage,
      desktopCarousel: newDesktopCarousel,
      mobileCarousel: newMobileCarousel,
      courses: {
        basic: basic,
        tertiary: tertiary,
        technical: technical,
      },
      news: newsData
    })

    setLoading(false)
  } catch (err) {
    if (err.response) {
      notifyError("Error while getting template.");
    }
    setLoading(false)
  }
}

async function publishTemplate(selectedVersion, setButtonSpinner, setLoading, setButtonSpinner2) {
  try {
    let res = await axios.get(ENDPOINT + `/dynamic-homepage-publish-template/${selectedVersion}`, {
      headers: headers,
    });

    setButtonSpinner(false)
    notifySuccess("Successfully published new version");
    setTimeout(() => window.location.reload(), 2000);
  } catch (err) {
    if (err.response) {
      notifyError("Error while publishing new version.");
    }
    setButtonSpinner(false)
  }
}

async function getCurrentTemplateFrontPage(
  setHomepage,
  homepage,
  setCurrentVersion,
  setSelectedVersion,
  setLoading,
  setVersionSelection,
  carousel1,
  carousel2,
  carousel3,
  carouselMobile1,
  carouselMobile2,
  carouselMobile3,
  basic1,
  basicGif,
  tertiary1,
  tertiaryGif,
  tesda1,
  tesdaGif,
  news5,
  news4,
  news3,
  news2,
  news1,
) {
  try {
    let res = await axios.get(ENDPOINT + `/dynamic-homepage-frontpage`, {
      headers: headers,
    });

    const desktopCarousel = res.data.current.desktop_carousel;
    const mobileCarousel = res.data.current.mobile_carousel;
    const news = res.data.current.news;
    const courses = res.data.current.courses;
    const versions = res.data.versions;
    setCurrentVersion(res.data.current.version)
    setVersionSelection(versions)
    setSelectedVersion(res.data.current.version)

    let newDesktopCarousel = [];
    let newMobileCarousel = [];
    let newsData = [];
    
    if (desktopCarousel.length >= 1) {
      desktopCarousel.map(desk => {
        let image = "";
        if (desk.src.includes("static/media/carousel-1")) {
          image = carousel1;
        } else if (desk.src.includes("static/media/carousel-2")) {
          image = carousel2;
        } else if (desk.src.includes("static/media/carousel-3")) {
          image = carousel3;
        } else {
          image = desk.src;
        }

        desk.src = image;
        newDesktopCarousel.push(desk)
      })
    }

    if (mobileCarousel.length >= 1) {
      mobileCarousel.map(mobi => {
        let image = "";
        if (mobi.src.includes("static/media/carousel-1")) {
          image = carouselMobile1;
        } else if (mobi.src.includes("static/media/carousel-2")) {
          image = carouselMobile2;
        } else if (mobi.src.includes("static/media/carousel-3")) {
          image = carouselMobile3;
        } else {
          image = mobi.src;
        }

        mobi.src = image;
        newMobileCarousel.push(mobi)
      })
    }

    let basic = courses.basic;
    let tertiary = courses.tertiary;
    let technical = courses.technical;

    if(basic.image.includes("static/media")){
      basic.image = basic1
    }else if(basic.gif.includes("static/media")){
      basic.gif = basicGif
    }else if(tertiary.image.includes("static/media")){
      tertiary.image = tertiary1
    }else if(tertiary.gif.includes("static/media")){
      tertiary.gif = tertiaryGif
    }else if(technical.image.includes("static/media")){
      technical.image = tesda1
    }else if(technical.gif.includes("static/media")){
      technical.gif = tesdaGif
    }

    if(news.length >= 1){
      news.map(nws => {
        let image = "";
        if (nws.image.includes("/static/media/news-5")) {
          image = news5;
        } else if (nws.image.includes("/static/media/news-4")) {
          image = news4;
        } else if (nws.image.includes("static/media/news-3")) {
          image = news3;
        } else if (nws.image.includes("static/media/news-2")) {
          image = news2;
        } else if (nws.image.includes("static/media/news-1")) {
          image = news1;
        } else {
          image = nws.image;
        }

        nws.image = image;
        newsData.push(nws)
      })
    }

    setHomepage({
      ...homepage,
      desktopCarousel: newDesktopCarousel,
      mobileCarousel: newMobileCarousel,
      courses: {
        basic: basic,
        tertiary: tertiary,
        technical: technical,
      },
      news: newsData
    })

    setLoading(false)
  } catch (err) {
    if (err.response) {
      notifyError("Error while getting latest template.");
    }
    setLoading(false)
  }
}
