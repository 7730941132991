import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Row,
  Input,
  Label,
  Form,
  FormGroup,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import optionList from "../Admission/optionList";
import { studentGradeImportActions } from "../../../services/StudentGradeImportServices.js";

export const DownloadTemplate = ({
  gradeLevelSelection,
  coursesSelection,
  sections,
}) => {
  const [gradeLevelFilter, setGradeLevelFilter] = useState(null);
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const { handleSubmit, errors, control, setValue, getValues } = useForm();

  const withSemester = [
    "Grade 11",
    "Grade 12",
    "First year",
    "Second year",
    "Third year",
    "Fourth year",
  ];
  const [filters, setFilters] = useState({
    sy_from: null,
    sy_to: null,
    grade_level: null,
    grade_type: null,
    semester: null,
    course_id: null,
    section_id: null,
  });

  const onSubmit = (data) => {
    setButtonSpinner(true);
    let newFilters = {
      sy_from: moment(data.sy_from).format("YYYY"),
      sy_to: moment(data.sy_to).format("YYYY"),
      grade_level: filters.grade_level,
      grade_type: filters.grade_type,
      semester: filters.semester,
      course_id: filters.course_id,
      section_id: filters.section_id,
    };

    studentGradeImportActions.downloadTemplate(newFilters, setButtonSpinner);
  };

  return (
    <Card className="p-2">
      <CardBody>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md="3" xs="12">
              <FormGroup>
                <Label>
                  <small className="font-weight-bolder">S.Y. From</small>
                </Label>
                <Controller
                  control={control}
                  name="sy_from"
                  defaultValue=""
                  rules={{ required: true }}
                  invalid={errors.sy_from}
                  render={({ onChange, value, onBlur, name }) => (
                    <DatePicker
                      className="form-control bg-light"
                      selected={
                        moment(filters.syFrom).isValid()
                          ? moment(filters.syFrom).toDate()
                          : filters.syFrom
                      }
                      onChange={(date) => {
                        setValue("sy_from", date);
                        setFilters({ ...filters, syFrom: date });
                        return date;
                      }}
                      showYearPicker
                      dateFormat="yyyy"
                      yearItemNumber={10}
                      name="sy_from"
                      defaultValue=""
                    />
                  )}
                />
                {errors.sy_from && errors.sy_from.type == "required" && (
                  <div
                    style={{
                      marginTop: "0.25rem",
                      fontSize: "80%",
                      color: "#f86c6b",
                    }}
                  >
                    School Year From is required!
                  </div>
                )}
              </FormGroup>
            </Col>
            <Col md="3" xs="12">
              <FormGroup>
                <Label>
                  <small className="font-weight-bolder">S.Y. To</small>
                </Label>
                <Controller
                  control={control}
                  name="sy_to"
                  defaultValue=""
                  rules={{
                    required: true,
                    validate: (value) => value > getValues("sy_from"),
                  }}
                  invalid={errors.sy_to}
                  render={({ onChange, value, onBlur, name }) => (
                    <DatePicker
                      className="form-control bg-light"
                      selected={
                        moment(filters.syTo).isValid()
                          ? moment(filters.syTo).toDate()
                          : filters.syTo
                      }
                      onChange={(date) => {
                        setValue("sy_to", date);
                        setFilters({ ...filters, syTo: date });
                        return date;
                      }}
                      showYearPicker
                      dateFormat="yyyy"
                      yearItemNumber={10}
                      name="sy_to"
                      defaultValue=""
                    />
                  )}
                />
                {errors.sy_to && errors.sy_to.type == "required" && (
                  <div
                    style={{
                      marginTop: "0.25rem",
                      fontSize: "80%",
                      color: "#f86c6b",
                    }}
                  >
                    School Year To is required!
                  </div>
                )}
                {errors.sy_to && errors.sy_to.type == "validate" && (
                  <div
                    style={{
                      marginTop: "0.25rem",
                      fontSize: "80%",
                      color: "#f86c6b",
                    }}
                  >
                    School Year To cannot be lower or equal to S.Y. From!
                  </div>
                )}
              </FormGroup>
            </Col>
            <Col md="3" xs="12" className="mb-2">
              <Label>
                <small className="font-weight-bolder">
                  <i className="fa fa-lightbulb-o"></i>&nbsp;Filter by Grade
                  Level
                </small>
              </Label>
              <Controller
                control={control}
                name="gradeLevel"
                rules={{ required: true }}
                invalid={errors.gradeLevel}
                defaultValue=""
                render={({ onChange, value, onBlur, name }) => (
                  <Select
                    options={gradeLevelSelection}
                    onChange={(e) => {
                      setValue("semester", null);
                      setValue("course_id", null);
                      setValue("section_id", null);
                      setValue("gradeLevel", e);
                      setGradeLevelFilter(e);
                      setFilters({
                        ...filters,
                        grade_level: e.value,
                        grade_type: e.grade_type,
                        course: null,
                        semester: null,
                        section_id: null,
                      });
                      return e;
                    }}
                    defaultValue=""
                  />
                )}
              />
              {errors?.gradeLevel && (
                <div
                  style={{
                    marginTop: "0.25rem",
                    fontSize: "80%",
                    color: "#f86c6b",
                  }}
                >
                  Grade level is required!
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col md="3" xs="12" className="mb-2">
              <Label>
                <small className="font-weight-bolder">
                  <i className="fa fa-lightbulb-o"></i>&nbsp;Filter by Section
                </small>
              </Label>
              <Controller
                control={control}
                name="section_id"
                rules={{ required: true }}
                invalid={errors.section_id}
                defaultValue=""
                render={({ onChange, value, onBlur, name }) => (
                  <Select
                    options={
                      gradeLevelFilter
                        ? sections.filter(
                            (section) =>
                              section.grade_level_id == gradeLevelFilter.value
                          )
                        : []
                    }
                    onChange={(e) => {
                      setFilters({
                        ...filters,
                        section_id: e.value,
                      });
                      setValue("section_id", e);
                      return e;
                    }}
                    defaultValue=""
                  />
                )}
              />
              {errors?.section_id && (
                <div
                  style={{
                    marginTop: "0.25rem",
                    fontSize: "80%",
                    color: "#f86c6b",
                  }}
                >
                  Section is required!
                </div>
              )}
            </Col>
            {gradeLevelFilter &&
              withSemester.includes(gradeLevelFilter?.label) && (
                <>
                  <Col md="3" xs="12" className="mb-2">
                    <Label>
                      <small className="font-weight-bolder">
                        <i className="fa fa-lightbulb-o"></i>&nbsp;Filter by
                        Courses
                      </small>
                    </Label>
                    <Controller
                      control={control}
                      name="course_id"
                      rules={{ required: false }}
                      invalid={errors.course_id}
                      defaultValue={null}
                      render={({ onChange, value, onBlur, name }) => (
                        <Select
                          options={coursesSelection}
                          onChange={(e) => {
                            setFilters({ ...filters, course_id: e.value });
                            return e;
                          }}
                          defaultValue={null}
                        />
                      )}
                    />
                  </Col>
                  <Col md="3" xs="12" className="mb-2">
                    <Label>
                      <small className="font-weight-bolder">
                        <i className="fa fa-lightbulb-o"></i>&nbsp;Filter by
                        Semester
                      </small>
                    </Label>
                    <Controller
                      control={control}
                      name="semester"
                      rules={{ required: false }}
                      invalid={errors.semester}
                      defaultValue={null}
                      render={({ onChange, value, onBlur, name }) => (
                        <Select
                          options={optionList.semester}
                          onChange={(e) => {
                            setFilters({ ...filters, semester: e.value });
                            return e;
                          }}
                          defaultValue={null}
                        />
                      )}
                    />
                  </Col>
                </>
              )}
          </Row>
          <Row className="pt-4">
            <Col xs="12" md="12">
              <Button
                disabled={buttonSpinner}
                className="bg-danger-edit text-white border-0"
              >
                Download Template
              </Button>
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
};
