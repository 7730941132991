import React from 'react';
import { Card, CardText, CardTitle, Col, Container, Label, Row } from 'reactstrap';

const StudentInfo = ({details}) => {

    return(
        <Container className="py-4">
            <Row>
                <Col md="12" xs="12" className="mb-3">
                    <Card body outline color="secondary">
                        <CardTitle tag="h5" className="text-danger-edit font-weight-bolder">Education</CardTitle>
                        <Label>
                            <span className="font-weight-bolder text-center">Grade Year/Level: </span>
                            <span>{details.grade_year_level}</span>
                        </Label>
                        <Label>
                            <span className="font-weight-bolder text-center">Section: </span>
                            <span>{details.student_section}</span>
                        </Label>
                        <Label>
                            <span className="font-weight-bolder text-center">Course: </span>
                            <span>{details.course}</span>
                        </Label>
                        <Label>
                            <span className="font-weight-bolder text-center">Semester: </span>
                            <span>{details.semester}</span>
                        </Label>
                    </Card>
                </Col>
                <Col md="12" xs="12">
                    <Card body outline color="secondary">
                        <CardTitle tag="h5" className="text-danger-edit font-weight-bolder">Personal Information</CardTitle>
                        <Label>
                            <span className="font-weight-bolder text-center">Last Name: </span>
                            <span>{details.last_name}</span>
                        </Label>
                        <Label>
                            <span className="font-weight-bolder text-center">First Name: </span>
                            <span>{details.given_name}</span>
                        </Label>
                        <Label>
                            <span className="font-weight-bolder text-center">Middle Name: </span>
                            <span>{details.middle_name}</span>
                        </Label>
                        <Label>
                            <span className="font-weight-bolder text-center">Suffix: </span>
                            <span>{details.suffix}</span>
                        </Label>
                        <Label>
                            <span className="font-weight-bolder text-center">Birth Date: </span>
                            <span>{details.birth_date}</span>
                        </Label>
                        <Label>
                            <span className="font-weight-bolder text-center">Birth Place: </span>
                            <span>{details.birth_place}</span>
                        </Label>
                        <Label>
                            <span className="font-weight-bolder text-center">Religion: </span>
                            <span>{details.religion}</span>
                        </Label>
                        <Label>
                            <span className="font-weight-bolder text-center">LRN: </span>
                            <span>{details.lrn}</span>
                        </Label>
                        <Label>
                            <span className="font-weight-bolder text-center">House no., Building, Street: </span>
                            <span>{details.house_bldg_street}</span>
                        </Label>
                        <Label>
                            <span className="font-weight-bolder text-center">Province: </span>
                            <span>{details.province}</span>
                        </Label>
                        <Label>
                            <span className="font-weight-bolder text-center">City: </span>
                            <span>{details.city_municipality}</span>
                        </Label>
                        <Label>
                            <span className="font-weight-bolder text-center">Barangay: </span>
                            <span>{details.barangay}</span>
                        </Label>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default StudentInfo;