import React, { useEffect, useState } from "react";
import {
  Container,
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  CustomInput,
  Spinner,
  Button,
  CardHeader,
  CardBody,
  Card,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import moment from "moment";
import { admissionActions } from "../../../services/AdmissionServices.js";
import Loading from "./Loading.js";

const ReviewSubmit = ({
  handleCancel,
  currentStep,
  setCurrentStep,
  loading,
  setLoading,
  basicForm,
  scholarshipForm,
  paymentForm,
  educationTypes,
  setEducationSelected,
  setBasicForm,
  setPaymentForm,
  setScholarShipForm,
  blankBasicEducationForm,
  blankScholarshipForm,
  blankPaymentForm,
  subjects,
  subjectForm,
  setSubjectForm,
  selectedSubjects,
  gradeTypes
}) => {
  const admissionAnswers = localStorage.getItem("admissionAnswers")
    ? JSON.parse(localStorage.getItem("admissionAnswers"))
    : "";
  const { register, handleSubmit, errors, control, setValue, getValues } =
    useForm();
  const [educationId, setEducationId] = useState(0);
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [referenceNo, setReferenceNo] = useState("");
  const [gradeTypeSelection, setGradeTypeSelection] = useState([]);
  
  const handlePreviousBtn = () => {
    setCurrentStep(currentStep - 1);
  };

  useEffect(() => {
    if (educationTypes && educationTypes.length >= 1) {
      educationTypes.map((type) => {
        if (type.name == admissionAnswers.education_type) {
          setEducationId(type.id);
        }
      });
    }
  }, [admissionAnswers]);

  useEffect(() => {
    if (gradeTypes.length) {
      let gradeTypesArr = gradeTypes.map(gt=>{
        return { value: gt.id, label: gt.name }
      }) 
      setGradeTypeSelection(gradeTypesArr)
    }
  }, [gradeTypes])

  const getOptionLabelByValue = (options, value) => {
    const option = options.find((option) => option.value == value);
    return option ? option.label : "";
  };

  const [submissionModal, setSubmissionModal] = useState(false);

  const toggleMessage = () => {
    setSubmissionModal(!submissionModal);
    setEducationSelected("");
    setBasicForm = { blankBasicEducationForm };
    setScholarShipForm = { blankScholarshipForm };
    setPaymentForm = { blankPaymentForm };
    setTimeout(() => window.location.reload(), 1000);
  };

  const studentStatusLevels = [
    "grade 11",
    "grade 12",
    "first year",
    "second year",
    "third year",
    "fourth year",
  ];

  const onSubmit = async (data) => {
    let files = [];

    if (basicForm.form138 && basicForm.form138.name) {
      files.push({
        filename: basicForm.form138.name,
        document_type_id: 1,
        document: await convertBase64(basicForm.form138),
        id: basicForm.form138_id ? basicForm.form138_id : "",
      });
    }

    if (basicForm.form137 && basicForm.form137.name) {
      files.push({
        filename: basicForm.form137.name,
        document_type_id: 2,
        document: await convertBase64(basicForm.form137),
        id: basicForm.form137_id ? basicForm.form137_id : "",
      });
    }

    if (basicForm.good_moral && basicForm.good_moral.name) {
      files.push({
        filename: basicForm.good_moral.name,
        document_type_id: 3,
        document: await convertBase64(basicForm.good_moral),
        id: basicForm.good_moral_id ? basicForm.good_moral_id : "",
      });
    }

    if (basicForm.ncae && basicForm.ncae.name) {
      files.push({
        filename: basicForm.ncae.name,
        document_type_id: 4,
        document: await convertBase64(basicForm.ncae),
        id: basicForm.ncae_id ? basicForm.ncae_id : "",
      });
    }

    if (basicForm.birth_certificate && basicForm.birth_certificate.name) {
      files.push({
        filename: basicForm.birth_certificate.name,
        document_type_id: 5,
        document: await convertBase64(basicForm.birth_certificate),
        id: basicForm.birth_certificate_id
          ? basicForm.birth_certificate_id
          : "",
      });
    }

    if (basicForm.esc_file && basicForm.esc_file.name) {
      files.push({
        filename: basicForm.esc_file.name,
        document_type_id: 6,
        document: await convertBase64(basicForm.esc_file),
        id: basicForm.esc_file_id ? basicForm.esc_file_id : "",
      });
    }

    if (basicForm.picture && basicForm.picture.name) {
      files.push({
        filename: basicForm.picture.name,
        document_type_id: 7,
        document: await convertBase64(basicForm.picture),
        id: basicForm.picture_id ? basicForm.picture_id : "",
      });
    }

    if (
      scholarshipForm.academic_scholarship_proof &&
      scholarshipForm.academic_scholarship_proof.name
    ) {
      files.push({
        filename: scholarshipForm.academic_scholarship_proof.name,
        document_type_id: 8,
        document: await convertBase64(
          scholarshipForm.academic_scholarship_proof
        ),
        id: basicForm.academic_scholarship_proof_id
          ? basicForm.academic_scholarship_proof_id
          : "",
      });
    }

    if (basicForm.cert_of_residency && basicForm.cert_of_residency.name) {
      files.push({
        filename: basicForm.cert_of_residency.name,
        document_type_id: 10,
        document: await convertBase64(basicForm.cert_of_residency),
        id: basicForm.cert_of_residency_id
          ? basicForm.cert_of_residency_id
          : "",
      });
    }

    if (basicForm.copy_of_grades && basicForm.copy_of_grades.name) {
      files.push({
        filename: basicForm.copy_of_grades.name,
        document_type_id: 11,
        document: await convertBase64(basicForm.copy_of_grades),
        id: basicForm.copy_of_grades_id ? basicForm.copy_of_grades_id : "",
      });
    }

    if (basicForm.shs_diploma && basicForm.shs_diploma.name) {
      files.push({
        filename: basicForm.shs_diploma.name,
        document_type_id: 12,
        document: await convertBase64(basicForm.shs_diploma),
        id: basicForm.shs_diploma_id ? basicForm.shs_diploma_id : "",
      });
    }

    if (basicForm.qvr_file && basicForm.qvr_file.name) {
      files.push({
        filename: basicForm.qvr_file.name,
        document_type_id: 13,
        document: await convertBase64(basicForm.qvr_file),
        id: basicForm.qvr_file_id ? basicForm.qvr_file_id : "",
      });
    }

    const famliy_information = [
      {
        family_role: 0,
        name: basicForm.father_name,
        age: basicForm.father_age,
        date_of_birth: moment(basicForm.father_birth_date).format("YYYY-MM-DD"),
        place_of_birth: basicForm.father_birth_place,
        home_address: basicForm.father_home_address,
        contact_number: basicForm.father_contact_no,
        education_attainment: basicForm.father_highest_education,
        occupation: basicForm.father_occupation,
        company_name: basicForm.father_company,
        monthly_income: basicForm.father_monthly_income,
      },
      {
        family_role: 1,
        name: basicForm.mother_name,
        age: basicForm.mother_age,
        date_of_birth: moment(basicForm.mother_birth_date).format("YYYY-MM-DD"),
        place_of_birth: basicForm.mother_birth_place,
        home_address: basicForm.mother_home_address,
        contact_number: basicForm.mother_contact_no,
        education_attainment: basicForm.mother_highest_education,
        occupation: basicForm.mother_occupation,
        company_name: basicForm.mother_company,
        monthly_income: basicForm.mother_monthly_income,
      },
      {
        family_role: 2,
        name: basicForm.guardian_toggle ? basicForm.mother_name : basicForm.guardian_name,
        age: basicForm.guardian_toggle? basicForm.mother_age : basicForm.guardian_age,
        date_of_birth: basicForm.guardian_toggle? moment(basicForm.mother_birth_date).format("YYYY-MM-DD") : moment(basicForm.guardian_birth_date).format(
          "YYYY-MM-DD"
        ),
        place_of_birth: basicForm.guardian_toggle? basicForm.mother_birth_place : basicForm.guardian_birth_place,
        home_address: basicForm.guardian_toggle? basicForm.mother_home_address : basicForm.guardian_home_address,
        contact_number: basicForm.guardian_toggle? basicForm.mother_contact_no : basicForm.guardian_contact_no,
        education_attainment: basicForm.guardian_toggle? basicForm.mother_highest_education : basicForm.guardian_highest_education,
        occupation: basicForm.guardian_toggle? basicForm.mother_occupation : basicForm.guardian_occupation,
        company_name: basicForm.guardian_toggle? basicForm.mother_company : basicForm.guardian_company,
        monthly_income: basicForm.guardian_toggle? basicForm.mother_monthly_income : basicForm.guardian_monthly_income,
      },
    ];

    let educ_background = [];
    if (basicForm.elementary_school_name != "") {
      educ_background.push({
        school_name: basicForm.elementary_school_name,
        school_address: basicForm.elementary_school_address,
        year_graduated: basicForm.elementary_year_graduated,
        education_grade_type: "",
        id: basicForm.elementary_school_id
          ? basicForm.elementary_school_id
          : "",
      });
    }
    if (basicForm.junior_high_school_name != "") {
      educ_background.push({
        school_name: basicForm.junior_high_school_name,
        school_address: basicForm.junior_high_school_address,
        year_graduated: basicForm.junior_high_year_graduated,
        education_grade_type: "",
        id: basicForm.junior_high_school_id
          ? basicForm.junior_high_school_id
          : "",
      });
    }

    if (basicForm.senior_high_school_name != "") {
      educ_background.push({
        school_name: basicForm.senior_high_school_name,
        school_address: basicForm.senior_high_school_address,
        year_graduated: basicForm.senior_high_year_graduated,
        education_grade_type: "",
        id: basicForm.senior_high_school_id
          ? basicForm.senior_high_school_id
          : "",
      });
    }

    let scholarshipIds = [];
    if (scholarshipForm.family_discount == "1") {
      scholarshipIds.push(scholarshipForm.family_discount);
    }
    if (scholarshipForm.academic_scholarship == "2") {
      scholarshipIds.push(scholarshipForm.academic_scholarship);
    }
    if (scholarshipForm.athletic_scholarship == "3") {
      scholarshipIds.push(scholarshipForm.athletic_scholarship);
    }
    if (scholarshipForm.sapc_employee == "5") {
      scholarshipIds.push(scholarshipForm.sapc_employee);
    }
    if (scholarshipForm.others == "6") {
      scholarshipIds.push(scholarshipForm.others);
    }
    if (scholarshipForm.loyalty_discount == "4") {
      scholarshipIds.push(scholarshipForm.loyalty_discount);
    }

    let health_additional_info = {
      living_status: basicForm.living_status,
      no_of_siblings: basicForm.no_of_siblings,
      health_student_boolean: basicForm.health_student_boolean,
      health_student_specific: basicForm.health_student_specific,
      consulting_doctor: basicForm.consulting_doctor,
      important_illness: basicForm.important_illness,
      hospitalized_in_past: basicForm.hospitalized_in_past,
      specific_hospitalized_in_past: basicForm.specific_hospitalized_in_past,
      difficulty: basicForm.difficulty,
      family_health_history: basicForm.family_health_history,
      family_member_with_sickness: basicForm.family_member_with_sickness,
      vaccination: basicForm.vaccination,
      self_evaluation: basicForm.self_evaluation,
      financial_support: basicForm.financial_support,
      other_financial_support: basicForm.other_financial_support,
      grew_up: basicForm.grew_up,
      language_home: basicForm.language_home,
      other_language_home: basicForm.other_language_home,
      concerns: basicForm.concerns,
      other_concern: basicForm.other_concern,
      problem_solution: basicForm.problem_solution,
      other_problem_solution: basicForm.other_problem_solution,
      cope_up_stress: basicForm.cope_up_stress,
      other_cope_up_stress: basicForm.other_cope_up_stress,
      how_to_know_sapc: basicForm.how_to_know_sapc,
      other_how_to_know_sapc: basicForm.other_how_to_know_sapc,
      listahan: basicForm.listahan,
      household_number: basicForm.household_number,
      disability: basicForm.disability,
      pwd_number: basicForm.pwd_number,
      subsidy: basicForm.subsidy,
      subsidy_details: basicForm.subsidy_details,
      admissionAnswers: admissionAnswers,
      paymentForm: paymentForm,
      guardian_toggle: basicForm.guardian_toggle
    };

    let scholarship_info = {
      name: scholarshipForm.scholarship_student_name,
      scholarhip_date: moment(scholarshipForm.scholarhip_date).format(
        "YYYY-MM-DD"
      ),
      academic_year_from: scholarshipForm.scholarship_academic_year_from,
      academic_year_to: scholarshipForm.scholarship_academic_year_to,
      scholarship_semester: scholarshipForm.scholarship_semester,
      scholarship_grade_level: scholarshipForm.scholarship_grade_level,
      scholarship_course: scholarshipForm.scholarship_course,
      scholarship_year: scholarshipForm.scholarship_year,
      scholarship_application_type:
        scholarshipForm.scholarship_application_type,
      scholarship_previous: scholarshipForm.scholarship_previous,
      scholarship_type_id: scholarshipIds,
      no_of_sibling: scholarshipForm.family_discount_siblings,
      academic_rank: scholarshipForm.academic_scholarship_rank,
      proof_certification: scholarshipForm.academic_scholarship_proof
        ? scholarshipForm.academic_scholarship_proof.name
        : "",
      level_of_sport: scholarshipForm.athletic_level,
      athletic_varsity_scholarship:
        scholarshipForm.athletic_varsity_scholarship,
      year_graduated: scholarshipForm.loyalty_discount_specific_year,
      department: scholarshipForm.sapc_employee_scholarship_specific,
      others: scholarshipForm.other_scholarship_specific,
    };

    const esc_info = {
      esc_type: basicForm.esc_type,
      esc_qvr: basicForm.esc_qvr,
    };

    let dataToSubmit = {
      given_name: basicForm.first_name,
      last_name: basicForm.last_name,
      middle_name: basicForm.middle_name,
      suffix: basicForm.suffix,
      year_level: admissionAnswers.grade_year_level.value,
      grade_type: admissionAnswers.grade_type,
      semester: admissionAnswers.semester
        ? admissionAnswers.semester.value
        : "",
      birth_place: basicForm.birth_place,
      birth_date: moment(basicForm.birth_date).format("YYYY-MM-DD"),
      gender: basicForm.gender,
      religion: basicForm.religion,
      lrn: basicForm.lrn,
      student_status: basicForm.student_status,
      age: basicForm.age,
      course_id: admissionAnswers.tertiary_course
        ? admissionAnswers.tertiary_course.value
        : 0,
      civil_status: basicForm.civil_status ? basicForm.civil_status : "",
      position: "",
      picture: basicForm.picture ? basicForm.picture.name : "",
      house_bldg_street: basicForm.street,
      province: basicForm.province,
      city_municipality: basicForm.city,
      barangay: basicForm.barangay,
      tertiary_course: admissionAnswers.tertiary_course
        ? admissionAnswers.tertiary_course.label
        : "",

      education_type_id: educationId,
      student_type_id: admissionAnswers.student_type.value,
      is_esc:
        basicForm.esc_type === 0 || basicForm.esc_type === 1 || basicForm.is_esc
          ? 1
          : 0,
      esc_type: basicForm.esc_type,
      esc_info: JSON.stringify(esc_info),
      school_fee_id: paymentForm.id,
      payment_mode: JSON.stringify(paymentForm.paymentMode),

      //family information
      living_status: basicForm.living_status.value,

      //scholarship student info

      scholarship_type_id: scholarshipIds,
      no_of_sibling: scholarshipForm.family_discount_siblings,
      academic_rank: scholarshipForm.academic_scholarship_rank,
      proof_certification: scholarshipForm.academic_scholarship_proof
        ? scholarshipForm.academic_scholarship_proof.name
        : "",
      level_of_sport: scholarshipForm.athletic_level,
      year_graduated: scholarshipForm.loyalty_discount_specific_year,
      department: scholarshipForm.sapc_employee_scholarship_specific,
      others: scholarshipForm.other_scholarship_specific,
      discount_type_id: "",
      scholarship_eligable: scholarshipForm.scholarship ? 1 : 0,
      family_info: famliy_information,
      education_background: educ_background,
      scholarship_info: scholarshipForm,
      health_additional_info: health_additional_info,
      subject_details: subjectForm,
      attachments: files,
      id: "",
      student_detail_id: "",
      application_id: "",
      old_attachments: "",
      student_id: "",
      sy_from: moment(basicForm.sy_from).format("YYYY"),
      sy_to: moment(basicForm.sy_to).format("YYYY"),
    };

    setButtonSpinner(true);
    if (basicForm.id == "") {
      dataToSubmit.student_id = basicForm.student_id;
      admissionActions.submitForm(
        dataToSubmit,
        setButtonSpinner,
        setSubmissionModal,
        setReferenceNo
      );
    } else {
      dataToSubmit.id = basicForm.id;
      dataToSubmit.student_detail_id = basicForm.student_detail_id;
      dataToSubmit.application_id = basicForm.application_id;
      dataToSubmit.old_attachments = basicForm.old_attachments;
      dataToSubmit.student_id = basicForm.student_id;
      admissionActions.updateForm(
        dataToSubmit,
        setButtonSpinner,
        setSubmissionModal
      );
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const gradeLevelsArr = [
    "Grade 7",
    "Grade 8",
    "Grade 9",
    "Grade 10",
    "Grade 11",
    "Grade 12",
  ];

  return (
    <>
      {loading ? <Loading /> : ""}
      <Container className="py-5">
        {submissionModal ? (
          <Modal
            isOpen={submissionModal}
            toggle={toggleMessage}
            backdrop="static"
          >
            <ModalHeader toggle={toggleMessage}></ModalHeader>
            <ModalBody>
              <h5 className="text-center">
                {basicForm.id == ""
                  ? "Application Successfully Created."
                  : "Application Successfully Updated."}
              </h5>
              <h5 className="text-center">
                {basicForm.id == ""
                  ? `Reference No: ${referenceNo.toUpperCase()}`
                  : ""}
              </h5>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={toggleMessage}>
                Close
              </Button>{" "}
            </ModalFooter>
          </Modal>
        ) : (
          ""
        )}
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Card>
            <CardHeader className="font-weight-bolder">
              Review and Submit
            </CardHeader>
            <CardBody>
              <Row className="mb-5">
                <Col md="12" xs="12">
                  <Label>
                    <span className="font-weight-bolder">Education Type: </span>{" "}
                    {admissionAnswers.education_type}
                  </Label>
                </Col>
                <Col md="12" xs="12">
                  <Label>
                    <span className="font-weight-bolder">Student Type: </span>{" "}
                    {admissionAnswers.student_type
                      ? admissionAnswers.student_type.label
                      : "NA"}
                  </Label>
                </Col>
                <Col md="12" xs="12">
                  <Label>
                    <span className="font-weight-bolder">Grade Type: </span>{" "}
                    {admissionAnswers.grade_type && typeof admissionAnswers.grade_type == "number"
                      ? 
                        getOptionLabelByValue(gradeTypeSelection, admissionAnswers.grade_type)
                      : 
                        admissionAnswers.grade_type && typeof admissionAnswers.grade_type == "object"
                      ?
                        admissionAnswers.grade_type.label
                      : "NA"}
                  </Label>
                </Col>
                <Col md="12" xs="12">
                  <Label>
                    <span className="font-weight-bolder">
                      Grade Year/Level:{" "}
                    </span>{" "}
                    {admissionAnswers.grade_year_level
                      ? admissionAnswers.grade_year_level.label
                      : "NA"}
                  </Label>
                </Col>
                <Col>
                  <Label>
                    <span className="font-weight-bolder">Course: </span>{" "}
                    {admissionAnswers.tertiary_course
                      ? admissionAnswers.tertiary_course.label
                      : "N/A"}
                  </Label>
                </Col>
              </Row>
              <Row className="mb-4 flex-column">
                <Col md="12" xs="12">
                  <h5 className="font-weight-bolder text-danger-edit">
                    PERSONAL INFORMATION
                  </h5>
                </Col>
                <FormGroup className="mb-3">
                  <Col md="12" xs="12">
                    <Label>
                      <span className="font-weight-bolder">Last Name: </span>{" "}
                      {basicForm.last_name}
                    </Label>
                  </Col>
                  <Col md="12" xs="12">
                    <Label>
                      <span className="font-weight-bolder">First Name: </span>{" "}
                      {basicForm.first_name}
                    </Label>
                  </Col>
                  <Col md="12" xs="12">
                    <Label>
                      <span className="font-weight-bolder">Middle Name: </span>{" "}
                      {basicForm.middle_name}
                    </Label>
                  </Col>
                  <Col md="12" xs="12">
                    <Label>
                      <span className="font-weight-bolder">Suffix: </span>{" "}
                      {basicForm.suffix}
                    </Label>
                  </Col>
                  <Col md="12" xs="12">
                    <Label>
                      <span className="font-weight-bolder">Birth Place: </span>{" "}
                      {basicForm.birth_place}
                    </Label>
                  </Col>
                  <Col md="12" xs="12">
                    <Label>
                      <span className="font-weight-bolder">Birth Date: </span>{" "}
                      {basicForm.birth_date != ""
                        ? moment(basicForm.birth_date).format("YYYY-MM-DD")
                        : ""}
                    </Label>
                  </Col>
                  <Col md="12" xs="12">
                    <Label>
                      <span className="font-weight-bolder">Gender: </span>{" "}
                      {basicForm.gender == "male" ? "Male" : "Female"}
                    </Label>
                  </Col>
                  <Col md="12" xs="12">
                    <Label>
                      <span className="font-weight-bolder">Age: </span>{" "}
                      {basicForm.age}
                    </Label>
                  </Col>
                  <Col md="12" xs="12">
                    <Label>
                      <span className="font-weight-bolder">Religion: </span>{" "}
                      {basicForm.religion}
                    </Label>
                  </Col>
                  <Col md="12" xs="12">
                    <Label>
                      <span className="font-weight-bolder">LRN: </span>{" "}
                      {basicForm.lrn}
                    </Label>
                  </Col>
                  <Col md="12" xs="12">
                    <Label>
                      <span className="font-weight-bolder">Student Status: </span>{" "}
                      {(admissionAnswers.grade_year_level && studentStatusLevels.includes(admissionAnswers.grade_year_level.label.toLowerCase())) && basicForm.student_status === 0 ? "Regular" : "Irregular"}
                    </Label>
                  </Col>
                </FormGroup>
                <FormGroup className="mb-3">
                  <Col md="12" xs="12">
                    <h5 className="font-weight-bolder text-danger-edit">
                      HOME ADDRESS
                    </h5>
                  </Col>
                  <Col md="12" xs="12">
                    <Label>
                      <span className="font-weight-bolder">
                        House no., Building, Street:{" "}
                      </span>{" "}
                      {basicForm.street}
                    </Label>
                  </Col>
                  <Col md="12" xs="12">
                    <Label>
                      <span className="font-weight-bolder">Province: </span>{" "}
                      {basicForm.province}
                    </Label>
                  </Col>
                  <Col md="12" xs="12">
                    <Label>
                      <span className="font-weight-bolder">City: </span>{" "}
                      {basicForm.city}
                    </Label>
                  </Col>
                  <Col md="12" xs="12">
                    <Label>
                      <span className="font-weight-bolder">Barangay: </span>{" "}
                      {basicForm.barangay}
                    </Label>
                  </Col>
                </FormGroup>
                {admissionAnswers.grade_type &&
                (admissionAnswers.grade_type.value == "5" ||
                  admissionAnswers.grade_type.value == "4") ? (
                  <FormGroup className="mb-1">
                    <Col md="12" xs="12">
                      <h5 className="font-weight-bolder text-danger-edit">
                        PRIOR EDUCATION
                      </h5>
                    </Col>
                  </FormGroup>
                ) : (
                  ""
                )}
                {admissionAnswers.grade_type &&
                (admissionAnswers.grade_type.label == "SENIOR HIGH SCHOOL" ||
                  admissionAnswers.grade_type.label == "JUNIOR HIGH SCHOOL") ? (
                  <FormGroup className="mb-2">
                    <Col md="12" xs="12">
                      <Label className="font-weight-bolder">ELEMENTARY</Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">
                          Name of School:{" "}
                        </span>{" "}
                        {basicForm.elementary_school_name}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">
                          School Address:{" "}
                        </span>{" "}
                        {basicForm.elementary_year_graduated}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">
                          Year Graduated:{" "}
                        </span>{" "}
                        {basicForm.elementary_year_graduated}
                      </Label>
                    </Col>
                  </FormGroup>
                ) : (
                  ""
                )}
                {admissionAnswers.grade_type &&
                admissionAnswers.grade_type.label == "SENIOR HIGH SCHOOL" ? (
                  <FormGroup className="mb-3">
                    <Col md="12" xs="12">
                      <Label className="font-weight-bolder">
                        JUNIOR HIGH SCHOOL
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">
                          Name of School:{" "}
                        </span>{" "}
                        {basicForm.junior_high_school_name}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">
                          School Address:{" "}
                        </span>{" "}
                        {basicForm.junior_high_school_address}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">
                          Year Graduated:{" "}
                        </span>{" "}
                        {basicForm.junior_high_year_graduated}
                      </Label>
                    </Col>
                  </FormGroup>
                ) : (
                  ""
                )}
              </Row>

              <Row className="mb-5">
                <Col md="12" xs="12">
                  <h5 className="font-weight-bolder text-danger-edit">
                    FAMILY INFORMATION
                  </h5>
                </Col>
                {admissionAnswers.education_type == "Basic Education" ? (
                  <>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">
                          Living Status:{" "}
                        </span>{" "}
                        {basicForm.living_status
                          ? basicForm.living_status.label
                          : ""}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">
                          No of Siblings:{" "}
                        </span>{" "}
                        {basicForm.no_of_siblings}
                      </Label>
                    </Col>
                  </>
                ) : (
                  ""
                )}
                <Col md="12" xs="12" className="pt-3">
                  <Label>
                    <span className="font-weight-bolder">Father's Name: </span>{" "}
                    {basicForm.father_name}
                  </Label>
                </Col>
                {admissionAnswers.education_type == "Basic Education" ? (
                  <>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">Age: </span>{" "}
                        {basicForm.father_age}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">Birth Date: </span>{" "}
                        {moment(basicForm.father_birth_date).format(
                          "YYYY-MM-DD"
                        )}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">
                          Birth Place:{" "}
                        </span>{" "}
                        {basicForm.father_birth_place}
                      </Label>
                    </Col>
                  </>
                ) : (
                  ""
                )}
                <Col md="12" xs="12">
                  <Label>
                    <span className="font-weight-bolder">Home Address: </span>{" "}
                    {basicForm.father_home_address}
                  </Label>
                </Col>
                <Col md="12" xs="12">
                  <Label>
                    <span className="font-weight-bolder">Contact No: </span>{" "}
                    {basicForm.father_contact_no}
                  </Label>
                </Col>
                {admissionAnswers.education_type == "Basic Education" ? (
                  <>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">
                          Highest Educational Attainment:{" "}
                        </span>{" "}
                        {basicForm.father_highest_education
                          ? basicForm.father_highest_education.label
                          : ""}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">Occupation: </span>{" "}
                        {basicForm.father_occupation
                          ? basicForm.father_occupation.label
                          : ""}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">Company: </span>{" "}
                        {basicForm.father_company}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">
                          Monthly Income:{" "}
                        </span>{" "}
                        {basicForm.father_monthly_income
                          ? basicForm.father_monthly_income.label
                          : ""}
                      </Label>
                    </Col>
                  </>
                ) : (
                  ""
                )}
                <Col md="12" xs="12" className="pt-3">
                  <Label>
                    <span className="font-weight-bolder">Mother's Name: </span>{" "}
                    {basicForm.mother_name}
                  </Label>
                </Col>
                {admissionAnswers.education_type == "Basic Education" ? (
                  <>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">Age: </span>{" "}
                        {basicForm.mother_age}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">Birth Date: </span>{" "}
                        {moment(basicForm.mother_birth_date).format(
                          "YYYY-MM-DD"
                        )}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">
                          Birth Place:{" "}
                        </span>{" "}
                        {basicForm.mother_birth_place}
                      </Label>
                    </Col>
                  </>
                ) : (
                  ""
                )}
                <Col md="12" xs="12">
                  <Label>
                    <span className="font-weight-bolder">Home Address: </span>{" "}
                    {basicForm.mother_home_address}
                  </Label>
                </Col>
                <Col md="12" xs="12">
                  <Label>
                    <span className="font-weight-bolder">Contact No: </span>{" "}
                    {basicForm.mother_contact_no}
                  </Label>
                </Col>
                {admissionAnswers.education_type == "Basic Education" ? (
                  <>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">
                          Highest Educational Attainment:{" "}
                        </span>{" "}
                        {basicForm.mother_highest_education
                          ? basicForm.mother_highest_education.label
                          : ""}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">Occupation: </span>{" "}
                        {basicForm.mother_occupation
                          ? basicForm.mother_occupation.label
                          : ""}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">Company: </span>{" "}
                        {basicForm.mother_company}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">
                          Monthly Income:{" "}
                        </span>{" "}
                        {basicForm.mother_monthly_income
                          ? basicForm.mother_monthly_income.label
                          : ""}
                      </Label>
                    </Col>
                  </>
                ) : (
                  ""
                )}
                {!basicForm.guardian_toggle ? 
                <>
                  <Col md="12" xs="12" className="pt-3">
                  <Label>
                    <span className="font-weight-bolder">
                      Guardian's Name:{" "}
                    </span>{" "}
                    {basicForm.guardian_name}
                  </Label>
                </Col>
                {admissionAnswers.education_type == "Basic Education" ? (
                  <>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">Age: </span>{" "}
                        {basicForm.guardian_age}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">Birth Date: </span>{" "}
                        {moment(basicForm.guardian_birth_date).format(
                          "YYYY-MM-DD"
                        )}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">
                          Birth Place:{" "}
                        </span>{" "}
                        {basicForm.guardian_birth_place}
                      </Label>
                    </Col>
                  </>
                ) : (
                  ""
                )}
                <Col md="12" xs="12">
                  <Label>
                    <span className="font-weight-bolder">Home Address: </span>{" "}
                    {basicForm.guardian_home_address}
                  </Label>
                </Col>
                <Col md="12" xs="12">
                  <Label>
                    <span className="font-weight-bolder">Contact No: </span>{" "}
                    {basicForm.guardian_contact_no}
                  </Label>
                </Col>
                {admissionAnswers.education_type == "Basic Education" ? (
                  <>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">
                          Highest Educational Attainment:{" "}
                        </span>{" "}
                        {basicForm.guardian_highest_education
                          ? basicForm.guardian_highest_education.label
                          : ""}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">Occupation: </span>{" "}
                        {basicForm.guardian_occupation
                          ? basicForm.guardian_occupation.label
                          : ""}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">Company: </span>{" "}
                        {basicForm.guardian_company}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">
                          Monthly Income:{" "}
                        </span>{" "}
                        {basicForm.guardian_monthly_income
                          ? basicForm.guardian_monthly_income.label
                          : ""}
                      </Label>
                    </Col>
                  </>
                ) : (
                  ""
                )}
                </>
                : ""}
              </Row>

              {admissionAnswers.education_type == "Basic Education" ? (
                <>
                  <Row className="mb-5">
                    <Col md="12" xs="12">
                      <h5 className="font-weight-bolder text-danger-edit">
                        HEALTH INFORMATION
                      </h5>
                    </Col>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">
                          Do you have any health problems now?{" "}
                        </span>{" "}
                        {basicForm.health_student_boolean
                          ? basicForm.health_student_boolean.label
                          : ""}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">
                          Specific health problem:{" "}
                        </span>{" "}
                        {basicForm.health_student_boolean
                          ? basicForm.health_student_specific
                          : "NA"}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">
                          Are you consulting a doctor?{" "}
                        </span>{" "}
                        {basicForm.consulting_doctor
                          ? basicForm.consulting_doctor.label
                          : ""}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">
                          What important illness did you have in the past?{" "}
                        </span>{" "}
                        {basicForm.important_illness}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">
                          Have you been hospitalized in the past?{" "}
                        </span>{" "}
                        {basicForm.hospitalized_in_past
                          ? basicForm.hospitalized_in_past.label
                          : ""}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">
                          if yes, indicate the reason and when?{" "}
                        </span>{" "}
                        {basicForm.hospitalized_in_past
                          ? basicForm.specific_hospitalized_in_past
                          : "NA"}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">
                          Do you have any difficulty in, or problems of the
                          following?{" "}
                        </span>{" "}
                        {basicForm.difficulty ? basicForm.difficulty.value : ""}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">
                          In your family (blood relations those living in the
                          household) has anyone been sick on the following?{" "}
                        </span>{" "}
                        {basicForm.family_health_history
                          ? basicForm.family_health_history.label
                          : ""}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">
                          If yes, who among them and what sickness?{" "}
                        </span>{" "}
                        {basicForm.family_health_history
                          ? basicForm.family_member_with_sickness
                          : "NA"}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">
                          What vaccination did you have?{" "}
                        </span>{" "}
                        {basicForm.vaccination}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">
                          If yes, who among them and what sickness?{" "}
                        </span>{" "}
                        {basicForm.self_evaluation
                          ? basicForm.self_evaluation.label
                          : ""}
                      </Label>
                    </Col>
                  </Row>

                  <Row className="mb-5">
                    <Col md="12" xs="12">
                      <h5 className="font-weight-bolder text-danger-edit">
                        ADDITIONAL INFORMATION
                      </h5>
                    </Col>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">
                          Who is financially supporting your studies?{" "}
                        </span>{" "}
                        {basicForm.financial_support
                          ? basicForm.financial_support.label
                          : ""}
                      </Label>
                    </Col>
                    {basicForm.financial_support &&
                    basicForm.financial_support.value == "6" ? (
                      <Col md="12" xs="12">
                        <Label>
                          <span className="font-weight-bolder">Other: </span>{" "}
                          {basicForm.other_financial_support}
                        </Label>
                      </Col>
                    ) : (
                      ""
                    )}
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">
                          Area where you grew up:{" "}
                        </span>{" "}
                        {basicForm.grew_up ? basicForm.grew_up.label : ""}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">
                          First language / dialect spoken at home:{" "}
                        </span>{" "}
                        {basicForm.language_home
                          ? basicForm.language_home.label
                          : ""}
                      </Label>
                    </Col>
                    {basicForm.language_home &&
                    basicForm.language_home.value == "3" ? (
                      <Col md="12" xs="12">
                        <Label>
                          <span className="font-weight-bolder">Other: </span>{" "}
                          {basicForm.other_language_home}
                        </Label>
                      </Col>
                    ) : (
                      ""
                    )}
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">
                          Which of the following concerns you most?{" "}
                        </span>{" "}
                        {basicForm.concerns ? basicForm.concerns.label : ""}
                      </Label>
                    </Col>
                    {basicForm.concerns && basicForm.concerns.value == "9" ? (
                      <Col md="12" xs="12">
                        <Label>
                          <span className="font-weight-bolder">Other: </span>{" "}
                          {basicForm.other_concern}
                        </Label>
                      </Col>
                    ) : (
                      ""
                    )}
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">
                          How do you usually deal with problem?{" "}
                        </span>{" "}
                        {basicForm.problem_solution
                          ? basicForm.problem_solution.label
                          : ""}
                      </Label>
                    </Col>
                    {basicForm.concerns && basicForm.concerns.value == "5" ? (
                      <Col md="12" xs="12">
                        <Label>
                          <span className="font-weight-bolder">Other: </span>{" "}
                          {basicForm.other_problem_solution}
                        </Label>
                      </Col>
                    ) : (
                      ""
                    )}
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">
                          How do you cope up with stressful situation?{" "}
                        </span>{" "}
                        {basicForm.cope_up_stress
                          ? basicForm.cope_up_stress.label
                          : ""}
                      </Label>
                    </Col>
                    {basicForm.cope_up_stress &&
                    basicForm.cope_up_stress.value == "7" ? (
                      <Col md="12" xs="12">
                        <Label>
                          <span className="font-weight-bolder">Other: </span>{" "}
                          {basicForm.other_cope_up_stress}
                        </Label>
                      </Col>
                    ) : (
                      ""
                    )}
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">
                          How do you cope up with stressful situation?{" "}
                        </span>{" "}
                        {basicForm.how_to_know_sapc
                          ? basicForm.how_to_know_sapc.label
                          : ""}
                      </Label>
                    </Col>
                    {basicForm.how_to_know_sapc &&
                    basicForm.how_to_know_sapc.value == "7" ? (
                      <Col md="12" xs="12">
                        <Label>
                          <span className="font-weight-bolder">Other: </span>{" "}
                          {basicForm.other_how_to_know_sapc}
                        </Label>
                      </Col>
                    ) : (
                      ""
                    )}
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">
                          How did you get to know about San Antonio de Padua
                          College?{" "}
                        </span>{" "}
                        {basicForm.how_to_know_sapc
                          ? basicForm.how_to_know_sapc.label
                          : ""}
                      </Label>
                    </Col>
                    {basicForm.how_to_know_sapc &&
                    basicForm.how_to_know_sapc.value == "7" ? (
                      <Col md="12" xs="12">
                        <Label>
                          <span className="font-weight-bolder">Other: </span>{" "}
                          {basicForm.other_how_to_know_sapc}
                        </Label>
                      </Col>
                    ) : (
                      ""
                    )}
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">
                          Are you a part of Listahan (4P`s) ?{" "}
                        </span>{" "}
                        {basicForm.listahan ? basicForm.listahan.label : ""}
                      </Label>
                    </Col>
                    {basicForm.listahan && basicForm.listahan.value ? (
                      <Col md="12" xs="12">
                        <Label>
                          <span className="font-weight-bolder">
                            DSWD Household Number:{" "}
                          </span>{" "}
                          {basicForm.household_number}
                        </Label>
                      </Col>
                    ) : (
                      ""
                    )}
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">
                          Are you a person with Disability?{" "}
                        </span>{" "}
                        {basicForm.disability ? basicForm.disability.label : ""}
                      </Label>
                    </Col>
                    {basicForm.disability && basicForm.disability.value ? (
                      <Col md="12" xs="12">
                        <Label>
                          <span className="font-weight-bolder">
                            PWD number:{" "}
                          </span>{" "}
                          {basicForm.pwd_number}
                        </Label>
                      </Col>
                    ) : (
                      ""
                    )}
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">
                          Are you a currently recipient of a government subsidy
                          from DEPED/CHED?{" "}
                        </span>{" "}
                        {basicForm.subsidy ? basicForm.subsidy.label : ""}
                      </Label>
                    </Col>
                    {basicForm.subsidy && basicForm.subsidy.value ? (
                      <Col md="12" xs="12">
                        <Label>
                          <span className="font-weight-bolder">
                            If yes, please write the details:{" "}
                          </span>{" "}
                          {basicForm.subsidy_details}
                        </Label>
                      </Col>
                    ) : (
                      ""
                    )}
                  </Row>
                </>
              ) : (
                ""
              )}

              <Row className="mb-5">
                <Col md="12" xs="12">
                  <h5 className="font-weight-bolder text-danger-edit">
                    SCHOLARSHIP
                  </h5>
                </Col>
                {scholarshipForm.scholarship ? (
                  <FormGroup className="mb-3">
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">Name: </span>{" "}
                        {scholarshipForm.scholarship_student_name}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">Date: </span>{" "}
                        {scholarshipForm.scholarhip_date != ""
                          ? moment(basicForm.scholarhip_date).format(
                              "YYYY-MM-DD"
                            )
                          : ""}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">
                          Academic Year From:{" "}
                        </span>{" "}
                        {scholarshipForm.scholarship_academic_year_from}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">
                          Academic Year To:{" "}
                        </span>{" "}
                        {scholarshipForm.scholarship_academic_year_to}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">Semester: </span>{" "}
                        {scholarshipForm.scholarship_semester}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">
                          Grade Level:{" "}
                        </span>{" "}
                        {scholarshipForm.scholarship_grade_level}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">Course: </span>{" "}
                        {scholarshipForm.scholarship_course}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">Year: </span>{" "}
                        {scholarshipForm.scholarship_year}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">
                          Application Type:{" "}
                        </span>{" "}
                        {scholarshipForm.scholarship_application_type}
                      </Label>
                    </Col>
                    <Col md="12" xs="12" className="mb-4">
                      <Label>
                        <span className="font-weight-bolder">
                          Previously Availed Scholarship:{" "}
                        </span>
                        <br /> {scholarshipForm.scholarship_previous}
                      </Label>
                    </Col>

                    <Col md="12" xs="12" className="mb-2">
                      <Label>
                        <span className="font-weight-bolder text-danger-edit">
                          SCHOLARSHIP and DISCOUNT APPLIED FOR
                        </span>
                      </Label>
                    </Col>
                    {scholarshipForm.family_discount == "1" ? (
                      <FormGroup className="mb-3">
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              Family Discount{" "}
                            </span>
                          </Label>
                        </Col>
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              Number of Siblings in SAPC:{" "}
                            </span>{" "}
                            {scholarshipForm.family_discount_siblings}
                          </Label>
                        </Col>
                      </FormGroup>
                    ) : (
                      ""
                    )}
                    {scholarshipForm.academic_scholarship == "2" ? (
                      <FormGroup className="mb-3">
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder mb-0">
                              Academic Scholarship{" "}
                            </span>
                          </Label>
                        </Col>
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              Academic Scholarship Rank:{" "}
                            </span>{" "}
                            {scholarshipForm.academic_scholarship_rank}
                          </Label>
                        </Col>
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              Academic Scholarship Proof:{" "}
                            </span>{" "}
                            {scholarshipForm.academic_scholarship_proof &&
                            scholarshipForm.academic_scholarship_proof.name
                              ? scholarshipForm.academic_scholarship_proof.name
                              : scholarshipForm.academic_scholarship_proof_file_name
                              ? scholarshipForm.academic_scholarship_proof_file_name
                              : ""}
                          </Label>
                        </Col>
                      </FormGroup>
                    ) : (
                      ""
                    )}
                    {scholarshipForm.athletic_scholarship == "3" ? (
                      <FormGroup className="mb-3">
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder mb-0">
                              Athletic Scholarship{" "}
                            </span>
                          </Label>
                        </Col>
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder mb-0">
                              Level of Sports Participation:{" "}
                            </span>{" "}
                            {scholarshipForm.athletic_level == 1
                              ? "Provincial"
                              : scholarshipForm.athletic_level == 2
                              ? "Regional"
                              : "National"}
                          </Label>
                        </Col>
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              Member of SAPC Varsity Team:{" "}
                            </span>{" "}
                            {scholarshipForm.scholarship_specific_sports}
                          </Label>
                        </Col>
                      </FormGroup>
                    ) : (
                      ""
                    )}
                    {scholarshipForm.loyalty_discount == "4" ? (
                      <FormGroup className="mb-3">
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder mb-0">
                              Loyalty Discount{" "}
                            </span>
                          </Label>
                        </Col>
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              Specific Year of Graduation at SAPC:{" "}
                            </span>{" "}
                            {scholarshipForm.loyalty_discount_specific_year}
                          </Label>
                        </Col>
                      </FormGroup>
                    ) : (
                      ""
                    )}
                    {scholarshipForm.sapc_employee == "5" ? (
                      <FormGroup className="mb-3">
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder mb-0">
                              SAPC Employee
                            </span>
                          </Label>
                        </Col>
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              Specific Year of Service:{" "}
                            </span>{" "}
                            {scholarshipForm.sapc_employee_scholarship_specific}
                          </Label>
                        </Col>
                      </FormGroup>
                    ) : (
                      ""
                    )}
                    {scholarshipForm.others == "6" ? (
                      <FormGroup className="mb-3">
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder mb-0">
                              Other Scholarship
                            </span>
                          </Label>
                        </Col>
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              Specific Scholarship:{" "}
                            </span>{" "}
                            {scholarshipForm.other_scholarship_specific}
                          </Label>
                        </Col>
                      </FormGroup>
                    ) : (
                      ""
                    )}
                    {scholarshipForm.esc_switch != "" ? (
                      <FormGroup>
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">ESC: </span>{" "}
                            {scholarshipForm.esc_switch ? "True" : "False"}
                          </Label>
                        </Col>
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              ESC File:{" "}
                            </span>
                            <br />{" "}
                            {scholarshipForm.esc_file.name
                              ? scholarshipForm.esc_file.name
                              : scholarshipForm.esc_file_name}
                          </Label>
                        </Col>
                      </FormGroup>
                    ) : (
                      ""
                    )}

                    {scholarshipForm.family_discount != "1" &&
                    scholarshipForm.academic_scholarship != "2" &&
                    scholarshipForm.athletic_scholarship != "3" &&
                    scholarshipForm.loyalty_discount != "4" &&
                    scholarshipForm.sapc_employee != "5" &&
                    scholarshipForm.others != "6" &&
                    scholarshipForm.esc_switch == "" ? (
                      <FormGroup>
                        <Col md="12" xs="12">
                          <Label className="text-secondary-edit">
                            No Scholarship/Discount chosen
                          </Label>
                        </Col>
                      </FormGroup>
                    ) : (
                      ""
                    )}
                  </FormGroup>
                ) : (
                  <Col md="12" xs="12">
                    <Label className="text-secondary-edit">
                      No Scholarship Applied
                    </Label>
                  </Col>
                )}
              </Row>

              {admissionAnswers.education_type == "Basic Education" &&
              gradeLevelsArr.includes(
                admissionAnswers.grade_year_level.label
              ) ? (
                <Row className="mb-5">
                  <Col md="12" xs="12">
                    <h5 className="font-weight-bolder text-danger-edit mb-3">
                      ESC Details
                    </h5>
                  </Col>
                  {basicForm.esc_type === 0 || basicForm.esc_type === 1 ? (
                    <>
                      <Col md="12" xs="12">
                        <Label>
                          <span className="font-weight-bolder">Type: </span>{" "}
                          {basicForm.esc_type == 0
                            ? "Public"
                            : basicForm.esc_type == 1
                            ? "Private"
                            : "No ESC/QVR"}
                        </Label>
                      </Col>
                      {basicForm.esc_type == 1 ? (
                        <>
                          <Col md="12" xs="12">
                            <Label>
                              <span className="font-weight-bolder">
                                Certificate:{" "}
                              </span>
                              {basicForm.esc_qvr == 0 ? "ESC" : "QVR"}
                            </Label>
                          </Col>
                          <Col md="12" xs="12">
                            {basicForm.esc_qvr == 0 ? (
                              <Label>
                                <span className="font-weight-bolder">
                                  ESC File:{" "}
                                </span>
                                <br />{" "}
                                {basicForm.esc_file && basicForm.esc_file.name
                                  ? basicForm.esc_file.name
                                  : basicForm.esc_file_name}
                              </Label>
                            ) : (
                              <Label>
                                <span className="font-weight-bolder">
                                  QVR File:{" "}
                                </span>
                                <br />{" "}
                                {basicForm.qvr_file && basicForm.qvr_file.name
                                  ? basicForm.qvr_file.name
                                  : basicForm.qvr_file_name}
                              </Label>
                            )}
                          </Col>
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    <>
                      <Col md="12" xs="12">
                        <Label>
                          <span className="font-weight-bolder">
                            ESC applied:{" "}
                          </span>{" "}
                          {basicForm.is_esc == 0
                            ? "No"
                            : basicForm.is_esc == 1
                            ? "Yes"
                            : ""}
                        </Label>
                      </Col>
                      <Col md="12" xs="12">
                        {basicForm.is_esc == 1 ? (
                          <Label>
                            <span className="font-weight-bolder">
                              ESC File:{" "}
                            </span>
                            {basicForm.esc_file && basicForm.esc_file.name
                              ? basicForm.esc_file.name
                              : basicForm.esc_file_name}
                          </Label>
                        ) : (
                          ""
                        )}
                      </Col>
                    </>
                  )}
                </Row>
              ) : (
                ""
              )}

              {admissionAnswers.education_type == "Tertiary Education" ? (
                <Row className="mb-5">
                  <Col md="12" xs="12">
                    <h5 className="font-weight-bolder text-danger-edit mb-3">
                      SUBJECTS
                    </h5>
                  </Col>
                  <>
                    {subjectForm.enrollee_type &&
                    subjectForm.enrollee_type.label == "Regular Student" &&
                    subjectForm.subjects &&
                    subjectForm.subjects.length
                      ? subjectForm.subjects.map((subject, key) => {
                          return (
                            <Col md="12" xs="12" key={key}>
                              <Label>{subject.description}</Label>
                            </Col>
                          );
                        })
                      : subjectForm.enrollee_type &&
                        subjectForm.enrollee_type.label ==
                          "Manually select subjects" &&
                        selectedSubjects &&
                        selectedSubjects.length
                      ? selectedSubjects &&
                        selectedSubjects.map((selectedSubject, key) => {
                          return (
                            <Col md="12" xs="12" key={key}>
                              <Label>{selectedSubject.description}</Label>
                            </Col>
                          );
                        })
                      : "No Selected Subjects"}
                  </>
                </Row>
              ) : (
                ""
              )}
              {admissionAnswers.education_type == "Basic Education" ||
              admissionAnswers.education_type == "Tertiary Education" ? (
                <Row className="mb-5">
                  <Col md="12" xs="12">
                    <h5 className="font-weight-bolder text-danger-edit mb-3">
                      ASSESSMENT
                    </h5>
                  </Col>
                  {paymentForm.paymentMode ? (
                    <>
                      <Col md="12" xs="12">
                        <Label>
                          <span className="font-weight-bolder">
                            Payment Mode:{" "}
                          </span>{" "}
                          {paymentForm.paymentMode
                            ? paymentForm.paymentMode.label
                            : ""}
                        </Label>
                      </Col>
                      <Col md="12" xs="12">
                        <Label>
                          <span className="font-weight-bolder">
                            Tuition Fee:{" "}
                          </span>{" "}
                          {paymentForm.paymentModeDetails
                            ? paymentForm.paymentModeDetails.tuition_fee
                            : ""}
                        </Label>
                      </Col>
                      <Col md="12" xs="12">
                        <Label>
                          <span className="font-weight-bolder">
                            Miscellaneous Fee:{" "}
                          </span>{" "}
                          {paymentForm.paymentModeDetails.miscellaneous_fee}
                        </Label>
                      </Col>
                      <Col md="12" xs="12">
                        <Label>
                          <span className="font-weight-bolder">
                            Other Fee:{" "}
                          </span>{" "}
                          {paymentForm.paymentModeDetails.other_fee}
                        </Label>
                      </Col>
                      <Col md="12" xs="12">
                        <Label>
                          <span className="font-weight-bolder">Discount: </span>{" "}
                          {paymentForm.paymentModeDetails &&
                          paymentForm.paymentMode &&
                          paymentForm.paymentMode.value == 1
                            ? paymentForm.discounted_tuituion_fee.toFixed(2)
                            : "N/A"}
                        </Label>
                      </Col>
                      {paymentForm.paymentModeDetails &&
                      paymentForm.paymentModeDetails
                        .payment_upon_enrollment_lab ? (
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              Payment Upon Enrollment:{" "}
                            </span>{" "}
                            {paymentForm.paymentModeDetails &&
                            paymentForm.paymentModeDetails
                              .payment_upon_enrollment_lab
                              ? paymentForm.paymentModeDetails
                                  .payment_upon_enrollment_lab
                              : 0}
                          </Label>
                        </Col>
                      ) : (
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              Payment Upon Enrollment:{" "}
                            </span>{" "}
                            {paymentForm.paymentModeDetails &&
                            paymentForm.paymentModeDetails
                              .payment_upon_enrollment
                              ? paymentForm.paymentModeDetails
                                  .payment_upon_enrollment
                              : 0}
                          </Label>
                        </Col>
                      )}
                      <Col md="12" xs="12">
                        <Label>
                          <span className="font-weight-bolder">
                            Payment per Billing:{" "}
                          </span>{" "}
                          {paymentForm.paymentModeDetails &&
                          paymentForm.paymentModeDetails.per_billing
                            ? paymentForm.paymentModeDetails.per_billing
                            : "N/A"}
                        </Label>
                      </Col>
                    </>
                  ) : (
                    <Col md="12" xs="12">
                      <Label>
                        <span className="text-secondary-edit">
                          No Assessment
                        </span>
                      </Label>
                    </Col>
                  )}
                </Row>
              ) : (
                ""
              )}
              <Row>
                <Col md="12" xs="12">
                  <h5 className="font-weight-bolder text-danger-edit mb-3">
                    ADMISSION REQUIREMENTS
                  </h5>
                </Col>
                <Col md="12" xs="12">
                  <Label>
                    <span className="font-weight-bolder">Form 138: </span>
                    <br />{" "}
                    {basicForm.form138.name
                      ? basicForm.form138.name
                      : basicForm.form138_file_name}
                  </Label>
                </Col>
                <Col md="12" xs="12">
                  <Label>
                    <span className="font-weight-bolder">Form 137: </span>
                    <br />{" "}
                    {basicForm.form137.name
                      ? basicForm.form137.name
                      : basicForm.form137_file_name}
                  </Label>
                </Col>
                <Col md="12" xs="12">
                  <Label>
                    <span className="font-weight-bolder">
                      Good Moral Character:{" "}
                    </span>
                    <br />{" "}
                    {basicForm.good_moral.name
                      ? basicForm.good_moral.name
                      : basicForm.good_moral_file_name}
                  </Label>
                </Col>
                <Col md="12" xs="12">
                  <Label>
                    <span className="font-weight-bolder">2"x2" Picture: </span>
                    <br />{" "}
                    {basicForm.picture.name
                      ? basicForm.picture.name
                      : basicForm.picture_file_name}
                  </Label>
                </Col>
                <Col md="12" xs="12">
                  <Label>
                    <span className="font-weight-bolder">NCAE: </span>
                    <br />{" "}
                    {basicForm.ncae.name
                      ? basicForm.ncae.name
                      : basicForm.ncae_file_name}
                  </Label>
                </Col>
                <Col md="12" xs="12">
                  <Label>
                    <span className="font-weight-bolder">
                      Birth Certificate:{" "}
                    </span>
                    <br />{" "}
                    {basicForm.birth_certificate.name
                      ? basicForm.birth_certificate.name
                      : basicForm.birth_certificate_file_name}
                  </Label>
                </Col>
                {admissionAnswers.education_type == "Tertiary Education" ? (
                  <>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">
                          Certificate of Residency:{" "}
                        </span>
                        <br />{" "}
                        {basicForm.cert_of_residency.name
                          ? basicForm.cert_of_residency.name
                          : basicForm.cert_of_residency_file_name}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">
                          Copy of Grades:{" "}
                        </span>
                        <br />{" "}
                        {basicForm.copy_of_grades.name
                          ? basicForm.copy_of_grades.name
                          : basicForm.copy_of_grades_file_name}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">
                          SHS Diploma:{" "}
                        </span>
                        <br />{" "}
                        {basicForm.shs_diploma.name
                          ? basicForm.shs_diploma.name
                          : basicForm.shs_diploma_file_name}
                      </Label>
                    </Col>
                  </>
                ) : (
                  ""
                )}
              </Row>
            </CardBody>
          </Card>

          {/* <Row className="mt-5 justify-content-center text-center">
                        <Row className="mt-5 justify-content-center text-center">
                            <Col md="6" xs="12" className="text-center w-100">
                                <Button type="button" onClick={handlePreviousBtn} className="mb-2 bg-white border-warning text-warning-edit rounded-pill py-3 px-5 font-weight-bolder">Previous</Button>
                                <Button onClick={handleCancel} className="bg-white border-0 text-dark rounded-pill py-3 px-5 mb-2 font-weight-bolder">Cancel</Button>
                            </Col>

                            <Col md="6" xs="12" className="text-center w-100">
                                <Button className="bg-warning text-white border-0 rounded-pill py-3 px-5 font-weight-bolder" disabled={buttonSpinner ? true : false}>
                                    {buttonSpinner ? (
                                        <>
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                            &nbsp;Processing...
                                    </>
                                    ) : (
                                        "Submit"
                                    )}
                                </Button>
                            </Col>
                        </Row>
                    </Row> */}
          <Row className="mt-5 justify-content-center text-center">
            <Col md="6" xs="12" className="text-center w-100">
              <Button
                type="button"
                onClick={handlePreviousBtn}
                className="mb-2 bg-white border-warning text-warning-edit rounded-pill py-3 px-5 font-weight-bolder"
              >
                Previous
              </Button>
              <Button
                onClick={handleCancel}
                className="bg-white border-0 text-dark rounded-pill py-3 px-5 mb-2 font-weight-bolder"
              >
                Cancel
              </Button>
            </Col>

            <Col md="6" xs="12" className="text-center w-100">
              <Button
                className="bg-warning text-white border-0 rounded-pill py-3 px-5 font-weight-bolder"
                disabled={buttonSpinner ? true : false}
              >
                {buttonSpinner ? (
                  <>
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    &nbsp;Processing...
                  </>
                ) : (
                  "Submit"
                )}
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default ReviewSubmit;
