import { ENDPOINT, S3_URL } from "../constants/api";
import axios from "axios";
import { notifyError, notifySuccess } from "./Toaster";
import moment from "moment";

export const dynamicAcademicsActions = {
  saveTemplate,
  getCurrentTemplate,
  getSelectedTemplate,
  publishTemplate,
  getCurrentTemplateFrontPage
  // updateUser
};

const token = localStorage.getItem("jwtToken")
  ? localStorage.getItem("jwtToken")
  : "";
const headers = {
  Authorization: `Bearer ${token}`,
  "Content-Type": "application/json",
  "X-Requested-With": "XMLHttpRequest",
};

async function saveTemplate(data, setLoading, setButtonSpinner, setButtonSpinner2) {
  try {
    let res = await axios.post(ENDPOINT + `/dynamic-academics`, data, {
      headers: headers,
    });

    notifySuccess("Successfully created new template");
    setLoading(false)
    setButtonSpinner(false)
    setButtonSpinner2(false)
    setTimeout(() => window.location.reload(), 2000);
  } catch (err) {
    if (err.response && err.response.data && err.response.data.error) {
      notifyError(err.response.data.error);
    } else if (err.response) {
      notifyError("Error while saving template");
    }
    setLoading(false)
    setButtonSpinner(false)
    setButtonSpinner2(false)
  }
}

async function getCurrentTemplate(
  setBasicEducation,
  basicEducation,
  setTertiaryEducation,
  tertiaryEducation,
  setTechnicalEducation,
  technicalEducation,
  setCurrentVersion,
  setSelectedVersion,
  setLoading,
  setVersionSelection,
  academicsBase,
  academicsBaseMobile,
  preSchool,
  gradeSchool1,
  gradeSchool2,
  highSchool4,
  highSchool2,
  highSchool1,
  highSchool3,
  hospitality,
  businessAd2,
  businessAd1,
  technical,
  technical2
) {
  try {
    let res = await axios.get(ENDPOINT + `/dynamic-academics`, {
      headers: headers,
    });

    const basic_education = res.data.current.basic_education;
    const tertiary_education = res.data.current.tertiary_education;
    const technical_education = res.data.current.technical_education;
    const versions = res.data.versions;
    setCurrentVersion(res.data.current.version)
    setVersionSelection(versions)
    setSelectedVersion(res.data.current.version)

    setBasicEducation({
      ...basicEducation,
      mainImage: basic_education.mainImage.includes("static/media/") ? academicsBase : basic_education.mainImage,
      mainTablet: basic_education.mainTablet.includes("static/media/") ? academicsBase : basic_education.mainTablet,
      mainMobile: basic_education.mainMobile.includes("static/media/") ? academicsBaseMobile : basic_education.mainMobile,
      mainTitle: basic_education.mainTitle,
      mainDescription: basic_education.mainDescription,
      first: basic_education.first.includes("static/media/") ? preSchool : basic_education.first,
      second: basic_education.second.includes("static/media/") ? gradeSchool1 : basic_education.second,
      third: basic_education.third.includes("static/media/") ? gradeSchool2 : basic_education.third,
      fourth: basic_education.fourth.includes("static/media/") ? highSchool4 : basic_education.fourth,
      fifth: basic_education.fifth.includes("static/media/") ? highSchool2 : basic_education.fifth,
      sixth: basic_education.sixth.includes("static/media/") ? highSchool1 : basic_education.sixth,
      seventh: basic_education.seventh.includes("static/media/") ? highSchool3 : basic_education.seventh,
      firstTitle: basic_education.firstTitle,
      firstDescription: basic_education.firstDescription,
      firstContents: basic_education.firstContents,
      secondTitle: basic_education.secondTitle,
      secondDescription: basic_education.secondDescription,
      secondContents: basic_education.secondContents,
      thirdTitle: basic_education.thirdTitle,
      thirdDescription: basic_education.thirdDescription,
      thirdContents: basic_education.thirdContents
    })

    setTertiaryEducation({
      ...tertiaryEducation,
        mainTitle: tertiary_education.mainTitle,
        mainDescription1: tertiary_education.mainDescription1,
        mainDescription2: tertiary_education.mainDescription2,
        firstTitle: tertiary_education.firstTitle,
        firstDescription: tertiary_education.firstDescription,
        firstContentTitle: tertiary_education.firstContentTitle,
        firstContents: tertiary_education.firstContents,
        secondTitle: tertiary_education.secondTitle,
        secondDescription: tertiary_education.secondDescription,
        secondContentTitle: tertiary_education.secondContentTitle,
        secondContents: tertiary_education.secondContents,
        secondContentTitle2: tertiary_education.secondContentTitle2,
        secondContents2: tertiary_education.secondContents2,
        thirdTitle: tertiary_education.thirdTitle,
        thirdDescription: tertiary_education.thirdDescription,
        thirdContentTitle: tertiary_education.thirdContentTitle,
        thirdContents: tertiary_education.thirdContents,
        fourthTitle: tertiary_education.fourthTitle,
        fourthDescription: tertiary_education.fourthDescription,
        fourthContentTitle: tertiary_education.fourthContentTitle,
        fourthContents: tertiary_education.fourthContents,
        firstImage: tertiary_education.firstImage.includes("static/media/") ? hospitality : tertiary_education.firstImage,
        secondImage: tertiary_education.secondImage.includes("static/media/") ? businessAd2 : tertiary_education.secondImage,
        thirdImage: tertiary_education.thirdImage.includes("static/media/") ? businessAd1 : tertiary_education.thirdImage,
        fourthImage: tertiary_education.fourthImage.includes("static/media/") ? highSchool4 : tertiary_education.fourthImage,
        fifthImage: tertiary_education.fifthImage.includes("static/media/") ? highSchool2 : tertiary_education.fifthImage,
    })

    setTechnicalEducation({...technicalEducation,
      mainTitle: technical_education.mainTitle,
      contentTitle1: technical_education.contentTitle1,
      contents1: technical_education.contents1,
      contentTitle2: technical_education.contentTitle2,
      contents2: technical_education.contents2,
      contentTitle3: technical_education.contentTitle3,
      contents3: technical_education.contents3,
      image1: technical_education.image1,
      image2: technical_education.image2,
    })

    setLoading(false)
  } catch (err) {
    if (err.response) {
      notifyError("Error while getting latest template.");
    }
    setLoading(false)
  }
}

async function getSelectedTemplate(
  setBasicEducation,
  basicEducation,
  setTertiaryEducation,
  tertiaryEducation,
  setTechnicalEducation,
  technicalEducation,
  setCurrentVersion,
  selectedVersion,
  setLoading,
  setVersionSelection,
  academicsBase,
  academicsBaseMobile,
  preSchool,
  gradeSchool1,
  gradeSchool2,
  highSchool4,
  highSchool2,
  highSchool1,
  highSchool3,
  hospitality,
  businessAd2,
  businessAd1,
  technical,
  technical2
) {
  try {
    let res = await axios.get(ENDPOINT + `/dynamic-academics/${selectedVersion}`, {
      headers: headers,
    });

    const basic_education = res.data.basic_education;
    const tertiary_education = res.data.tertiary_education;
    const technical_education = res.data.technical_education;

    setBasicEducation({
      ...basicEducation,
      mainImage: basic_education.mainImage.includes("static/media/") ? academicsBase : basic_education.mainImage,
      mainTablet: basic_education.mainTablet.includes("static/media/") ? academicsBase : basic_education.mainTablet,
      mainMobile: basic_education.mainMobile.includes("static/media/") ? academicsBaseMobile : basic_education.mainMobile,
      mainTitle: basic_education.mainTitle,
      mainDescription: basic_education.mainDescription,
      first: basic_education.first.includes("static/media/") ? preSchool : basic_education.first,
      second: basic_education.second.includes("static/media/") ? gradeSchool1 : basic_education.second,
      third: basic_education.third.includes("static/media/") ? gradeSchool2 : basic_education.third,
      fourth: basic_education.fourth.includes("static/media/") ? highSchool4 : basic_education.fourth,
      fifth: basic_education.fifth.includes("static/media/") ? highSchool2 : basic_education.fifth,
      sixth: basic_education.sixth.includes("static/media/") ? highSchool1 : basic_education.sixth,
      seventh: basic_education.seventh.includes("static/media/") ? highSchool3 : basic_education.seventh,
      firstTitle: basic_education.firstTitle,
      firstDescription: basic_education.firstDescription,
      firstContents: basic_education.firstContents,
      secondTitle: basic_education.secondTitle,
      secondDescription: basic_education.secondDescription,
      secondContents: basic_education.secondContents,
      thirdTitle: basic_education.thirdTitle,
      thirdDescription: basic_education.thirdDescription,
      thirdContents: basic_education.thirdContents
    })

    setTertiaryEducation({
      ...tertiaryEducation,
        mainTitle: tertiary_education.mainTitle,
        mainDescription1: tertiary_education.mainDescription1,
        mainDescription2: tertiary_education.mainDescription2,
        firstTitle: tertiary_education.firstTitle,
        firstDescription: tertiary_education.firstDescription,
        firstContentTitle: tertiary_education.firstContentTitle,
        firstContents: tertiary_education.firstContents,
        secondTitle: tertiary_education.secondTitle,
        secondDescription: tertiary_education.secondDescription,
        secondContentTitle: tertiary_education.secondContentTitle,
        secondContents: tertiary_education.secondContents,
        secondContentTitle2: tertiary_education.secondContentTitle2,
        secondContents2: tertiary_education.secondContents2,
        thirdTitle: tertiary_education.thirdTitle,
        thirdDescription: tertiary_education.thirdDescription,
        thirdContentTitle: tertiary_education.thirdContentTitle,
        thirdContents: tertiary_education.thirdContents,
        fourthTitle: tertiary_education.fourthTitle,
        fourthDescription: tertiary_education.fourthDescription,
        fourthContentTitle: tertiary_education.fourthContentTitle,
        fourthContents: tertiary_education.fourthContents,
        firstImage: tertiary_education.firstImage.includes("static/media/") ? hospitality : tertiary_education.firstImage,
        secondImage: tertiary_education.secondImage.includes("static/media/") ? businessAd2 : tertiary_education.secondImage,
        thirdImage: tertiary_education.thirdImage.includes("static/media/") ? businessAd1 : tertiary_education.thirdImage,
        fourthImage: tertiary_education.fourthImage.includes("static/media/") ? highSchool4 : tertiary_education.fourthImage,
        fifthImage: tertiary_education.fifthImage.includes("static/media/") ? highSchool2 : tertiary_education.fifthImage,
    })

    setTechnicalEducation({...technicalEducation,
      mainTitle: technical_education.mainTitle,
      contentTitle1: technical_education.contentTitle1,
      contents1: technical_education.contents1,
      contentTitle2: technical_education.contentTitle2,
      contents2: technical_education.contents2,
      contentTitle3: technical_education.contentTitle3,
      contents3: technical_education.contents3,
      image1: technical_education.image1.includes("static/media/") ? technical : technical_education.image1,
      image2: technical_education.image2.includes("static/media/") ? technical2 : technical_education.image2,
    })

    setLoading(false)
  } catch (err) {
    if (err.response) {
      notifyError("Error while getting template.");
    }
    setLoading(false)
  }
}

async function publishTemplate(selectedVersion, setButtonSpinner, setLoading, setButtonSpinner2) {
  try {
    let res = await axios.get(ENDPOINT + `/dynamic-academics-publish-template/${selectedVersion}`, {
      headers: headers,
    });

    setButtonSpinner(false)
    notifySuccess("Successfully published new version");
    setTimeout(() => window.location.reload(), 2000);
  } catch (err) {
    if (err.response) {
      notifyError("Error while publishing new version.");
    }
    setButtonSpinner(false)
  }
}

async function getCurrentTemplateFrontPage(
  setBasicEducation,
  basicEducation,
  setTertiaryEducation,
  tertiaryEducation,
  setTechnicalEducation,
  technicalEducation,
  setCurrentVersion,
  setSelectedVersion,
  setLoading,
  setVersionSelection,
  academicsBase,
  academicsBaseMobile,
  preSchool,
  gradeSchool1,
  gradeSchool2,
  highSchool4,
  highSchool2,
  highSchool1,
  highSchool3,
  hospitality,
  businessAd2,
  businessAd1,
  technical,
  technical2
) {
  try {
    let res = await axios.get(ENDPOINT + `/dynamic-academics-frontpage`, {
      headers: headers,
    });

    const basic_education = res.data.current.basic_education;
    const tertiary_education = res.data.current.tertiary_education;
    const technical_education = res.data.current.technical_education;
    const versions = res.data.versions;
    setCurrentVersion(res.data.current.version)
    setVersionSelection(versions)
    setSelectedVersion(res.data.current.version)

    setBasicEducation({
      ...basicEducation,
      mainImage: basic_education.mainImage.includes("static/media/") ? academicsBase : basic_education.mainImage,
      mainTablet: basic_education.mainTablet.includes("static/media/") ? academicsBase : basic_education.mainTablet,
      mainMobile: basic_education.mainMobile.includes("static/media/") ? academicsBaseMobile : basic_education.mainMobile,
      mainTitle: basic_education.mainTitle,
      mainDescription: basic_education.mainDescription,
      first: basic_education.first.includes("static/media/") ? preSchool : basic_education.first,
      second: basic_education.second.includes("static/media/") ? gradeSchool1 : basic_education.second,
      third: basic_education.third.includes("static/media/") ? gradeSchool2 : basic_education.third,
      fourth: basic_education.fourth.includes("static/media/") ? highSchool4 : basic_education.fourth,
      fifth: basic_education.fifth.includes("static/media/") ? highSchool2 : basic_education.fifth,
      sixth: basic_education.sixth.includes("static/media/") ? highSchool1 : basic_education.sixth,
      seventh: basic_education.seventh.includes("static/media/") ? highSchool3 : basic_education.seventh,
      firstTitle: basic_education.firstTitle,
      firstDescription: basic_education.firstDescription,
      firstContents: basic_education.firstContents,
      secondTitle: basic_education.secondTitle,
      secondDescription: basic_education.secondDescription,
      secondContents: basic_education.secondContents,
      thirdTitle: basic_education.thirdTitle,
      thirdDescription: basic_education.thirdDescription,
      thirdContents: basic_education.thirdContents
    })

    setTertiaryEducation({
      ...tertiaryEducation,
        mainTitle: tertiary_education.mainTitle,
        mainDescription1: tertiary_education.mainDescription1,
        mainDescription2: tertiary_education.mainDescription2,
        firstTitle: tertiary_education.firstTitle,
        firstDescription: tertiary_education.firstDescription,
        firstContentTitle: tertiary_education.firstContentTitle,
        firstContents: tertiary_education.firstContents,
        secondTitle: tertiary_education.secondTitle,
        secondDescription: tertiary_education.secondDescription,
        secondContentTitle: tertiary_education.secondContentTitle,
        secondContents: tertiary_education.secondContents,
        secondContentTitle2: tertiary_education.secondContentTitle2,
        secondContents2: tertiary_education.secondContents2,
        thirdTitle: tertiary_education.thirdTitle,
        thirdDescription: tertiary_education.thirdDescription,
        thirdContentTitle: tertiary_education.thirdContentTitle,
        thirdContents: tertiary_education.thirdContents,
        fourthTitle: tertiary_education.fourthTitle,
        fourthDescription: tertiary_education.fourthDescription,
        fourthContentTitle: tertiary_education.fourthContentTitle,
        fourthContents: tertiary_education.fourthContents,
        firstImage: tertiary_education.firstImage.includes("static/media/") ? hospitality : tertiary_education.firstImage,
        secondImage: tertiary_education.secondImage.includes("static/media/") ? businessAd2 : tertiary_education.secondImage,
        thirdImage: tertiary_education.thirdImage.includes("static/media/") ? businessAd1 : tertiary_education.thirdImage,
        fourthImage: tertiary_education.fourthImage.includes("static/media/") ? highSchool4 : tertiary_education.fourthImage,
        fifthImage: tertiary_education.fifthImage.includes("static/media/") ? highSchool2 : tertiary_education.fifthImage,
    })

    setTechnicalEducation({...technicalEducation,
      mainTitle: technical_education.mainTitle,
      contentTitle1: technical_education.contentTitle1,
      contents1: technical_education.contents1,
      contentTitle2: technical_education.contentTitle2,
      contents2: technical_education.contents2,
      contentTitle3: technical_education.contentTitle3,
      contents3: technical_education.contents3,
      image1: technical_education.image1,
      image2: technical_education.image2,
    })

    setLoading(false)
  } catch (err) {
    if (err.response) {
      notifyError("Error while getting latest template.");
    }
    setLoading(false)
  }
}