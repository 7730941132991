import { ENDPOINT } from "../constants/api";
import axios from "axios";
import { notifyError, notifySuccess } from "./Toaster";
import moment from "moment";

export const adminActions = {
  getEducationType,
  getGradeType,
  getGradeLevel,
  updateGradeLevel,
  addGradeLevel,
};

const token = localStorage.getItem("jwtToken")
  ? localStorage.getItem("jwtToken")
  : "";
const headers = {
  Authorization: `Bearer ${token}`,
  "Content-Type": "application/json",
  "X-Requested-With": "XMLHttpRequest",
};

// Fetch Education Type Data
async function getEducationType(selectEducationOptions, setSelectEducationOptions) {
  try {
    let res = await axios.get(ENDPOINT + `/get-education-type`, {
      headers: headers
    })

    let education = res.data.data.education_type;

    // console.log(education);

    let educationSelections = education.map((educationType) => {
      // if (educationType.name != "Tesda Course") {
      //   return {
      //     value: educationType.id,
      //     label: educationType.name
      //   }
      // }

      return {
        value: educationType.id,
        label: educationType.name
      }

    })

    // console.log(educationSelections)

    setSelectEducationOptions({ ...selectEducationOptions, education_type: educationSelections })
    // console.log(educationArr)

  } catch (error) {
    notifyError("Error while fetching Data");
  }
}

// Fetch Grade Type Data
async function getGradeType(selectGradeTypeOptions, setSelectGradeTypeOptions) {
  try {
    let res = await axios.get(ENDPOINT + `/get-grade-type`, {
      headers: headers
    })

    // console.log(res.data.data.grade_type)

    const grade = res.data.data.grade_type;

    // console.log(grade)

    let gradeTypeSelections = grade.map((gradeType) => {
      // console.log(gradeType)
      return {
        value: gradeType.id,
        label: gradeType.name,
        education_type: gradeType.education_type_id.id
      }
    })

    // console.log(gradeArr)
    setSelectGradeTypeOptions({ ...selectGradeTypeOptions, grade_type: gradeTypeSelections })

  } catch (error) {
    notifyError("Error while fetching Data");
  }
}


// Fetch Grade Level Data
async function getGradeLevel(setData, setLoading, educationTypes, setEducationId) {
  try {
    let res = await axios.get(ENDPOINT + `/get-grade-level`, {
      headers: headers
    })

    let educationTypeArr = [];

    const gradeLevel = res.data.data.grade_level
    // console.log(educationTypes)
    // console.log(gradeLevel)

    let gradeLevelArr = gradeLevel.map((data) => {
      // educationTypeArr.push(data.grade_type_id.education_type_id.id)
      // let educationArr = educationTypes.filter((education) => {
      //   return education.id == data.grade_type_id.education_type_id.id
      // })
      return {
        education_type_id: data.grade_type_id.education_type_id.id,
        // education_type: educationArr.length >= 1 ? educationArr[0].name : "",
        grade_type_id: data.grade_type_id.id,
        grade_type_name: data.grade_type_id.name,
        id: data.id,
        name: data.name,
        enabled: data.enabled,
      }
    })

    const uniqueArr = [...new Set(educationTypeArr)];
    setEducationId(uniqueArr);
    setData(gradeLevelArr)
    setLoading(false)

  } catch (error) {
    notifyError("Error while fetching Data");
  }
}

async function updateGradeLevel(
  data,
  setButtonSpinner,
  setModal,
  setUpdateTable,
  updateTable
) {
  try {

    let res = await axios.post(ENDPOINT + `/update-grade-level`, data, {
      headers: headers,
    });

    // console.log(res)

    setUpdateTable(!updateTable);
    setButtonSpinner(false);
    setModal(false)
    notifySuccess(res.data.message);
  } catch (err) {
    if (err.response) {
      notifyError("Error while updating grade level!");
    }
    setButtonSpinner(false);
  }
}

async function addGradeLevel(
  data,
  setButtonSpinner,
  setModal,
  setUpdateTable,
  updateTable
) {
  try {
    let res = await axios.post(ENDPOINT + `/add-grade-level`, data, {
      headers: headers,
    });

    if (res.data.success) {
      setUpdateTable(!updateTable);
      setButtonSpinner(false);
      setModal(false);
      notifySuccess(res.data.message);
    } else {
      notifyError(res.data.data.Error);
    }

  } catch (err) {
    if (err.response) {
      notifyError("Error while adding grade level!");
    }
    setButtonSpinner(false);
  }
}