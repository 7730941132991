import React, { useState } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
  Container,
  Label,
  FormGroup,
  Form,
  Input,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import classnames from "classnames";
import moment from "moment";
import { useForm, Controller } from "react-hook-form";
import { applicationActions } from "../../../services/ApplicationServices";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

const EscApproval = ({
  scholarshipForm,
  toggleEscModal,
  escModal,
  setEscModal,
  escAction,
  setEscApproval,
}) => {
  const { register, handleSubmit, errors, control, setValue, getValues } =
    useForm();
  const [buttonSpinner, setButtonSpinner] = useState(false);

  const onSubmit = () => {
    setButtonSpinner(true);
    const dataSubmit = {
      application_id: scholarshipForm.application_id,
      approve_esc: escAction == "approve" ? 1 : 0,
    };

    applicationActions.approveEsc(
      dataSubmit,
      setButtonSpinner,
      setEscModal,
      escAction,
      setEscApproval
    );
  };

  return (
    <Modal isOpen={escModal} toggle={toggleEscModal}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader toggle={toggleEscModal}>ESC Approval</ModalHeader>
        <ModalBody className="text-center">
          <h5>Confirm {escAction == "approve" ? "Approval" : "Disapproval"}</h5>
        </ModalBody>
        <ModalFooter>
          <Button
            color="warning"
            className="text-white"
            disabled={buttonSpinner ? true : false}
          >
            {buttonSpinner ? (
              <>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp;Processing...
              </>
            ) : (
              "Confirm"
            )}
          </Button>
          <Button color="secondary" onClick={toggleEscModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default EscApproval;
