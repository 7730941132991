import React, { useEffect, useState } from 'react';
import { isDesktop, isMobile, isTablet } from 'react-device-detect';
import { Button, Carousel, CarouselControl, CarouselIndicators, CarouselItem, Col, Fade, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap';
import carousel1 from '../../../../assets/img/carousel-1.jpg';
import carousel2 from '../../../../assets/img/carousel-2.jpg';
import carousel3 from '../../../../assets/img/carousel-3.jpg';
import carouselMobile1 from '../../../../assets/img/carousel-mobile-1.jpg';
import carouselMobile2 from '../../../../assets/img/carousel-mobile-2.jpg';
import carouselMobile3 from '../../../../assets/img/carousel-mobile-3.jpg';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import { notifyError } from '../../../../services/Toaster';

const HomepageCarousel = ({ homepage, setHomepage, handleChangeModal, handleArrangeModal, slidesDesktop, setSlidesDesktop, slidesMobile, setSlidesMobile }) => {

    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === (isDesktop || isTablet ? homepage.desktopCarousel.length : homepage.mobileCarousel.length)  - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? (isDesktop || isTablet ? homepage.desktopCarousel.length : homepage.mobileCarousel.length)  - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    }

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    }

    const [device, setDevice] = useState('')
    const [openDesktopModal, setOpenDesktopModal] = useState(false)
    const [openMobileModal, setOpenMobileModal] = useState(false)
    const [addOrEditModal, setAddOrEditModal] = useState(false)
    const [addOrEditModalMobile, setAddOrEditModalMobile] = useState(false)
    const [currentIndex, setCurrentIndex] = useState("")
    const [action, setAction] = useState("");
    const blankImage = {
        src: "",
        imageFile: "",
        altText: ""
    };

    const [tempImage, setTempImage] = useState(blankImage)

    const handleUpdateDesktop = (deviceType) => {
        setDevice('')
        if (!openDesktopModal) {
            setDevice(deviceType)
            setOpenDesktopModal(!openDesktopModal)
        } else {
            setOpenDesktopModal(!openDesktopModal)
        }
    }

    const handleUpdateMobile = (deviceType) => {
        if (!openDesktopModal) {
            setDevice(deviceType)
            setOpenMobileModal(!openMobileModal)
        } else {
            setOpenMobileModal(!openMobileModal)
        }
    }

    const toggleAddOrEdit = (act, index, d) => {
        setAction("")
        setCurrentIndex("")
        setTempImage(blankImage)
        if (!addOrEditModal) {
            setAction(act)
            if (act == "edit") {
                setTempImage(d)
                setCurrentIndex(index)
            }
            setAddOrEditModal(!addOrEditModal)
        } else {
            setAddOrEditModal(!addOrEditModal)
        }
    }

    const toggleAddOrEditMobile = (act, index, d) => {
        setAction("")
        setCurrentIndex("")
        setTempImage(blankImage)
        if (!addOrEditModalMobile) {
            setAction(act)
            if (act == "edit") {
                setTempImage(d)
                setCurrentIndex(index)
            }
            setAddOrEditModalMobile(!addOrEditModalMobile)
        } else {
            setAddOrEditModalMobile(!addOrEditModalMobile)
        }
    }

    const handleDeleteData = (i) => {
        let homepageDesktopCarousel = homepage.desktopCarousel;
        let newHomePageDesktopCarousel = homepageDesktopCarousel.filter((d, key) => {
            return i != key;
        })
        setHomepage({ ...homepage, desktopCarousel: newHomePageDesktopCarousel })
    }

    const acceptedImageExtentions = ['jpg', 'jpeg', 'png'];
    const handleUploadImage = async (e) => {
        let extension = e[0].name.split('.')[1];
        if (acceptedImageExtentions.includes(extension)) {
            let imageFile = {
                filename: e[0].name,
                document: await convertBase64(e[0]),
            };
            setTempImage({ ...tempImage, src: URL.createObjectURL(e[0]), imageFile: imageFile })
        } else {
            notifyError("Invalid file type for image")
        }
    }

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const handleSaveImageDesktop = () => {
        let currentDesktopCarousel = homepage.desktopCarousel;
        let currentDesktopSlide = slidesDesktop;
        if (action == "add") {
            currentDesktopCarousel.push(tempImage)
            setHomepage({ ...homepage, desktopCarousel: currentDesktopCarousel })

            const newSlide = <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={tempImage.src}
            >
                <img src={tempImage.src} alt={tempImage.altText} className="w-100" style={{ height: "80vh", objectFit: "cover" }} />
            </CarouselItem>

            currentDesktopSlide.push(newSlide)
            setSlidesDesktop(currentDesktopSlide)

        }

        if (action == "edit") {
            let updateDesktopImage = currentDesktopCarousel.map((imageDesktop, i) => {
                if (i == currentIndex) {
                    imageDesktop = tempImage;
                    return imageDesktop
                } else {
                    return imageDesktop
                }
            })
            setHomepage({ ...homepage, desktopCarousel: updateDesktopImage })
        }
        setAddOrEditModal(false)
    }

    const handleSaveImageMobile = () => {
        let currentMobileCarousel = homepage.mobileCarousel;
        let currentMobileSlide = slidesMobile;

        if (action == "add") {
            currentMobileCarousel.push(tempImage)
            setHomepage({ ...homepage, mobileCarousel: currentMobileCarousel })

            const newSlideMobile = <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={tempImage.src}
            >
                <img src={tempImage.src} alt={tempImage.altText} className="w-100" style={{ height: "100vh", objectFit: "cover" }} />
            </CarouselItem>

            currentMobileSlide.push(newSlideMobile)
            setSlidesMobile(currentMobileSlide)
        }

        if (action == "edit") {
            let updateMobileImage = currentMobileCarousel.map((imageMobile, i) => {
                if (i == currentIndex) {
                    imageMobile = tempImage;
                    return imageMobile
                } else {
                    return imageMobile
                }
            })
            setHomepage({ ...homepage, mobileCarousel: updateMobileImage })
        }
        setAddOrEditModalMobile(false)
    }

    return (
        <>
            <Fade>
                <Row className="px-0 h-100 mx-0 w-100 justify-content-center align-items-center">
                    <Col lg="12" md="12" xs="12" className="px-0 h-100 mx-0 w-100">
                        <Carousel
                            activeIndex={activeIndex}
                            next={next}
                            previous={previous}
                            interval={4000}
                            slide={true}
                        >
                            <CarouselIndicators items={isDesktop ? slidesDesktop : slidesMobile} activeIndex={activeIndex} onClickHandler={goToIndex} />
                            {isDesktop ? slidesDesktop : slidesMobile}
                            <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
                            <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
                        </Carousel>
                        {isDesktop ? <Button onClick={() => handleUpdateDesktop('desktop')} className="my-2 mr-2" color='success'>
                            UPDATE CAROUSEL DESKTOP AND TABLET
                        </Button>
                            : ""}
                        <Button onClick={() => handleUpdateMobile('mobile')} className="my-2" color='success'>UPDATE CAROUSEL MOBILE</Button>
                    </Col>
                </Row>
            </Fade>
            <Modal isOpen={openDesktopModal} backdrop="static" size="xl">
                <ModalHeader>Update Carousel Desktop and Tablet</ModalHeader>
                <ModalBody>
                    <div>
                        <div className='mb-3 text-right'>
                            <Button color="primary" disabled={homepage.desktopCarousel.length < 5 ? false : true} onClick={() => toggleAddOrEdit("add", null, null)}>Add Image</Button>
                        </div>
                        <Table className='text-center' bordered striped style={{ overFlowX: "scroll" }}>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Image</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {homepage.desktopCarousel.length >= 1 ?
                                    homepage.desktopCarousel.map((image, i) => {
                                        return (
                                            <tr key={i}>
                                                <td style={{ verticalAlign: "middle" }}>{i + 1}</td>
                                                <td style={{ verticalAlign: "middle" }}>
                                                    <img src={image.src} style={{ height: "150px", width: "300px", objectFit: "fill" }} />
                                                </td>
                                                <td style={{ verticalAlign: "middle" }}>
                                                    <div className='d-flex align-items-center text-center justify-content-center'>
                                                        <Button color="warning" className='text-center text-white mr-2' onClick={() => toggleAddOrEdit('edit', i, image)}>{<FaIcons.FaEdit style={{ fontSize: "1rem" }} />}</Button>
                                                        <Button color="danger" onClick={() => handleDeleteData(i)}>X</Button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                    :
                                    <tr>
                                        <td colSpan="3">No Carousel Images</td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => setOpenDesktopModal(!openDesktopModal)}>Close</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={addOrEditModal} backdrop="static">
                <ModalBody>
                    <img src={tempImage.src} style={{ height: "300px", width: "100%" }} />
                    <Input type="file" onChange={(e) => handleUploadImage(e.target.files)} />
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => handleSaveImageDesktop()} color="success">Save</Button>
                    <Button onClick={() => setAddOrEditModal(!addOrEditModal)}>Close</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={openMobileModal} backdrop="static" size="xl">
                <ModalHeader>Update Carousel Mobile</ModalHeader>
                <ModalBody>
                    <div style={{ overFlow: "auto" }}>
                        <div className='mb-3 text-right'>
                            <Button color="primary" disabled={homepage.mobileCarousel.length < 5 ? false : true} onClick={() => toggleAddOrEditMobile("add", null, null)}>Add Image</Button>
                        </div>
                        <Table className='text-center' bordered striped>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Image</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {homepage.mobileCarousel.length >= 1 ?
                                    homepage.mobileCarousel.map((image, i) => {
                                        return (
                                            <tr key={i}>
                                                <td style={{ verticalAlign: "middle" }}>{i + 1}</td>
                                                <td style={{ verticalAlign: "middle" }}>
                                                    <img src={image.src} style={{ height: isMobile ? "100px" : "300px", width: isMobile ? "60px" : "200px", objectFit: "fill" }} />
                                                </td>
                                                <td style={{ verticalAlign: "middle" }}>
                                                    <div className='d-flex align-items-center text-center justify-content-center'>
                                                        <Button color="warning" className='text-center text-white mr-2' onClick={() => toggleAddOrEditMobile('edit', i, image)}>{<FaIcons.FaEdit style={{ fontSize: "1rem" }} />}</Button>
                                                        <Button color="danger" onClick={() => handleDeleteData(i)}>X</Button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                    :
                                    <tr>
                                        <td colSpan="3">No Carousel Images</td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => setOpenMobileModal(!openMobileModal)}>Close</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={addOrEditModalMobile} backdrop="static">
                <ModalBody className='text-center'>
                    <img src={tempImage.src} style={{ height: "300px", width: "200px" }} />
                    <Input type="file" onChange={(e) => handleUploadImage(e.target.files)} />
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => handleSaveImageMobile()} color="success">Save</Button>
                    <Button onClick={() => setAddOrEditModalMobile(!addOrEditModalMobile)}>Close</Button>
                </ModalFooter>
            </Modal>

        </>
    )
}

export default HomepageCarousel;