import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Label, Input, FormGroup, Form, Spinner } from 'reactstrap';
import { useForm, Controller } from "react-hook-form";
import { adminActions } from '../../../services/GradeTypeServices';
import Select from "react-select";

const GradeTypeModal = ({
    modal,
    setModal,
    handleEditGradeType,
    editGradeType,
    updateTable,
    setUpdateTable,
    action,
    selectOptions,
    coordinators
}) => {

    // console.log(editEducationType)
    // console.log(action)

    const { register, handleSubmit, errors, control, setValue, getValues } = useForm();
    const [buttonSpinner, setButtonSpinner] = useState(false);

    const getOptionLabelByValue = (options, value) => {
        const option = options.find((option) => option.value == value);
        return option ? option.label : "";
    };

    const submitForm = (data) => {
        setButtonSpinner(true)
        data.enabled = data.enabled ? data.enabled.value : 0;
        if (action == "add") {
            data.education_type_id  = data.education_type ? data.education_type.value : ""
            data.grade_name = data.name;
            data.enabled = 1;

            adminActions.addGradeType(data,
                setButtonSpinner,
                setModal,
                setUpdateTable,
                updateTable)

        } else if (action == "edit") {
            data.grade_type_id = editGradeType.id;
            data.grade_name = data.name;
            data.enabled = 1;
            adminActions.updateGradeType(data,
                setButtonSpinner,
                setModal,
                setUpdateTable,
                updateTable)
        }

    }

    return (
        <Modal isOpen={modal} toggle={handleEditGradeType} backdrop="static" size="lg">
            <Form onSubmit={handleSubmit(submitForm)}>
                <ModalHeader toggle={handleEditGradeType}>{action == 'add' ? "Add" : "Update"} Grade Type</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col md="12" xs="12">
                            <FormGroup>
                                <Label
                                    htmlFor="education_type"
                                    className="text-left w-100 text-danger-edit font-weight-bolder"
                                >
                                    Select Education Type
                                </Label>
                                <Controller
                                    control={control}
                                    name="education_type"
                                    defaultValue={
                                        editGradeType.education_type_id != ""
                                            ? {
                                                value: editGradeType.education_type_id,
                                                label: getOptionLabelByValue(
                                                    selectOptions.education_type,
                                                    editGradeType.education_type_id
                                                ),
                                            }
                                            : ""
                                    }
                                    rules={{ required: true }}
                                    invalid={errors.education_type ? true : false}
                                    render={({ onChange, value, onBlur, name }) => (
                                        <Select
                                            options={selectOptions.education_type}
                                            name="education_type"
                                            onChange={(e) => {
                                                setValue("education_type", e);
                                                return e;
                                            }}
                                            defaultValue={
                                                editGradeType.education_type_id != ""
                                                    ? {
                                                        value: editGradeType.education_type_id,
                                                        label: getOptionLabelByValue(
                                                            selectOptions.education_type,
                                                            editGradeType.education_type_id
                                                        ),
                                                    }
                                                    : ""
                                            }
                                        />
                                    )}
                                />
                                {errors.education_type &&
                                    errors.education_type.type == "required" && (
                                        <small className="text-danger">
                                            Education type is required!
                                        </small>
                                    )}
                            </FormGroup>
                        </Col>
                        <Col md="12" xs="12">
                            <FormGroup>
                                <Label htmlFor="name">Grade Type:</Label>
                                <Controller
                                    control={control}
                                    name="name"
                                    id="name"
                                    defaultValue={editGradeType.name}
                                    rules={{ required: true }}
                                    invalid={errors.name ? true : false}
                                    render={({ onChange, value, onBlur, name }) => (
                                        <Input
                                            name="name"
                                            defaultValue={editGradeType.name}
                                            onChange={(e) => {
                                                setValue('name', e.target.value)
                                                // console.log(e.target.value);
                                            }}
                                        />
                                    )}
                                />
                                {errors.name && errors.name.type == "required" ?
                                    <small className="text-danger">
                                        Grade type is required!
                                    </small>
                                    :
                                    ""
                                }
                            </FormGroup>
                        </Col>
                        <Col md="12" xs="12">
                            <FormGroup>
                                <Label
                                    htmlFor="coordinator_id"
                                    className="text-left w-100 text-danger-edit font-weight-bolder"
                                >
                                    Select Coordinator
                                </Label>
                                <Controller
                                    control={control}
                                    name="coordinator_id"
                                    defaultValue={
                                        editGradeType.coordinator_id != ""
                                            ? {
                                                value: editGradeType.coordinator_id,
                                                label: getOptionLabelByValue(
                                                    coordinators,
                                                    editGradeType.coordinator_id
                                                ),
                                            }
                                            : ""
                                    }
                                    rules={{ required: true }}
                                    invalid={errors.coordinator_id ? true : false}
                                    render={({ onChange, value, onBlur, name }) => (
                                        <Select
                                            options={coordinators}
                                            name="coordinator_id"
                                            onChange={(e) => {
                                                setValue("coordinator_id", e.value);
                                                return e;
                                            }}
                                            defaultValue={
                                                editGradeType.coordinator_id != ""
                                                    ? {
                                                        value: editGradeType.coordinator_id,
                                                        label: getOptionLabelByValue(
                                                            coordinators,
                                                            editGradeType.coordinator_id
                                                        ),
                                                    }
                                                    : ""
                                            }
                                        />
                                    )}
                                />
                                {errors.coordinator_id &&
                                    errors.coordinator_id.type == "required" && (
                                        <small className="text-danger">
                                            Education type is required!
                                        </small>
                                    )}
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button className="bg-warning-edit border-0" disabled={buttonSpinner}>
                        {buttonSpinner ? (
                            <>
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            </>
                        ) : (
                            "Submit"
                        )}
                    </Button>
                    <Button color="secondary" onClick={handleEditGradeType} disabled={buttonSpinner}>Cancel</Button>
                </ModalFooter>
            </Form>
        </Modal>
    )
}

export default GradeTypeModal;