import React, { useEffect, useState } from "react";
import {
  Container,
  Input,
  Spinner,
  FormGroup,
  Col,
  Button,
  Form,
  Row,
  Label,
  UncontrolledTooltip,
  Table,
  Jumbotron,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { paymentActions } from "../../../services/PaymentServices";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import overlayFactory from "react-bootstrap-table2-overlay";
import { isMobile } from "react-device-detect";
import Paid from "../../../assets/img/paid.png";
import File from "../../../assets/img/UPLOAD.png";
import CashierPaidConfirm from "./CashierPaidConfirm";
import CashierUpdateTxn from "./CashierUpdateTxn";
import Select from "react-select";
import optionList from "./optionList.js";
import EnrollStudentConfirm from "./EnrollStudentConfirm";
import RevertEnrollStudentConfirm from "./RevertEnrollStudentConfirm";
import Receipt from "../../../assets/img/x.svg.png";
import CashierRevokeConfirm from "./CashierRevokeConfirm";
import Edit from "../../../assets/img/edit.png";
import Trash from "../../../assets/img/trash.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { educationActions } from "../../../services/EducationSelectionService.js";

const CashierView = () => {
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const { register, handleSubmit, errors, control, setValue, getValues } =
    useForm();
  const [remarksModal, setRemarksModal] = useState(false);
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [billings, setBillings] = useState([]);
  const [surnames, setSurnames] = useState([]);
  const [surnamesSelection, setSurnamesSelection] = useState(false);
  const [referenceNo, setReferenceNo] = useState("");
  const [updateTable, setUpdateTable] = useState(false);
  const [totalAmount, setTotalAmount] = useState("");
  const [remainingBalance, setRemainingBalance] = useState("");
  const [gradeLevels, setGradeLevels] = useState([]);
  const [courses, setCourses] = useState([]);
  const [coursesSelection, setCoursesSelection] = useState([]);
  const [gradeLevelSelection, setGradeLevelSelection] = useState([]);
  const [gradeLevelFilter, setGradeLevelFilter] = useState("");
  const withSemester = [
    "Grade 11",
    "Grade 12",
    "First year",
    "Second year",
    "Third year",
    "Fourth year",
  ];
  const [searchType, setSearchType] = useState("");
  const [filters, setFilters] = useState({
    syFrom: "",
    syTo: "",
    student_status: "enrolled",
    gradeLevel: "",
    semester: "",
    course: "",
  });

  const getOptionLabelByValue = (options, value) => {
    const option = options.find((option) => option.value == value);
    return option ? option.label : "";
  };

  const blankPaymentApplication = {
    application_id: "",
    referenceNo: "-",
    lastName: "-",
    givenName: "-",
    middleName: "-",
    paymentMode: "-",
    tuitionFee: "-",
    miscellaneousFee: "-",
    otherFee: "-",
    discountedTuitionFee: "-",
    paymentUponEnrollment: "-",
    paymentPerBilling: "-",
    course: "-",
    gradeLevel: "-",
    scholarship: "",
    approve_esc: "",
    esc_type: "",
    cashier_status: "",
  };
  const [paymentApplication, setPaymentApplication] = useState(
    blankPaymentApplication
  );

  useEffect(() => {
    educationActions.getApplications(
      null,
      null,
      null,
      setGradeLevels,
      null,
      setCourses,
      null,
      null
    );
  }, []);

  useEffect(() => {
    if (gradeLevels.length >= 1) {
      let level = gradeLevels.map((grade) => {
        let grade_type_id = grade.grade_type_id.id;
        return {
          value: grade.id,
          label: grade.name,
          grade_type: grade_type_id,
        };
      });
      setGradeLevelSelection(level);
    }
  }, [gradeLevels]);

  const onSubmit = (data) => {
    setButtonSpinner(true);
    setSurnamesSelection(false);
    setPaymentApplication(blankPaymentApplication);
    setBillings([]);

    if (data.searchType.value == 1) {
      setReferenceNo(data.searchText);
      paymentActions.getApplicationForCashier(
        data.searchText,
        setBillings,
        setPaymentApplication,
        setButtonSpinner,
        setLoading,
        setUpdateTable,
        setTotalAmount,
        setRemainingBalance
      );
    }

    if (data.searchType.value == 2) {
      let newFilters = {
        sy_from: moment(data.sy_from).format("YYYY"),
        sy_to: moment(data.sy_to).format("YYYY"),
        gradeLevel: filters.gradeLevel ? filters.gradeLevel.value : null,
        gradeType: filters.gradeLevel ? filters.gradeLevel.grade_type : null,
        semester: filters.semester,
        search: data.searchText,
      };
      paymentActions.getApplicationForCashierByLastName(
        newFilters,
        setSurnames,
        setSurnamesSelection,
        setButtonSpinner
      );
    }
  };

  const NoDataIndication = () => <div>No Data</div>;

  const columns = [
    {
      dataField: "transaction_number",
      text: "Transaction Number",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
      formatter: (cell) => {
        return cell.toUpperCase();
      },
    },
    {
      dataField: "billing",
      text: "Description",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
      formatter: (cell) => {
        let billing = "";
        if (billings.length >= 1) {
          billings.map((d) => {
            if (d.billing == cell) {
              if (!d.billing && !d.payment_due) {
                billing = "Downpayment";
              } else {
                billing = "Billing " + d.billing;
              }
            }
          });
        }
        return billing;
      },
    },
    {
      dataField: "payment_due",
      text: "Payment Due Date",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
      formatter: (cell) => {
        return cell ? cell : "-";
      },
    },
    {
      dataField: "total_amount",
      text: "Total Amount",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "status",
      text: "Status",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "id",
      text: "Action",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
      formatter: (cell, row) => {
        return (
          <div className="px-2 text-center" style={{ minWidth: "50px" }}>
            {row.status == "pending" ? (
              <>
                <Button
                  id={"paid-btn" + cell}
                  onClick={() => togglePaidStatus(cell, row)}
                  className="btn-warning mr-2"
                >
                  <img
                    src={Paid}
                    style={{ height: "1.5rem", objectFit: "fill" }}
                  />
                </Button>
                <UncontrolledTooltip placement="top" target={"paid-btn" + cell}>
                  Update Payment Status
                </UncontrolledTooltip>
              </>
            ) : (
              ""
            )}

            <Button
              id={"upload-btn" + cell}
              onClick={() => toggleUploadFile(row, "create")}
              className="btn-warning mr-2"
            >
              <img src={File} style={{ height: "1.5rem", objectFit: "fill" }} />
            </Button>
            <UncontrolledTooltip placement="top" target={"upload-btn" + cell}>
              Upload Proof of payment
            </UncontrolledTooltip>

            {row.status == "paid" ? (
              <>
                <Button
                  id={"cancel-btn" + cell}
                  onClick={() => toggleRevoke(cell)}
                  className="btn-danger"
                >
                  <img
                    src={Receipt}
                    style={{
                      height: "1.5rem",
                      objectFit: "fill",
                    }}
                  />
                </Button>
                <UncontrolledTooltip
                  placement="top"
                  target={"cancel-btn" + cell}
                >
                  Return to pending payment
                </UncontrolledTooltip>
              </>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
  ];

  const expandRow = {
    parentClassName: "parent-expand-foo",
    showExpandColumn: true,
    renderer: (row) => {
      return (
        <div className="p-3">
          <Table bordered striped>
            <thead>
              <tr>
                <th
                  style={{ color: "white", backgroundColor: "rgb(128, 0, 0)" }}
                >
                  #
                </th>
                <th
                  style={{ color: "white", backgroundColor: "rgb(128, 0, 0)" }}
                >
                  Payment Proof
                </th>
                <th
                  style={{ color: "white", backgroundColor: "rgb(128, 0, 0)" }}
                >
                  OR Number
                </th>
                <th
                  style={{ color: "white", backgroundColor: "rgb(128, 0, 0)" }}
                >
                  Amount
                </th>
                <th
                  style={{ color: "white", backgroundColor: "rgb(128, 0, 0)" }}
                >
                  Date Posted
                </th>
                <th
                  style={{ color: "white", backgroundColor: "rgb(128, 0, 0)" }}
                >
                  Remarks
                </th>
                <th
                  style={{ color: "white", backgroundColor: "rgb(128, 0, 0)" }}
                >
                  Uploaded By
                </th>
                <th
                  style={{ color: "white", backgroundColor: "rgb(128, 0, 0)" }}
                >
                  Date Created
                </th>
                <th
                  style={{ color: "white", backgroundColor: "rgb(128, 0, 0)" }}
                >
                  Status
                </th>
                <th
                  style={{ color: "white", backgroundColor: "rgb(128, 0, 0)" }}
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {row.payment_proof && row.payment_proof.length ? (
                row.payment_proof.map((proof, key) => {
                  return (
                    <tr key={key}>
                      <td>{key + 1}</td>
                      {proof.name ? (
                        <td>
                          <a href={proof.bucket_link} target="_blank">
                            {proof.name}
                          </a>
                        </td>
                      ) : (
                        <td>-</td>
                      )}
                      <td>{proof.or_number || "-"}</td>
                      <td>{proof.amount || "-"}</td>
                      <td>{proof.date_posted || "-"}</td>
                      <td>{proof.remarks}</td>
                      <td>{proof.uploaded_by ? proof.uploaded_by : "-"}</td>
                      <td>{proof.created_at}</td>
                      <td>{proof.enabled ? "Active" : "Void"}</td>
                      <td>
                        {proof.enabled ? (
                          <>
                            <Button
                              id={"edit-btn" + proof.id}
                              onClick={() => toggleUploadFile(proof, "update")}
                              className="btn-warning mr-2"
                            >
                              <img
                                src={Edit}
                                style={{
                                  height: "1.5rem",
                                  objectFit: "fill",
                                }}
                              />
                            </Button>
                            <UncontrolledTooltip
                              placement="top"
                              target={"edit-btn" + proof.id}
                            >
                              Edit Payment Proof
                            </UncontrolledTooltip>

                            <Button
                              id={"void-btn" + proof.id}
                              onClick={() => toggleUploadFile(proof, "void")}
                              className="btn-danger mr-2"
                            >
                              <img
                                src={Trash}
                                style={{
                                  height: "1.5rem",
                                  objectFit: "fill",
                                }}
                              />
                            </Button>
                            <UncontrolledTooltip
                              placement="top"
                              target={"void-btn" + proof.id}
                            >
                              Void Payment Proof
                            </UncontrolledTooltip>
                          </>
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="10">No Data</td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      );
    },
  };

  const filteredBillings = billings.filter((d) => {
    return d.transaction_number
      .toLowerCase()
      .includes(searchText.toLowerCase());
  });

  const [statusModalChange, setStatusModalChange] = useState(false);
  const [billingId, setBillingId] = useState("");
  const togglePaidStatus = (id) => {
    setBillingId("");
    if (!statusModalChange) {
      setBillingId(id);
      setStatusModalChange(!statusModalChange);
    } else {
      setStatusModalChange(!statusModalChange);
    }
  };

  const [updateTxnOpen, setUpdateTxnOpen] = useState(false);
  const [action, setAction] = useState("");
  const blankUpdateTxn = {
    billing_id: "",
    remarks: "",
    cashier_document: "",
    cashier_document_name: "",
    or_number: "",
    amount: "",
    date_posted: "",
  };
  const [txnStatus, setTxnStatus] = useState(blankUpdateTxn);
  const toggleUploadFile = (proof, action) => {
    setBillingId("");
    setAction("");
    setTxnStatus(blankUpdateTxn);
    if (!updateTxnOpen) {
      setAction(action);
      setBillingId(proof.id);
      if (action === "update") {
        setTxnStatus(proof);
      }
      setUpdateTxnOpen(!updateTxnOpen);
    } else {
      setUpdateTxnOpen(!updateTxnOpen);
    }
  };

  const handleChangeSurname = (e) => {
    setLoading(true);
    setReferenceNo(e.value);
    paymentActions.getApplicationForCashier(
      e.value,
      setBillings,
      setPaymentApplication,
      setButtonSpinner,
      setLoading,
      setUpdateTable,
      setTotalAmount,
      setRemainingBalance
    );
  };

  const [enrollConfirmModal, setEnrollConfirmModal] = useState(false);
  const toggleEnrollStudent = () => {
    if (!enrollConfirmModal) {
      setEnrollConfirmModal(!enrollConfirmModal);
    } else {
      setEnrollConfirmModal(!enrollConfirmModal);
    }
  };

  const [revertEnrollConfirmModal, setRevertEnrollConfirmModal] =
    useState(false);
  const toggleRevertEnrollStudent = () => {
    if (!revertEnrollConfirmModal) {
      setRevertEnrollConfirmModal(!revertEnrollConfirmModal);
    } else {
      setRevertEnrollConfirmModal(!revertEnrollConfirmModal);
    }
  };

  const [revokeConfirm, setRevokeConfirm] = useState(false);
  const toggleRevoke = (id) => {
    setBillingId("");
    if (!revokeConfirm) {
      setBillingId(id);
      setRevokeConfirm(!revokeConfirm);
    } else {
      setRevokeConfirm(!revokeConfirm);
    }
  };

  useEffect(() => {
    if (updateTable) {
      setLoading(true);

      paymentActions.getApplicationForCashier(
        referenceNo,
        setBillings,
        setPaymentApplication,
        setButtonSpinner,
        setLoading,
        setUpdateTable,
        setTotalAmount,
        setRemainingBalance
      );
    }
  }, [updateTable]);

  useEffect(() => {
    if (billings.length >= 1) {
      const totalAmountArr = billings.map((bill) => {
        return parseFloat(bill.total_amount);
      });
      let total = totalAmountArr.reduce((result, number) => result + number);

      let paymentProofArr = [];
      billings.map((bill) => {
        if (bill.payment_proof.length >= 1) {
          bill.payment_proof.map((proof) => {
            if (proof.enabled) {
              paymentProofArr.push(parseFloat(proof.amount));
            }
          });
        } else {
          return 0;
        }
      });
      let remaining =
        paymentProofArr.length >= 1
          ? paymentProofArr.reduce((result, number) => result + number)
          : 0;
      let totalRemaining = total - remaining;

      setTotalAmount(total.toFixed(2));
      setRemainingBalance(totalRemaining.toFixed(2));
    }
  }, [billings]);

  return (
    <>
      {statusModalChange ? (
        <CashierPaidConfirm
          statusModalChange={statusModalChange}
          setStatusModalChange={setStatusModalChange}
          togglePaidStatus={togglePaidStatus}
          billingId={billingId}
          billings={billings}
          setBillings={setBillings}
        />
      ) : (
        ""
      )}
      {updateTxnOpen ? (
        <CashierUpdateTxn
          updateTxnOpen={updateTxnOpen}
          setUpdateTxnOpen={setUpdateTxnOpen}
          toggleUploadFile={toggleUploadFile}
          billingId={billingId}
          billings={billings}
          setBillings={setBillings}
          action={action}
          updateTable={updateTable}
          setUpdateTable={setUpdateTable}
          txnStatus={txnStatus}
          setTxnStatus={setTxnStatus}
        />
      ) : (
        ""
      )}
      {enrollConfirmModal ? (
        <EnrollStudentConfirm
          enrollConfirmModal={enrollConfirmModal}
          setEnrollConfirmModal={setEnrollConfirmModal}
          toggleEnrollStudent={toggleEnrollStudent}
          applicationId={paymentApplication.application_id}
          setPaymentApplication={setPaymentApplication}
          blankPaymentApplication={blankPaymentApplication}
          setBillings={setBillings}
        />
      ) : (
        ""
      )}
      {revokeConfirm ? (
        <CashierRevokeConfirm
          revokeConfirm={revokeConfirm}
          setRevokeConfirm={setRevokeConfirm}
          toggleRevoke={toggleRevoke}
          billingId={billingId}
          billings={billings}
          setBillings={setBillings}
        />
      ) : (
        ""
      )}

      {revertEnrollConfirmModal ? (
        <RevertEnrollStudentConfirm
          revertEnrollConfirmModal={revertEnrollConfirmModal}
          setRevertEnrollConfirmModal={setRevertEnrollConfirmModal}
          toggleRevertEnrollStudent={toggleRevertEnrollStudent}
          applicationId={paymentApplication.application_id}
          setPaymentApplication={setPaymentApplication}
          blankPaymentApplication={blankPaymentApplication}
          setBillings={setBillings}
        />
      ) : (
        ""
      )}

      <div className="p-4">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Jumbotron className="py-5">
            <Row>
              <Col md="4" xs="12">
                <FormGroup>
                  <Label
                    htmlFor="searchType"
                    className="text-secondary font-weight-bolder"
                  >
                    Search By:
                  </Label>
                  <Controller
                    control={control}
                    name="searchType"
                    rules={{ required: false }}
                    invalid={errors.searchType ? true : false}
                    defaultValue=""
                    render={({ onChange, value, onBlur, name }) => (
                      <Select
                        options={optionList.searchType}
                        onChange={(e) => {
                          setSearchType(e.value);
                          setValue("searchType", e);
                          setValue("searchText", "");
                          setSearchText("");
                          return e;
                        }}
                        defaultValue=""
                      />
                    )}
                  />
                  {errors.searchType && (
                    <div
                      style={{
                        marginTop: "0.25rem",
                        fontSize: "80%",
                        color: "#f86c6b",
                      }}
                    >
                      Please select!
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col md="2" xs="12">
                <FormGroup>
                  <Label
                    htmlFor="searchType"
                    className="text-secondary font-weight-bolder"
                  >
                    Enter Search:
                  </Label>
                  <Controller
                    control={control}
                    name="searchText"
                    rules={{ required: false }}
                    invalid={errors.searchText ? true : false}
                    defaultValue=""
                    render={({ onChange, value, onBlur, name }) => (
                      <Input
                        className="form-control bg-light"
                        name="searchText"
                        onChange={(event) => {
                          // setBasicForm({ ...basicForm, searchText: event.target.value })
                          setSearchText(event.target.value);
                          setValue("searchText", event.target.value);
                          return event.target.value;
                        }}
                        defaultValue=""
                      />
                    )}
                  />
                  {searchType == 2 ? (
                    <small>
                      Search by: Reference No, Last Name, First Name{" "}
                      <i>(optional)</i>
                    </small>
                  ) : (
                    ""
                  )}
                  {errors.searchType && (
                    <div
                      style={{
                        marginTop: "0.25rem",
                        fontSize: "80%",
                        color: "#f86c6b",
                      }}
                    >
                      This field is required!
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col xs="12" md="4" className="text-right">
                <p style={{ fontSize: isMobile ? "" : "2rem" }}>
                  Total Amount: P{totalAmount}
                </p>
                <p style={{ fontSize: isMobile ? "" : "2rem" }}>
                  Remaining Balance: P{remainingBalance}
                </p>
              </Col>
            </Row>
            {searchType == 2 ? (
              <>
                <Row>
                  <Col md="2" xs="12">
                    <FormGroup>
                      <Label>
                        <small className="font-weight-bolder">S.Y. From</small>
                      </Label>
                      <Controller
                        control={control}
                        name="sy_from"
                        defaultValue=""
                        rules={{ required: true }}
                        invalid={errors.sy_from ? true : false}
                        render={({ onChange, value, onBlur, name }) => (
                          <DatePicker
                            className="form-control bg-light"
                            selected={
                              moment(filters.syFrom).isValid()
                                ? moment(filters.syFrom).toDate()
                                : filters.syFrom
                            }
                            onChange={(date) => {
                              // setStartDate(date);
                              setValue("sy_from", date);
                              setFilters({ ...filters, syFrom: date });
                              return date;
                            }}
                            showYearPicker
                            dateFormat="yyyy"
                            yearItemNumber={10}
                            name="sy_from"
                            defaultValue=""
                          />
                        )}
                      />
                      {errors.sy_from && errors.sy_from.type == "required" && (
                        <div
                          style={{
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#f86c6b",
                          }}
                        >
                          Schoo Year From is required!
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="2" xs="12">
                    <FormGroup>
                      <Label>
                        <small className="font-weight-bolder">S.Y. To</small>
                      </Label>
                      <Controller
                        control={control}
                        name="sy_to"
                        defaultValue=""
                        rules={{
                          required: true,
                          validate: (value) => value > getValues("sy_from"),
                        }}
                        invalid={errors.sy_to ? true : false}
                        render={({ onChange, value, onBlur, name }) => (
                          <DatePicker
                            className="form-control bg-light"
                            selected={
                              moment(filters.syTo).isValid()
                                ? moment(filters.syTo).toDate()
                                : filters.syTo
                            }
                            onChange={(date) => {
                              setValue("sy_to", date);
                              setFilters({ ...filters, syTo: date });
                              return date;
                            }}
                            showYearPicker
                            dateFormat="yyyy"
                            yearItemNumber={10}
                            name="sy_to"
                            defaultValue=""
                          />
                        )}
                      />
                      {errors.sy_to && errors.sy_to.type == "required" && (
                        <div
                          style={{
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#f86c6b",
                          }}
                        >
                          School Year To is required!
                        </div>
                      )}
                      {errors.sy_to && errors.sy_to.type == "validate" && (
                        <div
                          style={{
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#f86c6b",
                          }}
                        >
                          School Year To cannot be lower or equal to S.Y. From!
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="2" xs="12" className="mb-2">
                    <Label>
                      <small className="font-weight-bolder">
                        <i className="fa fa-lightbulb-o"></i>&nbsp;Filter by
                        Grade Level
                      </small>
                    </Label>
                    <Controller
                      control={control}
                      name="gradeLevel"
                      id="gradeLevel"
                      rules={{ required: true }}
                      invalid={errors.gradeLevel ? true : false}
                      defaultValue=""
                      render={({ onChange, value, onBlur, name }) => (
                        <Select
                          options={gradeLevelSelection}
                          onChange={(e) => {
                            setValue("gradeLevel", e);
                            setValue("semester", "");
                            setValue("course", "");
                            setGradeLevelFilter(e.label);
                            setFilters({
                              ...filters,
                              gradeLevel: e,
                              course: "",
                              semester: "",
                            });
                            return e;
                          }}
                          defaultValue=""
                        />
                      )}
                    />
                    {errors.gradeLevel &&
                      errors.gradeLevel.type == "required" && (
                        <div
                          style={{
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#f86c6b",
                          }}
                        >
                          Grade Level is required!
                        </div>
                      )}
                  </Col>
                </Row>
                <Row>
                  {withSemester.includes(gradeLevelFilter) ? (
                    <Col md="2" xs="12" className="mb-2">
                      <Label>
                        <small className="font-weight-bolder">
                          <i className="fa fa-lightbulb-o"></i>&nbsp;Filter by
                          Semester
                        </small>
                      </Label>
                      <Controller
                        control={control}
                        name="semester"
                        rules={{ required: true }}
                        invalid={errors.semester ? true : false}
                        defaultValue=""
                        render={({ onChange, value, onBlur, name }) => (
                          <Select
                            options={optionList.semester}
                            onChange={(e) => {
                              setValue("semester", e);
                              setFilters({ ...filters, semester: e.value });
                              return e;
                            }}
                            defaultValue=""
                          />
                        )}
                      />
                      {errors.semester &&
                        errors.semester.type == "required" && (
                          <div
                            style={{
                              marginTop: "0.25rem",
                              fontSize: "80%",
                              color: "#f86c6b",
                            }}
                          >
                            Semester is required!
                          </div>
                        )}
                    </Col>
                  ) : (
                    ""
                  )}
                </Row>
              </>
            ) : (
              ""
            )}
            <Row>
              <Col md="12" xs="12">
                <Button
                  color="warning"
                  className="text-white mt-3"
                  disabled={buttonSpinner ? true : false}
                >
                  {buttonSpinner ? (
                    <>
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      &nbsp;Processing...
                    </>
                  ) : (
                    "Search"
                  )}
                </Button>
              </Col>
            </Row>
          </Jumbotron>
        </Form>

        {surnamesSelection ? (
          <Row>
            <Col md="4" xs="12">
              <FormGroup>
                <Label
                  htmlFor="surnames"
                  className="text-secondary font-weight-bolder"
                >
                  Select to search by reference number:
                </Label>
                <Controller
                  control={control}
                  name="surnames"
                  rules={{ required: false }}
                  invalid={errors.surnames ? true : false}
                  defaultValue=""
                  render={({ onChange, value, onBlur, name }) => (
                    <Select
                      options={surnames}
                      onChange={handleChangeSurname}
                      defaultValue=""
                    />
                  )}
                />
              </FormGroup>
            </Col>
          </Row>
        ) : (
          ""
        )}
        <Row className="mb-5 justify-content-between pt-5">
          <Col md="12" xs="12">
            <div className="border border-2 border-warning py-2 px-3">
              <div className="w-100 px-2" style={{ marginTop: "-1.4rem" }}>
                <Label
                  className="text-danger-edit text-center font-weight-bolder mb-1 px-3"
                  style={{ backgroundColor: "white" }}
                >
                  Billings
                </Label>
              </div>
              <BootstrapTable
                wrapperClasses="table-responsive my-4 text-center"
                bootstrap4
                striped
                keyField="id"
                data={billings}
                columns={columns}
                loading={loading}
                expandRow={expandRow}
                classes={isMobile ? "mobileTable" : "desktopTable"}
                pagination={paginationFactory()}
                overlay={overlayFactory({
                  spinner: true,
                  styles: {
                    overlay: (base) => ({
                      ...base,
                      background: "rgba(0, 0, 0, 0.5)",
                    }),
                  },
                })}
                noDataIndication={() => <NoDataIndication />}
              />
            </div>
          </Col>
        </Row>
        <Row className="mb-5 justify-content-between">
          <Col md="4" xs="12">
            <div
              className="border border-2 border-warning py-2 mb-5 px-3"
              style={{ minHeight: "50vh" }}
            >
              <div className="w-100 px-2" style={{ marginTop: "-1.4rem" }}>
                <Label
                  className="text-danger-edit text-center font-weight-bolder mb-1 px-3"
                  style={{ backgroundColor: "white" }}
                >
                  Student Info
                </Label>
              </div>
              <Row className="py-4">
                <Col md="12" xs="12" className="mb-3">
                  <FormGroup>
                    <h5>
                      <strong>Reference No: </strong>
                      {paymentApplication.referenceNo}
                    </h5>
                  </FormGroup>
                </Col>
                <Col md="12" xs="12" className="mb-3">
                  <FormGroup>
                    <h5>
                      <strong>Last Name: </strong>
                      {paymentApplication.lastName}
                    </h5>
                  </FormGroup>
                </Col>
                <Col md="12" xs="12" className="mb-3">
                  <FormGroup>
                    <h5>
                      <strong>Given Name: </strong>
                      {paymentApplication.givenName}
                    </h5>
                  </FormGroup>
                </Col>
                <Col md="12" xs="12" className="mb-3">
                  <FormGroup>
                    <h5>
                      <strong>Middle Name: </strong>
                      {paymentApplication.middleName}
                    </h5>
                  </FormGroup>
                </Col>
                <Col md="12" xs="12" className="mb-3">
                  <FormGroup>
                    <h5>
                      <strong>Course: </strong>
                      {paymentApplication.course}
                    </h5>
                  </FormGroup>
                </Col>
                <Col md="12" xs="12" className="mb-3">
                  <FormGroup>
                    <h5>
                      <strong>Grade Level: </strong>
                      {paymentApplication.gradeLevel}
                    </h5>
                  </FormGroup>
                </Col>
                <Col md="12" xs="12" className="mb-5">
                  <FormGroup>
                    <h5>
                      <strong>Application Status: </strong>
                      {paymentApplication.application_status
                        ? paymentApplication.application_status
                            .charAt(0)
                            .toUpperCase() +
                          paymentApplication.application_status.slice(1)
                        : "-"}
                    </h5>
                  </FormGroup>
                </Col>
                {paymentApplication.cashier_status === 0 ? (
                  <Col md="12" xs="12" className="mb-3">
                    <FormGroup>
                      <Button color="success" onClick={toggleEnrollStudent}>
                        Enroll Student
                      </Button>
                    </FormGroup>
                  </Col>
                ) : paymentApplication.cashier_status === 1 ? (
                  <Col md="12" xs="12" className="mb-3">
                    <FormGroup>
                      <Button
                        className="btn btn-warning"
                        onClick={toggleRevertEnrollStudent}
                      >
                        Revert Enrollment
                      </Button>
                    </FormGroup>
                  </Col>
                ) : (
                  ""
                )}
              </Row>
            </div>
          </Col>
          <Col md="8" xs="12">
            <div
              className="border border-2 border-warning py-2 mb-5 px-3"
              style={{ minHeight: "50vh" }}
            >
              <div className="w-100 px-2" style={{ marginTop: "-1.4rem" }}>
                <Label
                  className="text-danger-edit text-center font-weight-bolder mb-1 px-3"
                  style={{ backgroundColor: "white" }}
                >
                  Scholarship and ESC
                </Label>
              </div>
              <Row className="py-4">
                <Col md="12" xs="12" className="mb-3">
                  <FormGroup>
                    <h5>
                      <strong>Approved Scholarship: </strong>
                      {paymentApplication.scholarship
                        ? paymentApplication.scholarship.name
                        : "N/A"}
                    </h5>
                  </FormGroup>
                </Col>
                <Col md="12" xs="12" className="mb-3">
                  <FormGroup>
                    <h5>
                      <strong>Scholarship Discount: </strong>
                      {paymentApplication.scholarship
                        ? paymentApplication.scholarship.discount_percentage +
                          "%"
                        : ""}
                    </h5>
                  </FormGroup>
                </Col>
                <Col md="12" xs="12" className="mb-3">
                  <FormGroup>
                    <h5>
                      <strong>ESC Approved: </strong>
                      {paymentApplication.approve_esc
                        ? "Approved"
                        : "Not Approve"}
                    </h5>
                  </FormGroup>
                </Col>
                <Col md="12" xs="12" className="mb-3">
                  <FormGroup>
                    <h5>
                      <strong>ESC Type: </strong>
                      {paymentApplication.approve_esc &&
                      paymentApplication.esc_type == "1"
                        ? "Private"
                        : paymentApplication.approve_esc &&
                          paymentApplication.esc_type == "0"
                        ? "Public"
                        : "N/A"}
                    </h5>
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default CashierView;
