import React, { useEffect, useState } from 'react';
import {
    Row,
    Col
} from 'reactstrap';

import landingPageLogo from '../../../../assets/img/landingPageLogo.png';
import sapcYellow from '../../../../assets/img/SAPC-Logo-yellow.png';
import flatEllipse from '../../../../assets/img/flat-ellipse.png';
import facebookIcon from '../../../../assets/img/facebook-icon.png';
import twitterIcon from '../../../../assets/img/twitter-icon.png';
import {
    isChrome,
    isDesktop,
    isMobile,
    isEdge
} from "react-device-detect";
import { dynamicFooterActions } from '../../../../services/DynamicFooterServices';

const Footer = () => {

    const[loading, setLoading] = useState(false)
    const [footer, setFooter] = useState({
        school_name: "SAN ANTONIO DE PADUA COLLEGE",
        sub_school_name: "Foundation of Pila, Laguna, Inc.",
        inquiries_title: "For inquiries and information contact",
        inquiries_mobile_no: "",
        inquiries_mobile_no_2: "",
        inquiries_mobile_no_3: "",
        inquiries_email: "info@sapc.edu.ph",
        inquiries_phone_no: "(049)559-0501",
        school_registrar_title: "School registrar",
        school_registrar_mobile_no: "",
        school_registrar_mobile_no_2: "",
        school_registrar_mobile_no_3: "",
        school_registrar_email: "registrar@sapc.edu.ph",
        school_registrar_phone_no: "(049)566-7258",
        billing_title: "Billing and payments",
        billing_mobile_no: "0917-703-9130",
        billing_mobile_no_2: "",
        billing_mobile_no_3: "",
        billing_email: "cashier@sapc.edu.ph",
        billing_phone_no: "(049)559-0501",
        security_title: "Security services",
        security_mobile_no: "0917-144-6920 (calls only)",
        security_mobile_no_2: "",
        security_mobile_no_3: "",
        security_email: "",
        security_phone_no: "",
        admission_title: "Admissions",
        admission_mobile_no: "0920-903-1564",
        admission_mobile_no_2: "0956-816-3185",
        admission_mobile_no_3: "",
        admission_email: "admissions@sapc.edu.ph",
        admission_phone_no: "(049)831-7954",
        mailing_address_title: "Mailing address",
        mailing_address_address: "National Highway, Barangay Santa Clara Sur, Pila, Laguna, Philippines 4010",
        social_media_link_facebook: "https://www.facebook.com/san.antonio.de.padua.college",
        social_media_link_twitter: "https://twitter.com/sapcfoundation",
        sapc_domain: "sapc.edu.ph",
        sapc_description: "All website content is owned and copyrighted by San Antonio De Padua College Foundation, Inc. and/or its respective source unless otherwise stated."
    })

    useEffect(() => {
        setLoading(true)
        dynamicFooterActions.getCurrentTemplateFrontPage(
            setFooter,
            footer,
            setLoading,
        );
    }, []);

    return (
        <div>
            {/* {isEdge, isChrome ?
                <img src={landingPageLogo} className="footerLogoSAPC" style={{ width: "15rem" }} style={{ width: "265px", marginLeft: "-12px" }} />
                :
                <img src={landingPageLogo} className="footerLogoSAPC" style={{ width: "15rem" }} style={{ width: "260px", marginLeft: "-12px" }} />
            } */}
            { isDesktop ? <img src={landingPageLogo} className="footerLogoSAPC" style={{ width: "15rem" }} style={{ width: "265px", marginLeft: "-12px" }} /> : "" }
            <Row className={isDesktop ? "mx-0 px-0 bg-danger-edit h-100 justify-content-center d-flex align-items-center text-center py-5 px-3 footerClassTrans" : "mx-0 px-0 bg-danger-edit h-100 justify-content-center d-flex align-items-center text-center py-5 px-3 footerClass"}>

                {isMobile ? <img src={sapcYellow} className={isMobile ? "footerLogo pb-4" : "footerLogo"} style={isMobile ? { width: "100px" } : { width: "150px" }} /> : ""}

                <Col lg="3" md="3" xs="12" className={isDesktop ? "ml-5" : "pb-5"}>
                    <div className="d-flex flex-column align-items-center justify-content-center">
                        <p className="footer-sapc mb-0 text-light">{footer.school_name}</p>
                        <img src={flatEllipse} className="w-100" />
                        <p className="footer-foundation text-light my-0">{footer.sub_school_name}</p>
                    </div>
                </Col>

                <Col lg="2" md="2" xs="12" className={isDesktop ? "pb-3" : "pb-5"}>
                    <span className="footer-foundation text-light my-0 font-weight-bolder"><strong>GET IN TOUCH</strong></span>
                    <br />
                    <a href={footer.social_media_link_facebook} target="_blank"><img src={facebookIcon} style={{ width: "40px" }} className="my-1 mx-1 zooming-transition" /></a>
                    <a href={footer.social_media_link_twitter} target="_blank"><img src={twitterIcon} style={{ width: "40px" }} className="my-1 mx-1 zooming-transition" /></a>
                </Col>

                <Col lg="4" md="4" xs="12" className={isDesktop ? "text-left py-2" : "text-center py-2"}>
                    <span className="footer-foundation text-warning-edit my-0"><strong>&#169; {footer.sapc_domain}</strong></span>
                    <br />
                    <span className="footer-foundation text-light my-0">{footer.sapc_description}</span>
                </Col>
            </Row>

            <Row className="" className={isDesktop ? "mx-0 px-0 bg-danger-edit h-100 justify-content-center d-flex footerClassTrans" : "mx-0 px-0 bg-danger-edit h-100 justify-content-center d-flex footerClass"}>

                <Col lg="2" md="2" xs="12" className={isDesktop ? "pb-3" : "pb-3"}>
                    <span className="footer-foundation text-light my-0 font-weight-bolder "><strong>CONTACT US:</strong></span>
                    <br /><br />
                    <span className="footer-foundation text-light my-0">
                        <strong>{footer.inquiries_title}:</strong>
                        {footer.inquiries_email ? <p className='mb-0'>{footer.inquiries_email}</p> : ""}
                        {footer.inquiries_mobile_no ? <p className='mb-0'>{footer.inquiries_mobile_no}</p> : ""}
                        {footer.inquiries_mobile_no_2 ? <p className='mb-0'>{footer.inquiries_mobile_no_2}</p> : ""}
                        {footer.inquiries_mobile_no_3 ? <p className='mb-0'>{footer.inquiries_mobile_no_3}</p> : ""}
                        {footer.inquiries_phone_no ? <p>{footer.inquiries_phone_no}</p> : ""}

                        <strong>{footer.school_registrar_title}:</strong>
                        {footer.school_registrar_email ? <p className='mb-0'>{footer.school_registrar_email}</p> : ""}
                        {footer.school_registrar_mobile_no ? <p className='mb-0'>{footer.school_registrar_mobile_no}</p> : ""}
                        {footer.school_registrar_mobile_no_2 ? <p className='mb-0'>{footer.school_registrar_mobile_no_2}</p> : ""}
                        {footer.school_registrar_mobile_no_3 ? <p className='mb-0'>{footer.school_registrar_mobile_no_3}</p> : ""}
                        {footer.school_registrar_phone_no ? <p>{footer.school_registrar_phone_no}</p> : ""}
                    </span>
                </Col>

                <Col lg="2" md="2" xs="12" className={isDesktop ? "pb-3" : "pb-3"}>
                    <br /><br />
                    <span className="footer-foundation text-light my-0">
                        <strong>{footer.billing_title}:</strong>
                        {footer.billing_email ? <p className='mb-0'>{footer.billing_email}</p> : ""}
                        {footer.billing_mobile_no ? <p className='mb-0'>{footer.billing_mobile_no}</p> : ""}
                        {footer.billing_mobile_no_2 ? <p className='mb-0'>{footer.billing_mobile_no_2}</p> : ""}
                        {footer.billing_mobile_no_3 ? <p className='mb-0'>{footer.billing_mobile_no_3}</p> : ""}
                        {footer.billing_phone_no ? <p>{footer.billing_phone_no}</p> : ""}

                        <strong>{footer.security_title}:</strong>
                        {footer.security_email ? <p className='mb-0'>{footer.security_email}</p> : ""}
                        {footer.security_mobile_no ? <p className='mb-0'>{footer.security_mobile_no}</p> : ""}
                        {footer.security_mobile_no_2 ? <p className='mb-0'>{footer.security_mobile_no_2}</p> : ""}
                        {footer.security_mobile_no_3 ? <p className='mb-0'>{footer.security_mobile_no_3}</p> : ""}
                        {footer.security_phone_no ? <p>{footer.security_phone_no}</p> : ""}
                    </span>
                </Col>

                <Col lg="2" md="2" xs="12" className={isDesktop ? "pb-3" : "pb-3"}>
                    <br /><br />
                    <span className="footer-foundation text-light my-0">
                        <strong>{footer.admission_title}:</strong>
                        {footer.admission_email ? <p className='mb-0'>{footer.admission_email}</p> : ""}
                        {footer.admission_mobile_no ? <p className='mb-0'>{footer.admission_mobile_no}</p> : ""}
                        {footer.admission_mobile_no_2 ? <p className='mb-0'>{footer.admission_mobile_no_2}</p> : ""}
                        {footer.admission_mobile_no_3 ? <p className='mb-0'>{footer.admission_mobile_no_3}</p> : ""}
                        {footer.admission_phone_no ? <p>{footer.admission_phone_no}</p> : ""}
                    </span>
                </Col>

                <Col lg="2" md="2" xs="12" className={isDesktop ? "pb-3" : "pb-5"}>
                    <br /><br />
                    <span className="footer-foundation text-light my-0">
                        {footer.mailing_address_title ? <strong>{footer.mailing_address_title}</strong> : ""}
                        {footer.mailing_address_address ? <p>{footer.mailing_address_address}</p> : ""}
                    </span>
                </Col>
            </Row>
        </div>
    );
}

export default Footer;