import React, { Fragment, useState } from "react";
import { isDesktop, isIOS, isMobile } from "react-device-detect";
import {
  Form,
  Button,
  Card,
  CardBody,
  CardDeck,
  CardFooter,
  CardImg,
  CardText,
  Col,
  Container,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import basic1 from "../../../assets/img/basic-1.jpg";
import basicGif from "../../../assets/img/basic-ed-gif.gif";
import tertiary1 from "../../../assets/img/tertiary-1.jpg";
import tertiaryGif from "../../../assets/img/tertiary-ed-gif.gif";
import tesda1 from "../../../assets/img/tesda-1.jpg";
import tesdaGif from "../../../assets/img/technical-ed-gif.gif";
import * as FaIcons from "react-icons/fa";
import { notifyError } from "../../../services/Toaster";
import { sectionActions } from "../../../services/SectionServices";
import * as AiIcons from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const HomepageCourses = ({ homepage, authenticated }) => {
  const [seeMore, setSeeMore] = useState(false);
  const [courseType, setCourseType] = useState("");
  const navigate = useNavigate();

  let blankTempCourses = {
    title: "",
    data: [],
  };

  const blankData = {
    title: "",
    gif: "",
    gifFile: "",
    image: "",
    imageFile: "",
    videoUrl: "",
    data: blankTempCourses,
    index: "",
  };

  const [tempData, setTempData] = useState(blankData);
  const [vidLink, setVidLink] = useState("");
  const [basicModal, setBasicModal] = useState(false);

  const basicToggle = (url) => {
    setVidLink("");
    if (!basicModal) {
      setVidLink(url);
      setBasicModal(!basicModal);
    } else {
      setBasicModal(!basicModal);
    }
  };

  const toggleSeeMore = () => {
    if (!seeMore) {
      setSeeMore(!seeMore);
    } else {
      setSeeMore(!seeMore);
    }
  };

  return (
    <Container className="mb-5 mt-5">
      <h3
        className={
          isDesktop
            ? "font-weight-bolder text-danger-edit mb-5"
            : "font-weight-bolder text-danger-edit mb-5 text-center"
        }
      >
        COURSES OFFERED AND TUTORIAL
      </h3>
      <CardDeck>
        <Card className="shadow">
          <CardImg
            onClick={() => basicToggle(homepage.courses.basic.videoUrl)}
            style={{ height: "13rem", cursor: "pointer" }}
            top
            width="100%"
            src={homepage.courses.basic.image}
            onMouseOver={(e) =>
              (e.currentTarget.src = homepage.courses.basic.gif)
            }
            onMouseOut={(e) =>
              (e.currentTarget.src = homepage.courses.basic.image)
            }
            alt="Card image cap"
          />
          <CardBody
            className="text-center d-flex flex-column align-items-center"
            style={{
              height: seeMore ? "500px" : "300px",
              overflowY: seeMore ? "scroll" : "hidden",
            }}
          >
            <div>
              {homepage.courses.basic.data.length >= 1
                ? homepage.courses.basic.data.map((dt, i) => {
                    return (
                      <div key={i} className="mb-4">
                        <div className="mb-3">
                          <CardText className="text-secondary-edit font-weight-bolder">
                            <i>{dt.title}</i>
                          </CardText>
                        </div>
                        {dt.data.length >= 1
                          ? dt.data.map((d, e) => {
                              return (
                                <div key={e}>
                                  <CardText className="mb-0 text-secondary-edit">
                                    <i>{d.title}</i>
                                  </CardText>
                                  <CardText className="mb-1 text-secondary-edit">
                                    <i>{d.subtitle}</i>
                                  </CardText>
                                </div>
                              );
                            })
                          : ""}
                      </div>
                    );
                  })
                : ""}
            </div>
          </CardBody>
          <CardFooter
            className="bg-white py-4 text-center"
            style={{ borderTop: 0 }}
          >
            <p
              className="mb-0 font-weight-bolder text-secondary"
              style={{ cursor: "pointer" }}
              onClick={toggleSeeMore}
            >
              <i>See More</i>
            </p>
            {authenticated ? (
              <Button
                outline
                className="rounded-pill mt-4 font-weight-bolder button-transition mt-auto w-50"
                color="warning"
                onClick={() => navigate("/portal")}
              >
                APPLY NOW
              </Button>
            ) : (
              <Button
                outline
                className="rounded-pill mt-4 font-weight-bolder button-transition mt-auto w-50"
                color="warning"
                onClick={() => navigate("/login")}
              >
                APPLY NOW
              </Button>
            )}
          </CardFooter>
        </Card>

        <Card className="shadow">
          <CardImg
            onClick={() => basicToggle(homepage.courses.tertiary.videoUrl)}
            style={{ height: "13rem", cursor: "pointer" }}
            top
            width="100%"
            src={homepage.courses.tertiary.image}
            onMouseOver={(e) =>
              (e.currentTarget.src = homepage.courses.tertiary.gif)
            }
            onMouseOut={(e) =>
              (e.currentTarget.src = homepage.courses.tertiary.image)
            }
            alt="Card image cap"
          />
          <CardBody
            className="text-center d-flex flex-column align-items-center"
            style={{
              height: seeMore ? "500px" : "300px",
              overflowY: seeMore ? "scroll" : "hidden",
            }}
          >
            <div>
              {homepage.courses.tertiary.data.length >= 1
                ? homepage.courses.tertiary.data.map((dt, i) => {
                    return (
                      <div key={i} className="mb-5">
                        <div className="mb-3">
                          <CardText className="text-secondary-edit font-weight-bolder">
                            <i>{dt.title}</i>
                          </CardText>
                        </div>
                        {dt.data.length >= 1
                          ? dt.data.map((d, e) => {
                              return (
                                <div key={e}>
                                  <CardText className="mb-0 text-secondary-edit">
                                    <i>{d.title}</i>
                                  </CardText>
                                  <CardText className="mb-1 text-secondary-edit">
                                    <i>{d.subtitle}</i>
                                  </CardText>
                                </div>
                              );
                            })
                          : ""}
                      </div>
                    );
                  })
                : ""}
            </div>
          </CardBody>
          <CardFooter
            className="bg-white py-4 text-center"
            style={{ borderTop: 0 }}
          >
            <p
              className="mb-0 font-weight-bolder text-secondary"
              style={{ cursor: "pointer" }}
              onClick={toggleSeeMore}
            >
              <i>See More</i>
            </p>
            {authenticated ? (
              <Button
                outline
                className="rounded-pill mt-4 font-weight-bolder button-transition mt-auto w-50"
                color="warning"
                onClick={() => navigate("/portal")}
              >
                APPLY NOW
              </Button>
            ) : (
              <Button
                outline
                className="rounded-pill mt-4 font-weight-bolder button-transition mt-auto w-50"
                color="warning"
                onClick={() => navigate("/login")}
              >
                APPLY NOW
              </Button>
            )}
          </CardFooter>
        </Card>

        <Card className="shadow">
          <CardImg
            onClick={() => basicToggle(homepage.courses.technical.videoUrl)}
            style={{ height: "13rem", cursor: "pointer" }}
            top
            width="100%"
            src={homepage.courses.technical.image}
            onMouseOver={(e) =>
              (e.currentTarget.src = homepage.courses.technical.gif)
            }
            onMouseOut={(e) =>
              (e.currentTarget.src = homepage.courses.technical.image)
            }
            alt="Card image cap"
          />
          <CardBody
            className="text-center d-flex flex-column align-items-center"
            style={{
              height: seeMore ? "500px" : "300px",
              overflowY: seeMore ? "scroll" : "hidden",
            }}
          >
            <div>
              {homepage.courses.technical.data.length >= 1
                ? homepage.courses.technical.data.map((dt, i) => {
                    return (
                      <div key={i} className="mb-5">
                        <div className="mb-3">
                          <CardText className="text-secondary-edit font-weight-bolder">
                            <i>{dt.title}</i>
                          </CardText>
                        </div>
                        {dt.data.length >= 1
                          ? dt.data.map((d, e) => {
                              return (
                                <div key={e}>
                                  <CardText className="mb-0 text-secondary-edit">
                                    <i>{d.title}</i>
                                  </CardText>
                                  <CardText className="mb-1 text-secondary-edit">
                                    <i>{d.subtitle}</i>
                                  </CardText>
                                </div>
                              );
                            })
                          : ""}
                      </div>
                    );
                  })
                : ""}
            </div>
          </CardBody>
          <CardFooter
            className="bg-white py-4 text-center"
            style={{ borderTop: 0 }}
          >
            <p
              className="mb-0 font-weight-bolder text-secondary"
              style={{ cursor: "pointer" }}
              onClick={toggleSeeMore}
            >
              <i>See More</i>
            </p>
            {authenticated ? (
              <Button
                outline
                className="rounded-pill mt-4 font-weight-bolder button-transition mt-auto w-50"
                color="warning"
                onClick={() => navigate("/portal")}
              >
                APPLY NOW
              </Button>
            ) : (
              <Button
                outline
                className="rounded-pill mt-4 font-weight-bolder button-transition mt-auto w-50"
                color="warning"
                onClick={() => navigate("/login")}
              >
                APPLY NOW
              </Button>
            )}
          </CardFooter>
        </Card>
      </CardDeck>
      <Modal
        isOpen={basicModal}
        className={
          isIOS
            ? isMobile
              ? "modal-tutorial-center-ios"
              : "landingPage"
            : "landingPage modal-tutorial-center"
        }
        contentClassName={
          isDesktop
            ? "modal-tutorial"
            : "modal-tutorial-center-mobile align-items-center justify-content-center"
        }
        backdrop="static"
      >
        <ModalBody>
          <div>
            <p className="text-center">
              <iframe
                className={isMobile ? "modal-tutorial-mobile" : "video-block"}
                src={vidLink}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
              <Row className="justify-content-center align-items-center">
                <Button
                  className="bg-warning-edit text-white font-weight-bolder border-0 w-25 button-transition mt-3"
                  onClick={() => setBasicModal(!basicModal)}
                >
                  EXIT
                </Button>
              </Row>
            </p>
          </div>
        </ModalBody>
      </Modal>
    </Container>
  );
};

export default HomepageCourses;
