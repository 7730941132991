import React, { useState } from "react";
import {
  Button,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { paymentActions } from "../../../services/PaymentServices";

const RevertEnrollStudentConfirm = ({
    revertEnrollConfirmModal,
    setRevertEnrollConfirmModal,
    toggleRevertEnrollStudent,
    applicationId,
    setPaymentApplication,
    blankPaymentApplication,
    setBillings,
}) => {

const [buttonSpinner, setButtonSpinner] = useState(false)
  const submit = () => {
    setButtonSpinner(!buttonSpinner)
    const dataSubmit = {
        application_id: applicationId,
        status: 'certified',
    };

    paymentActions.updateToEnrollStatus(dataSubmit, setButtonSpinner, setRevertEnrollConfirmModal, setPaymentApplication, blankPaymentApplication, setBillings);
  };

  return (
    <Modal isOpen={revertEnrollConfirmModal} toggle={toggleRevertEnrollStudent}>
        <ModalHeader>{" "}</ModalHeader>
        <ModalBody className="text-center">
          <h5>Confirm Revert Student Enrollment?</h5>
          <small>Note: Student application will revert back to Certified</small>
        </ModalBody>
        <ModalFooter>
        <Button
                color="warning"
                className="text-white"
                disabled={buttonSpinner ? true : false}
                onClick={submit}
              >
                {buttonSpinner ? (
                  <>
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    &nbsp;Processing...
                  </>
                ) : (
                  "Confirm"
                )}
              </Button>
          <Button color="secondary" onClick={toggleRevertEnrollStudent}>
            Cancel
          </Button>
        </ModalFooter>
    </Modal>
  );
};

export default RevertEnrollStudentConfirm;
