import React, { useEffect, useState } from "react";
import { teacherSubjectsActions } from "../../../../services/TeacherSubjectsServices";
import {
  Button,
  Col,
  Container,
  InputGroup,
  InputGroupAddon,
  Row,
  UncontrolledTooltip,
  Input,
} from "reactstrap";
import AddorEditModal from "./AddorEditModal";
import { isBrowser, isDesktop, isMobile } from "react-device-detect";
import Select from "react-select";
import Add from "../../../../assets/img/ADMISSION.png";
import View from "../../../../assets/img/view.png";
import Edit from "../../../../assets/img/edit.png";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import overlayFactory from "react-bootstrap-table2-overlay";
import { educationActions } from "../../../../services/EducationSelectionService";
import states from "../../States/States";

const TeacherSubjects = () => {
  const [data, setData] = useState([]);
  const [educationTypes, setEducationTypes] = useState([]);
  const [gradeLevels, setGradeLevels] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [addOrEditModal, setAddOrEditModal] = useState(false);
  const [action, setAction] = useState("");
  const [updateTable, setUpdateTable] = useState(false);
  const [teacherSubjects, setTeacherSubject] = useState(
    states.blankTeacherSubjects
  );
  const [educationTypeSelection, setEducationTypeSelection] = useState([]);
  const [gradeTypeSelection, setGradeTypeSelection] = useState([]);
  const [gradeLevelSelection, setGradeLevelSelection] = useState([]);
  const [teachersSelection, setTeachersSelection] = useState([]);
  const [sectionSelection, setSectionSelection] = useState([]);
  const [subjectSelection, setSubjectSelection] = useState([]);
  const [courses, setCourses] = useState([]);
  const [coursesSelection, setCoursesSelection] = useState([]);

  useEffect(() => {
    educationActions.getApplications(
      setEducationTypes,
      null,
      null,
      setGradeLevels,
      null,
      setCourses,
      null,
      null
    );
  }, []);

  useEffect(() => {
    setLoading(true);
    teacherSubjectsActions.getTeachers(
      setData,
      setLoading,
      setTeachersSelection,
      setSectionSelection,
      setSubjectSelection
    );
  }, [updateTable]);

  useEffect(() => {
    if (educationTypes.length >= 1) {
      let educationTypesArr = educationTypes.map((type) => {
        return { value: type.id, label: type.name };
      });
      setEducationTypeSelection(educationTypesArr);
    }
  }, [educationTypes]);

  useEffect(() => {
    if (courses.length >= 1) {
      let studentCourses = courses.map((course) => {
        return {
          value: course.id,
          label: course.name,
          grade_type_id: course.grade_type_id,
        };
      });
      setCoursesSelection(studentCourses);
    }
  }, [courses]);

  useEffect(() => {
    if (gradeLevels.length >= 1) {
      let gradeLevelsArr = gradeLevels.map((type) => {
        return {
          value: type.id,
          label: type.name,
          grade_type_id: type.grade_type_id.id,
          education_type_id: type.grade_type_id.education_type_id.id,
        };
      });
      setGradeLevelSelection(gradeLevelsArr);
    }
  }, [gradeLevels]);

  const columns = [
    {
      dataField: "role",
      text: "Role",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "email_address",
      text: "Email",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "last_name",
      text: "Last Name",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "given_name",
      text: "Given Name",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "is_verify",
      text: "Email Verify",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
      formatter: (cell) => {
        return cell ? "Yes" : "No";
      },
    },
    {
      dataField: "status",
      text: "Status",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
      formatter: (cell) => {
        return cell ? "Active" : "InActive";
      },
    },
    {
      dataField: "created_at",
      text: "Date Created",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "id",
      text: "Action",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
      formatter: (cell, row) => {
        return (
          <div
            className="px-2 justify-content-center d-flex"
            style={{ minWidth: "50px" }}
          >
            <Button
              id={"upload-btn" + cell}
              onClick={() => toggleAddOrEdit(row, "edit")}
              className="btn-warning mr-2"
            >
              <img src={Edit} style={{ height: "1.5rem", objectFit: "fill" }} />
            </Button>
            <UncontrolledTooltip placement="top" target={"upload-btn" + cell}>
              Edit
            </UncontrolledTooltip>
          </div>
        );
      },
    },
  ];

  const changeQuery = (e) => {
    setSearchText(e.target.value);
  };

  const toggleAddOrEdit = (details, action) => {
    setTeacherSubject(states.blankTeacherSubjects);
    setAction("");
    if (!addOrEditModal) {
      setAction(action);
      if (action == "edit") {
        setTeacherSubject(details);
      }
      setAddOrEditModal(!addOrEditModal);
    } else {
      setAddOrEditModal(!addOrEditModal);
    }
  };

  let filteredData = data.filter((d) => {
    return d.last_name.toLowerCase().includes(searchText.toLowerCase());
  });

  const NoDataIndication = () => <div>No Data</div>;
  return (
    <div className="p-4">
      {addOrEditModal ? (
        <AddorEditModal
          toggleAddOrEdit={toggleAddOrEdit}
          addOrEditModal={addOrEditModal}
          setAddOrEditModal={setAddOrEditModal}
          action={action}
          teacherSubjects={teacherSubjects}
          educationTypeSelection={educationTypeSelection}
          gradeLevelSelection={gradeLevelSelection}
          sectionSelection={sectionSelection}
          subjectSelection={subjectSelection}
          setUpdateTable={setUpdateTable}
          updateTable={updateTable}
          coursesSelection={coursesSelection}
        />
      ) : (
        ""
      )}
      <Row>
        <Col md="4" xs="12" className="ml-auto mb-2">
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <Button type="button" color="primary" id="search-button">
                <i className="fa fa-search"></i>
              </Button>
            </InputGroupAddon>
            <Input
              type="text"
              id="search-text"
              name="search-text"
              placeholder="Search"
              onChange={changeQuery}
            />
          </InputGroup>
          <p>
            <small className="font-weight-bolder">
              <i className="fa fa-lightbulb-o"></i>&nbsp;Search by Name
            </small>
          </p>
        </Col>
      </Row>
      <BootstrapTable
        wrapperClasses="table-responsive mb-3 text-center"
        bootstrap4
        striped
        keyField="id"
        data={filteredData}
        columns={columns}
        loading={loading}
        classes={isMobile ? "mobileTable" : "desktopTable"}
        pagination={paginationFactory()}
        overlay={overlayFactory({
          spinner: true,
          styles: {
            overlay: (base) => ({
              ...base,
              background: "rgba(0, 0, 0, 0.5)",
            }),
          },
        })}
        noDataIndication={() => <NoDataIndication />}
      />
    </div>
  );
};

export default TeacherSubjects;
