import React from 'react';
import { isBrowser, isDesktop, isMobile, isTablet } from 'react-device-detect';
import { Step, Stepper } from 'react-form-stepper';
import Subjects from '../Forms/Subjects';
import BasicEducationForm from '../Forms/BasicEducationForm.js'
import TertiaryEducationForm from '../Forms/TertiaryEducationForm.js'
import TesdatEducationForm from '../Forms/TesdaEducationForm'
import ScholarshipForm from '../Forms/ScholarshipForm.js'
import PaymentForm from '../Forms/PaymentForm.js';
import AssessmentForm from '../Forms/AssessmentForm.js';
import ReviewSubmit from '../Forms/ReviewSubmit.js';
import Loading from '../Forms/Loading.js'
import TertiaryAssessmentForm from '../Forms/TertiaryAssessmentForm.js';
import { Col } from 'reactstrap';
import states from '../States/States';
import ApplicationView from './ApplicationView';
import Notice from './Notice';

const StepperComponent = ({
    educationSelected,
    selectedEducationType,
    handleCancel,
    setEducationSelected,
    currentStep,
    setCurrentStep,
    setLoading,
    basicForm,
    setBasicForm,
    paymentForm,
    setPaymentForm,
    escDiscounts,
    scholarshipForm,
    setScholarShipForm,
    newTuitionFee,
    setSchoolFees,
    selectOptions,
    schoolFees,
    loading,
    educationTypes,
    subjects,
    subjectForm,
    setSubjectForm,
    selectedSubjects,
    setSelectedSubjects,
    setSubjects,
    setTertiaryTotalTuition,
    allSubjects,
    setTesdaEducationSelected,
    tesdaForm,
    setTesdaForm,
    currentPage,
    gradeTypes
}) => {

    const role = localStorage.getItem('role');
    const studentType = localStorage.getItem('admissionAnswers') ? JSON.parse(localStorage.getItem('admissionAnswers')).student_type : null;

    return (
        <>
            {currentPage == "admission" ?
                <Col md={isDesktop ? "10" : "12"} sm="12" xs="12" style={{ borderLeft: isBrowser ? "2px solid yellow" : "" }}>
                    {selectedEducationType == "Basic Education" ?
                        role != "Guest" ?
                            <>
                                <Stepper activeStep={currentStep} className="w-100" style={{ overflowX: "auto", marginTop: isMobile || isTablet ? "4rem" : "" }}>
                                    <Step label="Form" />
                                    <Step label="Scholarship" />
                                    <Step label="Assessment" />
                                    <Step label="Review and Submit" />
                                </Stepper>

                                {currentStep == 0 ?
                                    <BasicEducationForm handleCancel={handleCancel} setEducationSelected={setEducationSelected} currentStep={currentStep} setCurrentStep={setCurrentStep} setLoading={setLoading} basicForm={basicForm} setBasicForm={setBasicForm} paymentForm={paymentForm} setPaymentForm={setPaymentForm} escDiscounts={escDiscounts} gradeTypes={gradeTypes} />
                                    :
                                    currentStep == 1 ?
                                        <ScholarshipForm handleCancel={handleCancel} setEducationSelected={setEducationSelected} currentStep={currentStep} setCurrentStep={setCurrentStep} setLoading={setLoading} scholarshipForm={scholarshipForm} setScholarShipForm={setScholarShipForm} />
                                        :
                                        currentStep == 2 ?
                                            <AssessmentForm handleCancel={handleCancel} currentStep={currentStep} setCurrentStep={setCurrentStep} paymentForm={paymentForm} setPaymentForm={setPaymentForm} newTuitionFee={newTuitionFee} setSchoolFees={setSchoolFees} selectOptions={selectOptions} schoolFees={schoolFees} basicFormEsc={basicForm.is_esc} basicForm={basicForm} />
                                            :
                                            <ReviewSubmit handleCancel={handleCancel} currentStep={currentStep} setCurrentStep={setCurrentStep} loading={loading} setLoading={setLoading} basicForm={basicForm} scholarshipForm={scholarshipForm} paymentForm={paymentForm} educationTypes={educationTypes} setEducationSelected={setEducationSelected} setBasicForm={setBasicForm} setPaymentForm={setPaymentForm} setScholarShipForm={setScholarShipForm} blankBasicEducationForm={states.blankBasicEducationForm} blankScholarshipForm={states.blankScholarshipForm} blankPaymentForm={states.blankPaymentForm} subjects={subjects} subjectForm={subjectForm} setSubjectForm={setSubjectForm} selectedSubjects={selectedSubjects} gradeTypes={gradeTypes} />
                                }
                            </>
                            :

                            <>
                                <Stepper activeStep={currentStep} className="w-100" style={{ overflowX: "auto", marginTop: isMobile || isTablet ? "4rem" : "" }}>
                                    <Step label="Form" />
                                    <Step label="Notice" />
                                    <Step label="Review and Submit" />
                                </Stepper>

                                {currentStep == 0 ?
                                    <BasicEducationForm handleCancel={handleCancel} setEducationSelected={setEducationSelected} currentStep={currentStep} setCurrentStep={setCurrentStep} setLoading={setLoading} basicForm={basicForm} setBasicForm={setBasicForm} paymentForm={paymentForm} setPaymentForm={setPaymentForm} escDiscounts={escDiscounts} gradeTypes={gradeTypes} />
                                    : currentStep == 1 ?
                                        <Notice handleCancel={handleCancel} currentStep={currentStep} setCurrentStep={setCurrentStep} />
                                    :
                                    <ReviewSubmit handleCancel={handleCancel} currentStep={currentStep} setCurrentStep={setCurrentStep} loading={loading} setLoading={setLoading} basicForm={basicForm} scholarshipForm={scholarshipForm} paymentForm={paymentForm} educationTypes={educationTypes} setEducationSelected={setEducationSelected} setBasicForm={setBasicForm} setPaymentForm={setPaymentForm} setScholarShipForm={setScholarShipForm} blankBasicEducationForm={states.blankBasicEducationForm} blankScholarshipForm={states.blankScholarshipForm} blankPaymentForm={states.blankPaymentForm} subjects={subjects} subjectForm={subjectForm} setSubjectForm={setSubjectForm} selectedSubjects={selectedSubjects} gradeTypes={gradeTypes} />
                                }
                            </>
                        : selectedEducationType == "Tertiary Education" ?
                            role != "Guest" ?
                                <>
                                    <Stepper activeStep={currentStep} className="w-100" style={{ overflowX: "auto", marginTop: isMobile || isTablet ? "4rem" : "" }}>
                                        <Step label="Form" />
                                        <Step label="Subjects" />
                                        <Step label="Scholarship" />
                                        <Step label="Assessment" />
                                        <Step label="Review and Submit" />
                                    </Stepper>
                                    {currentStep == 0 ?
                                        <TertiaryEducationForm handleCancel={handleCancel} setEducationSelected={setEducationSelected} currentStep={currentStep} setCurrentStep={setCurrentStep} setLoading={setLoading} basicForm={basicForm} setBasicForm={setBasicForm} />
                                        :
                                        currentStep == 1 ?
                                            <Subjects handleCancel={handleCancel} setEducationSelected={setEducationSelected} currentStep={currentStep} setCurrentStep={setCurrentStep} setLoading={setLoading} subjects={subjects} setSubjects={setSubjects} selectOptions={selectOptions} subjectForm={subjectForm} setSubjectForm={setSubjectForm} schoolFees={schoolFees} setTertiaryTotalTuition={setTertiaryTotalTuition} allSubjects={allSubjects} selectedSubjects={selectedSubjects} setSelectedSubjects={setSelectedSubjects} paymentForm={paymentForm} setPaymentForm={setPaymentForm} />
                                            :
                                            currentStep == 2 ?
                                                <ScholarshipForm handleCancel={handleCancel} setEducationSelected={setEducationSelected} currentStep={currentStep} setCurrentStep={setCurrentStep} setLoading={setLoading} scholarshipForm={scholarshipForm} setScholarShipForm={setScholarShipForm} />
                                                :
                                                currentStep == 3 ?
                                                    <TertiaryAssessmentForm handleCancel={handleCancel} currentStep={currentStep} setCurrentStep={setCurrentStep} paymentForm={paymentForm} setPaymentForm={setPaymentForm} newTuitionFee={newTuitionFee} setSchoolFees={setSchoolFees} selectOptions={selectOptions} schoolFees={schoolFees} basicFormEsc={basicForm.is_esc} />
                                                    :
                                                    <ReviewSubmit handleCancel={handleCancel} currentStep={currentStep} setCurrentStep={setCurrentStep} loading={loading} setLoading={setLoading} basicForm={basicForm} scholarshipForm={scholarshipForm} paymentForm={paymentForm} educationTypes={educationTypes} setEducationSelected={setEducationSelected} setBasicForm={setBasicForm} setPaymentForm={setPaymentForm} setScholarShipForm={setScholarShipForm} blankBasicEducationForm={states.blankBasicEducationForm} blankScholarshipForm={states.blankScholarshipForm} blankPaymentForm={states.blankPaymentForm} subjects={subjects} subjectForm={subjectForm} setSubjectForm={setSubjectForm} selectedSubjects={selectedSubjects} gradeTypes={gradeTypes} />
                                    }
                                </>
                                :
                                <>
                                    <Stepper activeStep={currentStep} className="w-100" style={{ overflowX: "auto", marginTop: isMobile || isTablet ? "4rem" : "" }}>
                                        <Step label="Form" />
                                        <Step label="Subjects" />
                                        <Step label="Notice" />
                                        <Step label="Review and Submit" />
                                    </Stepper>
                                    {currentStep == 0 ?
                                        <TertiaryEducationForm handleCancel={handleCancel} setEducationSelected={setEducationSelected} currentStep={currentStep} setCurrentStep={setCurrentStep} setLoading={setLoading} basicForm={basicForm} setBasicForm={setBasicForm} />
                                        :
                                        currentStep == 1 ?
                                            <Subjects handleCancel={handleCancel} setEducationSelected={setEducationSelected} currentStep={currentStep} setCurrentStep={setCurrentStep} setLoading={setLoading} subjects={subjects} setSubjects={setSubjects} selectOptions={selectOptions} subjectForm={subjectForm} setSubjectForm={setSubjectForm} schoolFees={schoolFees} setTertiaryTotalTuition={setTertiaryTotalTuition} allSubjects={allSubjects} selectedSubjects={selectedSubjects} setSelectedSubjects={setSelectedSubjects} paymentForm={paymentForm} setPaymentForm={setPaymentForm} />
                                            : currentStep == 2 ?
                                                <Notice handleCancel={handleCancel} currentStep={currentStep} setCurrentStep={setCurrentStep} />
                                            :
                                            <ReviewSubmit handleCancel={handleCancel} currentStep={currentStep} setCurrentStep={setCurrentStep} loading={loading} setLoading={setLoading} basicForm={basicForm} scholarshipForm={scholarshipForm} paymentForm={paymentForm} educationTypes={educationTypes} setEducationSelected={setEducationSelected} setBasicForm={setBasicForm} setPaymentForm={setPaymentForm} setScholarShipForm={setScholarShipForm} blankBasicEducationForm={states.blankBasicEducationForm} blankScholarshipForm={states.blankScholarshipForm} blankPaymentForm={states.blankPaymentForm} subjects={subjects} subjectForm={subjectForm} setSubjectForm={setSubjectForm} selectedSubjects={selectedSubjects} gradeTypes={gradeTypes} />
                                    }
                                </>
                            : selectedEducationType == "Tesda Course" ?
                                <TesdatEducationForm handleCancel={handleCancel} setTesdaEducationSelected={setTesdaEducationSelected} blankTedaForm={states.blankTesdaForm} tesdaForm={tesdaForm} setTesdaForm={setTesdaForm} educationTypes={educationTypes} />
                                : ""}
                </Col>
                : currentPage == "viewApplication" ?
                    <Col md={isDesktop ? "10" : "12"} sm="12" xs="12" style={{ borderLeft: isBrowser ? "2px solid yellow" : "", paddingTop: isBrowser ? "5rem" : "2rem" }}>
                        <ApplicationView
                            basicForm={basicForm}
                            setBasicForm={setBasicForm}
                            scholarshipForm={scholarshipForm}
                            tesdaForm={tesdaForm}
                            paymentForm={paymentForm}
                            schoolFees={schoolFees} 
                            setPaymentForm={setPaymentForm}
                        />
                    </Col>
                    : ""}
        </>
    );
}

export default StepperComponent;