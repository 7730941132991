import { ENDPOINT } from "../constants/api";
import axios from "axios";
import { notifyError, notifySuccess } from "./Toaster";
import querystring from "querystring";

export const applicationActions = {
  getApplications,
  getApplication,
  rejectApplication,
  approveApplication,
  downloadFile,
  approveScholarship,
  getMyApplication,
  approveEsc,
  uploadFiles,
  cancelApplication,
  revertApplication,
  getMyBilling,
};
const token = localStorage.getItem("jwtToken")
  ? localStorage.getItem("jwtToken")
  : "";
const headers = {
  Authorization: `Bearer ${token}`,
  "Content-Type": "application/json",
  "X-Requested-With": "XMLHttpRequest",
};

async function getApplications(
  setData,
  setLoading,
  setTotalSize = null,
  page1 = null,
  sizePerPageQuery,
  searchText = null,
  filters,
  setButtonSpinner = null
) {
  try {
    let q = searchText ? searchText.trim() : "";
    let filter = `?q=${q}&sizePerPage=${sizePerPageQuery}&page=${page1}&sy_from=${filters.sy_from}&sy_to=${filters.sy_to}&student_status=${filters.student_status}&grade_level=${filters.gradeLevel}&course=${filters.course}&semester=${filters.semester}`;

    let res = await axios.get(ENDPOINT + `/get-application` + filter, {
      headers: headers,
    });
    let displayData = [];

    if (res.data && res.data.data && res.data.data.data) {
      let data = res.data.data.data;
      if (data && data.length >= 1) {
        displayData = data.map((d) => {
          return {
            id: d.id,
            ref_no: d.ref_no,
            last_name: d.last_name,
            first_name: d.given_name,
            created_at: d.date_filed,
            grade_level: d.grade_level,
            status: d.status,
            remarks: d.remarks,
            student_id: d.student_id,
            course: d.course,
            semester:
              d.semester == "1" ? "Second" : d.semester == "0" ? "First" : "-",
          };
        });
      }
    }
    setLoading(false);
    setData(displayData);
    if (setTotalSize) setTotalSize(res.data.data.total);
    if (setButtonSpinner) setButtonSpinner(false);
  } catch (err) {
    notifyError("Error while fetching students.");
    setLoading(false);
    if (setButtonSpinner) setButtonSpinner(false);
  }
}

async function getApplication(
  id,
  navigate,
  setBasicForm,
  setScholarShipForm,
  setTesdaForm,
  setCurrentPage,
  setCurrentStep,
  setEducationSelected,
  setSelectedEducationType,
  selectOptions,
  getOptionLabelByValue,
  paymentForm,
  setPaymentForm,
  setSubjectForm,
  setSubjects,
  setSelectedSubjects,
  schoolFees
) {
  try {
    let res = await axios.get(ENDPOINT + `/student/application-details/${id}`, {
      headers: headers,
    });

    let data = res.data.data.my_applicaiton[0];
    data.student_subject =
      typeof data.student_subject == "string"
        ? JSON.parse(data.student_subject)
        : data.student_subject;
    let esc_qvr_info = data.esc_info ? JSON.parse(data.esc_info) : "";
    let approvalScholarship =
      data.is_scholar && data.scholarship_type
        ? JSON.parse(data.scholarship_type)
        : null;
    let basic = {
      id: data.id,
      sy_from: data.sy_from,
      sy_to: data.sy_to,
      student_detail_id: data.student_detail_id.id,
      application_id: data.id,
      programs: "",
      last_name: data.student_detail_id.last_name,
      suffix: data.student_detail_id.suffix,
      first_name: data.student_detail_id.given_name,
      middle_name: data.student_detail_id.middle_name,
      birth_place: data.student_detail_id.birth_place,
      birth_date: data.student_detail_id.birth_date,
      gender: data.student_detail_id.gender,
      age: data.student_detail_id.age,
      religion: data.student_detail_id.religion,
      lrn: data.student_detail_id.lrn,
      civil_status: data.student_detail_id.civil_status,
      semester: data.student_detail_id.semester,
      street: data.student_detail_id.house_bldg_street,
      province: data.student_detail_id.province,
      city: data.student_detail_id.city_municipality,
      barangay: data.student_detail_id.barangay,
      elementary_school_id:
        data.education_background &&
        data.education_background.length &&
        data.education_background[0].id
          ? data.education_background[0].id
          : "",
      elementary_school_name:
        data.education_background && data.education_background.length
          ? data.education_background[0].school_name
          : "",
      elementary_year_graduated:
        data.education_background && data.education_background.length
          ? data.education_background[0].year_graduated
          : "",
      elementary_school_address:
        data.education_background && data.education_background.length
          ? data.education_background[0].school_address
          : "",
      junior_high_school_id:
        data.education_background &&
        data.education_background.length &&
        data.education_background[1].id
          ? data.education_background[1].id
          : "",
      junior_high_school_name:
        data.education_background && data.education_background.length
          ? data.education_background[1].school_name
          : "",
      junior_high_year_graduated:
        data.education_background && data.education_background.length
          ? data.education_background[1].year_graduated
          : "",
      junior_high_school_address:
        data.education_background && data.education_background.length
          ? data.education_background[1].school_address
          : "",
      senior_high_school_id:
        data.education_background &&
        data.education_background.length &&
        data.education_background[2].id
          ? data.education_background[2].id
          : "",
      senior_high_school_name:
        data.education_background && data.education_background.length
          ? data.education_background[2].school_name
          : "",
      senior_high_year_graduated:
        data.education_background && data.education_background.length
          ? data.education_background[2].year_graduated
          : "",
      senior_high_school_address:
        data.education_background && data.education_background.length
          ? data.education_background[2].school_address
          : "",
      form138: "",
      form137: "",
      picture: "",
      good_moral: "",
      ncae: "",
      birth_certificate: "",
      cert_of_residency: "",
      copy_of_grades: "",
      shs_diploma: "",
      selected_program: "",
      living_status:
        data.health_additional_info && data.health_additional_info.living_status
          ? data.health_additional_info.living_status
          : "",
      no_of_siblings:
        data.health_additional_info &&
        data.health_additional_info.no_of_siblings
          ? data.health_additional_info.no_of_siblings
          : "",
      father_name: "",
      father_age: "",
      father_birth_date: "",
      father_birth_place: "",
      father_home_address: "",
      father_contact_no: "",
      father_highest_education: "",
      father_occupation: "",
      father_company: "",
      father_monthly_income: "",
      mother_name: "",
      mother_age: "",
      mother_birth_date: "",
      mother_birth_place: "",
      mother_home_address: "",
      mother_contact_no: "",
      mother_highest_education: "",
      mother_occupation: "",
      mother_company: "",
      mother_monthly_income: "",
      guardian_name: "",
      guardian_age: "",
      guardian_birth_date: "",
      guardian_birth_place: "",
      guardian_home_address: "",
      guardian_contact_no: "",
      guardian_highest_education: "",
      guardian_occupation: "",
      guardian_company: "",
      guardian_monthly_income: "",
      health_student_boolean:
        data.health_additional_info &&
        data.health_additional_info.health_student_boolean
          ? data.health_additional_info.health_student_boolean
          : "",
      health_student_specific:
        data.health_additional_info &&
        data.health_additional_info.health_student_specific
          ? data.health_additional_info.health_student_specific
          : "",
      consulting_doctor:
        data.health_additional_info &&
        data.health_additional_info.consulting_doctor
          ? data.health_additional_info.consulting_doctor
          : "",
      important_illness:
        data.health_additional_info &&
        data.health_additional_info.important_illness
          ? data.health_additional_info.important_illness
          : "",
      hospitalized_in_past:
        data.health_additional_info &&
        data.health_additional_info.hospitalized_in_past
          ? data.health_additional_info.hospitalized_in_past
          : "",
      specific_hospitalized_in_past:
        data.health_additional_info &&
        data.health_additional_info.specific_hospitalized_in_past
          ? data.health_additional_info.specific_hospitalized_in_past
          : "",
      difficulty:
        data.health_additional_info && data.health_additional_info.difficulty
          ? data.health_additional_info.difficulty
          : "",
      family_health_history:
        data.health_additional_info &&
        data.health_additional_info.family_health_history
          ? data.health_additional_info.family_health_history
          : "",
      family_member_with_sickness:
        data.health_additional_info &&
        data.health_additional_info.family_member_with_sickness
          ? data.health_additional_info.family_member_with_sickness
          : "",
      vaccination:
        data.health_additional_info && data.health_additional_info.vaccination
          ? data.health_additional_info.vaccination
          : "",
      self_evaluation:
        data.health_additional_info &&
        data.health_additional_info.self_evaluation
          ? data.health_additional_info.self_evaluation
          : "",
      financial_support:
        data.health_additional_info &&
        data.health_additional_info.financial_support
          ? data.health_additional_info.financial_support
          : "",
      other_financial_support:
        data.health_additional_info &&
        data.health_additional_info.other_financial_support
          ? data.health_additional_info.other_financial_support
          : "",
      grew_up:
        data.health_additional_info && data.health_additional_info.grew_up
          ? data.health_additional_info.grew_up
          : "",
      language_home:
        data.health_additional_info && data.health_additional_info.language_home
          ? data.health_additional_info.language_home
          : "",
      other_language_home:
        data.health_additional_info &&
        data.health_additional_info.other_language_home
          ? data.health_additional_info.other_language_home
          : "",
      concerns:
        data.health_additional_info && data.health_additional_info.concerns
          ? data.health_additional_info.concerns
          : "",
      other_concern:
        data.health_additional_info && data.health_additional_info.other_concern
          ? data.health_additional_info.other_concern
          : "",
      problem_solution:
        data.health_additional_info &&
        data.health_additional_info.problem_solution
          ? data.health_additional_info.problem_solution
          : "",
      other_problem_solution:
        data.health_additional_info &&
        data.health_additional_info.other_problem_solution
          ? data.health_additional_info.other_problem_solution
          : "",
      cope_up_stress:
        data.health_additional_info &&
        data.health_additional_info.cope_up_stress
          ? data.health_additional_info.cope_up_stress
          : "",
      other_cope_up_stress:
        data.health_additional_info &&
        data.health_additional_info.other_cope_up_stress
          ? data.health_additional_info.other_cope_up_stress
          : "",
      how_to_know_sapc:
        data.health_additional_info &&
        data.health_additional_info.how_to_know_sapc
          ? data.health_additional_info.how_to_know_sapc
          : "",
      other_how_to_know_sapc:
        data.health_additional_info &&
        data.health_additional_info.other_how_to_know_sapc
          ? data.health_additional_info.other_how_to_know_sapc
          : "",
      listahan:
        data.health_additional_info && data.health_additional_info.listahan
          ? data.health_additional_info.listahan
          : "",
      household_number:
        data.health_additional_info &&
        data.health_additional_info.household_number
          ? data.health_additional_info.household_number
          : "",
      disability:
        data.health_additional_info && data.health_additional_info.disability
          ? data.health_additional_info.disability
          : "",
      pwd_number:
        data.health_additional_info && data.health_additional_info.pwd_number
          ? data.health_additional_info.pwd_number
          : "",
      subsidy:
        data.health_additional_info && data.health_additional_info.subsidy
          ? data.health_additional_info.subsidy
          : "",
      subsidy_details:
        data.health_additional_info &&
        data.health_additional_info.subsidy_details
          ? data.health_additional_info.subsidy_details
          : "",
      education_type: data.education_type_id,
      student_type: data.student_type_id,
      remarks: data.remarks,
      subjects: data.student_subject,
      old_attachments: data.student_document ? data.student_document : [],
      date_of_appointment:
        data.health_additional_info &&
        data.health_additional_info.date_of_appointment
          ? data.health_additional_info.date_of_appointment
          : "",
      is_esc: data.is_esc,
      esc_type: data.esc_type,
      esc_qvr: esc_qvr_info ? esc_qvr_info.esc_qvr : "",
      approve_esc: data.approve_esc,
      scholarship_name:
        data.is_scholar && data.scholarship_type
          ? approvalScholarship.name
          : "",
      scholarship_discount:
        data.is_scholar && data.scholarship_type
          ? approvalScholarship.discount_percentage
          : "",
      guardian_toggle: data.health_additional_info
        ? data.health_additional_info.guardian_toggle
        : true,
      student_status: data.student_status,
    };

    let scholarship = {
      scholarship_student_name: data.scholarship_eligable
        ? data.scholarship_info.scholarship_student_name
        : "",
      scholarhip_date: new Date(),
      scholarship_academic_year_from: data.scholarship_eligable
        ? data.scholarship_info.scholarship_academic_year_from
        : "",
      scholarship_academic_year_to: data.scholarship_eligable
        ? data.scholarship_info.scholarship_academic_year_to
        : "",
      scholarship_semester: data.scholarship_eligable
        ? data.scholarship_info.scholarship_semester
        : "",
      scholarship_grade_level: data.scholarship_eligable
        ? data.scholarship_info.scholarship_grade_level
        : "",
      scholarship_course: data.scholarship_eligable
        ? data.scholarship_info.scholarship_course
        : "",
      scholarship_year: data.scholarship_eligable
        ? data.scholarship_info.scholarship_year
        : "",
      scholarship_application_type: data.scholarship_eligable
        ? data.scholarship_info.scholarship_application_type
        : "",
      scholarship_previous: data.scholarship_eligable
        ? data.scholarship_info.scholarship_previous
        : "",
      family_discount: false,
      family_discount_siblings:
        data.scholarship_info && data.scholarship_info.family_discount
          ? data.scholarship_info.family_discount_siblings
          : "",
      academic_scholarship: false,
      academic_scholarship_rank:
        data.scholarship_info && data.scholarship_info.academic_scholarship
          ? data.scholarship_info.academic_scholarship_rank
          : "",
      academic_scholarship_proof:
        data.scholarship_info && data.scholarship_info.academic_scholarship
          ? data.scholarship_info.academic_scholarship_proof
          : "",
      athletic_scholarship: false,
      athletic_level:
        data.scholarship_info && data.scholarship_info.athletic_scholarship
          ? data.scholarship_info.athletic_level
          : "",
      athletic_varsity_scholarship:
        data.scholarship_info && data.scholarship_info.athletic_scholarship
          ? data.scholarship_info.athletic_varsity_scholarship
          : "",
      scholarship_specific_sports:
        data.scholarship_info && data.scholarship_info.athletic_scholarship
          ? data.scholarship_info.scholarship_specific_sports
          : "",
      loyalty_discount: false,
      loyalty_discount_specific_year:
        data.scholarship_info && data.scholarship_info.loyalty_discount
          ? data.scholarship_info.loyalty_discount_specific_year
          : "",
      sapc_employee: false,
      sapc_employee_scholarship_specific:
        data.scholarship_info && data.scholarship_info.sapc_employee
          ? data.scholarship_info.sapc_employee_scholarship_specific
          : "",
      others: false,
      other_scholarship_specific:
        data.scholarship_info && data.scholarship_info.others
          ? data.scholarship_info.other_scholarship_specific
          : "",
      esc_switch: false,
      esc_file: data.esc_switch ? true : false,
      scholarship: data.scholarship_eligable ? true : false,
    };

    if (data.student_document && data.student_document.length >= 1) {
      data.student_document.map((docu) => {
        if (docu.document_type_id.name == "Form 138") {
          basic.form138 = docu.name;
          basic.form138_file_name = docu.name;
          basic.form138_id = docu.id;
          basic.form138_file_url = docu.bucket_link;
        }
        if (docu.document_type_id.name == "Form 137") {
          basic.form137 = docu.name;
          basic.form137_file_name = docu.name;
          basic.form137_id = docu.id;
          basic.form137_file_url = docu.bucket_link;
        }
        if (docu.document_type_id.name == "Good Moral Character") {
          basic.good_moral = docu.name;
          basic.good_moral_file_name = docu.name;
          basic.good_moral_id = docu.id;
          basic.good_moral_file_url = docu.bucket_link;
        }
        if (
          docu.document_type_id.name == "National Career Assesment Examination"
        ) {
          basic.ncae = docu.name;
          basic.ncae_file_name = docu.name;
          basic.ncae_id = docu.id;
          basic.ncae_file_url = docu.bucket_link;
        }
        if (docu.document_type_id.name == "Birth Certificate") {
          basic.birth_certificate = docu.name;
          basic.birth_certificate_file_name = docu.name;
          basic.birth_certificate_id = docu.id;
          basic.birth_certificate_file_url = docu.bucket_link;
        }
        if (docu.document_type_id.name == "ESC certificate") {
          basic.esc_file = docu.name;
          basic.esc_file_name = docu.name;
          basic.esc_switch = true;
          basic.esc_file_id = docu.id;
          basic.esc_file_url = docu.bucket_link;
        }
        if (docu.document_type_id.name == "2x2 picture") {
          basic.picture = docu.name;
          basic.picture_file_name = docu.name;
          basic.picture_id = docu.id;
          basic.picture_file_url = docu.bucket_link;
        }
        if (docu.document_type_id.name == "Academic Rank") {
          scholarship.academic_scholarship_proof = docu.name;
          scholarship.academic_scholarship_proof_file_name = docu.name;
          scholarship.academic_scholarship_proof_id = docu.id;
          scholarship.academic_scholarship_proof_file_url = docu.bucket_link;
        }
        if (docu.document_type_id.name == "Tesda Document") {
          basic.tesda_form = docu.name;
          basic.tesda_form_file_name = docu.name;
          basic.tesda_form_id = docu.id;
          basic.tesda_form_file_url = docu.bucket_link;
        }

        if (docu.document_type_id.name == "Certificate of Residency") {
          basic.cert_of_residency = docu.name;
          basic.cert_of_residency_file_name = docu.name;
          basic.cert_of_residency_id = docu.id;
          basic.cert_of_residency_file_url = docu.bucket_link;
        }

        if (docu.document_type_id.name == "Copy of Grades") {
          basic.copy_of_grades = docu.name;
          basic.copy_of_grades_file_name = docu.name;
          basic.copy_of_grades_id = docu.id;
          basic.copy_of_grades_file_url = docu.bucket_link;
        }

        if (docu.document_type_id.name == "SHS Diploma") {
          basic.shs_diploma = docu.name;
          basic.shs_diploma_file_name = docu.name;
          basic.shs_diploma_id = docu.id;
          basic.shs_diploma_file_url = docu.bucket_link;
        }
        if (docu.document_type_id.name == "QVR Certificate") {
          basic.qvr_file = docu.name;
          basic.qvr_file_name = docu.name;
          basic.qvr_file_id = docu.id;
          basic.qvr_file_url = docu.bucket_link;
        }
      });
    }

    if (data.family_info && data.family_info.length >= 1) {
      if (data.family_info[0].family_role == 0) {
        basic.father_name = data.family_info[0].name;
        basic.father_age = data.family_info[0].age;
        basic.father_birth_date = data.family_info[0].date_of_birth;
        basic.father_birth_place = data.family_info[0].place_of_birth;
        basic.father_home_address = data.family_info[0].home_address;
        basic.father_contact_no = data.family_info[0].contact_number;
        basic.father_highest_education =
          data.family_info[0].education_attainment;
        basic.father_occupation = data.family_info[0].occupation;
        basic.father_company = data.family_info[0].company_name;
        basic.father_monthly_income = data.family_info[0].monthly_income;
      }

      if (data.family_info[1].family_role == 1) {
        basic.mother_name = data.family_info[1].name;
        basic.mother_age = data.family_info[1].age;
        basic.mother_birth_date = data.family_info[1].date_of_birth;
        basic.mother_birth_place = data.family_info[1].place_of_birth;
        basic.mother_home_address = data.family_info[1].home_address;
        basic.mother_contact_no = data.family_info[1].contact_number;
        basic.mother_highest_education =
          data.family_info[1].education_attainment;
        basic.mother_occupation = data.family_info[1].occupation;
        basic.mother_company = data.family_info[1].company_name;
        basic.mother_monthly_income = data.family_info[1].monthly_income;
      }

      if (data.family_info[2].family_role == 2) {
        basic.guardian_name = data.family_info[2].name;
        basic.guardian_age = data.family_info[2].age;
        basic.guardian_birth_date = data.family_info[2].date_of_birth;
        basic.guardian_birth_place = data.family_info[2].place_of_birth;
        basic.guardian_home_address = data.family_info[2].home_address;
        basic.guardian_contact_no = data.family_info[2].contact_number;
        basic.guardian_highest_education =
          data.family_info[2].education_attainment;
        basic.guardian_occupation = data.family_info[2].occupation;
        basic.guardian_company = data.family_info[2].company_name;
        basic.guardian_monthly_income = data.family_info[2].monthly_income;
      }
    }

    let scholarshipArr = [];
    if (data.scholarship_eligable) {
      if (data.scholarship_info.family_discount) {
        scholarship.family_discount = 1;
        scholarshipArr.push({
          value: "family_discount",
          label: "Family Discount",
        });
      }
      if (data.scholarship_info.academic_scholarship) {
        scholarship.academic_scholarship = 2;
        scholarshipArr.push({
          value: "academic_scholarship",
          label: "Academic Scholarship",
        });
      }
      if (data.scholarship_info.athletic_scholarship) {
        scholarship.athletic_scholarship = 3;
        scholarshipArr.push({
          value: "athletic_scholarship",
          label: "Athletic Scholarship",
        });
      }
      if (data.scholarship_info.loyalty_discount) {
        scholarship.loyalty_discount = 4;
        scholarshipArr.push({
          value: "loyalty_discount",
          label: "Loyalty Discount",
        });
      }
      if (data.scholarship_info.sapc_employee) {
        scholarship.sapc_employee = 5;
        scholarshipArr.push({ value: "sapc_employee", label: "Sapc Employee" });
      }
      if (data.scholarship_info.others) {
        scholarship.others = 6;
        scholarshipArr.push({ value: "others", label: "Others" });
      }
    }
    scholarship.scholarshipArr = scholarshipArr;
    scholarship.application_id = data.id;
    if (localStorage.getItem("role") == "Assesor") {
      if (data.health_additional_info) {
        if (
          data.health_additional_info.paymentForm &&
          !data.health_additional_info.paymentForm.tuition_fee
        ) {
          setPaymentForm(data.health_additional_info.paymentForm);
          // if (schoolFees && schoolFees.length >= 1) {
          //     schoolFees.map(fee => {
          //         if (data.health_additional_info.admissionAnswers.grade_year_level && data.grade_year_level.value == fee.grade_level_id) {
          //             // let subtotal = parseFloat(tertiaryTotalTuition) * 0.03;
          //             // let discount_total = parseFloat(tertiaryTotalTuition) - subtotal.toFixed(2);
          //             // fee.tuition_fee = tertiaryTotalTuition;
          //             // fee.discounted_tuituion_fee = discount_total;
          //             setPaymentForm(fee);
          //         }
          //     })
          // }
        } else {
          setPaymentForm(data.health_additional_info.paymentForm);
        }
      }
    } else if (
      localStorage.getItem("role") == "Guest" ||
      localStorage.getItem("role") == "Student" ||
      localStorage.getItem("role") == "Administrator"
    ) {
      if (data.health_additional_info) {
        if (
          data.health_additional_info.paymentForm &&
          !data.health_additional_info.paymentForm.tuition_fee
        ) {
          if (schoolFees && schoolFees.length >= 1) {
            schoolFees.map((fee) => {
              if (
                data.health_additional_info.admissionAnswers.grade_year_level &&
                data.health_additional_info.admissionAnswers.grade_year_level
                  .value == fee.grade_level_id
              ) {
                // let subtotal = parseFloat(tertiaryTotalTuition) * 0.03;
                // let discount_total = parseFloat(tertiaryTotalTuition) - subtotal.toFixed(2);
                // fee.tuition_fee = tertiaryTotalTuition;
                // fee.discounted_tuituion_fee = discount_total;
                setPaymentForm(fee);
              }
            });
          }
        } else {
          setPaymentForm(data.health_additional_info.paymentForm);
        }
      }
    }

    if (data.student_subject && data.student_subject.enrollee_type) {
      setSubjectForm({
        enrollee_type: data.student_subject.enrollee_type,
        subjects: data.student_subject.subjects,
      });
      setSubjects(data.student_subject.subjects);
      if (
        data.student_subject &&
        data.student_subject.enrollee_type.label == "Manually select subjects"
      ) {
        setSelectedSubjects(data.student_subject.subjects);
      }
    }

    setBasicForm(basic);
    setScholarShipForm(scholarship);
    setTesdaForm(basic);

    if (localStorage.getItem("role") == "Assesor") {
      if (data.health_additional_info) {
        localStorage.setItem(
          "admissionAnswers",
          JSON.stringify(data.health_additional_info.admissionAnswers)
        );
      }
      setCurrentPage("viewApplication");
    } else if (
      localStorage.getItem("role") == "Guest" ||
      localStorage.getItem("role") == "Student" ||
      localStorage.getItem("role") == "Administrator"
    ) {
      if (data.health_additional_info) {
        localStorage.setItem(
          "admissionAnswers",
          JSON.stringify(data.health_additional_info.admissionAnswers)
        );
      }
      setCurrentPage("admission");
      setCurrentStep(0);
      setEducationSelected(true);
      setSelectedEducationType(data.education_type_id.name);
    }
  } catch (err) {
    if (err.response) {
      notifyError("Invalid Data.");
      // if (err.response.Error) {
      //     notifyError(err.response.Error);
      // }
      // if (err.response.data && err.response.data.data.email_address) {
      //     notifyError(err.response.data.data.email_address);
      // }
    } else {
      notifyError("Network Error.");
    }
  }
}

async function rejectApplication(
  dataToSubmit,
  setButtonSpinner,
  navigate,
  setResponse
) {
  try {
    let res = await axios.post(ENDPOINT + `/reject-application`, dataToSubmit, {
      headers: headers,
    });

    setButtonSpinner(false);
    setResponse(res.data.message);
    localStorage.removeItem("admissionAnswers");
    // window.location.reload(1000)
  } catch (err) {
    notifyError("Error in updating application status");
    setButtonSpinner(false);
  }
}

async function approveApplication(
  dataToSubmit,
  setButtonSpinner,
  navigate,
  setResponse
) {
  try {
    let res = await axios.post(
      ENDPOINT + `/approve-application`,
      dataToSubmit,
      { headers: headers }
    );

    setButtonSpinner(false);
    setResponse(res.data.message);
    localStorage.removeItem("admissionAnswers");
    // window.location.reload(1000)
  } catch (err) {
    notifyError("Error in updating application status");
    setButtonSpinner(false);
  }
}

async function downloadFile(documentId, applicationId) {
  try {
    let res = await axios.get(
      ENDPOINT +
        `/download-file?application_id=${applicationId}&document_id=${documentId}`,
      { headers: headers }
    );

    notifySuccess("Download Starting!");
    let win = window.open(res.data, "_blank");
    win.focus();
  } catch (err) {
    if (err.response && err.response.data.error) {
      notifyError(err.response.data.error);
    } else {
      notifyError("Error while downloading file.");
    }
  }
}

async function approveScholarship(body, setButtonSpinner, setScholarshipModal) {
  try {
    let res = await axios.post(ENDPOINT + `/approve-scholarship`, body, {
      headers: headers,
    });
    // const data = res.data.data.student_billing;
    notifySuccess("Approval Successful");
    setButtonSpinner(false);
    setScholarshipModal(false);
  } catch (err) {
    notifyError("Invalid Reference No");
    setButtonSpinner(false);
  }
}

async function approveEsc(
  body,
  setButtonSpinner,
  setScholarshipModal,
  escAction,
  setEscApproval
) {
  try {
    let res = await axios.post(ENDPOINT + `/approve-esc`, body, {
      headers: headers,
    });
    // const data = res.data.data.student_billing;
    if (escAction == "approve") {
      notifySuccess("Approval Successful");
      setEscApproval(true);
    } else {
      notifySuccess("Disapproval Applied");
      setEscApproval(false);
    }
    setButtonSpinner(false);
    setScholarshipModal(false);
  } catch (err) {
    if (err.response) {
      notifyError("Invalid Reference No");
    }
    setButtonSpinner(false);
  }
}

async function getMyApplication(
  id,
  setBasicForm,
  setScholarShipForm,
  setTesdaForm,
  setEducationSelected,
  setSelectedEducationType,
  selectOptions,
  getOptionLabelByValue,
  paymentForm,
  setPaymentForm,
  setSubjectForm,
  setSubjects,
  setSelectedSubjects,
  schoolFees,
  setViewMyApplication,
  setMyUploadedFileOpen,
  action
) {
  try {
    let res = await axios.get(ENDPOINT + `/student/application-details/${id}`, {
      headers: headers,
    });

    let data = res.data.data.my_applicaiton[0];
    data.student_subject =
      typeof data.student_subject == "string"
        ? JSON.parse(data.student_subject)
        : data.student_subject;
    let esc_qvr_info = data.esc_info ? JSON.parse(data.esc_info) : "";
    let basic = {
      id: data.id,
      sy_from: data.sy_from,
      sy_to: data.sy_to,
      student_detail_id: data.student_detail_id.id,
      application_id: data.id,
      programs: "",
      last_name: data.student_detail_id.last_name,
      suffix: data.student_detail_id.suffix,
      first_name: data.student_detail_id.given_name,
      middle_name: data.student_detail_id.middle_name,
      birth_place: data.student_detail_id.birth_place,
      birth_date: data.student_detail_id.birth_date,
      gender: data.student_detail_id.gender,
      age: data.student_detail_id.age,
      religion: data.student_detail_id.religion,
      lrn: data.student_detail_id.lrn,
      civil_status: data.student_detail_id.civil_status,
      semester: data.student_detail_id.semester,
      street: data.student_detail_id.house_bldg_street,
      province: data.student_detail_id.province,
      city: data.student_detail_id.city_municipality,
      barangay: data.student_detail_id.barangay,
      elementary_school_id:
        data.education_background &&
        data.education_background.length &&
        data.education_background[0].id
          ? data.education_background[0].id
          : "",
      elementary_school_name:
        data.education_background && data.education_background.length
          ? data.education_background[0].school_name
          : "",
      elementary_year_graduated:
        data.education_background && data.education_background.length
          ? data.education_background[0].year_graduated
          : "",
      elementary_school_address:
        data.education_background && data.education_background.length
          ? data.education_background[0].school_address
          : "",
      junior_high_school_id:
        data.education_background &&
        data.education_background.length &&
        data.education_background[1].id
          ? data.education_background[1].id
          : "",
      junior_high_school_name:
        data.education_background && data.education_background.length
          ? data.education_background[1].school_name
          : "",
      junior_high_year_graduated:
        data.education_background && data.education_background.length
          ? data.education_background[1].year_graduated
          : "",
      junior_high_school_address:
        data.education_background && data.education_background.length
          ? data.education_background[1].school_address
          : "",
      senior_high_school_id:
        data.education_background &&
        data.education_background.length &&
        data.education_background[2].id
          ? data.education_background[2].id
          : "",
      senior_high_school_name:
        data.education_background && data.education_background.length
          ? data.education_background[2].school_name
          : "",
      senior_high_year_graduated:
        data.education_background && data.education_background.length
          ? data.education_background[2].year_graduated
          : "",
      senior_high_school_address:
        data.education_background && data.education_background.length
          ? data.education_background[2].school_address
          : "",
      form138: "",
      form137: "",
      picture: "",
      good_moral: "",
      ncae: "",
      birth_certificate: "",
      cert_of_residency: "",
      copy_of_grades: "",
      shs_diploma: "",
      selected_program: "",
      living_status:
        data.health_additional_info && data.health_additional_info.living_status
          ? data.health_additional_info.living_status
          : "",
      no_of_siblings:
        data.health_additional_info &&
        data.health_additional_info.no_of_siblings
          ? data.health_additional_info.no_of_siblings
          : "",
      father_name: "",
      father_age: "",
      father_birth_date: "",
      father_birth_place: "",
      father_home_address: "",
      father_contact_no: "",
      father_highest_education: "",
      father_occupation: "",
      father_company: "",
      father_monthly_income: "",
      mother_name: "",
      mother_age: "",
      mother_birth_date: "",
      mother_birth_place: "",
      mother_home_address: "",
      mother_contact_no: "",
      mother_highest_education: "",
      mother_occupation: "",
      mother_company: "",
      mother_monthly_income: "",
      guardian_name: "",
      guardian_age: "",
      guardian_birth_date: "",
      guardian_birth_place: "",
      guardian_home_address: "",
      guardian_contact_no: "",
      guardian_highest_education: "",
      guardian_occupation: "",
      guardian_company: "",
      guardian_monthly_income: "",
      health_student_boolean:
        data.health_additional_info &&
        data.health_additional_info.health_student_boolean
          ? data.health_additional_info.health_student_boolean
          : "",
      health_student_specific:
        data.health_additional_info &&
        data.health_additional_info.health_student_specific
          ? data.health_additional_info.health_student_specific
          : "",
      consulting_doctor:
        data.health_additional_info &&
        data.health_additional_info.consulting_doctor
          ? data.health_additional_info.consulting_doctor
          : "",
      important_illness:
        data.health_additional_info &&
        data.health_additional_info.important_illness
          ? data.health_additional_info.important_illness
          : "",
      hospitalized_in_past:
        data.health_additional_info &&
        data.health_additional_info.hospitalized_in_past
          ? data.health_additional_info.hospitalized_in_past
          : "",
      specific_hospitalized_in_past:
        data.health_additional_info &&
        data.health_additional_info.specific_hospitalized_in_past
          ? data.health_additional_info.specific_hospitalized_in_past
          : "",
      difficulty:
        data.health_additional_info && data.health_additional_info.difficulty
          ? data.health_additional_info.difficulty
          : "",
      family_health_history:
        data.health_additional_info &&
        data.health_additional_info.family_health_history
          ? data.health_additional_info.family_health_history
          : "",
      family_member_with_sickness:
        data.health_additional_info &&
        data.health_additional_info.family_member_with_sickness
          ? data.health_additional_info.family_member_with_sickness
          : "",
      vaccination:
        data.health_additional_info && data.health_additional_info.vaccination
          ? data.health_additional_info.vaccination
          : "",
      self_evaluation:
        data.health_additional_info &&
        data.health_additional_info.self_evaluation
          ? data.health_additional_info.self_evaluation
          : "",
      financial_support:
        data.health_additional_info &&
        data.health_additional_info.financial_support
          ? data.health_additional_info.financial_support
          : "",
      other_financial_support:
        data.health_additional_info &&
        data.health_additional_info.other_financial_support
          ? data.health_additional_info.other_financial_support
          : "",
      grew_up:
        data.health_additional_info && data.health_additional_info.grew_up
          ? data.health_additional_info.grew_up
          : "",
      language_home:
        data.health_additional_info && data.health_additional_info.language_home
          ? data.health_additional_info.language_home
          : "",
      other_language_home:
        data.health_additional_info &&
        data.health_additional_info.other_language_home
          ? data.health_additional_info.other_language_home
          : "",
      concerns:
        data.health_additional_info && data.health_additional_info.concerns
          ? data.health_additional_info.concerns
          : "",
      other_concern:
        data.health_additional_info && data.health_additional_info.other_concern
          ? data.health_additional_info.other_concern
          : "",
      problem_solution:
        data.health_additional_info &&
        data.health_additional_info.problem_solution
          ? data.health_additional_info.problem_solution
          : "",
      other_problem_solution:
        data.health_additional_info &&
        data.health_additional_info.other_problem_solution
          ? data.health_additional_info.other_problem_solution
          : "",
      cope_up_stress:
        data.health_additional_info &&
        data.health_additional_info.cope_up_stress
          ? data.health_additional_info.cope_up_stress
          : "",
      other_cope_up_stress:
        data.health_additional_info &&
        data.health_additional_info.other_cope_up_stress
          ? data.health_additional_info.other_cope_up_stress
          : "",
      how_to_know_sapc:
        data.health_additional_info &&
        data.health_additional_info.how_to_know_sapc
          ? data.health_additional_info.how_to_know_sapc
          : "",
      other_how_to_know_sapc:
        data.health_additional_info &&
        data.health_additional_info.other_how_to_know_sapc
          ? data.health_additional_info.other_how_to_know_sapc
          : "",
      listahan:
        data.health_additional_info && data.health_additional_info.listahan
          ? data.health_additional_info.listahan
          : "",
      household_number:
        data.health_additional_info &&
        data.health_additional_info.household_number
          ? data.health_additional_info.household_number
          : "",
      disability:
        data.health_additional_info && data.health_additional_info.disability
          ? data.health_additional_info.disability
          : "",
      pwd_number:
        data.health_additional_info && data.health_additional_info.pwd_number
          ? data.health_additional_info.pwd_number
          : "",
      subsidy:
        data.health_additional_info && data.health_additional_info.subsidy
          ? data.health_additional_info.subsidy
          : "",
      subsidy_details:
        data.health_additional_info &&
        data.health_additional_info.subsidy_details
          ? data.health_additional_info.subsidy_details
          : "",
      education_type: data.education_type_id,
      student_type: data.student_type_id,
      remarks: data.remarks,
      subjects: data.student_subject,
      old_attachments: data.student_document ? data.student_document : [],
      date_of_appointment:
        data.health_additional_info &&
        data.health_additional_info.date_of_appointment
          ? data.health_additional_info.date_of_appointment
          : "",
      is_esc: data.is_esc,
      esc_type: data.esc_type,
      esc_qvr: esc_qvr_info ? esc_qvr_info.esc_qvr : "",
      guardian_toggle: data.health_additional_info
        ? data.health_additional_info.guardian_toggle
        : true,
      student_status: data.student_status,
    };

    let scholarship = {
      scholarship_student_name: data.scholarship_eligable
        ? data.scholarship_info.scholarship_student_name
        : "",
      scholarhip_date: new Date(),
      scholarship_academic_year_from: data.scholarship_eligable
        ? data.scholarship_info.scholarship_academic_year_from
        : "",
      scholarship_academic_year_to: data.scholarship_eligable
        ? data.scholarship_info.scholarship_academic_year_to
        : "",
      scholarship_semester: data.scholarship_eligable
        ? data.scholarship_info.scholarship_semester
        : "",
      scholarship_grade_level: data.scholarship_eligable
        ? data.scholarship_info.scholarship_grade_level
        : "",
      scholarship_course: data.scholarship_eligable
        ? data.scholarship_info.scholarship_course
        : "",
      scholarship_year: data.scholarship_eligable
        ? data.scholarship_info.scholarship_year
        : "",
      scholarship_application_type: data.scholarship_eligable
        ? data.scholarship_info.scholarship_application_type
        : "",
      scholarship_previous: data.scholarship_eligable
        ? data.scholarship_info.scholarship_previous
        : "",
      family_discount: false,
      family_discount_siblings:
        data.scholarship_info && data.scholarship_info.family_discount
          ? data.scholarship_info.family_discount_siblings
          : "",
      academic_scholarship: false,
      academic_scholarship_rank:
        data.scholarship_info && data.scholarship_info.academic_scholarship
          ? data.scholarship_info.academic_scholarship_rank
          : "",
      academic_scholarship_proof:
        data.scholarship_info && data.scholarship_info.academic_scholarship
          ? data.scholarship_info.academic_scholarship_proof
          : "",
      athletic_scholarship: false,
      athletic_level:
        data.scholarship_info && data.scholarship_info.athletic_scholarship
          ? data.scholarship_info.athletic_level
          : "",
      athletic_varsity_scholarship:
        data.scholarship_info && data.scholarship_info.athletic_scholarship
          ? data.scholarship_info.athletic_varsity_scholarship
          : "",
      scholarship_specific_sports:
        data.scholarship_info && data.scholarship_info.athletic_scholarship
          ? data.scholarship_info.scholarship_specific_sports
          : "",
      loyalty_discount: false,
      loyalty_discount_specific_year:
        data.scholarship_info && data.scholarship_info.loyalty_discount
          ? data.scholarship_info.loyalty_discount_specific_year
          : "",
      sapc_employee: false,
      sapc_employee_scholarship_specific:
        data.scholarship_info && data.scholarship_info.sapc_employee
          ? data.scholarship_info.sapc_employee_scholarship_specific
          : "",
      others: false,
      other_scholarship_specific:
        data.scholarship_info && data.scholarship_info.others
          ? data.scholarship_info.other_scholarship_specific
          : "",
      esc_switch: false,
      esc_file: data.esc_switch ? true : false,
      scholarship: data.scholarship_eligable ? true : false,
    };

    if (data.student_document && data.student_document.length >= 1) {
      data.student_document.map((docu) => {
        if (docu.document_type_id.name == "Form 138") {
          basic.form138 = docu.name;
          basic.form138_file_name = docu.name;
          basic.form138_id = docu.id;
          basic.form138_file_url = docu.bucket_link;
        }
        if (docu.document_type_id.name == "Form 137") {
          basic.form137 = docu.name;
          basic.form137_file_name = docu.name;
          basic.form137_id = docu.id;
          basic.form137_file_url = docu.bucket_link;
        }
        if (docu.document_type_id.name == "Good Moral Character") {
          basic.good_moral = docu.name;
          basic.good_moral_file_name = docu.name;
          basic.good_moral_id = docu.id;
          basic.good_moral_file_url = docu.bucket_link;
        }
        if (
          docu.document_type_id.name == "National Career Assesment Examination"
        ) {
          basic.ncae = docu.name;
          basic.ncae_file_name = docu.name;
          basic.ncae_id = docu.id;
          basic.ncae_file_url = docu.bucket_link;
        }
        if (docu.document_type_id.name == "Birth Certificate") {
          basic.birth_certificate = docu.name;
          basic.birth_certificate_file_name = docu.name;
          basic.birth_certificate_id = docu.id;
          basic.birth_certificate_file_url = docu.bucket_link;
        }
        if (docu.document_type_id.name == "ESC certificate") {
          basic.esc_file = docu.name;
          basic.esc_file_name = docu.name;
          basic.esc_switch = true;
          basic.esc_file_id = docu.id;
          basic.esc_file_url = docu.bucket_link;
        }
        if (docu.document_type_id.name == "2x2 picture") {
          basic.picture = docu.name;
          basic.picture_file_name = docu.name;
          basic.picture_id = docu.id;
          basic.picture_file_url = docu.bucket_link;
        }
        if (docu.document_type_id.name == "Academic Rank") {
          scholarship.academic_scholarship_proof = docu.name;
          scholarship.academic_scholarship_proof_file_name = docu.name;
          scholarship.academic_scholarship_proof_id = docu.id;
          scholarship.academic_scholarship_proof_file_url = docu.bucket_link;
        }
        if (docu.document_type_id.name == "Tesda Document") {
          basic.tesda_form = docu.name;
          basic.tesda_form_file_name = docu.name;
          basic.tesda_form_id = docu.id;
          basic.tesda_form_file_url = docu.bucket_link;
        }

        if (docu.document_type_id.name == "Certificate of Residency") {
          basic.cert_of_residency = docu.name;
          basic.cert_of_residency_file_name = docu.name;
          basic.cert_of_residency_id = docu.id;
          basic.cert_of_residency_file_url = docu.bucket_link;
        }

        if (docu.document_type_id.name == "Copy of Grades") {
          basic.copy_of_grades = docu.name;
          basic.copy_of_grades_file_name = docu.name;
          basic.copy_of_grades_id = docu.id;
          basic.copy_of_grades_file_url = docu.bucket_link;
        }

        if (docu.document_type_id.name == "SHS Diploma") {
          basic.shs_diploma = docu.name;
          basic.shs_diploma_file_name = docu.name;
          basic.shs_diploma_id = docu.id;
          basic.shs_diploma_file_url = docu.bucket_link;
        }
        if (docu.document_type_id.name == "QVR Certificate") {
          basic.qvr_file = docu.name;
          basic.qvr_file_name = docu.name;
          basic.qvr_file_id = docu.id;
          basic.qvr_file_url = docu.bucket_link;
        }
      });
    }

    if (data.family_info && data.family_info.length >= 1) {
      if (data.family_info[0].family_role == 0) {
        basic.father_name = data.family_info[0].name;
        basic.father_age = data.family_info[0].age;
        basic.father_birth_date = data.family_info[0].date_of_birth;
        basic.father_birth_place = data.family_info[0].place_of_birth;
        basic.father_home_address = data.family_info[0].home_address;
        basic.father_contact_no = data.family_info[0].contact_number;
        basic.father_highest_education =
          data.family_info[0].education_attainment;
        basic.father_occupation = data.family_info[0].occupation;
        basic.father_company = data.family_info[0].company_name;
        basic.father_monthly_income = data.family_info[0].monthly_income;
      }

      if (data.family_info[1].family_role == 1) {
        basic.mother_name = data.family_info[1].name;
        basic.mother_age = data.family_info[1].age;
        basic.mother_birth_date = data.family_info[1].date_of_birth;
        basic.mother_birth_place = data.family_info[1].place_of_birth;
        basic.mother_home_address = data.family_info[1].home_address;
        basic.mother_contact_no = data.family_info[1].contact_number;
        basic.mother_highest_education =
          data.family_info[1].education_attainment;
        basic.mother_occupation = data.family_info[1].occupation;
        basic.mother_company = data.family_info[1].company_name;
        basic.mother_monthly_income = data.family_info[1].monthly_income;
      }

      if (data.family_info[2].family_role == 2) {
        basic.guardian_name = data.family_info[2].name;
        basic.guardian_age = data.family_info[2].age;
        basic.guardian_birth_date = data.family_info[2].date_of_birth;
        basic.guardian_birth_place = data.family_info[2].place_of_birth;
        basic.guardian_home_address = data.family_info[2].home_address;
        basic.guardian_contact_no = data.family_info[2].contact_number;
        basic.guardian_highest_education =
          data.family_info[2].education_attainment;
        basic.guardian_occupation = data.family_info[2].occupation;
        basic.guardian_company = data.family_info[2].company_name;
        basic.guardian_monthly_income = data.family_info[2].monthly_income;
      }
    }

    let scholarshipArr = [];
    if (data.scholarship_eligable) {
      if (data.scholarship_info.family_discount) {
        scholarship.family_discount = 1;
        scholarshipArr.push({
          value: "family_discount",
          label: "Family Discount",
        });
      }
      if (data.scholarship_info.academic_scholarship) {
        scholarship.academic_scholarship = 2;
        scholarshipArr.push({
          value: "academic_scholarship",
          label: "Academic Scholarship",
        });
      }
      if (data.scholarship_info.athletic_scholarship) {
        scholarship.athletic_scholarship = 3;
        scholarshipArr.push({
          value: "athletic_scholarship",
          label: "Athletic Scholarship",
        });
      }
      if (data.scholarship_info.loyalty_discount) {
        scholarship.loyalty_discount = 4;
        scholarshipArr.push({
          value: "loyalty_discount",
          label: "Loyalty Discount",
        });
      }
      if (data.scholarship_info.sapc_employee) {
        scholarship.sapc_employee = 5;
        scholarshipArr.push({ value: "sapc_employee", label: "Sapc Employee" });
      }
      if (data.scholarship_info.others) {
        scholarship.others = 6;
        scholarshipArr.push({ value: "others", label: "Others" });
      }
    }
    scholarship.scholarshipArr = scholarshipArr;
    scholarship.application_id = data.id;
    if (localStorage.getItem("role") == "Assesor") {
      if (data.health_additional_info) {
        if (
          data.health_additional_info.paymentForm &&
          !data.health_additional_info.paymentForm.tuition_fee
        ) {
          setPaymentForm(data.health_additional_info.paymentForm);
          // if (schoolFees && schoolFees.length >= 1) {
          //     schoolFees.map(fee => {
          //         if (data.health_additional_info.admissionAnswers.grade_year_level && data.grade_year_level.value == fee.grade_level_id) {
          //             // let subtotal = parseFloat(tertiaryTotalTuition) * 0.03;
          //             // let discount_total = parseFloat(tertiaryTotalTuition) - subtotal.toFixed(2);
          //             // fee.tuition_fee = tertiaryTotalTuition;
          //             // fee.discounted_tuituion_fee = discount_total;
          //             setPaymentForm(fee);
          //         }
          //     })
          // }
        } else {
          setPaymentForm(data.health_additional_info.paymentForm);
        }
      }
    } else if (
      localStorage.getItem("role") == "Guest" ||
      localStorage.getItem("role") == "Student"
    ) {
      if (data.health_additional_info) {
        if (
          data.health_additional_info.paymentForm &&
          !data.health_additional_info.paymentForm.tuition_fee
        ) {
          if (schoolFees && schoolFees.length >= 1) {
            schoolFees.map((fee) => {
              if (
                data.health_additional_info.admissionAnswers.grade_year_level &&
                data.health_additional_info.admissionAnswers.grade_year_level
                  .value == fee.grade_level_id
              ) {
                // let subtotal = parseFloat(tertiaryTotalTuition) * 0.03;
                // let discount_total = parseFloat(tertiaryTotalTuition) - subtotal.toFixed(2);
                // fee.tuition_fee = tertiaryTotalTuition;
                // fee.discounted_tuituion_fee = discount_total;
                setPaymentForm(fee);
              }
            });
          }
        } else {
          setPaymentForm(data.health_additional_info.paymentForm);
        }
      }
    }

    if (data.student_subject && data.student_subject.enrollee_type) {
      setSubjectForm({
        enrollee_type: data.student_subject.enrollee_type,
        subjects: data.student_subject.subjects,
      });
      setSubjects(data.student_subject.subjects);
      if (
        data.student_subject &&
        data.student_subject.enrollee_type.label == "Manually select subjects"
      ) {
        setSelectedSubjects(data.student_subject.subjects);
      }
    }

    setBasicForm(basic);
    setScholarShipForm(scholarship);
    setTesdaForm(basic);

    // if (localStorage.getItem("role") == "Assesor") {
    if (data.health_additional_info) {
      localStorage.setItem(
        "admissionAnswers",
        JSON.stringify(data.health_additional_info.admissionAnswers)
      );
    }
    // } else if (
    //   localStorage.getItem("role") == "Guest" ||
    //   localStorage.getItem("role") == "Student"
    // ) {
    //   if (data.health_additional_info) {
    //     localStorage.setItem(
    //       "admissionAnswers",
    //       JSON.stringify(data.health_additional_info.admissionAnswers)
    //     );
    //   }
    if (action == "viewMyApplication") {
      setViewMyApplication(true);
      setEducationSelected(true);
      setSelectedEducationType(data.education_type_id.name);
    } else if (action == "viewMyUploadedFiles") {
      setMyUploadedFileOpen(true);
    }
    // }
  } catch (err) {
    console.log(err);
    notifyError("Invalid Data.");
  }
}

async function uploadFiles(data, setButtonSpinner) {
  try {
    let res = await axios.post(ENDPOINT + `/assesor_upload`, data, {
      headers: headers,
    });
    // setReferenceNo(res.data.data.ref_no)
    setButtonSpinner(false);
    // setSubmissionModal(true)
    if (res.data.success) {
      notifySuccess("Upload Successful");
    }
    // notifySuccess('Successfully submit application form!');
  } catch (err) {
    if (err.response) {
      if (err.response.data && err.response.data.error) {
        notifyError(err.response.data.error.message);
      } else {
        notifyError("Submission Failed!");
      }
    }
    setButtonSpinner(false);
  }
}

async function cancelApplication(
  data,
  setButtonSpinner,
  setCancelApplication,
  setUpdateTable,
  updateTable
) {
  try {
    let res = await axios.post(ENDPOINT + `/cancel-application`, data, {
      headers: headers,
    });
    // setReferenceNo(res.data.data.ref_no)
    setButtonSpinner(false);
    // setSubmissionModal(true)
    if (res.data.success) {
      setCancelApplication(false);
      setUpdateTable(!updateTable);
      notifySuccess("Cancellation Complete");
    }
    // notifySuccess('Successfully submit application form!');
  } catch (err) {
    if (err.response) {
      if (err.response.data && err.response.data.error) {
        notifyError(err.response.data.error.message);
      } else {
        notifyError("Cancellation Failed!");
      }
    }
    setButtonSpinner(false);
  }
}

async function revertApplication(
  dataToSubmit,
  setButtonSpinner,
  setCancelApplication,
  setUpdateTable,
  updateTable
) {
  try {
    let res = await axios.post(ENDPOINT + `/reject-application`, dataToSubmit, {
      headers: headers,
    });

    setButtonSpinner(false);
    if (res.data.success) {
      setCancelApplication(false);
      setUpdateTable(!updateTable);
      notifySuccess("Update Complete");
    }
  } catch (err) {
    if (err.response) {
      notifyError("Error in updating application status");
    }
    setButtonSpinner(false);
  }
}

async function getMyBilling(
  setStudentPaymentModal,
  body,
  setPaymentApplication,
  setBillings
) {
  try {
    let res = await axios.post(ENDPOINT + `/get-billings`, body, {
      headers: headers,
    });
    const data = res.data.data.student_billing;
    let details = {
      scholarship: data.scholarship_type
        ? JSON.parse(data.scholarship_type)
        : null,
      approve_esc: data.approve_esc,
      esc_type: data.esc_type ? JSON.parse(data.esc_type) : null,
    };
    setBillings(res.data.data.student_billing.billings);
    setPaymentApplication(details);
    setStudentPaymentModal(true);
  } catch (err) {
    notifyError("Error in fetching application billing");
  }
}
