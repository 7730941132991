import React, { useState, useEffect } from "react";
import { subjectActions } from "../../../../services/SubjectServices";
import { useForm } from "react-hook-form";
import {
  Button,
  Col,
  Container,
  InputGroup,
  InputGroupAddon,
  Row,
  UncontrolledTooltip,
  Input,
} from "reactstrap";
import View from "../../../../assets/img/view.png";
import Edit from "../../../../assets/img/edit.png";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import overlayFactory from "react-bootstrap-table2-overlay";
import { isBrowser, isDesktop, isMobile } from "react-device-detect";
import Select from "react-select";
import AddEdit from "./AddEdit";
import Add from "../../../../assets/img/ADMISSION.png";
import { educationActions } from "../../../../services/EducationSelectionService";
import state from "../../States/States";

const Subjects = () => {
  const [data, setData] = useState([]);
  const [gradeTypes, setGradeTypes] = useState([]);
  const [gradeLevels, setGradeLevels] = useState([]);
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const { register, handleSubmit, errors, control, setValue, getValues } =
    useForm();
  const [gradeTypeFilter, setGradeTypeFilter] = useState("");
  const [gradeLevelFilter, setGradeLevelFilter] = useState("");
  const [semesterFilter, setSemesterFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [courseFilter, setCourseFilter] = useState("");
  const [action, setAction] = useState("");
  const [updateTable, setUpdateTable] = useState(false);
  const [gradeTypeSelection, setGradeTypeSelection] = useState([]);
  const [gradeLevelSelection, setGradeLevelSelection] = useState([]);
  const [coursesSelection, setCoursesSelection] = useState([]);
  const [gradeLevelFilterSelection, setGradeLevelFilterSelection] = useState(
    []
  );
  const [selectOptions, setSelectOptions] = useState({
    semester: [
      { value: 0, label: "First" },
      { value: 1, label: "Second" },
    ],
    status: [
      { value: 0, label: "InActive" },
      { value: 1, label: "Active" },
    ],
  });

  const [subject, setSubject] = useState(state.blankSubject);
  const withSemester = [4, 5];

  useEffect(() => {
    educationActions.getApplications(
      null,
      null,
      setGradeTypes,
      setGradeLevels,
      null,
      setCourses,
      null,
      null
    );
  }, []);

  useEffect(() => {
    setLoading(true);
    subjectActions.getSubjects(setData, setLoading);
  }, [updateTable]);

  useEffect(() => {
    if (gradeLevels.length >= 1) {
      let grLevels = gradeLevels.filter((type) => {
        return gradeTypeFilter == type.grade_type_id.id;
      });
      if (grLevels.length >= 1) {
        let newGradeArr = grLevels.map((grade) => {
          return {
            value: grade.id,
            label: grade.name,
            grade_type_id: grade.grade_type_id.id,
          };
        });
        setGradeLevelFilterSelection(newGradeArr);
      }
    }
  }, [gradeLevels, gradeTypeFilter]);

  useEffect(() => {
    if (gradeLevels.length >= 1) {
      let grLevels = gradeLevels.map((grade) => {
        return {
          value: grade.id,
          label: grade.name,
          grade_type_id: grade.grade_type_id.id,
        };
      });
      setGradeLevelSelection(grLevels);
    }
  }, [gradeLevels]);

  useEffect(() => {
    if (gradeTypes.length >= 1) {
      let grTypes = gradeTypes.map((type) => {
        return { value: type.id, label: type.name };
      });
      setGradeTypeSelection(grTypes);
    }
  }, [gradeTypes]);

  useEffect(() => {
    if (courses.length >= 1) {
      let studentCourses = courses.map((course) => {
        return {
          value: course.id,
          label: course.name,
          grade_type_id: course.grade_type_id,
        };
      });
      setCoursesSelection(studentCourses);
    }
  }, [courses]);

  let columns = [];
  if (withSemester.includes(gradeTypeFilter)) {
    columns = [
      {
        dataField: "grade_type_id",
        text: "Grade Type",
        sort: false,
        headerStyle: {
          backgroundColor: "#800000",
          color: "white",
          whiteSpace: "wrap",
        },
        formatter: (cell) => {
          let gradeType = gradeTypes.filter((type) => {
            return type.id == cell;
          });
          return gradeType[0].name;
        },
      },
      {
        dataField: "year_level_id",
        text: "Grade/Year Level",
        sort: false,
        headerStyle: {
          backgroundColor: "#800000",
          color: "white",
          whiteSpace: "wrap",
        },
        formatter: (cell) => {
          let gradeLevel = gradeLevels.filter((level) => {
            return level.id == cell;
          });
          return gradeLevel[0].name;
        },
      },
      {
        dataField: "semester",
        text: "Semester",
        sort: false,
        headerStyle: {
          backgroundColor: "#800000",
          color: "white",
          whiteSpace: "wrap",
        },
        formatter: (cell) => {
          return cell ? "Second" : "First";
        },
      },
      {
        dataField: "course_type_id",
        text: "Course",
        sort: false,
        headerStyle: {
          backgroundColor: "#800000",
          color: "white",
          whiteSpace: "wrap",
        },
        formatter: (cell) => {
          let studentCourses = courses.filter((course) => {
            return course.id == cell;
          });
          return studentCourses.length >= 1 ? studentCourses[0].name : "-";
        },
      },
      {
        dataField: "course_code",
        text: "Course Code",
        sort: false,
        headerStyle: {
          backgroundColor: "#800000",
          color: "white",
          whiteSpace: "wrap",
        },
      },
      {
        dataField: "description",
        text: "Description",
        sort: false,
        headerStyle: {
          backgroundColor: "#800000",
          color: "white",
          whiteSpace: "wrap",
        },
      },
      {
        dataField: "unit",
        text: "Unit",
        sort: true,
        headerStyle: {
          backgroundColor: "#800000",
          color: "white",
          whiteSpace: "wrap",
        },
      },
      {
        dataField: "lab_unit_price",
        text: "Lab Unit Price",
        sort: true,
        headerStyle: {
          backgroundColor: "#800000",
          color: "white",
          whiteSpace: "wrap",
        },
      },
      {
        dataField: "enabled",
        text: "Status",
        sort: false,
        headerStyle: {
          backgroundColor: "#800000",
          color: "white",
          whiteSpace: "wrap",
        },
        formatter: (cell) => {
          return cell ? "Active" : "InActive";
        },
      },
      {
        dataField: "created_at",
        text: "Date Created",
        sort: false,
        headerStyle: {
          backgroundColor: "#800000",
          color: "white",
          whiteSpace: "wrap",
        },
      },
      {
        dataField: "id",
        text: "Action",
        sort: false,
        headerStyle: {
          backgroundColor: "#800000",
          color: "white",
          whiteSpace: "wrap",
        },
        formatter: (cell, row) => {
          return (
            <div
              className="px-2 justify-content-center d-flex"
              style={{ minWidth: "50px" }}
            >
              <Button
                id={"upload-btn" + cell}
                onClick={() => toggleAddOrEdit(row, "edit")}
                className="btn-warning mr-2"
              >
                <img
                  src={Edit}
                  style={{ height: "1.5rem", objectFit: "fill" }}
                />
              </Button>
              <UncontrolledTooltip placement="top" target={"upload-btn" + cell}>
                Edit
              </UncontrolledTooltip>
            </div>
          );
        },
      },
    ];
  } else {
    columns = [
      {
        dataField: "grade_type_id",
        text: "Grade Type",
        sort: false,
        headerStyle: {
          backgroundColor: "#800000",
          color: "white",
          whiteSpace: "wrap",
        },
        formatter: (cell) => {
          if (gradeTypes.length) {
            let gradeType = gradeTypes.filter((type) => {
              return type.id == cell;
            });
            return gradeType[0].name;
          }
        },
      },
      {
        dataField: "year_level_id",
        text: "Grade/Year Level",
        sort: false,
        headerStyle: {
          backgroundColor: "#800000",
          color: "white",
          whiteSpace: "wrap",
        },
        formatter: (cell) => {
          if (gradeLevels.length) {
            let gradeLevel = gradeLevels.filter((level) => {
              return level.id == cell;
            });
            return gradeLevel[0].name;
          }
        },
      },
      {
        dataField: "description",
        text: "Description",
        sort: false,
        headerStyle: {
          backgroundColor: "#800000",
          color: "white",
          whiteSpace: "wrap",
        },
      },
      {
        dataField: "enabled",
        text: "Status",
        sort: false,
        headerStyle: {
          backgroundColor: "#800000",
          color: "white",
          whiteSpace: "wrap",
        },
        formatter: (cell) => {
          return cell ? "Active" : "InActive";
        },
      },
      {
        dataField: "created_at",
        text: "Date Created",
        sort: false,
        headerStyle: {
          backgroundColor: "#800000",
          color: "white",
          whiteSpace: "wrap",
        },
      },
      {
        dataField: "id",
        text: "Action",
        sort: false,
        headerStyle: {
          backgroundColor: "#800000",
          color: "white",
          whiteSpace: "wrap",
        },
        formatter: (cell, row) => {
          return (
            <div
              className="px-2 justify-content-center d-flex"
              style={{ minWidth: "50px" }}
            >
              <Button
                id={"upload-btn" + cell}
                onClick={() => toggleAddOrEdit(row, "edit")}
                className="btn-warning mr-2"
              >
                <img
                  src={Edit}
                  style={{ height: "1.5rem", objectFit: "fill" }}
                />
              </Button>
              <UncontrolledTooltip placement="top" target={"upload-btn" + cell}>
                Edit
              </UncontrolledTooltip>
            </div>
          );
        },
      },
    ];
  }

  const [addOrEditModal, setAddOrEditModal] = useState(false);
  const toggleAddOrEdit = (details, action) => {
    setSubject(state.blankSubject);
    setAction("");
    if (!addOrEditModal) {
      setAction(action);
      if (action == "edit") {
        setSubject(details);
      }
      setAddOrEditModal(!addOrEditModal);
    } else {
      setAddOrEditModal(!addOrEditModal);
    }
  };

  const NoDataIndication = () => <div>No Data</div>;

  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (data.length >= 1) {
      let newArr = [];
      //search INPUT
      let filteredSubjects = data.filter((d) => {
        return (
          d.description.toLowerCase().includes(searchText.toLowerCase()) ||
          d.course_code.toLowerCase().includes(searchText.toLowerCase())
        );
      });

      newArr = filteredSubjects;
      //filter
      if (semesterFilter !== "") {
        let filteredSemester = newArr.filter((f) => {
          return f.semester == semesterFilter;
        });

        newArr = filteredSemester;
      }

      if (gradeTypeFilter !== "") {
        let filteredGradeTypes = newArr.filter((f) => {
          return f.grade_type_id == gradeTypeFilter;
        });

        newArr = filteredGradeTypes;
      }

      if (gradeLevelFilter !== "") {
        let filteredGradeLevels = newArr.filter((f) => {
          return f.year_level_id == gradeLevelFilter;
        });

        newArr = filteredGradeLevels;
      }

      if (courseFilter !== "") {
        let filteredCourses = newArr.filter((f) => {
          return f.course_type_id == courseFilter;
        });

        newArr = filteredCourses;
      }
      setFilteredData(newArr);
    }
  }, [
    gradeLevelFilter,
    courseFilter,
    semesterFilter,
    gradeTypeFilter,
    searchText,
    data,
  ]);

  return (
    <div className="p-4">
      {addOrEditModal ? (
        <AddEdit
          addOrEditModal={addOrEditModal}
          setAddOrEditModal={setAddOrEditModal}
          toggleAddOrEdit={toggleAddOrEdit}
          subject={subject}
          action={action}
          setUpdateTable={setUpdateTable}
          updateTable={updateTable}
          selectOptions={selectOptions}
          gradeTypeSelection={gradeTypeSelection}
          gradeLevelSelection={gradeLevelSelection}
          coursesSelection={coursesSelection}
        />
      ) : (
        ""
      )}
      <Row>
        <Col md="12" xs="12" className="mb-3">
          <Button
            className="btn bg-danger-edit"
            onClick={() => toggleAddOrEdit("", "add")}
          >
            <img src={Add} style={{ height: "1rem" }} />
          </Button>
        </Col>
        <Col md="4" xs="12">
          <Select
            options={gradeTypeSelection}
            onChange={(e) => {
              setGradeTypeFilter(e.value);
              return e;
            }}
            defaultValue=""
          />
          <p>
            <small className="font-weight-bolder">
              <i className="fa fa-lightbulb-o"></i>&nbsp;Filter by Grade Type
            </small>
          </p>
        </Col>
        <Col md="4" xs="12">
          <Select
            options={gradeLevelFilterSelection}
            onChange={(e) => {
              setGradeLevelFilter(e.value);
            }}
            defaultValue=""
          />
          <p>
            <small className="font-weight-bolder">
              <i className="fa fa-lightbulb-o"></i>&nbsp;Filter by Grade Level
            </small>
          </p>
        </Col>
        {withSemester.includes(gradeTypeFilter) ? (
          <>
            <Col md="4" xs="12">
              <Select
                options={coursesSelection}
                onChange={(e) => {
                  setCourseFilter(e.value);
                  return e;
                }}
                defaultValue=""
              />
              <p>
                <small className="font-weight-bolder">
                  <i className="fa fa-lightbulb-o"></i>&nbsp;Filter by Course
                  (For Senior High and College)
                </small>
              </p>
            </Col>
            <Col md="4" xs="12">
              <Select
                options={selectOptions.semester}
                onChange={(e) => {
                  setSemesterFilter(e.value);
                  return e;
                }}
                defaultValue=""
              />
              <p>
                <small className="font-weight-bolder">
                  <i className="fa fa-lightbulb-o"></i>&nbsp;Filter by Semester
                </small>
              </p>
            </Col>
          </>
        ) : (
          ""
        )}
        <Col md="4" xs="12">
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <Button
                type="button"
                color="primary"
                id="search-button"
                style={{ zIndex: "0" }}
              >
                <i className="fa fa-search"></i>
              </Button>
            </InputGroupAddon>
            <Input
              type="text"
              id="search-text"
              name="search-text"
              placeholder="Search"
              onChange={(e) => setSearchText(e.target.value)}
            />
          </InputGroup>
          <p>
            <small className="font-weight-bolder">
              <i className="fa fa-lightbulb-o"></i>&nbsp;Search by Subject
              Course Code and Description
            </small>
          </p>
        </Col>
      </Row>

      <BootstrapTable
        wrapperClasses="table-responsive mb-3 text-center"
        bootstrap4
        striped
        keyField="id"
        data={filteredData}
        columns={columns}
        loading={loading}
        classes={isMobile ? "mobileTable" : "desktopTable"}
        pagination={paginationFactory()}
        overlay={overlayFactory({
          spinner: true,
          styles: {
            overlay: (base) => ({
              ...base,
              background: "rgba(0, 0, 0, 0.5)",
            }),
          },
        })}
        noDataIndication={() => <NoDataIndication />}
      />
    </div>
  );
};

export default Subjects;
