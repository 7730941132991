import { ENDPOINT } from "../constants/api";
import axios from "axios";
import { notifyError, notifySuccess } from "./Toaster";
import moment from "moment";

export const feesActions = {
  getFees,
  addFee,
  // getRoles,
  updateFee
};

const token = localStorage.getItem("jwtToken")
  ? localStorage.getItem("jwtToken")
  : "";
const headers = {
  Authorization: `Bearer ${token}`,
  "Content-Type": "application/json",
  "X-Requested-With": "XMLHttpRequest",
};

async function getFees(setData, setLoading, gradeLevels, escDiscounts) {
  try {
    let res = await axios.get(ENDPOINT + `/get-all-fee`, {
      headers: headers,
    });

    let schoolFees = res.data.data.school_fee;
    let newSchoolFees = schoolFees.map(fee=>{
      let newFee = JSON.parse(fee.student_fee);
      // let gradeLevel = gradeLevels.filter(level=>{
      //   return fee.grade_level_id == level.id
      // })
      return{
        id: fee.id,
        grade_level_id: fee.grade_level_id,
        grade_level: "",
        tuition_fee: newFee.tuition_fee,
        miscellaneous_fee: newFee.miscellaneous_fee,
        other_fee: newFee.other_fee,
        enabled: fee.enabled,
        payment_mode: newFee.payment_mode
      }
    })
    setData(newSchoolFees);
    setLoading(false);
  } catch (err) {
    if (err.response) {
      notifyError("Error while fetching Data");
    }
  }
}

async function addFee(dataToSubmit, setButtonSpinner, setUpdateTable, updateTable, setAddOrEditModal) {
  try {
    let res = await axios.post(ENDPOINT + `/add-fee`, dataToSubmit, {
      headers: headers,
    });
    
    if(res.data.success){
        notifySuccess(res.data.message);
        setUpdateTable(!updateTable);
        setAddOrEditModal(false);
    }else{
      notifyError(res.data.data.Error);
    }
    setButtonSpinner(false)
  } catch (err) {
    if (err.response) {
      notifyError("Error while adding fee.");
    }
    setButtonSpinner(false)
  }
}

async function updateFee(dataToSubmit, setButtonSpinner, setUpdateTable, updateTable, setAddOrEditModal) {
    try {
      let res = await axios.post(ENDPOINT + `/update-fee`, dataToSubmit, {
        headers: headers,
      });

      if(res.data.success){
          notifySuccess(res.data.message);
          setUpdateTable(!updateTable);
          setAddOrEditModal(false);
      }else{
        notifyError(res.data.data.Error);
      }
      setButtonSpinner(false)
    } catch (err) {
      if (err.response) {
        notifyError("Error while updating fee.");
      }
      setButtonSpinner(false)
    }
  }

async function getRoles(selectOptions, setSelectOptions) {
    try {
      let res = await axios.get(ENDPOINT + `/get-system-role`, {
        headers: headers,
      });
      let roles = res.data.data.system_role;
      let rolesArr = roles.filter(role=>{
          return role.name !== "Guest" && role.name !== "Teacher" && role.name !== "Student";
      })
      
      let roleSelections = rolesArr.map((role)=>{
        return {value: role.id, label: role.name}
      })
      
      setSelectOptions({...selectOptions, role: roleSelections})
    } catch (err) {
      if (err.response) {
        notifyError("Error while fetching Data");
      }
    }
  }
  