import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  InputGroup,
  InputGroupAddon,
  Row,
  Input,
  UncontrolledTooltip,
  Form,
  FormGroup,
  Label,
} from "reactstrap";
import { registrarActions } from "../../../services/RegistrarServices";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import overlayFactory from "react-bootstrap-table2-overlay";
import Edit from "../../../assets/img/edit.png";
import View from "../../../assets/img/view.png";
import { isBrowser, isDesktop, isMobile } from "react-device-detect";
import Select from "react-select";
import EditStudentList from "./EditStudentList";
import ViewStudentList from "./ViewStudentList";
import File from "../../../assets/img/UPLOAD.png";
import StudentSchedules from "./StudentSchedules";
import states from "../States/States";
import { educationActions } from "../../../services/EducationSelectionService.js";
import MobileSideBar from "../Admission/MobileSidebar";
import optionList from "../Admission/optionList";
import { notifyError } from "../../../services/Toaster";
import { useForm, Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const StudentSections = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [updateTable, setUpdateTable] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [gradeLevels, setGradeLevels] = useState([]);
  const [courses, setCourses] = useState([]);
  const [gradeLevelSelection, setGradeLevelSelection] = useState([]);
  const [gradeLevelFilter, setGradeLevelFilter] = useState("");
  const [gradeLevelIdFilter, setGradeLevelIdFilter] = useState("");
  const [section, setSection] = useState(states.blankSection);
  const withSemester = [4, 5];
  const { register, handleSubmit, errors, control, setValue, getValues } =
    useForm();

  useEffect(() => {
    educationActions.getApplications(
      null,
      null,
      null,
      setGradeLevels,
      null,
      setCourses,
      null,
      null
    );
  }, []);

  useEffect(() => {
    if (gradeLevels.length >= 1) {
      let level = gradeLevels.map((grade) => {
        return {
          value: grade.id,
          label: grade.name,
          grade_type: grade.grade_type_id.id,
        };
      });
      setGradeLevelSelection(level);
    }
  }, [gradeLevels]);

  const [filters, setFilters] = useState({
    gradeLevel: "",
    semester: "",
    start_year: "",
    end_year: "",
  });

  useEffect(() => {
    if (updateTable) {
      setLoading(true);
      registrarActions.getSections(
        setData,
        setLoading,
        courses,
        filters,
        semesterFilter,
        gradeLevelIdFilter,
        searchText
      );
    }
  }, [updateTable]);

  const onSubmit = (newData) => {
    if (filters.gradeLevel) {
      setLoading(true);
      registrarActions.getSections(
        setData,
        setLoading,
        courses,
        filters,
        semesterFilter,
        gradeLevelIdFilter,
        searchText
      );
    }
  };

  const columns = [
    {
      dataField: "grade_level",
      text: "Grade Level",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "section",
      text: "Section Name",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "totalStudents",
      text: "Total Students",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "adviser",
      text: "Adviser",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    // {
    //   dataField: "coordinator",
    //   text: "Coordinator",
    //   sort: false,
    //   headerStyle: {
    //     backgroundColor: "#800000",
    //     color: "white",
    //     whiteSpace: "wrap",
    //   },
    // },
    {
      dataField: "id",
      text: "Action",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
      formatter: (cell, row) => {
        return (
          <div className="px-2 text-center" style={{ minWidth: "50px" }}>
            <Button
              id={"view-btn" + cell}
              onClick={() => toggleViewStudentList(cell, row)}
              className="btn-warning mr-2"
            >
              <img
                src={View}
                style={{
                  height: isDesktop ? "1.5rem" : "1.1rem",
                  objectFit: "fill",
                }}
              />
            </Button>
            <UncontrolledTooltip placement="top" target={"view-btn" + cell}>
              View Details
            </UncontrolledTooltip>

            <Button
              id={"edit-btn" + cell}
              onClick={() => toggleEditStudentLists(cell, row)}
              className="btn-warning mr-2"
            >
              <img
                src={Edit}
                style={{
                  height: isDesktop ? "1.5rem" : "1.1rem",
                  objectFit: "fill",
                }}
              />
            </Button>
            <UncontrolledTooltip placement="top" target={"edit-btn" + cell}>
              Edit Student List
            </UncontrolledTooltip>

            <Button
              id={"upload-file-btn" + cell}
              onClick={() => toggleUploadFile(cell, row)}
              className="btn-warning"
            >
              <img
                src={File}
                style={{
                  height: isDesktop ? "1.5rem" : "1.1rem",
                  objectFit: "fill",
                }}
              />
            </Button>
            <UncontrolledTooltip
              placement="top"
              target={"upload-file-btn" + cell}
            >
              Upload Schedule
            </UncontrolledTooltip>
          </div>
        );
      },
    },
  ];

  const [addOrEditStudentList, setAddOrEditStudentList] = useState(false);
  const [action, setAction] = useState("");
  const [semesterFilter, setSemesterFilter] = useState("");
  const [sectionDetails, setSectionDetails] = useState(
    states.blankSectionDetails
  );

  const toggleViewStudentList = (id, details) => {
    setSection(states.blankSection);
    setAction("");
    setSectionDetails(states.blankSectionDetails);
    if (!addOrEditStudentList) {
      if (
        (withSemester.includes(details.grade_type) && semesterFilter !== "") ||
        !withSemester.includes(details.grade_type)
      ) {
        setAction("view");
        setSectionDetails(details);
        let IDs = {
          grade_level_id: details.grade_level_id,
          section_id: id,
          grade_type: details.grade_type,
          semester: semesterFilter,
          start_year: filters.start_year,
          end_year: filters.end_year,
        };
        registrarActions.getSection(
          IDs,
          setAddOrEditStudentList,
          setSection,
          details,
          action
        );
      } else {
        notifyError("Please select semester");
      }
    } else {
      setAddOrEditStudentList(!addOrEditStudentList);
    }
  };

  const toggleEditStudentLists = (id, details) => {
    setSection(states.blankSection);
    setAction("");
    if (!addOrEditStudentList) {
      if (
        (withSemester.includes(details.grade_type) && semesterFilter !== "") ||
        !withSemester.includes(details.grade_type)
      ) {
        setAction("update");
        let IDs = {
          grade_level_id: details.grade_level_id,
          section_id: id,
          grade_type: details.grade_type,
          course: details.course_type_id,
          semester: semesterFilter,
          start_year: filters.start_year,
          end_year: filters.end_year,
        };
        registrarActions.getSection(
          IDs,
          setAddOrEditStudentList,
          setSection,
          details
        );
      } else {
        notifyError("Please select semester");
      }
    } else {
      setAddOrEditStudentList(!addOrEditStudentList);
    }
  };

  const [uploadModal, setUploadModal] = useState(false);
  const [sectionId, setSectionId] = useState(false);
  const toggleUploadFile = (id, details) => {
    setSectionId("");
    setSectionDetails(states.blankSectionDetails);
    if (!uploadModal) {
      setUploadModal(!uploadModal);
      setSectionId(id);
      details.document = "";
      setSectionDetails(details);
    } else {
      setUploadModal(!uploadModal);
    }
  };

  const changeQuery = (e) => {
    setSearchText(e.target.value);
  };

  const NoDataIndication = () => <div>No Data</div>;

  return (
    <div className="p-4">
      {addOrEditStudentList && action == "update" ? (
        <EditStudentList
          editStudentLists={addOrEditStudentList}
          setEditStudents={setAddOrEditStudentList}
          toggleEditStudentLists={toggleEditStudentLists}
          section={section}
          updateTable={updateTable}
          setUpdateTable={setUpdateTable}
        />
      ) : (
        <ViewStudentList
          addOrEditStudentList={addOrEditStudentList}
          toggleViewStudentList={toggleViewStudentList}
          section={section}
          sectionDetails={sectionDetails}
          semesterFilter={semesterFilter}
          setSemesterFilter={setSemesterFilter}
        />
      )}
      {uploadModal ? (
        <StudentSchedules
          uploadModal={uploadModal}
          setUploadModal={setUploadModal}
          toggleUploadFile={toggleUploadFile}
          sectionId={sectionId}
          sectionDetails={sectionDetails}
          setSectionDetails={setSectionDetails}
          updateTable={updateTable}
          setUpdateTable={setUpdateTable}
        />
      ) : (
        ""
      )}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="pt-4">
          <Col md="3" xs="12">
            <FormGroup>
              <Label>
                <small className="font-weight-bolder">S.Y. From</small>
              </Label>
              <Controller
                control={control}
                name="start_year"
                defaultValue=""
                rules={{ required: true }}
                invalid={errors.start_year ? true : false}
                render={({ onChange, value, onBlur, name }) => (
                  <DatePicker
                    className="form-control bg-light"
                    selected={
                      moment(filters.start_year).isValid()
                        ? moment(filters.start_year).toDate()
                        : filters.start_year
                    }
                    onChange={(date) => {
                      // setStartDate(date);
                      setValue("start_year", date);
                      setFilters({
                        ...filters,
                        start_year: moment(date).format("YYYY"),
                      });
                      return date;
                    }}
                    showYearPicker
                    dateFormat="yyyy"
                    yearItemNumber={10}
                    name="start_year"
                    defaultValue=""
                  />
                )}
              />
              {errors.start_year && errors.start_year.type == "required" && (
                <div
                  style={{
                    marginTop: "0.25rem",
                    fontSize: "80%",
                    color: "#f86c6b",
                  }}
                >
                  Schoo Year From is required!
                </div>
              )}
            </FormGroup>
          </Col>
          <Col md="3" xs="12">
            <FormGroup>
              <Label>
                <small className="font-weight-bolder">S.Y. To</small>
              </Label>
              <Controller
                control={control}
                name="end_year"
                defaultValue=""
                rules={{
                  required: true,
                  validate: (value) => value > getValues("start_year"),
                }}
                invalid={errors.end_year ? true : false}
                render={({ onChange, value, onBlur, name }) => (
                  <DatePicker
                    className="form-control bg-light"
                    selected={
                      moment(filters.end_year).isValid()
                        ? moment(filters.end_year).toDate()
                        : filters.end_year
                    }
                    onChange={(date) => {
                      setValue("end_year", date);
                      setFilters({
                        ...filters,
                        end_year: moment(date).format("YYYY"),
                      });
                      return date;
                    }}
                    showYearPicker
                    dateFormat="yyyy"
                    yearItemNumber={10}
                    name="end_year"
                    defaultValue=""
                  />
                )}
              />
              {errors.end_year && errors.end_year.type == "required" && (
                <div
                  style={{
                    marginTop: "0.25rem",
                    fontSize: "80%",
                    color: "#f86c6b",
                  }}
                >
                  School Year To is required!
                </div>
              )}
              {errors.end_year && errors.end_year.type == "validate" && (
                <div
                  style={{
                    marginTop: "0.25rem",
                    fontSize: "80%",
                    color: "#f86c6b",
                  }}
                >
                  School Year To cannot be lower or equal to S.Y. From!
                </div>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row className="pb-4">
          <Col md="3" xs="12">
            <Controller
              control={control}
              id="gradeLevel"
              name="gradeLevel"
              rules={{ required: true }}
              invalid={errors.gradeLevel ? true : false}
              defaultValue=""
              render={({ onChange, value, onBlur, name }) => (
                <Select
                  options={gradeLevelSelection}
                  onChange={(e) => {
                    setValue("semester", "");
                    setValue("gradeLevel", e);
                    setGradeLevelIdFilter(e.grade_type);
                    setFilters({
                      ...filters,
                      gradeLevel: e.value,
                      semester: "",
                    });
                    return e;
                  }}
                  defaultValue=""
                />
              )}
            />
            {errors.gradeLevel && (
              <small className="text-danger">Grade level is required</small>
            )}
            <p className="mb-1">
              <small className="font-weight-bolder">
                <i className="fa fa-lightbulb-o"></i>&nbsp;Filter by Grade Level
              </small>
            </p>
          </Col>

          {gradeLevelIdFilter && withSemester.includes(gradeLevelIdFilter) ? (
            <Col md="3" xs="12">
              <Controller
                control={control}
                id="semester"
                name="semester"
                rules={{ required: true }}
                invalid={errors.semester ? true : false}
                defaultValue=""
                render={({ onChange, value, onBlur, name }) => (
                  <Select
                    options={optionList.semester}
                    onChange={(e) => {
                      setValue("semester", e);
                      setSemesterFilter(e.value);
                      setFilters({ ...filters, semester: e.value });
                      return e;
                    }}
                    defaultValue=""
                  />
                )}
              />
              {errors.semester && (
                <small className="text-danger">Semester is required</small>
              )}
              <p>
                <small className="font-weight-bolder">
                  <i className="fa fa-lightbulb-o"></i>&nbsp;Filter by Semester
                </small>
              </p>
            </Col>
          ) : (
            ""
          )}
          <Col md="4" xs="12" className="ml-auto">
            <InputGroup>
              <InputGroupAddon addonType="prepend" style={{ zIndex: "0" }}>
                <Button type="button" color="primary" id="search-button">
                  <i className="fa fa-search"></i>
                </Button>
              </InputGroupAddon>
              <Input
                type="text"
                id="search-text"
                name="search-text"
                placeholder="Search"
                onChange={changeQuery}
              />
            </InputGroup>
            <p>
              <small className="font-weight-bolder">
                <i className="fa fa-lightbulb-o"></i>&nbsp;Search by Section
                Name
              </small>
            </p>
          </Col>
          <Col md="12" xs="12">
            <Button className="bg-danger-edit text-white border-0 mt-3">
              Filter
            </Button>
          </Col>
        </Row>
      </Form>
      <BootstrapTable
        wrapperClasses="table-responsive mb-3 text-center"
        bootstrap4
        striped
        keyField="id"
        data={data}
        columns={columns}
        loading={loading}
        classes={isMobile ? "mobileTable" : "desktopTable"}
        pagination={paginationFactory()}
        overlay={overlayFactory({
          spinner: true,
          styles: {
            overlay: (base) => ({
              ...base,
              background: "rgba(0, 0, 0, 0.5)",
            }),
          },
        })}
        noDataIndication={() => <NoDataIndication />}
      />
    </div>
  );
};
export default StudentSections;
