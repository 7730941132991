import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Label,
  Input,
  FormGroup,
  Form,
  Spinner,
  Table,
} from "reactstrap";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { teacherSubjectsActions } from "../../../../services/TeacherSubjectsServices";
import Select from "react-select";
import MultiSelect from "@kenshooui/react-multi-select";
import "@kenshooui/react-multi-select/dist/style.css";

const AddorEditModal = ({
  toggleAddOrEdit,
  addOrEditModal,
  setAddOrEditModal,
  action,
  teacherSubjects,
  educationTypeSelection,
  gradeLevelSelection,
  sectionSelection,
  subjectSelection,
  setUpdateTable,
  updateTable,
  coursesSelection
}) => {
  const { register, handleSubmit, errors, control, setValue, getValues, resetField } = useForm();
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [selectedEducationType, setSelectedEducationType] = useState("");
  const [newGradeLevelSelection, setNewGradeLevelSelection] = useState([]);
  const [selectedGradeLevel, setSelectedGradeLevel] = useState("");
  const [newSubjectSelection, setNewSubjectSelection] = useState([]);
  const [selectedSemester, setSelectedSemester] = useState("");
  const [newSectionSelection, setNewSectionSelection] = useState([])
  const [gradeTypeId, setGradeTypeId] = useState("")
  const [sectionId, setSectionId] = useState("");
  const [duplicateError, setDuplicateError] = useState(false)
  const [selectError, setSelectError] = useState(false)
  const [addSubject, setAddSubject] = useState(false);
  const [selectedSubjects, setSelectedSubjects] = useState([])
  const [selectedCourse, setSelectedCourse] = useState("")
  const [newCourseSelection, setNewCourseSelection] = useState([])
  const [loading, setLoading] = useState(false)

  const handleSubjectLists = (e) => {
    setDuplicateError(false)
    setSelectError(false)
    let duplicate = false;
    if (selectedSubjects.length >= 1) {
      let newSubjects = selectedSubjects;
      newSubjects.map(sub => {
        if (e.value == sub.value && (sectionId && sectionId.value === sub.section_id)) {
          duplicate = true;
        }
      })
      if (!duplicate) {
        e.grade_level_id = selectedGradeLevel !== "" ? selectedGradeLevel.value : "";
        e.grade_level_name = selectedGradeLevel !== "" ? selectedGradeLevel.label : "";
        e.section_id = sectionId !== "" ? sectionId.value : "";
        e.section_name = sectionId !== "" ? sectionId.label : "";
        e.grade_type_id = gradeTypeId;
        e.course_type_id = selectedCourse;
        e.semester = selectedSemester;
        setSelectedSubjects([...selectedSubjects, e]);
        let newData = {
          id: teacherSubjects.id,
          list_of_subjects: selectedSubjects
        }
        teacherSubjectsActions.updateTeacherSubject(newData, setButtonSpinner, setUpdateTable, updateTable, null)
      } else {
        setDuplicateError(true)
      }
    } else {
      e.grade_level_id = selectedGradeLevel !== "" ? selectedGradeLevel.value : "";
      e.grade_level_name = selectedGradeLevel !== "" ? selectedGradeLevel.label : "";
      e.section_id = sectionId !== "" ? sectionId.value : "";
      e.section_name = sectionId !== "" ? sectionId.label : "";
      e.grade_type_id = gradeTypeId;
      e.course_type_id = selectedCourse;
      e.semester = selectedSemester;
      setSelectedSubjects([...selectedSubjects, e]);
      let newData = {
        id: teacherSubjects.id,
        list_of_subjects: selectedSubjects
      }
      teacherSubjectsActions.updateTeacherSubject(newData, setButtonSpinner, setUpdateTable, updateTable, null)
    }
  };

  useEffect(() => {
    if (teacherSubjects && teacherSubjects.list_of_subjects.length >= 1) {
      setSelectedSubjects(teacherSubjects.list_of_subjects)
    }
  }, [teacherSubjects])

  const [semesterSelection, setSemesterSelection] = useState([
    { value: 0, label: "First" },
    { value: 1, label: "Second" },
  ])

  const withSemester = [4, 5];
  const getOptionLabelByValue = (options, value) => {
    const option = options.find((option) => option.value == value);
    return option ? option.label : "";
  };

  useEffect(() => {
    if (selectedEducationType !== "") {
      const glArr = gradeLevelSelection.filter(gl => {
        return gl.education_type_id == selectedEducationType;
      })
      setNewGradeLevelSelection(glArr);
    }
  }, [selectedEducationType])

  useEffect(() => {
    let newCourses = coursesSelection.filter(course => {
      return course.grade_type_id == gradeTypeId
    })

    setNewCourseSelection(newCourses)

  }, [gradeTypeId])

  useEffect(() => {
    if (selectedGradeLevel !== "" && sectionId !== "") {
      if (withSemester.includes(gradeTypeId) && selectedSemester !== "" && selectedCourse !== "") {
        teacherSubjectsActions.getTeacherSubjectSelection(selectedGradeLevel.value, gradeTypeId, selectedSemester, selectedCourse, setNewSubjectSelection)
      } else if (!withSemester.includes(gradeTypeId)) {
        teacherSubjectsActions.getTeacherSubjectSelection(selectedGradeLevel.value, gradeTypeId, 0, 0, setNewSubjectSelection)
      }
    } else {
      setNewSubjectSelection([]);
    }
  }, [selectedGradeLevel, selectedSemester, sectionId, selectedCourse])

  useEffect(() => {
    if (selectedGradeLevel !== "") {
      const glArr = [];
      if (withSemester.includes(gradeTypeId) && selectedSemester !== "" && selectedCourse !== "") {
        sectionSelection.map(gl => {
          if (gl.course_type_id == selectedCourse && gl.grade_level_id == selectedGradeLevel.value) glArr.push(gl)
        })
      }

      if (!withSemester.includes(gradeTypeId)) {
        sectionSelection.map(gl => {
          if (gl.grade_level_id == selectedGradeLevel.value) {
            glArr.push(gl)
          }
        })
      }
      setNewSectionSelection(glArr);
    }
  }, [selectedGradeLevel, selectedCourse, selectedSemester])

  // useEffect(()=>{
  //   if(gradeTypeId !== ""){

  //   }
  // }, [gradeTypeId])

  const handleRemoveSubject = (index) => {
    if (selectedSubjects.length >= 1) {
      setAddSubject(false);
      const selectedSubjArr = selectedSubjects.filter((sub, i) => {
        return i != index;
      })
      setSelectedSubjects(selectedSubjArr)
      setAddSubject(true);
    }
  }

  const onSubmit = (data) => {
    setButtonSpinner(true)
    let newData = {
      id: teacherSubjects.id,
      list_of_subjects: selectedSubjects
    }
    teacherSubjectsActions.updateTeacherSubject(newData, setButtonSpinner, setUpdateTable, updateTable, setAddOrEditModal)
  };

  console.log(getValues("semester"))
  return (
    <Modal
      isOpen={addOrEditModal}
      toggle={toggleAddOrEdit}
      size="lg"
      backdrop="static"
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader toggle={toggleAddOrEdit}>
          {action == "add" ? "Add" : "Edit"} Teacher Subject
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md="6" xs="12">
              <FormGroup>
                <Label
                  htmlFor="education_type_id"
                  className="text-left w-100 text-danger-edit font-weight-bolder"
                >
                  Select Education Type
                </Label>
                <Controller
                  control={control}
                  name="education_type_id"
                  defaultValue=""
                  rules={{ required: false }}
                  invalid={errors.education_type_id ? true : false}
                  render={({ onChange, value, onBlur, name, resetField }) => (
                    <Select
                      options={educationTypeSelection}
                      name="education_type_id"
                      onChange={(e) => {
                        setValue("education_type_id", e);
                        setSelectedEducationType(e.value)
                        setValue("grade_level_id", "");
                        setValue("section_id", "");
                        setValue("semester", "");
                        setValue("course", "");
                        setValue("subjects", "");
                        setNewSectionSelection([])
                        setNewCourseSelection([])
                        setGradeTypeId("")
                        setAddSubject(false)
                        return e;
                      }}
                      defaultValue=""
                    />
                  )}
                />
                {errors.education_type_id &&
                  errors.education_type_id.type == "required" && (
                    <small className="text-danger">
                      Education type is required!
                    </small>
                  )}
              </FormGroup>
            </Col>
            <Col md="6" xs="12">
              <FormGroup>
                <Label
                  htmlFor="grade_level_id"
                  className="text-left w-100 text-danger-edit font-weight-bolder"
                >
                  Select Grade Level
                </Label>
                <Controller
                  control={control}
                  name="grade_level_id"
                  defaultValue=""
                  rules={{ required: false }}
                  invalid={errors.grade_level_id ? true : false}
                  render={({ onChange, value, onBlur, name }) => (
                    <Select
                      options={newGradeLevelSelection}
                      name="grade_level_id"
                      onChange={(e) => {
                        setValue("grade_level_id", e);
                        setValue("section_id", "");
                        setValue("semester", "");
                        setValue("course", "");
                        setValue("subjects", "");
                        setSelectedGradeLevel(e)
                        setGradeTypeId(e.grade_type_id)
                        setSelectedSemester("")
                        setSectionId("")
                        setAddSubject(false)
                        return e;
                      }}
                      defaultValue={getValues("grade_level_id")}
                      value={getValues("grade_level_id")}
                    />
                  )}
                />
                {errors.grade_level_id &&
                  errors.grade_level_id.type == "required" && (
                    <small className="text-danger">
                      Grade Level is required!
                    </small>
                  )}
              </FormGroup>
            </Col>
            {withSemester.includes(gradeTypeId) ?
              <>
                <Col md="6" xs="12">
                  <FormGroup>
                    <Label
                      htmlFor="course"
                      className="text-left w-100 text-danger-edit font-weight-bolder"
                    >
                      Select Course
                    </Label>
                    <Controller
                      control={control}
                      name="course"
                      rules={{ required: false }}
                      invalid={errors.course ? true : false}
                      render={({ onChange, value, onBlur, name }) => (
                        <Select
                          options={newCourseSelection}
                          name="course"
                          onChange={(e) => {
                            setValue("course", e);
                            setSelectedCourse(e.value)
                            setAddSubject(false)
                            setValue("section_id", "");
                            setValue("semester", "");
                            setValue("subjects", "");
                            return e;
                          }}
                          defaultValue={getValues("course")}
                          value={getValues("course")}
                        />
                      )}
                    />
                    {errors.course &&
                      errors.course.type == "required" && (
                        <small className="text-danger">
                          Course is required!
                        </small>
                      )}
                  </FormGroup>
                </Col>
                <Col md="6" xs="12">
                  <FormGroup>
                    <Label
                      htmlFor="semester"
                      className="text-left w-100 text-danger-edit font-weight-bolder"
                    >
                      Select Semester
                    </Label>
                    <Controller
                      control={control}
                      name="semester"
                      rules={{ required: true }}
                      invalid={errors.semester ? true : false}
                      render={({ onChange, value, onBlur, name }) => (
                        <Select
                          options={semesterSelection}
                          onChange={(e) => {
                            setValue("semester", e);
                            setSelectedSemester(e.value)
                            setAddSubject(false)
                            setValue("section_id", "");
                            setValue("subjects", "");
                            return e;
                          }}
                          defaultValue={getValues("semester")}
                          value={getValues("semester")}
                        />
                      )}
                    />
                    {errors.semester &&
                      errors.semester.type == "required" && (
                        <small className="text-danger">
                          Semester is required!
                        </small>
                      )}
                  </FormGroup>
                </Col>
              </>
              : ""}
            <Col md="6" xs="12">
              <FormGroup>
                <Label
                  htmlFor="section_id"
                  className="text-left w-100 text-danger-edit font-weight-bolder"
                >
                  Select Section
                </Label>
                <Controller
                  control={control}
                  name="section_id"
                  rules={{ required: false }}
                  invalid={errors.section_id ? true : false}
                  render={({ onChange, value, onBlur, name }) => (
                    <Select
                      options={newSectionSelection}
                      name="section_id"
                      onChange={(e) => {
                        setValue("section_id", e);
                        setSectionId(e);
                        setAddSubject(false)
                        setValue("subjects", "");
                        return e;
                      }}
                      defaultValue={getValues("section_id")}
                      value={getValues("section_id")}
                    />
                  )}
                />
                {errors.section_id &&
                  errors.section_id.type == "required" && (
                    <small className="text-danger">
                      Section is required!
                    </small>
                  )}
              </FormGroup>
            </Col>
            <Col md="12" xs="12" className="mb-3">
              <FormGroup>
                <Label
                  htmlFor="subjects"
                  className="text-left w-100 text-danger-edit font-weight-bolder"
                >
                  Select Subject
                </Label>
                <Controller
                  control={control}
                  name="subjects"
                  rules={{ required: false }}
                  invalid={errors.subjects ? true : false}
                  render={({ onChange, value, onBlur, name }) => (
                    <Select
                      options={newSubjectSelection}
                      name="subjects"
                      onChange={(e) => {
                        setValue("subjects", e);
                        handleSubjectLists(e)
                        setAddSubject(false)
                        return e;
                      }}
                      defaultValue={getValues("subjects")}
                      value={getValues("subjects")}
                    />
                  )}
                />
                {duplicateError ? <small className="text-danger">Duplicate Subject</small> : ""}
                {selectError ? <small className="text-danger">Invalid Grade Level or Section</small> : ""}
              </FormGroup>
            </Col>
            <Col xs="12" md="12">
              <Table striped bordered className="text-center">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Grade Level</th>
                    <th>Section</th>
                    <th>Subjects</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedSubjects.length >= 1 ?
                    selectedSubjects.map((subj, i) => {
                      return (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{subj.grade_level_name}</td>
                          <td>{subj.section_name}</td>
                          <td>{subj.label}</td>
                          <td>
                            <Button className="btn btn-danger" onClick={() => handleRemoveSubject(i)}>X</Button>
                          </td>
                        </tr>
                      )
                    })
                    :
                    <tr>
                      <td colSpan="5">No Subjects</td>
                    </tr>
                  }
                </tbody>
              </Table>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button className="bg-warning-edit border-0" disabled={buttonSpinner}>
            {buttonSpinner ? (
              <>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </>
            ) : (
              "Submit"
            )}
          </Button>
          <Button disabled={buttonSpinner} color="secondary" onClick={toggleAddOrEdit}>
            Cancel
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AddorEditModal;
