import React, { Fragment, useState } from 'react';
import { isDesktop, isIOS, isMobile } from 'react-device-detect';
import { Form, Button, Card, CardBody, CardDeck, CardFooter, CardImg, CardText, Col, Container, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap';
import basic1 from '../../../../assets/img/basic-1.jpg';
import basicGif from '../../../../assets/img/basic-ed-gif.gif';
import tertiary1 from '../../../../assets/img/tertiary-1.jpg';
import tertiaryGif from '../../../../assets/img/tertiary-ed-gif.gif';
import tesda1 from '../../../../assets/img/tesda-1.jpg';
import tesdaGif from '../../../../assets/img/technical-ed-gif.gif';
import * as FaIcons from 'react-icons/fa';
import { notifyError } from '../../../../services/Toaster';
import { sectionActions } from '../../../../services/SectionServices';
import * as AiIcons from 'react-icons/ai';

const HomepageCourses = ({ homepage, setHomepage, handleChangeModal, handleArrangeModal }) => {

    const [seeMore, setSeeMore] = useState(false);
    const [courseType, setCourseType] = useState("")
    let blankTempCourses = {
        title: "",
        data: []
    }
    const [tempData, setTempData] = useState({
        title: "",
        gif: "",
        gifFile: "",
        image: "",
        imageFile: "",
        videoUrl: "",
        data: blankTempCourses,
        index: ""
    })

    const [vidLink, setVidLink] = useState("")
    const [basicModal, setBasicModal] = useState(false);

    const basicToggle = (url) => {
        setVidLink("")
        if (!basicModal) {
            setVidLink(url)
            setBasicModal(!basicModal);
        } else {
            setBasicModal(!basicModal);
        }
    }



    const [tertiaryModal, setTertiaryModal] = useState(false);
    const tertiaryToggle = () => setTertiaryModal(!tertiaryModal);

    const [tesdaModal, setTesdaModal] = useState(false);
    const tesdaToggle = () => setTesdaModal(!tesdaModal);

    const toggleSeeMore = () => {
        if (!seeMore) {
            setSeeMore(!seeMore)
        } else {
            setSeeMore(!seeMore)
        }
    }

    const [openChangeModal, setOpenChangeModal] = useState(false)
    const [newTitle, setNewTitle] = useState("")
    const [updateIndex, setUpdateIndex] = useState("")
    const [courseT, setType] = useState("")
    const handleChange = (data, type) => {
        setCourseType("")
        if (!openChangeModal) {
            setCourseType(type)
            setTempData(data)
            setOpenChangeModal(!openChangeModal)
        } else {
            setOpenChangeModal(!openChangeModal)
        }
    }

    const acceptedImageExtentions = ['jpg', 'jpeg', 'png'];
    const gifExtentions = ['gif'];

    const handleChangeImage = async (e, data) => {
        let extension = e[0].name.split('.')[1];
        if (acceptedImageExtentions.includes(extension)) {
            let imageFile = {
                filename: e[0].name,
                document: await convertBase64(e[0]),
            };
            setTempData({ ...tempData, image: URL.createObjectURL(e[0]), imageFile: imageFile });
        } else {
            notifyError("Invalid file type for image")
        }
    }

    const handleUpdateGif = async (e) => {
        let extension = e[0].name.split('.')[1];
        if (gifExtentions.includes(extension)) {
            let imageFile = {
                filename: e[0].name,
                document: await convertBase64(e[0]),
            };
            setTempData({ ...tempData, gif: URL.createObjectURL(e[0]), gifFile: imageFile });
        } else {
            notifyError("Invalid file type for image")
        }
    }

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const handleDeleteData = (index) => {
        const newTempData = tempData.data;
        let ntd = newTempData.filter((d, i) => {
            return i != index
        })

        setTempData({ ...tempData, data: ntd })
    }

    const [openAddEditModal, setAddEditModal] = useState(false);

    let blankCourse = {
        title: "",
        subtitle: ""
    }

    const [tempCourses, setTempCourses] = useState(tempData.data)

    const [action, setAction] = useState("")
    const toggleAddEdit = (act, key, data) => {
        setNewTitle("")
        setAction("")
        if (!openAddEditModal) {
            setTempCourses(blankTempCourses)
            setAction(act)
            if (act == "edit") {
                setNewTitle(data.title)
                setUpdateIndex(key);
                setTempCourses(data)
                setTempData({ ...tempData, index: key })
            }
            setAddEditModal(!openAddEditModal)
        } else {
            setAddEditModal(!openAddEditModal)
        }
    }

    const handleAddNewCourse = () => {
        const temp = tempCourses.data;
        temp.push(blankCourse)

        setTempCourses({ ...tempCourses, data: temp })
    }

    const handleChangeCourse = (e, index, type) => {
        const temp = tempCourses.data;
        let courseData = temp.map((course, i) => {
            if (i == index) {
                if (type == "title") {
                    course.title = e;
                    return course
                }
                if (type == "subtitle") {
                    course.subtitle = e;
                    return course
                }
            } else {
                return course
            }
        })

        setTempCourses({ ...tempCourses, data: courseData })
    }

    const handleAddNew = () => {
        let newData = {
            title: newTitle,
            data: tempCourses.data
        }
        const tempD = tempData.data;
        if (action == "edit") {
            let dArr = tempData.data.map((d, i) => {
                if (i == updateIndex) {
                    d = newData;
                    return d;
                } else {
                    return d
                }
            })
            setTempData({ ...tempData, data: dArr })
        } else {
            tempD.push(newData);
            setTempData({ ...tempData, data: tempD })
        }
        setAddEditModal(!openAddEditModal)
    }

    const handleSaveCourses = () => {
        let homePageCourses = homepage.courses;
        if (courseType == "basic") {
            homePageCourses.basic = tempData;
        } else if (courseType == "tertiary") {
            homePageCourses.tertiary = tempData;
        } else if (courseType == "technical") {
            homePageCourses.technical = tempData;
        }

        setHomepage({ ...homepage, courses: homePageCourses })
        setOpenChangeModal(!openChangeModal)
    }

    return (
        <Container className="mb-5 mt-5">
            <h3 className={isDesktop ? "font-weight-bolder text-danger-edit mb-5" : "font-weight-bolder text-danger-edit mb-5 text-center"}>COURSES OFFERED AND TUTORIAL</h3>
            <CardDeck>
                <Card className="shadow">
                    <CardImg onClick={() => basicToggle(homepage.courses.basic.videoUrl)} style={{ height: "13rem", cursor: "pointer" }} top width="100%" src={homepage.courses.basic.image} onMouseOver={e => (e.currentTarget.src = homepage.courses.basic.gif)} onMouseOut={e => (e.currentTarget.src = homepage.courses.basic.image)} alt="Card image cap" />
                    <CardBody className="text-center d-flex flex-column align-items-center" style={{ height: seeMore ? "500px" : "300px", overflowY: seeMore ? "scroll" : "hidden" }}>
                        <div>
                            {homepage.courses.basic.data.length >= 1 ? homepage.courses.basic.data.map((dt, i) => {
                                return (
                                    <div key={i} className="mb-4">
                                        <div className='mb-3'>
                                            <CardText className="text-secondary-edit font-weight-bolder"><i>{dt.title}</i></CardText>
                                        </div>
                                        {dt.data.length >= 1 ? dt.data.map((d, e) => {
                                            return (
                                                <div key={e}>
                                                    <CardText className="mb-0 text-secondary-edit"><i>{d.title}</i></CardText>
                                                    <CardText className="mb-1 text-secondary-edit"><i>{d.subtitle}</i></CardText>
                                                </div>
                                            )
                                        }) : ""}
                                    </div>
                                )
                            }) : ""}
                        </div>
                    </CardBody>
                    <CardFooter className="bg-white py-4 text-center" style={{ borderTop: 0 }}>
                        <p className='mb-0 font-weight-bolder text-secondary' style={{ cursor: "pointer" }} onClick={toggleSeeMore}><i>See More</i></p>
                        <Button outline className="rounded-pill mt-4 font-weight-bolder button-transition" color="warning">APPLY NOW</Button>
                    </CardFooter>
                    <Button color="success" onClick={() => { handleChange(homepage.courses.basic, 'basic') }}>UPDATE</Button>
                </Card>


                <Card className="shadow">
                    <CardImg onClick={() => basicToggle(homepage.courses.tertiary.videoUrl)} style={{ height: "13rem", cursor: "pointer" }} top width="100%" src={homepage.courses.tertiary.image} onMouseOver={e => (e.currentTarget.src = homepage.courses.tertiary.gif)} onMouseOut={e => (e.currentTarget.src = homepage.courses.tertiary.image)} alt="Card image cap" />
                    <CardBody className="text-center d-flex flex-column align-items-center" style={{ height: seeMore ? "500px" : "300px", overflowY: seeMore ? "scroll" : "hidden" }}>
                        <div>
                            {homepage.courses.tertiary.data.length >= 1 ? homepage.courses.tertiary.data.map((dt, i) => {
                                return (
                                    <div key={i} className="mb-5">
                                        <div className='mb-3'>
                                            <CardText className="text-secondary-edit font-weight-bolder"><i>{dt.title}</i></CardText>
                                        </div>
                                        {dt.data.length >= 1 ? dt.data.map((d, e) => {
                                            return (
                                                <div key={e}>
                                                    <CardText className="mb-0 text-secondary-edit"><i>{d.title}</i></CardText>
                                                    <CardText className="mb-1 text-secondary-edit"><i>{d.subtitle}</i></CardText>
                                                </div>
                                            )
                                        }) : ""}
                                    </div>
                                )
                            }) : ""}
                        </div>
                    </CardBody>
                    <CardFooter className="bg-white py-4 text-center" style={{ borderTop: 0 }}>
                        <p className='mb-0 font-weight-bolder text-secondary' style={{ cursor: "pointer" }} onClick={toggleSeeMore}><i>See More</i></p>
                        <Button outline className="rounded-pill mt-4 font-weight-bolder button-transition" color="warning">APPLY NOW</Button>
                    </CardFooter>
                    <Button color="success" onClick={() => { handleChange(homepage.courses.tertiary, 'tertiary') }}>UPDATE</Button>
                </Card>

                <Card className="shadow">
                    <CardImg onClick={() => basicToggle(homepage.courses.technical.videoUrl)} style={{ height: "13rem", cursor: "pointer" }} top width="100%" src={homepage.courses.technical.image} onMouseOver={e => (e.currentTarget.src = homepage.courses.technical.gif)} onMouseOut={e => (e.currentTarget.src = homepage.courses.technical.image)} alt="Card image cap" />
                    <CardBody className="text-center d-flex flex-column align-items-center" style={{ height: seeMore ? "500px" : "300px", overflowY: seeMore ? "scroll" : "hidden" }}>
                        <div>
                            {homepage.courses.technical.data.length >= 1 ? homepage.courses.technical.data.map((dt, i) => {
                                return (
                                    <div key={i} className="mb-5">
                                        <div className="mb-3">
                                            <CardText className="text-secondary-edit font-weight-bolder"><i>{dt.title}</i></CardText>
                                        </div>
                                        {dt.data.length >= 1 ? dt.data.map((d, e) => {
                                            return (
                                                <div key={e}>
                                                    <CardText className="mb-0 text-secondary-edit"><i>{d.title}</i></CardText>
                                                    <CardText className="mb-1 text-secondary-edit"><i>{d.subtitle}</i></CardText>
                                                </div>
                                            )
                                        }) : ""}
                                    </div>
                                )
                            }) : ""}
                        </div>
                    </CardBody>
                    <CardFooter className="bg-white py-4 text-center" style={{ borderTop: 0 }}>
                        <p className='mb-0 font-weight-bolder text-secondary' style={{ cursor: "pointer" }} onClick={toggleSeeMore}><i>See More</i></p>
                        <Button outline className="rounded-pill mt-4 font-weight-bolder button-transition" color="warning">APPLY NOW</Button>
                    </CardFooter>
                    <Button color="success" onClick={() => { handleChange(homepage.courses.technical, 'technical') }}>UPDATE</Button>
                </Card>

                <Modal isOpen={basicModal} className={isIOS ? isMobile ? "modal-tutorial-center-ios" : "landingPage" : "landingPage modal-tutorial-center"} contentClassName={isDesktop ? "modal-tutorial" : "modal-tutorial-center-mobile align-items-center justify-content-center"} backdrop="static">
                    <ModalBody>
                        <div>
                            <p className="text-center">
                                <iframe className={isMobile ? "modal-tutorial-mobile" : "video-block"} src={vidLink} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <Row className="justify-content-center align-items-center">
                                    <Button className="bg-warning-edit text-white font-weight-bolder border-0 w-25 button-transition mt-3" onClick={()=>setBasicModal(!basicModal)}>EXIT</Button>
                                </Row>
                            </p>
                        </div>
                    </ModalBody>
                </Modal>
            </CardDeck>

            <Modal isOpen={openChangeModal} size="xl" backdrop="static">
                <ModalHeader toggle={handleChange}>Courses Offered</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col md="12" xs="12" className="mb-5">
                            <p className='font-weight-bolder mb-1'>Image</p>
                            <img src={tempData.image} style={{ height: "150px", width: "200px", objectFit: "cover" }} />
                            <Input type="file" onChange={(e) => handleChangeImage(e.target.files)} />

                        </Col>
                        <Col md="12" xs="12" className="mb-5">
                            <p className='font-weight-bolder mb-1'>GIF</p>
                            <img src={tempData.gif} style={{ height: "150px", width: "200px", objectFit: "cover" }} />
                            <Input type="file" onChange={(e) => handleUpdateGif(e.target.files)} />
                        </Col>
                        <Col md="12" xs="12" className="mb-5">
                            <p className='font-weight-bolder mb-1'>Video URL Link</p>
                            <Input type="text" defaultValue={tempData.videoUrl} onChange={(e) => setTempData({ ...tempData, videoUrl: e.target.value })} />
                        </Col>
                    </Row>
                    <Table striped bordered>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Title</th>
                                <th>Grade Levels, Courses and Tutorial</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tempData.data.length >= 1 ? tempData.data.map((dt, key) => {
                                return (
                                    <tr key={key}>
                                        <td style={{ verticalAlign: "middle" }} className="text-center">{key + 1}</td>
                                        <td style={{ verticalAlign: "middle" }} className="text-center">{dt.title}</td>
                                        <td style={{ verticalAlign: "middle" }}>
                                            {dt.data.length >= 1 ?
                                                dt.data.map((d, i) => {
                                                    return (
                                                        <Fragment key={i}>
                                                            <p className='mb-0'><strong>Grade Level/Course:</strong> {d.title}</p>
                                                            <p><strong>Descriptions:</strong> {d.subtitle}</p>
                                                        </Fragment>
                                                    )
                                                })
                                                : ""}
                                        </td>
                                        <td style={{ verticalAlign: "middle" }}>
                                            <div className='d-flex align-items-center text-center justify-content-center'>
                                                <Button color="warning" className='text-center text-white mr-2' onClick={() => toggleAddEdit('edit', key, dt)}>{<FaIcons.FaEdit style={{ fontSize: "1rem" }} />}</Button>
                                                <Button color="danger" onClick={() => handleDeleteData(key)}>X</Button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }) :
                                <tr>
                                    <td colSpan="4" className='text-center'>No Data</td>
                                </tr>
                            }
                        </tbody>
                    </Table>
                    <Button className='mr-2' color="secondary" onClick={() => toggleAddEdit("add", null, null)}>Add +</Button>
                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={() => handleSaveCourses()}>Save</Button>
                    <Button onClick={() => setOpenChangeModal(!openChangeModal)}>Close</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={openAddEditModal} size="lg" backdrop="static">
                <ModalBody>
                    <Label>Title</Label>
                    <Input className='mb-3' defaultValue={tempCourses.title} onChange={(e) => setNewTitle(e.target.value)} />
                    <Row>
                        <Col md="6" xs="12" className='font-weight-bolder text-center'>
                            <Label>Grade Level/Course</Label>
                        </Col>
                        <Col md="6" xs="12" className='font-weight-bolder text-center'>
                            <Label>Descriptions</Label>
                        </Col>
                    </Row>
                    {tempCourses.data.length >= 1 ? tempCourses.data.map((c, i) => {
                        return (
                            <Fragment key={i}>
                                <Row className='my-3'>

                                    <Col md="6" xs="12" className='mb-4'>
                                        <Input name={"title" + i} defaultValue={c.title} onChange={(e) => handleChangeCourse(e.target.value, i, "title")} />
                                    </Col>
                                    <Col md="6" xs="12" className='mb-4'>
                                        <Input name={"subtitle" + i} defaultValue={c.subtitle} onChange={(e) => handleChangeCourse(e.target.value, i, "subtitle")} />
                                    </Col>
                                </Row>
                            </Fragment>
                        )
                    }) : ""}
                    <div className='text-right'>
                        <Button onClick={handleAddNewCourse} className='font-weight-bolder'>
                            <AiIcons.AiOutlinePlus style={{ fontSize: "1rem", fontWeight: "bold" }} />
                        </Button>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={() => handleAddNew()}>{action == "edit" ? "Update" : "Save"}</Button>
                    <Button onClick={() => setAddEditModal(!openAddEditModal)}>Close</Button>
                </ModalFooter>
            </Modal>
        </Container>
    )
}

export default HomepageCourses;