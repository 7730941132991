import React, { useState, useEffect } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
  Container,
  Label,
  FormGroup,
  Form,
  Input,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import classnames from "classnames";
import moment from "moment";
import { useForm, Controller } from "react-hook-form";
import { applicationActions } from "../../../services/ApplicationServices";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import ScholarshipApproval from "./ScholarshipApproval.js";
import EscApproval from "./EscApproval.js";

const MyApplicationView = ({
  viewMyApplication,
  setViewMyApplication,
  toggleViewApplication,
  basicForm,
  setBasicForm,
  scholarshipForm,
  tesdaForm,
  paymentForm,
}) => {
  const admissionAnswers = localStorage.getItem("admissionAnswers")
    ? JSON.parse(localStorage.getItem("admissionAnswers"))
    : "";
  const [activeTab, setActiveTab] = useState("1");
  const { register, handleSubmit, errors, control, setValue, getValues } =
    useForm();
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [selectedRemark, setSelectedRemark] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const [response, setResponse] = useState("");
  const [remarksFormOpen, setRemarksFormOpen] = useState(false);
  const [totalUnit, setTotalUnit] = useState(0);
  const [labUnitPrice, setLabUnitPrice] = useState(0);
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const onSubmit = (data) => {
    if (selectedRemark == "reject") {
      const dataToSubmit = {
        application_id: basicForm.id,
        application_remarks: basicForm.remarks,
      };
      setButtonSpinner(true);
      applicationActions.rejectApplication(
        dataToSubmit,
        setButtonSpinner,
        navigate,
        setResponse
      );
    } else if (selectedRemark == "approve") {
      const dataToSubmit = {
        application_id: basicForm.id,
        application_remarks: basicForm.remarks,
      };
      setButtonSpinner(true);
      applicationActions.approveApplication(
        dataToSubmit,
        setButtonSpinner,
        navigate,
        setResponse
      );
    }
  };

  const toggleModal = (remark) => {
    setSelectedRemark("");
    if (!openModal) {
      setOpenModal(!openModal);
      setSelectedRemark(remark);
    } else {
      setOpenModal(!openModal);
    }
  };

  // useEffect(() => {
  //     if (selectedRemark != "") {
  //         if (!openModal) {
  //             setOpenModal(!openModal)
  //             if (selectedRemark == "approve") {
  //                 setRemarksFormOpen(false)
  //             }
  //         } else {
  //             setOpenModal(!openModal)
  //         }
  //     }
  // }, [selectedRemark])

  const toggleClose = () => {
    setOpenModal(!openModal);
    window.location.reload(500);
  };

  useEffect(() => {
    if (basicForm.subjects) {
      let unitArr = [];
      let labUnitPrice = [];
      let subjectsWLab = [];

      if (
        basicForm.subjects &&
        basicForm.subjects.subjects.length >= 1 &&
        basicForm.subjects.subjects
      ) {
        basicForm.subjects.subjects.map((subject) => {
          let unit = parseFloat(subject.unit);
          let unitPrice = parseFloat(subject.lab_unit_price);
          if (subject.lab_unit_price != "0.00") {
            subjectsWLab.push(subject);
          }
          unitArr.push(unit);
          labUnitPrice.push(unitPrice);
        });
        const sum = unitArr.reduce((result, number) => result + number);
        const labSum = labUnitPrice.reduce((result, number) => result + number);

        setTotalUnit(sum);
        setLabUnitPrice(labSum);
      }
    }
  }, [basicForm]);

  const [scholarshipModal, setScholarshipModal] = useState(false);
  const toggleScholarshipModal = () => {
    if (!scholarshipModal) {
      setScholarshipModal(!scholarshipModal);
    } else {
      setScholarshipModal(!scholarshipModal);
    }
  };

  const [escModal, setEscModal] = useState(false);
  const [escAction, setEscAction] = useState("");
  const toggleEscModal = (e) => {
    setEscAction(e);
    if (!escModal) {
      setEscModal(!escModal);
    } else {
      setEscModal(!escModal);
    }
  };

  return (
    <Modal
      isOpen={viewMyApplication}
      toggle={toggleViewApplication}
      size="lg"
      backdrop="static"
    >
      <ModalHeader
        toggle={toggleViewApplication}
        className="font-weight-bolder"
      >
        Assessment
        {/* <h4 className="text-danger-edit font-weight-bolder">Assessment</h4> */}
      </ModalHeader>
      <ModalBody>
        <Container fluid={true}>
          <Nav tabs>
            {admissionAnswers.education_type == "Tertiary Education" ||
            admissionAnswers.education_type == "Basic Education" ? (
              <>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    <p className="font-weight-bolder">Info</p>
                  </NavLink>
                </NavItem>
                {admissionAnswers.education_type == "Tertiary Education" ? (
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "2" })}
                      onClick={() => {
                        toggle("2");
                      }}
                    >
                      <p className="font-weight-bolder">Subjects</p>
                    </NavLink>
                  </NavItem>
                ) : (
                  ""
                )}
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "3" })}
                    onClick={() => {
                      toggle("3");
                    }}
                  >
                    <p className="font-weight-bolder">Scholarship</p>
                  </NavLink>
                </NavItem>
              </>
            ) : (
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "1" })}
                  onClick={() => {
                    toggle("1");
                  }}
                >
                  <p className="font-weight-bolder">Info</p>
                </NavLink>
              </NavItem>
            )}
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "4" })}
                onClick={() => {
                  toggle("4");
                }}
              >
                <p className="font-weight-bolder">Uploaded Files</p>
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            {admissionAnswers.education_type == "Basic Education" ||
            admissionAnswers.education_type == "Tertiary Education" ? (
              <>
                <TabPane tabId="1">
                  <Row className="py-5 px-2" style={{ overflowX: "auto" }}>
                    <Col sm="12">
                      <Row className="mb-5">
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              Education Type:{" "}
                            </span>{" "}
                            {admissionAnswers.education_type}
                          </Label>
                        </Col>
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              Student Type:{" "}
                            </span>{" "}
                            {admissionAnswers.student_type
                              ? admissionAnswers.student_type.label
                              : "N/A"}
                          </Label>
                        </Col>
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              Grade Year/Level:{" "}
                            </span>{" "}
                            {admissionAnswers.grade_year_level
                              ? admissionAnswers.grade_year_level.label
                              : "N/A"}
                          </Label>
                        </Col>
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">Course: </span>{" "}
                            {admissionAnswers.tertiary_course
                              ? admissionAnswers.tertiary_course.label
                              : "N/A"}
                          </Label>
                        </Col>
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              Semester:{" "}
                            </span>{" "}
                            {admissionAnswers.semester
                              ? admissionAnswers.semester.label
                              : "N/A"}
                          </Label>
                        </Col>
                      </Row>
                      <Row className="mb-4 flex-column">
                        <Col md="12" xs="12">
                          <h5 className="font-weight-bolder text-danger-edit">
                            PERSONAL INFORMATION
                          </h5>
                        </Col>
                        <FormGroup className="mb-3">
                          <Col md="12" xs="12">
                            <Label>
                              <span className="font-weight-bolder">
                                Last Name:{" "}
                              </span>{" "}
                              {basicForm.last_name}
                            </Label>
                          </Col>
                          <Col md="12" xs="12">
                            <Label>
                              <span className="font-weight-bolder">
                                First Name:{" "}
                              </span>{" "}
                              {basicForm.first_name}
                            </Label>
                          </Col>
                          <Col md="12" xs="12">
                            <Label>
                              <span className="font-weight-bolder">
                                Middle Name:{" "}
                              </span>{" "}
                              {basicForm.middle_name}
                            </Label>
                          </Col>
                          <Col md="12" xs="12">
                            <Label>
                              <span className="font-weight-bolder">
                                Suffix:{" "}
                              </span>{" "}
                              {basicForm.suffix}
                            </Label>
                          </Col>
                          <Col md="12" xs="12">
                            <Label>
                              <span className="font-weight-bolder">
                                Birth Place:{" "}
                              </span>{" "}
                              {basicForm.birth_place}
                            </Label>
                          </Col>
                          <Col md="12" xs="12">
                            <Label>
                              <span className="font-weight-bolder">
                                Birth Date:{" "}
                              </span>{" "}
                              {basicForm.birth_date != ""
                                ? moment(basicForm.birth_date).format(
                                    "YYYY-MM-DD"
                                  )
                                : ""}
                            </Label>
                          </Col>
                          <Col md="12" xs="12">
                            <Label>
                              <span className="font-weight-bolder">
                                Gender:{" "}
                              </span>{" "}
                              {basicForm.gender == "male" ? "Male" : "Female"}
                            </Label>
                          </Col>
                          <Col md="12" xs="12">
                            <Label>
                              <span className="font-weight-bolder">Age: </span>{" "}
                              {basicForm.age}
                            </Label>
                          </Col>
                          <Col md="12" xs="12">
                            <Label>
                              <span className="font-weight-bolder">
                                Religion:{" "}
                              </span>{" "}
                              {basicForm.religion}
                            </Label>
                          </Col>
                          <Col md="12" xs="12">
                            <Label>
                              <span className="font-weight-bolder">LRN: </span>{" "}
                              {basicForm.lrn}
                            </Label>
                          </Col>
                        </FormGroup>
                        <FormGroup className="mb-3">
                          <Col md="12" xs="12">
                            <h5 className="font-weight-bolder text-danger-edit">
                              HOME ADDRESS
                            </h5>
                          </Col>
                          <Col md="12" xs="12">
                            <Label>
                              <span className="font-weight-bolder">
                                House no., Building, Street:{" "}
                              </span>{" "}
                              {basicForm.street}
                            </Label>
                          </Col>
                          <Col md="12" xs="12">
                            <Label>
                              <span className="font-weight-bolder">
                                Province:{" "}
                              </span>{" "}
                              {basicForm.province}
                            </Label>
                          </Col>
                          <Col md="12" xs="12">
                            <Label>
                              <span className="font-weight-bolder">City: </span>{" "}
                              {basicForm.city}
                            </Label>
                          </Col>
                          <Col md="12" xs="12">
                            <Label>
                              <span className="font-weight-bolder">
                                Barangay:{" "}
                              </span>{" "}
                              {basicForm.barangay}
                            </Label>
                          </Col>
                        </FormGroup>
                        <FormGroup className="mb-1">
                          <Col md="12" xs="12">
                            <h5 className="font-weight-bolder text-danger-edit">
                              PRIOR EDUCATION
                            </h5>
                          </Col>
                        </FormGroup>
                        <FormGroup className="mb-2">
                          <Col md="12" xs="12">
                            <Label className="font-weight-bolder">
                              ELEMENTARY
                            </Label>
                          </Col>
                          <Col md="12" xs="12">
                            <Label>
                              <span className="font-weight-bolder">
                                Name of School:{" "}
                              </span>{" "}
                              {basicForm.elementary_school_name}
                            </Label>
                          </Col>
                          <Col md="12" xs="12">
                            <Label>
                              <span className="font-weight-bolder">
                                School Address:{" "}
                              </span>{" "}
                              {basicForm.elementary_year_graduated}
                            </Label>
                          </Col>
                          <Col md="12" xs="12">
                            <Label>
                              <span className="font-weight-bolder">
                                Year Graduated:{" "}
                              </span>{" "}
                              {basicForm.elementary_year_graduated}
                            </Label>
                          </Col>
                        </FormGroup>

                        <FormGroup className="mb-3">
                          <Col md="12" xs="12">
                            <Label className="font-weight-bolder">
                              JUNIOR HIGH SCHOOL
                            </Label>
                          </Col>
                          <Col md="12" xs="12">
                            <Label>
                              <span className="font-weight-bolder">
                                Name of School:{" "}
                              </span>{" "}
                              {basicForm.junior_high_school_name}
                            </Label>
                          </Col>
                          <Col md="12" xs="12">
                            <Label>
                              <span className="font-weight-bolder">
                                School Address:{" "}
                              </span>{" "}
                              {basicForm.junior_high_school_address}
                            </Label>
                          </Col>
                          <Col md="12" xs="12">
                            <Label>
                              <span className="font-weight-bolder">
                                Year Graduated:{" "}
                              </span>{" "}
                              {basicForm.junior_high_year_graduated}
                            </Label>
                          </Col>
                        </FormGroup>
                      </Row>

                      <Row className="mb-5">
                        <Col md="12" xs="12">
                          <h5 className="font-weight-bolder text-danger-edit">
                            FAMILY INFORMATION
                          </h5>
                        </Col>
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              Living Status:{" "}
                            </span>{" "}
                            {basicForm.living_status.label}
                          </Label>
                        </Col>
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              No of Siblings:{" "}
                            </span>{" "}
                            {basicForm.no_of_siblings}
                          </Label>
                        </Col>
                        <Col md="12" xs="12" className="pt-3">
                          <Label>
                            <span className="font-weight-bolder">
                              Father's Name:{" "}
                            </span>{" "}
                            {basicForm.father_name}
                          </Label>
                        </Col>

                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">Age: </span>{" "}
                            {basicForm.father_age}
                          </Label>
                        </Col>
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              Birth Date:{" "}
                            </span>{" "}
                            {moment(basicForm.father_birth_date).format(
                              "YYYY-MM-DD"
                            )}
                          </Label>
                        </Col>
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              Birth Place:{" "}
                            </span>{" "}
                            {basicForm.father_birth_place}
                          </Label>
                        </Col>
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              Home Address:{" "}
                            </span>{" "}
                            {basicForm.father_home_address}
                          </Label>
                        </Col>
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              Contact No:{" "}
                            </span>{" "}
                            {basicForm.father_contact_no}
                          </Label>
                        </Col>
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              Highest Educational Attainment:{" "}
                            </span>{" "}
                            {basicForm.father_highest_education
                              ? basicForm.father_highest_education.label
                              : ""}
                          </Label>
                        </Col>
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              Occupation:{" "}
                            </span>{" "}
                            {basicForm.father_occupation
                              ? basicForm.father_occupation.label
                              : ""}
                          </Label>
                        </Col>
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              Company:{" "}
                            </span>{" "}
                            {basicForm.father_company}
                          </Label>
                        </Col>
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              Monthly Income:{" "}
                            </span>{" "}
                            {basicForm.father_monthly_income
                              ? basicForm.father_monthly_income.label
                              : ""}
                          </Label>
                        </Col>
                        <Col md="12" xs="12" className="pt-3">
                          <Label>
                            <span className="font-weight-bolder">
                              Mother's Name:{" "}
                            </span>{" "}
                            {basicForm.mother_name}
                          </Label>
                        </Col>

                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">Age: </span>{" "}
                            {basicForm.mother_age}
                          </Label>
                        </Col>
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              Birth Date:{" "}
                            </span>{" "}
                            {moment(basicForm.mother_birth_date).format(
                              "YYYY-MM-DD"
                            )}
                          </Label>
                        </Col>
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              Birth Place:{" "}
                            </span>{" "}
                            {basicForm.mother_birth_place}
                          </Label>
                        </Col>

                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              Home Address:{" "}
                            </span>{" "}
                            {basicForm.mother_home_address}
                          </Label>
                        </Col>
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              Contact No:{" "}
                            </span>{" "}
                            {basicForm.mother_contact_no}
                          </Label>
                        </Col>
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              Highest Educational Attainment:{" "}
                            </span>{" "}
                            {basicForm.mother_highest_education
                              ? basicForm.mother_highest_education.label
                              : ""}
                          </Label>
                        </Col>
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              Occupation:{" "}
                            </span>{" "}
                            {basicForm.mother_occupation
                              ? basicForm.mother_occupation.label
                              : ""}
                          </Label>
                        </Col>
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              Company:{" "}
                            </span>{" "}
                            {basicForm.mother_company}
                          </Label>
                        </Col>
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              Monthly Income:{" "}
                            </span>{" "}
                            {basicForm.mother_monthly_income
                              ? basicForm.mother_monthly_income.label
                              : ""}
                          </Label>
                        </Col>
                        {!basicForm.guardian_toggle ? (
                          <>
                            <Col md="12" xs="12" className="pt-3">
                              <Label>
                                <span className="font-weight-bolder">
                                  Guardian's Name:{" "}
                                </span>{" "}
                                {basicForm.guardian_name}
                              </Label>
                            </Col>
                            <Col md="12" xs="12">
                              <Label>
                                <span className="font-weight-bolder">
                                  Age:{" "}
                                </span>{" "}
                                {basicForm.guardian_age}
                              </Label>
                            </Col>
                            <Col md="12" xs="12">
                              <Label>
                                <span className="font-weight-bolder">
                                  Birth Date:{" "}
                                </span>{" "}
                                {moment(basicForm.guardian_birth_date).format(
                                  "YYYY-MM-DD"
                                )}
                              </Label>
                            </Col>
                            <Col md="12" xs="12">
                              <Label>
                                <span className="font-weight-bolder">
                                  Birth Place:{" "}
                                </span>{" "}
                                {basicForm.guardian_birth_place}
                              </Label>
                            </Col>
                            <Col md="12" xs="12">
                              <Label>
                                <span className="font-weight-bolder">
                                  Home Address:{" "}
                                </span>{" "}
                                {basicForm.guardian_home_address}
                              </Label>
                            </Col>
                            <Col md="12" xs="12">
                              <Label>
                                <span className="font-weight-bolder">
                                  Contact No:{" "}
                                </span>{" "}
                                {basicForm.guardian_contact_no}
                              </Label>
                            </Col>
                            <>
                              <Col md="12" xs="12">
                                <Label>
                                  <span className="font-weight-bolder">
                                    Highest Educational Attainment:{" "}
                                  </span>{" "}
                                  {basicForm.guardian_highest_education
                                    ? basicForm.guardian_highest_education.label
                                    : ""}
                                </Label>
                              </Col>
                              <Col md="12" xs="12">
                                <Label>
                                  <span className="font-weight-bolder">
                                    Occupation:{" "}
                                  </span>{" "}
                                  {basicForm.guardian_occupation
                                    ? basicForm.guardian_occupation.label
                                    : ""}
                                </Label>
                              </Col>
                              <Col md="12" xs="12">
                                <Label>
                                  <span className="font-weight-bolder">
                                    Company:{" "}
                                  </span>{" "}
                                  {basicForm.guardian_company}
                                </Label>
                              </Col>
                              <Col md="12" xs="12">
                                <Label>
                                  <span className="font-weight-bolder">
                                    Monthly Income:{" "}
                                  </span>{" "}
                                  {basicForm.guardian_monthly_income
                                    ? basicForm.guardian_monthly_income.label
                                    : ""}
                                </Label>
                              </Col>
                            </>
                          </>
                        ) : (
                          ""
                        )}
                      </Row>

                      {admissionAnswers.education_type == "Basic Education" ? (
                        <Row className="mb-5">
                          <Col md="12" xs="12">
                            <h5 className="font-weight-bolder text-danger-edit mb-3">
                              ESC Details
                            </h5>
                          </Col>
                          {/* <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">Type: </span>{" "}
                            {basicForm.esc_type == 0 ? "Public" : basicForm.esc_type == 1 ? "Private" : "No ESC/QVR"}
                          </Label>
                        </Col> */}
                          {basicForm.esc_type === 0 ||
                          basicForm.esc_type === 1 ||
                          basicForm.esc_type === 2 ? (
                            <>
                              <Col md="12" xs="12">
                                <Label>
                                  <span className="font-weight-bolder">
                                    Type:{" "}
                                  </span>{" "}
                                  {basicForm.esc_type == 0
                                    ? "Public"
                                    : basicForm.esc_type == 1
                                    ? "Private"
                                    : "Private Non-ESC"}
                                </Label>
                              </Col>
                              {basicForm.esc_type == 1 ? (
                                <>
                                  <Col md="12" xs="12">
                                    <Label>
                                      <span className="font-weight-bolder">
                                        Certificate:{" "}
                                      </span>
                                      {basicForm.esc_qvr == 0 ? "ESC" : "QVR"}
                                    </Label>
                                  </Col>
                                  <Col md="12" xs="12">
                                    {basicForm.esc_qvr == 0 ? (
                                      <Label>
                                        <span className="font-weight-bolder">
                                          ESC File:{" "}
                                        </span>
                                        <br />{" "}
                                        {basicForm.esc_file &&
                                        basicForm.esc_file.name
                                          ? basicForm.esc_file.name
                                          : basicForm.esc_file_name}
                                      </Label>
                                    ) : (
                                      <Label>
                                        <span className="font-weight-bolder">
                                          QVR File:{" "}
                                        </span>
                                        <br />{" "}
                                        {basicForm.qvr_file &&
                                        basicForm.qvr_file.name
                                          ? basicForm.qvr_file.name
                                          : basicForm.qvr_file_name}
                                      </Label>
                                    )}
                                  </Col>
                                </>
                              ) : (
                                ""
                              )}
                            </>
                          ) : (
                            <>
                              <Col md="12" xs="12">
                                <Label>
                                  <span className="font-weight-bolder">
                                    ESC applied:{" "}
                                  </span>{" "}
                                  {basicForm.is_esc == 0
                                    ? "No"
                                    : basicForm.is_esc == 1
                                    ? "Yes"
                                    : ""}
                                </Label>
                              </Col>
                              <Col md="12" xs="12">
                                {basicForm.is_esc == 1 ? (
                                  <Label>
                                    <span className="font-weight-bolder">
                                      ESC File:{" "}
                                    </span>
                                    {basicForm.esc_file &&
                                    basicForm.esc_file.name
                                      ? basicForm.esc_file.name
                                      : basicForm.esc_file_name}
                                  </Label>
                                ) : (
                                  ""
                                )}
                              </Col>
                            </>
                          )}
                        </Row>
                      ) : (
                        ""
                      )}

                      <Row className="mb-5">
                        <Col md="12" xs="12">
                          <h5 className="font-weight-bolder text-danger-edit mb-3">
                            ASSESSMENT
                          </h5>
                        </Col>
                        {(admissionAnswers.education_type ==
                          "Basic Education" ||
                          admissionAnswers.education_type ==
                            "Tertiary Education") &&
                        paymentForm.paymentMode ? (
                          <>
                            <Col md="12" xs="12">
                              <Label>
                                <span className="font-weight-bolder">
                                  Payment Mode:{" "}
                                </span>{" "}
                                {paymentForm.paymentMode
                                  ? paymentForm.paymentMode.label
                                  : ""}
                              </Label>
                            </Col>
                            <Col md="12" xs="12">
                              <Label>
                                <span className="font-weight-bolder">
                                  Tuition Fee:{" "}
                                </span>{" "}
                                {paymentForm.paymentModeDetails
                                  ? paymentForm.paymentModeDetails.tuition_fee
                                  : ""}
                              </Label>
                            </Col>
                            <Col md="12" xs="12">
                              <Label>
                                <span className="font-weight-bolder">
                                  Miscellaneous Fee:{" "}
                                </span>{" "}
                                {
                                  paymentForm.paymentModeDetails
                                    .miscellaneous_fee
                                }
                              </Label>
                            </Col>
                            <Col md="12" xs="12">
                              <Label>
                                <span className="font-weight-bolder">
                                  Other Fee:{" "}
                                </span>{" "}
                                {paymentForm.paymentModeDetails.other_fee}
                              </Label>
                            </Col>
                            <Col md="12" xs="12">
                              <Label>
                                <span className="font-weight-bolder">
                                  Discount:{" "}
                                </span>{" "}
                                {paymentForm.paymentModeDetails &&
                                paymentForm.paymentMode &&
                                paymentForm.paymentMode.value == 1
                                  ? paymentForm.discounted_tuituion_fee.toFixed(
                                      2
                                    )
                                  : "N/A"}
                              </Label>
                            </Col>
                            {paymentForm.paymentModeDetails &&
                            paymentForm.paymentModeDetails
                              .payment_upon_enrollment_lab ? (
                              <Col md="12" xs="12">
                                <Label>
                                  <span className="font-weight-bolder">
                                    Payment Upon Enrollment:{" "}
                                  </span>{" "}
                                  {paymentForm.paymentModeDetails &&
                                  paymentForm.paymentModeDetails
                                    .payment_upon_enrollment_lab
                                    ? paymentForm.paymentModeDetails
                                        .payment_upon_enrollment_lab
                                    : 0}
                                </Label>
                              </Col>
                            ) : (
                              <Col md="12" xs="12">
                                <Label>
                                  <span className="font-weight-bolder">
                                    Payment Upon Enrollment:{" "}
                                  </span>{" "}
                                  {paymentForm.paymentModeDetails &&
                                  paymentForm.paymentModeDetails
                                    .payment_upon_enrollment
                                    ? paymentForm.paymentModeDetails
                                        .payment_upon_enrollment
                                    : 0}
                                </Label>
                              </Col>
                            )}
                            <Col md="12" xs="12">
                              <Label>
                                <span className="font-weight-bolder">
                                  Payment per Billing:{" "}
                                </span>{" "}
                                {paymentForm.paymentModeDetails &&
                                paymentForm.paymentModeDetails.per_billing
                                  ? paymentForm.paymentModeDetails.per_billing
                                  : "N/A"}
                              </Label>
                            </Col>
                          </>
                        ) : (
                          <Col md="12" xs="12">
                            <p className="text-secondary">
                              No Payment Mode Selected
                            </p>
                          </Col>
                        )}
                      </Row>
                      {admissionAnswers.education_type == "Basic Education" ? (
                        <>
                          <Row className="mb-5">
                            <Col md="12" xs="12">
                              <h5 className="font-weight-bolder text-danger-edit">
                                HEALTH INFORMATION
                              </h5>
                            </Col>
                            <Col md="12" xs="12">
                              <Label>
                                <span className="font-weight-bolder">
                                  Do you have any health problems now?{" "}
                                </span>{" "}
                                {basicForm.health_student_boolean.label}
                              </Label>
                            </Col>
                            <Col md="12" xs="12">
                              <Label>
                                <span className="font-weight-bolder">
                                  Specific health problem:{" "}
                                </span>{" "}
                                {basicForm.health_student_boolean
                                  ? basicForm.health_student_specific
                                  : "NA"}
                              </Label>
                            </Col>
                            <Col md="12" xs="12">
                              <Label>
                                <span className="font-weight-bolder">
                                  Are you consulting a doctor?{" "}
                                </span>{" "}
                                {basicForm.consulting_doctor.label}
                              </Label>
                            </Col>
                            <Col md="12" xs="12">
                              <Label>
                                <span className="font-weight-bolder">
                                  What important illness did you have in the
                                  past?{" "}
                                </span>{" "}
                                {basicForm.important_illness}
                              </Label>
                            </Col>
                            <Col md="12" xs="12">
                              <Label>
                                <span className="font-weight-bolder">
                                  Have you been hospitalized in the past?{" "}
                                </span>{" "}
                                {basicForm.hospitalized_in_past.label}
                              </Label>
                            </Col>
                            <Col md="12" xs="12">
                              <Label>
                                <span className="font-weight-bolder">
                                  if yes, indicate the reason and when?{" "}
                                </span>{" "}
                                {basicForm.hospitalized_in_past
                                  ? basicForm.specific_hospitalized_in_past
                                  : "NA"}
                              </Label>
                            </Col>
                            <Col md="12" xs="12">
                              <Label>
                                <span className="font-weight-bolder">
                                  Do you have any difficulty in, or problems of
                                  the following?{" "}
                                </span>{" "}
                                {basicForm.difficulty.value}
                              </Label>
                            </Col>
                            <Col md="12" xs="12">
                              <Label>
                                <span className="font-weight-bolder">
                                  In your family (blood relations those living
                                  in the household) has anyone been sick on the
                                  following?{" "}
                                </span>{" "}
                                {basicForm.family_health_history.label}
                              </Label>
                            </Col>
                            <Col md="12" xs="12">
                              <Label>
                                <span className="font-weight-bolder">
                                  If yes, who among them and what sickness?{" "}
                                </span>{" "}
                                {basicForm.family_health_history
                                  ? basicForm.family_member_with_sickness
                                  : "NA"}
                              </Label>
                            </Col>
                            <Col md="12" xs="12">
                              <Label>
                                <span className="font-weight-bolder">
                                  What vaccination did you have?{" "}
                                </span>{" "}
                                {basicForm.vaccination}
                              </Label>
                            </Col>
                            <Col md="12" xs="12">
                              <Label>
                                <span className="font-weight-bolder">
                                  How do you evaluate yourself?{" "}
                                </span>{" "}
                                {basicForm.self_evaluation.label}
                              </Label>
                            </Col>
                          </Row>

                          <Row className="mb-5">
                            <Col md="12" xs="12">
                              <h5 className="font-weight-bolder text-danger-edit">
                                ADDITIONAL INFORMATION
                              </h5>
                            </Col>
                            <Col md="12" xs="12">
                              <Label>
                                <span className="font-weight-bolder">
                                  Who is financially supporting your studies?{" "}
                                </span>{" "}
                                {basicForm.financial_support.label}
                              </Label>
                            </Col>
                            {basicForm.financial_support.value == "6" ? (
                              <Col md="12" xs="12">
                                <Label>
                                  <span className="font-weight-bolder">
                                    Other:{" "}
                                  </span>{" "}
                                  {basicForm.other_financial_support}
                                </Label>
                              </Col>
                            ) : (
                              ""
                            )}
                            <Col md="12" xs="12">
                              <Label>
                                <span className="font-weight-bolder">
                                  Area where you grew up:{" "}
                                </span>{" "}
                                {basicForm.grew_up.label}
                              </Label>
                            </Col>
                            <Col md="12" xs="12">
                              <Label>
                                <span className="font-weight-bolder">
                                  First language / dialect spoken at home:{" "}
                                </span>{" "}
                                {basicForm.language_home.label}
                              </Label>
                            </Col>
                            {basicForm.language_home.value == "3" ? (
                              <Col md="12" xs="12">
                                <Label>
                                  <span className="font-weight-bolder">
                                    Other:{" "}
                                  </span>{" "}
                                  {basicForm.other_language_home}
                                </Label>
                              </Col>
                            ) : (
                              ""
                            )}
                            <Col md="12" xs="12">
                              <Label>
                                <span className="font-weight-bolder">
                                  Which of the following concerns you most?{" "}
                                </span>{" "}
                                {basicForm.concerns.label}
                              </Label>
                            </Col>
                            {basicForm.concerns.value == "9" ? (
                              <Col md="12" xs="12">
                                <Label>
                                  <span className="font-weight-bolder">
                                    Other:{" "}
                                  </span>{" "}
                                  {basicForm.other_concern}
                                </Label>
                              </Col>
                            ) : (
                              ""
                            )}
                            <Col md="12" xs="12">
                              <Label>
                                <span className="font-weight-bolder">
                                  How do you usually deal with problem?{" "}
                                </span>{" "}
                                {basicForm.problem_solution.label}
                              </Label>
                            </Col>
                            {basicForm.concerns.value == "5" ? (
                              <Col md="12" xs="12">
                                <Label>
                                  <span className="font-weight-bolder">
                                    Other:{" "}
                                  </span>{" "}
                                  {basicForm.other_problem_solution}
                                </Label>
                              </Col>
                            ) : (
                              ""
                            )}
                            <Col md="12" xs="12">
                              <Label>
                                <span className="font-weight-bolder">
                                  How do you cope up with stressful situation?{" "}
                                </span>{" "}
                                {basicForm.cope_up_stress.label}
                              </Label>
                            </Col>
                            {basicForm.cope_up_stress.value == "7" ? (
                              <Col md="12" xs="12">
                                <Label>
                                  <span className="font-weight-bolder">
                                    Other:{" "}
                                  </span>{" "}
                                  {basicForm.other_cope_up_stress}
                                </Label>
                              </Col>
                            ) : (
                              ""
                            )}
                            <Col md="12" xs="12">
                              <Label>
                                <span className="font-weight-bolder">
                                  How do you cope up with stressful situation?{" "}
                                </span>{" "}
                                {basicForm.how_to_know_sapc.label}
                              </Label>
                            </Col>
                            {basicForm.how_to_know_sapc.value == "7" ? (
                              <Col md="12" xs="12">
                                <Label>
                                  <span className="font-weight-bolder">
                                    Other:{" "}
                                  </span>{" "}
                                  {basicForm.other_how_to_know_sapc}
                                </Label>
                              </Col>
                            ) : (
                              ""
                            )}
                            <Col md="12" xs="12">
                              <Label>
                                <span className="font-weight-bolder">
                                  How did you get to know about San Antonio de
                                  Padua College?{" "}
                                </span>{" "}
                                {basicForm.how_to_know_sapc.label}
                              </Label>
                            </Col>
                            {basicForm.how_to_know_sapc.value == "7" ? (
                              <Col md="12" xs="12">
                                <Label>
                                  <span className="font-weight-bolder">
                                    Other:{" "}
                                  </span>{" "}
                                  {basicForm.other_how_to_know_sapc}
                                </Label>
                              </Col>
                            ) : (
                              ""
                            )}
                            <Col md="12" xs="12">
                              <Label>
                                <span className="font-weight-bolder">
                                  Are you a part of Listahan (4P`s) ?{" "}
                                </span>{" "}
                                {basicForm.listahan.label}
                              </Label>
                            </Col>
                            {basicForm.listahan.value ? (
                              <Col md="12" xs="12">
                                <Label>
                                  <span className="font-weight-bolder">
                                    DSWD Household Number:{" "}
                                  </span>{" "}
                                  {basicForm.household_number}
                                </Label>
                              </Col>
                            ) : (
                              ""
                            )}
                            <Col md="12" xs="12">
                              <Label>
                                <span className="font-weight-bolder">
                                  Are you a person with Disability?{" "}
                                </span>{" "}
                                {basicForm.disability.label}
                              </Label>
                            </Col>
                            {basicForm.disability.value ? (
                              <Col md="12" xs="12">
                                <Label>
                                  <span className="font-weight-bolder">
                                    PWD number:{" "}
                                  </span>{" "}
                                  {basicForm.pwd_number}
                                </Label>
                              </Col>
                            ) : (
                              ""
                            )}
                            <Col md="12" xs="12">
                              <Label>
                                <span className="font-weight-bolder">
                                  Are you a currently recipient of a government
                                  subsidy from DEPED/CHED?{" "}
                                </span>{" "}
                                {basicForm.subsidy.label}
                              </Label>
                            </Col>
                            {basicForm.subsidy.value ? (
                              <Col md="12" xs="12">
                                <Label>
                                  <span className="font-weight-bolder">
                                    If yes, please write the details:{" "}
                                  </span>{" "}
                                  {basicForm.subsidy_details}
                                </Label>
                              </Col>
                            ) : (
                              ""
                            )}
                          </Row>
                        </>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Table bordered striped className="my-5">
                    <thead>
                      <tr>
                        <th>Subjects</th>
                        <th>Units</th>
                        <th>Lab Unit </th>
                      </tr>
                    </thead>
                    <tbody>
                      <>
                        {basicForm.subjects &&
                          basicForm.subjects.subjects.length &&
                          basicForm.subjects.subjects.map((subject, key) => {
                            return (
                              <tr key={key}>
                                <td>{subject.description}</td>
                                <td>{subject.unit}</td>
                                <td>
                                  {subject.lab_unit_price
                                    ? subject.lab_unit_price
                                    : "-"}
                                </td>
                              </tr>
                            );
                          })}
                      </>
                      <tr>
                        <td colSpan="2">Total Units</td>
                        <td>{totalUnit}</td>
                      </tr>
                      <tr>
                        <td colSpan="2">Lab Unit Price</td>
                        <td>{labUnitPrice}</td>
                      </tr>
                    </tbody>
                  </Table>
                </TabPane>
                <TabPane tabId="3">
                  <div className="py-5 px-2" style={{ overflowX: "auto" }}>
                    {scholarshipForm.scholarship ? (
                      <FormGroup className="mb-3">
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">Name: </span>{" "}
                            {scholarshipForm.scholarship_student_name}
                          </Label>
                        </Col>
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">Date: </span>{" "}
                            {scholarshipForm.scholarhip_date != ""
                              ? moment(basicForm.scholarhip_date).format(
                                  "YYYY-MM-DD"
                                )
                              : ""}
                          </Label>
                        </Col>
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              Academic Year From:{" "}
                            </span>{" "}
                            {scholarshipForm.scholarship_academic_year_from}
                          </Label>
                        </Col>
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              Academic Year To:{" "}
                            </span>{" "}
                            {scholarshipForm.scholarship_academic_year_to}
                          </Label>
                        </Col>
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              Semester:{" "}
                            </span>{" "}
                            {scholarshipForm.scholarship_semester}
                          </Label>
                        </Col>
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              Grade Level:{" "}
                            </span>{" "}
                            {scholarshipForm.scholarship_grade_level}
                          </Label>
                        </Col>
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">Course: </span>{" "}
                            {scholarshipForm.scholarship_course}
                          </Label>
                        </Col>
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">Year: </span>{" "}
                            {scholarshipForm.scholarship_year}
                          </Label>
                        </Col>
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              Application Type:{" "}
                            </span>{" "}
                            {scholarshipForm.scholarship_application_type}
                          </Label>
                        </Col>
                        <Col md="12" xs="12" className="mb-4">
                          <Label>
                            <span className="font-weight-bolder">
                              Previously Availed Scholarship:{" "}
                            </span>
                            <br /> {scholarshipForm.scholarship_previous}
                          </Label>
                        </Col>

                        <Col md="12" xs="12" className="mb-2">
                          <Label>
                            <span className="font-weight-bolder text-danger-edit">
                              SCHOLARSHIP and DISCOUNT APPLIED FOR
                            </span>
                          </Label>
                        </Col>

                        {scholarshipForm.family_discount ? (
                          <FormGroup className="mb-3">
                            <Col md="12" xs="12">
                              <Label>
                                <span className="font-weight-bolder">
                                  Family Discount{" "}
                                </span>
                              </Label>
                            </Col>
                            <Col md="12" xs="12">
                              <Label>
                                <span className="font-weight-bolder">
                                  Number of Siblings in SAPC:{" "}
                                </span>{" "}
                                {scholarshipForm.family_discount_siblings}
                              </Label>
                            </Col>
                          </FormGroup>
                        ) : (
                          ""
                        )}
                        {scholarshipForm.academic_scholarship ? (
                          <FormGroup className="mb-3">
                            <Col md="12" xs="12">
                              <Label>
                                <span className="font-weight-bolder mb-0">
                                  Academic Scholarship{" "}
                                </span>
                              </Label>
                            </Col>
                            <Col md="12" xs="12">
                              <Label>
                                <span className="font-weight-bolder">
                                  Academic Scholarship Rank:{" "}
                                </span>{" "}
                                {scholarshipForm.academic_scholarship_rank}
                              </Label>
                            </Col>
                            <Col md="12" xs="12">
                              <Label>
                                <span className="font-weight-bolder">
                                  Academic Scholarship Proof:{" "}
                                </span>
                                <br />
                                {scholarshipForm.academic_scholarship_proof ? (
                                  <a
                                    download
                                    href={
                                      scholarshipForm.academic_scholarship_proof_file_url
                                    }
                                    target="_blank"
                                  >
                                    {
                                      scholarshipForm.academic_scholarship_proof_file_name
                                    }
                                  </a>
                                ) : (
                                  "No File"
                                )}
                              </Label>
                            </Col>
                          </FormGroup>
                        ) : (
                          ""
                        )}
                        {scholarshipForm.athletic_scholarship ? (
                          <FormGroup className="mb-3">
                            <Col md="12" xs="12">
                              <Label>
                                <span className="font-weight-bolder mb-0">
                                  Athletic Scholarship{" "}
                                </span>
                              </Label>
                            </Col>
                            <Col md="12" xs="12">
                              <Label>
                                <span className="font-weight-bolder mb-0">
                                  Level of Sports Participation:{" "}
                                </span>{" "}
                                {scholarshipForm.athletic_level == 1
                                  ? "Provincial"
                                  : scholarshipForm.athletic_level == 2
                                  ? "Regional"
                                  : "National"}
                              </Label>
                            </Col>
                            <Col md="12" xs="12">
                              <Label>
                                <span className="font-weight-bolder">
                                  Member of SAPC Varsity Team:{" "}
                                </span>{" "}
                                {scholarshipForm.scholarship_specific_sports}
                              </Label>
                            </Col>
                          </FormGroup>
                        ) : (
                          ""
                        )}
                        {scholarshipForm.loyalty_discount ? (
                          <FormGroup className="mb-3">
                            <Col md="12" xs="12">
                              <Label>
                                <span className="font-weight-bolder mb-0">
                                  Loyalty Discount{" "}
                                </span>
                              </Label>
                            </Col>
                            <Col md="12" xs="12">
                              <Label>
                                <span className="font-weight-bolder">
                                  Specific Year of Graduation at SAPC:{" "}
                                </span>{" "}
                                {scholarshipForm.loyalty_discount_specific_year}
                              </Label>
                            </Col>
                          </FormGroup>
                        ) : (
                          ""
                        )}
                        {scholarshipForm.sapc_employee ? (
                          <FormGroup className="mb-3">
                            <Col md="12" xs="12">
                              <Label>
                                <span className="font-weight-bolder mb-0">
                                  SAPC Employee
                                </span>
                              </Label>
                            </Col>
                            <Col md="12" xs="12">
                              <Label>
                                <span className="font-weight-bolder">
                                  Specific Year of Service:{" "}
                                </span>{" "}
                                {
                                  scholarshipForm.sapc_employee_scholarship_specific
                                }
                              </Label>
                            </Col>
                          </FormGroup>
                        ) : (
                          ""
                        )}
                        {scholarshipForm.others ? (
                          <FormGroup className="mb-3">
                            <Col md="12" xs="12">
                              <Label>
                                <span className="font-weight-bolder mb-0">
                                  Other Scholarship
                                </span>
                              </Label>
                            </Col>
                            <Col md="12" xs="12">
                              <Label>
                                <span className="font-weight-bolder">
                                  Specific Scholarship:{" "}
                                </span>{" "}
                                {scholarshipForm.other_scholarship_specific}
                              </Label>
                            </Col>
                          </FormGroup>
                        ) : (
                          ""
                        )}
                        {scholarshipForm.esc_switch != "" ? (
                          <FormGroup>
                            <Col md="12" xs="12">
                              <Label>
                                <span className="font-weight-bolder">
                                  ESC:{" "}
                                </span>{" "}
                                {scholarshipForm.esc_switch ? "True" : "False"}
                              </Label>
                            </Col>
                            <Col md="12" xs="12">
                              <Label>
                                <span className="font-weight-bolder">
                                  ESC File:{" "}
                                </span>
                                <br />
                                {scholarshipForm.esc_file ? (
                                  <a
                                    download
                                    href={scholarshipForm.esc_file_url}
                                    target="_blank"
                                  >
                                    {scholarshipForm.esc_file_name}
                                  </a>
                                ) : (
                                  "No File"
                                )}
                              </Label>
                            </Col>
                          </FormGroup>
                        ) : (
                          ""
                        )}

                        {scholarshipForm.family_discount != "1" &&
                        scholarshipForm.academic_scholarship != "2" &&
                        scholarshipForm.athletic_scholarship != "3" &&
                        scholarshipForm.loyalty_discount != "4" &&
                        scholarshipForm.sapc_employee != "5" &&
                        scholarshipForm.others != "6" &&
                        scholarshipForm.esc_switch == "" ? (
                          <FormGroup>
                            <Col md="12" xs="12">
                              <Label className="text-secondary-edit">
                                No Scholarship/Discount chosen
                              </Label>
                            </Col>
                          </FormGroup>
                        ) : (
                          ""
                        )}
                      </FormGroup>
                    ) : (
                      <Col md="12" xs="12">
                        <Label className="text-secondary-edit">
                          No Scholarship Applied
                        </Label>
                      </Col>
                    )}
                  </div>
                </TabPane>
              </>
            ) : (
              <TabPane tabId="1">
                <Row className="flex-column py-5 px-3">
                  <Col md="12" xs="12">
                    <h5 className="font-weight-bolder text-danger-edit">
                      PERSONAL INFORMATION
                    </h5>
                  </Col>
                  <FormGroup className="mb-3">
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">Last Name: </span>{" "}
                        {basicForm.last_name}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">First Name: </span>{" "}
                        {basicForm.first_name}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">
                          Middle Name:{" "}
                        </span>{" "}
                        {basicForm.middle_name}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">Suffix: </span>{" "}
                        {basicForm.suffix}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">Gender: </span>{" "}
                        {basicForm.gender == "male" ? "Male" : "Female"}
                      </Label>
                    </Col>
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <Col md="12" xs="12">
                      <h5 className="font-weight-bolder text-danger-edit">
                        HOME ADDRESS
                      </h5>
                    </Col>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">
                          House no., Building, Street:{" "}
                        </span>{" "}
                        {basicForm.street}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">Province: </span>{" "}
                        {basicForm.province}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">City: </span>{" "}
                        {basicForm.city}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">Barangay: </span>{" "}
                        {basicForm.barangay}
                      </Label>
                    </Col>
                  </FormGroup>

                  <Col md="12" xs="12">
                    <h5 className="font-weight-bolder text-danger-edit">
                      FAMILY INFORMATION
                    </h5>
                  </Col>

                  <Col md="12" xs="12" className="pt-3">
                    <Label>
                      <span className="font-weight-bolder">
                        Father's Name:{" "}
                      </span>{" "}
                      {basicForm.father_name}
                    </Label>
                  </Col>
                  <Col md="12" xs="12">
                    <Label>
                      <span className="font-weight-bolder">Birth Date: </span>{" "}
                      {moment(basicForm.father_birth_date).format("YYYY-MM-DD")}
                    </Label>
                  </Col>
                  <Col md="12" xs="12">
                    <Label>
                      <span className="font-weight-bolder">Home Address: </span>{" "}
                      {basicForm.father_home_address}
                    </Label>
                  </Col>
                  <Col md="12" xs="12">
                    <Label>
                      <span className="font-weight-bolder">Contact No: </span>{" "}
                      {basicForm.father_contact_no}
                    </Label>
                  </Col>

                  <Col md="12" xs="12" className="pt-3">
                    <Label>
                      <span className="font-weight-bolder">
                        Mother's Name:{" "}
                      </span>{" "}
                      {basicForm.mother_name}
                    </Label>
                  </Col>
                  <Col md="12" xs="12">
                    <Label>
                      <span className="font-weight-bolder">Birth Date: </span>{" "}
                      {moment(basicForm.mother_birth_date).format("YYYY-MM-DD")}
                    </Label>
                  </Col>
                  <Col md="12" xs="12">
                    <Label>
                      <span className="font-weight-bolder">Home Address: </span>{" "}
                      {basicForm.mother_home_address}
                    </Label>
                  </Col>
                  <Col md="12" xs="12">
                    <Label>
                      <span className="font-weight-bolder">Contact No: </span>{" "}
                      {basicForm.mother_contact_no}
                    </Label>
                  </Col>

                  <Col md="12" xs="12" className="pt-3">
                    <Label>
                      <span className="font-weight-bolder">
                        Guardian's Name:{" "}
                      </span>{" "}
                      {basicForm.guardian_name}
                    </Label>
                  </Col>
                  <Col md="12" xs="12">
                    <Label>
                      <span className="font-weight-bolder">Birth Date: </span>{" "}
                      {moment(basicForm.guardian_birth_date).format(
                        "YYYY-MM-DD"
                      )}
                    </Label>
                  </Col>
                  <Col md="12" xs="12">
                    <Label>
                      <span className="font-weight-bolder">Home Address: </span>{" "}
                      {basicForm.guardian_home_address}
                    </Label>
                  </Col>
                  <Col md="12" xs="12">
                    <Label>
                      <span className="font-weight-bolder">Contact No: </span>{" "}
                      {basicForm.guardian_contact_no}
                    </Label>
                  </Col>
                </Row>
              </TabPane>
            )}
            <TabPane tabId="4">
              <Row className="py-5 px-3" style={{ overflowX: "auto" }}>
                <Col md="12" xs="12">
                  <h5 className="font-weight-bolder text-danger-edit mb-3">
                    ADMISSION REQUIREMENTS
                  </h5>
                </Col>
                {admissionAnswers.education_type == "Tertiary Education" ||
                admissionAnswers.education_type == "Basic Education" ? (
                  <>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">Form 138: </span>
                        <br />
                        {basicForm.form138 ? (
                          <a
                            download
                            href={basicForm.form138_file_url}
                            target="_blank"
                          >
                            {basicForm.form138_file_name}
                          </a>
                        ) : (
                          "No File"
                        )}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">Form 137: </span>
                        <br />
                        {basicForm.form137 ? (
                          <a
                            download
                            href={basicForm.form137_file_url}
                            target="_blank"
                          >
                            {basicForm.form137_file_name}
                          </a>
                        ) : (
                          "No File"
                        )}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">
                          Good Moral Character:{" "}
                        </span>
                        <br />
                        {basicForm.good_moral ? (
                          <a
                            download
                            href={basicForm.good_moral_file_url}
                            target="_blank"
                          >
                            {basicForm.good_moral_file_name}
                          </a>
                        ) : (
                          "No File"
                        )}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">
                          2"x2" Picture:{" "}
                        </span>
                        <br />
                        {basicForm.picture ? (
                          <a
                            download
                            href={basicForm.picture_file_url}
                            target="_blank"
                          >
                            {basicForm.picture_file_name}
                          </a>
                        ) : (
                          "No File"
                        )}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">NCAE: </span>
                        <br />
                        {basicForm.ncae ? (
                          <a
                            download
                            href={basicForm.ncae_file_url}
                            target="_blank"
                          >
                            {basicForm.ncae_file_name}
                          </a>
                        ) : (
                          "No File"
                        )}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label>
                        <span className="font-weight-bolder">
                          Birth Certificate:{" "}
                        </span>
                        <br />
                        {basicForm.birth_certificate ? (
                          <a
                            download
                            href={basicForm.birth_certificate_file_url}
                            target="_blank"
                          >
                            {basicForm.birth_certificate_file_name}
                          </a>
                        ) : (
                          "No File"
                        )}
                      </Label>
                    </Col>
                    {admissionAnswers.education_type == "Tertiary Education" ? (
                      <>
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              Certificate of Residency:{" "}
                            </span>
                            <br />{" "}
                            {basicForm.cert_of_residency ? (
                              <a
                                download
                                href={basicForm.cert_of_residency_file_url}
                                target="_blank"
                              >
                                {basicForm.cert_of_residency_file_name}
                              </a>
                            ) : (
                              "No File"
                            )}
                          </Label>
                        </Col>
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              Copy of Grades:{" "}
                            </span>
                            <br />{" "}
                            {basicForm.copy_of_grades ? (
                              <a
                                download
                                href={basicForm.copy_of_grades_file_url}
                                target="_blank"
                              >
                                {basicForm.copy_of_grades_file_name}
                              </a>
                            ) : (
                              "No File"
                            )}
                          </Label>
                        </Col>
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              SHS Diploma:{" "}
                            </span>
                            <br />{" "}
                            {basicForm.shs_diploma ? (
                              <a
                                download
                                href={basicForm.shs_diploma_file_url}
                                target="_blank"
                              >
                                {basicForm.shs_diploma_file_name}
                              </a>
                            ) : (
                              "No File"
                            )}
                          </Label>
                        </Col>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <Col md="12" xs="12">
                    <Label>
                      <span className="font-weight-bolder">Tesda Form: </span>
                      <br />
                      {tesdaForm.tesda_form ? (
                        <a
                          download
                          href={tesdaForm.tesda_form_file_url}
                          target="_blank"
                        >
                          {tesdaForm.tesda_form_file_name}
                        </a>
                      ) : (
                        "No File"
                      )}
                    </Label>
                  </Col>
                )}
              </Row>
            </TabPane>
          </TabContent>
        </Container>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggleViewApplication}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default MyApplicationView;
