import React, { useState } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
  Container,
  Label,
  FormGroup,
  Form,
  Input,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import classnames from "classnames";
import moment from "moment";
import { useForm, Controller } from "react-hook-form";
import { applicationActions } from "../../../services/ApplicationServices";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

const CancelApplication = ({
  id,
  cancelApplication,
  setCancelApplication,
  toggleCancel,
  setUpdateTable,
  updateTable,
  status,
}) => {
  const { register, handleSubmit, errors, control, setValue, getValues } =
    useForm();
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const onSubmit = (data) => {
    setButtonSpinner(true);
    if (status == "pending") {
      const dataSubmit = {
        application_id: id,
        cancel: 0,
        application_remarks: data.remarks,
      };
      applicationActions.cancelApplication(
        dataSubmit,
        setButtonSpinner,
        setCancelApplication,
        setUpdateTable,
        updateTable
      );
    }
    if (status == "certified") {
      const dataRevertSubmit = {
        application_id: id,
        application_remarks: data.remarks,
      };
      applicationActions.revertApplication(
        dataRevertSubmit,
        setButtonSpinner,
        setCancelApplication,
        setUpdateTable,
        updateTable
      );
    }
  };

  return (
    <Modal isOpen={cancelApplication} toggle={toggleCancel}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader toggle={toggleCancel}>Confirm Cancellation</ModalHeader>
        <ModalBody>
          <Row>
            <Col md="12" xs="12">
              <FormGroup>
                <Label
                  htmlFor="father_age"
                  className="text-secondary font-weight-bolder"
                >
                  Remarks
                </Label>
                <br />
                {status == "pending" ? (
                  <small>
                    Note: After cancellation, this application will no longer
                    visible
                  </small>
                ) : (
                  <small>
                    Note: After cancellation, the application status will
                    pending
                  </small>
                )}
                <Controller
                  control={control}
                  name="remarks"
                  rules={{ required: true }}
                  invalid={errors.remarks ? true : false}
                  defaultValue=""
                  render={({ onChange, value, onBlur, name }) => (
                    <Input
                      type="textarea"
                      className="form-control bg-light"
                      name="remarks"
                      defaultValue=""
                      onChange={(e) => {
                        setValue("remarks", e.target.value);
                        return e.target.value;
                      }}
                    />
                  )}
                />
                {errors.remarks && errors.remarks.type == "required" && (
                  <div
                    style={{
                      marginTop: "0.25rem",
                      fontSize: "80%",
                      color: "#f86c6b",
                    }}
                  >
                    Remarks is required!
                  </div>
                )}
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" disabled={buttonSpinner ? true : false}>
            {buttonSpinner ? (
              <>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp;Processing...
              </>
            ) : (
              "Confirm"
            )}
          </Button>
          <Button color="secondary" onClick={toggleCancel}>
            Cancel
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default CancelApplication;
