import React, { useState, useEffect, Fragment } from "react";
import {
  Container,
  Collapse,
  Navbar,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Card,
  CardImg,
  CardText,
  CardDeck,
  CardBody,
} from "reactstrap";
import { isDesktop, isMobile, isTablet, isIOS } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import landingPageLogo from "../../../assets/img/landingPageLogo.png";
import NavbarMobile from "../LandingPage/NavbarMobile.js";
import NavbarMobileAuth from "../LandingPage/NavbarMobileAuth.js";
import basic1 from "../../../assets/img/basic-1.jpg";
import tertiary1 from "../../../assets/img/tertiary-1.jpg";
import tesda1 from "../../../assets/img/tesda-1.jpg";
import Footer from "../LandingPage/Components/Footer.js";
import { Fade, Slide } from "react-reveal";
import { dynamicRequiermentsActions } from "../../../services/DynamicRequirementsServices";
import Loading from "../Forms/Loading";

const AdmissionPage = ({ authenticated }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [navbarMobile, setNavbarMobile] = useState(false);
  const [seeMore, setSeeMore] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDrop = (e) => setDropdownOpen(!dropdownOpen);
  const toggleDropClose = (e) => setDropdownOpen(false);

  const [loginDropdownOpen, setLoginDropdownOpen] = useState(false);
  const toggleDrop2 = (e) => setLoginDropdownOpen(!loginDropdownOpen);
  const toggleDropClose2 = (e) => setLoginDropdownOpen(false);

  const [lmsDropdownOpen, setLmsDropdownOpen] = useState(false);
  const toggleDrop3 = (e) => setLmsDropdownOpen(!lmsDropdownOpen);
  const toggleDropClose3 = (e) => setLmsDropdownOpen(false);

  const handleScroll = () => {
    setNavbarMobile(true);
  };

  useEffect(() => {
    document.title = "ADMISSION REQUIREMENTS | San Antonio de Padua College";
  }, []);

  const [currentVersion, setCurrentVersion] = useState("");
  const [versionSelection, setVersionSelection] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setCurrentVersion("");
    dynamicRequiermentsActions.getCurrentTemplateFrontPage(
      basicRequirements,
      setBasicRequirements,
      tertiaryRequirements,
      setTertiaryRequirements,
      technicalRequirements,
      setTechnicalRequirements,
      setCurrentVersion,
      setSelectedVersion,
      setLoading,
      setVersionSelection,
      basic1,
      tertiary1,
      tesda1
    );
  }, []);

  const [basicRequirements, setBasicRequirements] = useState({
    image: basic1,
    imageFile: "",
    title: "For High School / Grade School Students",
    subtitle_1: "Applicant should undergo Interview and Assessment",
    subtitle_2: "The following documents are required:",
    data: [
      {
        id: "Original and Photocopy of Form 138 (Grade 6/10 Card if incoming Gr. 7/11)",
        text: "Original and Photocopy of Form 138 (Grade 6/10 Card if incoming Gr. 7/11)",
      },
      {
        id: "Original and Photocopy of Form 137 (School Student Permanent Record Gr. 6/10)",
        text: "Original and Photocopy of Form 137 (School Student Permanent Record Gr. 6/10)",
      },
      {
        id: "Two (2) (2”x2”) picture (Colored with white background and name tag).",
        text: "Two (2) (2”x2”) picture (Colored with white background and name tag).",
      },
      {
        id: "Certificate of Good Moral Character signed by School Principal/Guidance Officer with documentary stamp.",
        text: "Certificate of Good Moral Character signed by School Principal/Guidance Officer with documentary stamp.",
      },
      {
        id: "National Career Assessment Examination (NCAE) result for Grades 10-12.",
        text: "National Career Assessment Examination (NCAE) result for Grades 10-12.",
      },
      {
        id: "Three (3) Photocopies of PSA authenticated Birth Certificate.",
        text: "Three (3) Photocopies of PSA authenticated Birth Certificate.",
      },
      {
        id: "Two (2) pieces heavy duty long brown envelope.",
        text: "Two (2) pieces heavy duty long brown envelope.",
      },
      {
        id: "Four (4) pieces of BIR Documentary Stamp (for F137 GR12 & Diploma / Grade 10 Certificate of Completion for JHS).",
        text: "Four (4) pieces of BIR Documentary Stamp (for F137 GR12 & Diploma / Grade 10 Certificate of Completion for JHS).",
      },
    ],
  });

  const [tertiaryRequirements, setTertiaryRequirements] = useState({
    image: tertiary1,
    imageFile: "",
    title: "For College Students",
    subtitle_1: "Applicant should undergo Interview and Assessment",
    subtitle_2: "The following documents are required:",
    data: [
      {
        id: "Original and Photocopy of Form 138 (Grade 12 Senior High School Card)",
        text: "Original and Photocopy of Form 138 (Grade 12 Senior High School Card)",
      },
      {
        id: "Original and Photocopy of Form 137 (Senior High School (SHS) Permanent record) with annotation “Eligible to Enter College education”.",
        text: "Original and Photocopy of Form 137 (Senior High School (SHS) Permanent record) with annotation “Eligible to Enter College education”.",
      },
      {
        id: "Copy of Grades (if College Undergraduate)",
        text: "Copy of Grades (if College Undergraduate)",
      },
      {
        id: "Two (3) (2”x2”) picture (Colored with white background and name tag).",
        text: "Two (3) (2”x2”) picture (Colored with white background and name tag).",
      },
      {
        id: "Certificate of Good Moral Character signed by School Principal/Guidance Officer with documentary stamp.",
        text: "Certificate of Good Moral Character signed by School Principal/Guidance Officer with documentary stamp.",
      },
      {
        id: "Certified photocopy of SHS Diploma with LRN, Track & Strand indicated and/or National Assessment Test (NAT) for Grade 12",
        text: "Certified photocopy of SHS Diploma with LRN, Track & Strand indicated and/or National Assessment Test (NAT) for Grade 12",
      },
      {
        id: "Three (3) Photocopies of PSA authenticated Birth Certificate.",
        text: "Three (3) Photocopies of PSA authenticated Birth Certificate.",
      },
      {
        id: "Two (2) pieces heavy duty long brown envelope.",
        text: "Two (2) pieces heavy duty long brown envelope.",
      },
      {
        id: "Four (4) pieces of BIR Documentary Stamp (for F137).",
        text: "Four (4) pieces of BIR Documentary Stamp (for F137).",
      },
      {
        id: "For TES Applicants: Certificate of Residency (for Pila, Laguna Residents)",
        text: "For TES Applicants: Certificate of Residency (for Pila, Laguna Residents)",
      },
    ],
  });

  const [technicalRequirements, setTechnicalRequirements] = useState({
    image: tesda1,
    imageFile: "",
    title: "For TESDA Students",
    subtitle_1:
      "Applicant should schedule an appointment in San Antonio De Padua College",
    subtitle_2: "The following documents are required:",
    data: [
      { id: "Form 137/TOR", text: "Form 137/TOR" },
      {
        id: "PSA copy of Birth Certificate",
        text: "PSA copy of Birth Certificate",
      },
      {
        id: "Photo Copy of Marriage Certificate of Wife if married.",
        text: "Photo Copy of Marriage Certificate of Wife if married.",
      },
      { id: "4 pcs 1x1 picture", text: "4 pcs 1x1 picture" },
      {
        id: "4 pcs passport size chemical print white background, with name tag and with collar.",
        text: "4 pcs passport size chemical print white background, with name tag and with collar.",
      },
    ],
  });

  return (
    <>
      {loading ? <Loading /> : ""}
      <Container
        fluid={true}
        className={
          isIOS
            ? "w-100 px-0 admission-image-mobile"
            : isTablet
            ? "w-100 px-0 admission-image"
            : "w-100 px-0 landingPage admission-image"
        }
      >
        {isDesktop ? (
          <Navbar expand="md lg" className="w-100 py-0 navbarClass">
            {/* <NavbarToggler onClick={toggle} /> */}
            <Collapse isOpen={isOpen} navbar>
              <Nav
                navbar
                className="mr-auto justify-content-between w-100 landingPageNav"
              >
                <NavItem className="w-100 text-center h-100">
                  <NavLink
                    className="navlinkButton font-weight-bolder px-5"
                    onClick={() => navigate("/")}
                  >
                    HOME
                  </NavLink>
                </NavItem>
                <NavItem className="w-100 text-center h-100">
                  <NavLink
                    className="navlinkButton font-weight-bolder px-4"
                    onClick={() => navigate("/about-us")}
                  >
                    ABOUT US
                  </NavLink>
                </NavItem>
                <NavItem className="w-100 text-center h-100">
                  <NavLink
                    className="navlinkButton font-weight-bolder px-5"
                    onClick={() => navigate("/academics")}
                  >
                    ACADEMICS
                  </NavLink>
                </NavItem>
                <div className="w-100 d-flex justify-content-center">
                  <img
                    src={landingPageLogo}
                    className="landingPageLogo"
                    style={{ width: "10rem" }}
                  />
                </div>
                <Dropdown
                  nav
                  isOpen={dropdownOpen}
                  onMouseEnter={toggleDrop}
                  onMouseLeave={toggleDropClose}
                  className="w-100 text-center h-100"
                >
                  <DropdownToggle
                    nav
                    caret
                    className="navlinkButton font-weight-bolder px-5"
                  >
                    ADMISSION
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem
                      className="font-weight-bolder text-danger-edit"
                      onClick={() => navigate("/admission-page")}
                    >
                      REQUIREMENTS
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem
                      className="font-weight-bolder text-danger-edit"
                      onClick={() => navigate("/scholarship-page")}
                    >
                      SCHOLARSHIP
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
                <Dropdown
                  nav
                  isOpen={lmsDropdownOpen}
                  onMouseEnter={toggleDrop3}
                  onMouseLeave={toggleDropClose3}
                  className="w-100 text-center h-100"
                >
                  <DropdownToggle
                    nav
                    caret
                    className="navlinkButton font-weight-bolder px-5"
                  >
                    LMS
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem
                      className="font-weight-bolder text-danger-edit"
                      onClick={() =>
                        navigate(
                          window.open(
                            "http://52.74.221.30/sapc/?fbclid=IwAR0Cb6NDp56odfRENGOl1Tr47ztbf0Ac38ZyBHCWCfIIyg2-ZcbmTvePYtw",
                            "_blank"
                          )
                        )
                      }
                    >
                      LIKHA ONLINE
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem
                      className="font-weight-bolder text-danger-edit"
                      onClick={() =>
                        navigate(
                          window.open(
                            "https://app.seesaw.me/#/student/login?fbclid=IwAR2GIW7rGIvu4SWjVDB2gmPe7riyw3smZnfGhWYOE-fDhw22Fr_RI3KUXX8",
                            "_blank"
                          )
                        )
                      }
                    >
                      SEESAW
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem
                      className="font-weight-bolder text-danger-edit"
                      onClick={() =>
                        navigate(
                          window.open("https://www.canva.com/", "_blank")
                        )
                      }
                    >
                      CANVA FOR EDUCATION
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
                <Dropdown
                  nav
                  isOpen={loginDropdownOpen}
                  onMouseEnter={toggleDrop2}
                  onMouseLeave={toggleDropClose2}
                  className="w-100 text-center h-100"
                >
                  <DropdownToggle
                    nav
                    caret
                    className="navlinkButton font-weight-bolder px-5"
                  >
                    LOGIN
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem
                      className="font-weight-bolder text-danger-edit"
                      onClick={() =>
                        navigate(window.open("http://mail.sapc.edu.ph/"))
                      }
                    >
                      UGNAI
                    </DropdownItem>
                    <DropdownItem divider />
                    {authenticated ? (
                      <DropdownItem
                        className="font-weight-bolder text-danger-edit"
                        onClick={() => navigate("/portal")}
                      >
                        PORTAL
                      </DropdownItem>
                    ) : (
                      <DropdownItem
                        className="font-weight-bolder text-danger-edit"
                        onClick={() => navigate("/login")}
                      >
                        SINAG
                      </DropdownItem>
                    )}
                    <DropdownItem divider />
                    <DropdownItem
                      className="font-weight-bolder text-danger-edit"
                      onClick={() =>
                        navigate(
                          window.open("https://sapc.ec1schoolsystems.com")
                        )
                      }
                    >
                      SASS
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </Nav>
            </Collapse>
          </Navbar>
        ) : (
          ""
        )}

        {isMobile ? (
          navbarMobile ? (
            authenticated ? (
              <NavbarMobileAuth
                setNavbarMobile={setNavbarMobile}
                navbarMobile={navbarMobile}
              />
            ) : (
              <NavbarMobile
                setNavbarMobile={setNavbarMobile}
                navbarMobile={navbarMobile}
              />
            )
          ) : (
            ""
          )
        ) : (
          ""
        )}
        {isMobile ? (
          <div
            onClick={handleScroll}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              zIndex: "9999",
            }}
          >
            <div className="bar-menu"></div>
            <div className="bar-menu"></div>
            <div className="bar-menu"></div>
          </div>
        ) : isTablet ? (
          <div
            onClick={handleScroll}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              zIndex: "9999",
            }}
          >
            <div className="bar-menu"></div>
            <div className="bar-menu"></div>
            <div className="bar-menu"></div>
          </div>
        ) : (
          ""
        )}

        <Container className="pt-5 mt-5 mb-5">
          <Fade top>
            <CardDeck>
              <Card className="shadow">
                <CardImg
                  style={{ height: "13rem" }}
                  top
                  width="100%"
                  src={basicRequirements.image}
                  alt="Card image cap"
                />
                <CardBody className="text-left py-5">
                  <p style={{ fontSize: "15px" }}>
                    <strong>{basicRequirements.title}</strong>
                  </p>
                  <p className="mb-1">{basicRequirements.subtitle_1}</p>
                  <p className="mb-3">{basicRequirements.subtitle_2}</p>
                  <ul className="mx-2">
                    {basicRequirements.data.length >= 1
                      ? basicRequirements.data.map((d, i) => {
                          return (
                            <Fragment key={i}>
                              <li className="mb-2">{d.text}</li>
                            </Fragment>
                          );
                        })
                      : ""}
                  </ul>
                </CardBody>
              </Card>

              <Card className="shadow">
                <CardImg
                  style={{ height: "13rem" }}
                  top
                  width="100%"
                  src={tertiaryRequirements.image}
                  alt="Card image cap"
                />
                <CardBody className="text-left py-5">
                  <p style={{ fontSize: "15px" }}>
                    <strong>{tertiaryRequirements.title}</strong>
                  </p>
                  <p className="mb-1">{tertiaryRequirements.subtitle_1}</p>
                  <p className="mb-3">{tertiaryRequirements.subtitle_2}</p>
                  <ul className="mx-2">
                    {tertiaryRequirements.data.length >= 1
                      ? tertiaryRequirements.data.map((d, i) => {
                          return (
                            <Fragment key={i}>
                              <li className="mb-2">{d.text}</li>
                            </Fragment>
                          );
                        })
                      : ""}
                  </ul>
                </CardBody>
              </Card>

              <Card className="shadow">
                <CardImg
                  style={{ height: "13rem" }}
                  top
                  width="100%"
                  src={technicalRequirements.image}
                  alt="Card image cap"
                />
                <CardBody className="text-left py-5">
                  <p style={{ fontSize: "15px" }}>
                    <strong>{technicalRequirements.title}</strong>
                  </p>
                  <p className="mb-1">{technicalRequirements.subtitle_1}</p>
                  <p className="mb-3">{technicalRequirements.subtitle_2}</p>
                  <ul className="mx-2">
                    {technicalRequirements.data.length >= 1
                      ? technicalRequirements.data.map((d, i) => {
                          return (
                            <Fragment key={i}>
                              <li className="mb-2">{d.text}</li>
                            </Fragment>
                          );
                        })
                      : ""}
                  </ul>
                </CardBody>
              </Card>
            </CardDeck>
          </Fade>
        </Container>
        <Footer />
      </Container>
    </>
  );
};

export default AdmissionPage;
