import {
    Container,
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavbarText,
    Row,
    Col,
    Label,
    Card,
    Button,
    CardImg,
    CardTitle,
    CardText,
    CardDeck,
    CardSubtitle,
    CardBody,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    TabContent,
    TabPane
} from 'reactstrap';
import {
    BrowserView,
    MobileView,
    isDesktop,
    isMobile,
    isTablet
} from "react-device-detect";
import back from '../../../../assets/img/acads-tertiary/back.jpg';
import hospitality from '../../../../assets/img/acads-tertiary/hospitality.jpg';
import businessAd1 from '../../../../assets/img/acads-tertiary/business-ad.jpg';
import businessAd2 from '../../../../assets/img/acads-tertiary/business-ad-2.jpg';
import psych from '../../../../assets/img/acads-tertiary/psych.jpg';
import officeAd from '../../../../assets/img/acads-tertiary/office-ad.jpg';
import { Fade, Slide } from "react-reveal";
import { Fragment } from 'react';

const TertiaryAcads = ({ tertiaryEducation }) => {
    return (
        <>
            <Row className={isDesktop ? "ml-4 pl-2" : isTablet ? "ml-4 pl-2" : "text-center"} xs="12" md="12" lg="12">
                <Col className="mt-5" xs="12" md="1" lg="1">
                    <h3 className="font-weight-bolder text-danger-edit">{tertiaryEducation.mainTitle}</h3>
                    <h3 className={isDesktop ? "font-weight-bolder text-danger-edit text-left" : "font-weight-bolder text-danger-edit mr-5 ml-5 mt-3 mb-2"} style={{ fontSize: "40px", borderTop: "4px solid" }}></h3>
                </Col>
                {/* <Col className={isDesktop ? "mt-5 pt-3 ml-5 pr-5" : "text-center"} xs="12" md="1" lg="1">
                        <h3 className={isDesktop ? "font-weight-bolder text-danger-edit text-left" : "font-weight-bolder text-danger-edit mr-5 ml-5 mt-3 mb-2"} style={{ fontSize: "40px", borderTop: "4px solid" }}></h3>
                    </Col> */}
            </Row>


            <Row className={isDesktop ? "d-flex mt-3 ml-5" : "d-flex mt-3 ml-5 mr-5 text-justify"} xs="12" md="12" lg="12">
                <p className="text-secondary font-weight-bolder w-75">{tertiaryEducation.mainDescription1}</p>
                <p className="text-secondary font-weight-bolder w-75">{tertiaryEducation.mainDescription2}</p>
            </Row>

            <Container fluid={true} className="bg-light">
                <Row lg="12" md="12" xs="12" className={isDesktop ? "align-items-center justify-content-center d-flex mt-5 pt-3 mb-5 ml-3" : "align-items-center justify-content-center d-flex mt-5 pt-3 mb-5 ml-3 mr-2"}>
                    <Col lg="8" md="8" xs="12" className="text-left mt-5">
                        <h4 className="text-danger-edit font-weight-bolder">{tertiaryEducation.firstTitle}</h4>
                        <Label className={isDesktop ? "text-secondary mr-2" : "text-secondary mr-2 text-justify"} style={{ fontSize: "14px" }}>
                            <strong>{tertiaryEducation.firstDescription}</strong>
                            <br /><br />

                            <span>{tertiaryEducation.firstContentTitle}</span>
                            <ol>
                                {tertiaryEducation.firstContents.length >= 1 ? tertiaryEducation.firstContents.map((content, key) => {
                                    return (
                                        <Fragment key={key}>
                                            <li>{key + 1 + ". "} {content.text}</li>
                                        </Fragment>
                                    )
                                }) : ""}
                            </ol>
                        </Label>
                    </Col>

                    <Col lg="4" md="4" xs="12">
                        {isDesktop ?
                            <Container className="acads-tertiary-cont">
                                <Slide right>
                                    <img src={back} style={{ width: "350px" }} className="tertiary-image back-image" />
                                    <img src={tertiaryEducation.firstImage} style={{ width: "350px" }} className="tertiary-image front-image" />
                                </Slide>
                            </Container>
                            :
                            <img src={tertiaryEducation.firstImage} style={{ width: "350px" }} className="w-100" />
                        }
                    </Col>
                </Row>

                {isDesktop ?
                    <Row lg="12" md="12" xs="12" className="align-items-center justify-content-center d-flex pt-3">
                        <Col lg="4" md="4">
                            <Container className="acads-tertiary-cont2">
                                <Row>
                                    <Slide left>
                                        <img src={back} style={{ width: "350px" }} className="tertiary-image back-image2" />
                                        <img src={tertiaryEducation.secondImage} style={{ width: "350px" }} className="tertiary-image front-image2" />
                                    </Slide>
                                </Row>
                            </Container>

                            <Container className="acads-tertiary-cont2">
                                <Row>
                                    <Slide left>
                                        <img src={back} style={{ width: "350px" }} className="tertiary-image back-image2-1" />
                                        <img src={tertiaryEducation.thirdImage} style={{ width: "350px" }} className="tertiary-image front-image2-1" />
                                    </Slide>
                                </Row>
                            </Container>
                        </Col>

                        <Col lg="8" md="8" className="text-left">
                            <h4 className="text-danger-edit font-weight-bolder">{tertiaryEducation.secondTitle}</h4>
                            <Label className="text-secondary mr-2" style={{ fontSize: "14px" }}>
                                <strong>{tertiaryEducation.secondDescription}</strong>
                                <br /><br />

                                <span>{tertiaryEducation.secondContentTitle}</span>
                                <ol>
                                    {tertiaryEducation.secondContents.length >= 1 ? tertiaryEducation.secondContents.map((content, key) => {
                                        return (
                                            <Fragment key={key}>
                                                <li>{key + 1 + ". "} {content.text}</li>
                                            </Fragment>
                                        )
                                    }) : ""}
                                </ol>

                                <span>{tertiaryEducation.secondContentTitle2}</span>
                                <ol>
                                    {tertiaryEducation.secondContents2.length >= 1 ? tertiaryEducation.secondContents2.map((content, key) => {
                                        return (
                                            <Fragment key={key}>
                                                <li>{key + 1 + ". "} {content.text}</li>
                                            </Fragment>
                                        )
                                    }) : ""}
                                </ol>
                            </Label>
                        </Col>
                    </Row>
                    :
                    <Row xs="12" className="align-items-center justify-content-center d-flex mt-5 pt-3 mb-5 ml-3 mr-2">
                        <Col xs="12" md="8" className="text-left">
                            <h4 className="text-danger-edit font-weight-bolder">{tertiaryEducation.secondTitle}</h4>
                            <Label className="text-secondary text-justify" style={{ fontSize: "14px" }}>
                                <strong>{tertiaryEducation.secondDescription}</strong>
                                <br /><br />

                                <span>{tertiaryEducation.secondContentTitle}</span>
                                <ol>
                                    {tertiaryEducation.secondContents.length >= 1 ? tertiaryEducation.secondContents.map((content, key) => {
                                        return (
                                            <Fragment key={key}>
                                                <li>{key + 1 + ". "} {content.text}</li>
                                            </Fragment>
                                        )
                                    }) : ""}
                                </ol>

                                <span>{tertiaryEducation.secondContentTitle2}</span>
                                <ol>
                                    {tertiaryEducation.secondContents2.length >= 1 ? tertiaryEducation.secondContents2.map((content, key) => {
                                        return (
                                            <Fragment key={key}>
                                                <li>{key + 1 + ". "} {content.text}</li>
                                            </Fragment>
                                        )
                                    }) : ""}
                                </ol>
                            </Label>
                        </Col>

                        <Col md="4" xs="12">
                            <Slide left>
                                <img src={tertiaryEducation.thirdImage} style={{ width: "350px" }} className="w-100 mb-3" />
                            </Slide>
                            <Slide left>
                                <img src={tertiaryEducation.secondImage} style={{ width: "350px" }} className="w-100" />
                            </Slide>
                        </Col>
                    </Row>
                }


                <Row lg="12" md="12" xs="12" className={isDesktop ? "align-items-center justify-content-center d-flex ml-3 mr-3 mt-5 pt-3" : "align-items-center justify-content-center d-flex ml-3 mr-3"}>
                    <Col lg="8" md="8" xs="12" className={isDesktop ? "text-left mb-5" : "text-justify"}>
                        <h4 className="text-danger-edit font-weight-bolder text-left">{tertiaryEducation.thirdTitle}</h4>
                        <Label className="text-secondary" style={{ fontSize: "14px" }}>
                            <strong>{tertiaryEducation.thirdDescription}</strong>
                            <br /><br />

                            <span>{tertiaryEducation.thirdContentTitle}</span>
                            <ol>
                                {tertiaryEducation.thirdContents.length >= 1 ? tertiaryEducation.thirdContents.map((content, key) => {
                                    return (
                                        <Fragment key={key}>
                                            <li>{key + 1 + ". "} {content.text}</li>
                                        </Fragment>
                                    )
                                }) : ""}
                            </ol>
                        </Label>
                    </Col>

                    <Col lg="4" md="4" xs="12">
                        {isDesktop ?
                            <Container className="acads-tertiary-cont">
                                <Slide right>
                                    <img src={back} style={{ width: "350px" }} className="tertiary-image back-image2-2" />
                                    <img src={tertiaryEducation.fourthImage} style={{ width: "350px" }} className="tertiary-image front-image2-2" />
                                </Slide>
                            </Container>
                            :
                            <Slide right>
                                <img src={tertiaryEducation.fourthImage} style={{ width: "350px" }} className="w-100 mb-5" />
                            </Slide>
                        }
                    </Col>
                </Row>

                <Row className={isDesktop ? "align-items-center justify-content-center d-flex ml-3 mr-3 mt-4 pt-2" : "align-items-center justify-content-center d-flex ml-3 mr-3"}>
                    <Col md="8" className={isDesktop ? "text-left mb-5 pb-5" : "text-justify"}>
                        <h4 className="text-danger-edit font-weight-bolder text-left">{tertiaryEducation.fourthTitle}</h4>
                        <Label className="text-secondary mr-2" style={{ fontSize: "14px" }}>
                            <strong>{tertiaryEducation.fourthDescription}</strong>
                            <br /><br />

                            <span>{tertiaryEducation.fourthContentTitle}</span>
                            <ol>
                                {tertiaryEducation.fourthContents.length >= 1 ? tertiaryEducation.fourthContents.map((content, key) => {
                                    return (
                                        <Fragment key={key}>
                                            <li>{key + 1 + ". "} {content.text}</li>
                                        </Fragment>
                                    )
                                }) : ""}
                            </ol>
                        </Label>
                    </Col>

                    <Col md="4" className="pb-5">
                        {isDesktop ?
                            <Container className="acads-tertiary-cont">
                                <Slide right>
                                    <img src={back} style={{ width: "350px" }} className="tertiary-image back-image2-3" />
                                    <img src={tertiaryEducation.fifthImage} style={{ width: "350px" }} className="tertiary-image front-image2-3" />
                                </Slide>
                            </Container>
                            :
                            <Slide right>
                                <img src={tertiaryEducation.fifthImage} style={{ width: "350px" }} className="w-100 mb-3" />
                            </Slide>
                        }
                    </Col>

                </Row>
            </Container>
        </>
    );
}

export default TertiaryAcads;
