import { ENDPOINT } from "../constants/api";
import axios from "axios";
import { notifyError, notifySuccess } from "./Toaster";
import moment from "moment";

export const courseActions = {
  getCourses,
  addCourse,
  updateCourse
};

const token = localStorage.getItem("jwtToken")
  ? localStorage.getItem("jwtToken")
  : "";
const headers = {
  Authorization: `Bearer ${token}`,
  "Content-Type": "application/json",
  "X-Requested-With": "XMLHttpRequest",
};

async function getCourses(setData, setLoading) {
  try {
    let res = await axios.get(ENDPOINT + `/get-course`, {
      headers: headers,
    });
    const courses = res.data.data.student_course;
    const newCourses = courses.map(course=>{
      return{
        id: course.id,
        name: course.name,
        description: course.description,
        grade_type_id: course.grade_type_id,
        grade_type: "Course",
        enabled: course.enabled,
        created_at: course.created_at,
      }
    })
    setData(newCourses)
    setLoading(false);
  } catch (err) {
    if (err.response) {
      notifyError("Error while fetching Data");
    }
  }
}

async function addCourse(dataToSubmit, setButtonSpinner, setUpdateTable, updateTable, setAddOrEditModal) {
  try {
    let res = await axios.post(ENDPOINT + `/add-course`, dataToSubmit, {
      headers: headers,
    });
    
    if(res.data.success){
        notifySuccess(res.data.message);
        setUpdateTable(!updateTable);
        setAddOrEditModal(false);
    }else{
      notifyError("Error while adding course.");
    }
    setButtonSpinner(false)
  } catch (err) {
    if (err.response) {
      notifyError("Error while adding course.");
    }
    setButtonSpinner(false)
  }
}

async function updateCourse(dataToSubmit, setButtonSpinner, setUpdateTable, updateTable, setAddOrEditModal) {
    try {
      let res = await axios.post(ENDPOINT + `/update-course`, dataToSubmit, {
        headers: headers,
      });

      if(res.data.success){
          notifySuccess(res.data.message);
          setUpdateTable(!updateTable);
          setAddOrEditModal(false);
      }else{
        notifyError("Error while updating course.");
      }
      setButtonSpinner(false)
    } catch (err) {
      if (err.response) {
        notifyError("Error while updating course.");
      }
      setButtonSpinner(false)
    }
  }

  