import {
    Container,
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavbarText,
    Row,
    Col,
    Label,
    Card,
    Button,
    CardImg,
    CardTitle,
    CardText,
    CardDeck,
    CardSubtitle,
    CardBody,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    TabContent,
    TabPane
} from 'reactstrap';
import {
    BrowserView,
    MobileView,
    isDesktop,
    isMobile,
    isTablet
} from "react-device-detect";
import technical from '../../../../assets/img/acads-tech/technical.png';
import technical2 from '../../../../assets/img/acads-tech/technical-2.png';
import { Fade, Slide } from "react-reveal";
import { Fragment } from 'react';

const TechnicalAcads = ({ technicalEducation }) => {
    return (
        <Container fluid={true}>
            <Row className={isDesktop ? "align-items-center justify-content-center d-flex mb-5 ml-3" : "align-items-center justify-content-center d-flex mb-5 ml-1 mr-1"} lg="12" md="12" xs="12">
                <Col lg="7" md="7" xs="12" className="text-justify mt-5">
                    <h4 className={isDesktop ? "text-danger-edit font-weight-bolder" : "text-danger-edit font-weight-bolder text-center"}>{technicalEducation.mainTitle}</h4>
                    <Label className="text-secondary mr-2" style={{ fontSize: "14px" }}>
                        <br />
                        <span>{technicalEducation.contentTitle1}</span>
                        <ol>
                            {technicalEducation.contents1.length >= 1 ? technicalEducation.contents1.map((content, key) => {
                                return (
                                    <Fragment key={key}>
                                        <li>{content.text}</li>
                                    </Fragment>
                                )
                            }) : ""}
                        </ol>
                    </Label>

                    <Label className="text-secondary" style={{ fontSize: "15px" }}>
                        <Row className={isDesktop ? "" : "text-left"}>
                            <Col md="6">
                                <strong>{technicalEducation.contentTitle2}</strong>
                                <br /><br />
                                <ul>
                                    {technicalEducation.contents2.length >= 1 ? technicalEducation.contents2.map((content, key) => {
                                        return (
                                            <Fragment key={key}>
                                                <li>{content.text}</li>
                                            </Fragment>
                                        )
                                    }) : ""}
                                </ul>
                            </Col>
                            <Col md="6">
                                <strong>{technicalEducation.contentTitle3}</strong>
                                <br /><br />
                                <ul>
                                    {technicalEducation.contents3.length >= 1 ? technicalEducation.contents3.map((content, key) => {
                                        return (
                                            <Fragment key={key}>
                                                <li>{content.text}</li>
                                            </Fragment>
                                        )
                                    }) : ""}
                                </ul>
                            </Col>
                        </Row>
                    </Label>
                </Col>

                <Col md="5">
                    {isDesktop ?
                        <Container className="acads-tertiary-cont">
                            <Slide right>
                                <img src={technicalEducation.image2} style={{ width: "500px" }} className="tertiary-image back-image-tech" />
                            </Slide>
                            <Slide left>
                                <img src={technicalEducation.image1} style={{ width: "470px" }} className="tertiary-image front-image-tech" />
                            </Slide>
                        </Container>
                        :
                        <Container>
                            <Slide right>
                                <img src={technicalEducation.image2} style={{ width: "500px" }} className="w-100 mb-3" />
                            </Slide>
                            <Slide left>
                                <img src={technicalEducation.image1} style={{ width: "500px" }} className="w-100" />
                            </Slide>
                        </Container>
                    }
                </Col>
            </Row>
        </Container>
    );

}

export default TechnicalAcads;
