import {
  Container,
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
  Row,
  Col,
  Label,
  Card,
  Button,
  CardImg,
  CardTitle,
  CardText,
  CardDeck,
  CardSubtitle,
  CardBody,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from "reactstrap";
import {
  BrowserView,
  MobileView,
  isDesktop,
  isMobile,
  isTablet,
  isIOS,
} from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, Fragment } from "react";
import NavbarMobile from "../LandingPage/NavbarMobile.js";
import NavbarMobileAuth from "../LandingPage/NavbarMobileAuth.js";
import sapcYellow from "../../../assets/img/SAPC-Logo-yellow.png";
import flatEllipse from "../../../assets/img/flat-ellipse.png";
import facebookIcon from "../../../assets/img/facebook-icon.png";
import twitterIcon from "../../../assets/img/twitter-icon.png";
import landingPageLogo from "../../../assets/img/landingPageLogo.png";
import aboutUsBase from "../../../assets/img/about-us-base-with-text.jpg";
import Chairman from "../../../assets/img/trustees/Chairman.jpg";
import AmeliaTrustee from "../../../assets/img/trustees/Amelia-Trustee.jpg";
import President from "../../../assets/img/trustees/President.jpg";
import SecretaryTrustee from "../../../assets/img/trustees/Secretary.JPG";
import ViceChairman from "../../../assets/img/trustees/Vice-Chairman.jpg";
import VictoriaTrustee from "../../../assets/img/trustees/Victoria-Trustee.jpg";
import Treasurer from "../../../assets/img/trustees/Cecilia-Treasurer.jpg";
import FooterTrans from "../LandingPage/Components/FooterTrans";
import aboutUsBaseMobile from "../../../assets/img/about-us-base-mobile.jpg";
import { Fade, Slide } from "react-reveal";
import { dynamicAboutUsActions } from "../../../services/DynamicAboutUsServices.js";
import Loading from "../Forms/Loading.js";
import BaseFooter from "../../../assets/img/base-footer.jpg";

const AboutUs = ({ authenticated }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [navbarMobile, setNavbarMobile] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDrop = (e) => setDropdownOpen(!dropdownOpen);
  const toggleDropClose = (e) => setDropdownOpen(false);
  const [currentVersion, setCurrentVersion] = useState("");
  const [versionSelection, setVersionSelection] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState("");
  const [loading, setLoading] = useState(false);

  const [loginDropdownOpen, setLoginDropdownOpen] = useState(false);
  const toggleDrop2 = (e) => setLoginDropdownOpen(!loginDropdownOpen);
  const toggleDropClose2 = (e) => setLoginDropdownOpen(false);

  const [lmsDropdownOpen, setLmsDropdownOpen] = useState(false);
  const toggleDrop3 = (e) => setLmsDropdownOpen(!lmsDropdownOpen);
  const toggleDropClose3 = (e) => setLmsDropdownOpen(false);

  const handleScroll = () => {
    setNavbarMobile(true);
  };

  const [aboutUsData, setAboutUsData] = useState({
    mainImageDesktop: aboutUsBase,
    mainImageDesktopFile: "",
    mainImageTablet: aboutUsBase,
    mainImageTabletFile: "",
    mainImageMobile: aboutUsBaseMobile,
    mainImageMobileFile: "",
    mainBaseImage: BaseFooter,
    mainBaseImageFile: "",
    mainTitle: "ABOUT US",
    mainDescription:
      "Located south of Manila is the town of Pila in Laguna – Known for its rich history and well-presented historical structural designs. Pila is the seat of the old Tagalog civilization, it is here where the San Antonio de Padua College (SAPC) was established in 1979 by a group of successful entrepreneurs and professionals who believed that poverty is not a hindrance to education. Thus, the institution was founded on a strong sense of corporate social responsibility of the founders. The San Antonio de Padua College was named in honor of the patron saint of the town. Saint Anthony of Padua whose feast day falls on the 13th of June. The school became San Antonio de Padua College Foundation, Inc. in the year 2002. Over the years, SAPC has distinguished itself in academics, culture, and the arts, sports, and entrepreneurship.",
    achievements: [
      {
        title: "Some of its award-winning achievements are :",
        data: [
          "National Essay Writing Contest Population Education Quiz Bee",
          "Jose P. Laurel Centennial Oratorical Contest recipient for three consecutive years of the Chief Girl Scout Award",
          "Four-time volleyball Southern Tagalog Region Champion",
          "Various Palarong Pambansa sports medals",
          "National Regional and Division Awardee of Secondary Schools Press Conference contest",
        ],
      },
      {
        title: "The school’s collegiate level achievements are :",
        data: [
          "Overall Champion in Provincial Skills Olympics (CALABARZON)",
          "Overall Champion in Chefs on Parade Winner of the Accounting Quiz Bee Champion in the Cooking Challenge at the World Food Expo.",
        ],
      },
    ],
    subTitle:
      "The school was founded and continues to be supported by the Figueroa Family of Makati and Pila together with other distinguished individuals who share in their belief and vision.",
    trusteesTitle: "BOARD OF TRUSTEES",
    trustees: [
      {
        image: Chairman,
        imageFile: "",
        name: "Leonardo V. Figueroa, Jr.",
        position: "CHAIRMAN",
      },
      {
        image: ViceChairman,
        imageFile: "",
        name: "Leonardo B. Figueroa, Sr.",
        position: "VICE CHAIRMAN",
      },
      {
        image: President,
        imageFile: "",
        name: "Elisa T. Martinez",
        position: "PRESIDENT",
      },
      {
        image: SecretaryTrustee,
        imageFile: "",
        name: "Ma. Mimie M. Castillo",
        position: "SECRETARY",
      },
      {
        image: Treasurer,
        imageFile: "",
        name: "Cecilia F. Ortiz",
        position: "TREASURER",
      },
      {
        image: AmeliaTrustee,
        imageFile: "",
        name: "Amelia F. Angeles",
        position: "TRUSTEE",
      },
      {
        image: VictoriaTrustee,
        imageFile: "",
        name: "Ma. Victoria A. Merca",
        position: "TRUSTEE",
      },
    ],
    bomTitle: "MEMBERS",
    bomData: [
      "Juliana V. Figueroa",
      "Ernesto B. Figueroa",
      "Liza F. Kravinsky",
      "Dr. Kenneth T. Figueroa",
      "Isagarde Jose D. Angeles",
      "Jaime F. Tiongson",
    ],
    philosophy: {
      title: "SCHOOL PHILOSOPHY",
      description:
        "San Antonio de Padua College strongly believes that education is a holistic experience of learning and living out.  The perfection of knowledge is better appreciated when it is fully realized in a life worth living.  SAPC, therefore, upholds an educational approach that places high regard for individuality and responsible commitment to excellence.  In keeping with this philosophy, the school is bound to carry out its mission as well as achieve its vision of nurturing generations of dynamic, creative and productive members of society.",
    },
    mission: {
      title: "MISSION",
      description:
        "We are and will always be an institution of learning for integrated basic, tertiary, and technical education and training that actively engages the family in pursuit of lifelong independent learning, critical and creative thinking and technological fluency. We are and will always contribute to nation-building and economic development through relevant researches and pro-active extension services geared towards the improvement of the environment and the quality of life of the community we serve. We are and will always demonstrate positive values and beliefs as well as love for country through the virtues of charity, humility and patience.",
    },
    vision: {
      title: "VISION",
      description:
        "We are an institution of learning that fosters the role of the family as we work together in preparing learners to be values-ready, life-ready, work-ready and world-ready.",
    },
    coreValues: {
      title: "CORE VALUES",
      description:
        "To effectively carry out its goals, SAPC as a community commits to the cultivation of the following character and values:",
    },
    coreValuesData: [
      {
        title: "STEWARDSHIP",
        description:
          "Fostering learning and high achievement characterized by social engagement, mutual respect, care and concern for all the students, family, community and environment.",
      },
      {
        title: "ACCOUNTABILITY",
        description:
          "Developing strong work ethic through honesty, integrity, authenticity, and confidence wherein each individual takes responsibility for his words, actions and deeds.",
      },
      {
        title: "DISCIPLINE",
        description:
          "Strengthening moral character by instilling discipline through mutual respect, along with the virtues of charity, humility and patience.",
      },
      {
        title: "EXCELLENCE",
        description:
          "Committing oneself in elevating his/her potentials through independent learning, research and inquiry, creativity, and use of new technologies.",
      },
    ],
  });

  useEffect(() => {
    document.title = "ABOUT US | San Antonio de Padua College";
  }, []);

  useEffect(() => {
    setLoading(true);
    setCurrentVersion("");
    dynamicAboutUsActions.getCurrentTemplateFrontPage(
      setAboutUsData,
      aboutUsData,
      setCurrentVersion,
      setSelectedVersion,
      setLoading,
      setVersionSelection,
      aboutUsBase,
      Chairman,
      AmeliaTrustee,
      President,
      SecretaryTrustee,
      ViceChairman,
      VictoriaTrustee,
      Treasurer,
      aboutUsBaseMobile,
      BaseFooter
    );
  }, []);

  return (
    <Container
      fluid={true}
      className={
        isIOS
          ? "w-100 px-0 overflow-hidden"
          : "w-100 px-0 landingPage overflow-hidden"
      }
    >
      {loading ? <Loading /> : ""}
      {isDesktop ? (
        <Navbar expand="md" className="w-100 py-0 navbarClass">
          {/* <NavbarToggler onClick={toggle} /> */}
          <Collapse isOpen={isOpen} navbar>
            <Nav
              navbar
              className="mr-auto justify-content-between w-100 landingPageNav"
            >
              <NavItem className="w-100 text-center h-100">
                <NavLink
                  className="navlinkButton font-weight-bolder px-5"
                  onClick={() => navigate("/")}
                >
                  HOME
                </NavLink>
              </NavItem>
              <NavItem className="w-100 text-center h-100">
                <NavLink
                  className="navlinkButton font-weight-bolder px-4"
                  onClick={() => navigate("/about-us")}
                >
                  ABOUT US
                </NavLink>
              </NavItem>
              <NavItem className="w-100 text-center h-100">
                <NavLink
                  className="navlinkButton font-weight-bolder px-5"
                  onClick={() => navigate("/academics")}
                >
                  ACADEMICS
                </NavLink>
              </NavItem>
              <div className="w-100 d-flex justify-content-center">
                <img
                  src={landingPageLogo}
                  className="landingPageLogo"
                  style={{ width: "10rem" }}
                />
              </div>
              <Dropdown
                nav
                isOpen={dropdownOpen}
                onMouseEnter={toggleDrop}
                onMouseLeave={toggleDropClose}
                className="w-100 text-center h-100"
              >
                <DropdownToggle
                  nav
                  caret
                  className="navlinkButton font-weight-bolder px-5"
                >
                  ADMISSION
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem
                    className="font-weight-bolder text-danger-edit"
                    onClick={() => navigate("/admission-page")}
                  >
                    REQUIREMENTS
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem
                    className="font-weight-bolder text-danger-edit"
                    onClick={() => navigate("/scholarship-page")}
                  >
                    SCHOLARSHIP
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
              <Dropdown
                nav
                isOpen={lmsDropdownOpen}
                onMouseEnter={toggleDrop3}
                onMouseLeave={toggleDropClose3}
                className="w-100 text-center h-100"
              >
                <DropdownToggle
                  nav
                  caret
                  className="navlinkButton font-weight-bolder px-5"
                >
                  LMS
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem
                    className="font-weight-bolder text-danger-edit"
                    onClick={() =>
                      navigate(
                        window.open(
                          "http://52.74.221.30/sapc/?fbclid=IwAR0Cb6NDp56odfRENGOl1Tr47ztbf0Ac38ZyBHCWCfIIyg2-ZcbmTvePYtw",
                          "_blank"
                        )
                      )
                    }
                  >
                    LIKHA ONLINE
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem
                    className="font-weight-bolder text-danger-edit"
                    onClick={() =>
                      navigate(
                        window.open(
                          "https://app.seesaw.me/#/student/login?fbclid=IwAR2GIW7rGIvu4SWjVDB2gmPe7riyw3smZnfGhWYOE-fDhw22Fr_RI3KUXX8",
                          "_blank"
                        )
                      )
                    }
                  >
                    SEESAW
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem
                    className="font-weight-bolder text-danger-edit"
                    onClick={() =>
                      navigate(window.open("https://www.canva.com/", "_blank"))
                    }
                  >
                    CANVA FOR EDUCATION
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
              <Dropdown
                nav
                isOpen={loginDropdownOpen}
                onMouseEnter={toggleDrop2}
                onMouseLeave={toggleDropClose2}
                className="w-100 text-center h-100"
              >
                <DropdownToggle
                  nav
                  caret
                  className="navlinkButton font-weight-bolder px-5"
                >
                  LOGIN
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem
                    className="font-weight-bolder text-danger-edit"
                    onClick={() =>
                      navigate(window.open("http://mail.sapc.edu.ph/"))
                    }
                  >
                    UGNAI
                  </DropdownItem>
                  <DropdownItem divider />
                  {authenticated ? (
                    <DropdownItem
                      className="font-weight-bolder text-danger-edit"
                      onClick={() => navigate("/portal")}
                    >
                      PORTAL
                    </DropdownItem>
                  ) : (
                    <DropdownItem
                      className="font-weight-bolder text-danger-edit"
                      onClick={() => navigate("/login")}
                    >
                      SINAG
                    </DropdownItem>
                  )}
                  <DropdownItem divider />
                  <DropdownItem
                    className="font-weight-bolder text-danger-edit"
                    onClick={() =>
                      navigate(window.open("https://sapc.ec1schoolsystems.com"))
                    }
                  >
                    SASS
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </Nav>
          </Collapse>
        </Navbar>
      ) : (
        ""
      )}

      {isMobile ? (
        navbarMobile ? (
          authenticated ? (
            <NavbarMobileAuth
              setNavbarMobile={setNavbarMobile}
              navbarMobile={navbarMobile}
            />
          ) : (
            <NavbarMobile
              setNavbarMobile={setNavbarMobile}
              navbarMobile={navbarMobile}
            />
          )
        ) : (
          ""
        )
      ) : (
        ""
      )}
      {isMobile ? (
        <div
          onClick={handleScroll}
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            zIndex: "9999",
          }}
        >
          <div className="bar-menu"></div>
          <div className="bar-menu"></div>
          <div className="bar-menu"></div>
        </div>
      ) : isTablet ? (
        <div
          onClick={handleScroll}
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            zIndex: "9999",
          }}
        >
          <div className="bar-menu"></div>
          <div className="bar-menu"></div>
          <div className="bar-menu"></div>
        </div>
      ) : (
        ""
      )}
      <Slide left>
        <Row>
          <Col
            md="12"
            xs="12"
            className="px-0 w-100 justify-content-center align-items-center d-flex"
          >
            {isDesktop ? (
              <img
                src={aboutUsData.mainImageDesktop}
                className="w-100"
                style={{ height: "95vh", objectFit: "cover" }}
              />
            ) : isTablet ? (
              <img
                src={aboutUsData.mainImageTablet}
                className="w-100"
                style={{ height: "50vh", objectFit: "cover" }}
              />
            ) : (
              <img
                src={aboutUsData.mainImageMobile}
                className="w-100 image-mobile"
                style={{ height: "100vh", objectFit: "cover" }}
              />
            )}
          </Col>
        </Row>
      </Slide>
      <div
        style={{
          backgroundImage: `url(${aboutUsData.mainBaseImage})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "bottom",
          minHeight: "100vh",
          backgroundSize: "100%",
          position: "absolute",
          overflowX: "hidden",
        }}
      >
        <Row className="text-secondary">
          <Col
            md="2"
            xs="12"
            className="about-border justify-content-center align-items-center d-flex"
          >
            <h3
              className={
                isDesktop
                  ? "vertical-flip font-weight-bolder"
                  : isTablet
                  ? "vertical-flip font-weight-bolder"
                  : "mt-5 font-weight-bolder"
              }
            >
              {aboutUsData.mainTitle}
            </h3>
          </Col>
          <Col
            md="9"
            xs="12"
            className={
              isDesktop
                ? "mx-auto h-100 w-100 justify-content-center align-items-center mt-5"
                : "mt-5 justify-content-center align-items-center px-5"
            }
          >
            <p>{aboutUsData.mainDescription}</p>
            <br />
            {isDesktop ? (
              <>
                <div>
                  {aboutUsData.achievements.length >= 1
                    ? aboutUsData.achievements.map((achievement, key) => {
                        return (
                          <Fragment key={key}>
                            <p className="font-weight-bolder">
                              {achievement.title}
                            </p>
                            <ul className="mb-5">
                              {achievement.data.map((list, i) => {
                                return (
                                  <Fragment key={i}>
                                    <li>{list}</li>
                                  </Fragment>
                                );
                              })}
                            </ul>
                          </Fragment>
                        );
                      })
                    : ""}
                </div>

                <p className="font-weight-bolder">{aboutUsData.subTitle}</p>
              </>
            ) : (
              <>
                <div>
                  {aboutUsData.achievements.length >= 1
                    ? aboutUsData.achievements.map((achievement, key) => {
                        return (
                          <Fragment key={key}>
                            <p className="font-weight-bolder">
                              {achievement.title}
                            </p>
                            {achievement.data.map((list, i) => {
                              return (
                                <Fragment key={i}>
                                  <p>{list}</p>
                                </Fragment>
                              );
                            })}
                          </Fragment>
                        );
                      })
                    : ""}
                </div>
                <p className="font-weight-bolder">{aboutUsData.subTitle}</p>
              </>
            )}
          </Col>
        </Row>

        <Row>
          <Col
            md="2"
            xs="12"
            className="about-border justify-content-center align-items-center d-flex bg-warning text-white"
          >
            <h3
              className={
                isDesktop
                  ? "vertical-flip font-weight-bolder"
                  : isTablet
                  ? "vertical-flip font-weight-bolder"
                  : "mt-2 font-weight-bolder text-center"
              }
            >
              {aboutUsData.trusteesTitle}
            </h3>
          </Col>

          <Col
            md="4"
            xs="12"
            className="mt-5 font-weight-bolder text-secondary"
          >
            <div>
              {aboutUsData.trustees.map((trustee, i) => {
                return (
                  <Row
                    key={i}
                    className="ml-1 justify-content-center align-items-center"
                  >
                    <Col>
                      <img
                        src={trustee.image}
                        style={{
                          width: "150px",
                          height: "150px",
                          objectFit: "cover",
                        }}
                        className="mb-4"
                      />
                    </Col>
                    <Col>
                      <p>
                        {trustee.name}
                        <br />
                        {trustee.position}
                      </p>
                    </Col>
                  </Row>
                );
              })}
            </div>

            <Row
              className={
                isDesktop
                  ? "ml-1 justify-content-center align-items-center text-white members-overlay ml-3"
                  : "ml-1 justify-content-center align-items-center"
              }
            >
              <Col xs="12" className="py-2 text-center">
                <div className="members-opacity">
                  <p>{aboutUsData.bomTitle}</p>
                  {aboutUsData.bomData.map((data) => {
                    return <p className="mb-0">{data}</p>;
                  })}
                </div>
              </Col>
            </Row>
          </Col>

          <Col
            md="6"
            xs="12"
            className="mx-auto h-100 w-100 justify-content-center align-items-center pt-5 text-center content-border bg-light"
          >
            <h4 className="font-weight-bolder text-center mx-5">
              {aboutUsData.philosophy.title}
            </h4>
            <p className="text-justify mx-5">
              {aboutUsData.philosophy.description}
            </p>

            <h4 className="font-weight-bolder text-center mx-5">
              {aboutUsData.mission.title}
            </h4>
            <p className="text-justify mx-5">
              {aboutUsData.mission.description}
            </p>

            <h4 className="font-weight-bolder text-center mx-5">
              {aboutUsData.vision.title}
            </h4>
            <p className="text-justify mx-5">
              {aboutUsData.vision.description}
            </p>

            <div>
              <h4 className="font-weight-bolder text-center mx-5">
                {aboutUsData.coreValues.title}
              </h4>
              <p className="text-justify mx-5 mb-3">
                {aboutUsData.coreValues.description}
              </p>
            </div>
            <div>
              {aboutUsData.coreValuesData.length >= 1 ? (
                <>
                  {aboutUsData.coreValuesData.map((d, i) => {
                    return (
                      <Fragment key={i}>
                        <p className="text-justify mx-5">
                          <strong>{d.title + ". "}</strong>
                          {d.description}
                        </p>
                      </Fragment>
                    );
                  })}
                </>
              ) : (
                ""
              )}
            </div>
            <br />
          </Col>
        </Row>
        <FooterTrans />
      </div>
    </Container>
  );
};

export default AboutUs;
