import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalBody,
  FormGroup,
  Input,
  Form,
  FormFeedback,
  Spinner,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { isDesktop, isIOS } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { authActions } from "../../../services/AuthService";
import SubmitCode from "./SubmitCode";
import ResetPassword from "./ResetPassword";

const ForgotPassword = (props) => {
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    register,
    errors,
    reset,
    getValues,
    setValue,
  } = useForm();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [forgotPwProcess, setForgotPwProcess] = useState(0);
  const [emailAdress, setEmailAddress] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [verificationId, setVerificationId] = useState("");

  const { buttonLabel, className } = props;

  const [modal, setModal] = useState(true);
  const toggle = () => {
    if (!modal) {
      setModal(!modal);
    } else {
      setModal(!modal);
      navigate("/login");
    }
  };

  if (modal === false) {
    Swal.fire({
      icon: "success",
      title: "An email has been sent for your password change!",
      showConfirmButton: false,
      timer: 1500,
    });
    setTimeout(function () {
      window.location.replace("http://localhost:3000/login");
    }, 3000);
  }

  const onSubmit = (data) => {
    setEmailAddress(data.email_address);
    setButtonSpinner(true);
    authActions.forgotPassword(
      data,
      setError,
      setSuccess,
      setButtonSpinner,
      setForgotPwProcess,
      setVerificationId
    );
  };
  useEffect(() => {
    document.title = "FORGOT PASSWORD | San Antonio de Padua College";
  }, []);

  return (
    <div className="two-background justify-content-center align-items-center d-flex">
      <Modal
        isOpen={modal}
        toggle={toggle}
        className={isIOS ? "modal-center" : "landingPage modal-center"}
      >
        <ModalBody>
          {!forgotPwProcess ? (
            <>
              <span
                style={{ fontSize: "25px" }}
                className="font-weight-bolder hand-pointer"
                placeholder="Back"
                onClick={() => navigate("/login")}
              >
                &#8592;
              </span>
              <div
                className={
                  isDesktop
                    ? "text-center mt-5 mb-5 mx-5"
                    : "text-center mt-5 mb-5"
                }
              >
                <h3 className="font-weight-bolder">
                  <i className="fa fa-lock" style={{ fontSize: "50px" }}></i>{" "}
                  FORGOT PASSWORD
                </h3>
                <p className="mt-3 mb-3 text-secondary font-weight-bolder">
                  To reset your password, provide your account's email address.
                </p>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <FormGroup>
                    <Controller
                      as={
                        <Input className="form-control bg-light signIn rounded-0" />
                      }
                      value=""
                      defaultValue=""
                      type="text"
                      id="email_address"
                      name="email_address"
                      className="text-center"
                      control={control}
                      rules={{
                        required: true,
                        pattern:
                          /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                      }}
                      invalid={errors.email_address ? true : false}
                      onChange={(e) => {
                        return e.target.value;
                      }}
                    />
                    {errors.email_address &&
                      errors.email_address.type == "required" && (
                        <FormFeedback>Email is required!</FormFeedback>
                      )}
                    {errors.email_address &&
                      errors.email_address.type == "pattern" && (
                        <FormFeedback>
                          Please input a valid email address!
                        </FormFeedback>
                      )}
                  </FormGroup>
                  <Button
                    disabled={buttonSpinner}
                    className="bg-warning-edit text-white font-weight-bolder border-0 w-100 button-transition"
                  >
                    {buttonSpinner ? (
                      <>
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        &nbsp;Processing...
                      </>
                    ) : (
                      "SUBMIT"
                    )}
                  </Button>
                </Form>
              </div>
            </>
          ) : forgotPwProcess == 1 ? (
            <SubmitCode
              emailAdress={emailAdress}
              setForgotPwProcess={setForgotPwProcess}
              setVerificationCode={setVerificationCode}
            />
          ) : forgotPwProcess == 2 ? (
            <ResetPassword
              emailAdress={emailAdress}
              setForgotPwProcess={setForgotPwProcess}
              verificationCode={verificationCode}
              verificationId={verificationId}
            />
          ) : (
            ""
          )}
        </ModalBody>
      </Modal>
    </div>
  );
};
export default ForgotPassword;
