import {
  Container,
  Collapse,
  Navbar,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  TabContent,
  TabPane,
} from "reactstrap";
import { isDesktop, isMobile, isTablet, isIOS } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import NavbarMobile from "../LandingPage/NavbarMobile.js";
import NavbarMobileAuth from "../LandingPage/NavbarMobileAuth.js";
import landingPageLogo from "../../../assets/img/landingPageLogo.png";
import academicsBase from "../../../assets/img/academics-base-with-text.jpg";
import academicsBaseMobile from "../../../assets/img/academics-base-mobile.jpg";
import classnames from "classnames";
import BasicAcads from "./Components/BasicAcads.js";
import TertiaryAcads from "./Components/TertiaryAcads.js";
import TechnicalAcads from "./Components/TechnicalAcads.js";
import Footer from "../LandingPage/Components/Footer.js";
import { Fade, Slide } from "react-reveal";
import preSchool from "../../../assets/img/acads-basic/pre-school.jpg";
import gradeSchool1 from "../../../assets/img/acads-basic/grade-school-1.jpg";
import gradeSchool2 from "../../../assets/img/acads-basic/grade-school-2.jpg";
import highSchool1 from "../../../assets/img/acads-basic/high-school-1.jpg";
import highSchool2 from "../../../assets/img/acads-basic/high-school-2.jpg";
import highSchool3 from "../../../assets/img/acads-basic/high-school-3.jpg";
import highSchool4 from "../../../assets/img/acads-basic/high-school-4.jpg";
import hospitality from "../../../assets/img/acads-tertiary/hospitality.jpg";
import businessAd1 from "../../../assets/img/acads-tertiary/business-ad.jpg";
import businessAd2 from "../../../assets/img/acads-tertiary/business-ad-2.jpg";
import psych from "../../../assets/img/acads-tertiary/psych.jpg";
import officeAd from "../../../assets/img/acads-tertiary/office-ad.jpg";
import technical from "../../../assets/img/acads-tech/technical.png";
import technical2 from "../../../assets/img/acads-tech/technical-2.png";
import { dynamicAcademicsActions } from "../../../services/DynamicAcademicsServices.js";
import Loading from "../Forms/Loading.js";

const Academics = ({ authenticated }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [navbarMobile, setNavbarMobile] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDrop = (e) => setDropdownOpen(!dropdownOpen);
  const toggleDropClose = (e) => setDropdownOpen(false);

  const [loginDropdownOpen, setLoginDropdownOpen] = useState(false);
  const toggleDrop2 = (e) => setLoginDropdownOpen(!loginDropdownOpen);
  const toggleDropClose2 = (e) => setLoginDropdownOpen(false);

  const [lmsDropdownOpen, setLmsDropdownOpen] = useState(false);
  const toggleDrop3 = (e) => setLmsDropdownOpen(!lmsDropdownOpen);
  const toggleDropClose3 = (e) => setLmsDropdownOpen(false);

  const handleScroll = () => {
    setNavbarMobile(true);
  };

  const [currentVersion, setCurrentVersion] = useState("");
  const [versionSelection, setVersionSelection] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState("");
  const [loading, setLoading] = useState(false);

  const [activeTab, setActiveTab] = useState("1");

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const [basicEducation, setBasicEducation] = useState({
    mainImage: academicsBase,
    mainTablet: academicsBase,
    mainMobile: academicsBaseMobile,
    mainTitle: "Goals & Objectives",
    mainDescription:
      "In keeping with the goals and objectives of the Department of Education and the vision and mission of the San Antonio de Padua College Foundation of Pila, Laguna Inc, the Basic Education Department hopes to attain the following:",
    first: preSchool,
    second: gradeSchool1,
    third: gradeSchool2,
    fourth: highSchool4,
    fifth: highSchool2,
    sixth: highSchool1,
    seventh: highSchool3,
    firstTitle: "Pre-School",
    firstDescription: "Nursery and Kindergarten programs are committed to:",
    firstContents: [
      {
        id: "Implement a curriculum that provides pupils with a variety of holistic developmentally meaningful activities that nurture them to become confident, happy, and lifelong learners.",
        text: "Implement a curriculum that provides pupils with a variety of holistic developmentally meaningful activities that nurture them to become confident, happy, and lifelong learners.",
      },
      {
        id: "Engage pupils in a variety of fun and stimulating activities that allows children to enjoy, explore, investigate, discover, collaborate, create, and innovate.",
        text: "Engage pupils in a variety of fun and stimulating activities that allows children to enjoy, explore, investigate, discover, collaborate, create, and innovate.",
      },
      {
        id: "Emboldens a strong, supportive partnership between home and the school.",
        text: "Emboldens a strong, supportive partnership between home and the school.",
      },
    ],
    secondTitle: "Grade School",
    secondDescription: "The SAPC Grade School is committed to:",
    secondContents: [
      {
        id: "Provide an engaging educational program that is age-appropriate that enables learners aged 6 to 11 to move progressively towards independent learning.",
        text: "Provide an engaging educational program that is age-appropriate that enables learners aged 6 to 11 to move progressively towards independent learning.",
      },
      {
        id: "Implement a curricular approach that blends student-centered approaches with E-Learning, integrating understanding of career pathways in preparation for higher learning.",
        text: "Implement a curricular approach that blends student-centered approaches with E-Learning, integrating understanding of career pathways in preparation for higher learning.",
      },
      {
        id: "Builds on the discovery of students’ strengths to gain confidence through self-expression and collaboration with fellow students.",
        text: "Builds on the discovery of students’ strengths to gain confidence through self-expression and collaboration with fellow students.",
      },
      {
        id: "Promote a strong, supportive partnership between home and the school and the wider community.",
        text: "Promote a strong, supportive partnership between home and the school and the wider community.",
      },
    ],
    thirdTitle: "High School",
    thirdDescription: "SAPC High School (Grades 7 – 12) is committed in:",
    thirdContents: [
      {
        id: "Providing an educational program that develops students’ academic, thinking, and workplace competencies to prepare them for employment, entrepreneurship, middle level skills development, and higher education.",
        text: "Providing an educational program that develops students’ academic, thinking, and workplace competencies to prepare them for employment, entrepreneurship, middle level skills development, and higher education.",
      },
      {
        id: "Implementing a curriculum that is student-centered, technologically-aided, relevant, collaborative, career-oriented, and global in perspective that allows all students to succeed.",
        text: "Implementing a curriculum that is student-centered, technologically-aided, relevant, collaborative, career-oriented, and global in perspective that allows all students to succeed.",
      },
      {
        id: "Fostering an atmosphere where student feels they belong, respected and accepted by as they nurture creativity and originality to be competent and socially engaged individuals.",
        text: "Fostering an atmosphere where student feels they belong, respected and accepted by as they nurture creativity and originality to be competent and socially engaged individuals.",
      },
    ],
  });

  const [tertiaryEducation, setTertiaryEducation] = useState({
    mainTitle: "Curriculum",
    mainDescription1:
      "SAPC course offerings are responsible to the needs of the times and in consonance with student skills and interests.",
    mainDescription2:
      "All business and related courses are offered in ladderized curriculum. After two years (4 semesters) an Associate Diploma is awarded with the option of pursuing the full 4 years degree courses.",
    firstTitle:
      "Bachelor of Science in Hospitality Management – Ladderized Curriculum",
    firstDescription:
      "The program equips students with essential skills, knowledge, competencies and attitude to pursue and succeed in a career in the tourism industry and/or hospitality sectors. Apply basic techniques in performing prescribed range of specific functions in the areas of Food and Beverage, Front Office, and Housekeeping Operations as required in accommodation, food and beverages enterprises; undertakes planning and initiation of alternative approaches to skills and knowledge applications across a broad range of technical and procedural requirements.",
    firstContentTitle: "The program aims to produce graduates who can:",
    firstContents: [
      {
        id: "Function as an effective and skilled worker in every aspect of food management and hotel supervision and administration.",
        text: "Function as an effective and skilled worker in every aspect of food management and hotel supervision and administration.",
      },
      {
        id: "To integrate stewardship, accountability, excellence and moral values in the exercise of their function.",
        text: "To integrate stewardship, accountability, excellence and moral values in the exercise of their function.",
      },
      {
        id: "Provide leadership is the promotion of the countries natural and cultural attraction to domestic and international travelers.",
        text: "Provide leadership is the promotion of the countries natural and cultural attraction to domestic and international travelers.",
      },
    ],
    secondTitle: "Bachelor of Science in Business Administration",
    secondDescription:
      "Human Resource Development Program aims to prepare the graduates for a career in the field of Human Resource Management in various corporations whether in the manufacturing, marketing and service sectors, or in the different types of industries such as pharmaceutical, semi-conductor, food and beverage, banking industries and other types of organizations.",
    secondContentTitle: "The program aims to produce graduates who can:",
    secondContents: [
      {
        id: "Exhibit proficiency in the major facets of concepts, principles, theories, and philosophies in Human Resource of business operations.",
        text: "Exhibit proficiency in the major facets of concepts, principles, theories, and philosophies in Human Resource of business operations.",
      },
      {
        id: "Effectively show management/marketing techniques, skills and principles to enable them to deal with actual situation of problem solving and decision making.",
        text: "Effectively show management/marketing techniques, skills and principles to enable them to deal with actual situation of problem solving and decision making.",
      },
      {
        id: "Appreciate the HR role in the organization and how they can make meaningful contributions as a strategic partner in building the organization to become globally competitive.",
        text: "Appreciate the HR role in the organization and how they can make meaningful contributions as a strategic partner in building the organization to become globally competitive.",
      },
      {
        id: "Manifest stewardship, accountability, excellence and moral integrity in the exercise of one’s personal and professional life.",
        text: "Manifest stewardship, accountability, excellence and moral integrity in the exercise of one’s personal and professional life.",
      },
    ],
    secondContentTitle2:
      "The Operations Management Program prepares the students for the acquisition of competencies and skills needed as managers in manufacturing and service oriented businesses. It focuses on managing the processes to produce and distribute products and services. Major overall activities often include product creation, development, production and distribution. Operations Management covers all operations within the organization and related activities including managing purchases, inventory control, quality control, storage, logistics and evaluation. A great deal of focus is on efficiency and effectiveness of processes.",
    secondContents2: [
      {
        id: "Show know-how with concepts, principles and the theories of Operations Management as well display competencies and skills as entrepreneurs and managers.",
        text: "Show know-how with concepts, principles and the theories of Operations Management as well display competencies and skills as entrepreneurs and managers.",
      },
      {
        id: "Organize and manage activities in manufacturing and service related businesses.",
        text: "Organize and manage activities in manufacturing and service related businesses.",
      },
      {
        id: "Manifest stewardship, accountability, excellence and moral integrity in the exercise of one’s personal and professional life.",
        text: "Manifest stewardship, accountability, excellence and moral integrity in the exercise of one’s personal and professional life.",
      },
      {
        id: "Prepare the students to pursue a teaching career or graduate studies in business.",
        text: "Prepare the students to pursue a teaching career or graduate studies in business.",
      },
    ],
    thirdTitle: "Bachelor of Arts in Psychology",
    thirdDescription:
      "The program prepares students for jobs that may involve training, testing, research and intervention in industrial, clinical, and educational settings. It also provides preparation for graduate studies in psychology as well as further studies in other field of specialization such as medicine, law, and business management.",
    thirdContentTitle: "The program aims to produce graduates who:",
    thirdContents: [
      {
        id: "Can be proficient in dealing with people of different level.",
        text: "Can be proficient in dealing with people of different level.",
      },
      {
        id: "Possess high moral and ethical standards and values in his relation with others.",
        text: "Possess high moral and ethical standards and values in his relation with others.",
      },
      {
        id: "Can undertake research using the knowledge and skills in psychology and able to communicate the result of such research to both specialist and non-specialist audiences.",
        text: "Can undertake research using the knowledge and skills in psychology and able to communicate the result of such research to both specialist and non-specialist audiences.",
      },
      {
        id: "Are equipped with knowledge in understanding people with problems in their relationship with others.",
        text: "Are equipped with knowledge in understanding people with problems in their relationship with others.",
      },
    ],
    fourthTitle: "Associate in Office Administration",
    fourthDescription:
      "The program prepares students for a career in an outcomes focused, technology rich professional environment. It also provides preparation for a four- year course in BS in Office Administration.",
    fourthContentTitle: "The program aims to produce graduates who:",
    fourthContents: [
      {
        id: "Are adept in the current techniques in office practice and procedures, development of office systems and technology, efficient team-building and management skills.",
        text: "Are adept in the current techniques in office practice and procedures, development of office systems and technology, efficient team-building and management skills.",
      },
      {
        id: "Possess effective human relations and communication skill to become key players in day-to-day office operations.",
        text: "Possess effective human relations and communication skill to become key players in day-to-day office operations.",
      },
      {
        id: "Possess high moral standards and values in his relation with the others.",
        text: "Possess high moral standards and values in his relation with the others.",
      },
      {
        id: "Are equipped with leadership skills needed to cope with the increasing responsibility in the field of business and industry.",
        text: "Are equipped with leadership skills needed to cope with the increasing responsibility in the field of business and industry.",
      },
    ],
    firstImage: hospitality,
    secondImage: businessAd2,
    thirdImage: businessAd1,
    fourthImage: highSchool4,
    fifthImage: highSchool2,
  });

  const [technicalEducation, setTechnicalEducation] = useState({
    mainTitle: "Technical Education and Skills Development",
    contentTitle1:
      "The TESDA programs of SAPC aims provide middle level skills development trainings that seeks to produce graduates who are:",
    contents1: [
      {
        id: "Highly skilled and competent workers demanded by industry.",
        text: "Highly skilled and competent workers demanded by industry.",
      },
      {
        id: "Certified according to competency assessment standards.",
        text: "Certified according to competency assessment standards.",
      },
      {
        id: "Displays desirable work attitude and integrates stewardship, accountability, and excellence in the exercise of their function.",
        text: "Displays desirable work attitude and integrates stewardship, accountability, and excellence in the exercise of their function.",
      },
    ],
    contentTitle2: "TRAINING CENTER",
    contents2: [
      {
        id: "Bread and Pastry Production NC II",
        text: "Bread and Pastry Production NC II",
      },
      { id: "Caregiving NC II", text: "Caregiving NC II" },
      { id: "Cookery NC II", text: "Cookery NC II" },
      { id: "Housekeeping NC II", text: "Housekeeping NC II" },
      {
        id: "Food and Beverage Services NC II",
        text: "Food and Beverage Services NC II",
      },
      {
        id: "Shielded Metal Arc Welding (SMAW) NC I",
        text: "Shielded Metal Arc Welding (SMAW) NC I",
      },
      {
        id: "Shielded Metal Arc Welding (SMAW) NC II",
        text: "Shielded Metal Arc Welding (SMAW) NC II",
      },
    ],
    contentTitle3: "ACCREDITED COMPETENCY ASSESSMENT CENTER",
    contents3: [
      {
        id: "Bread and Pastry Production NC II",
        text: "Bread and Pastry Production NC II",
      },
      { id: "Caregiving NC II", text: "Caregiving NC II" },
      { id: "Cookery NC II", text: "Cookery NC II" },
      { id: "Housekeeping NC II", text: "Housekeeping NC II" },
      {
        id: "Food and Beverage Services NC II",
        text: "Food and Beverage Services NC II",
      },
      {
        id: "Shielded Metal Arc Welding (SMAW) NC I",
        text: "Shielded Metal Arc Welding (SMAW) NC I",
      },
      {
        id: "Shielded Metal Arc Welding (SMAW) NC II",
        text: "Shielded Metal Arc Welding (SMAW) NC II",
      },
    ],
    image1: technical,
    image2: technical2,
  });

  useEffect(() => {
    document.title = "ACADEMICS | San Antonio de Padua College";
  }, []);

  useEffect(() => {
    setLoading(true);
    setCurrentVersion("");
    dynamicAcademicsActions.getCurrentTemplateFrontPage(
      setBasicEducation,
      basicEducation,
      setTertiaryEducation,
      tertiaryEducation,
      setTechnicalEducation,
      technicalEducation,
      setCurrentVersion,
      setSelectedVersion,
      setLoading,
      setVersionSelection,
      academicsBase,
      academicsBaseMobile,
      preSchool,
      gradeSchool1,
      gradeSchool2,
      highSchool4,
      highSchool2,
      highSchool1,
      highSchool3,
      hospitality,
      businessAd2,
      businessAd1,
      technical,
      technical2
    );
  }, []);

  return (
    <Container
      fluid={true}
      className={
        isIOS
          ? "w-100 px-0 overflow-hidden"
          : "w-100 px-0 landingPage overflow-hidden"
      }
    >
      {loading ? <Loading /> : ""}
      {isDesktop ? (
        <Navbar expand="md lg" className="w-100 py-0 navbarClassTrans">
          {/* <NavbarToggler onClick={toggle} /> */}
          <Collapse isOpen={isOpen} navbar>
            <Nav
              navbar
              className="mr-auto justify-content-between w-100 landingPageNav"
            >
              <NavItem className="w-100 text-center h-100">
                <NavLink
                  className="navlinkButton font-weight-bolder px-5"
                  onClick={() => navigate("/")}
                >
                  HOME
                </NavLink>
              </NavItem>
              <NavItem className="w-100 text-center h-100">
                <NavLink
                  className="navlinkButton font-weight-bolder px-4"
                  onClick={() => navigate("/about-us")}
                >
                  ABOUT US
                </NavLink>
              </NavItem>
              <NavItem className="w-100 text-center h-100">
                <NavLink
                  className="navlinkButton font-weight-bolder px-5"
                  onClick={() => navigate("/academics")}
                >
                  ACADEMICS
                </NavLink>
              </NavItem>
              <div className="w-100 d-flex justify-content-center">
                <img
                  src={landingPageLogo}
                  className="landingPageLogo"
                  style={{ width: "10rem" }}
                />
              </div>
              <Dropdown
                nav
                isOpen={dropdownOpen}
                onMouseEnter={toggleDrop}
                onMouseLeave={toggleDropClose}
                className="w-100 text-center h-100"
              >
                <DropdownToggle
                  nav
                  caret
                  className="navlinkButton font-weight-bolder px-5"
                >
                  ADMISSION
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem
                    className="font-weight-bolder text-danger-edit"
                    onClick={() => navigate("/admission-page")}
                  >
                    REQUIREMENTS
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem
                    className="font-weight-bolder text-danger-edit"
                    onClick={() => navigate("/scholarship-page")}
                  >
                    SCHOLARSHIP
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
              <Dropdown
                nav
                isOpen={lmsDropdownOpen}
                onMouseEnter={toggleDrop3}
                onMouseLeave={toggleDropClose3}
                className="w-100 text-center h-100"
              >
                <DropdownToggle
                  nav
                  caret
                  className="navlinkButton font-weight-bolder px-5"
                >
                  LMS
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem
                    className="font-weight-bolder text-danger-edit"
                    onClick={() =>
                      navigate(
                        window.open(
                          "http://52.74.221.30/sapc/?fbclid=IwAR0Cb6NDp56odfRENGOl1Tr47ztbf0Ac38ZyBHCWCfIIyg2-ZcbmTvePYtw",
                          "_blank"
                        )
                      )
                    }
                  >
                    LIKHA ONLINE
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem
                    className="font-weight-bolder text-danger-edit"
                    onClick={() =>
                      navigate(
                        window.open(
                          "https://app.seesaw.me/#/student/login?fbclid=IwAR2GIW7rGIvu4SWjVDB2gmPe7riyw3smZnfGhWYOE-fDhw22Fr_RI3KUXX8",
                          "_blank"
                        )
                      )
                    }
                  >
                    SEESAW
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem
                    className="font-weight-bolder text-danger-edit"
                    onClick={() =>
                      navigate(window.open("https://www.canva.com/", "_blank"))
                    }
                  >
                    CANVA FOR EDUCATION
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
              <Dropdown
                nav
                isOpen={loginDropdownOpen}
                onMouseEnter={toggleDrop2}
                onMouseLeave={toggleDropClose2}
                className="w-100 text-center h-100"
              >
                <DropdownToggle
                  nav
                  caret
                  className="navlinkButton font-weight-bolder px-5"
                >
                  LOGIN
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem
                    className="font-weight-bolder text-danger-edit"
                    onClick={() =>
                      navigate(window.open("http://mail.sapc.edu.ph/"))
                    }
                  >
                    UGNAI
                  </DropdownItem>
                  <DropdownItem divider />
                  {authenticated ? (
                    <DropdownItem
                      className="font-weight-bolder text-danger-edit"
                      onClick={() => navigate("/portal")}
                    >
                      PORTAL
                    </DropdownItem>
                  ) : (
                    <DropdownItem
                      className="font-weight-bolder text-danger-edit"
                      onClick={() => navigate("/login")}
                    >
                      SINAG
                    </DropdownItem>
                  )}
                  <DropdownItem divider />
                  <DropdownItem
                    className="font-weight-bolder text-danger-edit"
                    onClick={() =>
                      navigate(window.open("https://sapc.ec1schoolsystems.com"))
                    }
                  >
                    SASS
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </Nav>
          </Collapse>
        </Navbar>
      ) : (
        ""
      )}

      {isMobile ? (
        navbarMobile ? (
          authenticated ? (
            <NavbarMobileAuth
              setNavbarMobile={setNavbarMobile}
              navbarMobile={navbarMobile}
            />
          ) : (
            <NavbarMobile
              setNavbarMobile={setNavbarMobile}
              navbarMobile={navbarMobile}
            />
          )
        ) : (
          ""
        )
      ) : (
        ""
      )}
      {isMobile ? (
        <div
          onClick={handleScroll}
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            zIndex: "9999",
          }}
        >
          <div className="bar-menu"></div>
          <div className="bar-menu"></div>
          <div className="bar-menu"></div>
        </div>
      ) : isTablet ? (
        <div
          onClick={handleScroll}
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            zIndex: "9999",
          }}
        >
          <div className="bar-menu"></div>
          <div className="bar-menu"></div>
          <div className="bar-menu"></div>
        </div>
      ) : (
        ""
      )}

      <Slide top>
        <Row>
          <Col
            lg="12"
            md="12"
            xs="12"
            className="px-0 w-100 justify-content-center align-items-center d-flex"
          >
            {isDesktop ? (
              <img
                src={basicEducation.mainImage}
                className="w-100"
                style={{ height: "80vh", objectFit: "cover" }}
              />
            ) : isTablet ? (
              <img
                src={basicEducation.mainTablet}
                className="w-100"
                style={{ height: "100vh", objectFit: "cover" }}
              />
            ) : (
              <img
                src={basicEducation.mainMobile}
                className="w-100 image-mobile"
                style={{ height: "100vh", objectFit: "cover" }}
              />
            )}
          </Col>
        </Row>
      </Slide>

      <Nav
        tabs
        className={
          isDesktop
            ? ""
            : "align-items-center d-flex px-0 w-100 justified-content-center"
        }
      >
        <NavItem className={isDesktop ? "ml-auto" : ""}>
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => {
              toggleTab("1");
            }}
            id="basic-acads"
          >
            {isDesktop ? (
              <strong>BASIC EDUCATION</strong>
            ) : (
              <span className="font-weight-bolder">BASIC</span>
            )}
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "2" })}
            onClick={() => {
              toggleTab("2");
            }}
            id="tertiary-acads"
          >
            {isDesktop ? (
              <strong>TERTIARY EDUCATION</strong>
            ) : (
              <strong>TERTIARY</strong>
            )}
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "3" })}
            onClick={() => {
              toggleTab("3");
            }}
            id="technical-acads"
          >
            {isDesktop ? (
              <strong>TECHNICAL EDUCATION</strong>
            ) : (
              <strong>TECHNICAL</strong>
            )}
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <BasicAcads basicEducation={basicEducation} />
        </TabPane>

        <TabPane tabId="2">
          <TertiaryAcads tertiaryEducation={tertiaryEducation} />
        </TabPane>

        <TabPane tabId="3">
          <TechnicalAcads technicalEducation={technicalEducation} />
        </TabPane>
      </TabContent>
      <Footer />
    </Container>
  );
};

export default Academics;
