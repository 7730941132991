import React, { Fragment, useEffect, useState } from "react";
import {
  Container,
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  CustomInput,
  Input,
  Button,
  CardHeader,
  CardFooter,
  Card,
  CardBody,
  Table,
  Alert,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import SapcLogo from "../../../assets/img/SAPC-Logo-red.png";
import "../../../App.css";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { isDesktop } from "react-device-detect";
import optionList from "../Admission/optionList.js";
import MultiSelect from "@kenshooui/react-multi-select";
import "@kenshooui/react-multi-select/dist/style.css";
import PaymentForm from "./PaymentForm";
import { notifyError } from "../../../services/Toaster";

const Subjects = ({
  handleCancel,
  setEducationSelected,
  currentStep,
  setCurrentStep,
  setLoading,
  subjects,
  setSubjects,
  selectOptions,
  subjectForm,
  setSubjectForm,
  schoolFees,
  setTertiaryTotalTuition,
  allSubjects,
  selectedSubjects,
  setSelectedSubjects,
  setPaymentForm,
  paymentForm,
}) => {
  const admissionAnswers = localStorage.getItem("admissionAnswers")
    ? JSON.parse(localStorage.getItem("admissionAnswers"))
    : "";
  // const [basicForm, setBasicForm] = useState(sessionStorage.getItem('form') ? JSON.parse(sessionStorage.getItem('form')) : blankBasicEducationForm)
  const { register, handleSubmit, errors, control, setValue, getValues } =
    useForm();
  const [selectedEnrolleeType, setSelectedEnrolleeType] = useState("");
  const [tuitionFee, setTuitionFee] = useState(0);
  const [totalUnit, setTotalUnit] = useState(0);
  const [totalLabPrice, setTotalLabPrice] = useState(0);
  const [totalIrregularUnit, setTotalIrregularUnit] = useState(0);
  const [totalIrregularLabPrice, setTotalIrregularLabPrice] = useState(0);
  const [subjectsPerCourse, setSubjectsPerCourse] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState([]);
  const [subjectError, setSubjectError] = useState(false);
  const [overUnits, setOverUnits] = useState(false);

  const onSubmit = (data) => {
    setSubjectError(false);
    const duplicateSubjects = checkForDuplicates(selectedSubjects, "label");
    if (!duplicateSubjects) {
      if (
        (selectedEnrolleeType == "Manually select subjects" ||
          (subjectForm.enrollee_type &&
            subjectForm.enrollee_type.label == "Manually select subjects")) &&
        selectedSubjects.length < 1
      ) {
        setSubjectError(true);
      } else {
        if (
          selectedEnrolleeType == "Manually select subjects" ||
          (subjectForm.enrollee_type &&
            subjectForm.enrollee_type.label == "Manually select subjects")
        ) {
          setSubjectForm({
            ...subjectForm,
            enrollee_type: data.enrollee_type
              ? data.enrollee_type
              : subjectForm.enrollee_type,
            subjects: selectedSubjects,
          });
        } else {
          setSubjectForm({
            ...subjectForm,
            enrollee_type: data.enrollee_type
              ? data.enrollee_type
              : subjectForm.enrollee_type,
            subjects: subjects,
          });
        }
        setCurrentStep(currentStep + 1);
      }
    } else {
      notifyError("Duplicate subjects found. Please check");
    }
  };

  const checkForDuplicates = (array, keyName) => {
    return new Set(array.map((item) => item[keyName])).size !== array.length;
  };

  const handlePreviousButton = () => {
    setCurrentStep(currentStep - 1);
  };

  // useEffect(()=>{
  //     if(admissionAnswers.tertiary_course){
  //         setSelectedCourse(admissionAnswers.tertiary_course)
  //     }
  // }, [])
  useEffect(() => {
    if (schoolFees && schoolFees.length >= 1) {
      schoolFees.map((fee) => {
        if (admissionAnswers.grade_year_level.value == fee.grade_level_id) {
          // fee.tuition_fee = paymentForm.tuition_fee;
          setTuitionFee(parseFloat(fee.tuition_fee));
        }
      });
    }
  }, [schoolFees]);

  useEffect(() => {
    let unitArr = [];
    let labUnitPrice = [];
    let subjectsWLab = [];
    if (subjects && subjects.length >= 1) {
      subjects.map((subject) => {
        let unit = parseFloat(subject.unit);
        let unitPrice = parseFloat(subject.lab_unit_price);
        unitArr.push(unit);
        labUnitPrice.push(unitPrice);
        if (subject.lab_unit_price != "0.00") {
          subjectsWLab.push(subject);
        }
      });
      const sum = unitArr.reduce((result, number) => result + number);
      const labSum = labUnitPrice.reduce((result, number) => result + number);
      setTotalUnit(sum);
      setTotalLabPrice(labSum);
      const total_tuition = sum * tuitionFee;
      const new_fullpayment_fee = total_tuition + labSum;
      const sum_tuition_fee =
        total_tuition + paymentForm.miscellaneous_fee + paymentForm.other_fee;
      let subtotal = parseFloat(total_tuition) * 0.03;
      let discount_total = parseFloat(sum_tuition_fee) - subtotal.toFixed(2);

      let monthlyA_misc = paymentForm.miscellaneous_fee / 4;
      let monthlyA_other = paymentForm.other_fee;
      let monthlyA_tuition = total_tuition / 4;
      let monthlyA_upon = monthlyA_misc + monthlyA_other + monthlyA_tuition;
      let monthlyA_upon_with_lab =
        monthlyA_misc + monthlyA_other + monthlyA_tuition + labSum;
      let monthlyA_perBilling = monthlyA_misc + monthlyA_tuition;

      let monthlyB_misc = paymentForm.miscellaneous_fee / 4;
      let monthlyB_other = paymentForm.other_fee / 4;
      let monthlyB_tuition = total_tuition / 4;
      let monthlyB_upon = monthlyB_misc + monthlyB_other + monthlyB_tuition;
      let monthlyB_upon_with_lab =
        monthlyB_misc + monthlyB_other + monthlyB_tuition + labSum;

      let payment_mode = {
        annual: {
          miscellaneous_fee: paymentForm.miscellaneous_fee,
          other_fee: paymentForm.other_fee,
          payment_upon_enrollment: sum_tuition_fee,
          payment_upon_enrollment_lab: new_fullpayment_fee,
          tuition_fee: total_tuition,
        },
        monthly_a: {
          miscellaneous_fee: monthlyA_misc,
          other_fee: monthlyA_other,
          payment_upon_enrollment: monthlyA_upon,
          payment_upon_enrollment_lab: monthlyA_upon_with_lab,
          per_billing: monthlyA_perBilling,
          tuition_fee: monthlyA_tuition,
        },
        monthly_b: {
          miscellaneous_fee: monthlyB_misc,
          other_fee: monthlyB_other,
          payment_upon_enrollment: monthlyB_upon,
          payment_upon_enrollment_lab: monthlyB_upon_with_lab,
          per_billing: monthlyB_upon,
          tuition_fee: monthlyB_tuition,
        },
      };

      setPaymentForm({
        ...paymentForm,
        tuition_fee: total_tuition,
        discounted_tuituion_fee: discount_total,
        total_tuition: sum_tuition_fee,
        payment_mode: payment_mode,
        no_of_units: sum,
        subjects_with_lab_units: subjectsWLab,
      });
      setTertiaryTotalTuition(total_tuition);
    }
  }, [subjects, tuitionFee]);

  useEffect(() => {
    if (allSubjects && allSubjects.length >= 1) {
      const subjects1 = [];
      const subjects2 = [];
      const subjects3 = [];
      const subjects4 = [];
      const subjects1_second_sem = [];
      const subjects2_second_sem = [];
      const subjects3_second_sem = [];
      const subjects4_second_sem = [];
      allSubjects.map((subject) => {
        if (subject.course_type_id == selectedCourse.value) {
          switch (subject.year_level_id) {
            case 15:
              subject.semester == 0
                ? subjects1.push(subject)
                : subjects1_second_sem.push(subject);
            case 16:
              subject.semester == 0
                ? subjects2.push(subject)
                : subjects2_second_sem.push(subject);
            case 17:
              subject.semester == 0
                ? subjects3.push(subject)
                : subjects3_second_sem.push(subject);
            case 18:
              subject.semester == 0
                ? subjects4.push(subject)
                : subjects4_second_sem.push(subject);
          }
        }
      });

      const subjects = [
        {
          label: (
            <h5 className="text-center mb-2">First Year - First Semester</h5>
          ),
          options: subjects1,
        },
        {
          label: (
            <h5 className="text-center mb-2">First Year - Second Semester</h5>
          ),
          options: subjects1_second_sem,
        },
        {
          label: (
            <h5 className="text-center mb-2 mt-3">
              Second Year - First Semester
            </h5>
          ),
          options: subjects2,
        },
        {
          label: (
            <h5 className="text-center mb-2">Second Year - Second Semester</h5>
          ),
          options: subjects2_second_sem,
        },
        {
          label: (
            <h5 className="text-center mb-2 mt-3">
              Third Year - First Semester
            </h5>
          ),
          options: subjects3,
        },
        {
          label: (
            <h5 className="text-center mb-2 mt-3">
              Third Year - Second Semester
            </h5>
          ),
          options: subjects3_second_sem,
        },
        {
          label: (
            <h5 className="text-center mb-2 mt-3">
              Fourth Year - First Semester
            </h5>
          ),
          options: subjects4,
        },
        {
          label: (
            <h5 className="text-center mb-2 mt-3">
              Fourth Year - Second Semester
            </h5>
          ),
          options: subjects4_second_sem,
        },
      ];

      setSubjectsPerCourse(subjects);
    }
  }, [selectedCourse]);

  const handleAddSubject = (e) => {
    if (!overUnits) {
      setSelectedSubjects([...selectedSubjects, e]);
    } else {
      selectedSubjects.pop();
      notifyError("Already reached allowed max unit of subjects.");
    }
  };

  useEffect(() => {
    let unitArr = [];
    let labUnitPrice = [];
    let subjectsWLab = [];

    if (selectedSubjects && selectedSubjects.length >= 1) {
      selectedSubjects.map((subject) => {
        let unit = parseFloat(subject.unit);
        let unitPrice = parseFloat(subject.lab_unit_price);
        if (subject.lab_unit_price != "0.00") {
          subjectsWLab.push(subject);
        }
        unitArr.push(unit);
        labUnitPrice.push(unitPrice);
      });
      const sum = unitArr.reduce((result, number) => result + number);
      if (sum <= 30) {
        setOverUnits(false);
        const labSum = labUnitPrice.reduce((result, number) => result + number);
        setTotalIrregularUnit(sum);
        setTotalIrregularLabPrice(labSum);
        const total_tuition = sum * tuitionFee;
        const new_fullpayment_fee = total_tuition + labSum;

        const sum_tuition_fee =
          total_tuition + paymentForm.miscellaneous_fee + paymentForm.other_fee;
        let subtotal = parseFloat(total_tuition) * 0.03;
        let discount_total = parseFloat(sum_tuition_fee) - subtotal.toFixed(2);

        let monthlyA_misc = paymentForm.miscellaneous_fee / 4;
        let monthlyA_other = paymentForm.other_fee;
        let monthlyA_tuition = total_tuition / 4;
        let monthlyA_upon = monthlyA_misc + monthlyA_other + monthlyA_tuition;
        let monthlyA_upon_with_lab =
          monthlyA_misc + monthlyA_other + monthlyA_tuition + labSum;
        let monthlyA_perBilling = monthlyA_misc + monthlyA_tuition;

        let monthlyB_misc = paymentForm.miscellaneous_fee / 4;
        let monthlyB_other = paymentForm.other_fee / 4;
        let monthlyB_tuition = total_tuition / 4;
        let monthlyB_upon = monthlyB_misc + monthlyB_other + monthlyB_tuition;
        let monthlyB_upon_with_lab =
          monthlyB_misc + monthlyB_other + monthlyB_tuition + labSum;

        let payment_mode = {
          annual: {
            miscellaneous_fee: paymentForm.miscellaneous_fee,
            other_fee: paymentForm.other_fee,
            payment_upon_enrollment: sum_tuition_fee,
            payment_upon_enrollment_lab: new_fullpayment_fee,
            tuition_fee: total_tuition,
          },
          monthly_a: {
            miscellaneous_fee: monthlyA_misc,
            other_fee: monthlyA_other,
            payment_upon_enrollment: monthlyA_upon,
            payment_upon_enrollment_lab: monthlyA_upon_with_lab,
            per_billing: monthlyA_perBilling,
            tuition_fee: monthlyA_tuition,
          },
          monthly_b: {
            miscellaneous_fee: monthlyB_misc,
            other_fee: monthlyB_other,
            payment_upon_enrollment: monthlyB_upon,
            payment_upon_enrollment_lab: monthlyB_upon_with_lab,
            per_billing: monthlyB_upon,
            tuition_fee: monthlyB_tuition,
          },
        };

        setPaymentForm({
          ...paymentForm,
          tuition_fee: total_tuition,
          discounted_tuituion_fee: discount_total,
          total_tuition: sum_tuition_fee,
          payment_mode: payment_mode,
          no_of_units: sum,
          subjects_with_lab_units: subjectsWLab,
        });
        // setPaymentForm({...paymentForm.payment_mode.annual, payment_upon_enrollment: total_tuition})
        setTertiaryTotalTuition(total_tuition);
      } else {
        setOverUnits(true);
        notifyError("Already reached allowed max unit of subjects.");
        selectedSubjects.pop();
      }
    }
  }, [selectedSubjects, tuitionFee]);

  const handleRemove = (i) => {
    if (
      subjectForm.enrollee_type &&
      subjectForm.enrollee_type.label == "Manually select subjects"
    ) {
      const filteredSubjects = selectedSubjects.filter((subject, k) => {
        return i !== k;
      });
      setSelectedSubjects(filteredSubjects);
    }
  };

  return (
    <Container className="px-0">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md="6" xs="12">
            <FormGroup>
              <Label
                htmlFor="enrollee_type"
                className="text-left w-100 text-danger-edit font-weight-bolder"
              >
                Enrollee Type
              </Label>
              <Controller
                control={control}
                name="enrollee_type"
                defaultValue={subjectForm.enrollee_type}
                rules={{ required: true }}
                invalid={errors.enrollee_type ? true : false}
                render={({ onChange, value, onBlur, name }) => (
                  <Select
                    options={selectOptions.enrollee_type}
                    onChange={(e) => {
                      setValue("enrollee_type", e);
                      setSelectedEnrolleeType(e.label);
                      setSubjectForm({ ...subjectForm, enrollee_type: e });
                      return e;
                    }}
                    defaultValue={subjectForm.enrollee_type}
                    name="enrollee_type"
                  />
                )}
              />
              {errors.enrollee_type &&
                errors.enrollee_type.type == "required" && (
                  <small className="text-danger-edit">Please Select!</small>
                )}
            </FormGroup>
          </Col>
        </Row>

        <Card className="my-3">
          <CardHeader>Subjects</CardHeader>
          <CardBody style={{ overflowX: "auto" }}>
            {selectedEnrolleeType == "Regular Student" ||
            (subjectForm.enrollee_type &&
              subjectForm.enrollee_type.label == "Regular Student") ? (
              <>
                <Table bordered striped>
                  <thead>
                    <tr>
                      <th>Course Code</th>
                      <th>Subjects</th>
                      <th className="text-center">Units</th>
                      <th className="text-center">Lab Unit Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {subjects &&
                      subjects.map((subject, key) => {
                        return (
                          <tr key={key}>
                            <td>{subject.course_code}</td>
                            <td>{subject.description}</td>
                            <td className="text-center">{subject.unit}</td>
                            <td className="text-center">
                              {subject.lab_unit_price
                                ? subject.lab_unit_price
                                : "-"}
                            </td>
                          </tr>
                        );
                      })}
                    <tr>
                      <td colSpan="2">Total Units</td>
                      <td colSpan="2" className="text-center">
                        <strong>{totalUnit}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">Additional Fee</td>
                      <td colSpan="2" className="text-center">
                        <strong>{totalLabPrice}</strong>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </>
            ) : selectedEnrolleeType == "Manually select subjects" ||
              (subjectForm.enrollee_type &&
                subjectForm.enrollee_type.label ==
                  "Manually select subjects") ? (
              <>
                <Row>
                  {subjectError ? (
                    <Col md="12" xs="12">
                      <Alert color="danger">Please select subjects</Alert>
                    </Col>
                  ) : (
                    ""
                  )}
                  <Col md="12" xs="12">
                    <FormGroup>
                      <Label
                        htmlFor="coursesSelection"
                        className="text-left w-100 text-danger-edit font-weight-bolder"
                      >
                        Select Course
                      </Label>
                      <Controller
                        control={control}
                        name="coursesSelection"
                        defaultValue={selectedCourse}
                        rules={{ required: false }}
                        invalid={errors.coursesSelection ? true : false}
                        render={({ onChange, value, onBlur, name }) => (
                          <Select
                            options={selectOptions.collegeSubjSelection}
                            onChange={(e) => {
                              setValue("coursesSelection", e);
                              setSelectedCourse(e);
                              return e;
                            }}
                            defaultValue={selectedCourse}
                            name="coursesSelection"
                          />
                        )}
                      />
                      {errors.coursesSelection &&
                        errors.coursesSelection.type == "required" && (
                          <small className="text-danger-edit">
                            Course is required!
                          </small>
                        )}
                    </FormGroup>
                  </Col>
                  <Col md="12" xs="12">
                    <FormGroup>
                      <Label
                        htmlFor="allSubjects"
                        className="text-left w-100 text-danger-edit font-weight-bolder"
                      >
                        Select Subjects
                      </Label>
                      <Controller
                        control={control}
                        name="allSubjects"
                        defaultValue=""
                        rules={{ required: false }}
                        invalid={errors.allSubjects ? true : false}
                        render={({ onChange, value, onBlur, name }) => (
                          <Select
                            isOptionDisabled={(option) =>
                              option.disabled == "yes"
                            }
                            options={subjectsPerCourse}
                            onChange={(e) => {
                              setValue("allSubjects", e);
                              handleAddSubject(e);
                              setSubjectError(false);
                              return e;
                            }}
                            defaultValue=""
                            name="allSubjects"
                          />
                        )}
                      />
                      {errors.allSubjects &&
                        errors.allSubjects.type == "required" && (
                          <small className="text-danger-edit">
                            Subjects is required!
                          </small>
                        )}
                    </FormGroup>
                  </Col>
                  <Col md="12" xs="12" style={{ overflowX: "auto" }}>
                    <Table bordered striped>
                      <thead>
                        <tr>
                          <th>Course Code</th>
                          <th>Subjects</th>
                          <th className="text-center">Units</th>
                          <th className="text-center">Lab Unit</th>
                          <th className="text-center">Remove</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedSubjects &&
                          selectedSubjects.map((selectedSubject, key) => {
                            return (
                              <tr key={key}>
                                <td>{selectedSubject.course_code}</td>
                                <td>{selectedSubject.description}</td>
                                <td className="text-center">
                                  {selectedSubject.unit}
                                </td>
                                <td className="text-center">
                                  {selectedSubject.lab_unit_price
                                    ? selectedSubject.lab_unit_price
                                    : "-"}
                                </td>
                                <td className="text-center">
                                  <Button
                                    className="btn btn-danger"
                                    onClick={() => handleRemove(key)}
                                  >
                                    <strong>x</strong>
                                  </Button>
                                </td>
                              </tr>
                            );
                          })}
                        <tr>
                          <td colSpan="2">Total Unit</td>
                          <td colSpan="3" className="text-center">
                            <strong>
                              {selectedSubjects.length >= 1
                                ? totalIrregularUnit
                                : 0}
                            </strong>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="2">Lab Fee</td>
                          <td colSpan="3" className="text-center">
                            <strong>
                              {selectedSubjects.length >= 1
                                ? totalIrregularLabPrice
                                : 0}
                            </strong>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </>
            ) : (
              ""
            )}
            <Row className="mt-5 justify-content-center text-center">
              <Col md="6" xs="12" className="text-center w-100">
                <Button
                  type="button"
                  onClick={handlePreviousButton}
                  className="mb-2 bg-white border-warning text-warning-edit rounded-pill py-3 px-5 font-weight-bolder"
                >
                  Previous
                </Button>
                <Button
                  onClick={handleCancel}
                  className="bg-white border-0 text-dark rounded-pill py-3 px-5 mb-2 font-weight-bolder"
                >
                  Cancel
                </Button>
              </Col>

              <Col md="6" xs="12" className="text-center w-100">
                <Button className="bg-warning text-white rounded-pill border-0 py-3 px-5 mb-2 font-weight-bolder">
                  Next
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Form>
    </Container>
  );
};

export default Subjects;
