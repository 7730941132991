import React, { useEffect, useRef, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import overlayFactory from "react-bootstrap-table2-overlay";
import {
  Button,
  Col,
  Container,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  Input,
  UncontrolledTooltip,
  Row,
  Form,
  Label,
} from "reactstrap";
import { applicationActions } from "../../../services/ApplicationServices";
import { useForm, Controller } from "react-hook-form";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { isBrowser, isDesktop } from "react-device-detect";
import View from "../../../assets/img/view.png";
import File from "../../../assets/img/UPLOAD.png";
import Receipt from "../../../assets/img/x.svg.png";
import Trash from "../../../assets/img/trash.png";
import MyApplicationView from "./MyApplicationView";
import MyUploadedFiles from "./MyUploadedFiles";
import CancelApplication from "./CancelApplication";
import StudentBillingModal from "./StudentBillingModal";
import PaymentModeModal from "./PaymentModeModal";
import states from "../States/States";
import optionList from "./optionList";
import { educationActions } from "../../../services/EducationSelectionService";
import StepperComponent from "./StepperComponent";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Select from "react-select";

const MyApplication = () => {
  const [data, setData] = useState([]);
  const [totalSize, setTotalSize] = useState(0);
  const [sortFieldQuery, setSortFieldQuery] = useState("id");
  const [sortOrderQuery, setSortOrderQuery] = useState("desc");
  const [sizePerPageQuery, setSizePerPageQuery] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [page1, setPage1] = useState(1);
  const [loading, setLoading] = useState(false);
  const [updateTable, setUpdateTable] = useState(false);
  const { register, handleSubmit, errors, control, setValue, getValues } =
    useForm();
  const navigate = useNavigate();
  const role = localStorage.getItem("role");
  const [scholarshipForm, setScholarShipForm] = useState(
    states.blankScholarshipForm
  );
  const [basicForm, setBasicForm] = useState(states.blankBasicEducationForm);
  const [tesdaForm, setTesdaForm] = useState(states.blankTesdaForm);
  const [paymentForm, setPaymentForm] = useState(states.blankPaymentForm);
  const [subjectForm, setSubjectForm] = useState(states.blankSubjectForm);
  const [newTuitionFee, setNewTuitionFee] = useState("");
  const [schoolFees, setSchoolFees] = useState([]);
  const [escDiscounts, setEscDiscounts] = useState([]);
  const [currentPage, setCurrentPage] = useState("");
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [educationTypes, setEducationTypes] = useState([]);
  const [gradeLevels, setGradeLevels] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [educationSelected, setEducationSelected] = useState(false);
  const [selectedEducationType, setSelectedEducationType] = useState("");
  const [currentStep, setCurrentStep] = useState(0);
  const [tesdaEducationSelected, setTesdaEducationSelected] = useState(false);
  const [tertiaryTotalTuition, setTertiaryTotalTuition] = useState(false);
  const [allSubjects, setAllSubjects] = useState([]);
  const [courses, setCourses] = useState([]);
  const [gradeLevelFilter, setGradeLevelFilter] = useState("");
  const [gradeLevelSelection, setGradeLevelSelection] = useState([]);
  const [coursesSelection, setCoursesSelection] = useState([]);
  const withSemester = [
    "Grade 11",
    "Grade 12",
    "First year",
    "Second year",
    "Third year",
    "Fourth year",
  ];
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [gradeTypes, setGradeTypes] = useState([]);

  const [selectOptions, setSelectOptions] = useState({
    education_types: [],
    student_types: [],
    grade_types: [],
    grade_levels: [],
    gradeLevelSelection: [],
    coursesSelection: [],
    collegeSubjSelection: [],
    subjects: [],
    allSubjects: [],
    collegeCourses: [],
    enrollee_type: [
      { value: 0, label: "Regular Student" },
      { value: 1, label: "Manually select subjects" },
    ],
    payment_mode: [
      { value: 1, label: "Annual" },
      { value: 2, label: "Semestral" },
      { value: 3, label: "Monthly A" },
      { value: 4, label: "Monthly B" },
    ],
    payment_mode_tertiary: [
      { value: 1, label: "Full Payment" },
      { value: 3, label: "Monthly A" },
      { value: 4, label: "Monthly B" },
    ],
    student_status: [
      { value: "pending", label: "Pending" },
      { value: "certified", label: "Certified" },
      { value: "enrolled", label: "Enrolled" },
    ],
  });

  const [filters, setFilters] = useState({
    syFrom: "",
    syTo: "",
    student_status: "",
    gradeLevel: "",
    semester: "",
    course: "",
  });

  useEffect(() => {
    educationActions.getApplications(
      setEducationTypes,
      null,
      setGradeTypes,
      setGradeLevels,
      setSchoolFees,
      setCourses,
      setAllSubjects,
      setEscDiscounts
    );
  }, []);

  useEffect(() => {
    if (gradeLevels.length >= 1) {
      let level = gradeLevels.map((grade) => {
        return { value: grade.id, label: grade.name };
      });
      setGradeLevelSelection(level);
    }
  }, [gradeLevels]);

  useEffect(() => {
    if (courses.length >= 1) {
      let c = courses.map((course) => {
        return { value: course.id, label: course.name };
      });
      setCoursesSelection(c);
    }
  }, [courses]);

  useEffect(() => {
    if (data.length) {
      setLoading(true);
      let newFilters = {
        sy_from: moment(filters.syFrom).format("YYYY"),
        sy_to: moment(filters.syTo).format("YYYY"),
        student_status: filters.student_status,
        gradeLevel: filters.gradeLevel,
        semester: filters.semester,
        course: filters.course,
      };
      applicationActions.getApplications(
        setData,
        setLoading,
        setTotalSize,
        page1,
        sizePerPageQuery,
        searchText,
        newFilters,
        setButtonSpinner
      );
    }
  }, [page1, sizePerPageQuery, updateTable]);

  const NoDataIndication = () => <div>No Data</div>;

  const [filterData, setFilterData] = useState({
    date_from: null,
    date_to: null,
  });

  const getOptionLabelByValue = (options, value) => {
    const option = options.find((option) => option.value == value);
    return option ? option.label : "";
  };

  const toggleRefNo = (id) => {
    applicationActions.getApplication(
      id,
      navigate,
      setBasicForm,
      setScholarShipForm,
      setTesdaForm,
      setCurrentPage,
      setCurrentStep,
      setEducationSelected,
      setSelectedEducationType,
      selectOptions,
      getOptionLabelByValue,
      paymentForm,
      setPaymentForm,
      setSubjectForm,
      setSubjects,
      setSelectedSubjects,
      schoolFees
    );
  };

  const handleCancel = () => {
    setEducationSelected(false);
    setTesdaEducationSelected(false);
    window.location.reload(500);
  };

  const toggleCancelButton = (cell) => {
    // console.log(cell);
  };

  const columns = [
    {
      dataField: "ref_no",
      text: "Reference No.",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
      formatter: (cell, row) => {
        return (
          <>
            {row.status == "pending" ? (
              <a
                className="ref_no"
                style={{ color: "#800000", cursor: "pointer" }}
                onClick={() => toggleRefNo(row.id)}
              >
                {cell}
              </a>
            ) : (
              cell
            )}
          </>
        );
      },
    },
    {
      dataField: "last_name",
      text: "Last Name",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "first_name",
      text: "First Name",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "grade_level",
      text: "Grade Level",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "created_at",
      text: "Date of Application",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "course",
      text: "Course",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "status",
      text: "Status",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
      formatter: (cell) => {
        return cell.toUpperCase();
      },
    },
    {
      dataField: "semester",
      text: "Semester",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "remarks",
      text: "Remarks",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
      formatter: (cell) => {
        return cell ? cell : "-";
      },
    },
    {
      dataField: "id",
      text: "Actions",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "nowrap",
      },
      formatter: (cell, row) => {
        let status = "";
        if (data.length >= 1) {
          data.map((d) => {
            if (d.id == cell) {
              status = d.status;
            }
          });
        }
        return (
          <div className="px-2 text-center" style={{ minWidth: "50px" }}>
            {(role == "Assesor" ||
              role == "Registrar" ||
              role == "Administrator") &&
            row.status == "certified" ? (
              <>
                <Button
                  id={"cancel-btn" + row.id}
                  onClick={() => toggleCancel(cell, row.status)}
                  className="btn-danger mr-2"
                >
                  <img
                    src={Receipt}
                    style={{
                      height: isDesktop ? "1.5rem" : "1.1rem",
                      objectFit: "fill",
                    }}
                  />
                </Button>
                <UncontrolledTooltip
                  placement="top"
                  target={"cancel-btn" + row.id}
                >
                  Return to Pending
                </UncontrolledTooltip>
              </>
            ) : (
              ""
            )}
            {(role == "Guest" ||
              role == "Student" ||
              role == "Assesor" ||
              role == "Administrator") &&
            row.status == "pending" ? (
              <>
                <Button
                  id={"delete-btn" + row.id}
                  onClick={() => toggleCancel(cell, row.status)}
                  className="btn-danger mr-2"
                >
                  <img
                    src={Trash}
                    style={{
                      height: isDesktop ? "1.5rem" : "1.1rem",
                      objectFit: "fill",
                    }}
                  />
                </Button>
                <UncontrolledTooltip
                  placement="top"
                  target={"delete-btn" + row.id}
                >
                  Cancel application
                </UncontrolledTooltip>
              </>
            ) : (
              ""
            )}
            {status != "pending" ? (
              <>
                <Button
                  id={"view-btn" + row.id}
                  onClick={() => toggleViewApplication(cell)}
                  className="btn-warning mr-2"
                >
                  <img
                    src={View}
                    style={{
                      height: isDesktop ? "1.5rem" : "1.1rem",
                      objectFit: "fill",
                    }}
                  />
                </Button>
                <UncontrolledTooltip
                  placement="top"
                  target={"view-btn" + row.id}
                >
                  View application
                </UncontrolledTooltip>
              </>
            ) : (
              ""
            )}

            {(role == "Guest" ||
              role == "Student" ||
              role == "Administrator") &&
            row.status == "enrolled" ? (
              <>
                <Button
                  id={"proof-btn" + row.id}
                  onClick={() => toggleUploadProofOfPayment(row.ref_no)}
                  className="btn-warning mr-2"
                >
                  <img
                    src={File}
                    style={{
                      height: isDesktop ? "1.5rem" : "1.1rem",
                      objectFit: "fill",
                    }}
                  />
                </Button>
                <UncontrolledTooltip
                  placement="top"
                  target={"proof-btn" + row.id}
                >
                  Upload Payment
                </UncontrolledTooltip>
              </>
            ) : (
              ""
            )}

            {role == "Assesor" ||
            role == "Registrar" ||
            role == "Administrator" ? (
              <>
                <Button
                  id={"upload-file-btn" + row.id}
                  onClick={() => toggleUploadFile(cell)}
                  className="btn-warning"
                >
                  <img
                    src={File}
                    style={{
                      height: isDesktop ? "1.5rem" : "1.1rem",
                      objectFit: "fill",
                    }}
                  />
                </Button>
                <UncontrolledTooltip
                  placement="top"
                  target={"upload-file-btn" + row.id}
                >
                  Upload Requirements
                </UncontrolledTooltip>
              </>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
  ];

  const [tooltipViewOpen, setTooltipViewOpen] = useState(false);
  const toggleViewToolTip = () => {
    setTooltipViewOpen(!tooltipViewOpen);
  };

  const [viewMyApplication, setViewMyApplication] = useState(false);
  const [myUploadedFileOpen, setMyUploadedFileOpen] = useState(false);
  const [myBasicForm, setMyBasicForm] = useState({});
  const [myScholarShipForm, setMyScholarShipForm] = useState({});
  const [myTesdaForm, setMyTesdaForm] = useState({});
  const [myEducationSelected, setMyEducationSelected] = useState({});
  const [mySelectedEducationType, setMySelectedEducationType] = useState({});
  const [myPaymentForm, setMyPaymentForm] = useState({});
  const [mySubjectForm, setMySubjectForm] = useState({});
  const [mySubjects, setMySubjects] = useState({});
  const [mySelectedSubjects, setMySelectedSubjects] = useState({});
  const [mySchoolFees, setMySchoolFees] = useState({});
  const toggleViewApplication = (id) => {
    if (!viewMyApplication) {
      applicationActions.getMyApplication(
        id,
        setMyBasicForm,
        setMyScholarShipForm,
        setMyTesdaForm,
        setMyEducationSelected,
        setMySelectedEducationType,
        selectOptions,
        getOptionLabelByValue,
        myPaymentForm,
        setMyPaymentForm,
        setMySubjectForm,
        setMySubjects,
        setMySelectedSubjects,
        mySchoolFees,
        setViewMyApplication,
        setMyUploadedFileOpen,
        "viewMyApplication"
      );
    } else {
      setViewMyApplication(!viewMyApplication);
    }
  };

  const toggleUploadFile = (id) => {
    if (!myUploadedFileOpen) {
      applicationActions.getMyApplication(
        id,
        setMyBasicForm,
        setMyScholarShipForm,
        setMyTesdaForm,
        setMyEducationSelected,
        setMySelectedEducationType,
        selectOptions,
        getOptionLabelByValue,
        myPaymentForm,
        setMyPaymentForm,
        setMySubjectForm,
        setMySubjects,
        setMySelectedSubjects,
        mySchoolFees,
        setViewMyApplication,
        setMyUploadedFileOpen,
        "viewMyUploadedFiles"
      );
    } else {
      setMyUploadedFileOpen(!myUploadedFileOpen);
    }
  };

  const [studentPaymentModal, setStudentPaymentModal] = useState(false);
  const [billings, setBillings] = useState([]);
  const blankPaymentApplication = {
    scholarship: "",
    approve_esc: "",
    esc_type: "",
  };
  const [paymentApplication, setPaymentApplication] = useState(
    blankPaymentApplication
  );

  const toggleUploadProofOfPayment = (ref_no) => {
    if (!studentPaymentModal) {
      let body = {
        ref_no: ref_no,
      };
      applicationActions.getMyBilling(
        setStudentPaymentModal,
        body,
        setPaymentApplication,
        setBillings
      );
    } else {
      setStudentPaymentModal(!studentPaymentModal);
    }
  };

  const [cancelApplication, setCancelApplication] = useState(false);
  const [cancellationId, setCancellationId] = useState(false);
  const [cancellationStatus, setCancellationStatus] = useState(false);

  const toggleCancel = (id, status) => {
    setCancellationId("");
    setCancellationStatus("");
    if (!cancelApplication) {
      setCancellationId(id);
      setCancellationStatus(status);
      setCancelApplication(!cancelApplication);
    } else {
      setCancelApplication(!cancelApplication);
    }
  };

  const changeQuery = (e) => {
    setSearchText(e.target.value);
  };

  const [paymentOptionModal, setPaymentOptionModal] = useState(false);
  const togglePaymentOptions = () => {
    if (!paymentOptionModal) {
      setPaymentOptionModal(!paymentOptionModal);
    } else {
      setPaymentOptionModal(!paymentOptionModal);
    }
  };

  const RemoteAll = ({
    loading,
    data,
    page,
    sizePerPage,
    onTableChange,
    totalSize,
  }) => (
    <div>
      <BootstrapTable
        wrapperClasses="table-responsive text-center"
        bootstrap4
        striped
        remote
        loading={loading}
        keyField="id"
        data={data}
        columns={columns}
        defaultSorted={defaultSorted}
        filter={filterFactory()}
        pagination={paginationFactory({ page, sizePerPage, totalSize })}
        onTableChange={onTableChange}
        overlay={overlayFactory({
          spinner: true,
          styles: {
            overlay: (base) => ({
              ...base,
              background: "rgba(0, 0, 0, 0.5)",
            }),
          },
        })}
        noDataIndication={() => <NoDataIndication />}
      />
    </div>
  );

  // DATA TABLE
  const handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder }
  ) => {
    if (page !== page1) {
      setPage1(page);

      setSortFieldQuery(sortField ? sortField : sortFieldQuery);
      setSortOrderQuery(sortOrder ? sortOrder : sortOrderQuery);
      setLoading(true);
    }

    if (
      (sortField != sortFieldQuery && sortField != undefined) ||
      (sortOrder != sortOrderQuery && sortOrder != undefined)
    ) {
      setSortFieldQuery(sortField ? sortField : sortFieldQuery);
      setSortOrderQuery(sortOrder ? sortOrder : sortOrderQuery);
      setLoading(true);
    }

    if (sizePerPage != sizePerPageQuery) {
      setSizePerPageQuery(sizePerPage);
      setLoading(true);
    }
  };

  const defaultSorted = [
    {
      dataField: sortFieldQuery,
      order: sortOrderQuery,
    },
  ];

  const onSubmit = (data) => {
    setButtonSpinner(true);
    setLoading(true);
    let newFilters = {
      sy_from: moment(data.sy_from).format("YYYY"),
      sy_to: moment(data.sy_to).format("YYYY"),
      student_status: data.student_status ? data.student_status.value : "",
      gradeLevel: filters.gradeLevel,
      semester: filters.semester,
      course: filters.course,
    };
    applicationActions.getApplications(
      setData,
      setLoading,
      setTotalSize,
      page1,
      sizePerPageQuery,
      searchText,
      newFilters,
      setButtonSpinner
    );
  };

  return (
    <div className="Admission p-4">
      {currentPage == "admission" || currentPage == "viewApplication" ? (
        <StepperComponent
          educationSelected={educationSelected}
          selectedEducationType={selectedEducationType}
          handleCancel={handleCancel}
          setEducationSelected={setEducationSelected}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          setLoading={setLoading}
          basicForm={basicForm}
          setBasicForm={setBasicForm}
          paymentForm={paymentForm}
          setPaymentForm={setPaymentForm}
          escDiscounts={escDiscounts}
          scholarshipForm={scholarshipForm}
          setScholarShipForm={setScholarShipForm}
          newTuitionFee={newTuitionFee}
          setSchoolFees={setSchoolFees}
          selectOptions={selectOptions}
          schoolFees={schoolFees}
          loading={loading}
          educationTypes={educationTypes}
          subjects={subjects}
          subjectForm={subjectForm}
          setSubjectForm={setSubjectForm}
          selectedSubjects={selectedSubjects}
          setSelectedSubjects={setSelectedSubjects}
          setSubjects={setSubjects}
          setTertiaryTotalTuition={setTertiaryTotalTuition}
          allSubjects={allSubjects}
          setTesdaEducationSelected={setTesdaEducationSelected}
          tesdaForm={tesdaForm}
          setTesdaForm={setTesdaForm}
          currentPage={currentPage}
          gradeTypes={gradeTypes}
        />
      ) : (
        <>
          {viewMyApplication ? (
            <MyApplicationView
              viewMyApplication={viewMyApplication}
              setViewApplication={setViewMyApplication}
              toggleViewApplication={toggleViewApplication}
              basicForm={myBasicForm}
              setBasicForm={setMyBasicForm}
              scholarshipForm={myScholarShipForm}
              tesdaForm={myTesdaForm}
              paymentForm={myPaymentForm}
            />
          ) : (
            ""
          )}
          {myUploadedFileOpen ? (
            <MyUploadedFiles
              myUploadedFileOpen={myUploadedFileOpen}
              toggleUploadFile={toggleUploadFile}
              basicForm={myBasicForm}
              setBasicForm={setMyBasicForm}
              tesdaForm={myTesdaForm}
              setTesdaForm={setMyTesdaForm}
              scholarshipForm={myScholarShipForm}
            />
          ) : (
            ""
          )}
          {cancelApplication ? (
            <CancelApplication
              id={cancellationId}
              cancelApplication={cancelApplication}
              setCancelApplication={setCancelApplication}
              toggleCancel={toggleCancel}
              setUpdateTable={setUpdateTable}
              updateTable={updateTable}
              status={cancellationStatus}
            />
          ) : (
            ""
          )}
          {studentPaymentModal ? (
            <StudentBillingModal
              paymentApplication={paymentApplication}
              billings={billings}
              setBillings={setBillings}
              toggleUploadProofOfPayment={toggleUploadProofOfPayment}
              studentPaymentModal={studentPaymentModal}
            />
          ) : (
            ""
          )}
          {paymentOptionModal ? (
            <PaymentModeModal
              paymentOptionModal={paymentOptionModal}
              togglePaymentOptions={togglePaymentOptions}
            />
          ) : (
            ""
          )}
          <FormGroup row>
            {role == "Guest" || role == "Student" || role == "Administrator" ? (
              <Col md="4" xs="12">
                <Button
                  className="btn btn-secondary mb-2"
                  onClick={togglePaymentOptions}
                >
                  Payment Options
                </Button>
              </Col>
            ) : (
              ""
            )}
          </FormGroup>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md="2" xs="12">
                <FormGroup>
                  <Label>
                    <small className="font-weight-bolder">S.Y. From</small>
                  </Label>
                  <Controller
                    control={control}
                    name="sy_from"
                    defaultValue=""
                    rules={{ required: true }}
                    invalid={errors.sy_from ? true : false}
                    render={({ onChange, value, onBlur, name }) => (
                      <DatePicker
                        className="form-control bg-light"
                        selected={
                          moment(filters.syFrom).isValid()
                            ? moment(filters.syFrom).toDate()
                            : filters.syFrom
                        }
                        onChange={(date) => {
                          // setStartDate(date);
                          setValue("sy_from", date);
                          setFilters({ ...filters, syFrom: date });
                          return date;
                        }}
                        showYearPicker
                        dateFormat="yyyy"
                        yearItemNumber={10}
                        name="sy_from"
                        defaultValue=""
                      />
                    )}
                  />
                  {errors.sy_from && errors.sy_from.type == "required" && (
                    <div
                      style={{
                        marginTop: "0.25rem",
                        fontSize: "80%",
                        color: "#f86c6b",
                      }}
                    >
                      Schoo Year From is required!
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col md="2" xs="12">
                <FormGroup>
                  <Label>
                    <small className="font-weight-bolder">S.Y. To</small>
                  </Label>
                  <Controller
                    control={control}
                    name="sy_to"
                    defaultValue=""
                    rules={{
                      required: true,
                      validate: (value) => value > getValues("sy_from"),
                    }}
                    invalid={errors.sy_to ? true : false}
                    render={({ onChange, value, onBlur, name }) => (
                      <DatePicker
                        className="form-control bg-light"
                        selected={
                          moment(filters.syTo).isValid()
                            ? moment(filters.syTo).toDate()
                            : filters.syTo
                        }
                        onChange={(date) => {
                          setValue("sy_to", date);
                          setFilters({ ...filters, syTo: date });
                          return date;
                        }}
                        showYearPicker
                        dateFormat="yyyy"
                        yearItemNumber={10}
                        name="sy_to"
                        defaultValue=""
                      />
                    )}
                  />
                  {errors.sy_to && errors.sy_to.type == "required" && (
                    <div
                      style={{
                        marginTop: "0.25rem",
                        fontSize: "80%",
                        color: "#f86c6b",
                      }}
                    >
                      School Year To is required!
                    </div>
                  )}
                  {errors.sy_to && errors.sy_to.type == "validate" && (
                    <div
                      style={{
                        marginTop: "0.25rem",
                        fontSize: "80%",
                        color: "#f86c6b",
                      }}
                    >
                      School Year To cannot be lower or equal to S.Y. From!
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col md="2" xs="12">
                <FormGroup>
                  <Label>
                    <small className="font-weight-bolder">
                      <i className="fa fa-lightbulb-o"></i>&nbsp;Filter by
                      Student Status
                    </small>
                  </Label>
                  <Controller
                    control={control}
                    name="student_status"
                    rules={{ required: true }}
                    invalid={errors.student_status ? true : false}
                    defaultValue=""
                    render={({ onChange, value, onBlur, name }) => (
                      <Select
                        options={selectOptions.student_status}
                        onChange={(e) => {
                          setValue("student_status", e);
                          setFilters({
                            ...filters,
                            student_status: e.value,
                          });
                          return e;
                        }}
                        defaultValue=""
                      />
                    )}
                  />
                  {errors.student_status && (
                    <div
                      style={{
                        marginTop: "0.25rem",
                        fontSize: "80%",
                        color: "#f86c6b",
                      }}
                    >
                      Please select student status!
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col md="4" xs="12" className="ml-auto mb-2">
                <Label>
                  <small className="font-weight-bolder">
                    <i className="fa fa-lightbulb-o"></i>&nbsp;Search by Last
                    Name
                  </small>
                </Label>
                <InputGroup>
                  <InputGroupAddon addonType="prepend" style={{ zIndex: "0" }}>
                    <Button type="button" color="primary" id="search-button">
                      <i className="fa fa-search"></i>
                    </Button>
                  </InputGroupAddon>
                  <Input
                    type="text"
                    id="search-text"
                    name="search-text"
                    placeholder="Search"
                    onChange={changeQuery}
                  />
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <Col md="2" xs="12" className="mb-2">
                <Label>
                  <small className="font-weight-bolder">
                    <i className="fa fa-lightbulb-o"></i>&nbsp;Filter by Grade
                    Level
                  </small>
                </Label>
                <Controller
                  control={control}
                  name="gradeLevel"
                  rules={{ required: false }}
                  invalid={errors.gradeLevel ? true : false}
                  defaultValue=""
                  render={({ onChange, value, onBlur, name }) => (
                    <Select
                      options={gradeLevelSelection}
                      onChange={(e) => {
                        setValue("semester", "");
                        setValue("course", "");
                        setGradeLevelFilter(e.label);
                        setFilters({
                          ...filters,
                          gradeLevel: e.value,
                          course: "",
                          semester: "",
                        });
                        return e;
                      }}
                      defaultValue=""
                    />
                  )}
                />
              </Col>
              {withSemester.includes(gradeLevelFilter) ? (
                <>
                  <Col md="2" xs="12" className="mb-2">
                    <Label>
                      <small className="font-weight-bolder">
                        <i className="fa fa-lightbulb-o"></i>&nbsp;Filter by
                        Courses
                      </small>
                    </Label>
                    <Controller
                      control={control}
                      name="course"
                      rules={{ required: false }}
                      invalid={errors.course ? true : false}
                      defaultValue={
                        filters.course != "" ? { value: filters.course } : ""
                      }
                      render={({ onChange, value, onBlur, name }) => (
                        <Select
                          options={coursesSelection}
                          onChange={(e) => {
                            setFilters({ ...filters, course: e.value });
                            return e;
                          }}
                          defaultValue={
                            filters.course != ""
                              ? {
                                  value: filters.course,
                                  label: getOptionLabelByValue(
                                    coursesSelection,
                                    filters.course
                                  ),
                                }
                              : ""
                          }
                        />
                      )}
                    />
                  </Col>
                  <Col md="2" xs="12" className="mb-2">
                    <Label>
                      <small className="font-weight-bolder">
                        <i className="fa fa-lightbulb-o"></i>&nbsp;Filter by
                        Semester
                      </small>
                    </Label>
                    <Controller
                      control={control}
                      name="semester"
                      rules={{ required: false }}
                      invalid={errors.semester ? true : false}
                      defaultValue=""
                      render={({ onChange, value, onBlur, name }) => (
                        <Select
                          options={optionList.semester}
                          onChange={(e) => {
                            setFilters({ ...filters, semester: e.value });
                            return e;
                          }}
                          defaultValue=""
                        />
                      )}
                    />
                  </Col>
                </>
              ) : (
                ""
              )}
            </Row>
            <Row className="pb-5 pt-4">
              <Col xs="12" md="12">
                <Button
                  disabled={buttonSpinner}
                  className="bg-danger-edit text-white border-0"
                >
                  Filter
                </Button>
              </Col>
            </Row>
          </Form>
          <RemoteAll
            loading={loading}
            data={data}
            page={page1}
            sizePerPage={sizePerPageQuery}
            totalSize={totalSize}
            onTableChange={handleTableChange}
            register={register}
          />
        </>
      )}
    </div>
  );
};

export default MyApplication;
