import { ENDPOINT, S3_URL } from "../constants/api";
import axios from "axios";
import { notifyError, notifySuccess } from "./Toaster";
import moment from "moment";

export const dynamicRequiermentsActions = {
  saveTemplate,
  getCurrentTemplate,
  getSelectedTemplate,
  publishTemplate,
  getCurrentTemplateFrontPage
  // updateUser
};

const token = localStorage.getItem("jwtToken")
  ? localStorage.getItem("jwtToken")
  : "";
const headers = {
  Authorization: `Bearer ${token}`,
  "Content-Type": "application/json",
  "X-Requested-With": "XMLHttpRequest",
};

async function saveTemplate(data, setLoading, setButtonSpinner, setButtonSpinner2) {
  try {
    let res = await axios.post(ENDPOINT + `/dynamic-admission-requirements`, data, {
      headers: headers,
    });

    notifySuccess("Successfully created new template");
    setLoading(false)
    setButtonSpinner(false)
    setButtonSpinner2(false)
    setTimeout(() => window.location.reload(), 2000);
  } catch (err) {
    if (err.response && err.response.data && err.response.data.error) {
      notifyError(err.response.data.error);
    } else if (err.response) {
      notifyError("Error while saving template");
    }
    setLoading(false)
    setButtonSpinner(false)
    setButtonSpinner2(false)
  }
}

async function getCurrentTemplate(
  basicRequirements,
  setBasicRequirements,
  tertiaryRequirements,
  setTertiaryRequirements,
  technicalRequirements,
  setTechnicalRequirements,
  setCurrentVersion,
  setSelectedVersion,
  setLoading,
  setVersionSelection,
  basic1,
  tertiary1,
  tesda1
) {
  try {
    let res = await axios.get(ENDPOINT + `/dynamic-admission-requirements`, {
      headers: headers,
    });

    const versions = res.data.versions;
    const requirements = res.data.current.requirements;
    setCurrentVersion(res.data.current.version)
    setVersionSelection(versions)
    setSelectedVersion(res.data.current.version)

    setBasicRequirements({
      ...basicRequirements,
        image: requirements.basic.image.includes("static/media/") ? basic1 : requirements.basic.image,
        imageFile: "",
        title: requirements.basic.title,
        subtitle_1: requirements.basic.subtitle_1,
        subtitle_2: requirements.basic.subtitle_2,
        data: requirements.basic.data
    })

    setTertiaryRequirements({
      ...tertiaryRequirements,
        image: requirements.tertiary.image.includes("static/media/") ? tertiary1 : requirements.tertiary.image,
        imageFile: "",
        title: requirements.tertiary.title,
        subtitle_1: requirements.tertiary.subtitle_1,
        subtitle_2: requirements.tertiary.subtitle_2,
        data: requirements.tertiary.data
    })


    setTechnicalRequirements({
      ...technicalRequirements,
        image: requirements.technical.image.includes("static/media/") ? tesda1 : requirements.technical.image,
        imageFile: "",
        title: requirements.technical.title,
        subtitle_1: requirements.technical.subtitle_1,
        subtitle_2: requirements.technical.subtitle_2,
        data: requirements.technical.data
    })


    setLoading(false)
  } catch (err) {
    if (err.response) {
      notifyError("Error while getting latest template.");
    }
    setLoading(false)
  }
}

async function getSelectedTemplate(
  basicRequirements,
  setBasicRequirements,
  tertiaryRequirements,
  setTertiaryRequirements,
  technicalRequirements,
  setTechnicalRequirements,
  setCurrentVersion,
  selectedVersion,
  setLoading,
  setVersionSelection,
  basic1,
  tertiary1,
  tesda1
) {
  try {
    let res = await axios.get(ENDPOINT + `/get-dynamic-admission-requirements/${selectedVersion}`, {
      headers: headers,
    });

    const requirements = res.data.requirements;

    setBasicRequirements({
      ...basicRequirements,
        image: requirements.basic.image.includes("static/media/") ? basic1 : requirements.basic.image,
        imageFile: "",
        title: requirements.basic.title,
        subtitle_1: requirements.basic.subtitle_1,
        subtitle_2: requirements.basic.subtitle_2,
        data: requirements.basic.data
    })

    setTertiaryRequirements({
      ...tertiaryRequirements,
        image: requirements.tertiary.image.includes("static/media/") ? tertiary1 : requirements.tertiary.image,
        imageFile: "",
        title: requirements.tertiary.title,
        subtitle_1: requirements.tertiary.subtitle_1,
        subtitle_2: requirements.tertiary.subtitle_2,
        data: requirements.tertiary.data
    })

    setTechnicalRequirements({
      ...technicalRequirements,
        image: requirements.technical.image.includes("static/media/") ? tesda1 : requirements.technical.image,
        imageFile: "",
        title: requirements.technical.title,
        subtitle_1: requirements.technical.subtitle_1,
        subtitle_2: requirements.technical.subtitle_2,
        data: requirements.technical.data
    })

    setLoading(false)
  } catch (err) {
    if (err.response) {
      notifyError("Error while getting template.");
    }
    setLoading(false)
  }
}

async function publishTemplate(selectedVersion, setButtonSpinner, setLoading, setButtonSpinner2) {
  try {
    let res = await axios.get(ENDPOINT + `/dynamic-admission-requirements-publish-template/${selectedVersion}`, {
      headers: headers,
    });

    setButtonSpinner(false)
    notifySuccess("Successfully published new version");
    setTimeout(() => window.location.reload(), 2000);
  } catch (err) {
    if (err.response) {
      notifyError("Error while publishing new version.");
    }
    setButtonSpinner(false)
  }
}

async function getCurrentTemplateFrontPage(
  basicRequirements,
  setBasicRequirements,
  tertiaryRequirements,
  setTertiaryRequirements,
  technicalRequirements,
  setTechnicalRequirements,
  setCurrentVersion,
  setSelectedVersion,
  setLoading,
  setVersionSelection,
  basic1,
  tertiary1,
  tesda1
) {
  try {
    let res = await axios.get(ENDPOINT + `/dynamic-admission-requirements-frontpage`, {
      headers: headers,
    });

    const versions = res.data.versions;
    const requirements = res.data.current.requirements;
    setCurrentVersion(res.data.current.version)
    setVersionSelection(versions)
    setSelectedVersion(res.data.current.version)

    setBasicRequirements({
      ...basicRequirements,
        image: requirements.basic.image.includes("static/media/") ? basic1 : requirements.basic.image,
        imageFile: "",
        title: requirements.basic.title,
        subtitle_1: requirements.basic.subtitle_1,
        subtitle_2: requirements.basic.subtitle_2,
        data: requirements.basic.data
    })

    setTertiaryRequirements({
      ...tertiaryRequirements,
        image: requirements.tertiary.image.includes("static/media/") ? tertiary1 : requirements.tertiary.image,
        imageFile: "",
        title: requirements.tertiary.title,
        subtitle_1: requirements.tertiary.subtitle_1,
        subtitle_2: requirements.tertiary.subtitle_2,
        data: requirements.tertiary.data
    })

    setTechnicalRequirements({
      ...technicalRequirements,
        image: requirements.technical.image.includes("static/media/") ? tesda1 : requirements.technical.image,
        imageFile: "",
        title: requirements.technical.title,
        subtitle_1: requirements.technical.subtitle_1,
        subtitle_2: requirements.technical.subtitle_2,
        data: requirements.technical.data
    })
    setLoading(false)
  } catch (err) {
    if (err.response) {
      notifyError("Error while getting latest template.");
    }
    setLoading(false)
  }
}
