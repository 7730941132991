import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link, useNavigate } from "react-router-dom";
import SapcLogo from "../assets/img/SAPC-Logo-red.png";
import { sidebarMenu } from "./SidebarMenu";
import { sidebarData } from "./SidebarData";
import { Fragment, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { Button } from "reactstrap";

const SideBar = ({ role, toggled, setToggled }) => {
  const navigate = useNavigate();
  // const toggleHome = () => {
  //   localStorage.removeItem("currentPage");
  //   navigate("/");
  // };
  const email = localStorage.getItem("email");
  const [applicationsPage, setApplicationsPage] = useState([]);

  useEffect(() => {
    const newPages = sidebarData.filter((d) => {
      return d.access.includes(role);
    });
    const newSidebarMenu = sidebarMenu.map((d) => {
      const pages = newPages
        .map((page) => {
          if (d.children.includes(page.title)) {
            return page;
          }
        })
        .filter((v) => v);
      d.children = pages;
      return d;
    });
    setApplicationsPage(newSidebarMenu);
  }, []);

  const toggleLogOut = () => {
    localStorage.clear();
    sessionStorage.clear();
    navigate("/login");
  };

  let styledSidebar = {
    height: "100vh",
    position: "sticky",
    top: 0,
    zIndex: 999,
  };

  if (isMobile) {
    styledSidebar = {
      height: "100vh",
      zIndex: 999,
    };
  }

  return (
    <Sidebar
      style={styledSidebar}
      className="shadow"
      breakPoint="always"
      onBackdropClick={() => setToggled(false)}
      toggled={toggled}
    >
      <Menu className="my-4">
        <div className="text-center">
          <img
            src={SapcLogo}
            style={{ width: "4rem", cursor: "pointer" }}
            alt="Logo"
          />
        </div>
      </Menu>
      <Menu style={{ height: "70vh", overflowY: "auto" }}>
        {applicationsPage.map((menu) => {
          return (
            <Fragment key={menu.title}>
              {menu.children.length > 0 ? (
                <SubMenu label={menu.title}>
                  {menu.children.map((page) => {
                    return (
                      <MenuItem
                        component={<Link to={page.path} />}
                        key={page.title}
                        icon={page.icon}
                      >
                        {page.title}
                      </MenuItem>
                    );
                  })}
                </SubMenu>
              ) : (
                ""
              )}
            </Fragment>
          );
        })}
      </Menu>
      <Menu className="text-center mt-5">
        <p className="mb-1">{role}</p>
        <p className="mb-1">{email}</p>
        <Button onClick={toggleLogOut}>Logout</Button>
      </Menu>
    </Sidebar>
  );
};

export default SideBar;
