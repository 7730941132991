import React, { useState } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
  Container,
  Label,
  FormGroup,
  Form,
  Input,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import classnames from "classnames";
import moment from "moment";
import { useForm, Controller } from "react-hook-form";
import { applicationActions } from "../../../services/ApplicationServices";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

const ScholarshipApproval = ({
  scholarshipForm,
  toggleScholarshipModal,
  scholarshipModal,
  setScholarshipModal,
  scholarshipDetails,
  setScholarshipDetails,
}) => {
  const { register, handleSubmit, errors, control, setValue, getValues } =
    useForm();
  const [buttonSpinner, setButtonSpinner] = useState(false);

  const onSubmit = () => {
    setButtonSpinner(true);
    const type = {
      name: scholarshipDetails.name,
      discount_percentage: scholarshipDetails.discount_percentage,
    };
    const dataSubmit = {
      application_id: scholarshipForm.application_id,
      is_scholar: 1,
      scholarship_type: JSON.stringify(type),
    };

    applicationActions.approveScholarship(
      dataSubmit,
      setButtonSpinner,
      setScholarshipModal
    );
  };

  return (
    <Modal isOpen={scholarshipModal} toggle={toggleScholarshipModal}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader toggle={toggleScholarshipModal}>
          Approve Scholarship
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md="12" xs="12">
              <FormGroup>
                <Label
                  htmlFor="name"
                  className="text-secondary font-weight-bolder"
                >
                  Select Scholarship
                </Label>
                <Controller
                  control={control}
                  name="name"
                  rules={{ required: true }}
                  invalid={errors.name ? true : false}
                  defaultValue=""
                  render={({ onChange, value, onBlur, name }) => (
                    <Select
                      options={scholarshipForm.scholarshipArr}
                      onChange={(e) => {
                        setValue("name", e);
                        setScholarshipDetails({
                          ...scholarshipDetails,
                          name: e.value,
                        });

                        return e;
                      }}
                      defaultValue=""
                    />
                  )}
                />
                {errors.name && (
                  <div
                    style={{
                      marginTop: "0.25rem",
                      fontSize: "80%",
                      color: "#f86c6b",
                    }}
                  >
                    Please select!
                  </div>
                )}
              </FormGroup>
            </Col>
            <Col md="12" xs="12">
              <FormGroup>
                <Label
                  htmlFor="father_age"
                  className="text-secondary font-weight-bolder"
                >
                  Discount Percentage
                </Label>
                <Controller
                  control={control}
                  name="discount_percentage"
                  rules={{ required: true, min: 1 }}
                  invalid={errors.discount_percentage ? true : false}
                  defaultValue=""
                  render={({ onChange, value, onBlur, name }) => (
                    <Input
                      type="number"
                      className="form-control bg-light"
                      name="discount_percentage"
                      onChange={(event) => {
                        setScholarshipDetails({
                          ...scholarshipDetails,
                          discount_percentage: event.target.value,
                        });
                        setValue("discount_percentage", event.target.value);
                        return event.target.value;
                      }}
                      defaultValue=""
                    />
                  )}
                />
                {errors.discount_percentage &&
                  errors.discount_percentage.type == "required" && (
                    <div
                      style={{
                        marginTop: "0.25rem",
                        fontSize: "80%",
                        color: "#f86c6b",
                      }}
                    >
                      Percentage is required!
                    </div>
                  )}
                {errors.discount_percentage &&
                  errors.discount_percentage.type == "min" && (
                    <div
                      style={{
                        marginTop: "0.25rem",
                        fontSize: "80%",
                        color: "#f86c6b",
                      }}
                    >
                      Invalid percentage!
                    </div>
                  )}
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" disabled={buttonSpinner ? true : false}>
            {buttonSpinner ? (
              <>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp;Processing...
              </>
            ) : (
              "Submit"
            )}
          </Button>
          <Button color="secondary" onClick={toggleScholarshipModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default ScholarshipApproval;
