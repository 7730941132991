import { ENDPOINT, S3_URL } from "../constants/api";
import axios from "axios";
import { notifyError, notifySuccess } from "./Toaster";
import moment from "moment";

export const dynamicFooterActions = {
  saveTemplate,
  getCurrentTemplate,
  getSelectedTemplate,
  publishTemplate,
  getCurrentTemplateFrontPage
  // updateUser
};

const token = localStorage.getItem("jwtToken")
  ? localStorage.getItem("jwtToken")
  : "";
const headers = {
  Authorization: `Bearer ${token}`,
  "Content-Type": "application/json",
  "X-Requested-With": "XMLHttpRequest",
};

async function saveTemplate(data, setLoading, setButtonSpinner, setButtonSpinner2) {
  try {
    let res = await axios.post(ENDPOINT + `/dynamic-footer`, data, {
      headers: headers,
    });

    notifySuccess("Successfully created new template");
    setLoading(false)
    setButtonSpinner(false)
    setButtonSpinner2(false)
    setTimeout(() => window.location.reload(), 2000);
  } catch (err) {
    if (err.response && err.response.data && err.response.data.error) {
      notifyError(err.response.data.error);
    } else if (err.response) {
      notifyError("Error while saving template");
    }
    setLoading(false)
    setButtonSpinner(false)
    setButtonSpinner2(false)
  }
}

async function getCurrentTemplate(
  setFooter,
  footer,
  setCurrentVersion,
  setSelectedVersion,
  setLoading,
  setVersionSelection,
) {
  try {
    let res = await axios.get(ENDPOINT + `/dynamic-footer`, {
      headers: headers,
    });

    const versions = res.data.versions;
    setCurrentVersion(res.data.current.version)
    setVersionSelection(versions)
    setSelectedVersion(res.data.current.version)
    setFooter(res.data.current.footer)

    setLoading(false)
  } catch (err) {
    if (err.response) {
      notifyError("Error while getting latest template.");
    }
    setLoading(false)
  }
}

async function getSelectedTemplate(
  setFooter,
  footer,
  setCurrentVersion,
  selectedVersion,
  setLoading,
  setVersionSelection,
) {
  try {
    let res = await axios.get(ENDPOINT + `/get-dynamic-footer/${selectedVersion}`, {
      headers: headers,
    });

    setFooter(res.data.footer)

    setLoading(false)
  } catch (err) {
    if (err.response) {
      notifyError("Error while getting template.");
    }
    setLoading(false)
  }
}

async function publishTemplate(selectedVersion, setButtonSpinner, setLoading, setButtonSpinner2) {
  try {
    let res = await axios.get(ENDPOINT + `/dynamic-footer-publish-template/${selectedVersion}`, {
      headers: headers,
    });

    setButtonSpinner(false)
    notifySuccess("Successfully published new version");
    setTimeout(() => window.location.reload(), 2000);
  } catch (err) {
    if (err.response) {
      notifyError("Error while publishing new version.");
    }
    setButtonSpinner(false)
  }
}

async function getCurrentTemplateFrontPage(
  setFooter,
  footer,
  setLoading,
) {
  try {
    let res = await axios.get(ENDPOINT + `/dynamic-footer-frontpage`, {
      headers: headers,
    });

    setFooter(res.data.current.footer)
    setLoading(false)
  } catch (err) {
    if (err.response) {
      notifyError("Error while getting latest template.");
    }
    setLoading(false)
  }
}
