import React, { useEffect, useState } from "react";
import { adminActions } from "../../../services/GradeLevelServices";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import overlayFactory from "react-bootstrap-table2-overlay";
import { isBrowser, isDesktop, isMobile } from "react-device-detect";
import Edit from "../../../assets/img/edit.png";
import Add from "../../../assets/img/ADMISSION.png";
import {
  Button,
  Col,
  Container,
  InputGroup,
  InputGroupAddon,
  Row,
  Input,
  UncontrolledTooltip,
} from "reactstrap";
import ModalEditor from "./GradeLevelModal";
import Select from "react-select";
import { educationActions } from "../../../services/EducationSelectionService";
import states from "../States/States";

const NoDataIndication = () => <div>No Data</div>;

const GradeLevel = () => {
  const [data, setData] = useState([]);
  const [educationTypes, setEducationTypes] = useState([]);
  const [gradeLevels, setGradeLevels] = useState([]);
  const [gradeTypes, setGradeTypes] = useState([]);
  const [updateTable, setUpdateTable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [gradeTypeFilter, setGradeTypeFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [educationId, setEducationId] = useState([]);
  const [selectEducationOptions, setSelectEducationOptions] = useState({
    education_type: [],
  });
  const [selectGradeTypeOptions, setSelectGradeTypeOptions] = useState({
    grade_type: [],
  });
  const [statusSelection, setStatusSelection] = useState([
    { value: 0, label: "InActive" },
    { value: 1, label: "Active" },
  ]);

  useEffect(() => {
    educationActions.getApplications(
      setEducationTypes,
      null,
      setGradeTypes,
      setGradeLevels,
      null,
      null,
      null,
      null
    );
  }, []);

  useEffect(() => {
    setLoading(true);
    adminActions.getGradeLevel(
      setData,
      setLoading,
      educationTypes,
      setEducationId
    );
  }, [updateTable]);

  useEffect(() => {
    // adminActions.getEducationType(selectEducationOptions, setSelectEducationOptions)
    let educTypeArr = educationTypes.map((type) => {
      return {
        value: type.id,
        label: type.name,
      };
    });
    setSelectEducationOptions({
      ...selectEducationOptions,
      education_type: educTypeArr,
    });
  }, [educationTypes]);

  useEffect(() => {
    // adminActions.getGradeType(selectGradeTypeOptions, setSelectGradeTypeOptions)
    let gradeTypesArr = gradeTypes.map((type) => {
      return {
        value: type.id,
        label: type.name,
        education_type: type.education_type_id.id,
      };
    });
    setSelectGradeTypeOptions({
      ...selectGradeTypeOptions,
      grade_type: gradeTypesArr,
    });
  }, [gradeTypes]);

  // console.log(selectEducationOptions)

  const columns = [
    {
      dataField: "education_type_id",
      text: "Education Type",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
      formatter: (row) => {
        if (educationTypes.length) {
          let educationType = educationTypes.filter((level) => {
            return level.id == row;
          });
          return educationType[0].name;
        }
      },
    },
    {
      dataField: "grade_type_name",
      text: "Grade Type",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "name",
      text: "Grade Level",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "enabled",
      text: "Status",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
      formatter: (cell) => {
        return cell ? "Active" : "InActive";
      },
    },
    {
      dataField: "id",
      text: "Action",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
      formatter: (cell, row) => {
        return (
          <>
            <Button
              className="btn btn-warning"
              onClick={() => handleEditGradeLevel(cell, row, "edit")}
            >
              <img src={Edit} style={{ height: "1rem" }} />
            </Button>
          </>
        );
      },
    },
  ];

  const [modal, setModal] = useState(false);
  const [editGradeLevel, setEditGradeLevel] = useState(states.blankGradeLevel);
  const [action, setAction] = useState("");
  const [gradeLevelId, setGradeLevelId] = useState("");

  // console.log(editGradeLevel)

  const handleEditGradeLevel = (cell, row, action) => {
    setEditGradeLevel(states.blankGradeLevel);
    setAction("");

    if (!modal) {
      setAction(action);
      setModal(!modal);
      setGradeLevelId(cell);
      setEditGradeLevel(row);
    } else {
      setModal(!modal);
    }
  };

  const handleAddGradeLevel = (action) => {
    setAction("");
    setEditGradeLevel(states.blankGradeLevel);

    if (!modal) {
      setAction(action);
      setModal(!modal);
    } else {
      setModal(!modal);
    }
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    if (data.length >= 1) {
      //search INPUT
      let filteredGradeLevel = data.filter((d) => {
        return (
          d.name.toLowerCase().includes(searchText.toLowerCase()) ||
          d.education_type.toLowerCase().includes(searchText.toLowerCase()) ||
          d.grade_type_name.toLowerCase().includes(searchText.toLowerCase())
        );
      });

      //filter for role and status
      if (gradeTypeFilter !== "") {
        let filteredGrade = filteredGradeLevel.filter((f) => {
          return f.name.toLowerCase() == gradeTypeFilter.toLowerCase();
        });
        let filteredStatus = filteredGrade.filter((f) => {
          return f.status == statusFilter;
        });
        setFilteredData(filteredStatus);
      } else {
        setFilteredData(filteredGradeLevel);
      }
      // console.log(filteredGradeLevel)
    }
  }, [gradeTypeFilter, statusFilter, searchText, data]);

  // console.log(gradeTypeFilter)

  return (
    <div className="p-4">
      <ModalEditor
        modal={modal}
        setModal={setModal}
        handleEditGradeLevel={handleEditGradeLevel}
        editGradeLevel={editGradeLevel}
        updateTable={updateTable}
        setUpdateTable={setUpdateTable}
        action={action}
        selectEducationOptions={selectEducationOptions}
        selectGradeTypeOptions={selectGradeTypeOptions}
        educationId={educationId}
        statusSelection={statusSelection}
      />
      <Row className="mb-3">
        <Col md="2" xs="12" className="mb-3">
          <Button
            className="btn bg-danger-edit"
            onClick={() => handleAddGradeLevel("add")}
          >
            <img src={Add} style={{ height: "1rem" }} />
          </Button>
        </Col>
        <Col md="4" xs="12" className="ml-auto mb-3">
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <Button type="button" color="primary" id="search-button">
                <i className="fa fa-search"></i>
              </Button>
            </InputGroupAddon>
            <Input
              className="form-control"
              placeholder="Search..."
              onChange={handleSearch}
            />
          </InputGroup>
          <p>Searchable Column: Education Type, Grade Type, Grade Level</p>
        </Col>
      </Row>
      <BootstrapTable
        wrapperClasses="table-responsive mb-3 text-center"
        bootstrap4
        striped
        keyField="id"
        data={filteredData}
        columns={columns}
        loading={loading}
        classes={isMobile ? "mobileTable" : "desktopTable"}
        pagination={paginationFactory()}
        overlay={overlayFactory({
          spinner: true,
          styles: {
            overlay: (base) => ({
              ...base,
              background: "rgba(0, 0, 0, 0.5)",
            }),
          },
        })}
        noDataIndication={() => <NoDataIndication />}
      />
    </div>
  );
};

export default GradeLevel;
