import React, { useState, useEffect, useRef } from "react";
import { isBrowser, isDesktop } from "react-device-detect";
import { BsCloudDownload } from "react-icons/bs";
import {
  Button,
  Col,
  Container,
  InputGroup,
  Form,
  InputGroupAddon,
  FormGroup,
  Row,
  Input,
  UncontrolledTooltip,
  Label,
} from "reactstrap";
import MobileSideBar from "../Admission/MobileSidebar";
import BootstrapTable from "react-bootstrap-table-next";
import overlayFactory from "react-bootstrap-table2-overlay";
import filterFactory from "react-bootstrap-table2-filter";
import Select from "react-select";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useForm, Controller } from "react-hook-form";
import _, { isEmpty } from "lodash";
import { educationActions } from "../../../services/EducationSelectionService";
import { coordinatorActions } from "../../../services/CoordinatorServices";
import optionList from "../Admission/optionList";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const StudentReport = () => {
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [totalSize, setTotalSize] = useState(0);
  const [page1, setPage1] = useState(1);
  const [sizePerPageQuery, setSizePerPageQuery] = useState(10);
  const [sortFieldQuery, setSortFieldQuery] = useState("id");
  const [sortOrderQuery, setSortOrderQuery] = useState("desc");
  const [loading, setLoading] = useState(false);
  const [gradeTypes, setGradeTypes] = useState([]);
  const [gradeTypeSelection, setGradeTypeSelection] = useState([]);
  const [selectedGradeType, setSelectedGradeType] = useState("");
  const [disableGradeType, setDisableGradeType] = useState(false);
  const [exportButtonSpinner, setExportButtonSpinner] = useState(false);
  const withSemester = [4, 5];
  const {
    control,
    handleSubmit,
    register,
    errors,
    reset,
    getValues,
    setValue,
    watch,
  } = useForm();
  const [semester, setSemester] = useState("");
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [filters, setFilters] = useState({
    syFrom: "",
    syTo: "",
  });

  useEffect(() => {
    educationActions.getApplications(
      null,
      null,
      setGradeTypes,
      null,
      null,
      null,
      null,
      null
    );
  }, []);

  useEffect(() => {
    if (gradeTypes.length) {
      let gradeTypesArr = [];
      gradeTypes.map((gt) => {
        if (gt.id != 1) {
          gradeTypesArr.push({ value: gt.id, label: gt.name });
        }
      });
      setGradeTypeSelection(gradeTypesArr);
    }
  }, [gradeTypes]);

  useEffect(() => {
    if (data.length) {
      let newFilters = {
        sy_from: moment(filters.syFrom).format("YYYY"),
        sy_to: moment(filters.syTo).format("YYYY"),
      };
      setLoading(true);
      setDisableGradeType(true);
      coordinatorActions.adminGetStudents(
        setData,
        selectedGradeType,
        setDisableGradeType,
        setTotalSize,
        page1,
        sizePerPageQuery,
        searchText,
        setLoading,
        semester,
        newFilters
      );
    }
  }, [page1, sizePerPageQuery]);

  const columns = [
    {
      dataField: "student_id",
      text: "Student Id",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "full_name",
      text: "Student Name",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "student_detail_id",
      text: "Action",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
      formatter: (cell, row) => {
        return (
          <div className="px-2" style={{ minWidth: "50px" }}>
            <Button
              id={"download-btn" + cell}
              onClick={() => toggleExportSF10(row)}
              className="btn-success"
              disabled={exportButtonSpinner}
            >
              <BsCloudDownload style={{ fontSize: "1.5rem", color: "#FFF" }} />
            </Button>
            <UncontrolledTooltip placement="top" target={"download-btn" + cell}>
              Download SF10
            </UncontrolledTooltip>
          </div>
        );
      },
    },
  ];

  const toggleExportSF10 = (row) => {
    setExportButtonSpinner(true);
    setDisableGradeType(true);
    let dataToSubmit = {
      student_detail_id: row.student_detail_id,
      grade_type_id: selectedGradeType,
    };

    coordinatorActions.exportSF10(
      dataToSubmit,
      setExportButtonSpinner,
      setDisableGradeType
    );
  };

  const RemoteAll = ({
    loading,
    data,
    page,
    sizePerPage,
    onTableChange,
    totalSize,
  }) => (
    <div>
      <BootstrapTable
        wrapperClasses="table-responsive text-center"
        bootstrap4
        striped
        remote
        loading={loading}
        keyField="id"
        data={data}
        columns={columns}
        defaultSorted={defaultSorted}
        filter={filterFactory()}
        pagination={paginationFactory({ page, sizePerPage, totalSize })}
        onTableChange={onTableChange}
        overlay={overlayFactory({
          spinner: true,
          styles: {
            overlay: (base) => ({
              ...base,
              background: "rgba(0, 0, 0, 0.5)",
            }),
          },
        })}
        noDataIndication={() => <NoDataIndication />}
      />
    </div>
  );

  // DATA TABLE
  const handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder }
  ) => {
    if (page !== page1) {
      setPage1(page);

      setSortFieldQuery(sortField);
      setSortOrderQuery(sortOrder);
      setLoading(true);
    }

    if (
      (sortField != sortFieldQuery && sortField != undefined) ||
      (sortOrder != sortOrderQuery && sortOrder != undefined)
    ) {
      setSortFieldQuery(sortField);
      setSortOrderQuery(sortOrder);
      setLoading(true);
    }

    if (sizePerPage != sizePerPageQuery) {
      setSizePerPageQuery(sizePerPage);
      setLoading(true);
    }
  };

  const NoDataIndication = () => <div>No Data</div>;
  const defaultSorted = [
    {
      dataField: sortFieldQuery,
      order: sortOrderQuery,
    },
  ];

  const delayedQuery = useRef(_.debounce((q) => sendQuery(q), 500)).current;
  const changeQuery = (e) => {
    setPage1(1);
    // setUserQuery(e.target.value);
    delayedQuery(e.target.value);
  };

  const sendQuery = (query) => {
    setSearchText(query);
    setLoading(true);
  };

  const onSubmit = (data) => {
    setLoading(true);
    setDisableGradeType(true);
    setSemester("");
    let newFilters = {
      sy_from: moment(data.sy_from).format("YYYY"),
      sy_to: moment(data.sy_to).format("YYYY"),
    };
    coordinatorActions.adminGetStudents(
      setData,
      selectedGradeType,
      setDisableGradeType,
      setTotalSize,
      page1,
      sizePerPageQuery,
      searchText,
      setLoading,
      semester,
      newFilters
    );
  };

  return (
    <div className="p-4">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md="3" xs="12">
            <FormGroup>
              <Label className="font-weight-bolder">S.Y. From</Label>
              <Controller
                control={control}
                name="sy_from"
                defaultValue=""
                rules={{ required: true }}
                invalid={errors.sy_from ? true : false}
                render={({ onChange, value, onBlur, name }) => (
                  <DatePicker
                    className="form-control bg-light"
                    selected={
                      moment(filters.syFrom).isValid()
                        ? moment(filters.syFrom).toDate()
                        : filters.syFrom
                    }
                    onChange={(date) => {
                      // setStartDate(date);
                      setValue("sy_from", date);
                      setFilters({ ...filters, syFrom: date });
                      return date;
                    }}
                    showYearPicker
                    dateFormat="yyyy"
                    yearItemNumber={10}
                    name="sy_from"
                    defaultValue=""
                  />
                )}
              />
              {errors.sy_from && errors.sy_from.type == "required" && (
                <div
                  style={{
                    marginTop: "0.25rem",
                    fontSize: "80%",
                    color: "#f86c6b",
                  }}
                >
                  School Year From is required!
                </div>
              )}
            </FormGroup>
          </Col>
          <Col md="3" xs="12">
            <FormGroup>
              <Label className="font-weight-bolder">S.Y. To</Label>
              <Controller
                control={control}
                name="sy_to"
                defaultValue=""
                rules={{
                  required: true,
                  validate: (value) => value > getValues("sy_from"),
                }}
                invalid={errors.sy_to ? true : false}
                render={({ onChange, value, onBlur, name }) => (
                  <DatePicker
                    className="form-control bg-light"
                    selected={
                      moment(filters.syTo).isValid()
                        ? moment(filters.syTo).toDate()
                        : filters.syTo
                    }
                    onChange={(date) => {
                      setValue("sy_to", date);
                      setFilters({ ...filters, syTo: date });
                      return date;
                    }}
                    showYearPicker
                    dateFormat="yyyy"
                    yearItemNumber={10}
                    name="sy_to"
                    defaultValue=""
                  />
                )}
              />
              {errors.sy_to && errors.sy_to.type == "required" && (
                <div
                  style={{
                    marginTop: "0.25rem",
                    fontSize: "80%",
                    color: "#f86c6b",
                  }}
                >
                  School Year To is required!
                </div>
              )}
              {errors.sy_to && errors.sy_to.type == "validate" && (
                <div
                  style={{
                    marginTop: "0.25rem",
                    fontSize: "80%",
                    color: "#f86c6b",
                  }}
                >
                  School Year To cannot be lower or equal to S.Y. From!
                </div>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md="3" xs="12" className="mb-2">
            <Label>Filter by Grade Level</Label>
            <Controller
              control={control}
              name="gradeLevel"
              rules={{ required: true }}
              invalid={errors.gradeLevel ? true : false}
              defaultValue=""
              render={({ onChange, value, onBlur, name }) => (
                <Select
                  options={gradeTypeSelection}
                  onChange={(e) => {
                    setSelectedGradeType(e.value);
                    setValue("gradeLevel", e);
                    return e;
                  }}
                  defaultValue=""
                />
              )}
            />
            {errors.gradeLevel && errors.gradeLevel.type == "required" && (
              <div
                style={{
                  marginTop: "0.25rem",
                  fontSize: "80%",
                  color: "#f86c6b",
                }}
              >
                Grade Level is required!
              </div>
            )}
          </Col>
          {withSemester.includes(selectedGradeType) ? (
            <Col md="3" xs="12" className="mb-2">
              <Label>Filter by Semester</Label>
              <Controller
                control={control}
                name="semester"
                rules={{ required: true }}
                invalid={errors.semester ? true : false}
                defaultValue=""
                render={({ onChange, value, onBlur, name }) => (
                  <Select
                    options={optionList.semester}
                    onChange={(e) => {
                      setSemester(e.value);
                      setValue("semester", e);
                      return e;
                    }}
                    defaultValue=""
                  />
                )}
              />
              {errors.semester && errors.semester.type == "required" && (
                <div
                  style={{
                    marginTop: "0.25rem",
                    fontSize: "80%",
                    color: "#f86c6b",
                  }}
                >
                  Semester is required!
                </div>
              )}
            </Col>
          ) : (
            ""
          )}
          <Col md="4" xs="12" className="ml-auto mb-3">
            <Label>Searchable Column: Student Name</Label>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <Button type="button" color="primary" id="search-button">
                  <i className="fa fa-search"></i>
                </Button>
              </InputGroupAddon>
              <Input
                className="form-control"
                placeholder="Search..."
                onChange={(e) => setSearchText(e.target.value)}
                disabled={disableGradeType}
              />
            </InputGroup>
          </Col>
        </Row>
        <Row className="pb-5">
          <Col xs="12" md="12">
            <Button
              disabled={buttonSpinner}
              className="bg-danger-edit text-white border-0"
            >
              Filter
            </Button>
          </Col>
        </Row>
      </Form>
      <RemoteAll
        loading={loading}
        data={data}
        page={page1}
        sizePerPage={sizePerPageQuery}
        totalSize={totalSize}
        onTableChange={handleTableChange}
        register={register}
      />
    </div>
  );
};

export default StudentReport;
