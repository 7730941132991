import React from "react";
import {
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  TabContent,
  TabPane,
  ModalHeader,
  Modal,
  ModalFooter,
  ModalBody,
  Input,
  UncontrolledTooltip,
  Button,
  Label,
  Spinner,
} from "reactstrap";
import { isDesktop, isTablet, isBrowser } from "react-device-detect";
import { useState, useEffect } from "react";
import academicsBase from "../../../../assets/img/academics-base-with-text.jpg";
import academicsBaseMobile from "../../../../assets/img/academics-base-mobile.jpg";
import classnames from "classnames";
import BasicAcads from "./BasicAcads.js";
import TertiaryAcads from "./TertiaryAcads.js";
import TechnicalAcads from "./TechnicalAcads.js";
import { WithContext as ReactTags } from "react-tag-input";
import preSchool from "../../../../assets/img/acads-basic/pre-school.jpg";
import gradeSchool1 from "../../../../assets/img/acads-basic/grade-school-1.jpg";
import gradeSchool2 from "../../../../assets/img/acads-basic/grade-school-2.jpg";
import highSchool1 from "../../../../assets/img/acads-basic/high-school-1.jpg";
import highSchool2 from "../../../../assets/img/acads-basic/high-school-2.jpg";
import highSchool3 from "../../../../assets/img/acads-basic/high-school-3.jpg";
import highSchool4 from "../../../../assets/img/acads-basic/high-school-4.jpg";
import hospitality from "../../../../assets/img/acads-tertiary/hospitality.jpg";
import businessAd1 from "../../../../assets/img/acads-tertiary/business-ad.jpg";
import businessAd2 from "../../../../assets/img/acads-tertiary/business-ad-2.jpg";
import psych from "../../../../assets/img/acads-tertiary/psych.jpg";
import officeAd from "../../../../assets/img/acads-tertiary/office-ad.jpg";
import technical from "../../../../assets/img/acads-tech/technical.png";
import technical2 from "../../../../assets/img/acads-tech/technical-2.png";
import { notifyError } from "../../../../services/Toaster";
import { dynamicAcademicsActions } from "../../../../services/DynamicAcademicsServices";
import Select from "react-select";

const Academics = () => {
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [buttonSpinner2, setButtonSpinner2] = useState(false);
  const [currentVersion, setCurrentVersion] = useState("");
  const [versionSelection, setVersionSelection] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState("");

  const [activeTab, setActiveTab] = useState("1");

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    document.title = "ACADEMICS | San Antonio de Padua College";
  }, []);

  const getOptionLabelByValue = (options, value) => {
    const option = options.find((option) => option.value == value);
    return option ? option.label : "";
  };

  const [type, setType] = useState("");
  const [type2, setType2] = useState("");
  const acceptedImageExtentions = ["jpg", "jpeg", "png", "gif"];
  const [basicEducation, setBasicEducation] = useState({
    mainImage: academicsBase,
    mainTablet: academicsBase,
    mainMobile: academicsBaseMobile,
    mainTitle: "Goals & Objectives",
    mainDescription:
      "In keeping with the goals and objectives of the Department of Education and the vision and mission of the San Antonio de Padua College Foundation of Pila, Laguna Inc, the Basic Education Department hopes to attain the following:",
    first: preSchool,
    second: gradeSchool1,
    third: gradeSchool2,
    fourth: highSchool4,
    fifth: highSchool2,
    sixth: highSchool1,
    seventh: highSchool3,
    firstTitle: "Pre-School",
    firstDescription: "Nursery and Kindergarten programs are committed to:",
    firstContents: [
      {
        id: "Implement a curriculum that provides pupils with a variety of holistic developmentally meaningful activities that nurture them to become confident, happy, and lifelong learners.",
        text: "Implement a curriculum that provides pupils with a variety of holistic developmentally meaningful activities that nurture them to become confident, happy, and lifelong learners.",
      },
      {
        id: "Engage pupils in a variety of fun and stimulating activities that allows children to enjoy, explore, investigate, discover, collaborate, create, and innovate.",
        text: "Engage pupils in a variety of fun and stimulating activities that allows children to enjoy, explore, investigate, discover, collaborate, create, and innovate.",
      },
      {
        id: "Emboldens a strong, supportive partnership between home and the school.",
        text: "Emboldens a strong, supportive partnership between home and the school.",
      },
    ],
    secondTitle: "Grade School",
    secondDescription: "The SAPC Grade School is committed to:",
    secondContents: [
      {
        id: "Provide an engaging educational program that is age-appropriate that enables learners aged 6 to 11 to move progressively towards independent learning.",
        text: "Provide an engaging educational program that is age-appropriate that enables learners aged 6 to 11 to move progressively towards independent learning.",
      },
      {
        id: "Implement a curricular approach that blends student-centered approaches with E-Learning, integrating understanding of career pathways in preparation for higher learning.",
        text: "Implement a curricular approach that blends student-centered approaches with E-Learning, integrating understanding of career pathways in preparation for higher learning.",
      },
      {
        id: "Builds on the discovery of students’ strengths to gain confidence through self-expression and collaboration with fellow students.",
        text: "Builds on the discovery of students’ strengths to gain confidence through self-expression and collaboration with fellow students.",
      },
      {
        id: "Promote a strong, supportive partnership between home and the school and the wider community.",
        text: "Promote a strong, supportive partnership between home and the school and the wider community.",
      },
    ],
    thirdTitle: "High School",
    thirdDescription: "SAPC High School (Grades 7 – 12) is committed in:",
    thirdContents: [
      {
        id: "Providing an educational program that develops students’ academic, thinking, and workplace competencies to prepare them for employment, entrepreneurship, middle level skills development, and higher education.",
        text: "Providing an educational program that develops students’ academic, thinking, and workplace competencies to prepare them for employment, entrepreneurship, middle level skills development, and higher education.",
      },
      {
        id: "Implementing a curriculum that is student-centered, technologically-aided, relevant, collaborative, career-oriented, and global in perspective that allows all students to succeed.",
        text: "Implementing a curriculum that is student-centered, technologically-aided, relevant, collaborative, career-oriented, and global in perspective that allows all students to succeed.",
      },
      {
        id: "Fostering an atmosphere where student feels they belong, respected and accepted by as they nurture creativity and originality to be competent and socially engaged individuals.",
        text: "Fostering an atmosphere where student feels they belong, respected and accepted by as they nurture creativity and originality to be competent and socially engaged individuals.",
      },
    ],
  });

  const [tertiaryEducation, setTertiaryEducation] = useState({
    mainTitle: "Curriculum",
    mainDescription1:
      "SAPC course offerings are responsible to the needs of the times and in consonance with student skills and interests.",
    mainDescription2:
      "All business and related courses are offered in ladderized curriculum. After two years (4 semesters) an Associate Diploma is awarded with the option of pursuing the full 4 years degree courses.",
    firstTitle:
      "Bachelor of Science in Hospitality Management – Ladderized Curriculum",
    firstDescription:
      "The program equips students with essential skills, knowledge, competencies and attitude to pursue and succeed in a career in the tourism industry and/or hospitality sectors. Apply basic techniques in performing prescribed range of specific functions in the areas of Food and Beverage, Front Office, and Housekeeping Operations as required in accommodation, food and beverages enterprises; undertakes planning and initiation of alternative approaches to skills and knowledge applications across a broad range of technical and procedural requirements.",
    firstContentTitle: "The program aims to produce graduates who can:",
    firstContents: [
      {
        id: "Function as an effective and skilled worker in every aspect of food management and hotel supervision and administration.",
        text: "Function as an effective and skilled worker in every aspect of food management and hotel supervision and administration.",
      },
      {
        id: "To integrate stewardship, accountability, excellence and moral values in the exercise of their function.",
        text: "To integrate stewardship, accountability, excellence and moral values in the exercise of their function.",
      },
      {
        id: "Provide leadership is the promotion of the countries natural and cultural attraction to domestic and international travelers.",
        text: "Provide leadership is the promotion of the countries natural and cultural attraction to domestic and international travelers.",
      },
    ],
    secondTitle: "Bachelor of Science in Business Administration",
    secondDescription:
      "Human Resource Development Program aims to prepare the graduates for a career in the field of Human Resource Management in various corporations whether in the manufacturing, marketing and service sectors, or in the different types of industries such as pharmaceutical, semi-conductor, food and beverage, banking industries and other types of organizations.",
    secondContentTitle: "The program aims to produce graduates who can:",
    secondContents: [
      {
        id: "Exhibit proficiency in the major facets of concepts, principles, theories, and philosophies in Human Resource of business operations.",
        text: "Exhibit proficiency in the major facets of concepts, principles, theories, and philosophies in Human Resource of business operations.",
      },
      {
        id: "Effectively show management/marketing techniques, skills and principles to enable them to deal with actual situation of problem solving and decision making.",
        text: "Effectively show management/marketing techniques, skills and principles to enable them to deal with actual situation of problem solving and decision making.",
      },
      {
        id: "Appreciate the HR role in the organization and how they can make meaningful contributions as a strategic partner in building the organization to become globally competitive.",
        text: "Appreciate the HR role in the organization and how they can make meaningful contributions as a strategic partner in building the organization to become globally competitive.",
      },
      {
        id: "Manifest stewardship, accountability, excellence and moral integrity in the exercise of one’s personal and professional life.",
        text: "Manifest stewardship, accountability, excellence and moral integrity in the exercise of one’s personal and professional life.",
      },
    ],
    secondContentTitle2:
      "The Operations Management Program prepares the students for the acquisition of competencies and skills needed as managers in manufacturing and service oriented businesses. It focuses on managing the processes to produce and distribute products and services. Major overall activities often include product creation, development, production and distribution. Operations Management covers all operations within the organization and related activities including managing purchases, inventory control, quality control, storage, logistics and evaluation. A great deal of focus is on efficiency and effectiveness of processes.",
    secondContents2: [
      {
        id: "Show know-how with concepts, principles and the theories of Operations Management as well display competencies and skills as entrepreneurs and managers.",
        text: "Show know-how with concepts, principles and the theories of Operations Management as well display competencies and skills as entrepreneurs and managers.",
      },
      {
        id: "Organize and manage activities in manufacturing and service related businesses.",
        text: "Organize and manage activities in manufacturing and service related businesses.",
      },
      {
        id: "Manifest stewardship, accountability, excellence and moral integrity in the exercise of one’s personal and professional life.",
        text: "Manifest stewardship, accountability, excellence and moral integrity in the exercise of one’s personal and professional life.",
      },
      {
        id: "Prepare the students to pursue a teaching career or graduate studies in business.",
        text: "Prepare the students to pursue a teaching career or graduate studies in business.",
      },
    ],
    thirdTitle: "Bachelor of Arts in Psychology",
    thirdDescription:
      "The program prepares students for jobs that may involve training, testing, research and intervention in industrial, clinical, and educational settings. It also provides preparation for graduate studies in psychology as well as further studies in other field of specialization such as medicine, law, and business management.",
    thirdContentTitle: "The program aims to produce graduates who:",
    thirdContents: [
      {
        id: "Can be proficient in dealing with people of different level.",
        text: "Can be proficient in dealing with people of different level.",
      },
      {
        id: "Possess high moral and ethical standards and values in his relation with others.",
        text: "Possess high moral and ethical standards and values in his relation with others.",
      },
      {
        id: "Can undertake research using the knowledge and skills in psychology and able to communicate the result of such research to both specialist and non-specialist audiences.",
        text: "Can undertake research using the knowledge and skills in psychology and able to communicate the result of such research to both specialist and non-specialist audiences.",
      },
      {
        id: "Are equipped with knowledge in understanding people with problems in their relationship with others.",
        text: "Are equipped with knowledge in understanding people with problems in their relationship with others.",
      },
    ],
    fourthTitle: "Associate in Office Administration",
    fourthDescription:
      "The program prepares students for a career in an outcomes focused, technology rich professional environment. It also provides preparation for a four- year course in BS in Office Administration.",
    fourthContentTitle: "The program aims to produce graduates who:",
    fourthContents: [
      {
        id: "Are adept in the current techniques in office practice and procedures, development of office systems and technology, efficient team-building and management skills.",
        text: "Are adept in the current techniques in office practice and procedures, development of office systems and technology, efficient team-building and management skills.",
      },
      {
        id: "Possess effective human relations and communication skill to become key players in day-to-day office operations.",
        text: "Possess effective human relations and communication skill to become key players in day-to-day office operations.",
      },
      {
        id: "Possess high moral standards and values in his relation with the others.",
        text: "Possess high moral standards and values in his relation with the others.",
      },
      {
        id: "Are equipped with leadership skills needed to cope with the increasing responsibility in the field of business and industry.",
        text: "Are equipped with leadership skills needed to cope with the increasing responsibility in the field of business and industry.",
      },
    ],
    firstImage: hospitality,
    secondImage: businessAd2,
    thirdImage: businessAd1,
    fourthImage: highSchool4,
    fifthImage: highSchool2,
  });

  const [technicalEducation, setTechnicalEducation] = useState({
    mainTitle: "Technical Education and Skills Development",
    contentTitle1:
      "The TESDA programs of SAPC aims provide middle level skills development trainings that seeks to produce graduates who are:",
    contents1: [
      {
        id: "Highly skilled and competent workers demanded by industry.",
        text: "Highly skilled and competent workers demanded by industry.",
      },
      {
        id: "Certified according to competency assessment standards.",
        text: "Certified according to competency assessment standards.",
      },
      {
        id: "Displays desirable work attitude and integrates stewardship, accountability, and excellence in the exercise of their function.",
        text: "Displays desirable work attitude and integrates stewardship, accountability, and excellence in the exercise of their function.",
      },
    ],
    contentTitle2: "TRAINING CENTER",
    contents2: [
      {
        id: "Bread and Pastry Production NC II",
        text: "Bread and Pastry Production NC II",
      },
      { id: "Caregiving NC II", text: "Caregiving NC II" },
      { id: "Cookery NC II", text: "Cookery NC II" },
      { id: "Housekeeping NC II", text: "Housekeeping NC II" },
      {
        id: "Food and Beverage Services NC II",
        text: "Food and Beverage Services NC II",
      },
      {
        id: "Shielded Metal Arc Welding (SMAW) NC I",
        text: "Shielded Metal Arc Welding (SMAW) NC I",
      },
      {
        id: "Shielded Metal Arc Welding (SMAW) NC II",
        text: "Shielded Metal Arc Welding (SMAW) NC II",
      },
    ],
    contentTitle3: "ACCREDITED COMPETENCY ASSESSMENT CENTER",
    contents3: [
      {
        id: "Bread and Pastry Production NC II",
        text: "Bread and Pastry Production NC II",
      },
      { id: "Caregiving NC II", text: "Caregiving NC II" },
      { id: "Cookery NC II", text: "Cookery NC II" },
      { id: "Housekeeping NC II", text: "Housekeeping NC II" },
      {
        id: "Food and Beverage Services NC II",
        text: "Food and Beverage Services NC II",
      },
      {
        id: "Shielded Metal Arc Welding (SMAW) NC I",
        text: "Shielded Metal Arc Welding (SMAW) NC I",
      },
      {
        id: "Shielded Metal Arc Welding (SMAW) NC II",
        text: "Shielded Metal Arc Welding (SMAW) NC II",
      },
    ],
    image1: technical,
    image2: technical2,
  });

  useEffect(() => {
    setLoading(true);
    setCurrentVersion("");
    dynamicAcademicsActions.getCurrentTemplate(
      setBasicEducation,
      basicEducation,
      setTertiaryEducation,
      tertiaryEducation,
      setTechnicalEducation,
      technicalEducation,
      setCurrentVersion,
      setSelectedVersion,
      setLoading,
      setVersionSelection,
      academicsBase,
      academicsBaseMobile,
      preSchool,
      gradeSchool1,
      gradeSchool2,
      highSchool4,
      highSchool2,
      highSchool1,
      highSchool3,
      hospitality,
      businessAd2,
      businessAd1,
      technical,
      technical2
    );
  }, []);

  useEffect(() => {
    dynamicAcademicsActions.getSelectedTemplate(
      setBasicEducation,
      basicEducation,
      setTertiaryEducation,
      tertiaryEducation,
      setTechnicalEducation,
      technicalEducation,
      setCurrentVersion,
      selectedVersion,
      setLoading,
      setVersionSelection,
      academicsBase,
      academicsBaseMobile,
      preSchool,
      gradeSchool1,
      gradeSchool2,
      highSchool4,
      highSchool2,
      highSchool1,
      highSchool3,
      hospitality,
      businessAd2,
      businessAd1,
      technical,
      technical2
    );
  }, [selectedVersion]);

  const KeyCodes = {
    enter: 13,
  };

  const delimiters = [KeyCodes.enter];

  const handleChangeModal = (type, type2) => {
    setType("");
    setType2("");
    if (!openUpdateModal) {
      setType(type);
      setType2(type2);
      setOpenUpdateModal(!openUpdateModal);
    } else {
      setOpenUpdateModal(!openUpdateModal);
    }
  };

  const [images, setImages] = useState({
    mainImage: "",
    mainTablet: "",
    mainMobile: "",
    first: "",
    second: "",
    third: "",
    fourth: "",
    fifth: "",
    sixth: "",
    seventh: "",
    firstImage: "",
    secondImage: "",
    thirdImage: "",
    fourthImage: "",
    fifthImage: "",
    image1: "",
    image2: "",
  });

  const [imageError, setImageError] = useState(false);
  const handleUploadImage = (e, data) => {
    setImageError(false);
    let extension = e[0].name.split(".")[1];
    if (acceptedImageExtentions.includes(extension)) {
      if (data == "main") {
        setImages({ ...images, mainImage: e[0] });
        setBasicEducation({
          ...basicEducation,
          mainImage: URL.createObjectURL(e[0]),
        });
      } else if (data == "tablet") {
        setImages({ ...images, mainTablet: e[0] });
        setBasicEducation({
          ...basicEducation,
          mainTablet: URL.createObjectURL(e[0]),
        });
      } else if (data == "mobileMain") {
        setImages({ ...images, mainMobile: e[0] });
        setBasicEducation({
          ...basicEducation,
          mainMobile: URL.createObjectURL(e[0]),
        });
      } else if (data == "firstImage") {
        setImages({ ...images, first: e[0] });
        setBasicEducation({
          ...basicEducation,
          first: URL.createObjectURL(e[0]),
        });
      } else if (data == "secondImage") {
        setImages({ ...images, second: e[0] });
        setBasicEducation({
          ...basicEducation,
          second: URL.createObjectURL(e[0]),
        });
      } else if (data == "thirdImage") {
        setImages({ ...images, third: e[0] });
        setBasicEducation({
          ...basicEducation,
          third: URL.createObjectURL(e[0]),
        });
      } else if (data == "fourthImage") {
        setImages({ ...images, fourth: e[0] });
        setBasicEducation({
          ...basicEducation,
          fourth: URL.createObjectURL(e[0]),
        });
      } else if (data == "fifthImage") {
        setImages({ ...images, fifth: e[0] });
        setBasicEducation({
          ...basicEducation,
          fifth: URL.createObjectURL(e[0]),
        });
      } else if (data == "sixthImage") {
        setImages({ ...images, sixth: e[0] });
        setBasicEducation({
          ...basicEducation,
          sixth: URL.createObjectURL(e[0]),
        });
      } else if (data == "seventhImage") {
        setImages({ ...images, seventh: e[0] });
        setBasicEducation({
          ...basicEducation,
          seventh: URL.createObjectURL(e[0]),
        });
      } else if (data == "tertiaryFirstImage") {
        setImages({ ...images, firstImage: e[0] });
        setTertiaryEducation({
          ...tertiaryEducation,
          firstImage: URL.createObjectURL(e[0]),
        });
      } else if (data == "tertiarySecondImage") {
        setImages({ ...images, secondImage: e[0] });
        setTertiaryEducation({
          ...tertiaryEducation,
          secondImage: URL.createObjectURL(e[0]),
        });
      } else if (data == "tertiaryThirdImage") {
        setImages({ ...images, thirdImage: e[0] });
        setTertiaryEducation({
          ...tertiaryEducation,
          thirdImage: URL.createObjectURL(e[0]),
        });
      } else if (data == "tertiaryFourthImage") {
        setImages({ ...images, fourthImage: e[0] });
        setTertiaryEducation({
          ...tertiaryEducation,
          fourthImage: URL.createObjectURL(e[0]),
        });
      } else if (data == "tertiaryFifthImage") {
        setImages({ ...images, fifthImage: e[0] });
        setTertiaryEducation({
          ...tertiaryEducation,
          fifthImage: URL.createObjectURL(e[0]),
        });
      } else if (data == "technicalFirstImage") {
        setImages({ ...images, image1: e[0] });
        setTechnicalEducation({
          ...technicalEducation,
          image1: URL.createObjectURL(e[0]),
        });
      } else if (data == "technicalSecondImage") {
        setImages({ ...images, image2: e[0] });
        setTechnicalEducation({
          ...technicalEducation,
          image2: URL.createObjectURL(e[0]),
        });
      }
    } else {
      notifyError("Invalid file for image");
      setImageError(true);
    }
  };

  const handleDeleteFirstContent = (i) => {
    const filteredTags = basicEducation.firstContents.filter(
      (tag, index) => index !== i
    );
    setBasicEducation({ ...basicEducation, firstContents: filteredTags });
  };

  const handleAdditionFirstContent = (tag) => {
    let currentFirstContents = basicEducation.firstContents;
    currentFirstContents.push(tag);
    setBasicEducation({
      ...basicEducation,
      firstContents: currentFirstContents,
    });
  };

  const handleDragFirstContent = (tag, currPos, newPos) => {
    const newTags = basicEducation.firstContents.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setBasicEducation({ ...basicEducation, firstContents: newTags });
  };

  const handleDeleteSecondContent = (i) => {
    const filteredTags = basicEducation.secondContents.filter(
      (tag, index) => index !== i
    );
    setBasicEducation({ ...basicEducation, secondContents: filteredTags });
  };

  const handleAdditionSecondContent = (tag) => {
    let currentSecondContents = basicEducation.secondContents;
    currentSecondContents.push(tag);
    setBasicEducation({
      ...basicEducation,
      secondContents: currentSecondContents,
    });
  };

  const handleDragSecondContent = (tag, currPos, newPos) => {
    const newTags = basicEducation.secondContents.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setBasicEducation({ ...basicEducation, secondContents: newTags });
  };

  const handleDeleteThirdContent = (i) => {
    const filteredTags = basicEducation.thirdContents.filter(
      (tag, index) => index !== i
    );
    setBasicEducation({ ...basicEducation, thirdContents: filteredTags });
  };

  const handleAdditionThirdContent = (tag) => {
    let currentThirdContents = basicEducation.thirdContents;
    currentThirdContents.push(tag);
    setBasicEducation({
      ...basicEducation,
      thirdContents: currentThirdContents,
    });
  };

  const handleDragThirdContent = (tag, currPos, newPos) => {
    const newTags = basicEducation.thirdContents.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setBasicEducation({ ...basicEducation, thirdContents: newTags });
  };

  //TERTIARY CONTENTS
  const handleDeleteTertiaryFirstContents = (i) => {
    const filteredTags = tertiaryEducation.firstContents.filter(
      (tag, index) => index !== i
    );
    setTertiaryEducation({ ...tertiaryEducation, firstContents: filteredTags });
  };

  const handleAdditionTertiaryFirstContents = (tag) => {
    let currentFirstContents = tertiaryEducation.firstContents;
    currentFirstContents.push(tag);
    setTertiaryEducation({
      ...tertiaryEducation,
      firstContents: currentFirstContents,
    });
  };

  const handleDragTertiaryFirstContents = (tag, currPos, newPos) => {
    const newTags = tertiaryEducation.firstContents.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTertiaryEducation({ ...tertiaryEducation, firstContents: newTags });
  };

  const handleDeleteTertiarySecondContents = (i) => {
    const filteredTags = tertiaryEducation.secondContents.filter(
      (tag, index) => index !== i
    );
    setTertiaryEducation({
      ...tertiaryEducation,
      secondContents: filteredTags,
    });
  };

  const handleAdditionTertiarySecondContents = (tag) => {
    let currentSecondContents = tertiaryEducation.secondContents;
    currentSecondContents.push(tag);
    setTertiaryEducation({
      ...tertiaryEducation,
      secondContents: currentSecondContents,
    });
  };

  const handleDragTertiarySecondContents = (tag, currPos, newPos) => {
    const newTags = tertiaryEducation.secondContents.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTertiaryEducation({ ...tertiaryEducation, secondContents2: newTags });
  };

  const handleDeleteTertiarySecondContents2 = (i) => {
    const filteredTags = tertiaryEducation.secondContents2.filter(
      (tag, index) => index !== i
    );
    setTertiaryEducation({
      ...tertiaryEducation,
      secondContents2: filteredTags,
    });
  };

  const handleAdditionTertiarySecondContents2 = (tag) => {
    let currentSecondContents2 = tertiaryEducation.secondContents2;
    currentSecondContents2.push(tag);
    setTertiaryEducation({
      ...tertiaryEducation,
      secondContents2: currentSecondContents2,
    });
  };

  const handleDragTertiarySecondContents2 = (tag, currPos, newPos) => {
    const newTags = tertiaryEducation.secondContents2.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTertiaryEducation({ ...tertiaryEducation, secondContents2: newTags });
  };

  const handleDeleteTertiaryThirdContents = (i) => {
    const filteredTags = tertiaryEducation.thirdContents.filter(
      (tag, index) => index !== i
    );
    setTertiaryEducation({ ...tertiaryEducation, thirdContents: filteredTags });
  };

  const handleAdditionTertiaryThirdContents = (tag) => {
    let currentThirdContents = tertiaryEducation.thirdContents;
    currentThirdContents.push(tag);
    setTertiaryEducation({
      ...tertiaryEducation,
      thirdContents: currentThirdContents,
    });
  };

  const handleDragTertiaryThirdContents = (tag, currPos, newPos) => {
    const newTags = tertiaryEducation.thirdContents.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTertiaryEducation({ ...tertiaryEducation, fourthContents: newTags });
  };

  const handleDeleteTertiaryFourthContents = (i) => {
    const filteredTags = tertiaryEducation.fourthContents.filter(
      (tag, index) => index !== i
    );
    setTertiaryEducation({
      ...tertiaryEducation,
      fourthContents: filteredTags,
    });
  };

  const handleAdditionTertiaryFourthContents = (tag) => {
    let currentFourthContents = tertiaryEducation.fourthContents;
    currentFourthContents.push(tag);
    setTertiaryEducation({
      ...tertiaryEducation,
      fourthContents: currentFourthContents,
    });
  };

  const handleDragTertiaryFourthContents = (tag, currPos, newPos) => {
    const newTags = tertiaryEducation.fourthContents.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTertiaryEducation({ ...tertiaryEducation, fourthContents: newTags });
  };

  //TECHNICAL EDUCATION
  const handleDeleteTechnicalContents1 = (i) => {
    const filteredTags = technicalEducation.contents1.filter(
      (tag, index) => index !== i
    );
    setTechnicalEducation({ ...technicalEducation, contents1: filteredTags });
  };

  const handleAdditionTechnicalContents1 = (tag) => {
    let currentContents = technicalEducation.contents1;
    currentContents.push(tag);
    setTechnicalEducation({
      ...technicalEducation,
      contents1: currentContents,
    });
  };

  const handleDragTechnicalContents1 = (tag, currPos, newPos) => {
    const newTags = technicalEducation.contents1.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTechnicalEducation({ ...technicalEducation, contents1: newTags });
  };

  const handleDeleteTechnicalContents2 = (i) => {
    const filteredTags = technicalEducation.contents2.filter(
      (tag, index) => index !== i
    );
    setTechnicalEducation({ ...technicalEducation, contents2: filteredTags });
  };

  const handleAdditionTechnicalContents2 = (tag) => {
    let currentContents = technicalEducation.contents2;
    currentContents.push(tag);
    setTechnicalEducation({
      ...technicalEducation,
      contents2: currentContents,
    });
  };

  const handleDragTechnicalContents2 = (tag, currPos, newPos) => {
    const newTags = technicalEducation.contents1.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTechnicalEducation({ ...technicalEducation, contents2: newTags });
  };

  const handleDeleteTechnicalContents3 = (i) => {
    const filteredTags = technicalEducation.contents3.filter(
      (tag, index) => index !== i
    );
    setTechnicalEducation({ ...technicalEducation, contents3: filteredTags });
  };

  const handleAdditionTechnicalContents3 = (tag) => {
    let currentContents = technicalEducation.contents3;
    currentContents.push(tag);
    setTechnicalEducation({
      ...technicalEducation,
      contents3: currentContents,
    });
  };

  const handleDragTechnicalContents3 = (tag, currPos, newPos) => {
    const newTags = technicalEducation.contents1.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTechnicalEducation({ ...technicalEducation, contents3: newTags });
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleSaveTemplate = async () => {
    setButtonSpinner(true);
    let files = [];

    if (images.mainImage && images.mainImage.name) {
      files.push({
        type: "mainImage",
        filename: images.mainImage.name,
        document: await convertBase64(images.mainImage),
      });
    }

    if (images.mainTablet && images.mainTablet.name) {
      files.push({
        type: "mainTablet",
        filename: images.mainTablet.name,
        document: await convertBase64(images.mainTablet),
      });
    }

    if (images.mainMobile && images.mainMobile.name) {
      files.push({
        type: "mainMobile",
        filename: images.mainMobile.name,
        document: await convertBase64(images.mainMobile),
      });
    }

    if (images.first && images.first.name) {
      files.push({
        type: "first",
        filename: images.first.name,
        document: await convertBase64(images.first),
      });
    }

    if (images.second && images.second.name) {
      files.push({
        type: "second",
        filename: images.second.name,
        document: await convertBase64(images.second),
      });
    }

    if (images.third && images.third.name) {
      files.push({
        type: "third",
        filename: images.third.name,
        document: await convertBase64(images.third),
      });
    }

    if (images.fourth && images.fourth.name) {
      files.push({
        type: "fourth",
        filename: images.fourth.name,
        document: await convertBase64(images.fourth),
      });
    }

    if (images.fifth && images.fifth.name) {
      files.push({
        type: "fifth",
        filename: images.fifth.name,
        document: await convertBase64(images.fifth),
      });
    }

    if (images.sixth && images.sixth.name) {
      files.push({
        type: "sixth",
        filename: images.sixth.name,
        document: await convertBase64(images.sixth),
      });
    }

    if (images.seventh && images.seventh.name) {
      files.push({
        type: "seventh",
        filename: images.seventh.name,
        document: await convertBase64(images.seventh),
      });
    }

    if (images.firstImage && images.firstImage.name) {
      files.push({
        type: "firstImage",
        filename: images.firstImage.name,
        document: await convertBase64(images.firstImage),
      });
    }

    if (images.secondImage && images.secondImage.name) {
      files.push({
        type: "secondImage",
        filename: images.secondImage.name,
        document: await convertBase64(images.secondImage),
      });
    }

    if (images.thirdImage && images.thirdImage.name) {
      files.push({
        type: "thirdImage",
        filename: images.thirdImage.name,
        document: await convertBase64(images.thirdImage),
      });
    }

    if (images.fourthImage && images.fourthImage.name) {
      files.push({
        type: "fourthImage",
        filename: images.fourthImage.name,
        document: await convertBase64(images.fourthImage),
      });
    }

    if (images.fifthImage && images.fifthImage.name) {
      files.push({
        type: "fifthImage",
        filename: images.fifthImage.name,
        document: await convertBase64(images.fifthImage),
      });
    }

    if (images.image1 && images.image1.name) {
      files.push({
        type: "image1",
        filename: images.image1.name,
        document: await convertBase64(images.image1),
      });
    }

    if (images.image2 && images.image2.name) {
      files.push({
        type: "image2",
        filename: images.image2.name,
        document: await convertBase64(images.image2),
      });
    }

    let dataToSubmit = {
      file_upload: files,
      basic_education: JSON.stringify(basicEducation),
      tertiary_education: JSON.stringify(tertiaryEducation),
      technical_education: JSON.stringify(technicalEducation),
    };

    dynamicAcademicsActions.saveTemplate(
      dataToSubmit,
      setLoading,
      setButtonSpinner,
      setButtonSpinner2
    );
  };

  const handlePublishTemplate = () => {
    setButtonSpinner2(true);
    dynamicAcademicsActions.publishTemplate(
      selectedVersion,
      setButtonSpinner,
      setLoading,
      setButtonSpinner2
    );
  };

  return (
    <div className="p-4">
      {/* <Slide top> */}
      <Row>
        <Col md="6" xs="12" className="my-4">
          <Select
            options={versionSelection}
            name="version"
            className="w-25"
            onChange={(e) => {
              setLoading(true);
              setSelectedVersion(e.value);
              return e;
            }}
            defaultValue={
              selectedVersion != ""
                ? {
                    value: selectedVersion,
                    label: getOptionLabelByValue(
                      versionSelection,
                      selectedVersion
                    ),
                  }
                : ""
            }
            value={
              selectedVersion != ""
                ? {
                    value: selectedVersion,
                    label: getOptionLabelByValue(
                      versionSelection,
                      selectedVersion
                    ),
                  }
                : ""
            }
          />
          <Label className="text-secondary">Select Version</Label>
        </Col>
        <Col md="6" xs="12" className="w-100 ml-auto text-right my-4">
          <div>
            <Button
              className="mr-3 border-0 bg-danger-edit"
              onClick={handleSaveTemplate}
              disabled={buttonSpinner || buttonSpinner2 || loading}
            >
              {buttonSpinner ? (
                <>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  &nbsp;Processing...
                </>
              ) : (
                "Save"
              )}
            </Button>
            <Button
              onClick={handlePublishTemplate}
              color="success"
              disabled={buttonSpinner || buttonSpinner2 || loading}
            >
              {buttonSpinner2 ? (
                <>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  &nbsp;Processing...
                </>
              ) : (
                "Publish"
              )}
            </Button>
          </div>
          <Label className="font-weight-bolder text-secondary">
            Latest Version: {currentVersion}
          </Label>
        </Col>
        <Col
          lg="12"
          md="12"
          xs="12"
          className="px-0 w-100 justify-content-center align-items-center d-flex"
        >
          {isDesktop ? (
            <img
              src={basicEducation.mainImage}
              id="mainImage"
              onClick={() => handleChangeModal("Main Image")}
              className="w-100"
              style={{ height: "80vh", objectFit: "cover" }}
            />
          ) : isTablet ? (
            <img
              src={basicEducation.mainTablet}
              id="mainImage"
              onClick={() => handleChangeModal("Main Image")}
              className="w-100"
            />
          ) : (
            <img
              src={basicEducation.mainMobile}
              id="mainImage"
              onClick={() => handleChangeModal("Main Image")}
              className="w-100 image-mobile"
            />
          )}
          <UncontrolledTooltip placement="top" target="mainImage">
            <h5>Click Image to Update</h5>
          </UncontrolledTooltip>
        </Col>
      </Row>
      {/* </Slide> */}

      <Nav
        tabs
        className={
          isDesktop
            ? ""
            : "align-items-center d-flex px-0 w-100 justified-content-center"
        }
      >
        <NavItem className={isDesktop ? "ml-auto" : ""}>
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => {
              toggleTab("1");
            }}
            id="basic-acads"
          >
            {isDesktop ? (
              <strong>BASIC EDUCATION</strong>
            ) : (
              <span className="font-weight-bolder">BASIC</span>
            )}
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "2" })}
            onClick={() => {
              toggleTab("2");
            }}
            id="tertiary-acads"
          >
            {isDesktop ? (
              <strong>TERTIARY EDUCATION</strong>
            ) : (
              <strong>TERTIARY</strong>
            )}
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "3" })}
            onClick={() => {
              toggleTab("3");
            }}
            id="technical-acads"
          >
            {isDesktop ? (
              <strong>TECHNICAL EDUCATION</strong>
            ) : (
              <strong>TECHNICAL</strong>
            )}
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <BasicAcads
            handleChangeModal={handleChangeModal}
            basicEducation={basicEducation}
          />
        </TabPane>

        <TabPane tabId="2">
          <TertiaryAcads
            handleChangeModal={handleChangeModal}
            tertiaryEducation={tertiaryEducation}
          />
        </TabPane>

        <TabPane tabId="3">
          <TechnicalAcads
            handleChangeModal={handleChangeModal}
            technicalEducation={technicalEducation}
          />
        </TabPane>
      </TabContent>
      <Modal isOpen={openUpdateModal} size="lg">
        <ModalHeader toggle={handleChangeModal}>Update {type}</ModalHeader>
        <ModalBody>
          {type == "Main Image" ? (
            <>
              <Label>Desktop</Label>
              <Input
                type="file"
                className="mb-3"
                onChange={(e) => handleUploadImage(e.target.files, "main")}
              />

              <Label>Tablet</Label>
              <Input
                type="file"
                className="mb-3"
                onChange={(e) => handleUploadImage(e.target.files, "tablet")}
              />

              <Label>Mobile</Label>
              <Input
                type="file"
                onChange={(e) =>
                  handleUploadImage(e.target.files, "mobileMain")
                }
              />
            </>
          ) : type == "Basic Education Title" ? (
            <Input
              defaultValue={basicEducation.mainTitle}
              onChange={(e) =>
                setBasicEducation({
                  ...basicEducation,
                  mainTitle: e.target.value,
                })
              }
            />
          ) : type == "Basic Education Main Description" ? (
            <Input
              defaultValue={basicEducation.mainDescription}
              type="textarea"
              style={{ height: "200px" }}
              onChange={(e) =>
                setBasicEducation({
                  ...basicEducation,
                  mainDescription: e.target.value,
                })
              }
            />
          ) : type == "Basic Education Image" && type2 == "first" ? (
            <Input
              type="file"
              onChange={(e) => handleUploadImage(e.target.files, "firstImage")}
            />
          ) : type == "Basic Education Image" && type2 == "second" ? (
            <Input
              type="file"
              onChange={(e) => handleUploadImage(e.target.files, "secondImage")}
            />
          ) : type == "Basic Education Image" && type2 == "third" ? (
            <Input
              type="file"
              onChange={(e) => handleUploadImage(e.target.files, "thirdImage")}
            />
          ) : type == "Basic Education Image" && type2 == "fourth" ? (
            <Input
              type="file"
              onChange={(e) => handleUploadImage(e.target.files, "fourthImage")}
            />
          ) : type == "Basic Education Image" && type2 == "fifth" ? (
            <Input
              type="file"
              onChange={(e) => handleUploadImage(e.target.files, "fifthImage")}
            />
          ) : type == "Basic Education Image" && type2 == "sixth" ? (
            <Input
              type="file"
              onChange={(e) => handleUploadImage(e.target.files, "sixthImage")}
            />
          ) : type == "Basic Education Image" && type2 == "seventh" ? (
            <Input
              type="file"
              onChange={(e) =>
                handleUploadImage(e.target.files, "seventhImage")
              }
            />
          ) : type == "Basic Education First Title" ? (
            <Input
              defaultValue={basicEducation.firstTitle}
              onChange={(e) =>
                setBasicEducation({
                  ...basicEducation,
                  firstTitle: e.target.value,
                })
              }
            />
          ) : type == "Basic Education First Description" ? (
            <Input
              defaultValue={basicEducation.firstDescription}
              type="textarea"
              style={{ height: "200px" }}
              onChange={(e) =>
                setBasicEducation({
                  ...basicEducation,
                  firstDescription: e.target.value,
                })
              }
            />
          ) : type == "Basic Education First Contents" ? (
            <>
              <h5 className="mb-3">Contents</h5>
              <ReactTags
                tags={basicEducation.firstContents}
                handleDelete={handleDeleteFirstContent}
                handleAddition={handleAdditionFirstContent}
                handleDrag={handleDragFirstContent}
                inputFieldPosition="top"
                placeholder="Press enter to add new content"
                labelField="text"
                delimiters={delimiters}
              />
            </>
          ) : type == "Basic Education Second Title" ? (
            <Input
              defaultValue={basicEducation.secondTitle}
              onChange={(e) =>
                setBasicEducation({
                  ...basicEducation,
                  secondTitle: e.target.value,
                })
              }
            />
          ) : type == "Basic Education Second Description" ? (
            <Input
              defaultValue={basicEducation.secondDescription}
              type="textarea"
              style={{ height: "200px" }}
              onChange={(e) =>
                setBasicEducation({
                  ...basicEducation,
                  secondDescription: e.target.value,
                })
              }
            />
          ) : type == "Basic Education Second Contents" ? (
            <>
              <h5 className="mb-3">Contents</h5>
              <ReactTags
                tags={basicEducation.secondContents}
                handleDelete={handleDeleteSecondContent}
                handleAddition={handleAdditionSecondContent}
                handleDrag={handleDragSecondContent}
                inputFieldPosition="top"
                placeholder="Press enter to add new content"
                labelField="text"
                delimiters={delimiters}
              />
            </>
          ) : type == "Basic Education Third Title" ? (
            <Input
              defaultValue={basicEducation.thirdTitle}
              onChange={(e) =>
                setBasicEducation({
                  ...basicEducation,
                  thirdTitle: e.target.value,
                })
              }
            />
          ) : type == "Basic Education Third Description" ? (
            <Input
              defaultValue={basicEducation.thirdDescription}
              type="textarea"
              style={{ height: "200px" }}
              onChange={(e) =>
                setBasicEducation({
                  ...basicEducation,
                  thirdDescription: e.target.value,
                })
              }
            />
          ) : type == "Basic Education Third Contents" ? (
            <>
              <h5 className="mb-3">Contents</h5>
              <ReactTags
                tags={basicEducation.thirdContents}
                handleDelete={handleDeleteThirdContent}
                handleAddition={handleAdditionThirdContent}
                handleDrag={handleDragThirdContent}
                inputFieldPosition="top"
                placeholder="Press enter to add new content"
                labelField="text"
                delimiters={delimiters}
              />
            </>
          ) : type == "Tertiary Education Main Title" ? (
            <Input
              defaultValue={tertiaryEducation.mainTitle}
              onChange={(e) =>
                setTertiaryEducation({
                  ...tertiaryEducation,
                  mainTitle: e.target.value,
                })
              }
            />
          ) : type == "Tertiary Education Main Description" &&
            type2 == "first" ? (
            <Input
              defaultValue={tertiaryEducation.mainDescription1}
              type="textarea"
              style={{ height: "200px" }}
              onChange={(e) =>
                setTertiaryEducation({
                  ...tertiaryEducation,
                  mainDescription1: e.target.value,
                })
              }
            />
          ) : type == "Tertiary Education Main Description" &&
            type2 == "second" ? (
            <Input
              defaultValue={tertiaryEducation.mainDescription2}
              type="textarea"
              style={{ height: "200px" }}
              onChange={(e) =>
                setTertiaryEducation({
                  ...tertiaryEducation,
                  mainDescription2: e.target.value,
                })
              }
            />
          ) : type == "Tertiary Education Title" && type2 == "first" ? (
            <Input
              defaultValue={tertiaryEducation.firstTitle}
              onChange={(e) =>
                setTertiaryEducation({
                  ...tertiaryEducation,
                  firstTitle: e.target.value,
                })
              }
            />
          ) : type == "Tertiary Education Description" && type2 == "first" ? (
            <Input
              defaultValue={tertiaryEducation.firstDescription}
              type="textarea"
              style={{ height: "200px" }}
              onChange={(e) =>
                setTertiaryEducation({
                  ...tertiaryEducation,
                  firstDescription: e.target.value,
                })
              }
            />
          ) : type == "Tertiary Education Content Title" && type2 == "first" ? (
            <Input
              defaultValue={tertiaryEducation.firstContentTitle}
              type="textarea"
              style={{ height: "200px" }}
              onChange={(e) =>
                setTertiaryEducation({
                  ...tertiaryEducation,
                  firstContentTitle: e.target.value,
                })
              }
            />
          ) : type == "Tertiary Education Contents" && type2 == "first" ? (
            <>
              <h5 className="mb-3">Contents</h5>
              <ReactTags
                tags={tertiaryEducation.firstContents}
                handleDelete={handleDeleteTertiaryFirstContents}
                handleAddition={handleAdditionTertiaryFirstContents}
                handleDrag={handleDragTertiaryFirstContents}
                inputFieldPosition="top"
                placeholder="Press enter to add new content"
                labelField="text"
                delimiters={delimiters}
              />
            </>
          ) : type == "Tertiary Education Title" && type2 == "second" ? (
            <Input
              defaultValue={tertiaryEducation.secondTitle}
              onChange={(e) =>
                setTertiaryEducation({
                  ...tertiaryEducation,
                  secondTitle: e.target.value,
                })
              }
            />
          ) : type == "Tertiary Education Description" && type2 == "second" ? (
            <Input
              defaultValue={tertiaryEducation.secondDescription}
              type="textarea"
              style={{ height: "200px" }}
              onChange={(e) =>
                setTertiaryEducation({
                  ...tertiaryEducation,
                  secondDescription: e.target.value,
                })
              }
            />
          ) : type == "Tertiary Education Content Title" &&
            type2 == "second" ? (
            <Input
              defaultValue={tertiaryEducation.secondContentTitle}
              type="textarea"
              style={{ height: "200px" }}
              onChange={(e) =>
                setTertiaryEducation({
                  ...tertiaryEducation,
                  secondContentTitle: e.target.value,
                })
              }
            />
          ) : type == "Tertiary Education Contents" && type2 == "second" ? (
            <>
              <h5 className="mb-3">Contents</h5>
              <ReactTags
                tags={tertiaryEducation.secondContents}
                handleDelete={handleDeleteTertiarySecondContents}
                handleAddition={handleAdditionTertiarySecondContents}
                handleDrag={handleDragTertiarySecondContents}
                inputFieldPosition="top"
                placeholder="Press enter to add new content"
                labelField="text"
                delimiters={delimiters}
              />
            </>
          ) : type == "Tertiary Education Content Title" &&
            type2 == "second2" ? (
            <Input
              defaultValue={tertiaryEducation.secondContentTitle2}
              type="textarea"
              style={{ height: "200px" }}
              onChange={(e) =>
                setTertiaryEducation({
                  ...tertiaryEducation,
                  secondContentTitle2: e.target.value,
                })
              }
            />
          ) : type == "Tertiary Education Contents" && type2 == "second2" ? (
            <>
              <h5 className="mb-3">Contents</h5>
              <ReactTags
                tags={tertiaryEducation.secondContents2}
                handleDelete={handleDeleteTertiarySecondContents2}
                handleAddition={handleAdditionTertiarySecondContents2}
                handleDrag={handleDragTertiarySecondContents2}
                inputFieldPosition="top"
                placeholder="Press enter to add new content"
                labelField="text"
                delimiters={delimiters}
              />
            </>
          ) : type == "Tertiary Education Title" && type2 == "third" ? (
            <Input
              defaultValue={tertiaryEducation.thirdTitle}
              onChange={(e) =>
                setTertiaryEducation({
                  ...tertiaryEducation,
                  thirdTitle: e.target.value,
                })
              }
            />
          ) : type == "Tertiary Education Description" && type2 == "third" ? (
            <Input
              defaultValue={tertiaryEducation.thirdDescription}
              type="textarea"
              style={{ height: "200px" }}
              onChange={(e) =>
                setTertiaryEducation({
                  ...tertiaryEducation,
                  thirdDescription: e.target.value,
                })
              }
            />
          ) : type == "Tertiary Education Content Title" && type2 == "third" ? (
            <Input
              defaultValue={tertiaryEducation.thirdContentTitle}
              type="textarea"
              style={{ height: "200px" }}
              onChange={(e) =>
                setTertiaryEducation({
                  ...tertiaryEducation,
                  thirdContentTitle: e.target.value,
                })
              }
            />
          ) : type == "Tertiary Education Contents" && type2 == "third" ? (
            <>
              <h5 className="mb-3">Contents</h5>
              <ReactTags
                tags={tertiaryEducation.thirdContents}
                handleDelete={handleDeleteTertiaryThirdContents}
                handleAddition={handleAdditionTertiaryThirdContents}
                handleDrag={handleDragTertiaryThirdContents}
                inputFieldPosition="top"
                placeholder="Press enter to add new content"
                labelField="text"
                delimiters={delimiters}
              />
            </>
          ) : type == "Tertiary Education Title" && type2 == "fourth" ? (
            <Input
              defaultValue={tertiaryEducation.fourthTitle}
              onChange={(e) =>
                setTertiaryEducation({
                  ...tertiaryEducation,
                  fourthTitle: e.target.value,
                })
              }
            />
          ) : type == "Tertiary Education Description" && type2 == "fourth" ? (
            <Input
              defaultValue={tertiaryEducation.fourthDescription}
              type="textarea"
              style={{ height: "200px" }}
              onChange={(e) =>
                setTertiaryEducation({
                  ...tertiaryEducation,
                  fourthDescription: e.target.value,
                })
              }
            />
          ) : type == "Tertiary Education Content Title" &&
            type2 == "fourth" ? (
            <Input
              defaultValue={tertiaryEducation.fourthContentTitle}
              type="textarea"
              style={{ height: "200px" }}
              onChange={(e) =>
                setTertiaryEducation({
                  ...tertiaryEducation,
                  fourthContentTitle: e.target.value,
                })
              }
            />
          ) : type == "Tertiary Education Contents" && type2 == "fourth" ? (
            <>
              <h5 className="mb-3">Contents</h5>
              <ReactTags
                tags={tertiaryEducation.fourthContents}
                handleDelete={handleDeleteTertiaryFourthContents}
                handleAddition={handleAdditionTertiaryFourthContents}
                handleDrag={handleDragTertiaryFourthContents}
                inputFieldPosition="top"
                placeholder="Press enter to add new content"
                labelField="text"
                delimiters={delimiters}
              />
            </>
          ) : type == "Tertiary Education Image" && type2 == "first" ? (
            <Input
              type="file"
              onChange={(e) =>
                handleUploadImage(e.target.files, "tertiaryFirstImage")
              }
            />
          ) : type == "Tertiary Education Image" && type2 == "second" ? (
            <Input
              type="file"
              onChange={(e) =>
                handleUploadImage(e.target.files, "tertiarySecondImage")
              }
            />
          ) : type == "Tertiary Education Image" && type2 == "third" ? (
            <Input
              type="file"
              onChange={(e) =>
                handleUploadImage(e.target.files, "tertiaryThirdImage")
              }
            />
          ) : type == "Tertiary Education Image" && type2 == "fourth" ? (
            <Input
              type="file"
              onChange={(e) =>
                handleUploadImage(e.target.files, "tertiaryFourthImage")
              }
            />
          ) : type == "Tertiary Education Image" && type2 == "fifth" ? (
            <Input
              type="file"
              onChange={(e) =>
                handleUploadImage(e.target.files, "tertiaryFifthImage")
              }
            />
          ) : type == "Technical Education Main Title" ? (
            <Input
              defaultValue={technicalEducation.mainTitle}
              onChange={(e) =>
                setTechnicalEducation({
                  ...technicalEducation,
                  mainTitle: e.target.value,
                })
              }
            />
          ) : type == "Technical Education Content Title" &&
            type2 == "first" ? (
            <Input
              defaultValue={technicalEducation.contentTitle1}
              type="textarea"
              style={{ height: "200px" }}
              onChange={(e) =>
                setTechnicalEducation({
                  ...technicalEducation,
                  contentTitle1: e.target.value,
                })
              }
            />
          ) : type == "Technical Education Contents" && type2 == "first" ? (
            <>
              <h5 className="mb-3">Contents</h5>
              <ReactTags
                tags={technicalEducation.contents1}
                handleDelete={handleDeleteTechnicalContents1}
                handleAddition={handleAdditionTechnicalContents1}
                handleDrag={handleDragTechnicalContents1}
                inputFieldPosition="top"
                placeholder="Press enter to add new content"
                labelField="text"
                delimiters={delimiters}
              />
            </>
          ) : type == "Technical Education Content Title" &&
            type2 == "second" ? (
            <Input
              defaultValue={technicalEducation.contentTitle2}
              type="textarea"
              style={{ height: "200px" }}
              onChange={(e) =>
                setTechnicalEducation({
                  ...technicalEducation,
                  contentTitle2: e.target.value,
                })
              }
            />
          ) : type == "Technical Education Contents" && type2 == "second" ? (
            <>
              <h5 className="mb-3">Contents</h5>
              <ReactTags
                tags={technicalEducation.contents2}
                handleDelete={handleDeleteTechnicalContents2}
                handleAddition={handleAdditionTechnicalContents2}
                handleDrag={handleDragTechnicalContents2}
                inputFieldPosition="top"
                placeholder="Press enter to add new content"
                labelField="text"
                delimiters={delimiters}
              />
            </>
          ) : type == "Technical Education Content Title" &&
            type2 == "third" ? (
            <Input
              defaultValue={technicalEducation.contentTitle3}
              type="textarea"
              style={{ height: "200px" }}
              onChange={(e) =>
                setTechnicalEducation({
                  ...technicalEducation,
                  contentTitle3: e.target.value,
                })
              }
            />
          ) : type == "Technical Education Contents" && type2 == "third" ? (
            <>
              <h5 className="mb-3">Contents</h5>
              <ReactTags
                tags={technicalEducation.contents3}
                handleDelete={handleDeleteTechnicalContents3}
                handleAddition={handleAdditionTechnicalContents3}
                handleDrag={handleDragTechnicalContents3}
                inputFieldPosition="top"
                placeholder="Press enter to add new content"
                labelField="text"
                delimiters={delimiters}
              />
            </>
          ) : type == "Technical Education Image" && type2 == "first" ? (
            <Input
              type="file"
              onChange={(e) =>
                handleUploadImage(e.target.files, "technicalFirstImage")
              }
            />
          ) : type == "Technical Education Image" && type2 == "second" ? (
            <Input
              type="file"
              onChange={(e) =>
                handleUploadImage(e.target.files, "technicalSecondImage")
              }
            />
          ) : (
            ""
          )}
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => setOpenUpdateModal(!openUpdateModal)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Academics;
