import { ENDPOINT } from "../constants/api";
import axios from "axios";
import { notifyError, notifyInfo, notifySuccess } from "./Toaster";
import querystring from "querystring";

export const paymentActions = {
  updateToPaidStatus,
  updateTxn,
  getApplicationForCashierByLastName,
  getApplicationForCashier,
  getPaymentHistory,
  updateToEnrollStatus,
  updateToPendingStatus,
  updatePaymentProof,
  exportPaymentHistory,
  voidPaymentProof,
};
const token = localStorage.getItem("jwtToken")
  ? localStorage.getItem("jwtToken")
  : "";
const headers = {
  Authorization: `Bearer ${token}`,
  "Content-Type": "application/json",
  "X-Requested-With": "XMLHttpRequest",
};

async function getPaymentHistory(
  setData,
  setLoading,
  setTotalSize,
  page1,
  sizePerPageQuery,
  searchText,
  filter
) {
  try {
    let q = searchText.trim();
    let filters = `?q=${q}&sizePerPage=${sizePerPageQuery}&page=${page1}&date_from=${filter.date_from}&date_to=${filter.date_to}&year_level=${filter.year_level}&semester=${filter.semester}`;
    let res = await axios.get(ENDPOINT + `/payment-history` + filters, {
      headers: headers,
    });

    setTotalSize(res.data.data.payment_history.total);

    if (
      res.data &&
      res.data.data.payment_history &&
      res.data.data.payment_history.data.length >= 1
    ) {
      let history = res.data.data.payment_history.data.map((his) => {
        let fullname =
          his.full_name && his.full_name.fullname ? his.full_name.fullname : "";
        let ref_no =
          his.full_name && his.full_name.ref_no ? his.full_name.ref_no : "";
        his.fullname = fullname;
        his.ref_no = ref_no;
        console.log(his);
        return his;
      });
      setData(history);
    } else {
      setData([]);
      notifyError("No Payment History");
    }

    setLoading(false);
  } catch (err) {
    console.log(err);
    if (err.response) {
      notifyError("Error while fetching payment history");
    }
  }
}

async function updateToPaidStatus(
  dataSubmit,
  setButtonSpinner,
  setStatusModalChange,
  billings,
  setBillings
) {
  try {
    let res = await axios.post(ENDPOINT + `/approved-billing`, dataSubmit, {
      headers: headers,
    });
    if (res.data.success) {
      setStatusModalChange(false);
      let newBillings = billings.map((billing) => {
        if (billing.id == dataSubmit.billing_id) {
          billing.status = "paid";
          return billing;
        }
        return billing;
      });
      setBillings(newBillings);
      notifySuccess("Payment Update Complete");
    } else {
      notifyError("Payment Update Incomplete, Error while updating status");
    }
    setButtonSpinner(false);
  } catch (err) {
    if (err.response) {
      notifyError("Payment Update Incomplete, Error while updating status");
    }
    setButtonSpinner(false);
  }
}

async function updateToPendingStatus(
  dataSubmit,
  setButtonSpinner,
  setRevokeConfirm,
  billings,
  setBillings
) {
  try {
    let res = await axios.post(ENDPOINT + `/revoke-billing`, dataSubmit, {
      headers: headers,
    });
    if (res.data.success) {
      setRevokeConfirm(false);
      let newBillings = billings.map((billing) => {
        if (billing.id == dataSubmit.billing_id) {
          billing.status = "pending";
          return billing;
        }
        return billing;
      });
      setBillings(newBillings);
      notifySuccess("Payment Revoke Complete");
    } else {
      notifyError("Payment Revoke Incomplete, Error while updating status");
    }
    setButtonSpinner(false);
  } catch (err) {
    if (err.response) {
      notifyError("Payment Revoke Incomplete, Error while updating status");
    }
    setButtonSpinner(false);
  }
}

async function updateTxn(
  dataSubmit,
  setButtonSpinner,
  setUpdateTxnOpen,
  updateTable,
  setUpdateTable
) {
  try {
    let res = await axios.post(ENDPOINT + `/cashier-upload`, dataSubmit, {
      headers: headers,
    });
    if (res.data.success) {
      setUpdateTxnOpen(false);
      notifySuccess("Payment Update Complete");
    } else {
      notifyError("Payment Update Incomplete, Error while updating status");
    }
    setButtonSpinner(false);
    setUpdateTable(!updateTable);
  } catch (err) {
    if (err.response) {
      notifyError("Payment Update Incomplete, Error while updating status");
    }
    setButtonSpinner(false);
  }
}

async function updatePaymentProof(
  dataSubmit,
  setButtonSpinner,
  setUpdateTxnOpen,
  updateTable,
  setUpdateTable
) {
  try {
    let res = await axios.post(ENDPOINT + `/remove-payment-proof`, dataSubmit, {
      headers: headers,
    });
    if (res.data.success) {
      setUpdateTxnOpen(false);
      notifySuccess("Payment Update Complete");
      setUpdateTable(!updateTable);
    } else {
      notifyError("Payment Update Incomplete, Error while updating status");
    }
    setButtonSpinner(false);
  } catch (err) {
    if (err.response) {
      notifyError("Payment Update Incomplete, Error while updating status");
    }
    setButtonSpinner(false);
  }
}

async function getApplicationForCashier(
  ref_no,
  setBillings,
  setPaymentApplication,
  setButtonSpinner,
  setLoading,
  setUpdateTable,
  setTotalAmount,
  setRemainingBalance
) {
  try {
    console.log(ref_no);
    let q = ref_no ? ref_no.trim() : "";
    let res = await axios.get(ENDPOINT + `/get-billings?ref_no=${q}`, {
      headers: headers,
    });
    const data = res.data.data.student_billing;
    const billings = data.billings;
    let paymentModeDetails = data.health_additional_info.paymentForm;
    if (paymentModeDetails && paymentModeDetails.paymentModeDetails) {
      let details = {
        application_id: data.id,
        referenceNo: data.ref_no,
        lastName: data.student_detail_id.last_name,
        givenName: data.student_detail_id.given_name,
        middleName: data.student_detail_id.middle_name,
        paymentMode: data.payment_mode.label,
        tuitionFee:
          paymentModeDetails && paymentModeDetails.paymentModeDetails
            ? paymentModeDetails.paymentModeDetails.tuition_fee
            : 0,
        miscellaneousFee:
          paymentModeDetails && paymentModeDetails.paymentModeDetails
            ? paymentModeDetails.paymentModeDetails.miscellaneous_fee
            : 0,
        otherFee:
          paymentModeDetails && paymentModeDetails.paymentModeDetails
            ? paymentModeDetails.paymentModeDetails.other_fee
            : 0,
        discountedTuitionFee: paymentModeDetails
          ? paymentModeDetails.discounted_tuituion_fee
          : 0,
        paymentUponEnrollment:
          paymentModeDetails && paymentModeDetails.paymentModeDetails
            ? paymentModeDetails.paymentModeDetails.payment_upon_enrollment
            : 0,
        paymentPerBilling:
          paymentModeDetails && paymentModeDetails.paymentModeDetails
            ? paymentModeDetails.paymentModeDetails.per_billing
            : 0,
        course: data.course_id ? data.course_id.name : "-",
        gradeLevel: data.grade_level_id ? data.grade_level_id.name : "-",
        scholarship: data.scholarship_type
          ? JSON.parse(data.scholarship_type)
          : null,
        approve_esc: data.approve_esc,
        esc_type: data.esc_type ? JSON.parse(data.esc_type) : null,
        cashier_status: data.cashier,
        application_status: data.status,
      };

      setBillings(billings);
      setPaymentApplication(details);
    } else {
      notifyError(
        "Entered reference number has no billing yet. Please contact admin"
      );
    }

    setButtonSpinner(false);
    setLoading(false);
    setUpdateTable(false);
  } catch (err) {
    console.log(err);
    if (err.response) {
      notifyError("Invalid Reference No");
      setButtonSpinner(false);
    }
    notifyError("Error while getting reference number details");
  }
}

async function getApplicationForCashierByLastName(
  body,
  setSurnames,
  setSurnamesSelection,
  setButtonSpinner
) {
  try {
    let q = body.search.trim();
    let filters = `?q=${q}&sy_from=${body.sy_from}&sy_to=${body.sy_to}&year_level=${body.gradeLevel}&semester=${body.semester}&grade_type=${body.gradeType}`;

    let res = await axios.get(ENDPOINT + `/get-refno` + filters, {
      headers: headers,
    });
    if (res.data.success) {
      let data = res.data.data.ref_no;
      if (data.length >= 1) {
        let surnamesArr = data.map((surname) => {
          return {
            value: surname.ref_no,
            label: surname.fullname + " - " + surname.ref_no.toUpperCase(),
          };
        });
        setSurnamesSelection(true);
        setSurnames(surnamesArr);
      } else {
        notifyError("Last Name not found");
      }
    }
    setButtonSpinner(false);
  } catch (err) {
    if (err.response) {
      notifyError("Error while getting Info");
      setButtonSpinner(false);
    }
  }
}
async function updateToEnrollStatus(
  dataSubmit,
  setButtonSpinner,
  setEnrollConfirmModal,
  setPaymentApplication,
  blankPaymentApplication,
  setBillings
) {
  try {
    let res = await axios.post(ENDPOINT + `/enroll-student`, dataSubmit, {
      headers: headers,
    });
    if (res.data.success) {
      if (dataSubmit.status == "certified") {
        notifySuccess("Application Revert Complete");
      } else {
        notifySuccess("Student Enrollment Complete");
      }
      setPaymentApplication(blankPaymentApplication);
      setBillings([]);
      setEnrollConfirmModal(false);
    } else {
      notifyError("Student Enrollment Incomplete, Error while updating status");
    }
    setButtonSpinner(false);
  } catch (err) {
    if (err.response) {
      notifyError("Student Enrollment Incomplete, Error while updating status");
    }
    setButtonSpinner(false);
  }
}

async function exportPaymentHistory(
  setButtonSpinner,
  setExportUrl,
  exportFilter
) {
  try {
    notifyInfo("Export Payment history in progress...");
    let res = await axios.post(
      ENDPOINT + `/payment-history-export`,
      exportFilter,
      {
        headers: headers,
      }
    );
    // setExportUrl(res.data)
    const exportUrl = res.data;
    window.location.href = exportUrl;

    notifySuccess("Export Payment History Complete");
    setButtonSpinner(false);
  } catch (err) {
    if (err.response) {
      notifyError("Error while exporting payment history");
    }
    setButtonSpinner(false);
  }
}

async function voidPaymentProof(
  dataSubmit,
  setButtonSpinner,
  setUpdateTxnOpen,
  updateTable,
  setUpdateTable
) {
  try {
    let res = await axios.post(ENDPOINT + `/void-payment-proof`, dataSubmit, {
      headers: headers,
    });
    if (res.data.success) {
      notifySuccess(res.data.message);
      setUpdateTable(!updateTable);
      setUpdateTxnOpen(false);
    } else {
      notifyError("Error while updating payment proof.");
    }
    setButtonSpinner(false);
  } catch (err) {
    if (err.response) {
      notifyError("Error while updating payment proof");
    }
    setButtonSpinner(false);
  }
}
