import React, { useEffect, useState } from "react";
import {
  Container,
  Input,
  Spinner,
  FormGroup,
  Col,
  Button,
  Form,
  Row,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Loading from "../Forms/Loading.js";
import { useForm, Controller } from "react-hook-form";
import { paymentActions } from "../../../services/PaymentServices";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import overlayFactory from "react-bootstrap-table2-overlay";
import { isMobile } from "react-device-detect";
import Paid from "../../../assets/img/paid.png";
import File from "../../../assets/img/UPLOAD.png";
import CashierPaidConfirm from "./CashierPaidConfirm";
import CashierUpdateTxn from "./CashierUpdateTxn";
import Select from "react-select";
import optionList from "./optionList.js";

const StudentBillingModal = ({
  paymentApplication,
  billings,
  setBillings,
  toggleUploadProofOfPayment,
  studentPaymentModal,
  action,
  updateTable,
  setUpdateTable,
}) => {
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const { register, handleSubmit, errors, control, setValue, getValues } = useForm();
  const [remarksModal, setRemarksModal] = useState(false);
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const role = localStorage.getItem('role') ? localStorage.getItem('role') : ""

  const handleEnrollApplication = () => {
    if (!remarksModal) {
      setRemarksModal(!remarksModal);
    } else {
      setRemarksModal(!remarksModal);
    }
  };

  const NoDataIndication = () => <div>No Data</div>;

  const Cashier = ['Cashier', "Administrator"];

  const columns = [
    {
      dataField: "transaction_number",
      text: "Transaction Number",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
      formatter: (cell) => {
        return cell.toUpperCase();
      },
    },
    {
      dataField: "billing",
      text: "Description",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
      formatter: (cell) => {
        let billing = "";
        if (billings.length >= 1) {
          billings.map((d) => {
            if (d.billing == cell) {
              if (!d.billing && !d.payment_due) {
                billing = "Downpayment";
              } else {
                billing = "Billing " + d.billing;
              }
            }
          });
        }
        return billing;
      },
    },
    {
      dataField: "payment_due",
      text: "Payment Due Date",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
      formatter: (cell) => {
        return cell ? cell : "-";
      },
    },
    {
      dataField: "total_amount",
      text: "Total Amount",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "status",
      text: "Status",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "id",
      text: "Action",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
      formatter: (cell) => {
        return (
          <Row className="justify-content-center w-100">
            <Col md="12" xs="12">
                <Button
                  id="view-btn"
                  onClick={() => toggleUploadFile(cell)}
                  className="btn-warning"
                >
                  <img
                    src={File}
                    style={{ height: "1.5rem", objectFit: "fill" }}
                  />
              </Button>
            </Col>
          </Row>
        );
      },
    },
  ];

  const filteredBillings = billings.filter((d) => {
    return d.transaction_number
      .toLowerCase()
      .includes(searchText.toLowerCase());
  });

  const [statusModalChange, setStatusModalChange] = useState(false);
  const [billingId, setBillingId] = useState("");
  const togglePaidStatus = (id) => {
    setBillingId("");
    if (!statusModalChange) {
      setBillingId(id);
      setStatusModalChange(!statusModalChange);
    } else {
      setStatusModalChange(!statusModalChange);
    }
  };

  const [updateTxnOpen, setUpdateTxnOpen] = useState(false);
  const toggleUploadFile = (id) => {
    setBillingId("");
    if (!updateTxnOpen) {
      setBillingId(id);
      setUpdateTxnOpen(!updateTxnOpen);
    } else {
      setUpdateTxnOpen(!updateTxnOpen);
    }
  };

  return (
    <>
      {updateTxnOpen ? (
        <CashierUpdateTxn
          updateTxnOpen={updateTxnOpen}
          setUpdateTxnOpen={setUpdateTxnOpen}
          toggleUploadFile={toggleUploadFile}
          billingId={billingId}
          billings={billings}
          setBillings={setBillings}
          action={'create'}
          updateTable={updateTable}
          setUpdateTable={setUpdateTable}
        />
      ) : (
        ""
      )}
      <Modal
       isOpen={studentPaymentModal} toggle={toggleUploadProofOfPayment} size="xl"
      >
          <ModalBody>
        {/* {loading ? <Loading /> : ""} */}
        <Row className="mb-5 justify-content-between pt-5">
          <Col md="12" xs="12">
            <div className="border border-2 border-warning py-2 px-3">
              <div className="w-100 px-2" style={{ marginTop: "-1.4rem" }}>
                <Label
                  className="text-danger-edit text-center font-weight-bolder mb-1 px-3"
                  style={{ backgroundColor: "white" }}
                >
                  Billings
                </Label>
              </div>
              <BootstrapTable
                wrapperClasses="table-responsive my-4 text-center"
                bootstrap4
                striped
                keyField="id"
                data={billings}
                columns={columns}
                loading={loading}
                classes={isMobile ? "mobileTable" : "desktopTable"}
                pagination={paginationFactory()}
                overlay={overlayFactory({
                  spinner: true,
                  styles: {
                    overlay: (base) => ({
                      ...base,
                      background: "rgba(0, 0, 0, 0.5)",
                    }),
                  },
                })}
                noDataIndication={() => <NoDataIndication />}
              />
            </div>
          </Col>
        </Row>
          </ModalBody>
          <ModalFooter>
              <Button color="secondary" onClick={toggleUploadProofOfPayment}>Cancel</Button>
          </ModalFooter>
      </Modal>
    </>
  );
};

export default StudentBillingModal;
