export const sidebarMenu = [
  {
    title: "Applications",
    children: ["Applications", "Admission"],
  },
  {
    title: "Student Portal",
    children: [
      "Student Portal",
      "Students",
      "Student Sections",
      "Student Report",
    ],
  },
  {
    title: "Payments",
    children: ["Payment", "Payment History"],
  },
  {
    title: "Admin",
    children: [
      "Users",
      "User Roles",
      "Subject Lists",
      "Education Types",
      "Grade Types",
      "Grade Levels",
      "Teacher Subjects",
      "School Fees",
      "Sections",
      "Courses",
      "Global Filters",
      "Import Grades",
    ],
  },
  {
    title: "Web Page",
    children: [
      "Homepage",
      "AboutUs",
      "Academics",
      "Admission Requirements",
      "Admission Scholarships",
      "Footer",
    ],
  },
  {
    title: "Student Grading",
    children: ["Student Grading"],
  },
];
