import { ENDPOINT, S3_URL } from "../constants/api";
import axios from "axios";
import { notifyError, notifySuccess } from "./Toaster";
import moment from "moment";

export const dynamicScholarshipsActions = {
  saveTemplate,
  getCurrentTemplate,
  getSelectedTemplate,
  publishTemplate,
  getCurrentTemplateFrontPage
  // updateUser
};

const token = localStorage.getItem("jwtToken")
  ? localStorage.getItem("jwtToken")
  : "";
const headers = {
  Authorization: `Bearer ${token}`,
  "Content-Type": "application/json",
  "X-Requested-With": "XMLHttpRequest",
};

async function saveTemplate(data, setLoading, setButtonSpinner, setButtonSpinner2) {
  try {
    let res = await axios.post(ENDPOINT + `/dynamic-scholarships`, data, {
      headers: headers,
    });

    notifySuccess("Successfully created new template");
    setLoading(false)
    setButtonSpinner(false)
    setButtonSpinner2(false)
    setTimeout(() => window.location.reload(), 2000);
  } catch (err) {
    if (err.response && err.response.data && err.response.data.error) {
      notifyError(err.response.data.error);
    } else if (err.response) {
      notifyError("Error while saving template");
    }
    setLoading(false)
    setButtonSpinner(false)
    setButtonSpinner2(false)
  }
}

async function getCurrentTemplate(
  setScholarships,
  scholarships,
  setCurrentVersion,
  setSelectedVersion,
  setLoading,
  setVersionSelection,
  scholarshipImage
) {
  try {
    let res = await axios.get(ENDPOINT + `/dynamic-scholarships`, {
      headers: headers,
    });

    const versions = res.data.versions;
    const scholarshipsData = res.data.current.scholarships;
    setCurrentVersion(res.data.current.version)
    setVersionSelection(versions)
    setSelectedVersion(res.data.current.version)

    if (scholarshipsData.baseImage.includes("static/media")) {
      scholarshipsData.baseImage = scholarshipImage
    }

    setScholarships(scholarshipsData)



    setLoading(false)
  } catch (err) {
    if (err.response) {
      notifyError("Error while getting latest template.");
    }
    setLoading(false)
  }
}

async function getSelectedTemplate(
  setScholarships,
  scholarships,
  setCurrentVersion,
  selectedVersion,
  setLoading,
  setVersionSelection,
  scholarshipImage
) {
  try {
    let res = await axios.get(ENDPOINT + `/get-dynamic-scholarships/${selectedVersion}`, {
      headers: headers,
    });

    let scholarshipsData = res.data.scholarships
    if (scholarshipsData.baseImage.includes("static/media")) {
      scholarshipsData.baseImage = scholarshipImage
    }

    setScholarships(scholarshipsData)

    setLoading(false)
  } catch (err) {
    if (err.response) {
      notifyError("Error while getting template.");
    }
    setLoading(false)
  }
}

async function publishTemplate(selectedVersion, setButtonSpinner, setLoading, setButtonSpinner2) {
  try {
    let res = await axios.get(ENDPOINT + `/dynamic-scholarships-publish-template/${selectedVersion}`, {
      headers: headers,
    });

    setButtonSpinner(false)
    notifySuccess("Successfully published new version");
    setTimeout(() => window.location.reload(), 2000);
  } catch (err) {
    if (err.response) {
      notifyError("Error while publishing new version.");
    }
    setButtonSpinner(false)
  }
}

async function getCurrentTemplateFrontPage(
  setScholarships,
  setLoading,
  scholarshipImage
) {
  try {
    let res = await axios.get(ENDPOINT + `/dynamic-scholarships-frontpage`, {
      headers: headers,
    });

    const scholarshipsData = res.data.current.scholarships;
    if (scholarshipsData.baseImage.includes("static/media")) {
      scholarshipsData.baseImage = scholarshipImage
    }

    setScholarships(scholarshipsData)
    setLoading(false)
  } catch (err) {
    if (err.response) {
      notifyError("Error while getting latest template.");
    }
    setLoading(false)
  }
}
