import React, { useState } from 'react';
import { Button, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap';
import optionList from "../Admission/optionList.js";
import moment from "moment";
import { studentActions } from "../../../services/StudentPortalServices";

const ViewStudentDetails = ({viewDetails, details, toggleViewDetails}) => {
    const [buttonSpinner, setButtonSpinner] = useState(false)
    const getOptionLabelByValue = (options, value) => {
        const option = options.find((option) => option.value == value);
        return option ? option.label : "";
    };

    const toggleDownload = () => {
        setButtonSpinner(true)
        let dataToSubmit = {
            application_id: details.id
        }
        studentActions.downloadStudentDetails(dataToSubmit, setButtonSpinner)
    }
    
    return (
        <Modal isOpen={viewDetails} toggle={toggleViewDetails} size="lg" backdrop="static">
        <ModalHeader toggle={toggleViewDetails} className="bg-danger-edit text-white" charCode="">Student Information</ModalHeader>
        <ModalBody style={{overflow: "auto"}} className="mr-3">
            <Row>
              <Col md="12" xs="12" className="mb-2 text-right">
                <Button className="bg-danger-edit border-0 text-white shadow-sm" onClick={toggleDownload} disabled={buttonSpinner}>
                    {buttonSpinner ? (
                        <>
                            <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            &nbsp;Processing...
                        </>
                        ) : (
                        "Download Details"
                    )}
                </Button>
              </Col>
          </Row>
          <Row>
              <Col md="6" xs="6">
                  <Label>Reference Number:</Label>
              </Col>
              <Col md="6" xs="6">
                  <Label>{details.ref_no.toUpperCase()}</Label>
              </Col>
          </Row>
          <Row>
              <Col md="6" xs="6">
                  <Label>Student Id:</Label>
              </Col>
              <Col md="6" xs="6">
                  <Label>{details.student_id !== "" ? details.student_id : "N/A"}</Label>
              </Col>
          </Row>
          <Row>
              <Col md="6" xs="6">
                  <Label>Last Name:</Label>
              </Col>
              <Col md="6" xs="6">
                  <Label>{details.last_name}, {details.given_name} {details.suffix}</Label>
              </Col>
          </Row>
          <Row>
              <Col md="6" xs="6">
                  <Label>Grade Level:</Label>
              </Col>
              <Col md="6" xs="6">
                  <Label>{details.grade_level}</Label>
              </Col>
          </Row>
          <Row>
              <Col md="6" xs="6">
                  <Label>Email Address:</Label>
              </Col>
              <Col md="6" xs="6">
                  <Label>{details.email_address}</Label>
              </Col>
          </Row>
          <Row>
              <Col md="6" xs="6">
                  <Label>Birth Date:</Label>
              </Col>
              <Col md="6" xs="6">
                  <Label>{moment(details.birth_date).format("MMMM DD, YYYY")}</Label>
              </Col>
          </Row>
          <Row>
              <Col md="6" xs="6">
                  <Label>Birth Place:</Label>
              </Col>
              <Col md="6" xs="6">
                  <Label>{details.birth_place}</Label>
              </Col>
          </Row>
          <Row>
              <Col md="6" xs="6">
                  <Label>Gender:</Label>
              </Col>
              <Col md="6" xs="6">
                  <Label>{details.gender}</Label>
              </Col>
          </Row>
          <Row>
              <Col md="6" xs="6">
                  <Label>Religion:</Label>
              </Col>
              <Col md="6" xs="6">
                  <Label>{details.religion}</Label>
              </Col>
          </Row>
          <Row>
              <Col md="6" xs="6">
                  <Label>LRN:</Label>
              </Col>
              <Col md="6" xs="6">
                  <Label>{details.lrn}</Label>
              </Col>
          </Row>
          <Row>
              <Col md="6" xs="6">
                  <Label>Age:</Label>
              </Col>
              <Col md="6" xs="6">
                  <Label>{details.age}</Label>
              </Col>
          </Row>
          <Row>
              <Col md="6" xs="6">
                  <Label>Civil Status:</Label>
              </Col>
              <Col md="6" xs="6">
                  <Label>{details.civil_status !== "" ? details.civil_status : "N/A"}</Label>
              </Col>
          </Row>
          <Row>
              <Col md="6" xs="6">
                  <Label>Living Status:</Label>
              </Col>
              <Col md="6" xs="6">
                  <Label>{details.living_status !== "" ? getOptionLabelByValue(
                                    optionList.living_status,
                                    details.living_status
                                  ) : "N/A"}</Label>
              </Col>
          </Row>
          <Row>
              <Col md="6" xs="6">
                  <Label>No of Siblings:</Label>
              </Col>
              <Col md="6" xs="6">
                  <Label>{details.no_of_siblings !== "" ? details.no_of_siblings : "N/A"}</Label>
              </Col>
          </Row>

          <h3 className="text-danger-edit mt-3">Home Address Details</h3>
          <Row>
              <Col md="6" xs="6">
                  <Label>Province:</Label>
              </Col>
              <Col md="6" xs="6">
                  <Label>{details.province}</Label>
              </Col>
          </Row>
          <Row>
              <Col md="6" xs="6">
                  <Label>City/Municipality:</Label>
              </Col>
              <Col md="6" xs="6">
                  <Label>{details.city_municipality}</Label>
              </Col>
          </Row>
          <Row>
              <Col md="6" xs="6">
                  <Label>Street:</Label>
              </Col>
              <Col md="6" xs="6">
                  <Label>{details.house_bldg_street}</Label>
              </Col>
          </Row>
          <Row>
              <Col md="6" xs="6">
                  <Label>Barangay:</Label>
              </Col>
              <Col md="6" xs="6">
                  <Label>{details.barangay}</Label>
              </Col>
          </Row>
          {details.education_type == "Basic Education" ? (
                    <>
                      <h3 className="text-danger-edit mt-3">Health Information</h3>

                      <Row className="mb-5">
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              Do you have any health problems now?{" "}
                            </span>{" "}
                            {details.health_student_boolean.label}
                          </Label>
                        </Col>
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              Specific health problem:{" "}
                            </span>{" "}
                            {details.health_student_boolean
                              ? details.health_student_specific
                              : "NA"}
                          </Label>
                        </Col>
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              Are you consulting a doctor?{" "}
                            </span>{" "}
                            {details.consulting_doctor.label}
                          </Label>
                        </Col>
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              What important illness did you have in the past?{" "}
                            </span>{" "}
                            {details.important_illness}
                          </Label>
                        </Col>
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              Have you been hospitalized in the past?{" "}
                            </span>{" "}
                            {details.hospitalized_in_past.label}
                          </Label>
                        </Col>
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              if yes, indicate the reason and when?{" "}
                            </span>{" "}
                            {details.hospitalized_in_past
                              ? details.specific_hospitalized_in_past
                              : "NA"}
                          </Label>
                        </Col>
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              Do you have any difficulty in, or problems of the
                              following?{" "}
                            </span>{" "}
                            {JSON.stringify(details.difficulty)}
                          </Label>
                        </Col>
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              In your family (blood relations those living in
                              the household) has anyone been sick on the
                              following?{" "}
                            </span>{" "}
                            {details.family_health_history.label}
                          </Label>
                        </Col>
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              If yes, who among them and what sickness?{" "}
                            </span>{" "}
                            {details.family_health_history
                              ? details.family_member_with_sickness
                              : "NA"}
                          </Label>
                        </Col>
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              What vaccination did you have?{" "}
                            </span>{" "}
                            {details.vaccination}
                          </Label>
                        </Col>
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              How do you evaluate yourself?{" "}
                            </span>{" "}
                            {details.self_evaluation.label}
                          </Label>
                        </Col>
                      </Row>

                      <h3 className="text-danger-edit mt-3">Additional Information</h3>
                      <Row className="mb-5">
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              Who is financially supporting your studies?{" "}
                            </span>{" "}
                            {details.financial_support.label}
                          </Label>
                        </Col>
                        {details.financial_support.value == "6" ? (
                          <Col md="12" xs="12">
                            <Label>
                              <span className="font-weight-bolder">
                                Other:{" "}
                              </span>{" "}
                              {details.other_financial_support}
                            </Label>
                          </Col>
                        ) : (
                          ""
                        )}
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              Area where you grew up:{" "}
                            </span>{" "}
                            {details.grew_up.label}
                          </Label>
                        </Col>
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              First language / dialect spoken at home:{" "}
                            </span>{" "}
                            {details.language_home.label}
                          </Label>
                        </Col>
                        {details.language_home.value == "3" ? (
                          <Col md="12" xs="12">
                            <Label>
                              <span className="font-weight-bolder">
                                Other:{" "}
                              </span>{" "}
                              {details.other_language_home}
                            </Label>
                          </Col>
                        ) : (
                          ""
                        )}
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              Which of the following concerns you most?{" "}
                            </span>{" "}
                            {details.concerns.label}
                          </Label>
                        </Col>
                        {details.concerns.value == "9" ? (
                          <Col md="12" xs="12">
                            <Label>
                              <span className="font-weight-bolder">
                                Other:{" "}
                              </span>{" "}
                              {details.other_concern}
                            </Label>
                          </Col>
                        ) : (
                          ""
                        )}
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              How do you usually deal with problem?{" "}
                            </span>{" "}
                            {details.problem_solution.label}
                          </Label>
                        </Col>
                        {details.concerns.value == "5" ? (
                          <Col md="12" xs="12">
                            <Label>
                              <span className="font-weight-bolder">
                                Other:{" "}
                              </span>{" "}
                              {details.other_problem_solution}
                            </Label>
                          </Col>
                        ) : (
                          ""
                        )}
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              How do you cope up with stressful situation?{" "}
                            </span>{" "}
                            {details.cope_up_stress.label}
                          </Label>
                        </Col>
                        {details.cope_up_stress.value == "7" ? (
                          <Col md="12" xs="12">
                            <Label>
                              <span className="font-weight-bolder">
                                Other:{" "}
                              </span>{" "}
                              {details.other_cope_up_stress}
                            </Label>
                          </Col>
                        ) : (
                          ""
                        )}
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              How do you cope up with stressful situation?{" "}
                            </span>{" "}
                            {details.how_to_know_sapc.label}
                          </Label>
                        </Col>
                        {details.how_to_know_sapc.value == "7" ? (
                          <Col md="12" xs="12">
                            <Label>
                              <span className="font-weight-bolder">
                                Other:{" "}
                              </span>{" "}
                              {details.other_how_to_know_sapc}
                            </Label>
                          </Col>
                        ) : (
                          ""
                        )}
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              How did you get to know about San Antonio de Padua
                              College?{" "}
                            </span>{" "}
                            {details.how_to_know_sapc.label}
                          </Label>
                        </Col>
                        {details.how_to_know_sapc.value == "7" ? (
                          <Col md="12" xs="12">
                            <Label>
                              <span className="font-weight-bolder">
                                Other:{" "}
                              </span>{" "}
                              {details.other_how_to_know_sapc}
                            </Label>
                          </Col>
                        ) : (
                          ""
                        )}
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              Are you a part of Listahan (4P`s) ?{" "}
                            </span>{" "}
                            {details.listahan.label}
                          </Label>
                        </Col>
                        {details.listahan.value ? (
                          <Col md="12" xs="12">
                            <Label>
                              <span className="font-weight-bolder">
                                DSWD Household Number:{" "}
                              </span>{" "}
                              {details.household_number}
                            </Label>
                          </Col>
                        ) : (
                          ""
                        )}
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              Are you a person with Disability?{" "}
                            </span>{" "}
                            {details.disability.label}
                          </Label>
                        </Col>
                        {details.disability.value ? (
                          <Col md="12" xs="12">
                            <Label>
                              <span className="font-weight-bolder">
                                PWD number:{" "}
                              </span>{" "}
                              {details.pwd_number}
                            </Label>
                          </Col>
                        ) : (
                          ""
                        )}
                        <Col md="12" xs="12">
                          <Label>
                            <span className="font-weight-bolder">
                              Are you a currently recipient of a government
                              subsidy from DEPED/CHED?{" "}
                            </span>{" "}
                            {details.subsidy.label}
                          </Label>
                        </Col>
                        {details.subsidy.value ? (
                          <Col md="12" xs="12">
                            <Label>
                              <span className="font-weight-bolder">
                                If yes, please write the details:{" "}
                              </span>{" "}
                              {details.subsidy_details}
                            </Label>
                          </Col>
                        ) : (
                          ""
                        )}
                      </Row>
                    </>
                  ) : (
                    ""
                  )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleViewDetails}>Close</Button>
        </ModalFooter>
      </Modal>
    )
}
export default ViewStudentDetails;