import React from 'react';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Row } from 'reactstrap';

const Notice = ({
    handleCancel,
    currentStep,
    setCurrentStep
}) => {

    const handlePreviousBtn = () => {
        setCurrentStep(currentStep - 1)
    }

    const handleNext = () => {
        setCurrentStep(currentStep + 1)
    }

    return (
        <Container className='py-5'>
            <Card>
                <CardHeader>
                    <strong>Notification message</strong>
                </CardHeader>
                <CardBody style={{ lineHeight: "1.5rem" }}>
                    <p><strong>Magandang araw, Future Paduans!</strong></p>
                    <p>Thank you for choosing San Antonio De Padua College -- "The School of Choice." Your SAPCAT (San Antonio De Padua College Admission Test), we will contact you via email or phone call for your schedule.</p>
                    <p className='mb-1'>Please be reminded of the following guidelines:</p>
                    <p className='mb-1'>1. Make sure that you already filled up the form.-- <a target='_blank' href="https://sapc.edu.ph/portal/admission">https://sapc.edu.ph/portal/admission</a></p>
                    <p className='mb-1'>2. Come in at least 15 minutes before your testing schedule.</p>
                    <p className='mb-1'>3. Wear decent clothes and a face mask.</p>
                    <p className='mb-1'>4. Bring your own Mongol pencil #2, eraser, black ballpen. Snacks are also allowed.</p>
                    <p className='mb-1'>5. Secure your proof of payment from the school cashier. The test fee is PHP100.00. Payments can be made on the day of the exam.</p>
                    <p>- If you pay online (through Gcash or Bank Transfer), make sure to send a screenshot of your transaction to <a href="https://facebook.com/san.antonio.de.padua.college" target='_blank'>(facebook.com/san.antonio.de.padua.college)</a> together with your name.</p>

                    <Card className='mb-4'>
                        <CardBody>
                            <p className='mb-1'>GCASH ACCOUNT: Nelsie Maria D.</p>
                            <p className='mb-1'>GCash Number: 09276306987</p>
                            <p className='mb-1'>BPI Account Name: San Antonio De Padua College Foundation</p>
                            <p className='mb-1'>BPI Account Number: 3420003276</p>
                        </CardBody>
                    </Card>

                    <p className='mb-1'><strong>Note:</strong> Upon entering the campus, the security personnel will hand you an admission process slip that will serve as your guide in the admission process.</p>
                    <p>Please fill out the information needed before entering the testing center.</p>

                    <p>See you and be part of the caring SAPC family. Good luck!</p>

                    <p>Hanggang sa muli,</p>
                    <h5><strong>SAPCares</strong></h5>
                    <p>"Shaping careers, Improving lives"</p>
                    <Row className="mt-5 justify-content-center text-center">
                        <Col md="6" xs="12" className="text-center w-100">
                            <Button type="button" onClick={handlePreviousBtn} className="mb-2 bg-white border-warning text-warning-edit rounded-pill py-3 px-5 font-weight-bolder">Previous</Button>
                            <Button onClick={handleCancel} className="bg-white border-0 text-dark rounded-pill py-3 mb-2 px-5 font-weight-bolder">Cancel</Button>
                        </Col>

                        <Col md="6" xs="12" className="text-center w-100">
                            <Button onClick={handleNext} className="bg-warning text-white rounded-pill border-0 py-3 mb-2 px-5 font-weight-bolder">Next</Button>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Container>
    )
}

export default Notice;