import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  InputGroup,
  InputGroupAddon,
  Row,
  Input,
  UncontrolledTooltip,
  Spinner,
} from "reactstrap";
import { teacherActions } from "../../../services/TeacherServices";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import overlayFactory from "react-bootstrap-table2-overlay";
import Edit from "../../../assets/img/edit.png";
import View from "../../../assets/img/view.png";
import { isBrowser, isDesktop, isMobile } from "react-device-detect";
import Select from "react-select";
import ViewStudents from "./ViewStudents";
import MobileSideBar from "../Admission/MobileSidebar";

const StudentsGrading = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [updateTable, setUpdateTable] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [gradeLevelFilter, setGradeLevelFilter] = useState("");
  const [sectionFilter, setSectionFilter] = useState("");
  const [studentGrade, setStudentGrade] = useState([]);
  const [gradingId, setGradingId] = useState("");
  const blankDateFilters = {
    syFrom: "",
    syTo: "",
  };
  const [filters, setFilters] = useState(blankDateFilters);

  const blankSubject = {
    id: "",
    subject_id: "",
    semester: "",
    section: "",
    grading_period: "",
  };
  const [subjectStudents, setSubjectStudents] = useState(blankSubject);

  useEffect(() => {
    setLoading(true);
    teacherActions.getSubjects(setData, setLoading);
  }, [updateTable]);

  const columns = [
    {
      dataField: "label",
      text: "Subject",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "grade_level_name",
      text: "Grade Level",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "section_name",
      text: "Section",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "semester",
      text: "Semester",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
      formatter: (cell) => {
        return cell === 0 ? "First" : cell === 1 ? "Second" : "-";
      },
    },
    {
      dataField: "value",
      text: "Action",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
      formatter: (cell, row) => {
        return (
          <div
            className="px-2 justify-content-center d-flex"
            style={{ minWidth: "50px" }}
          >
            <Button
              id={"upload-btn" + cell}
              onClick={() => toggleUpdateGrade(row, "edit")}
              className="btn-warning mr-2"
            >
              <img src={Edit} style={{ height: "1.5rem", objectFit: "fill" }} />
            </Button>
            <UncontrolledTooltip placement="top" target={"upload-btn" + cell}>
              Edit Grades
            </UncontrolledTooltip>
          </div>
        );
      },
    },
  ];

  const [updateGradeModal, setUpdateGradeModal] = useState(false);
  const toggleUpdateGrade = (data) => {
    setSubjectStudents(blankSubject);
    setFilters(blankDateFilters);
    setStudentGrade([]);
    setGradingId("");
    if (!updateGradeModal) {
      setSubjectStudents({
        subject_id: data.value,
        subject_name: data.label,
        semester: data.semester,
        section_id: data.section_id,
        section_name: data.section_name,
        course_type_id: data.course_type_id,
        grading_period: "",
        grade_type_id: data.grade_type_id,
        grade_level_id: data.grade_level_id,
      });
      setUpdateGradeModal(!updateGradeModal);
    } else {
      setUpdateGradeModal(!updateGradeModal);
    }
  };

  const changeQuery = (e) => {
    setSearchText(e.target.value);
  };

  const NoDataIndication = () => <div>No Data</div>;

  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (data.length >= 1) {
      let newArr = [];
      //search INPUT
      let filteredSubjects = data.filter((d) => {
        return d.label.toLowerCase().includes(searchText.toLowerCase());
      });

      newArr = filteredSubjects;
      //filter
      if (sectionFilter !== "") {
        let filteredSection = newArr.filter((f) => {
          return f.id == sectionFilter;
        });

        newArr = filteredSection;
      }

      if (gradeLevelFilter !== "") {
        let filteredGradeLevels = newArr.filter((f) => {
          return f.year_level_id == gradeLevelFilter;
        });

        newArr = filteredGradeLevels;
      }

      setFilteredData(newArr);
    }
  }, [gradeLevelFilter, sectionFilter, searchText, data]);

  return (
    <div className="p-4">
      <ViewStudents
        updateGradeModal={updateGradeModal}
        setUpdateGradeModal={setUpdateGradeModal}
        toggleUpdateGrade={toggleUpdateGrade}
        subjectStudents={subjectStudents}
        studentGrade={studentGrade}
        setStudentGrade={setStudentGrade}
        gradingId={gradingId}
        setGradingId={setGradingId}
        filters={filters}
        setFilters={setFilters}
      />

      <Row>
        <Col md="4" xs="12" className="ml-auto">
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <Button type="button" color="primary" id="search-button">
                <i className="fa fa-search"></i>
              </Button>
            </InputGroupAddon>
            <Input
              type="text"
              id="search-text"
              name="search-text"
              placeholder="Search"
              onChange={changeQuery}
            />
          </InputGroup>
          <p>
            <small className="font-weight-bolder">
              <i className="fa fa-lightbulb-o"></i>&nbsp;Search by Subject
            </small>
          </p>
        </Col>
      </Row>
      <BootstrapTable
        wrapperClasses="table-responsive mb-3 text-center"
        bootstrap4
        striped
        keyField="value"
        data={filteredData}
        columns={columns}
        loading={loading}
        classes={isMobile ? "mobileTable" : "desktopTable"}
        pagination={paginationFactory()}
        overlay={overlayFactory({
          spinner: true,
          styles: {
            overlay: (base) => ({
              ...base,
              background: "rgba(0, 0, 0, 0.5)",
            }),
          },
        })}
        noDataIndication={() => <NoDataIndication />}
      />
    </div>
  );
};

export default StudentsGrading;
