import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardTitle,
  CardText,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import StudentCor from "./StudentCor";
import classnames from "classnames";
import StudentInfo from "./StudentInfo";
import { isMobile } from "react-device-detect";
import { studentActions } from "../../../services/StudentPortalServices";
import StudentGrade from "./StudentGrade";

const StudentPortalModal = ({
  viewDetails,
  setViewDetails,
  toggleViewDetails,
  details,
}) => {
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const toggleDownload = (type) => {
    setButtonSpinner(true);
    let dataToSubmit = {
      application_id: details.id,
    };
    if (type == "cor") {
      studentActions.downloadCor(dataToSubmit, setButtonSpinner);
    } else if (type == "aof") {
      studentActions.downloadAof(dataToSubmit, setButtonSpinner);
    }
  };

  const toggleDownloadSchedule = (link) => {
    const exportUrl = link;
    let win = window.open(exportUrl, "_blank");
    win.focus();
  };

  return (
    <Modal
      isOpen={viewDetails}
      toggle={toggleViewDetails}
      backdrop="static"
      size="lg"
    >
      <ModalHeader toggle={toggleViewDetails}>Student Details</ModalHeader>
      <ModalBody>
        <Nav
          tabs
          style={{
            flexWrap: isMobile ? "unset" : "wrap",
            overflow: isMobile ? "auto" : "",
          }}
        >
          <NavItem>
            <NavLink
              style={{ width: "max-content" }}
              className={classnames({ active: activeTab === "1" })}
              onClick={() => {
                toggle("1");
              }}
            >
              Information
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ width: "max-content" }}
              className={classnames({ active: activeTab === "2" })}
              onClick={() => {
                toggle("2");
              }}
            >
              Schedules
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ width: "max-content" }}
              className={classnames({ active: activeTab === "3" })}
              onClick={() => {
                toggle("3");
              }}
            >
              Grades
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ width: "max-content" }}
              className={classnames({ active: activeTab === "4" })}
              onClick={() => {
                toggle("4");
              }}
            >
              Certificate of Registration
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ width: "max-content" }}
              className={classnames({ active: activeTab === "5" })}
              onClick={() => {
                toggle("5");
              }}
            >
              Assessment of Fees
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <StudentInfo details={details} />
          </TabPane>
          <TabPane tabId="2">
            <Row className="py-5">
              <Col md="12" xs="12">
                <img
                  src={details.scheduleImage}
                  onClick={() => toggleDownloadSchedule(details.scheduleImage)}
                  style={{ cursor: "pointer" }}
                  className="w-100"
                />
                {details.scheduleImage ? (
                  <p className="text-secondary-edit">Click image to view</p>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="3">
            <Row className="py-5">
              <Col md="12" xs="12">
                <StudentGrade activeTab={activeTab} details={details} />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="4">
            <Row className="py-5">
              <Col md="12" xs="12" className="text-center">
                <Button
                  color="warning"
                  onClick={() => toggleDownload("cor")}
                  disabled={buttonSpinner}
                >
                  {buttonSpinner ? (
                    <>
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      &nbsp;Processing...
                    </>
                  ) : (
                    "Download COR"
                  )}
                </Button>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="5">
            <Row className="py-5">
              <Col md="12" xs="12" className="text-center">
                <Button
                  color="warning"
                  onClick={() => toggleDownload("aof")}
                  disabled={buttonSpinner}
                >
                  {buttonSpinner ? (
                    <>
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      &nbsp;Processing...
                    </>
                  ) : (
                    "Download AOF"
                  )}
                </Button>
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </ModalBody>
      <ModalFooter>
        <Button
          style={{ backgroundColor: "#800000", borderColor: "#800000" }}
          onClick={toggleViewDetails}
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default StudentPortalModal;
