import React, { Fragment, useEffect, useState } from "react";
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  NavbarText,
  Container,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  ModalHeader,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import SapcLogo from "../../../assets/img/SAPC-Logo-red.png";
import LoginImage from "../../../assets/img/LoginImage.jpg";
import Mobile from "../../../assets/img/mobile.jpg";
import "../../../App.css";
import optionList from "./optionList.js";
import { educationActions } from "../../../services/EducationSelectionService.js";
import AdmissionModal from "./AdmissionModal.js";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { defineLocale } from "moment";
import { Stepper, Step } from "react-form-stepper";
import BasicEducationForm from "../Forms/BasicEducationForm.js";
import TertiaryEducationForm from "../Forms/TertiaryEducationForm.js";
import TesdatEducationForm from "../Forms/TesdaEducationForm";
import ScholarshipForm from "../Forms/ScholarshipForm.js";
import PaymentForm from "../Forms/PaymentForm.js";
import AssessmentForm from "../Forms/AssessmentForm.js";
import ReviewSubmit from "../Forms/ReviewSubmit.js";
import Loading from "../Forms/Loading.js";
import { isMobile, isBrowser, isDesktop, isTablet } from "react-device-detect";
import NavbarMobileDashboard from "./NavbarMobileDashboard.js";
import Subjects from "../Forms/Subjects";
import MyApplication from "./MyApplication";
import ApplicationView from "./ApplicationView";
import TertiaryAssessmentForm from "../Forms/TertiaryAssessmentForm.js";
import CashierView from "./CashierView";
import PaymentHistory from "./PaymentHistory";
import EnrolledStudents from "../Registrar/EnrolledStudents";
import StudentSections from "../Registrar/StudentSections";
import StudentSchedules from "../Registrar/StudentSchedules";
import StudentsGrading from "../Teacher/StudentsGrading";
import Users from "../Users/Users";
import StudentPortal from "../StudentPortal/StudentPortal";
import AdminEducationType from "../AdminPage/EducationType";
import AdminGradeType from "../AdminPage/GradeType";
import AdminGradeLevel from "../AdminPage/GradeLevel";
import TeacherSubjects from "../AdminPage/TeacherSubjects/TeacherSubjects";
import UserRole from "../UserRole/UserRole";
import SubjectsList from "../AdminPage/Subjects/Subjects";
import Fees from "../AdminPage/Fees/Fees";
import Sections from "../AdminPage/Sections/Sections";
import Courses from "../AdminPage/Courses/Courses";
import PrivateRoute from "../Login/PrivateRoute.js";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useNavigate,
} from "react-router-dom";
import states from "../States/States";
import * as AiIcons from "react-icons/ai";
import { sidebarData } from "../../../components/SidebarData.js";

const MobileSideBar = () => {
  const [educationTypes, setEducationTypes] = useState([]);
  const [studentTypes, setStudentTypes] = useState([]);
  const [gradeTypes, setGradeTypes] = useState([]);
  const [gradeLevels, setGradeLevels] = useState([]);
  const [courses, setCourses] = useState([]);
  const [modal, setModal] = useState(false);
  const [educ, setEduc] = useState("");
  const [subjects, setSubjects] = useState([]);
  const [allSubjects, setAllSubjects] = useState([]);
  const [educationSelected, setEducationSelected] = useState(false);
  const [selectedEducationType, setSelectedEducationType] = useState("");
  const [currentStep, setCurrentStep] = useState(0);
  const [currentPage, setCurrentPage] = useState(
    localStorage.getItem("currentPage")
  );
  const [loading, setLoading] = useState(false);
  const [navbarMobile, setNavbarMobile] = useState(false);
  const admissionAnswers = localStorage.getItem("admissionAnswers")
    ? JSON.parse(localStorage.getItem("admissionAnswers"))
    : "";
  const role = localStorage.getItem("role") ? localStorage.getItem("role") : "";
  const email = localStorage.getItem("email")
    ? localStorage.getItem("email")
    : "";
  const [tesdaEducationSelected, setTesdaEducationSelected] = useState(false);
  const navigate = useNavigate();

  let selectedStyle =
    "border-0 rounded bg-danger-edit text-white font-weight-bolder shadow mx-2";
  let unselectedStyle = "border-0 rounded bg-light-edit mx-2";

  const Application = ["Guest", "Student", "Assesor", "Administrator"];
  const Admission = ["Guest", "Student", "Administrator"];
  const Cashier = ["Cashier", "Administrator"];
  const Registrar = ["Registrar", "Administrator"];
  const Teacher = ["Teacher"];
  const Administrator = ["Administrator"];

  const toggleLogOut = () => {
    localStorage.clear();
    sessionStorage.clear();
    setCurrentPage("");
    navigate("/login");
  };

  const toggleHome = () => {
    localStorage.removeItem("currentPage");
    navigate("/");
  };

  const toggleSideBar = (sidebar) => {
    localStorage.setItem("currentPage", sidebar.title);
    navigate(sidebar.path);
  };

  const handleOpenNav = () => {
    setNavbarMobile(true);
  };

  return (
    <>
      <Row>
        {navbarMobile ? (
          <NavbarMobileDashboard setNavbarMobile={setNavbarMobile} />
        ) : (
          ""
        )}
      </Row>
      <Row>
        {isBrowser ? (
          <Col md="12" className="px-0 bg-light-edit pr-3">
            <div
              style={{ position: "sticky", top: "0", minHeight: "98vh" }}
              className="d-flex flex-column align-items-center w-100 pt-3 mt-3 px-0 mx-0 text-center"
            >
              <img
                src={SapcLogo}
                style={{ width: "4rem", cursor: "pointer" }}
                onClick={toggleHome}
              />
              <ListGroup
                className="pt-4 py-4 w-100 text-left"
                style={{ height: "70vh", overflow: "auto" }}
              >
                {sidebarData.map((sidebar, key) => {
                  return (
                    <div key={key}>
                      {sidebar.access.includes(role) ? (
                        <ListGroupItem
                          className={
                            currentPage == sidebar.title
                              ? selectedStyle
                              : unselectedStyle
                          }
                          onClick={() => toggleSideBar(sidebar)}
                        >
                          {sidebar.icon} {sidebar.title}
                        </ListGroupItem>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })}
              </ListGroup>
              <div
                className="w-100"
                style={{ position: "absolute", bottom: 0 }}
              >
                <div className="d-flex flex-column">
                  <small>{role == "Asssesor" ? "Assessor" : role}</small>
                  <small>{email}</small>
                </div>
                <Button
                  onClick={toggleLogOut}
                  className="text-dark border-0 rounded-0 font-weight-bolder mb-5 bg-light-edit shadow text-center"
                >
                  Logout
                </Button>
              </div>
            </div>
          </Col>
        ) : (
          <>
            {isMobile ? (
              <Row
                className="justify-content-between w-100 p-3"
                style={{ position: "absolute" }}
              >
                <Col xs="6">
                  {/* <img src={SapcLogo} style={{ width: "4rem" }} onClick={() => navigate('/')} /> */}
                </Col>
                <Col xs="6">
                  <div
                    onClick={handleOpenNav}
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "-40px",
                    }}
                  >
                    <div className="bar-menu"></div>
                    <div className="bar-menu"></div>
                    <div className="bar-menu"></div>
                  </div>
                </Col>
              </Row>
            ) : (
              ""
            )}
          </>
        )}
        {/* {isMobile ?
                    <img src={Mobile} style={{ height: "100vh", width: "100%", objectFit: "unset" }} />
                    : ""} */}
      </Row>
    </>
  );
};

export default MobileSideBar;
