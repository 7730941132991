import React, { useState } from "react";
import {
  Button,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Input,
  FormGroup,
  Form,
  Spinner,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import optionList from "../Admission/optionList.js";
import { registrarActions } from "../../../services/RegistrarServices";

const EditStudentDetails = ({
  editDetails,
  setEditDetails,
  details,
  setDetails,
  toggleEditDetails,
  updateTable,
  setUpdateTable
}) => {
  const getOptionLabelByValue = (options, value) => {
    const option = options.find((option) => option.value == value);
    return option ? option.label : "";
  };
  const admissionAnswers = localStorage.getItem("admissionAnswers")
    ? JSON.parse(localStorage.getItem("admissionAnswers"))
    : "";
    
  const studentStatusLevels = [
    "grade 11",
    "grade 12",
    "first year",
    "second year",
    "third year",
    "fourth year",
  ];
  const { register, handleSubmit, errors, control, setValue, getValues } = useForm();
  const [startDate, setStartDate] = useState("");
  const [buttonSpinner, setButtonSpinner] = useState(false);

  const onSubmit = (data) => {
    const birth_date = moment(data.birth_date).format("YYYY-MM-DD");
    data.birth_date = birth_date;
    data.civil_status = data.civil_status.value;
    data.gender = data.gender.value;
    data.living_status = data.living_status.value;
    data.student_status = data.student_status ? data.student_status.value : 0;
    setButtonSpinner(true);

    registrarActions.updateStudentDetails(data, setButtonSpinner, setEditDetails, setUpdateTable, updateTable);
  };
  return (
    <Modal isOpen={editDetails} toggle={toggleEditDetails} size="lg" backdrop="static">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader
          toggle={toggleEditDetails}
          className="bg-danger-edit text-white"
          charCode=""
        >
          Edit Student Information
        </ModalHeader>
        <ModalBody>
          <Controller
            control={control}
            name="detail_id"
            rules={{ required: true }}
            invalid={errors.id ? true : false}
            defaultValue={details.id}
            render={({ onChange, value, onBlur, name }) => (
              <Input
                className="form-control bg-light"
                name="detail_id"
                type="hidden"
                defaultValue={details.id}
              />
            )}
          />
          <Row className="border-danger">
            <Col md="12" xs="12">
              <FormGroup>
                <Label className="text-secondary font-weight-bolder w-100">
                  Student ID
                </Label>
                <Controller
                  control={control}
                  name="student_id"
                  rules={{ required: true }}
                  invalid={errors.student_id ? true : false}
                  defaultValue={details.student_id}
                  render={({ onChange, value, onBlur, name }) => (
                    <Input
                      className="form-control bg-light"
                      name="student_id"
                      onChange={(event) => {
                        // setDetails({ ...details, student_id: event.target.value })
                        setValue("student_id", event.target.value);
                        return event.target.value;
                      }}
                      defaultValue={details.student_id}
                    />
                  )}
                />
                {errors.student_id && (
                  <div
                    style={{
                      marginTop: "0.25rem",
                      fontSize: "80%",
                      color: "#f86c6b",
                    }}
                  >
                    Student Id is required!
                  </div>
                )}
              </FormGroup>
            </Col>
            {details.grade_level &&
              studentStatusLevels.includes(
                details.grade_level.toLowerCase()
              ) ?
              <Col md="4" xs="12">
                <FormGroup>
                  <Label
                    htmlFor="student_status"
                    className="text-secondary font-weight-bolder"
                  >
                    Student Status
                  </Label>
                  <Controller
                    control={control}
                    name="student_status"
                    rules={{ required: true }}
                    invalid={errors.student_status ? true : false}
                    defaultValue={
                      details.student_status !== ""
                        ? {
                          value: details.student_status,
                          label: getOptionLabelByValue(
                            optionList.student_status,
                            details.student_status
                          ),
                        }
                        : ""
                    }
                    render={({ onChange, value, onBlur, name }) => (
                      <Select
                        options={optionList.student_status}
                        onChange={(e) => {
                          setValue("student_status", e);
                          // setdetails({ ...details, student_status: e })

                          return e;
                        }}
                        defaultValue={
                          details.student_status !== ""
                            ? {
                              value: details.student_status,
                              label: getOptionLabelByValue(
                                optionList.student_status,
                                details.student_status
                              ),
                            }
                            : ""
                        }
                      />
                    )}
                  />
                  {errors.student_status && (
                    <div
                      style={{
                        marginTop: "0.25rem",
                        fontSize: "80%",
                        color: "#f86c6b",
                      }}
                    >
                      Please select!
                    </div>
                  )}
                </FormGroup>
              </Col>
              : ""}
          </Row>
          <Row className="border-danger">
            <Col md="4" xs="12">
              <FormGroup>
                <Label className="text-secondary font-weight-bolder w-100">
                  Last Name
                </Label>
                <Controller
                  control={control}
                  name="last_name"
                  rules={{ required: true }}
                  invalid={errors.last_name ? true : false}
                  defaultValue={details.last_name}
                  render={({ onChange, value, onBlur, name }) => (
                    <Input
                      className="form-control bg-light"
                      name="last_name"
                      onChange={(event) => {
                        // setDetails({ ...details, last_name: event.target.value })
                        setValue("last_name", event.target.value);
                        return event.target.value;
                      }}
                      defaultValue={details.last_name}
                    />
                  )}
                />
                {errors.last_name && (
                  <div
                    style={{
                      marginTop: "0.25rem",
                      fontSize: "80%",
                      color: "#f86c6b",
                    }}
                  >
                    Last name is required!
                  </div>
                )}
              </FormGroup>
            </Col>
            <Col md="4" xs="12">
              <FormGroup>
                <Label className="text-secondary font-weight-bolder w-100">
                  First Name
                </Label>
                <Controller
                  control={control}
                  name="given_name"
                  rules={{ required: true }}
                  invalid={errors.given_name ? true : false}
                  defaultValue={details.given_name}
                  render={({ onChange, value, onBlur, name }) => (
                    <Input
                      className="form-control bg-light"
                      name="given_name"
                      onChange={(event) => {
                        // setDetails({ ...details, given_name: event.target.value })
                        setValue("given_name", event.target.value);
                        return event.target.value;
                      }}
                      defaultValue={details.given_name}
                    />
                  )}
                />
                {errors.given_name && (
                  <div
                    style={{
                      marginTop: "0.25rem",
                      fontSize: "80%",
                      color: "#f86c6b",
                    }}
                  >
                    First name is required!
                  </div>
                )}
              </FormGroup>
            </Col>
            <Col md="4" xs="12">
              <FormGroup>
                <Label className="text-secondary font-weight-bolder w-100">
                  Middle Name
                </Label>
                <Controller
                  control={control}
                  name="middle_name"
                  rules={{ required: true }}
                  invalid={errors.middle_name ? true : false}
                  defaultValue={details.middle_name}
                  render={({ onChange, value, onBlur, name }) => (
                    <Input
                      className="form-control bg-light"
                      name="middle_name"
                      onChange={(event) => {
                        // setDetails({ ...details, middle_name: event.target.value })
                        setValue("middle_name", event.target.value);
                        return event.target.value;
                      }}
                      defaultValue={details.middle_name}
                    />
                  )}
                />
                {errors.middle_name && (
                  <div
                    style={{
                      marginTop: "0.25rem",
                      fontSize: "80%",
                      color: "#f86c6b",
                    }}
                  >
                    Middle name is required!
                  </div>
                )}
              </FormGroup>
            </Col>
            <Col md="4" xs="12">
              <FormGroup>
                <Label className="text-secondary font-weight-bolder w-100">
                  Suffix
                </Label>
                <Controller
                  control={control}
                  name="suffix"
                  rules={{ required: false }}
                  invalid={errors.suffix ? true : false}
                  defaultValue={details.suffix}
                  render={({ onChange, value, onBlur, name }) => (
                    <Input
                      className="form-control bg-light"
                      name="suffix"
                      onChange={(event) => {
                        // setDetails({ ...details, suffix: event.target.value })
                        setValue("suffix", event.target.value);
                        return event.target.value;
                      }}
                      defaultValue={details.suffix}
                    />
                  )}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md="8" xs="12">
              <Label className="text-secondary font-weight-bolder">
                Birth Place
              </Label>
              <FormGroup>
                <Controller
                  control={control}
                  name="birth_place"
                  rules={{ required: true }}
                  invalid={errors.birth_place ? true : false}
                  defaultValue={details.birth_place}
                  render={({ onChange, value, onBlur, name }) => (
                    <Input
                      className="form-control bg-light"
                      name="birth_place"
                      onChange={(event) => {
                        // setDetails({ ...details, birth_place: event.target.value })
                        setValue("birth_place", event.target.value);
                        return event.target.value;
                      }}
                      defaultValue={details.birth_place}
                    />
                  )}
                />
                {errors.birth_place && (
                  <div
                    style={{
                      marginTop: "0.25rem",
                      fontSize: "80%",
                      color: "#f86c6b",
                    }}
                  >
                    Birth place is required!
                  </div>
                )}
              </FormGroup>
            </Col>
            <Col md="4" xs="12">
              <FormGroup>
                <Label className="text-secondary font-weight-bolder">
                  Birth Date
                </Label>
                <Controller
                  control={control}
                  name="birth_date"
                  defaultValue={
                    moment(details.birth_date).isValid()
                      ? moment(details.birth_date).toDate()
                      : startDate
                  }
                  rules={{ required: true }}
                  invalid={errors.birth_date ? true : false}
                  render={({ onChange, value, onBlur, name }) => (
                    <DatePicker
                      className="form-control bg-light"
                      selected={
                        moment(details.birth_date).isValid()
                          ? moment(details.birth_date).toDate()
                          : startDate
                      }
                      onChange={(date) => {
                        setStartDate(date);
                        setValue("birth_date", date);
                        setDetails({
                          ...details,
                          birth_date: date,
                        });
                        return date;
                      }}
                      showYearDropdown
                      placeholderText="mm/dd/YYYY"
                      name="birth_date"
                      defaultValue={
                        moment(details.birth_date).isValid()
                          ? moment(details.birth_date).toDate()
                          : startDate
                      }
                    />
                  )}
                />
                {errors.birth_date && errors.birth_date.type == "required" && (
                  <div
                    style={{
                      marginTop: "0.25rem",
                      fontSize: "80%",
                      color: "#f86c6b",
                    }}
                  >
                    Birth date is required!
                  </div>
                )}
              </FormGroup>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md="4" xs="12">
              <FormGroup>
                <Label
                  htmlFor="gender"
                  className="text-secondary font-weight-bolder"
                >
                  Gender
                </Label>
                <Controller
                  control={control}
                  name="gender"
                  rules={{ required: true }}
                  invalid={errors.gender ? true : false}
                  defaultValue={
                    details.gender != ""
                      ? {
                        value: details.gender,
                        label: getOptionLabelByValue(
                          optionList.gender,
                          details.gender
                        ),
                      }
                      : ""
                  }
                  render={({ onChange, value, onBlur, name }) => (
                    <Select
                      options={optionList.gender}
                      onChange={(e) => {
                        setValue("gender", e);
                        // setDetails({ ...details, gender: e })

                        return e;
                      }}
                      defaultValue={
                        details.gender != ""
                          ? {
                            value: details.gender,
                            label: getOptionLabelByValue(
                              optionList.gender,
                              details.gender
                            ),
                          }
                          : ""
                      }
                    />
                  )}
                />
                {errors.gender && (
                  <div
                    style={{
                      marginTop: "0.25rem",
                      fontSize: "80%",
                      color: "#f86c6b",
                    }}
                  >
                    Please select!
                  </div>
                )}
              </FormGroup>
            </Col>
            <Col md="4" xs="12">
              <Label className="text-secondary font-weight-bolder">Age</Label>
              <FormGroup>
                <Controller
                  control={control}
                  name="age"
                  rules={{ required: true, min: 1 }}
                  invalid={errors.age ? true : false}
                  defaultValue={details.age}
                  render={({ onChange, value, onBlur, name }) => (
                    <Input
                      className="form-control bg-light"
                      name="age"
                      type="number"
                      onChange={(event) => {
                        // setDetails({ ...details, age: event.target.value })
                        setValue("age", event.target.value);
                        return event.target.value;
                      }}
                      defaultValue={details.age}
                    />
                  )}
                />
                {errors.age && errors.age.type == "required" && (
                  <div
                    style={{
                      marginTop: "0.25rem",
                      fontSize: "80%",
                      color: "#f86c6b",
                    }}
                  >
                    Age is required!
                  </div>
                )}
                {errors.age && errors.age.type == "min" && (
                  <div
                    style={{
                      marginTop: "0.25rem",
                      fontSize: "80%",
                      color: "#f86c6b",
                    }}
                  >
                    Invalid age value
                  </div>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="4" xs="12">
              <FormGroup>
                <Label
                  htmlFor="civil_status"
                  className="text-secondary font-weight-bolder"
                >
                  Civil Status
                </Label>
                <Controller
                  control={control}
                  name="civil_status"
                  rules={{ required: true }}
                  invalid={errors.civil_status ? true : false}
                  defaultValue={
                    details.civil_status != ""
                      ? {
                        value: details.civil_status,
                        label: getOptionLabelByValue(
                          optionList.civil_status,
                          details.civil_status
                        ),
                      }
                      : ""
                  }
                  render={({ onChange, value, onBlur, name }) => (
                    <Select
                      options={optionList.civil_status}
                      onChange={(e) => {
                        setValue("civil_status", e);
                        setDetails({
                          ...details,
                          civil_status: e.value,
                        });

                        return e;
                      }}
                      defaultValue={
                        details.civil_status != ""
                          ? {
                            value: details.civil_status,
                            label: getOptionLabelByValue(
                              optionList.civil_status,
                              details.civil_status
                            ),
                          }
                          : ""
                      }
                    />
                  )}
                />
                {errors.civil_status && (
                  <div
                    style={{
                      marginTop: "0.25rem",
                      fontSize: "80%",
                      color: "#f86c6b",
                    }}
                  >
                    Civil status is required!
                  </div>
                )}
              </FormGroup>
            </Col>
            <Col md="4" xs="12">
              <Label className="text-secondary font-weight-bolder">
                Religion
              </Label>
              <FormGroup>
                <Controller
                  control={control}
                  name="religion"
                  rules={{ required: true }}
                  invalid={errors.religion ? true : false}
                  defaultValue={details.religion}
                  render={({ onChange, value, onBlur, name }) => (
                    <Input
                      className="form-control bg-light"
                      name="religion"
                      onChange={(event) => {
                        // setDetails({ ...details, religion: event.target.value })
                        setValue("religion", event.target.value);
                        return event.target.value;
                      }}
                      defaultValue={details.religion}
                    />
                  )}
                />
                {errors.religion && (
                  <div
                    style={{
                      marginTop: "0.25rem",
                      fontSize: "80%",
                      color: "#f86c6b",
                    }}
                  >
                    Religion is required!
                  </div>
                )}
              </FormGroup>
            </Col>
            <Col md="4" xs="12">
              <Label className="text-secondary font-weight-bolder">
                LRN
              </Label>
              <FormGroup>
                <Controller
                  control={control}
                  name="lrn"
                  rules={{ required: true }}
                  invalid={errors.lrn ? true : false}
                  defaultValue={details.lrn}
                  render={({ onChange, value, onBlur, name }) => (
                    <Input
                      className="form-control bg-light"
                      name="lrn"
                      onChange={(event) => {
                        // setDetails({ ...details, lrn: event.target.value })
                        setValue("lrn", event.target.value);
                        return event.target.value;
                      }}
                      defaultValue={details.lrn}
                    />
                  )}
                />
                {errors.lrn && (
                  <div
                    style={{
                      marginTop: "0.25rem",
                      fontSize: "80%",
                      color: "#f86c6b",
                    }}
                  >
                    LRN is required!
                  </div>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="9" xs="12">
              <FormGroup>
                <Label
                  htmlFor="living_status"
                  className="text-secondary font-weight-bolder"
                >
                  Living Status
                </Label>
                <Controller
                  control={control}
                  name="living_status"
                  rules={{ required: true }}
                  invalid={errors.living_status ? true : false}
                  defaultValue={
                    details.living_status !== ""
                      ? {
                        value: details.living_status,
                        label: getOptionLabelByValue(
                          optionList.living_status,
                          details.living_status
                        ),
                      }
                      : ""
                  }
                  render={({ onChange, value, onBlur, name }) => (
                    <Select
                      options={optionList.living_status}
                      onChange={(e) => {
                        setValue("living_status", e);
                        // setDetails({ ...details, living_status: e })

                        return e;
                      }}
                      defaultValue={
                        details.living_status !== ""
                          ? {
                            value: details.living_status,
                            label: getOptionLabelByValue(
                              optionList.living_status,
                              details.living_status
                            ),
                          }
                          : ""
                      }
                    />
                  )}
                />
                {errors.living_status && (
                  <div
                    style={{
                      marginTop: "0.25rem",
                      fontSize: "80%",
                      color: "#f86c6b",
                    }}
                  >
                    Living status is required!
                  </div>
                )}
              </FormGroup>
            </Col>
            <Col md="3" xs="12">
              <FormGroup>
                <Label
                  htmlFor="no_of_siblings"
                  className="text-secondary font-weight-bolder"
                >
                  No of Siblings
                </Label>
                <Controller
                  control={control}
                  name="no_of_siblings"
                  rules={{ required: true }}
                  invalid={errors.no_of_siblings ? true : false}
                  defaultValue={details.no_of_siblings}
                  render={({ onChange, value, onBlur, name }) => (
                    <Input
                      className="form-control bg-light"
                      name="no_of_siblings"
                      onChange={(event) => {
                        // setDetails({ ...details, no_of_siblings: event.target.value })
                        setValue("no_of_siblings", event.target.value);
                        return event.target.value;
                      }}
                      defaultValue={details.no_of_siblings}
                    />
                  )}
                />
                {errors.no_of_siblings && (
                  <div
                    style={{
                      marginTop: "0.25rem",
                      fontSize: "80%",
                      color: "#f86c6b",
                    }}
                  >
                    No of siblings is required!
                  </div>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="4" xs="12">
              <Label className="text-secondary font-weight-bolder">
                House no., Building, Street
              </Label>
              <FormGroup>
                <Controller
                  control={control}
                  name="house_bldg_street"
                  rules={{ required: true }}
                  invalid={errors.house_bldg_street ? true : false}
                  defaultValue={details.house_bldg_street}
                  render={({ onChange, value, onBlur, name }) => (
                    <Input
                      className="form-control bg-light"
                      name="house_bldg_street"
                      onChange={(event) => {
                        // setDetails({ ...details, house_bldg_street: event.target.value })
                        setValue("house_bldg_street", event.target.value);
                        return event.target.value;
                      }}
                      defaultValue={details.house_bldg_street}
                    />
                  )}
                />
                {errors.house_bldg_street && (
                  <div
                    style={{
                      marginTop: "0.25rem",
                      fontSize: "80%",
                      color: "#f86c6b",
                    }}
                  >
                    House no., Building, Street are required!
                  </div>
                )}
              </FormGroup>
            </Col>
            <Col md="4" xs="12">
              <FormGroup>
                <Label className="text-secondary font-weight-bolder">
                  Province
                </Label>
                <Controller
                  control={control}
                  name="province"
                  rules={{ required: true }}
                  invalid={errors.province ? true : false}
                  defaultValue={details.province}
                  render={({ onChange, value, onBlur, name }) => (
                    <Input
                      className="form-control bg-light"
                      name="province"
                      onChange={(event) => {
                        // setDetails({ ...details, province: event.target.value })
                        setValue("province", event.target.value);
                        return event.target.value;
                      }}
                      defaultValue={details.province}
                    />
                  )}
                />
                {errors.province && (
                  <div
                    style={{
                      marginTop: "0.25rem",
                      fontSize: "80%",
                      color: "#f86c6b",
                    }}
                  >
                    Province is required!
                  </div>
                )}
              </FormGroup>
            </Col>
            <Col md="4" xs="12">
              <FormGroup>
                <Label className="text-secondary font-weight-bolder">
                  City/Municipality
                </Label>
                <Controller
                  control={control}
                  name="city_municipality"
                  rules={{ required: true }}
                  invalid={errors.city_municipality ? true : false}
                  defaultValue={details.city_municipality}
                  render={({ onChange, value, onBlur, name }) => (
                    <Input
                      className="form-control bg-light"
                      name="city_municipality"
                      onChange={(event) => {
                        // setDetails({ ...details, city_municipality: event.target.value })
                        setValue("city_municipality", event.target.value);
                        return event.target.value;
                      }}
                      defaultValue={details.city_municipality}
                    />
                  )}
                />
                {errors.city_municipality && (
                  <div
                    style={{
                      marginTop: "0.25rem",
                      fontSize: "80%",
                      color: "#f86c6b",
                    }}
                  >
                    City/Municipality is required!
                  </div>
                )}
              </FormGroup>
            </Col>
            <Col md="4" xs="12">
              <FormGroup>
                <Label className="text-secondary font-weight-bolder">
                  Barangay
                </Label>
                <Controller
                  control={control}
                  name="barangay"
                  rules={{ required: true }}
                  invalid={errors.barangay ? true : false}
                  defaultValue={details.barangay}
                  render={({ onChange, value, onBlur, name }) => (
                    <Input
                      className="form-control bg-light"
                      name="barangay"
                      onChange={(event) => {
                        // setDetails({ ...details, barangay: event.target.value })
                        setValue("barangay", event.target.value);
                        return event.target.value;
                      }}
                      defaultValue={details.barangay}
                    />
                  )}
                />
                {errors.barangay && (
                  <div
                    style={{
                      marginTop: "0.25rem",
                      fontSize: "80%",
                      color: "#f86c6b",
                    }}
                  >
                    Barangay is required!
                  </div>
                )}
              </FormGroup>
            </Col>
          </Row>
          <small>Please put N.A. if it does not apply to you</small>
        </ModalBody>
        <ModalFooter>
          <Button className="bg-warning-edit border-0" disabled={buttonSpinner}>
            {buttonSpinner ? (
              <>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp;Processing...
              </>
            ) : (
              "Submit"
            )}
          </Button>
          <Button color="secondary" onClick={toggleEditDetails} disabled={buttonSpinner}>
            Close
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};
export default EditStudentDetails;
