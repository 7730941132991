import React, { Fragment, useEffect, useState } from "react";
import {
  Container,
  Collapse,
  Navbar,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Label,
  Card,
  Button,
  CardImg,
  CardText,
  CardDeck,
  CardBody,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  Modal,
  ModalBody,
  Spinner,
  Fade,
  ModalHeader,
  Input,
  ModalFooter,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import landingPageLogo from "../../../../assets/img/landingPageLogo.png";
import sapcYellow from "../../../../assets/img/SAPC-Logo-yellow.png";
import flatEllipse from "../../../../assets/img/flat-ellipse.png";
import facebookIcon from "../../../../assets/img/facebook-icon.png";
import twitterIcon from "../../../../assets/img/twitter-icon.png";
import {
  isBrowser,
  isDesktop,
  isIOS,
  isTablet,
  isMobile,
} from "react-device-detect";
import Select from "react-select";
import scholarshipImage from "../../../../assets/img/admission-base.jpg";
import * as FaIcons from "react-icons/fa";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { notifyError } from "../../../../services/Toaster";
import { dynamicScholarshipsActions } from "../../../../services/DynamicScholarshipServices";

const AdmissionScholarship = () => {
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [buttonSpinner2, setButtonSpinner2] = useState(false);
  const [currentVersion, setCurrentVersion] = useState("");
  const [versionSelection, setVersionSelection] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState("");
  const [currentStep, setCurrentStep] = useState(false);

  const getOptionLabelByValue = (options, value) => {
    const option = options.find((option) => option.value == value);
    return option ? option.label : "";
  };

  useEffect(() => {
    setLoading(true);
    setCurrentVersion("");
    dynamicScholarshipsActions.getCurrentTemplate(
      setScholarships,
      scholarships,
      setCurrentVersion,
      setSelectedVersion,
      setLoading,
      setVersionSelection,
      scholarshipImage
    );
  }, []);

  useEffect(() => {
    if (selectedVersion != "") {
      dynamicScholarshipsActions.getSelectedTemplate(
        setScholarships,
        scholarships,
        setCurrentVersion,
        selectedVersion,
        setLoading,
        setVersionSelection,
        scholarshipImage
      );
    }
  }, [selectedVersion]);

  const toggleUpdateScholarship = () => {
    if (!openUpdateModal) {
      setOpenUpdateModal(!openUpdateModal);
    } else {
      setOpenUpdateModal(!openUpdateModal);
    }
  };

  const handlePublishTemplate = () => {
    setButtonSpinner2(true);
    dynamicScholarshipsActions.publishTemplate(
      selectedVersion,
      setButtonSpinner,
      setLoading,
      setButtonSpinner2
    );
  };

  const [scholarships, setScholarships] = useState({
    baseImage: scholarshipImage,
    baseImageFile: "",
    data: [
      {
        name: "Sanghaya",
        description: "Academic Scholarships",
        scholarshipData: [
          {
            title: "1st Honors - 100% discount on tuition fees",
            data: [],
          },
          {
            title: "2nd Honors - 50% discount on tuition fees",
            data: [],
          },
          {
            title: "3rd Honors - 20% discount on tuition fees",
            data: [],
          },
        ],
      },
      {
        name: "Kapamilya Discount",
        description:
          "Loyalty discounts for alumni, SAPC employee benefit, family discount",
        scholarshipData: [
          {
            title: "Alumni Discount - 20% discount on tuition fees",
            data: [],
          },
          {
            title:
              "Family Discount - When 2 or more siblings are enrolled in SAPC in the same term:",
            data: ["20% - 2nd child", "40% - 3rd child", "60% - 4th child"],
          },
          {
            title:
              "SAPC Employee Benefit - for SAPC employees with children enrolled at SAPC",
            data: [
              "1st child - 100% discount on tuition fees",
              "2nd child - 80%",
              "3rd child - 60%",
            ],
          },
        ],
      },
      {
        name: "Kumpas Scholarships",
        description: "For the performing arts (50% discount on tuition fees)",
        scholarshipData: [
          {
            title: "Instruments",
            data: [],
          },
          {
            title: "Voice",
            data: [],
          },
          {
            title: "Dance",
            data: [],
          },
        ],
      },
      {
        name: "SAPC Raiders",
        description: "Athletic Scholarship for student athletes",
        scholarshipData: [
          {
            title: "Palarong Pambansa Athlete - 100% discount",
            data: [],
          },
          {
            title: "STCAA Athlete - 75% discount",
            data: [],
          },
          {
            title: "One Laguna Athlete - 50% discount",
            data: [],
          },
          {
            title: "Provincial Meet Athlete - 20% discount",
            data: [],
          },
        ],
      },
    ],
  });

  const acceptedImageExtentions = ["jpg", "jpeg", "png"];
  const handleUploadImage = async (e) => {
    let extension = e[0].name.split(".")[1];
    if (acceptedImageExtentions.includes(extension)) {
      let imageFile = {
        filename: e[0].name,
        document: await convertBase64(e[0]),
      };
      setScholarships({
        ...scholarships,
        baseImage: URL.createObjectURL(e[0]),
        baseImageFile: imageFile,
      });
    } else {
      notifyError("Invalid file type for image");
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleDeleteData = (i) => {
    let currentScholarshipsData = scholarships.data;
    let newData = currentScholarshipsData.filter((d, key) => {
      return i != key;
    });
    setScholarships({ ...scholarships, data: newData });
  };

  const blankOneData = {
    name: "",
    description: "",
    scholarshipData: [
      {
        title: "",
        data: "",
      },
    ],
  };

  const [oneDataModal, setOneDataModal] = useState(false);
  const [tempData, setTempData] = useState(blankOneData);
  const [action, setAction] = useState("");
  const [currentIndex, setCurrentIndex] = useState("");

  const toggleAddOrEditMobile = (actionType, index, e) => {
    setTempData(blankOneData);
    setAction("");
    setCurrentIndex("");
    if (!oneDataModal) {
      if (actionType == "edit") {
        setAction(actionType);
        setTempData(e);
        setCurrentIndex(index);
      }
      setOneDataModal(!oneDataModal);
    } else {
      setOneDataModal(!oneDataModal);
    }
  };

  const toggleAddNewScholarship = () => {
    let currentScholarshipData = tempData.scholarshipData;
    currentScholarshipData.push({ title: "", data: [] });

    setTempData({ ...tempData, scholarshipData: currentScholarshipData });
  };

  const handleChangeScholarshipData = (e, key, details) => {
    let currentScholarshipData = tempData.scholarshipData;

    let newDetails = currentScholarshipData.map((nd, i) => {
      if (key == i) {
        if (details == "details") {
          nd.title = e;
        }

        if (details == "sub-details") {
          nd.data = e;
        }

        return nd;
      } else {
        return nd;
      }
    });

    setTempData({ ...tempData, scholarshipData: newDetails });
  };

  const [nameErrorMessage, setNameErrorMessage] = useState("");
  const [descriptionErrorMessage, setDescriptionErrorMessage] = useState("");
  const [detailsErrorMessage, setDetailsErrorMessage] = useState("");

  const handleSaveNewScholarshipDetails = () => {
    setNameErrorMessage("");
    setDescriptionErrorMessage("");
    setDetailsErrorMessage("");
    if (tempData.name == "") {
      setNameErrorMessage("Name is required!");
    }
    if (tempData.description == "") {
      setDescriptionErrorMessage("Description is required!");
    }

    if (tempData.name && tempData.description) {
      let scholarshipDataArr = tempData.scholarshipData.map((temp) => {
        if (temp.data) {
          let dataArr = temp.data.split(",");
          temp.data = dataArr;
          return temp;
        } else {
          temp.data = [];
          return temp;
        }
      });

      tempData.scholarshipData = scholarshipDataArr;

      let currentScholarshipsData = scholarships.data;
      currentScholarshipsData.push(tempData);
      setScholarships({ ...scholarships, data: currentScholarshipsData });
      setOneDataModal(!oneDataModal);
    }
  };

  const [arrangeModal, setArrangeModal] = useState(false);
  const toggleArrange = () => {
    if (!arrangeModal) {
      setArrangeModal(!arrangeModal);
    } else {
      setArrangeModal(!arrangeModal);
    }
  };

  const arrangeContents = (result) => {
    if (!result.destination) return;
    const items = Array.from(scholarships.data);
    const [reoderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reoderedItem);

    setScholarships({ ...scholarships, data: items });
  };

  const handleSaveTemplate = () => {
    setButtonSpinner(true);
    let dataToSubmit = {
      scholarship: JSON.stringify(scholarships),
    };

    dynamicScholarshipsActions.saveTemplate(
      dataToSubmit,
      setLoading,
      setButtonSpinner,
      setButtonSpinner2
    );
  };

  return (
    <div className="p-4">
      <Row>
        <Col md="6" xs="12" className="my-4" style={{ zIndex: "9" }}>
          <Select
            options={versionSelection}
            name="version"
            className={isMobile ? "w-100" : isTablet ? "w-50" : "w-25"}
            onChange={(e) => {
              setLoading(true);
              setSelectedVersion(e.value);
              return e;
            }}
            defaultValue={
              selectedVersion != ""
                ? {
                    value: selectedVersion,
                    label: getOptionLabelByValue(
                      versionSelection,
                      selectedVersion
                    ),
                  }
                : ""
            }
            value={
              selectedVersion != ""
                ? {
                    value: selectedVersion,
                    label: getOptionLabelByValue(
                      versionSelection,
                      selectedVersion
                    ),
                  }
                : ""
            }
          />
          <Label className="text-secondary">Select Version</Label>
        </Col>
        <Col md="6" xs="12" className="w-100 ml-auto text-right my-4">
          <div>
            <Button
              className="mr-3 border-0 bg-danger-edit"
              onClick={handleSaveTemplate}
              disabled={buttonSpinner || buttonSpinner2 || loading}
            >
              {buttonSpinner ? (
                <>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  &nbsp;Processing...
                </>
              ) : (
                "Save"
              )}
            </Button>
            <Button
              onClick={handlePublishTemplate}
              color="success"
              disabled={buttonSpinner || buttonSpinner2 || loading}
            >
              {buttonSpinner2 ? (
                <>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  &nbsp;Processing...
                </>
              ) : (
                "Publish"
              )}
            </Button>
          </div>
          <Label className="font-weight-bolder text-secondary">
            Latest Version: {currentVersion}
          </Label>
        </Col>
      </Row>

      <div
        className="p-5"
        style={{
          backgroundImage: `url(${scholarships.baseImage})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right top",
          minHeight: "100vh",
        }}
      >
        {scholarships.data.length >= 1 ? (
          scholarships.data.map((scholarship, index) => {
            let currentIndex = index + 1;
            return (
              <div key={index}>
                <p>
                  <strong>
                    {currentIndex}. {scholarship.name}
                  </strong>
                </p>
                <p>{scholarship.description}</p>
                <ol>
                  {scholarship.scholarshipData.length >= 1
                    ? scholarship.scholarshipData.map((schoData, index2) => {
                        let currentIndex2 = index2 + 1;
                        return (
                          <Fragment key={index2}>
                            <li className="mb-2">
                              {currentIndex}.{currentIndex2} {schoData.title}
                            </li>
                            <ol className="mb-2">
                              {schoData.data.length >= 1
                                ? schoData.data.map((d, index3) => {
                                    let currentIndex3 = index3 + 1;
                                    return (
                                      <Fragment key={index3}>
                                        <li>
                                          {currentIndex}.{currentIndex2}.
                                          {currentIndex3} {d}
                                        </li>
                                      </Fragment>
                                    );
                                  })
                                : ""}
                            </ol>
                          </Fragment>
                        );
                      })
                    : ""}
                </ol>
              </div>
            );
          })
        ) : (
          <div className="text-center">
            <h5>No Scholarships Available</h5>
          </div>
        )}
      </div>

      <div className="my-4">
        <Button onClick={() => toggleUpdateScholarship()}>
          UPDATE SCHOLARSHIPS
        </Button>
      </div>
      <Modal isOpen={openUpdateModal} backdrop="static" size="xl">
        <ModalHeader>Update Scholarship Types</ModalHeader>
        <ModalBody>
          <Row>
            <Col md="12" xs="12" className="mb-4">
              <img
                src={scholarships.baseImage}
                style={{ height: "200px", width: "200px" }}
              />
              <Input
                type="file"
                onChange={(d) => handleUploadImage(d.target.files)}
              />
            </Col>
            <Col md="12" xs="12" className="mb-4">
              <Table striped bordered>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Details</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {scholarships.data.map((scholarship, i) => {
                    return (
                      <tr key={i}>
                        <td style={{ verticalAlign: "middle" }}>{i + 1}</td>
                        <td style={{ verticalAlign: "middle" }}>
                          {scholarship.name}
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                          {scholarship.description}
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                          {scholarship.scholarshipData.map((s, i) => {
                            return (
                              <ul key={i}>
                                <li className="mb-3">{s.title}</li>
                                {s.data.length >= 1
                                  ? s.data.map((u, k) => {
                                      return (
                                        <ul key={k}>
                                          <li>{u}</li>
                                        </ul>
                                      );
                                    })
                                  : ""}
                              </ul>
                            );
                          })}
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                          <div className="d-flex align-items-center text-center justify-content-center">
                            {/* <Button color="warning" className='text-center text-white mr-2' onClick={() => toggleAddOrEditMobile('edit', i, scholarship)}>{<FaIcons.FaEdit style={{ fontSize: "1rem" }} />}</Button> */}
                            <Button
                              color="danger"
                              onClick={() => handleDeleteData(i)}
                            >
                              X
                            </Button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <Button
                className="mr-2"
                color="success"
                onClick={() => toggleAddOrEditMobile("add", null, null)}
              >
                ADD SCHOLARSHIP
              </Button>
              <Button color="success" onClick={() => toggleArrange()}>
                ARRANGEMENT
              </Button>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => setOpenUpdateModal(!openUpdateModal)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={oneDataModal} backdrop="static" size="lg">
        <ModalHeader>
          {action == "add" ? "Add " : "Update "}Scholarship
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md="12" xs="12" className="mb-3">
              <Label>Name</Label>
              <Input
                defaultValue={tempData.name}
                onChange={(e) =>
                  setTempData({ ...tempData, name: e.target.value })
                }
              />
              <small className="text-danger-edit">{nameErrorMessage}</small>
            </Col>
            <Col md="12" xs="12" className="mb-5">
              <Label>Description</Label>
              <Input
                defaultValue={tempData.description}
                onChange={(e) =>
                  setTempData({ ...tempData, description: e.target.value })
                }
              />
              <small className="text-danger-edit">
                {descriptionErrorMessage}
              </small>
            </Col>

            <Col md="6" xs="12" className="mb-3">
              <h5 className="font weight-bolder">Scholarship Details</h5>
              <small className="text-danger-edit">{detailsErrorMessage}</small>
            </Col>
            <Col md="6" xs="12" className="mb-3 text-right">
              <Button
                id="toggleAddNewScholarship"
                color="primary"
                onClick={() => toggleAddNewScholarship()}
              >
                +
              </Button>
              <UncontrolledTooltip target="toggleAddNewScholarship">
                Add Scholarship Details
              </UncontrolledTooltip>
            </Col>
            <Col md="12" xs="12" className="mb-3">
              {tempData.scholarshipData.map((scholarship, key) => {
                return (
                  <Card className="mb-3" key={key}>
                    <CardBody>
                      <Label>Details</Label>
                      <Input
                        type="text"
                        className="mb-2"
                        defaultValue={scholarship.title}
                        onChange={(e) =>
                          handleChangeScholarshipData(
                            e.target.value,
                            key,
                            "details"
                          )
                        }
                      />

                      <Label>Sub-Details</Label>
                      <Input
                        type="textarea"
                        defaultValue={scholarship.data}
                        onChange={(e) =>
                          handleChangeScholarshipData(
                            e.target.value,
                            key,
                            "sub-details"
                          )
                        }
                      />
                      <p className="text-secondary">
                        <i>
                          Separate sub details by coma " , " ( Ex:
                          sub_detail_1,sub_detail_2,sub_detail3 )
                        </i>
                      </p>
                    </CardBody>
                  </Card>
                );
              })}
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => handleSaveNewScholarshipDetails()}>
            Save
          </Button>
          <Button onClick={() => setOneDataModal(!oneDataModal)}>Close</Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={arrangeModal} backdrop="static">
        <ModalHeader toggle={toggleArrange}>
          Arrange Scholarship Details
        </ModalHeader>
        <ModalBody>
          <DragDropContext onDragEnd={arrangeContents}>
            <Droppable droppableId="characters">
              {(provided) => (
                <ul
                  className="list-unstyled"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {scholarships.data && scholarships.data.length >= 1
                    ? scholarships.data.map((d, index) => {
                        return (
                          <Draggable
                            key={index + 1}
                            draggableId={(index + 1).toString()}
                            index={index}
                          >
                            {(provided) => (
                              <li
                                className="text-center mb-3"
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                              >
                                <h5
                                  style={{ backgroundColor: "#ff7a00" }}
                                  className="p-4 text-white"
                                >
                                  {d.name}
                                </h5>
                              </li>
                            )}
                          </Draggable>
                        );
                      })
                    : ""}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => setArrangeModal(!arrangeModal)}
            disabled={buttonSpinner}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AdmissionScholarship;
