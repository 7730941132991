import React, { useState } from "react";
import {
  Button,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  FormGroup,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Spinner,
} from "reactstrap";
import classnames from "classnames";
import { registrarActions } from "../../../services/RegistrarServices";
import Select from "react-select";

const ViewStudentList = ({
  addOrEditStudentList,
  toggleViewStudentList,
  section,
  sectionDetails,
  semesterFilter,
  setSemesterFilter
}) => {
  const [activeTab, setActiveTab] = useState("1");
  const [buttonSpinner, setButtonSpinner] = useState(false);
  
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const downloadList = () => {
    setButtonSpinner(true);
    const dataToSubmit = {
      section_id: section.id,
    };
    registrarActions.downloadStudentList(dataToSubmit, setButtonSpinner);
  };

  const downloadReport = () => {
    setButtonSpinner(true);
    const dataToSubmit = {
      section_id: section.id,
      year_level_id: sectionDetails.grade_level_id,
      semester: semesterFilter,
      course_type_id: sectionDetails.courseCodeArr.length >= 1 ? sectionDetails.courseCodeArr[0].id : ""
    };
    registrarActions.downloadReport(dataToSubmit, setButtonSpinner);
  }
  
  const semesterSelection = [
      { value: 0, label: "First" },
      { value: 1, label: "Second" },
  ];

  const withSemester = [4, 5];

  return (
    <Modal
      isOpen={addOrEditStudentList}
      toggle={toggleViewStudentList}
      size="lg"
      backdrop="static"
    >
      <ModalHeader
        toggle={toggleViewStudentList}
        className="bg-danger-edit text-white"
        charCode=""
      >
        View Student List
      </ModalHeader>
      <ModalBody style={{ overflow: "auto" }} className="mr-3">
        <Row className="mb-5">
          <Col md="4" xs="12" className="mr-auto">
            <h5 className="text-danger-edit">
              Grade Level : {section.grade_level}
            </h5>
          </Col>
          <Col md="6" xs="12" className="ml-auto">
            <h5 className="text-danger-edit">
              Section Name : {section.section}
            </h5>
          </Col>
        </Row>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => {
                toggle("1");
              }}
            >
              Student List
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => {
                toggle("2");
              }}
            >
              Student Schedule
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "3" })}
              onClick={() => {
                toggle("3");
              }}
            >
              Performance Report
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab} className="py-3">
          <TabPane tabId="1">
            <Row>
              <Col md="6" xs="6" className="mb-3">
                <Button
                  onClick={downloadList}
                  disabled={buttonSpinner}
                  className="btn bg-danger-edit shadow"
                  style={{ borderColor: "#800000" }}
                >
                  {buttonSpinner ? (
                    <>
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      &nbsp;Processing...
                    </>
                  ) : (
                    "Download Student List"
                  )}
                </Button>
              </Col>
              <Col md="12" xs="12">
                <FormGroup>
                  <Table bordered striped className="text-center">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {section.withSection && section.withSection.length >= 1 ? (
                        section.withSection.map((student, key) => {
                          return (
                            <tr key={key}>
                              <td>{key + 1}</td>
                              <td>{student.label}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="2">No Students</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </FormGroup>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row>
              <Col md="12" xs="12" className="text-center">
                {sectionDetails.studentSchedule ? (
                  <img src={sectionDetails.studentSchedule} className="w-100" />
                ) : (
                  <p>No Upload Schedule</p>
                )}
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="3">
              <Row className="text-center">
                {sectionDetails.courseCodeArr.length >= 1 && withSemester.includes(sectionDetails.courseCodeArr[0].grade_type_id) ?
                <Col md="6" xs="12" className="mx-auto">
                    <Label className="font-weight-bolder">
                      Select Semester
                    </Label>
                  <Select
                    options={semesterSelection}
                    onChange={(e) => {
                      setSemesterFilter(e.value);
                      return e;
                    }}
                    defaultValue={sectionDetails.semester}
                  />
                </Col>
                : ""}
                <Col md="12" xs="12" className="py-3">
                  <Button color="warning" disabled={buttonSpinner || (sectionDetails.courseCodeArr.length >= 1 && withSemester.includes(sectionDetails.courseCodeArr[0].grade_type_id) && semesterFilter === "" ? true : false)} onClick={downloadReport}>
                  {buttonSpinner ? (
                      <>
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        &nbsp;Processing...
                      </>
                    ) : (
                      "Download Report"
                    )}
                  </Button>
                </Col>
              </Row>
          </TabPane>
        </TabContent>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggleViewStudentList}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};
export default ViewStudentList;
