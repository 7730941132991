import { Button, Card, CardBody, CardHeader } from "reactstrap";
import SideBar from "./SideBar";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import { GiHamburgerMenu } from "react-icons/gi";

const PageLayout = ({ title, children }) => {
  const role = localStorage.getItem("role") ? localStorage.getItem("role") : "";
  const [toggled, setToggled] = useState(false);
  return (
    <div style={{ display: "flex", height: "100%", minHeight: "400px" }}>
      <SideBar role={role} toggled={toggled} setToggled={setToggled} />
      <main className="px-3 my-3 w-100">
        <Card className="shadow mb-3">
          {!["Admission"].includes(title) && title && (
            <CardHeader className="border-0 bg-white pt-4 pb-0 mb-0">
              <div className="d-flex align-items-center">
                {isMobile ? (
                  <h4 className="mr-2 text-danger-edit font-weight-bolder">
                    <GiHamburgerMenu onClick={() => setToggled(!toggled)} />
                  </h4>
                ) : (
                  <></>
                )}
                <h4 className="text-danger-edit font-weight-bolder">{title}</h4>
              </div>
              <hr />
            </CardHeader>
          )}
          <CardBody className="p-0">{children}</CardBody>
        </Card>
      </main>
    </div>
  );
};

export default PageLayout;
