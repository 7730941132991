import {
    Container,
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavbarText,
    Row,
    Col,
    Label,
    Card,
    Button,
    CardImg,
    CardTitle,
    CardText,
    CardDeck,
    CardSubtitle,
    CardBody,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    TabContent,
    TabPane,
    ListGroup,
    ListGroupItem,
    List
} from 'reactstrap';
import {
    BrowserView,
    MobileView,
    isDesktop,
    isMobile,
    isTablet
} from "react-device-detect";
import preSchool from '../../../../assets/img/acads-basic/pre-school.jpg';
import gradeSchool1 from '../../../../assets/img/acads-basic/grade-school-1.jpg';
import gradeSchool2 from '../../../../assets/img/acads-basic/grade-school-2.jpg';
import highSchool1 from '../../../../assets/img/acads-basic/high-school-1.jpg';
import highSchool2 from '../../../../assets/img/acads-basic/high-school-2.jpg';
import highSchool3 from '../../../../assets/img/acads-basic/high-school-3.jpg';
import highSchool4 from '../../../../assets/img/acads-basic/high-school-4.jpg';
import { Fade, Slide } from "react-reveal";
import { Fragment } from 'react';

const BasicAcads = ({ basicEducation }) => {
    return (
        <>
            <Row className="align-items-center justify-content-center d-flex mt-5" xs="12" md="12" lg="12">
                <Col lg="2" md="2" xs="12" className="text-center text-danger-edit">
                    <h3 className="font-weight-bolder">{basicEducation.mainTitle}</h3>
                </Col>

                {isDesktop ?
                    <Col lg="1" md="1" xs="1" className="text-center text-danger-edit">
                        <h1 style={{ fontSize: "85px" }}>|</h1>
                    </Col>
                    :
                    <Col className="text-center" xs="12" md="1" lg="1">
                        <h3 className={isDesktop ? "font-weight-bolder text-danger-edit text-left" : "font-weight-bolder text-danger-edit mr-5 ml-5 mt-3 mb-4"} style={{ fontSize: "40px", borderTop: "4px solid" }}></h3>
                    </Col>
                }

                <Col lg="7" md="7" xs="12" className="text-secondary font-weight-bolder">
                    <p className={isDesktop ? "" : "text-justify mx-5"}>
                        {basicEducation.mainDescription}
                    </p>
                </Col>
            </Row>

            <Container fluid={true} className="bg-light pb-5">
                <Row className="align-items-center justify-content-center d-flex mt-5 mb-3" xs="12" md="12" lg="12">
                    <Col lg="4" md="4" xs="12">
                        <Slide left>
                            <img src={basicEducation.first} style={{ width: "350px" }} className={isDesktop ? "" : "w-100 mb-5"} />
                        </Slide>
                    </Col>

                    <Col lg="8" md="8" xs="12" className={isDesktop ? "text-left" : "text-center"}>
                        <h4 className="text-danger-edit font-weight-bolder">{basicEducation.firstTitle}</h4>
                        <Label className="text-secondary mr-2" style={{ fontSize: "14px" }}>
                            <strong>{basicEducation.firstDescription}</strong>
                            <br /><br />
                            <ul className="text-left">
                                {basicEducation.firstContents.length >= 1 ? basicEducation.firstContents.map((content, key) => {
                                    return (
                                        <Fragment key={key}>
                                            <li>{content.text}</li>
                                        </Fragment>
                                    )
                                }) : ""}
                            </ul>
                        </Label>
                    </Col>
                </Row>

                <Row className="align-items-center justify-content-center d-flex" xs="12" md="12" lg="12">
                    <Col lg={isTablet ? "4" : "7"} md={isTablet ? "4" : "7"} xs="12">
                        <Slide left>
                            <img src={basicEducation.second} style={{ width: "350px" }} className={isDesktop ? "mr-3" : "w-100 mb-3"} />
                        </Slide>
                        <Slide left>
                            <img src={basicEducation.third} style={{ width: "350px" }} className={isDesktop ? "" : "w-100 mb-5"} />
                        </Slide>
                    </Col>

                    <Col lg="5" md="5" xs="12" className={isDesktop ? "text-left" : "text-center"}>
                        <h4 className="text-danger-edit font-weight-bolder">{basicEducation.secondTitle}</h4>
                        <Label className="text-secondary mr-2" style={{ fontSize: "14px" }}>
                            <strong>{basicEducation.secondDescription}</strong>
                            <br /><br />
                            <ul className="text-left">
                                {basicEducation.secondContents.length >= 1 ? basicEducation.secondContents.map((content, key) => {
                                    return (
                                        <Fragment key={key}>
                                            <li>{content.text}</li>
                                        </Fragment>
                                    )
                                }) : ""}
                            </ul>
                        </Label>
                    </Col>
                </Row>

                <Row className={isDesktop ? "align-items-center justify-content-center d-flex mr-3 pr-2 mt-3" : "align-items-center justify-content-center d-flex mt-3"} xs="12" md="12" lg="12">
                    <Col lg={isTablet ? "4" : "7"} md={isTablet ? "4" : "7"} xs="12" className="text-center">
                        <Slide left>
                            <img src={basicEducation.fourth} style={{ width: "350px" }} className={isDesktop ? "mr-3" : "w-100 mb-3"} />
                        </Slide>
                        <Slide right>
                            <img src={basicEducation.fifth} style={{ width: "350px" }} className={isDesktop ? "" : "w-100 mb-5"} />
                        </Slide>
                    </Col>
                </Row>

                <Row className="align-items-center justify-content-center d-flex mt-3" xs="12" md="12" lg="12">
                    <Col lg="5" md="5" xs="12" className={isDesktop ? "text-left" : "text-center"}>
                        <h4 className="text-danger-edit font-weight-bolder">{basicEducation.thirdTitle}</h4>
                        <Label className="text-secondary" style={{ fontSize: "14px" }}>
                            <strong>{basicEducation.thirdDescription}</strong>
                            <br /><br />
                            <List className="text-left">
                                {basicEducation.thirdContents.length >= 1 ? basicEducation.thirdContents.map((content, key) => {
                                    return (
                                        <Fragment key={key}>
                                            <li>{content.text}</li>
                                        </Fragment>
                                    )
                                }) : ""}
                            </List>
                        </Label>
                    </Col>

                    <Col lg={isTablet ? "4" : "6"} md={isTablet ? "4" : "6"} xs="12" className={isDesktop ? "ml-5 pl-4" : "text-center mt-3"}>
                        <Slide right>
                            <img src={basicEducation.sixth} style={{ width: "350px" }} className={isDesktop ? "ml-1 mr-3" : "w-100 mb-3"} />
                        </Slide>
                        <Slide right>
                            <img src={basicEducation.seventh} style={{ width: "350px" }} className={isDesktop ? "" : "w-100 mb-5"} />
                        </Slide>
                    </Col>

                </Row>
            </Container>
        </>
    );

}

export default BasicAcads;
