import React, { useState } from 'react';
import { Button, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, FormGroup, Form, Spinner, Input, CardBody, CardTitle, Card, CardText } from 'reactstrap';
import "@kenshooui/react-multi-select/dist/style.css";

const ViewUser = ({
    viewModal, 
    toggleView,
    user,
}) => {

    return (
        <Modal isOpen={viewModal} toggle={toggleView} size="lg" backdrop="static">
        <ModalHeader toggle={toggleView} className="bg-danger-edit text-white" charCode="">View User</ModalHeader>
        <ModalBody>
            <Card>
              <CardBody>
                <CardTitle tag="h5" className="mb-3 text-danger-edit">{user.last_name}, {user.given_name} {user.middle_name}</CardTitle>
                <CardText><span className="font-weight-bolder">Role</span> {user.role}</CardText>
                <CardText><span className="font-weight-bolder">Email Address:</span> {user.email_address}</CardText>
                <CardText><span className="font-weight-bolder">Email Verified:</span> {user.is_verify ? "Yes" : "No"}</CardText>
                <CardText><span className="font-weight-bolder">Status:</span> {user.status ? "Active" : "InActive"}</CardText>
                <CardText><span className="font-weight-bolder">Date Created:</span> {user.created_at}</CardText>
              </CardBody>
            </Card>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleView}>Close</Button>
        </ModalFooter>
      </Modal>
    )
}
export default ViewUser;