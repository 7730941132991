import React, { useState } from "react";
import {
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { isIOS, isDesktop } from "react-device-detect";

const NavbarMobile = ({ setNavbarMobile, navbarMobile }) => {
  const navigate = useNavigate();
  // const toggleDrop = (e) => setDropdownOpen(!dropdownOpen);
  // const toggleDropClose = (e) => setDropdownOpen(false);
  const [admissionDropdown, setAdmissionDropdown] = useState(false);
  const [lmsDropdown, setLmsDropdown] = useState(false);
  const [loginDropdown, setLoginDropdown] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  // const toggle = () => setDropdownOpen(dropdownOpen => !dropdownOpen);

  const admissionButton = admissionDropdown ? (
    <Button
      onClick={() => {
        setAdmissionDropdown(!admissionDropdown);
        setLmsDropdown(false);
        setLoginDropdown(false);
      }}
      className="text-danger-edit nav-mobile font-weight-bolder"
    >
      ADMISSION
    </Button>
  ) : (
    <Button
      onClick={() => {
        setAdmissionDropdown(!admissionDropdown);
        setLmsDropdown(false);
        setLoginDropdown(false);
      }}
      className="text-danger-edit nav-mobile font-weight-bolder mb-4"
    >
      ADMISSION
    </Button>
  );

  const lmsButton = lmsDropdown ? (
    <Button
      onClick={() => {
        setLmsDropdown(!lmsDropdown);
        setAdmissionDropdown(false);
        setLoginDropdown(false);
      }}
      className="text-danger-edit nav-mobile font-weight-bolder"
    >
      LMS
    </Button>
  ) : (
    <Button
      onClick={() => {
        setLmsDropdown(!lmsDropdown);
        setAdmissionDropdown(false);
        setLoginDropdown(false);
      }}
      className="text-danger-edit nav-mobile font-weight-bolder mb-4"
    >
      LMS
    </Button>
  );

  const loginButton = loginDropdown ? (
    <Button
      onClick={() => {
        setLoginDropdown(!loginDropdown);
        setAdmissionDropdown(false);
        setLmsDropdown(false);
      }}
      className="text-danger-edit nav-mobile font-weight-bolder"
    >
      LOGIN
    </Button>
  ) : (
    <Button
      onClick={() => {
        setLoginDropdown(!loginDropdown);
        setAdmissionDropdown(false);
        setLmsDropdown(false);
      }}
      className="text-danger-edit nav-mobile font-weight-bolder mb-4"
    >
      LOGIN
    </Button>
  );

  const admissionContent = (
    <div className="navbar-mobile-admission fade-in text-center">
      <Button
        onClick={() => navigate("/admission-page")}
        className="text-light nav-mobile font-weight-bolder mt-2 mb-2"
      >
        REQUIREMENTS
      </Button>
      <Button
        onClick={() => navigate("/scholarship-page")}
        className="text-light nav-mobile font-weight-bolder mb-2"
      >
        SCHOLARSHIP
      </Button>
    </div>
  );

  const lmsContent = (
    <div
      className={
        isIOS
          ? "navbar-mobile-lms-safari fade-in text-center"
          : "navbar-mobile-lms fade-in text-center"
      }
    >
      <Button
        onClick={() =>
          navigate(
            window.open(
              "http://52.74.221.30/sapc/?fbclid=IwAR0Cb6NDp56odfRENGOl1Tr47ztbf0Ac38ZyBHCWCfIIyg2-ZcbmTvePYtw",
              "_blank"
            )
          )
        }
        className="text-light nav-mobile font-weight-bolder mt-2 mb-2"
      >
        LIKHA ONLINE
      </Button>
      <Button
        onClick={() =>
          navigate(
            window.open(
              "https://app.seesaw.me/#/student/login?fbclid=IwAR2GIW7rGIvu4SWjVDB2gmPe7riyw3smZnfGhWYOE-fDhw22Fr_RI3KUXX8",
              "_blank"
            )
          )
        }
        className="text-light nav-mobile font-weight-bolder mb-2"
      >
        SEESAW
      </Button>
      <Button
        onClick={() =>
          navigate(window.open("https://www.canva.com/", "_blank"))
        }
        className="text-light nav-mobile font-weight-bolder mb-2"
      >
        CANVA FOR EDUCATION
      </Button>
    </div>
  );

  const loginContent = (
    <div
      className={
        isIOS
          ? "navbar-mobile-login-safari fade-in text-center"
          : "navbar-mobile-login fade-in text-center"
      }
    >
      <Button
        onClick={() => navigate(window.open("http://mail.sapc.edu.ph/"))}
        className="text-light nav-mobile font-weight-bolder mt-2 mb-2"
      >
        UGNAI
      </Button>
      <Button
        onClick={() => navigate("/login")}
        className="text-light nav-mobile font-weight-bolder mb-2"
      >
        SINAG
      </Button>
      <Button
        onClick={() => navigate(window.open("https://sapc.ec1schoolsystems.com"))}
        className="text-light nav-mobile font-weight-bolder mb-2"
      >
        SASS
      </Button>
    </div>
  );

  return (
    <div
      style={{
        height: "100%",
        position: "fixed",
        zIndex: "99999999",
        backgroundColor: "white",
        width: "100%",
        overflowY: "hidden",
        transition: "1s",
      }}
      className="fade-in justify-content-center align-items-center d-flex"
    >
      {/* <Button color="danger" onClick={() => setNavbarMobile(!navbarMobile)} style={{ backgroundColor: "white", position: "absolute", top: "10px", right: "10px", fontSize: "2rem" }} className="text-dark border-0 font-weight-bolder">X</Button> */}
      <div
        onClick={() => setNavbarMobile(!navbarMobile)}
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          zIndex: "9999",
        }}
      >
        <div className="bar-menu-red"></div>
        <div className="bar-menu-red-center"></div>
        <div className="bar-menu-red"></div>
      </div>
      <Row className="d-flex flex-column justify-content-center align-items-center">
        <Button
          onClick={() => navigate("/")}
          className="text-danger-edit nav-mobile font-weight-bolder mb-4"
        >
          HOME
        </Button>
        <Button
          onClick={() => navigate("/about-us")}
          className="text-danger-edit nav-mobile font-weight-bolder mb-4"
        >
          ABOUT US
        </Button>
        <Button
          onClick={() => navigate("/academics")}
          className="text-danger-edit nav-mobile font-weight-bolder mb-4"
        >
          ACADEMICS
        </Button>
        {/* <Button onClick={() => navigate('/admission-page')} className="text-danger-edit nav-mobile font-weight-bolder mb-4">ADMISSION</Button> */}
        {/* <Dropdown isOpen={dropdownOpen} toggle={toggle} className="w-100 text-center h-100">
                    <DropdownToggle nav caret className="text-danger-edit font-weight-bolder mb-4">ADMISSION</DropdownToggle>
                    <DropdownMenu center>
                        <DropdownItem className="font-weight-bolder text-danger-edit" onClick={() => navigate('/admission-page')}>REQUIREMENTS</DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem className="font-weight-bolder text-danger-edit" onClick={() => navigate('/scholarship-page')}>SCHOLARSHIP</DropdownItem>
                    </DropdownMenu>
                </Dropdown> */}
        {admissionButton}
        {admissionDropdown && admissionContent}
        {lmsButton}
        {lmsDropdown && lmsContent}
        {loginButton}
        {loginDropdown && loginContent}
        {/* <Button onClick={() => navigate(window.open("http://mail.sapc.edu.ph/"))} className="text-danger-edit nav-mobile font-weight-bolder mb-4">UGNAI</Button>
                <Button onClick={() => navigate('/login')} className="text-danger-edit nav-mobile font-weight-bolder">SINAG LOGIN</Button> */}
      </Row>
    </div>
  );
};

export default NavbarMobile;
