import { ENDPOINT } from "../constants/api";
import axios from "axios";
import { notifyError, notifySuccess } from "./Toaster";

export const authActions = {
  login,
  registration,
  verifyEmail,
  forgotPassword,
  submitCode,
  updatePassword,
};
const headers = {
  "Content-Type": "application/json",
  "X-Requested-With": "XMLHttpRequest",
  "Access-Control-Allow-Origin": "*",
};

async function login(data, setAuthenticated, setButtonSpinner, navigate) {
  try {
    let res = await axios.post(ENDPOINT + "/login", data, { headers: headers });
    if (res.data.data.is_verify) {
      if (res.data.data.token) {
        localStorage.setItem("jwtToken", res.data.data.token);
        localStorage.setItem("email", res.data.data.fullname);
        localStorage.setItem("role", res.data.data.role.name);
        if (res.data.data.role.id == 6) {
          localStorage.setItem("gradeType", res.data.data.grade_type_id);
        }
        setAuthenticated(true);
        navigate("/portal");
        window.location.reload(2000);
      } else {
        notifyError("Login Failed! Invalid Email or Password");
      }
    } else {
      if (res.data.data.token) {
        notifyError(
          "Account is not yet verified. Please check your email for verification. Thank you!"
        );
      } else {
        notifyError("Login Failed! Invalid Email or Password");
      }
    }
    setButtonSpinner(false);
  } catch (err) {
    console.log(err);
    notifyError("Login Failed! Invalid Email or Password");
  }
  setButtonSpinner(false);
}

async function registration(
  data,
  setErrorMessage,
  setButtonSpinner,
  buttonSpinner,
  setRegistrationDone
) {
  try {
    let res = await axios.post(ENDPOINT + "/register", data, {
      headers: headers,
    });
    setButtonSpinner(false);
    setRegistrationDone(true);
  } catch (err) {
    if (err.response) {
      if (err.response.data && err.response.data.data) {
        notifyError(err.response.data.data.Error);
      }
      if (err.response.data && err.response.data.email_address) {
        notifyError(err.response.data.email_address);
      }
    } else {
      notifyError("Registration Failed!");
    }
    setButtonSpinner(false);
  }
}

async function verifyEmail(id, setMessage) {
  try {
    let res = await axios.get(ENDPOINT + `/email-verify/${id}`, {
      headers: headers,
    });
    setMessage("Email is now verified");
  } catch (err) {
    notifyError("Unable to verify email.");
  }
}

async function forgotPassword(
  data,
  setError,
  setSuccess,
  setButtonSpinner,
  setForgotPwProcess,
  setVerificationId
) {
  try {
    let res = await axios.post(ENDPOINT + `/forgot-password`, data, {
      headers: headers,
    });
    if (res.data.success) {
      setVerificationId(res.data.data.verification_code.id);
      setSuccess(res.data.message);
      setForgotPwProcess(1);
      notifySuccess(res.data.message);
    } else {
      notifyError(res.data.data.message);
    }
    setButtonSpinner(false);
  } catch (err) {
    if (err.response) {
      notifyError("Error while sending email.");
    }
    setButtonSpinner(false);
  }
}

async function submitCode(
  data,
  setError,
  setSuccess,
  setButtonSpinner,
  setForgotPwProcess,
  setVerificationCode
) {
  try {
    let res = await axios.post(ENDPOINT + `/verify-code`, data, {
      headers: headers,
    });
    if (res.data.success) {
      setSuccess(res.data.message);
      setForgotPwProcess(2);
      setVerificationCode(res.data.data.verification_code);
      setSuccess(true);
      notifySuccess(res.data.message);
    } else {
      notifyError(res.data.data.message);
    }
    setButtonSpinner(false);
  } catch (err) {
    if (err.response) {
      notifyError("Error while code verification.");
    }
    setButtonSpinner(false);
  }
}

async function updatePassword(
  data,
  setError,
  setSuccess,
  setButtonSpinner,
  setForgotPwProcess
) {
  try {
    let res = await axios.post(ENDPOINT + `/update-password`, data, {
      headers: headers,
    });
    if (res.data.success) {
      setSuccess(res.data.message);
      notifySuccess(res.data.message);
    } else {
      notifyError(res.data.data.message);
    }
    setButtonSpinner(false);
  } catch (err) {
    if (err.response) {
      notifyError("Error while updating password.");
    }
    setButtonSpinner(false);
  }
}
