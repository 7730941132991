import React, { useEffect, useState } from "react";
import { adminActions } from "../../../services/GradeTypeServices";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import overlayFactory from "react-bootstrap-table2-overlay";
import { isBrowser, isDesktop, isMobile } from "react-device-detect";
import Edit from "../../../assets/img/edit.png";
import Add from "../../../assets/img/ADMISSION.png";
import {
  Button,
  Col,
  Container,
  InputGroup,
  InputGroupAddon,
  Row,
  Input,
  UncontrolledTooltip,
} from "reactstrap";
import ModalEditor from "./GradeTypeModal";
import Select from "react-select";
import { educationActions } from "../../../services/EducationSelectionService";
import MobileSideBar from "../Admission/MobileSidebar";

const NoDataIndication = () => <div>No Data</div>;

const GradeType = () => {
  const [data, setData] = useState([]);
  const [educationTypes, setEducationTypes] = useState([]);
  const [updateTable, setUpdateTable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [educationTypeFilter, setEducationTypeFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [coordinators, setCoordinators] = useState([]);
  const [selectOptions, setSelectOptions] = useState({
    education_type: [],
    status: [
      { value: 0, label: "InActive" },
      { value: 1, label: "Active" },
    ],
  });

  useEffect(() => {
    educationActions.getApplications(
      setEducationTypes,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );
  }, []);

  useEffect(() => {
    setLoading(true);
    adminActions.getGradeType(setData, setCoordinators, setLoading);
  }, [updateTable]);

  useEffect(() => {
    // adminActions.getEducationType(selectOptions, setSelectOptions)
    let educTypeArr = educationTypes.map((type) => {
      return {
        value: type.id,
        label: type.name,
      };
    });
    setSelectOptions({ ...selectOptions, education_type: educTypeArr });
  }, [educationTypes]);

  // console.log(selectOptions)

  const columns = [
    {
      dataField: "name",
      text: "Grade Type",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "id",
      text: "Action",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
      formatter: (cell, row) => {
        return (
          <>
            <Button
              className="btn btn-warning"
              onClick={() => handleEditGradeType(cell, row, "edit")}
            >
              <img src={Edit} style={{ height: "1rem" }} />
            </Button>
          </>
        );
      },
    },
  ];

  const [modal, setModal] = useState(false);
  const blankGradeType = {
    id: "",
    name: "",
    education_type_id: "",
    coordinator_id: "",
    enabled: "",
  };
  const [editGradeType, setEditGradeType] = useState(blankGradeType);
  const [action, setAction] = useState("");
  const [gradeId, setGradeId] = useState("");

  // console.log(editGradeType)

  const handleEditGradeType = (cell, row, action) => {
    setEditGradeType(blankGradeType);
    setAction("");
    if (!modal) {
      setAction(action);
      setModal(!modal);
      setGradeId(cell);
      setEditGradeType(row);
    } else {
      setModal(!modal);
    }
  };

  const handleAddGradeType = (action) => {
    setAction("");
    setEditGradeType(blankGradeType);

    if (!modal) {
      setAction(action);
      setModal(!modal);
    } else {
      setModal(!modal);
    }
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  let filteredGradeType = data.filter((d) => {
    return d.name.toLowerCase().includes(searchText.toLowerCase());
  });

  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    if (data.length >= 1) {
      //search INPUT
      let filteredGrade = data.filter((d) => {
        return d.name.toLowerCase().includes(searchText.toLowerCase());
      });

      //filter for role and status
      if (educationTypeFilter !== "") {
        let filteredEducation = filteredGrade.filter((f) => {
          return f.name.toLowerCase() == educationTypeFilter.toLowerCase();
        });
        let filteredStatus = filteredEducation.filter((f) => {
          return f.status == statusFilter;
        });
        setFilteredData(filteredStatus);
      } else {
        setFilteredData(filteredGrade);
      }
      // console.log(filteredGrade)
    }
  }, [educationTypeFilter, statusFilter, searchText, data]);

  // console.log(educationTypeFilter)

  return (
    <div className="p-4">
      <ModalEditor
        modal={modal}
        setModal={setModal}
        handleEditGradeType={handleEditGradeType}
        editGradeType={editGradeType}
        updateTable={updateTable}
        setUpdateTable={setUpdateTable}
        action={action}
        selectOptions={selectOptions}
        coordinators={coordinators}
      />
      <Row className="mb-3">
        <Col md="2" xs="12" className="mb-3">
          <Button
            className="btn bg-danger-edit"
            onClick={() => handleAddGradeType("add")}
          >
            <img src={Add} style={{ height: "1rem" }} />
          </Button>
        </Col>
        <Col md="4" xs="12" className="ml-auto mb-3">
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <Button type="button" color="primary" id="search-button">
                <i className="fa fa-search"></i>
              </Button>
            </InputGroupAddon>
            <Input
              className="form-control"
              placeholder="Search..."
              onChange={handleSearch}
            />
          </InputGroup>
          <p>Searchable Column: Grade Type</p>
        </Col>
      </Row>
      <BootstrapTable
        wrapperClasses="table-responsive mb-3 text-center"
        bootstrap4
        striped
        keyField="id"
        data={filteredData}
        columns={columns}
        loading={loading}
        classes={isMobile ? "mobileTable" : "desktopTable"}
        pagination={paginationFactory()}
        overlay={overlayFactory({
          spinner: true,
          styles: {
            overlay: (base) => ({
              ...base,
              background: "rgba(0, 0, 0, 0.5)",
            }),
          },
        })}
        noDataIndication={() => <NoDataIndication />}
      />
    </div>
  );
};

export default GradeType;
