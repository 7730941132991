import React, { useState } from 'react';
import { Button, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, FormGroup, Form, Spinner, Input } from 'reactstrap';
import "@kenshooui/react-multi-select/dist/style.css";
import { useForm, Controller } from "react-hook-form";
import {userRoleActions} from '../../../services/UserRoleServices'
import Select from "react-select";

const AddEdit = ({
    addOrEditModal, 
    setAddOrEditModal, 
    toggleAddOrEdit, 
    userRole,
    action,
    setUpdateTable,
    updateTable,
    selectOptions
}) => {
    const { handleSubmit, errors, control, setValue } = useForm();
    const [buttonSpinner, setButtonSpinner] = useState(false);

    const onSubmit = (data) => {
        setButtonSpinner(true);


        if(action == "add"){
          data.enabled = data.enabled ? data.enabled.value : 0;
          userRoleActions.addUserRole(data, setButtonSpinner, setUpdateTable, updateTable, setAddOrEditModal)
        }else if("edit"){
          data.sys_role_id = userRole.id;
          data.enabled = data.enabled ? data.enabled.value : 0;
          userRoleActions.updateUserRole(data, setButtonSpinner, setUpdateTable, updateTable, setAddOrEditModal)
        }
    }

    const getOptionLabelByValue = (options, value) => {
      const option = options.find((option) => option.value == value);
      return option ? option.label : "";
  };

    return (
        <Modal isOpen={addOrEditModal} toggle={toggleAddOrEdit} size="lg" backdrop="static">
        <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader toggle={toggleAddOrEdit} className="bg-danger-edit text-white" charCode="">{action == "add" ? "Add User" : "Edit User"}</ModalHeader>
        <ModalBody>
            <Row>
                <Col md="12" xs="12">
                    <Label className="text-secondary font-weight-bolder">
                        Name
                    </Label>
                    <FormGroup>
                        <Controller
                          control={control}
                          name="name"
                          rules={{ required: true }}
                          invalid={errors.name ? true : false}
                          defaultValue={userRole.name}
                          render={({ onChange, value, onBlur, name }) => (
                            <Input
                              className="form-control bg-light"
                              name="name"
                              type="text"
                              onChange={(event) => {
                                setValue("name", event.target.value);
                                return event.target.value;
                              }}
                              defaultValue={userRole.name}
                            />
                          )}
                        />
                        {errors.name && errors.name.type == "required" && (
                          <small className="text-danger">
                            Name is required!
                          </small>
                        )}
                    </FormGroup>
                </Col>
                <Col md="6" xs="12">
                    <FormGroup>
                      <Label
                        htmlFor="enabled"
                        className="text-secondary w-100 font-weight-bolder"
                      >
                        Status
                      </Label>
                      <Controller
                        control={control}
                        name="enabled"
                        defaultValue={
                            userRole.enabled !== ""
                              ? {
                                value: userRole.enabled,
                                label: getOptionLabelByValue(
                                  selectOptions.status,
                                  userRole.enabled
                                ),
                              }
                              : ""
                            }
                        rules={{ required: true }}
                        invalid={errors.enabled ? true : false}
                        render={({ onChange, value, onBlur, name }) => (
                          <Select
                            options={selectOptions.status}
                            name="enabled"
                            onChange={(e) => {
                              setValue("enabled", e);
                              return e;
                            }}
                            defaultValue={
                                userRole.enabled !== ""
                              ? {
                                  value: userRole.enabled,
                                  label: getOptionLabelByValue(
                                    selectOptions.status,
                                    userRole.enabled
                                  ),
                                }
                              : ""
                            }
                          />
                        )}
                      />
                      {errors.enabled &&
                        errors.enabled.type == "required" && (
                          <small className="text-danger">
                            Status is required!
                          </small>
                        )}
                    </FormGroup>
                </Col>
            </Row>
        </ModalBody>
        <ModalFooter>
        <Button className="bg-warning-edit border-0" disabled={buttonSpinner}>
            {buttonSpinner ? (
              <>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </>
            ) : (
              "Save"
            )}
          </Button>
          <Button color="secondary" disabled={buttonSpinner} onClick={toggleAddOrEdit}>Close</Button>
        </ModalFooter>
        </Form>
      </Modal>
    )
}
export default AddEdit;