import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import LoginImage from "../../../assets/img/LoginImage.jpg";
import "../../../App.css";
import optionList from "./optionList.js";
import { educationActions } from "../../../services/EducationSelectionService.js";
import AdmissionModal from "./AdmissionModal.js";
import Loading from "../Forms/Loading.js";
import { isBrowser, isDesktop, isTablet } from "react-device-detect";
import states from "../States/States";
import StepperComponent from "./StepperComponent";

const Admission = () => {
  const [educationTypes, setEducationTypes] = useState([]);
  const [studentTypes, setStudentTypes] = useState([]);
  const [gradeTypes, setGradeTypes] = useState([]);
  const [gradeLevels, setGradeLevels] = useState([]);
  const [courses, setCourses] = useState([]);
  const [modal, setModal] = useState(true);
  const [educ, setEduc] = useState("");
  const [subjects, setSubjects] = useState([]);
  const [allSubjects, setAllSubjects] = useState([]);
  const [educationSelected, setEducationSelected] = useState(false);
  const [selectedEducationType, setSelectedEducationType] = useState("");
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [scholarshipForm, setScholarShipForm] = useState(
    states.blankScholarshipForm
  );
  const [basicForm, setBasicForm] = useState(states.blankBasicEducationForm);
  const [tesdaForm, setTesdaForm] = useState(states.blankTesdaForm);
  const [paymentForm, setPaymentForm] = useState(states.blankPaymentForm);
  const [subjectForm, setSubjectForm] = useState(states.blankSubjectForm);
  const [newTuitionFee, setNewTuitionFee] = useState("");
  const [schoolFees, setSchoolFees] = useState([]);
  const [escDiscounts, setEscDiscounts] = useState([]);
  const [currentPage, setCurrentPage] = useState("");
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [navbarMobile, setNavbarMobile] = useState(false);
  const [tesdaEducationSelected, setTesdaEducationSelected] = useState(false);
  const [tertiaryTotalTuition, setTertiaryTotalTuition] = useState(false);

  const toggle = () => {
    setNavbarMobile(false);
    if (!modal) {
      setModal(!modal);
      setEduc("");
      setCurrentPage("");
    } else {
      setModal(!modal);
    }
  };

  const [selectOptions, setSelectOptions] = useState({
    education_types: [],
    student_types: [],
    grade_types: [],
    grade_levels: [],
    gradeLevelSelection: [],
    coursesSelection: [],
    collegeSubjSelection: [],
    subjects: [],
    allSubjects: [],
    collegeCourses: [],
    enrollee_type: [
      { value: 0, label: "Regular Student" },
      { value: 1, label: "Manually select subjects" },
    ],
    payment_mode: [
      { value: 1, label: "Annual" },
      { value: 2, label: "Semestral" },
      { value: 3, label: "Monthly A" },
      { value: 4, label: "Monthly B" },
    ],
    payment_mode_tertiary: [
      { value: 1, label: "Full Payment" },
      { value: 3, label: "Monthly A" },
      { value: 4, label: "Monthly B" },
    ],
  });

  useEffect(() => {
    educationActions.getApplications(
      setEducationTypes,
      setStudentTypes,
      setGradeTypes,
      setGradeLevels,
      setSchoolFees,
      setCourses,
      setAllSubjects,
      setEscDiscounts
    );
  }, []);

  useEffect(() => {
    if (studentTypes && studentTypes.length >= 1) {
      let student_types = studentTypes.map((type) => {
        return { value: type.id, label: type.name.toUpperCase() };
      });
      setSelectOptions({ ...selectOptions, student_types: student_types });
    }
  }, [studentTypes]);

  useEffect(() => {
    if (subjects && subjects.length >= 1) {
      let student_subjects = subjects.map((type) => {
        return { value: type.id, label: type.description.toUpperCase() };
      });
      setSelectOptions({ ...selectOptions, subjects: student_subjects });
    }
  }, [subjects]);

  useEffect(() => {
    if (courses && courses.length >= 1) {
      let collegeSubjArr = [];
      let student_courses = courses.map((type) => {
        if (type.grade_type_id == 5) {
          collegeSubjArr.push({
            value: type.id,
            label: type.name.toUpperCase(),
            grade_type_id: type.grade_type_id,
          });
        }
        return {
          value: type.id,
          label: type.name.toUpperCase(),
          grade_type_id: type.grade_type_id,
        };
      });

      setSelectOptions({
        ...selectOptions,
        coursesSelection: student_courses,
        collegeSubjSelection: collegeSubjArr,
      });
    }
  }, [courses]);

  const handleCancel = () => {
    setEducationSelected(false);
    setTesdaEducationSelected(false);
    window.location.reload(500);
  };

  return (
    <>
      {loading ? <Loading /> : ""}
      <div className="Admission">
        {currentPage == "admission" ? (
          <StepperComponent
            educationSelected={educationSelected}
            selectedEducationType={selectedEducationType}
            handleCancel={handleCancel}
            setEducationSelected={setEducationSelected}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            setLoading={setLoading}
            basicForm={basicForm}
            setBasicForm={setBasicForm}
            paymentForm={paymentForm}
            setPaymentForm={setPaymentForm}
            escDiscounts={escDiscounts}
            scholarshipForm={scholarshipForm}
            setScholarShipForm={setScholarShipForm}
            newTuitionFee={newTuitionFee}
            setSchoolFees={setSchoolFees}
            selectOptions={selectOptions}
            schoolFees={schoolFees}
            loading={loading}
            educationTypes={educationTypes}
            subjects={subjects}
            subjectForm={subjectForm}
            setSubjectForm={setSubjectForm}
            selectedSubjects={selectedSubjects}
            setSelectedSubjects={setSelectedSubjects}
            setSubjects={setSubjects}
            setTertiaryTotalTuition={setTertiaryTotalTuition}
            allSubjects={allSubjects}
            setTesdaEducationSelected={setTesdaEducationSelected}
            tesdaForm={tesdaForm}
            setTesdaForm={setTesdaForm}
            currentPage={currentPage}
            gradeTypes={gradeTypes}
          />
        ) : isDesktop ? (
          <img
            src={isBrowser ? LoginImage : ""}
            style={{ height: "100vh", width: "100%", objectFit: "unset" }}
          />
        ) : (
          ""
        )}
        <AdmissionModal
          modal={modal}
          setModal={setModal}
          toggle={toggle}
          selectOptions={selectOptions}
          setSelectOptions={setSelectOptions}
          educ={educ}
          setEduc={setEduc}
          optionList={optionList}
          setEducationSelected={setEducationSelected}
          gradeLevels={gradeLevels}
          setLoading={setLoading}
          setCurrentStep={setCurrentStep}
          setSelectedEducationType={setSelectedEducationType}
          courses={courses}
          gradeTypes={gradeTypes}
          setSubjects={setSubjects}
          schoolFees={schoolFees}
          setPaymentForm={setPaymentForm}
          setSelectedSubjects={setSelectedSubjects}
          setBasicForm={setBasicForm}
          basicForm={basicForm}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </>
  );
};

export default Admission;
