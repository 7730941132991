import React, { useEffect, useState } from "react";
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane,
} from "reactstrap";
import _ from "lodash";
import classnames from "classnames";

const StudentGrade = ({ activeTab, details }) => {
  const [firstSemesterGrades, setFirstSemesterGrades] = useState([]);
  const [secondSemesterGrades, setSecondSemesterGrades] = useState([]);
  const [basicGrades, setBasicGrades] = useState([]);
  const [firstSem1stAverage, setFirstSem1stAverage] = useState("");
  const [firstSem2ndAverage, setFirstSem2ndAverage] = useState("");
  const [firstSem3rdAverage, setFirstSem3rdAverage] = useState("");
  const [firstSem4thAverage, setFirstSem4thAverage] = useState("");
  const [firstSemFinalAverage, setFirstSemFinalAverage] = useState("");
  const [secondSem1stAverage, setSecondSem1stAverage] = useState("");
  const [secondSem2ndAverage, setSecondSem2ndAverage] = useState("");
  const [secondSem3rdAverage, setSecondSem3rdAverage] = useState("");
  const [secondSem4thAverage, setSecondSem4thAverage] = useState("");
  const [secondSemFinalAverage, setSecondSemFinalAverage] = useState("");
  const withSemester = [4, 5];

  const [gradeTab, setGradeTab] = useState("1");

  const toggle = (tab) => {
    if (gradeTab !== tab) setGradeTab(tab);
  };

  useEffect(() => {
    if (withSemester.includes(details.grade_type_id)) {
      let firstSem = [];
      let secondSem = [];
      if (details.student_grades) {
        let grades = details.student_grades.map((grade) => {
          if (grade.semester == "1") {
            secondSem.push({
              subject_id: grade.subject_id ? grade.subject_id.id : "",
              subject_name: grade.subject_id
                ? grade.subject_id.description
                : "",
              grade: grade.grade,
              grading_period: grade.grading_period,
              semester: grade.semester,
            });
          } else {
            firstSem.push({
              subject_id: grade.subject_id ? grade.subject_id.id : "",
              subject_name: grade.subject_id
                ? grade.subject_id.description
                : "",
              grade: grade.grade,
              grading_period: grade.grading_period,
              semester: grade.semester,
            });
          }
        });

        if (firstSem.length >= 1) {
          const firstSemGroup = _.groupBy(firstSem, "subject_id");
          const firstRes = Object.keys(firstSemGroup);

          const firstSemGrades = firstRes.map((r) => {
            return {
              subject_id: r,
              subject_name: "",
              grading_period: "",
              semester: "",
              first: "",
              second: "",
              third: "",
              fourth: "",
            };
          });

          const firstSem1stArr = [];
          const firstSem2ndArr = [];
          const firstSem3rdArr = [];
          const firstSem4thArr = [];
          const firstSemFinalAveArr = [];
          if (firstSemGrades.length >= 1) {
            const firstGradesArr = firstSemGrades.map((newG) => {
              const firstSemAverArr = [];
              const firstNewGArr = firstSem.map((grade) => {
                if (newG.subject_id == grade.subject_id) {
                  if (grade.grading_period == "1st") {
                    newG.first = grade.grade;
                    firstSem1stArr.push(parseFloat(grade.grade))
                    firstSemAverArr.push(parseFloat(grade.grade))
                  }

                  if (grade.grading_period == "2nd") {
                    newG.second = grade.grade;
                    firstSem2ndArr.push(parseFloat(grade.grade))
                    firstSemAverArr.push(parseFloat(grade.grade))
                  }

                  if (grade.grading_period == "3rd") {
                    newG.third = grade.grade;
                    firstSem3rdArr.push(parseFloat(grade.grade))
                    firstSemAverArr.push(parseFloat(grade.grade))
                  }

                  if (grade.grading_period == "4th") {
                    newG.fourth = grade.grade;
                    firstSem4thArr.push(parseFloat(grade.grade))
                    firstSemAverArr.push(parseFloat(grade.grade))
                  }

                  newG.subject_name = grade.subject_name;
                  newG.semester = grade.semester;

                  return newG;
                }
              });

              let firstAverage = "";
              if (firstSemAverArr.length) {
                const subAverage = (firstSemAverArr.reduce((result, number) => result + number)) / firstSemAverArr.length;
                firstSemFinalAveArr.push(subAverage)
                firstAverage = subAverage.toFixed(2);
              }
              newG.subject_average = (firstAverage);
              const s = firstNewGArr.filter((n) => {
                return n;
              });
              return s[0];
            });
            const firstSem1stSum = firstSem1stArr.length ? ((firstSem1stArr.reduce((result, number) => result + number)) / firstSem1stArr.length).toFixed(2) : "";
            const firstSem2ndSum = firstSem2ndArr.length ? ((firstSem2ndArr.reduce((result, number) => result + number)) / firstSem2ndArr.length).toFixed(2) : "";
            const firstSem3rdSum = firstSem3rdArr.length ? ((firstSem3rdArr.reduce((result, number) => result + number)) / firstSem3rdArr.length).toFixed(2) : "";
            const firstSem4thSum = firstSem4thArr.length ? ((firstSem4thArr.reduce((result, number) => result + number)) / firstSem4thArr.length).toFixed(2) : "";
            const firstSemFinalAveSum = firstSemFinalAveArr.length ? ((firstSemFinalAveArr.reduce((result, number) => result + number)) / firstSemFinalAveArr.length).toFixed(2) : "";
            setFirstSem1stAverage(firstSem1stSum)
            setFirstSem2ndAverage(firstSem2ndSum)
            setFirstSem3rdAverage(firstSem3rdSum)
            setFirstSem4thAverage(firstSem4thSum)
            setFirstSemFinalAverage(firstSemFinalAveSum)
            setFirstSemesterGrades(firstGradesArr);
          }
        }

        if (secondSem.length >= 1) {
          const secondSemGroup = _.groupBy(secondSem, "subject_id");
          const secondRes = Object.keys(secondSemGroup);
          const secondSemGrades = secondRes.map((r) => {
            return {
              subject_id: r,
              subject_name: "",
              grading_period: "",
              semester: "",
              first: "",
              second: "",
              third: "",
              fourth: "",
            };
          });

          const secondSem1stArr = [];
          const secondSem2ndArr = [];
          const secondSem3rdArr = [];
          const secondSem4thArr = [];
          const secondSemFinalAveArr = [];
          if (secondSemGrades.length >= 1) {
            const secondGradesArr = secondSemGrades.map((newG) => {
              const secondeSemAveArr = [];
              const secondNewGArr = secondSem.map((grade) => {
                if (newG.subject_id == grade.subject_id) {
                  if (grade.grading_period == "1st") {
                    newG.first = grade.grade;
                    secondSem1stArr.push(parseFloat(grade.grade))
                    secondeSemAveArr.push(parseFloat(grade.grade))
                  }

                  if (grade.grading_period == "2nd") {
                    newG.second = grade.grade;
                    secondSem2ndArr.push(parseFloat(grade.grade))
                    secondeSemAveArr.push(parseFloat(grade.grade))
                  }

                  if (grade.grading_period == "3rd") {
                    newG.third = grade.grade;
                    secondSem3rdArr.push(parseFloat(grade.grade))
                    secondeSemAveArr.push(parseFloat(grade.grade))
                  }

                  if (grade.grading_period == "4th") {
                    newG.fourth = grade.grade;
                    secondSem4thArr.push(parseFloat(grade.grade))
                    secondeSemAveArr.push(parseFloat(grade.grade))
                  }

                  newG.subject_name = grade.subject_name;
                  newG.semester = grade.semester;

                  return newG;
                }
              });
              let secondAverage = "";
              if (secondeSemAveArr.length) {
                const subAverage = (secondeSemAveArr.reduce((result, number) => result + number)) / secondeSemAveArr.length;
                secondSemFinalAveArr.push(subAverage)
                secondAverage = subAverage.toFixed(2);
              }
              newG.subject_average = (secondAverage);
              const s = secondNewGArr.filter((n) => {
                return n;
              });
              return s[0];
            });
            const secondSem1stSum = secondSem1stArr.length ? ((secondSem1stArr.reduce((result, number) => result + number)) / secondSem1stArr.length).toFixed(2) : "";
            const secondSem2ndSum = secondSem2ndArr.length ? ((secondSem2ndArr.reduce((result, number) => result + number)) / secondSem2ndArr.length).toFixed(2) : "";
            const secondSem3rdSum = secondSem3rdArr.length ? ((secondSem3rdArr.reduce((result, number) => result + number)) / secondSem3rdArr.length).toFixed(2) : "";
            const secondSem4thSum = secondSem4thArr.length ? ((secondSem4thArr.reduce((result, number) => result + number)) / secondSem4thArr.length).toFixed(2) : "";
            const secondSemFinalAveSum = secondSemFinalAveArr.length ? ((secondSemFinalAveArr.reduce((result, number) => result + number)) / secondSemFinalAveArr.length).toFixed(2) : "";
            setSecondSem1stAverage(secondSem1stSum)
            setSecondSem2ndAverage(secondSem2ndSum)
            setSecondSem3rdAverage(secondSem3rdSum)
            setSecondSem4thAverage(secondSem4thSum)
            setSecondSemFinalAverage(secondSemFinalAveSum)
            setSecondSemesterGrades(secondGradesArr);
          }
        }
      }
    } else {
      if (details.student_grades) {
        let grades = details.student_grades.map((grade) => {
          return {
            subject_id: grade.subject_id ? grade.subject_id.id : "",
            subject_name: grade.subject_id ? grade.subject_id.description : "",
            grade: grade.grade,
            grading_period: grade.grading_period,
            semester: grade.semester,
          };
        });

        const firstSem1stArr = [];
        const firstSem2ndArr = [];
        const firstSem3rdArr = [];
        const firstSem4thArr = [];
        const firstSemFinalAveArr = [];
        if (grades.length >= 1) {
          const group = _.groupBy(grades, "subject_id");
          const res = Object.keys(group);

          const gradesArr = res.map((r) => {
            return {
              subject_id: r,
              subject_name: "",
              grading_period: "",
              semester: "",
              first: "",
              second: "",
              third: "",
              fourth: "",
            };
          });

          if (gradesArr.length >= 1) {
            const finalGradesArr = gradesArr.map((newG) => {
              const firstSemAverArr = [];
              const gradeNewGArr = grades.map((grade) => {
                if (newG.subject_id == grade.subject_id) {
                  if (grade.grading_period == "1st") {
                    newG.first = grade.grade;
                    firstSem1stArr.push(parseFloat(grade.grade))
                    firstSemAverArr.push(parseFloat(grade.grade))
                  }

                  if (grade.grading_period == "2nd") {
                    newG.second = grade.grade;
                    firstSem2ndArr.push(parseFloat(grade.grade))
                    firstSemAverArr.push(parseFloat(grade.grade))
                  }

                  if (grade.grading_period == "3rd") {
                    newG.third = grade.grade;
                    firstSem3rdArr.push(parseFloat(grade.grade))
                    firstSemAverArr.push(parseFloat(grade.grade))
                  }

                  if (grade.grading_period == "4th") {
                    newG.fourth = grade.grade;
                    firstSem4thArr.push(parseFloat(grade.grade))
                    firstSemAverArr.push(parseFloat(grade.grade))
                  }

                  newG.subject_name = grade.subject_name;
                  newG.semester = grade.semester;

                  return newG;
                }
              });
              let basicAverage = "";
              if (firstSemAverArr.length) {
                const subAverage = (firstSemAverArr.reduce((result, number) => result + number)) / firstSemAverArr.length;
                firstSemFinalAveArr.push(subAverage)
                basicAverage = subAverage.toFixed(2);
              }
              newG.subject_average = (basicAverage);
              const s = gradeNewGArr.filter((n) => {
                return n;
              });
              return s[0];
            });
            const firstSem1stSum = firstSem1stArr.length ? ((firstSem1stArr.reduce((result, number) => result + number)) / firstSem1stArr.length).toFixed(2) : "";
            const firstSem2ndSum = firstSem2ndArr.length ? ((firstSem2ndArr.reduce((result, number) => result + number)) / firstSem2ndArr.length).toFixed(2) : "";
            const firstSem3rdSum = firstSem3rdArr.length ? ((firstSem3rdArr.reduce((result, number) => result + number)) / firstSem3rdArr.length).toFixed(2) : "";
            const firstSem4thSum = firstSem4thArr.length ? ((firstSem4thArr.reduce((result, number) => result + number)) / firstSem4thArr.length).toFixed(2) : "";
            const firstSemFinalAveSum = firstSemFinalAveArr.length ? ((firstSemFinalAveArr.reduce((result, number) => result + number)) / firstSemFinalAveArr.length).toFixed(2) : "";
            setFirstSem1stAverage(firstSem1stSum)
            setFirstSem2ndAverage(firstSem2ndSum)
            setFirstSem3rdAverage(firstSem3rdSum)
            setFirstSem4thAverage(firstSem4thSum)
            setFirstSemFinalAverage(firstSemFinalAveSum)
            setBasicGrades(finalGradesArr);
          }
        }
      }
    }
  }, [details]);

  console.log(details)
  return (
    <>
      {withSemester.includes(details.grade_type_id) ? (
        <>
          {details.grade_type_id == 4 && details.semester == "First" ?
            <Table striped bordered>
              <thead>
                <tr
                  style={{ backgroundColor: "#800000", color: "white", textAlign: "center" }}
                >
                  <td>#</td>
                  <td>Subjects</td>
                  <td>First Quarter</td>
                  <td>Second Quarter</td>
                  <td>Average</td>
                </tr>
              </thead>
              <tbody>
                {firstSemesterGrades.length >= 1 ? (
                  <>
                    {firstSemesterGrades.map((grade, index) => {
                      return (
                        <tr key={index}>
                          <td className="text-center" style={{ verticalAlign: "middle" }}>{index + 1}</td>
                          <td className="text-center" style={{ verticalAlign: "middle" }}>{grade.subject_name}</td>
                          <td className="text-center" style={{ verticalAlign: "middle" }}>{grade.first}</td>
                          <td className="text-center" style={{ verticalAlign: "middle" }}>{grade.second}</td>
                          <td className="text-center" style={{ verticalAlign: "middle" }}>{grade.subject_average}</td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td colSpan="2" className="text-right font-weight-bolder" style={{ verticalAlign: "middle" }}>
                        Grading Average
                      </td>
                      <td className="text-center" style={{ verticalAlign: "middle" }}>
                        {firstSem1stAverage}
                      </td>
                      <td className="text-center" style={{ verticalAlign: "middle" }}>
                        {firstSem2ndAverage}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="4" className="text-right font-weight-bolder">
                        <h3>Final Average</h3>
                      </td>
                      <td className="text-center" style={{ verticalAlign: "middle" }}>
                        {firstSemFinalAverage}
                      </td>
                    </tr>
                  </>
                ) : (
                  <tr>
                    <td colSpan="5" className="text-center">
                      No Grades Available
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            : ""}
          {details.grade_type_id == 4 && details.semester == "Second" ?
            <Table striped bordered>
              <thead>
                <tr
                  style={{ backgroundColor: "#800000", color: "white", textAlign: "center" }}
                >
                  <td>#</td>
                  <td>Subjects</td>
                  <td>Third Quarter</td>
                  <td>Fourth Quarter</td>
                  <td>Average</td>
                </tr>
              </thead>
              <tbody>
                {secondSemesterGrades.length >= 1 ? (
                  <>
                    {secondSemesterGrades.map((grade, index) => {
                      return (
                        <tr key={index}>
                          <td className="text-center" style={{ verticalAlign: "middle" }}>{index + 1}</td>
                          <td className="text-center" style={{ verticalAlign: "middle" }}>{grade.subject_name}</td>
                          <td className="text-center" style={{ verticalAlign: "middle" }}>{grade.third}</td>
                          <td className="text-center" style={{ verticalAlign: "middle" }}>{grade.fourth}</td>
                          <td className="text-center" style={{ verticalAlign: "middle" }}>{grade.subject_average}</td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td colSpan="2" className="text-right font-weight-bolder" style={{ verticalAlign: "middle" }}>
                        Grading Average
                      </td>
                      <td className="text-center" style={{ verticalAlign: "middle" }}>
                        {secondSem3rdAverage}
                      </td>
                      <td className="text-center" style={{ verticalAlign: "middle" }}>
                        {secondSem4thAverage}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="4" className="text-right font-weight-bolder">
                        <h3>Final Average</h3>
                      </td>
                      <td className="text-center" style={{ verticalAlign: "middle" }}>
                        {secondSemFinalAverage}
                      </td>
                    </tr>
                  </>
                ) : (
                  <tr>
                    <td colSpan="5" className="text-center">
                      No Grades Available
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            : ""}

          {details.grade_type_id != 4 && details.semester == "First" ?
            <Table striped bordered>
              <thead>
                <tr
                  style={{ backgroundColor: "#800000", color: "white", textAlign: "center" }}
                >
                  <td>#</td>
                  <td>Subjects</td>
                  <td>Prelim</td>
                  <td>Midterm</td>
                  {/* <td>Semi Finals</td> */}
                  <td>Finals</td>
                  <td>Average</td>
                </tr>
              </thead>
              <tbody>
                {firstSemesterGrades.length >= 1 ? (
                  <>
                    {firstSemesterGrades.map((grade, index) => {
                      return (
                        <tr key={index}>
                          <td className="text-center" style={{ verticalAlign: "middle" }}>{index + 1}</td>
                          <td className="text-center" style={{ verticalAlign: "middle" }}>{grade.subject_name}</td>
                          <td className="text-center" style={{ verticalAlign: "middle" }}>{grade.first}</td>
                          <td className="text-center" style={{ verticalAlign: "middle" }}>{grade.second}</td>
                          <td className="text-center" style={{ verticalAlign: "middle" }}>{grade.third}</td>
                          {/* <td className="text-center" style={{ verticalAlign: "middle" }}>{grade.fourth}</td> */}
                          <td className="text-center" style={{ verticalAlign: "middle" }}>{grade.subject_average}</td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td colSpan="2" className="text-right font-weight-bolder" style={{ verticalAlign: "middle" }}>
                        Grading Average
                      </td>
                      <td className="text-center" style={{ verticalAlign: "middle" }}>
                        {firstSem1stAverage}
                      </td>
                      <td className="text-center" style={{ verticalAlign: "middle" }}>
                        {firstSem2ndAverage}
                      </td>
                      <td className="text-center" style={{ verticalAlign: "middle" }}>
                        {firstSem3rdAverage}
                      </td>
                      {/* <td className="text-center" style={{ verticalAlign: "middle" }}>
                        {firstSem4thAverage}
                      </td> */}
                    </tr>
                    <tr>
                      <td colSpan="5" className="text-right font-weight-bolder">
                        <h3>Final Average</h3>
                      </td>
                      <td className="text-center" style={{ verticalAlign: "middle" }}>
                        {firstSemFinalAverage}
                      </td>
                    </tr>
                  </>
                ) : (
                  <tr>
                    <td colSpan="6" className="text-center">
                      No Grades Available
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            : ""}
          {details.grade_type_id != 4 && details.semester == "Second" ?
            <Table striped bordered>
              <thead>
                <tr
                  style={{ backgroundColor: "#800000", color: "white", textAlign: "center" }}
                >
                  <td>#</td>
                  <td>Subjects</td>
                  <td>Prelim</td>
                  <td>Midterm</td>
                  {/* <td>Semi Finals</td> */}
                  <td>Finals</td>
                  <td>Average</td>
                </tr>
              </thead>
              <tbody>
                {secondSemesterGrades.length >= 1 ? (
                  <>
                    {secondSemesterGrades.map((grade, index) => {
                      return (
                        <tr key={index}>
                          <td className="text-center" style={{ verticalAlign: "middle" }}>{index + 1}</td>
                          <td className="text-center" style={{ verticalAlign: "middle" }}>{grade.subject_name}</td>
                          <td className="text-center" style={{ verticalAlign: "middle" }}>{grade.first}</td>
                          <td className="text-center" style={{ verticalAlign: "middle" }}>{grade.second}</td>
                          <td className="text-center" style={{ verticalAlign: "middle" }}>{grade.third}</td>
                          {/* <td className="text-center" style={{ verticalAlign: "middle" }}>{grade.fourth}</td> */}
                          <td className="text-center" style={{ verticalAlign: "middle" }}>{grade.subject_average}</td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td colSpan="2" className="text-right font-weight-bolder" style={{ verticalAlign: "middle" }}>
                        Grading Average
                      </td>
                      <td className="text-center" style={{ verticalAlign: "middle" }}>
                        {secondSem1stAverage}
                      </td>
                      <td className="text-center" style={{ verticalAlign: "middle" }}>
                        {secondSem2ndAverage}
                      </td>
                      <td className="text-center" style={{ verticalAlign: "middle" }}>
                        {secondSem3rdAverage}
                      </td>
                      {/* <td className="text-center" style={{ verticalAlign: "middle" }}>
                        {secondSem4thAverage}
                      </td> */}
                    </tr>
                    <tr>
                      <td colSpan="5" className="text-right font-weight-bolder">
                        <h3>Final Average</h3>
                      </td>
                      <td className="text-center" style={{ verticalAlign: "middle" }}>
                        {secondSemFinalAverage}
                      </td>
                    </tr>
                  </>
                ) : (
                  <tr>
                    <td colSpan="6" className="text-center">
                      No Grades Available
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            : ""}
        </>
      ) : (
        <Row className="pt-5">
          <Col md="12" xs="12">
            <Table striped bordered>
              <thead>
                <tr style={{ backgroundColor: "#800000", color: "white" }}>
                  <td>#</td>
                  <td>Subjects</td>
                  <td>First Grading</td>
                  <td>Second Grading</td>
                  <td>Third Grading</td>
                  <td>Fourth Grading</td>
                  {details.grade_type_id != 1 ? <td>Average</td> : ""}
                </tr>
              </thead>
              <tbody>
                {basicGrades.length >= 1 ? (
                  <>
                    {basicGrades.map((grade, index) => {
                      return (
                        <tr key={index}>
                          <td className="text-center" style={{ verticalAlign: "middle" }}>{index + 1}</td>
                          <td className="text-center" style={{ verticalAlign: "middle" }}>{grade.subject_name}</td>
                          <td className="text-center" style={{ verticalAlign: "middle" }}>{grade.first}</td>
                          <td className="text-center" style={{ verticalAlign: "middle" }}>{grade.second}</td>
                          <td className="text-center" style={{ verticalAlign: "middle" }}>{grade.third}</td>
                          <td className="text-center" style={{ verticalAlign: "middle" }}>{grade.fourth}</td>
                          <td className="text-center" style={{ verticalAlign: "middle" }}>{grade.subject_average}</td>
                        </tr>
                      );
                    })}
                    {details.grade_type_id != 1 ?
                      <>
                        <tr>
                          <td colSpan="2" className="text-right font-weight-bolder" style={{ verticalAlign: "middle" }}>
                            Grading Average
                          </td>
                          <td className="text-center" style={{ verticalAlign: "middle" }}>
                            {firstSem1stAverage}
                          </td>
                          <td className="text-center" style={{ verticalAlign: "middle" }}>
                            {firstSem2ndAverage}
                          </td>
                          <td className="text-center" style={{ verticalAlign: "middle" }}>
                            {firstSem3rdAverage}
                          </td>
                          <td className="text-center" style={{ verticalAlign: "middle" }}>
                            {firstSem4thAverage}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="6" className="text-right font-weight-bolder">
                            <h3>Final Average</h3>
                          </td>
                          <td className="text-center" style={{ verticalAlign: "middle" }}>
                            {firstSemFinalAverage}
                          </td>
                        </tr>
                      </>
                      : ""}
                  </>
                ) : (
                  <tr>
                    <td colSpan={details.grade_type_id != 1 ? "7" : "6"} className="text-center">
                      No Grades Available
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
      )}
    </>
  );
};

export default StudentGrade;
