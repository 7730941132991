import React, { useState } from "react";
import {
  Button,
  Col,
  Row,
  Input,
  Label,
  Form,
  FormGroup,
  Card,
  CardBody,
  Alert,
} from "reactstrap";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import optionList from "../Admission/optionList";
import { studentGradeImportActions } from "../../../services/StudentGradeImportServices";

export const UploadGrades = ({
  gradeLevelSelection,
  coursesSelection,
  sections,
  gradeTypes,
}) => {
  const [gradeTypeFilter, setGradeTypeFilter] = useState(null);
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const { handleSubmit, errors, control, setValue, getValues } = useForm();
  const [file, setFile] = useState("");
  const [uploadError, setUploadError] = useState("");

  const withSemester = [3, 4, 5];
  const [filters, setFilters] = useState({
    sy_from: null,
    sy_to: null,
    grade_level: null,
    grade_type: null,
    semester: null,
    course_id: null,
    section_id: 3,
  });

  const onSubmit = (data) => {
    setButtonSpinner(true);
    setUploadError("");
    const formData = new FormData();
    formData.append("file", file[0]);
    formData.append("grade_type_id", data.grade_type_id.value);
    formData.append("sy_from", moment(data.sy_from).format("YYYY"));
    formData.append("sy_to", moment(data.sy_to).format("YYYY"));
    formData.append("semester", data?.semester ? data?.semester?.value : "");
    studentGradeImportActions.uploadGrades(
      formData,
      setButtonSpinner,
      setUploadError
    );
  };

  return (
    <Card className="p-2">
      <CardBody>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md="3" xs="12">
              <FormGroup>
                <Label>
                  <small className="font-weight-bolder">S.Y. From</small>
                </Label>
                <Controller
                  control={control}
                  name="sy_from"
                  defaultValue=""
                  rules={{ required: true }}
                  invalid={errors.sy_from}
                  render={({ onChange, value, onBlur, name }) => (
                    <DatePicker
                      className="form-control bg-light"
                      selected={
                        moment(filters.syFrom).isValid()
                          ? moment(filters.syFrom).toDate()
                          : filters.syFrom
                      }
                      onChange={(date) => {
                        setValue("sy_from", date);
                        setFilters({ ...filters, syFrom: date });
                        return date;
                      }}
                      showYearPicker
                      dateFormat="yyyy"
                      yearItemNumber={10}
                      name="sy_from"
                      defaultValue=""
                    />
                  )}
                />
                {errors.sy_from && errors.sy_from.type == "required" && (
                  <div
                    style={{
                      marginTop: "0.25rem",
                      fontSize: "80%",
                      color: "#f86c6b",
                    }}
                  >
                    School Year From is required!
                  </div>
                )}
              </FormGroup>
            </Col>
            <Col md="3" xs="12">
              <FormGroup>
                <Label>
                  <small className="font-weight-bolder">S.Y. To</small>
                </Label>
                <Controller
                  control={control}
                  name="sy_to"
                  defaultValue=""
                  rules={{
                    required: true,
                    validate: (value) => value > getValues("sy_from"),
                  }}
                  invalid={errors.sy_to}
                  render={({ onChange, value, onBlur, name }) => (
                    <DatePicker
                      className="form-control bg-light"
                      selected={
                        moment(filters.syTo).isValid()
                          ? moment(filters.syTo).toDate()
                          : filters.syTo
                      }
                      onChange={(date) => {
                        setValue("sy_to", date);
                        setFilters({ ...filters, syTo: date });
                        return date;
                      }}
                      showYearPicker
                      dateFormat="yyyy"
                      yearItemNumber={10}
                      name="sy_to"
                      defaultValue=""
                    />
                  )}
                />
                {errors.sy_to && errors.sy_to.type == "required" && (
                  <div
                    style={{
                      marginTop: "0.25rem",
                      fontSize: "80%",
                      color: "#f86c6b",
                    }}
                  >
                    School Year To is required!
                  </div>
                )}
                {errors.sy_to && errors.sy_to.type == "validate" && (
                  <div
                    style={{
                      marginTop: "0.25rem",
                      fontSize: "80%",
                      color: "#f86c6b",
                    }}
                  >
                    School Year To cannot be lower or equal to S.Y. From!
                  </div>
                )}
              </FormGroup>
            </Col>
            <Col md="3" xs="12" className="mb-2">
              <Label>
                <small className="font-weight-bolder">
                  <i className="fa fa-lightbulb-o"></i>&nbsp;Filter by Grade
                  Type
                </small>
              </Label>
              <Controller
                control={control}
                name="grade_type_id"
                rules={{ required: true }}
                invalid={errors.grade_type_id}
                defaultValue=""
                render={({ onChange, value, onBlur, name }) => (
                  <Select
                    options={gradeTypes?.map((type) => ({
                      value: type.id,
                      label: type.name,
                    }))}
                    onChange={(e) => {
                      setGradeTypeFilter(e.value);
                      setValue("grade_type_id", e);
                      return e;
                    }}
                    defaultValue=""
                  />
                )}
              />
              {errors?.grade_type_id && (
                <div
                  style={{
                    marginTop: "0.25rem",
                    fontSize: "80%",
                    color: "#f86c6b",
                  }}
                >
                  Grade type is required!
                </div>
              )}
            </Col>
            {gradeTypeFilter && withSemester.includes(gradeTypeFilter) && (
              <Col md="3" xs="12" className="mb-2">
                <Label>
                  <small className="font-weight-bolder">
                    <i className="fa fa-lightbulb-o"></i>&nbsp;Filter by
                    Semester
                  </small>
                </Label>
                <Controller
                  control={control}
                  name="semester"
                  rules={{ required: true }}
                  invalid={errors.semester}
                  defaultValue={null}
                  render={({ onChange, value, onBlur, name }) => (
                    <Select
                      options={optionList.semester}
                      onChange={(e) => {
                        setFilters({ ...filters, semester: e.value });
                        setValue("semester", e);
                        return e;
                      }}
                      defaultValue={null}
                    />
                  )}
                />
                {errors?.semester && (
                  <div
                    style={{
                      marginTop: "0.25rem",
                      fontSize: "80%",
                      color: "#f86c6b",
                    }}
                  >
                    Semester is required!
                  </div>
                )}
              </Col>
            )}
          </Row>
          <Row>
            <Col md="3" xs="12">
              <FormGroup>
                <Label
                  htmlFor="guardian_contact_no"
                  className="text-secondary font-weight-bolder"
                >
                  Browse File
                </Label>
                <Input
                  className="form-control bg-light"
                  name="file"
                  type="file"
                  accept=".xlsx"
                  onChange={(event) => {
                    setFile(event.target.files);
                    return event;
                  }}
                  defaultValue={""}
                />
              </FormGroup>
            </Col>
            {uploadError && (
              <Col md="12" xs="12">
                <Alert color="danger">{uploadError}</Alert>
              </Col>
            )}
            <Col md="12" xs="12">
              <Button
                disabled={buttonSpinner || !file}
                className="bg-danger-edit text-white border-0"
              >
                Upload
              </Button>
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
};
