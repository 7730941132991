import React, { useEffect, useState } from 'react';
import { Button, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, FormGroup, Form, Spinner, Input } from 'reactstrap';
import "@kenshooui/react-multi-select/dist/style.css";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { courseActions } from "../../../../services/CoursesServices";

const AddEdit = ({
  addOrEditModal,
  setAddOrEditModal,
  toggleAddOrEdit,
  course,
  action,
  setUpdateTable,
  updateTable,
  selectOptions,
  gradeTypeSelection
}) => {
    const { handleSubmit, errors, control, setValue, getValues, watch } = useForm();
    const [buttonSpinner, setButtonSpinner] = useState(false);
    const [gradeTypeSelected, setGradeTypeSelected] = useState('')
    const [newGradeLevel, setNewGradeLevel] = useState([])
    const [newCourseSelection, setNewCourseSelection] = useState([])

    const getOptionLabelByValue = (options, value) => {
        const option = options.find((option) => option.value == value);
        return option ? option.label : "";
    };
    
    const onSubmit = (data) => {
        setButtonSpinner(true);
        let dataToSubmit = {
          course_name: data.name,
          description: data.description,
          grade_type_id: data.grade_type_id ? data.grade_type_id.value : "",
          enabled: data.enabled ? data.enabled.value : "",
        }
        if(action == "add"){
          courseActions.addCourse(dataToSubmit, setButtonSpinner, setUpdateTable, updateTable, setAddOrEditModal)
        }else if("edit"){
          dataToSubmit.course_id = course.id;
          courseActions.updateCourse(dataToSubmit, setButtonSpinner, setUpdateTable, updateTable, setAddOrEditModal)
        }
    }

    return (
        <Modal isOpen={addOrEditModal} toggle={toggleAddOrEdit} size="lg" backdrop="static">
        <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader toggle={toggleAddOrEdit} className="bg-danger-edit text-white" charCode="">{action == "add" ? "Add Section" : "Edit Section"}</ModalHeader>
        <ModalBody>
            <Row>
            <Col md="12" xs="12">
                    <Label className="text-secondary font-weight-bolder">
                        Course Name
                    </Label>
                    <FormGroup>
                        <Controller
                          control={control}
                          name="name"
                          rules={{ required: true }}
                          invalid={errors.name ? true : false}
                          defaultValue={course.name}
                          render={({ onChange, value, onBlur, name }) => (
                            <Input
                              className="form-control bg-light"
                              name="name"
                              type="text"
                              onChange={(event) => {
                                setValue("name", event.target.value);
                                return event.target.value;
                              }}
                              defaultValue={course.name}
                            />
                          )}
                        />
                        {errors.name && errors.name.type == "required" && (
                          <small className="text-danger">
                            Course Name is required!
                          </small>
                        )}
                    </FormGroup>
                </Col>
                <Col md="12" xs="12">
                    <Label className="text-secondary font-weight-bolder">
                      Course Description
                    </Label>
                    <FormGroup>
                        <Controller
                          control={control}
                          name="description"
                          rules={{ required: true }}
                          invalid={errors.description ? true : false}
                          defaultValue={course.description}
                          render={({ onChange, value, onBlur, name }) => (
                            <Input
                              className="form-control bg-light"
                              name="description"
                              type="text"
                              onChange={(event) => {
                                setValue("description", event.target.value);
                                return event.target.value;
                              }}
                              defaultValue={course.description}
                            />
                          )}
                        />
                        {errors.description && errors.description.type == "required" && (
                          <small className="text-danger">
                            Course Description is required!
                          </small>
                        )}
                    </FormGroup>
                </Col>
                <Col md="6" xs="12">
                    <FormGroup>
                      <Label
                        htmlFor="grade_type_id"
                        className="text-secondary w-100 font-weight-bolder"
                      >
                        Select Grade Type
                      </Label>
                      <Controller
                        control={control}
                        name="grade_type_id"
                        defaultValue={
                            course.grade_type_id != ""
                              ? {
                                value: course.grade_type_id,
                                label: getOptionLabelByValue(
                                  gradeTypeSelection,
                                  course.grade_type_id
                                ),
                              }
                              : ""
                            }
                        rules={{ required: true }}
                        invalid={errors.grade_type_id ? true : false}
                        render={({ onChange, value, onBlur, name }) => (
                          <Select
                            options={gradeTypeSelection}
                            name="grade_type_id"
                            onChange={(e) => {
                              setValue("grade_type_id", e);
                              return e;
                            }}
                            defaultValue={
                                course.grade_type_id != ""
                              ? {
                                  value: course.grade_type_id,
                                  label: getOptionLabelByValue(
                                    gradeTypeSelection,
                                    course.grade_type_id
                                  ),
                                }
                              : ""
                            }
                          />
                        )}
                      />
                      {errors.grade_type_id &&
                        errors.grade_type_id.type == "required" && (
                          <small className="text-danger">
                            Grade/Year Level is required!
                          </small>
                        )}
                    </FormGroup>
                </Col>
                <Col md="6" xs="12">
                    <FormGroup>
                      <Label
                        htmlFor="enabled"
                        className="text-secondary w-100 font-weight-bolder"
                      >
                        Status
                      </Label>
                      <Controller
                        control={control}
                        name="enabled"
                        defaultValue={
                            course.enabled !== ""
                              ? {
                                value: course.enabled,
                                label: getOptionLabelByValue(
                                  selectOptions.status,
                                  course.enabled
                                ),
                              }
                              : ""
                            }
                        rules={{ required: true }}
                        invalid={errors.enabled ? true : false}
                        render={({ onChange, value, onBlur, name }) => (
                          <Select
                            options={selectOptions.status}
                            name="enabled"
                            onChange={(e) => {
                              setValue("enabled", e);
                              return e;
                            }}
                            defaultValue={
                                course.enabled !== ""
                              ? {
                                  value: course.enabled,
                                  label: getOptionLabelByValue(
                                    selectOptions.status,
                                    course.enabled
                                  ),
                                }
                              : ""
                            }
                          />
                        )}
                      />
                      {errors.enabled &&
                        errors.enabled.type == "required" && (
                          <small className="text-danger">
                            Status is required!
                          </small>
                        )}
                    </FormGroup>
                </Col>
            </Row>
        </ModalBody>
        <ModalFooter>
        <Button className="bg-warning-edit border-0" disabled={buttonSpinner}>
            {buttonSpinner ? (
              <>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </>
            ) : (
              "Save"
            )}
          </Button>
          <Button color="secondary" disabled={buttonSpinner} onClick={toggleAddOrEdit}>Close</Button>
        </ModalFooter>
        </Form>
      </Modal>
    )
}
export default AddEdit;