import React, { Fragment, useEffect, useState } from "react";
import {
  Container,
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  CustomInput,
  Input,
  Button,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import SapcLogo from "../../../assets/img/SAPC-Logo-red.png";
import "../../../App.css";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import optionList from "../Admission/optionList.js";
import { isDesktop } from "react-device-detect";

const TertiaryEducationForm = ({
  handleCancel,
  setEducationSelected,
  currentStep,
  setCurrentStep,
  setLoading,
  basicForm,
  setBasicForm,
}) => {
  const admissionAnswers = localStorage.getItem("admissionAnswers")
    ? JSON.parse(localStorage.getItem("admissionAnswers"))
    : "";
  const { register, handleSubmit, errors, control, setValue, getValues } =
    useForm();
  const [startDate, setStartDate] = useState("");

  const onSubmit = (data) => {
    setCurrentStep(1);
  };

  const getOptionLabelByValue = (options, value) => {
    const option = options.find((option) => option.value == value);
    return option ? option.label : "";
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Container fluid={true} className="my-5">
          <Row
            className={
              isDesktop
                ? "w-100 justify-content-center pl-5"
                : "justify-content-center"
            }
          >
            <Col md="8" xs="12">
              <Row className="border border-2 border-warning py-2 mb-5">
                <Col md="12" xs="12">
                  <Label>
                    <span className="font-weight-bolder">Education Type: </span>{" "}
                    {admissionAnswers.education_type}
                  </Label>
                </Col>
                <Col md="12" xs="12">
                  <Label>
                    <span className="font-weight-bolder">Student Type: </span>{" "}
                    {admissionAnswers.student_type
                      ? admissionAnswers.student_type.label
                      : "NA"}
                  </Label>
                </Col>
                <Col md="12" xs="12">
                  <Label>
                    <span className="font-weight-bolder">
                      Grade Year/Level:{" "}
                    </span>{" "}
                    {admissionAnswers.grade_year_level
                      ? admissionAnswers.grade_year_level.label
                      : "NA"}
                  </Label>
                </Col>
                <Col md="12" xs="12">
                  <Label>
                    <span className="font-weight-bolder">Course: </span>{" "}
                    {admissionAnswers.tertiary_course
                      ? admissionAnswers.tertiary_course.label
                      : "NA"}
                  </Label>
                </Col>
                <Col md="12" xs="12">
                  <Label>
                    <span className="font-weight-bolder">Semester: </span>{" "}
                    {admissionAnswers.semester
                      ? admissionAnswers.semester.label
                      : "NA"}
                  </Label>
                </Col>
              </Row>
              <Row className="border border-2 border-warning py-2 mb-5 px-3">
                <div className="w-100 px-2" style={{ marginTop: "-1.4rem" }}>
                  <Label
                    className="text-danger-edit text-center font-weight-bolder mb-1 px-3"
                    style={{ backgroundColor: "white" }}
                  >
                    SCHOOL YEAR
                  </Label>
                </div>
                <div className="w-100 py-4">
                  <Row>
                    <Col md="6" xs="12">
                      <FormGroup>
                        <Label className="text-secondary font-weight-bolder">
                          S.Y. From
                        </Label>
                        <Controller
                          control={control}
                          name="sy_from"
                          defaultValue={
                            moment(basicForm.sy_from).isValid()
                              ? moment(basicForm.sy_from).toDate()
                              : startDate
                          }
                          rules={{ required: true }}
                          invalid={errors.sy_from ? true : false}
                          render={({ onChange, value, onBlur, name }) => (
                            <DatePicker
                              className="form-control bg-light"
                              selected={
                                moment(basicForm.sy_from).isValid()
                                  ? moment(basicForm.sy_from).toDate()
                                  : startDate
                              }
                              onChange={(date) => {
                                // setStartDate(date);
                                setValue("sy_from", date);
                                setBasicForm({
                                  ...basicForm,
                                  sy_from: date,
                                });
                                return date;
                              }}
                              showYearPicker
                              dateFormat="yyyy"
                              yearItemNumber={9}
                              name="sy_from"
                              defaultValue={
                                moment(basicForm.sy_from).isValid()
                                  ? moment(basicForm.sy_from).toDate()
                                  : startDate
                              }
                            />
                          )}
                        />
                        {errors.sy_from &&
                          errors.sy_from.type == "required" && (
                            <div
                              style={{
                                marginTop: "0.25rem",
                                fontSize: "80%",
                                color: "#f86c6b",
                              }}
                            >
                              Schoo Year From is required!
                            </div>
                          )}
                      </FormGroup>
                    </Col>
                    <Col md="6" xs="12">
                      <FormGroup>
                        <Label className="text-secondary font-weight-bolder">
                          S.Y. To
                        </Label>
                        <Controller
                          control={control}
                          name="sy_to"
                          defaultValue={
                            moment(basicForm.sy_to).isValid()
                              ? moment(basicForm.sy_to).toDate()
                              : startDate
                          }
                          rules={{
                            required: true,
                            validate: (value) => value > getValues("sy_from"),
                          }}
                          invalid={errors.sy_to ? true : false}
                          render={({ onChange, value, onBlur, name }) => (
                            <DatePicker
                              className="form-control bg-light"
                              selected={
                                moment(basicForm.sy_to).isValid()
                                  ? moment(basicForm.sy_to).toDate()
                                  : startDate
                              }
                              onChange={(date) => {
                                // setStartDate(date);
                                setValue("sy_to", date);
                                setBasicForm({
                                  ...basicForm,
                                  sy_to: date,
                                });
                                return date;
                              }}
                              showYearPicker
                              dateFormat="yyyy"
                              yearItemNumber={9}
                              name="sy_to"
                              defaultValue={
                                moment(basicForm.sy_to).isValid()
                                  ? moment(basicForm.sy_to).toDate()
                                  : startDate
                              }
                            />
                          )}
                        />
                        {errors.sy_to && errors.sy_to.type == "required" && (
                          <div
                            style={{
                              marginTop: "0.25rem",
                              fontSize: "80%",
                              color: "#f86c6b",
                            }}
                          >
                            School Year To is required!
                          </div>
                        )}
                        {errors.sy_to && errors.sy_to.type == "validate" && (
                          <div
                            style={{
                              marginTop: "0.25rem",
                              fontSize: "80%",
                              color: "#f86c6b",
                            }}
                          >
                            School Year To cannot be lower or equal to S.Y.
                            From!
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </Row>
              <Row className="border border-2 border-warning py-2 mb-5 px-3">
                <div className="w-100 px-2" style={{ marginTop: "-1.4rem" }}>
                  <Label
                    className="text-danger-edit text-center font-weight-bolder mb-1 px-3"
                    style={{ backgroundColor: "white" }}
                  >
                    PERSONAL INFORMATION
                  </Label>
                </div>
                <div className="w-100 py-4">
                  <Row className="border-danger">
                    <Col md="4" xs="12">
                      <FormGroup>
                        <Label className="text-secondary font-weight-bolder w-100">
                          Last Name
                        </Label>
                        <Controller
                          control={control}
                          name="last_name"
                          rules={{ required: true }}
                          invalid={errors.last_name ? true : false}
                          defaultValue={basicForm.last_name}
                          render={({ onChange, value, onBlur, name }) => (
                            <Input
                              className="form-control bg-light"
                              name="last_name"
                              onChange={(event) => {
                                setBasicForm({
                                  ...basicForm,
                                  last_name: event.target.value,
                                });
                                setValue("last_name", event.target.value);
                                return basicForm.last_name;
                              }}
                              defaultValue={basicForm.last_name}
                            />
                          )}
                        />
                        {errors.last_name && (
                          <div
                            style={{
                              marginTop: "0.25rem",
                              fontSize: "80%",
                              color: "#f86c6b",
                            }}
                          >
                            Last name is required!
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="4" xs="12">
                      <FormGroup>
                        <Label className="text-secondary font-weight-bolder w-100">
                          First Name
                        </Label>
                        <Controller
                          control={control}
                          name="first_name"
                          rules={{ required: true }}
                          invalid={errors.first_name ? true : false}
                          defaultValue={basicForm.first_name}
                          render={({ onChange, value, onBlur, name }) => (
                            <Input
                              className="form-control bg-light"
                              name="first_name"
                              onChange={(event) => {
                                setBasicForm({
                                  ...basicForm,
                                  first_name: event.target.value,
                                });
                                setValue("first_name", event.target.value);
                                return basicForm.first_name;
                              }}
                              defaultValue={basicForm.first_name}
                            />
                          )}
                        />
                        {errors.first_name && (
                          <div
                            style={{
                              marginTop: "0.25rem",
                              fontSize: "80%",
                              color: "#f86c6b",
                            }}
                          >
                            First name is required!
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="4" xs="12">
                      <FormGroup>
                        <Label className="text-secondary font-weight-bolder w-100">
                          Middle Name
                        </Label>
                        <Controller
                          control={control}
                          name="middle_name"
                          rules={{ required: true }}
                          invalid={errors.middle_name ? true : false}
                          defaultValue={basicForm.middle_name}
                          render={({ onChange, value, onBlur, name }) => (
                            <Input
                              className="form-control bg-light"
                              name="middle_name"
                              onChange={(event) => {
                                setBasicForm({
                                  ...basicForm,
                                  middle_name: event.target.value,
                                });
                                setValue("middle_name", event.target.value);
                                return basicForm.middle_name;
                              }}
                              defaultValue={basicForm.middle_name}
                            />
                          )}
                        />
                        {errors.middle_name && (
                          <div
                            style={{
                              marginTop: "0.25rem",
                              fontSize: "80%",
                              color: "#f86c6b",
                            }}
                          >
                            Middle name is required!
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="4" xs="12">
                      <FormGroup>
                        <Label className="text-secondary font-weight-bolder w-100">
                          Suffix
                        </Label>
                        <Controller
                          control={control}
                          name="suffix"
                          rules={{ required: false }}
                          invalid={errors.suffix ? true : false}
                          defaultValue={basicForm.suffix}
                          render={({ onChange, value, onBlur, name }) => (
                            <Input
                              className="form-control bg-light"
                              name="suffix"
                              onChange={(event) => {
                                setBasicForm({
                                  ...basicForm,
                                  suffix: event.target.value,
                                });
                                setValue("suffix", event.target.value);
                                return basicForm.suffix;
                              }}
                              defaultValue={basicForm.suffix}
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="8" xs="12">
                      <Label className="text-secondary font-weight-bolder">
                        Birth Place
                      </Label>
                      <FormGroup>
                        <Controller
                          control={control}
                          name="birth_place"
                          rules={{ required: true }}
                          invalid={errors.birth_place ? true : false}
                          defaultValue={basicForm.birth_place}
                          render={({ onChange, value, onBlur, name }) => (
                            <Input
                              className="form-control bg-light"
                              name="birth_place"
                              onChange={(event) => {
                                setBasicForm({
                                  ...basicForm,
                                  birth_place: event.target.value,
                                });
                                setValue("birth_place", event.target.value);
                                return basicForm.birth_place;
                              }}
                              defaultValue={basicForm.birth_place}
                            />
                          )}
                        />
                        {errors.birth_place && (
                          <div
                            style={{
                              marginTop: "0.25rem",
                              fontSize: "80%",
                              color: "#f86c6b",
                            }}
                          >
                            Birth place is required!
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="4" xs="12">
                      <FormGroup>
                        <Label className="text-secondary font-weight-bolder">
                          Birth Date
                        </Label>
                        <Controller
                          control={control}
                          name="birth_date"
                          defaultValue={
                            moment(basicForm.birth_date).isValid()
                              ? moment(basicForm.birth_date).toDate()
                              : startDate
                          }
                          rules={{ required: true }}
                          invalid={errors.birth_date ? true : false}
                          render={({ onChange, value, onBlur, name }) => (
                            <DatePicker
                              className="form-control bg-light"
                              selected={
                                moment(basicForm.birth_date).isValid()
                                  ? moment(basicForm.birth_date).toDate()
                                  : startDate
                              }
                              onChange={(date) => {
                                setStartDate(date);
                                setValue("birth_date", date);
                                setBasicForm({
                                  ...basicForm,
                                  birth_date: date,
                                });
                                return date;
                              }}
                              showYearDropdown
                              placeholderText="mm/dd/YYYY"
                              name="birth_date"
                            />
                          )}
                        />
                        {errors.birth_date &&
                          errors.birth_date.type == "required" && (
                            <div
                              style={{
                                marginTop: "0.25rem",
                                fontSize: "80%",
                                color: "#f86c6b",
                              }}
                            >
                              Birth place is required!
                            </div>
                          )}
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col md="4" xs="12">
                      <FormGroup>
                        <Label
                          htmlFor="gender"
                          className="text-secondary font-weight-bolder"
                        >
                          Gender
                        </Label>
                        <Controller
                          control={control}
                          name="gender"
                          rules={{ required: true }}
                          invalid={errors.gender ? true : false}
                          defaultValue={
                            basicForm.gender != ""
                              ? {
                                  value: basicForm.gender,
                                  label: getOptionLabelByValue(
                                    optionList.gender,
                                    basicForm.gender
                                  ),
                                }
                              : ""
                          }
                          render={({ onChange, value, onBlur, name }) => (
                            <Select
                              options={optionList.gender}
                              onChange={(e) => {
                                setValue("gender", e);
                                setBasicForm({ ...basicForm, gender: e.value });

                                return e;
                              }}
                              defaultValue={
                                basicForm.gender != ""
                                  ? {
                                      value: basicForm.gender,
                                      label: getOptionLabelByValue(
                                        optionList.gender,
                                        basicForm.gender
                                      ),
                                    }
                                  : ""
                              }
                            />
                          )}
                        />
                        {errors.gender && (
                          <div
                            style={{
                              marginTop: "0.25rem",
                              fontSize: "80%",
                              color: "#f86c6b",
                            }}
                          >
                            Please select!
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="4" xs="12">
                      <Label className="text-secondary font-weight-bolder">
                        Age
                      </Label>
                      <FormGroup>
                        <Controller
                          control={control}
                          name="age"
                          rules={{ required: true, min: 1 }}
                          invalid={errors.age ? true : false}
                          defaultValue={basicForm.age}
                          render={({ onChange, value, onBlur, name }) => (
                            <Input
                              className="form-control bg-light"
                              name="age"
                              type="number"
                              onChange={(event) => {
                                setBasicForm({
                                  ...basicForm,
                                  age: event.target.value,
                                });
                                setValue("age", event.target.value);
                                return basicForm.age;
                              }}
                              defaultValue={basicForm.age}
                            />
                          )}
                        />
                        {errors.age && errors.age.type == "required" && (
                          <div
                            style={{
                              marginTop: "0.25rem",
                              fontSize: "80%",
                              color: "#f86c6b",
                            }}
                          >
                            Age is required!
                          </div>
                        )}
                        {errors.age && errors.age.type == "min" && (
                          <div
                            style={{
                              marginTop: "0.25rem",
                              fontSize: "80%",
                              color: "#f86c6b",
                            }}
                          >
                            Invalid age value
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="4" xs="12">
                      <FormGroup>
                        <Label
                          htmlFor="civil_status"
                          className="text-secondary font-weight-bolder"
                        >
                          Civil Status
                        </Label>
                        <Controller
                          control={control}
                          name="civil_status"
                          rules={{ required: true }}
                          invalid={errors.civil_status ? true : false}
                          defaultValue={
                            basicForm.civil_status != ""
                              ? {
                                  value: basicForm.civil_status,
                                  label: getOptionLabelByValue(
                                    optionList.civil_status,
                                    basicForm.civil_status
                                  ),
                                }
                              : ""
                          }
                          render={({ onChange, value, onBlur, name }) => (
                            <Select
                              options={optionList.civil_status}
                              onChange={(e) => {
                                setValue("civil_status", e);
                                setBasicForm({
                                  ...basicForm,
                                  civil_status: e.value,
                                });

                                return e;
                              }}
                              defaultValue={
                                basicForm.civil_status != ""
                                  ? {
                                      value: basicForm.civil_status,
                                      label: getOptionLabelByValue(
                                        optionList.civil_status,
                                        basicForm.civil_status
                                      ),
                                    }
                                  : ""
                              }
                            />
                          )}
                        />
                        {errors.civil_status && (
                          <div
                            style={{
                              marginTop: "0.25rem",
                              fontSize: "80%",
                              color: "#f86c6b",
                            }}
                          >
                            Civil status is required!
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="4" xs="12">
                      <Label className="text-secondary font-weight-bolder">
                        LRN
                      </Label>
                      <FormGroup>
                        <Controller
                          control={control}
                          name="lrn"
                          rules={{ required: true }}
                          invalid={errors.lrn ? true : false}
                          defaultValue={basicForm.lrn}
                          render={({ onChange, value, onBlur, name }) => (
                            <Input
                              className="form-control bg-light"
                              name="lrn"
                              type="text"
                              onChange={(event) => {
                                setBasicForm({
                                  ...basicForm,
                                  lrn: event.target.value,
                                });
                                setValue("lrn", event.target.value);
                                return basicForm.lrn;
                              }}
                              defaultValue={basicForm.lrn}
                            />
                          )}
                        />
                        {errors.lrn && errors.lrn.type == "required" && (
                          <div
                            style={{
                              marginTop: "0.25rem",
                              fontSize: "80%",
                              color: "#f86c6b",
                            }}
                          >
                            LRN is required!
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="4" xs="12">
                      <FormGroup>
                        <Label
                          htmlFor="student_status"
                          className="text-secondary font-weight-bolder"
                        >
                          Enrollment Status
                        </Label>
                        <Controller
                          control={control}
                          name="student_status"
                          rules={{ required: true }}
                          invalid={errors.student_status ? true : false}
                          defaultValue={
                            basicForm.student_status !== ""
                              ? {
                                  value: basicForm.student_status,
                                  label: getOptionLabelByValue(
                                    optionList.student_status,
                                    basicForm.student_status
                                  ),
                                }
                              : ""
                          }
                          render={({ onChange, value, onBlur, name }) => (
                            <Select
                              options={optionList.student_status}
                              onChange={(e) => {
                                setValue("student_status", e);
                                setBasicForm({
                                  ...basicForm,
                                  student_status: e.value,
                                });

                                return e;
                              }}
                              defaultValue={
                                basicForm.student_status !== ""
                                  ? {
                                      value: basicForm.student_status,
                                      label: getOptionLabelByValue(
                                        optionList.student_status,
                                        basicForm.student_status
                                      ),
                                    }
                                  : ""
                              }
                            />
                          )}
                        />
                        {errors.student_status && (
                          <div
                            style={{
                              marginTop: "0.25rem",
                              fontSize: "80%",
                              color: "#f86c6b",
                            }}
                          >
                            Please select!
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <small className="mt-3">
                  Please put N.A. if it does not apply to you
                </small>
              </Row>

              <Row className="border border-2 border-warning py-2 mb-5 px-3">
                <div className="w-100 px-2" style={{ marginTop: "-1.4rem" }}>
                  <Label
                    className="text-danger-edit text-center font-weight-bolder mb-1 px-3"
                    style={{ backgroundColor: "white" }}
                  >
                    HOME ADDRESS
                  </Label>
                </div>
                <div className="py-4">
                  <Row>
                    <Col md="4" xs="12">
                      <Label className="text-secondary font-weight-bolder">
                        House no., Building, Street
                      </Label>
                      <FormGroup>
                        <Controller
                          control={control}
                          name="street"
                          rules={{ required: true }}
                          invalid={errors.street ? true : false}
                          defaultValue={basicForm.street}
                          render={({ onChange, value, onBlur, name }) => (
                            <Input
                              className="form-control bg-light"
                              name="street"
                              onChange={(event) => {
                                setBasicForm({
                                  ...basicForm,
                                  street: event.target.value,
                                });
                                setValue("street", event.target.value);
                                return basicForm.street;
                              }}
                              defaultValue={basicForm.street}
                            />
                          )}
                        />
                        {errors.street && (
                          <div
                            style={{
                              marginTop: "0.25rem",
                              fontSize: "80%",
                              color: "#f86c6b",
                            }}
                          >
                            House no., Building, Street are required!
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="4" xs="12">
                      <FormGroup>
                        <Label className="text-secondary font-weight-bolder">
                          Province
                        </Label>
                        <Controller
                          control={control}
                          name="province"
                          rules={{ required: true }}
                          invalid={errors.province ? true : false}
                          defaultValue={basicForm.province}
                          render={({ onChange, value, onBlur, name }) => (
                            <Input
                              className="form-control bg-light"
                              name="province"
                              onChange={(event) => {
                                setBasicForm({
                                  ...basicForm,
                                  province: event.target.value,
                                });
                                setValue("province", event.target.value);
                                return basicForm.province;
                              }}
                              defaultValue={basicForm.province}
                            />
                          )}
                        />
                        {errors.province && (
                          <div
                            style={{
                              marginTop: "0.25rem",
                              fontSize: "80%",
                              color: "#f86c6b",
                            }}
                          >
                            Province is required!
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="4" xs="12">
                      <FormGroup>
                        <Label className="text-secondary font-weight-bolder">
                          City/Municipality
                        </Label>
                        <Controller
                          control={control}
                          name="city"
                          rules={{ required: true }}
                          invalid={errors.city ? true : false}
                          defaultValue={basicForm.city}
                          render={({ onChange, value, onBlur, name }) => (
                            <Input
                              className="form-control bg-light"
                              name="city"
                              onChange={(event) => {
                                setBasicForm({
                                  ...basicForm,
                                  city: event.target.value,
                                });
                                setValue("city", event.target.value);
                                return basicForm.city;
                              }}
                              defaultValue={basicForm.city}
                            />
                          )}
                        />
                        {errors.city && (
                          <div
                            style={{
                              marginTop: "0.25rem",
                              fontSize: "80%",
                              color: "#f86c6b",
                            }}
                          >
                            City/Municipality is required!
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="4" xs="12">
                      <FormGroup>
                        <Label className="text-secondary font-weight-bolder">
                          Barangay
                        </Label>
                        <Controller
                          control={control}
                          name="barangay"
                          rules={{ required: true }}
                          invalid={errors.barangay ? true : false}
                          defaultValue={basicForm.barangay}
                          render={({ onChange, value, onBlur, name }) => (
                            <Input
                              className="form-control bg-light"
                              name="barangay"
                              onChange={(event) => {
                                setBasicForm({
                                  ...basicForm,
                                  barangay: event.target.value,
                                });
                                setValue("barangay", event.target.value);
                                return basicForm.barangay;
                              }}
                              defaultValue={basicForm.barangay}
                            />
                          )}
                        />
                        {errors.barangay && (
                          <div
                            style={{
                              marginTop: "0.25rem",
                              fontSize: "80%",
                              color: "#f86c6b",
                            }}
                          >
                            Barangay is required!
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <small className="mt-3">
                  Please put N.A. if it does not apply to you
                </small>
              </Row>

              <Row className="border border-2 border-warning py-2 mb-5 px-3">
                <div className="w-100 px-2" style={{ marginTop: "-1.4rem" }}>
                  <Label
                    className="text-danger-edit text-center font-weight-bolder mb-1 px-3"
                    style={{ backgroundColor: "white" }}
                  >
                    FAMILY INFORMATION
                  </Label>
                </div>
                <div className="w-100 py-4">
                  <Row>
                    <Col md="9" xs="12">
                      <FormGroup>
                        <Label
                          htmlFor="father_name"
                          className="text-secondary font-weight-bolder"
                        >
                          Father's Name
                        </Label>
                        <Controller
                          control={control}
                          name="father_name"
                          rules={{ required: true }}
                          invalid={errors.father_name ? true : false}
                          defaultValue={basicForm.father_name}
                          render={({ onChange, value, onBlur, name }) => (
                            <Input
                              className="form-control bg-light"
                              name="father_name"
                              onChange={(event) => {
                                setBasicForm({
                                  ...basicForm,
                                  father_name: event.target.value,
                                });
                                setValue("father_name", event.target.value);
                                return event.target.value;
                              }}
                              defaultValue={basicForm.father_name}
                            />
                          )}
                        />
                        {errors.father_name && (
                          <div
                            style={{
                              marginTop: "0.25rem",
                              fontSize: "80%",
                              color: "#f86c6b",
                            }}
                          >
                            Father's name is required!
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="3" xs="12">
                      <FormGroup>
                        <Label
                          htmlFor="father_contact_no"
                          className="text-secondary font-weight-bolder"
                        >
                          Contact No
                        </Label>
                        <Controller
                          control={control}
                          name="father_contact_no"
                          rules={{ required: true }}
                          invalid={errors.father_contact_no ? true : false}
                          defaultValue={basicForm.father_contact_no}
                          render={({ onChange, value, onBlur, name }) => (
                            <Input
                              className="form-control bg-light"
                              name="father_contact_no"
                              onChange={(event) => {
                                setBasicForm({
                                  ...basicForm,
                                  father_contact_no: event.target.value,
                                });
                                setValue(
                                  "father_contact_no",
                                  event.target.value
                                );
                                return basicForm.father_contact_no;
                              }}
                              defaultValue={basicForm.father_contact_no}
                            />
                          )}
                        />
                        {errors.father_contact_no && (
                          <div
                            style={{
                              marginTop: "0.25rem",
                              fontSize: "80%",
                              color: "#f86c6b",
                            }}
                          >
                            Contact no is required!
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="9" xs="12">
                      <FormGroup>
                        <Label
                          htmlFor="father_home_address"
                          className="text-secondary font-weight-bolder"
                        >
                          Home Address
                        </Label>
                        <Controller
                          control={control}
                          name="father_home_address"
                          rules={{ required: true }}
                          invalid={errors.father_home_address ? true : false}
                          defaultValue={basicForm.father_home_address}
                          render={({ onChange, value, onBlur, name }) => (
                            <Input
                              className="form-control bg-light"
                              name="father_home_address"
                              onChange={(event) => {
                                setBasicForm({
                                  ...basicForm,
                                  father_home_address: event.target.value,
                                });
                                setValue(
                                  "father_home_address",
                                  event.target.value
                                );
                                return basicForm.father_home_address;
                              }}
                              defaultValue={basicForm.father_home_address}
                            />
                          )}
                        />
                        {errors.father_home_page && (
                          <div
                            style={{
                              marginTop: "0.25rem",
                              fontSize: "80%",
                              color: "#f86c6b",
                            }}
                          >
                            Home address is required!
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row className="py-4">
                    <Col md="9" xs="12">
                      <FormGroup>
                        <Label
                          htmlFor="mother_name"
                          className="text-secondary font-weight-bolder"
                        >
                          Mother's Name
                        </Label>
                        <Controller
                          control={control}
                          name="mother_name"
                          rules={{ required: true }}
                          invalid={errors.mother_name ? true : false}
                          defaultValue={basicForm.mother_name}
                          render={({ onChange, value, onBlur, name }) => (
                            <Input
                              className="form-control bg-light"
                              name="mother_name"
                              onChange={(event) => {
                                setBasicForm({
                                  ...basicForm,
                                  mother_name: event.target.value,
                                });
                                setValue("mother_name", event.target.value);
                                return basicForm.mother_name;
                              }}
                              defaultValue={basicForm.mother_name}
                            />
                          )}
                        />
                        {errors.mother_name && (
                          <div
                            style={{
                              marginTop: "0.25rem",
                              fontSize: "80%",
                              color: "#f86c6b",
                            }}
                          >
                            Mother's name is required!
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="3" xs="12">
                      <FormGroup>
                        <Label
                          htmlFor="mother_contact_no"
                          className="text-secondary font-weight-bolder"
                        >
                          Contact No
                        </Label>
                        <Controller
                          control={control}
                          name="mother_contact_no"
                          rules={{ required: true }}
                          invalid={errors.mother_contact_no ? true : false}
                          defaultValue={basicForm.mother_contact_no}
                          render={({ onChange, value, onBlur, name }) => (
                            <Input
                              className="form-control bg-light"
                              name="mother_contact_no"
                              onChange={(event) => {
                                setBasicForm({
                                  ...basicForm,
                                  mother_contact_no: event.target.value,
                                });
                                setValue(
                                  "mother_contact_no",
                                  event.target.value
                                );
                                return basicForm.mother_contact_no;
                              }}
                              defaultValue={basicForm.mother_contact_no}
                            />
                          )}
                        />
                        {errors.mother_contact_no && (
                          <div
                            style={{
                              marginTop: "0.25rem",
                              fontSize: "80%",
                              color: "#f86c6b",
                            }}
                          >
                            Contact no is required!
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="9" xs="12">
                      <FormGroup>
                        <Label
                          htmlFor="mother_home_address"
                          className="text-secondary font-weight-bolder"
                        >
                          Home Address
                        </Label>
                        <Controller
                          control={control}
                          name="mother_home_address"
                          rules={{ required: true }}
                          invalid={errors.mother_home_address ? true : false}
                          defaultValue={basicForm.mother_home_address}
                          render={({ onChange, value, onBlur, name }) => (
                            <Input
                              className="form-control bg-light"
                              name="mother_home_address"
                              onChange={(event) => {
                                setBasicForm({
                                  ...basicForm,
                                  mother_home_address: event.target.value,
                                });
                                setValue(
                                  "mother_home_address",
                                  event.target.value
                                );
                                return basicForm.mother_home_address;
                              }}
                              defaultValue={basicForm.mother_home_address}
                            />
                          )}
                        />
                        {errors.mother_home_address && (
                          <div
                            style={{
                              marginTop: "0.25rem",
                              fontSize: "80%",
                              color: "#f86c6b",
                            }}
                          >
                            Home address is required!
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row className="py-4">
                    <Col md="12" xs="12" className="mb-4">
                      <CustomInput
                        onClick={() => {
                          if (!basicForm.guardian_toggle) {
                            setBasicForm({
                              ...basicForm,
                              guardian_toggle: true,
                            });
                          } else {
                            setBasicForm({
                              ...basicForm,
                              guardian_toggle: false,
                            });
                          }
                        }}
                        type="switch"
                        defaultChecked={basicForm.guardian_toggle}
                        id="exampleCustomSwitch"
                        name="customSwitch"
                        label="Click here if guardian is the same as parents"
                      />
                    </Col>
                    {!basicForm.guardian_toggle ? (
                      <>
                        <Col md="9" xs="12">
                          <FormGroup>
                            <Label
                              htmlFor="guardian_name"
                              className="text-secondary font-weight-bolder"
                            >
                              Guardian Name
                            </Label>
                            <Controller
                              control={control}
                              name="guardian_name"
                              rules={{ required: true }}
                              invalid={errors.guardian_name ? true : false}
                              defaultValue={basicForm.guardian_name}
                              render={({ onChange, value, onBlur, name }) => (
                                <Input
                                  className="form-control bg-light"
                                  name="guardian_name"
                                  onChange={(event) => {
                                    setBasicForm({
                                      ...basicForm,
                                      guardian_name: event.target.value,
                                    });
                                    setValue(
                                      "guardian_name",
                                      event.target.value
                                    );
                                    return basicForm.guardian_name;
                                  }}
                                  defaultValue={basicForm.guardian_name}
                                />
                              )}
                            />
                            {errors.guardian_name && (
                              <div
                                style={{
                                  marginTop: "0.25rem",
                                  fontSize: "80%",
                                  color: "#f86c6b",
                                }}
                              >
                                Guardian name is required!
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                        <Col md="3" xs="12">
                          <FormGroup>
                            <Label
                              htmlFor="guardian_contact_no"
                              className="text-secondary font-weight-bolder"
                            >
                              Contact No
                            </Label>
                            <Controller
                              control={control}
                              name="guardian_contact_no"
                              rules={{ required: true }}
                              invalid={
                                errors.guardian_contact_no ? true : false
                              }
                              defaultValue={basicForm.guardian_contact_no}
                              render={({ onChange, value, onBlur, name }) => (
                                <Input
                                  className="form-control bg-light"
                                  name="guardian_contact_no"
                                  onChange={(event) => {
                                    setBasicForm({
                                      ...basicForm,
                                      guardian_contact_no: event.target.value,
                                    });
                                    setValue(
                                      "guardian_contact_no",
                                      event.target.value
                                    );
                                    return basicForm.guardian_contact_no;
                                  }}
                                  defaultValue={basicForm.guardian_contact_no}
                                />
                              )}
                            />
                            {errors.guardian_contact_no && (
                              <div
                                style={{
                                  marginTop: "0.25rem",
                                  fontSize: "80%",
                                  color: "#f86c6b",
                                }}
                              >
                                Contact no is required!
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                        <Col md="9" xs="12">
                          <FormGroup>
                            <Label
                              htmlFor="guardian_home_address"
                              className="text-secondary font-weight-bolder"
                            >
                              Home Address
                            </Label>
                            <Controller
                              control={control}
                              name="guardian_home_address"
                              rules={{ required: true }}
                              invalid={
                                errors.guardian_home_address ? true : false
                              }
                              defaultValue={basicForm.guardian_home_address}
                              render={({ onChange, value, onBlur, name }) => (
                                <Input
                                  className="form-control bg-light"
                                  name="guardian_home_address"
                                  onChange={(event) => {
                                    setBasicForm({
                                      ...basicForm,
                                      guardian_home_address: event.target.value,
                                    });
                                    setValue(
                                      "guardian_home_address",
                                      event.target.value
                                    );
                                    return basicForm.guardian_home_address;
                                  }}
                                  defaultValue={basicForm.guardian_home_address}
                                />
                              )}
                            />
                            {errors.guardian_home_address && (
                              <div
                                style={{
                                  marginTop: "0.25rem",
                                  fontSize: "80%",
                                  color: "#f86c6b",
                                }}
                              >
                                Home address is required!
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                      </>
                    ) : (
                      ""
                    )}
                  </Row>
                </div>
                <small className="mt-3">
                  Please put N.A. if it does not apply to you
                </small>
              </Row>

              <Row className="border border-2 border-warning py-2 mb-5 px-3">
                <div className="w-100 px-2" style={{ marginTop: "-1.4rem" }}>
                  <Label
                    className="text-danger-edit text-center font-weight-bolder mb-1 px-3"
                    style={{ backgroundColor: "white" }}
                  >
                    PRIOR EDUCATION
                  </Label>
                </div>
                <div className="w-100 py-4 text-center">
                  <Label className="text-danger-edit font-weight-bolder">
                    ELEMENTARY
                  </Label>
                  <Row className="w-100 py-4 text-left">
                    <Col md="9" xs="12">
                      <FormGroup>
                        <Label className="text-secondary font-weight-bolder">
                          School Name
                        </Label>
                        <Controller
                          control={control}
                          name="elementary_school_name"
                          rules={{ required: true }}
                          invalid={errors.elementary_school_name ? true : false}
                          defaultValue={basicForm.elementary_school_name}
                          render={({ onChange, value, onBlur, name }) => (
                            <Input
                              className="form-control bg-light"
                              name="elementary_school_name"
                              onChange={(event) => {
                                setBasicForm({
                                  ...basicForm,
                                  elementary_school_name: event.target.value,
                                });
                                setValue(
                                  "elementary_school_name",
                                  event.target.value
                                );
                                return basicForm.elementary_school_name;
                              }}
                              defaultValue={basicForm.elementary_school_name}
                            />
                          )}
                        />
                        {errors.elementary_school_name && (
                          <div
                            style={{
                              marginTop: "0.25rem",
                              fontSize: "80%",
                              color: "#f86c6b",
                            }}
                          >
                            School name is required!
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="3" xs="12">
                      <FormGroup>
                        <Label className="text-secondary font-weight-bolder">
                          Year Graduated
                        </Label>
                        <Controller
                          control={control}
                          name="elementary_year_graduated"
                          rules={{ required: true }}
                          invalid={
                            errors.elementary_year_graduated ? true : false
                          }
                          defaultValue={basicForm.elementary_year_graduated}
                          render={({ onChange, value, onBlur, name }) => (
                            <Input
                              className="form-control bg-light"
                              name="elementary_year_graduated"
                              onChange={(event) => {
                                setBasicForm({
                                  ...basicForm,
                                  elementary_year_graduated: event.target.value,
                                });
                                setValue(
                                  "elementary_year_graduated",
                                  event.target.value
                                );
                                return basicForm.elementary_year_graduated;
                              }}
                              defaultValue={basicForm.elementary_year_graduated}
                            />
                          )}
                        />
                        {errors.elementary_year_graduated && (
                          <div
                            style={{
                              marginTop: "0.25rem",
                              fontSize: "80%",
                              color: "#f86c6b",
                            }}
                          >
                            Graduation year is required!
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="9" xs="12">
                      <FormGroup>
                        <Label
                          htmlFor="elementary_school_address"
                          className="text-secondary font-weight-bolder"
                        >
                          School Address
                        </Label>
                        <Controller
                          control={control}
                          name="elementary_school_address"
                          rules={{ required: true }}
                          invalid={
                            errors.elementary_school_address ? true : false
                          }
                          defaultValue={basicForm.elementary_school_address}
                          render={({ onChange, value, onBlur, name }) => (
                            <Input
                              className="form-control bg-light"
                              name="elementary_school_address"
                              onChange={(event) => {
                                setBasicForm({
                                  ...basicForm,
                                  elementary_school_address: event.target.value,
                                });
                                setValue(
                                  "elementary_school_address",
                                  event.target.value
                                );
                                return basicForm.elementary_school_address;
                              }}
                              defaultValue={basicForm.elementary_school_address}
                            />
                          )}
                        />
                        {errors.elementary_school_address && (
                          <div
                            style={{
                              marginTop: "0.25rem",
                              fontSize: "80%",
                              color: "#f86c6b",
                            }}
                          >
                            School address is required!
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <div className="w-100 text-center">
                  <Label className="font-weight-bolder text-danger-edit">
                    JUNIOR HIGH SCHOOL
                  </Label>
                  <Row className="text-left">
                    <Col md="9" xs="12">
                      <FormGroup>
                        <Label className="text-secondary font-weight-bolder">
                          School Name
                        </Label>
                        <Controller
                          control={control}
                          name="junior_high_school_name"
                          rules={{ required: true }}
                          invalid={
                            errors.junior_high_school_name ? true : false
                          }
                          defaultValue={basicForm.junior_high_school_name}
                          render={({ onChange, value, onBlur, name }) => (
                            <Input
                              className="form-control bg-light"
                              name="junior_high_school_name"
                              onChange={(event) => {
                                setBasicForm({
                                  ...basicForm,
                                  junior_high_school_name: event.target.value,
                                });
                                setValue(
                                  "junior_high_school_name",
                                  event.target.value
                                );
                                return basicForm.junior_high_school_name;
                              }}
                              defaultValue={basicForm.junior_high_school_name}
                            />
                          )}
                        />
                        {errors.junior_high_school_name && (
                          <div
                            style={{
                              marginTop: "0.25rem",
                              fontSize: "80%",
                              color: "#f86c6b",
                            }}
                          >
                            School name is required!
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="3" xs="12">
                      <FormGroup>
                        <Label className="text-secondary font-weight-bolder">
                          Year Graduated
                        </Label>
                        <Controller
                          control={control}
                          name="junior_high_year_graduated"
                          rules={{ required: true }}
                          invalid={
                            errors.junior_high_year_graduated ? true : false
                          }
                          defaultValue={basicForm.junior_high_year_graduated}
                          render={({ onChange, value, onBlur, name }) => (
                            <Input
                              className="form-control bg-light"
                              name="junior_high_year_graduated"
                              onChange={(event) => {
                                setBasicForm({
                                  ...basicForm,
                                  junior_high_year_graduated:
                                    event.target.value,
                                });
                                setValue(
                                  "junior_high_year_graduated",
                                  event.target.value
                                );
                                return basicForm.junior_high_year_graduated;
                              }}
                              defaultValue={
                                basicForm.junior_high_year_graduated
                              }
                            />
                          )}
                        />
                        {errors.junior_high_year_graduated && (
                          <div
                            style={{
                              marginTop: "0.25rem",
                              fontSize: "80%",
                              color: "#f86c6b",
                            }}
                          >
                            Graduation year is required!
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="9" xs="12">
                      <FormGroup>
                        <Label
                          htmlFor="elementary_school_address"
                          className="text-secondary font-weight-bolder"
                        >
                          School Address
                        </Label>
                        <Controller
                          control={control}
                          name="junior_high_school_address"
                          rules={{ required: true }}
                          invalid={
                            errors.junior_high_school_address ? true : false
                          }
                          defaultValue={basicForm.junior_high_school_address}
                          render={({ onChange, value, onBlur, name }) => (
                            <Input
                              className="form-control bg-light"
                              name="junior_high_school_address"
                              onChange={(event) => {
                                setBasicForm({
                                  ...basicForm,
                                  junior_high_school_address:
                                    event.target.value,
                                });
                                setValue(
                                  "junior_high_school_address",
                                  event.target.value
                                );
                                return basicForm.junior_high_school_address;
                              }}
                              defaultValue={
                                basicForm.junior_high_school_address
                              }
                            />
                          )}
                        />
                        {errors.junior_high_school_address && (
                          <div
                            style={{
                              marginTop: "0.25rem",
                              fontSize: "80%",
                              color: "#f86c6b",
                            }}
                          >
                            School address is required!
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <div className="w-100 py-4 text-center">
                  <Label className="text-danger-edit font-weight-bolder">
                    SENIOR HIGH SCHOOL
                  </Label>
                  <Row className="w-100 py-4 text-left">
                    <Col md="9" xs="12">
                      <FormGroup>
                        <Label className="text-secondary font-weight-bolder">
                          School Name
                        </Label>
                        <Controller
                          control={control}
                          name="senior_high_school_name"
                          rules={{ required: true }}
                          invalid={
                            errors.senior_high_school_name ? true : false
                          }
                          defaultValue={basicForm.senior_high_school_name}
                          render={({ onChange, value, onBlur, name }) => (
                            <Input
                              className="form-control bg-light"
                              name="senior_high_school_name"
                              onChange={(event) => {
                                setBasicForm({
                                  ...basicForm,
                                  senior_high_school_name: event.target.value,
                                });
                                setValue(
                                  "senior_high_school_name",
                                  event.target.value
                                );
                                return basicForm.senior_high_school_name;
                              }}
                              defaultValue={basicForm.senior_high_school_name}
                            />
                          )}
                        />
                        {errors.senior_high_school_name && (
                          <div
                            style={{
                              marginTop: "0.25rem",
                              fontSize: "80%",
                              color: "#f86c6b",
                            }}
                          >
                            School name is required!
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="3" xs="12">
                      <FormGroup>
                        <Label className="text-secondary font-weight-bolder">
                          Year Graduated
                        </Label>
                        <Controller
                          control={control}
                          name="senior_high_year_graduated"
                          rules={{ required: true }}
                          invalid={
                            errors.senior_high_year_graduated ? true : false
                          }
                          defaultValue={basicForm.senior_high_year_graduated}
                          render={({ onChange, value, onBlur, name }) => (
                            <Input
                              className="form-control bg-light"
                              name="senior_high_year_graduated"
                              onChange={(event) => {
                                setBasicForm({
                                  ...basicForm,
                                  senior_high_year_graduated:
                                    event.target.value,
                                });
                                setValue(
                                  "senior_high_year_graduated",
                                  event.target.value
                                );
                                return basicForm.senior_high_year_graduated;
                              }}
                              defaultValue={
                                basicForm.senior_high_year_graduated
                              }
                            />
                          )}
                        />
                        {errors.senior_high_year_graduated && (
                          <div
                            style={{
                              marginTop: "0.25rem",
                              fontSize: "80%",
                              color: "#f86c6b",
                            }}
                          >
                            Graduation year is required!
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="9" xs="12">
                      <FormGroup>
                        <Label
                          htmlFor="senior_high_school_address"
                          className="text-secondary font-weight-bolder"
                        >
                          School Address
                        </Label>
                        <Controller
                          control={control}
                          name="senior_high_school_address"
                          rules={{ required: true }}
                          invalid={
                            errors.senior_high_school_address ? true : false
                          }
                          defaultValue={basicForm.senior_high_school_address}
                          render={({ onChange, value, onBlur, name }) => (
                            <Input
                              className="form-control bg-light"
                              name="senior_high_school_address"
                              onChange={(event) => {
                                setBasicForm({
                                  ...basicForm,
                                  senior_high_school_address:
                                    event.target.value,
                                });
                                setValue(
                                  "senior_high_school_address",
                                  event.target.value
                                );
                                return basicForm.senior_high_school_address;
                              }}
                              defaultValue={
                                basicForm.senior_high_school_address
                              }
                            />
                          )}
                        />
                        {errors.senior_high_school_address && (
                          <div
                            style={{
                              marginTop: "0.25rem",
                              fontSize: "80%",
                              color: "#f86c6b",
                            }}
                          >
                            School address is required!
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <small className="mt-3">
                  Please put N.A. if it does not apply to you
                </small>
              </Row>
              <Row>
                <Col md="6" xs="6" className="w-100">
                  <Button
                    onClick={handleCancel}
                    className="border-0 border-0 bg-white text-dark rounded-pill py-3 px-5 font-weight-bolder"
                  >
                    Cancel
                  </Button>
                </Col>

                <Col md="6" xs="6" className="w-100">
                  <Button className="bg-warning text-white border-0 rounded-pill py-3 px-5 font-weight-bolder">
                    Next
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col md="4" xs="12" className={isDesktop ? "pl-5" : "px-3 w-100"}>
              <Row className="mb-5">
                <div className="w-100 px-3">
                  <Label
                    className="text-danger-edit  font-weight-bolder mb-1"
                    style={{ backgroundColor: "white" }}
                  >
                    Admission Requirements
                  </Label>
                </div>
                <div className="px-3">
                  <p className="text-secondary">
                    <i>
                      Make sure to label the attachments properly, File must be
                      less than 3 MB of .jpg, png or pdf file.
                    </i>
                  </p>
                  <hr />
                  <Row className="flex-column">
                    <div className="d-flex mb-3">
                      <Col md="6" xs="6">
                        <Label className="text-secondary">
                          Form 138 (Grade 12 Senior High School Card)
                        </Label>
                      </Col>
                      <Col md="6" xs="6" className="px-3">
                        <div className="d-flex flex-column">
                          <div
                            className="fileUpload btn btn-warning text-white rounded-pill p-2"
                            style={{ width: "110px" }}
                          >
                            <span>Upload</span>
                            <Controller
                              control={control}
                              name="form138"
                              defaultValue={basicForm.form138}
                              rules={{ required: false }}
                              invalid={errors.form138 ? true : false}
                              render={({ onChange, value, onBlur, name }) => (
                                <Input
                                  type="file"
                                  name="form138"
                                  id="uploadBtn"
                                  className="upload"
                                  onChange={(e) =>
                                    setBasicForm({
                                      ...basicForm,
                                      form138: e.target.files[0],
                                    })
                                  }
                                  defaultValue={[]}
                                  // style={{ width: "110px" }}
                                />
                              )}
                            />
                          </div>
                          <Label className="text-secondary">
                            {basicForm.form138 ? basicForm.form138.name : ""}
                          </Label>
                        </div>
                      </Col>
                    </div>

                    <hr />
                    <div className="d-flex mb-3">
                      <Col md="6" xs="6">
                        <Label className="text-secondary">
                          Form 137 (Senior High School (SHS) Permanent Record)
                          with annotation "Eligible to Enter College Education")
                        </Label>
                      </Col>
                      <Col md="6" xs="6" className="mx-auto px-auto">
                        <div className="d-flex flex-column">
                          <div
                            className="fileUpload btn btn-warning text-white rounded-pill p-2"
                            style={{ width: "110px" }}
                          >
                            <span>Upload</span>
                            <Controller
                              control={control}
                              name="form137"
                              defaultValue={basicForm.form137}
                              rules={{ required: false }}
                              invalid={errors.form137 ? true : false}
                              render={({ onChange, value, onBlur, name }) => (
                                <Input
                                  type="file"
                                  name="form137"
                                  id="uploadBtn"
                                  className="upload"
                                  onChange={(e) => {
                                    setBasicForm({
                                      ...basicForm,
                                      form137: e.target.files[0],
                                    });
                                  }}
                                  defaultValue={[]}
                                  // style={{ width: "110px" }}
                                />
                              )}
                            />
                          </div>
                          <Label className="text-secondary">
                            {basicForm.form137 ? basicForm.form137.name : ""}
                          </Label>
                        </div>
                      </Col>
                    </div>

                    <hr />
                    <div className="d-flex mb-3">
                      <Col md="6" xs="6">
                        <Label className="text-secondary">
                          Copy of grades (if College Undergraduate)
                        </Label>
                      </Col>
                      <Col md="6" xs="6" className="mx-auto px-auto">
                        <div className="d-flex flex-column">
                          <div
                            className="fileUpload btn btn-warning text-white rounded-pill p-2"
                            style={{ width: "110px" }}
                          >
                            <span>Upload</span>
                            <Controller
                              control={control}
                              name="copy_of_grades"
                              defaultValue={[]}
                              rules={{ required: false }}
                              invalid={errors.copy_of_grades ? true : false}
                              render={({ onChange, value, onBlur, name }) => (
                                <Input
                                  type="file"
                                  name="copy_of_grades"
                                  id="uploadBtn"
                                  className="upload"
                                  onChange={(e) =>
                                    setBasicForm({
                                      ...basicForm,
                                      copy_of_grades: e.target.files[0],
                                    })
                                  }
                                  defaultValue={[]}
                                  // style={{ width: "110px" }}
                                />
                              )}
                            />
                          </div>
                          <Label className="text-secondary">
                            {basicForm.copy_of_grades
                              ? basicForm.copy_of_grades.name
                              : ""}
                          </Label>
                        </div>
                      </Col>
                    </div>

                    <hr />
                    <div className="d-flex mb-3">
                      <Col md="6" xs="6">
                        <Label className="text-secondary">
                          (2"x2") picture (Colored with white background and
                          name tag)
                        </Label>
                      </Col>
                      <Col md="6" xs="6">
                        <div className="d-flex flex-column">
                          <div
                            className="fileUpload btn btn-warning text-white rounded-pill p-2"
                            style={{ width: "110px" }}
                          >
                            <span>Upload</span>
                            <Controller
                              control={control}
                              name="picture"
                              defaultValue={basicForm.picture}
                              rules={{ required: false }}
                              invalid={errors.picture ? true : false}
                              render={({ onChange, value, onBlur, name }) => (
                                <Input
                                  type="file"
                                  name="picture"
                                  id="uploadBtn"
                                  className="upload"
                                  onChange={(e) =>
                                    setBasicForm({
                                      ...basicForm,
                                      picture: e.target.files[0],
                                    })
                                  }
                                  defaultValue={[]}
                                  // style={{ width: "110px" }}
                                />
                              )}
                            />
                          </div>
                          <Label className="text-secondary">
                            {basicForm.picture ? basicForm.picture.name : ""}
                          </Label>
                        </div>
                      </Col>
                    </div>
                    <div className="d-flex mb-3">
                      <Col md="6" xs="6">
                        <Label className="text-secondary">
                          Certificate of Good Moral Character signed by School
                          Principal/Guidance Officer with documentary stamp.
                        </Label>
                      </Col>
                      <Col md="6" xs="6" className="mx-auto px-auto">
                        <div className="d-flex flex-column">
                          <div
                            className="fileUpload btn btn-warning text-white rounded-pill p-2"
                            style={{ width: "110px" }}
                          >
                            <span>Upload</span>
                            <Controller
                              control={control}
                              name="good_moral"
                              defaultValue={basicForm.good_moral}
                              rules={{ required: false }}
                              invalid={errors.good_moral ? true : false}
                              render={({ onChange, value, onBlur, name }) => (
                                <Input
                                  type="file"
                                  name="good_moral"
                                  id="uploadBtn"
                                  className="upload"
                                  onChange={(e) =>
                                    setBasicForm({
                                      ...basicForm,
                                      good_moral: e.target.files[0],
                                    })
                                  }
                                  defaultValue={[]}
                                  // style={{ width: "110px" }}
                                />
                              )}
                            />
                          </div>
                          <Label className="text-secondary">
                            {basicForm.good_moral
                              ? basicForm.good_moral.name
                              : ""}
                          </Label>
                        </div>
                      </Col>
                    </div>
                    <div className="d-flex mb-3">
                      <Col md="6" xs="6">
                        <Label className="text-secondary">
                          Certified photocopy of SHS Diploma with LRN, Track and
                          indicated and/or National Assessment Test (NAT) for
                          Grade 12.
                        </Label>
                      </Col>
                      <Col md="6" xs="6" className="mx-auto px-auto">
                        <div className="d-flex flex-column">
                          <div
                            className="fileUpload btn btn-warning text-white rounded-pill p-2"
                            style={{ width: "110px" }}
                          >
                            <span>Upload</span>
                            <Controller
                              control={control}
                              name="shs_diploma"
                              defaultValue={basicForm.shs_diploma}
                              rules={{ required: false }}
                              invalid={errors.shs_diploma ? true : false}
                              render={({ onChange, value, onBlur, name }) => (
                                <Input
                                  type="file"
                                  name="shs_diploma"
                                  id="uploadBtn"
                                  className="upload"
                                  onChange={(e) =>
                                    setBasicForm({
                                      ...basicForm,
                                      shs_diploma: e.target.files[0],
                                    })
                                  }
                                  defaultValue={[]}
                                  // style={{ width: "110px" }}
                                />
                              )}
                            />
                          </div>
                          <Label className="text-secondary">
                            {basicForm.shs_diploma
                              ? basicForm.shs_diploma.name
                              : ""}
                          </Label>
                        </div>
                      </Col>
                    </div>
                    <hr />
                    <div className="d-flex mb-3">
                      <Col md="6" xs="6">
                        <Label className="text-secondary">
                          National Career Assessment Examination (NCAE) result
                          for Grades 10-12.
                        </Label>
                      </Col>
                      <Col md="6" xs="6" className="mx-auto px-auto">
                        <div className="d-flex flex-column">
                          <div
                            className="fileUpload btn btn-warning text-white rounded-pill p-2"
                            style={{ width: "110px" }}
                          >
                            <span>Upload</span>
                            <Controller
                              control={control}
                              name="ncae"
                              defaultValue={basicForm.ncae}
                              rules={{ required: false }}
                              invalid={errors.ncae ? true : false}
                              render={({ onChange, value, onBlur, name }) => (
                                <Input
                                  type="file"
                                  name="ncae"
                                  id="uploadBtn"
                                  className="upload"
                                  onChange={(e) =>
                                    setBasicForm({
                                      ...basicForm,
                                      ncae: e.target.files[0],
                                    })
                                  }
                                  defaultValue={[]}
                                  // style={{ width: "110px" }}
                                />
                              )}
                            />
                          </div>
                          <Label className="text-secondary">
                            {basicForm.ncae ? basicForm.ncae.name : ""}
                          </Label>
                        </div>
                      </Col>
                    </div>
                    <div className="d-flex mb-3">
                      <Col md="6" xs="6">
                        <Label className="text-secondary">
                          Photocopy of PSA authenticated Birth Certificate.
                        </Label>
                      </Col>
                      <Col md="6" xs="6" className="mx-auto px-auto">
                        <div className="d-flex flex-column">
                          <div
                            className="fileUpload btn btn-warning text-white rounded-pill p-2"
                            style={{ width: "110px" }}
                          >
                            <span>Upload</span>
                            <Controller
                              control={control}
                              name="birth_certificate"
                              defaultValue={basicForm.birth_certificate}
                              rules={{ required: false }}
                              invalid={errors.birth_certificate ? true : false}
                              render={({ onChange, value, onBlur, name }) => (
                                <Input
                                  type="file"
                                  name="birth_certificate"
                                  id="uploadBtn"
                                  className="upload"
                                  onChange={(e) =>
                                    setBasicForm({
                                      ...basicForm,
                                      birth_certificate: e.target.files[0],
                                    })
                                  }
                                  defaultValue={[]}
                                  // style={{ width: "110px" }}
                                />
                              )}
                            />
                          </div>
                          <Label className="text-secondary">
                            {basicForm.birth_certificate
                              ? basicForm.birth_certificate.name
                              : ""}
                          </Label>
                        </div>
                      </Col>
                    </div>
                    <div className="d-flex mb-3">
                      <Col md="6" xs="6">
                        <Label className="text-secondary">
                          For TES applicants, Certificate of Residency (for
                          Pila, Laguna Residents)
                        </Label>
                      </Col>
                      <Col md="6" xs="6" className="mx-auto px-auto">
                        <div className="d-flex flex-column">
                          <div
                            className="fileUpload btn btn-warning text-white rounded-pill p-2"
                            style={{ width: "110px" }}
                          >
                            <span>Upload</span>
                            <Controller
                              control={control}
                              name="cert_of_residency"
                              defaultValue={[]}
                              rules={{ required: false }}
                              invalid={errors.cert_of_residency ? true : false}
                              render={({ onChange, value, onBlur, name }) => (
                                <Input
                                  type="file"
                                  name="cert_of_residency"
                                  id="uploadBtn"
                                  className="upload"
                                  onChange={(e) =>
                                    setBasicForm({
                                      ...basicForm,
                                      cert_of_residency: e.target.files[0],
                                    })
                                  }
                                  defaultValue={[]}
                                  // style={{ width: "110px" }}
                                />
                              )}
                            />
                          </div>
                          <Label className="text-secondary">
                            {basicForm.cert_of_residency
                              ? basicForm.cert_of_residency.name
                              : ""}
                          </Label>
                        </div>
                      </Col>
                    </div>
                  </Row>
                </div>
              </Row>
              {basicForm.id != "" ? (
                <>
                  <Row className="mb-5">
                    <div className="w-100 px-3">
                      <Label
                        className="text-danger-edit  font-weight-bolder mb-2"
                        style={{ backgroundColor: "white" }}
                      >
                        Submitted Documents
                      </Label>
                    </div>
                    <Col md="12" xs="12">
                      <Label className="text-secondary">
                        <strong>Form138 - </strong>{" "}
                        {basicForm.id != "" && basicForm.form138_file_name
                          ? basicForm.form138_file_name
                          : ""}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label className="text-secondary">
                        <strong>Form137 - </strong>{" "}
                        {basicForm.id != "" && basicForm.form137_file_name
                          ? basicForm.form137_file_name
                          : ""}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label className="text-secondary">
                        <strong>Copy of Grades - </strong>{" "}
                        {basicForm.id != "" &&
                        basicForm.copy_of_grades_file_name
                          ? basicForm.copy_of_grades_file_name
                          : ""}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label className="text-secondary">
                        <strong>2"x2" Picture - </strong>{" "}
                        {basicForm.id != "" && basicForm.picture_file_name
                          ? basicForm.picture_file_name
                          : ""}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label className="text-secondary">
                        <strong>Good Moral - </strong>{" "}
                        {basicForm.id != "" && basicForm.good_moral_file_name
                          ? basicForm.good_moral_file_name
                          : ""}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label className="text-secondary">
                        <strong>SHS Diploma - </strong>{" "}
                        {basicForm.id != "" && basicForm.shs_diploma_file_name
                          ? basicForm.shs_diploma_file_name
                          : ""}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label className="text-secondary">
                        <strong>NCAE - </strong>{" "}
                        {basicForm.id != "" && basicForm.ncae_file_name
                          ? basicForm.ncae_file_name
                          : ""}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label className="text-secondary">
                        <strong>PSA (Birth Certificate) - </strong>{" "}
                        {basicForm.id != "" &&
                        basicForm.birth_certificate_file_name
                          ? basicForm.birth_certificate_file_name
                          : ""}
                      </Label>
                    </Col>
                    <Col md="12" xs="12">
                      <Label className="text-secondary">
                        <strong>Certificate of Residency</strong>{" "}
                        {basicForm.id != "" &&
                        basicForm.cert_of_residency_file_name
                          ? basicForm.cert_of_residency_file_name
                          : ""}
                      </Label>
                    </Col>
                  </Row>
                </>
              ) : (
                ""
              )}
            </Col>
          </Row>
        </Container>
      </Form>
    </>
  );
};

export default TertiaryEducationForm;
