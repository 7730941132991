import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  FormGroup,
  Input,
  Form,
  FormFeedback,
  Spinner,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { isDesktop } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { authActions } from "../../../services/AuthService";

const SubmitCode = ({
  emailAdress,
  setForgotPwProcess,
  setVerificationCode,
}) => {
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    register,
    errors,
    reset,
    getValues,
    setValue,
  } = useForm();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const [modal, setModal] = useState(true);
  const toggle = () => {
    if (!modal) {
      setModal(!modal);
    } else {
      setModal(!modal);
      navigate("/login");
    }
  };

  const onSubmit = (data) => {
    setButtonSpinner(true);
    authActions.submitCode(
      data,
      setError,
      setSuccess,
      setButtonSpinner,
      setForgotPwProcess,
      setVerificationCode
    );
  };

  const handleResend = () => {
    setResendLoading(true);
    let data = {
      email_address: emailAdress,
    };
    authActions.forgotPassword(
      data,
      setError,
      setSuccess,
      setResendLoading,
      setForgotPwProcess
    );
  };

  return (
    <div className="justify-content-center align-items-center d-flex">
      <Modal
        isOpen={modal}
        toggle={toggle}
        backdrop="static"
        className="landingPage modal-center"
      >
        <ModalBody>
          <>
            <span
              style={{ fontSize: "25px" }}
              className="font-weight-bolder hand-pointer"
              placeholder="Back"
              onClick={() => navigate("/login")}
            >
              &#8592;
            </span>
            <div
              className={
                isDesktop
                  ? "text-center mt-5 mb-5 mx-5"
                  : "text-center mt-5 mb-5"
              }
            >
              <h3 className="font-weight-bolder">
                <i className="fa fa-lock" style={{ fontSize: "50px" }}></i>{" "}
                VERIFICATION CODE
              </h3>
              <p className="mt-3 mb-3 text-secondary font-weight-bolder">
                Please enter the code sent to your email address.
              </p>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <FormGroup>
                  <Controller
                    as={
                      <Input className="form-control bg-light signIn rounded-0" />
                    }
                    value=""
                    defaultValue=""
                    type="text"
                    id="verification_code"
                    name="verification_code"
                    className="text-center"
                    control={control}
                    rules={{ required: true }}
                    invalid={errors.verification_code ? true : false}
                    onChange={(e) => {
                      return e.target.value;
                    }}
                  />
                  {errors.verification_code &&
                    errors.verification_code.type == "required" && (
                      <FormFeedback>
                        Verification Code is required!
                      </FormFeedback>
                    )}
                </FormGroup>
                <Button
                  disabled={buttonSpinner}
                  className="bg-warning-edit text-white font-weight-bolder border-0 mb-2 w-100 button-transition"
                >
                  {buttonSpinner ? (
                    <>
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      &nbsp;Processing...
                    </>
                  ) : (
                    "SUBMIT"
                  )}
                </Button>
              </Form>
              {!resendLoading ? (
                <p className="text-secondary-edit">
                  <span
                    style={{ cursor: "pointer", color: "#800000" }}
                    id="resend"
                    onClick={handleResend}
                  >
                    Resend verification code
                  </span>
                  , If no email received.
                </p>
              ) : (
                <>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  &nbsp;Processing...
                </>
              )}
            </div>
          </>
        </ModalBody>
      </Modal>
    </div>
  );
};
export default SubmitCode;
