import { ENDPOINT } from "../constants/api";
import axios from "axios";
import { notifyError, notifySuccess } from "./Toaster";
import moment from "moment";

export const registrarActions = {
  getEnrolledStudents,
  updateStudentDetails,
  getSections,
  getSection,
  updateStudentList,
  uploadSchedule,
  downloadStudentList,
  downloadReport
};

const token = localStorage.getItem("jwtToken")
  ? localStorage.getItem("jwtToken")
  : "";
const headers = {
  Authorization: `Bearer ${token}`,
  "Content-Type": "application/json",
  "X-Requested-With": "XMLHttpRequest",
};

async function getEnrolledStudents(
  setData,
  setSummary,
  setLoading,
  setTotalSize,
  page1,
  sizePerPageQuery,
  searchText,
  filters,
  setButtonSpinner
) {
  try {
    let q = searchText.trim();
    let filter = `?q=${q}&sizePerPage=${sizePerPageQuery}&page=${page1}&sy_from=${filters.sy_from}&sy_to=${filters.sy_to}&student_status=${filters.student_status}&grade_level=${filters.gradeLevel}&course=${filters.course}&semester=${filters.semester}`;

    let res = await axios.get(ENDPOINT + `/student-record` + filter,  { headers: headers });

    const data = res.data.data.student_record.data;
    setTotalSize(res.data.data.student_record.total)
    const studentSummary = res.data.data.summary;
    let dataArr = [];
    if (data.length >= 1) {
      data.map((d) => {
        dataArr.push({
          id: d.student_detail.id,
          student_status: d.student_status,
          application_id: d.id,
          semester: d.semester == "1" ? "Second" : d.semester == "0" ? "First" : "-",
          ref_no: d.ref_no,
          student_id: d.student_detail.student_id
            ? d.student_detail.student_id
            : "",
          education_type: d.grade_level_id && d.grade_level_id.grade_type_id && d.grade_level_id.grade_type_id.education_type_id ? d.grade_level_id.grade_type_id.education_type_id.name : "",
          last_name: d.student_detail.last_name,
          given_name: d.student_detail.given_name,
          middle_name: d.student_detail.middle_name,
          grade_level: d.grade_level_id ? d.grade_level_id.name : "Tesda Application",
          email_address: d.users.email_address,
          suffix: d.student_detail.suffix,
          birth_place: d.student_detail.birth_place,
          birth_date: d.student_detail.birth_date,
          gender: d.student_detail.gender,
          religion: d.student_detail.religion,
          lrn: d.student_detail.lrn,
          age: d.student_detail.age,
          civil_status:
            d.student_detail.civil_status != null
              ? d.student_detail.civil_status
              : "",
          house_bldg_street: d.student_detail.house_bldg_street,
          province: d.student_detail.province,
          city_municipality: d.student_detail.city_municipality,
          barangay: d.student_detail.barangay,
          living_status:
            d.student_detail.living_status != null
              ? d.student_detail.living_status
              : "",
          no_of_siblings: d.student_detail.no_of_siblings
            ? d.student_detail.no_of_siblings
            : "",
          health_student_boolean:
            d.health_additional_info &&
              d.health_additional_info.health_student_boolean
              ? d.health_additional_info.health_student_boolean
              : "",
          health_student_specific:
            d.health_additional_info &&
              d.health_additional_info.health_student_specific
              ? d.health_additional_info.health_student_specific
              : "",
          consulting_doctor:
            d.health_additional_info &&
              d.health_additional_info.consulting_doctor
              ? d.health_additional_info.consulting_doctor
              : "",
          important_illness:
            d.health_additional_info &&
              d.health_additional_info.important_illness
              ? d.health_additional_info.important_illness
              : "",
          hospitalized_in_past:
            d.health_additional_info &&
              d.health_additional_info.hospitalized_in_past
              ? d.health_additional_info.hospitalized_in_past
              : "",
          specific_hospitalized_in_past:
            d.health_additional_info &&
              d.health_additional_info.specific_hospitalized_in_past
              ? d.health_additional_info.specific_hospitalized_in_past
              : "",
          difficulty:
            d.health_additional_info && d.health_additional_info.difficulty && d.health_additional_info.difficulty.length >= 1
              ? d.health_additional_info.difficulty.map(diff => { return diff.label })
              : "",
          family_health_history:
            d.health_additional_info &&
              d.health_additional_info.family_health_history
              ? d.health_additional_info.family_health_history
              : "",
          family_member_with_sickness:
            d.health_additional_info &&
              d.health_additional_info.family_member_with_sickness
              ? d.health_additional_info.family_member_with_sickness
              : "",
          vaccination:
            d.health_additional_info && d.health_additional_info.vaccination
              ? d.health_additional_info.vaccination
              : "",
          self_evaluation:
            d.health_additional_info &&
              d.health_additional_info.self_evaluation
              ? d.health_additional_info.self_evaluation
              : "",
          financial_support:
            d.health_additional_info &&
              d.health_additional_info.financial_support
              ? d.health_additional_info.financial_support
              : "",
          other_financial_support:
            d.health_additional_info &&
              d.health_additional_info.other_financial_support
              ? d.health_additional_info.other_financial_support
              : "",
          grew_up:
            d.health_additional_info && d.health_additional_info.grew_up
              ? d.health_additional_info.grew_up
              : "",
          language_home:
            d.health_additional_info && d.health_additional_info.language_home
              ? d.health_additional_info.language_home
              : "",
          other_language_home:
            d.health_additional_info &&
              d.health_additional_info.other_language_home
              ? d.health_additional_info.other_language_home
              : "",
          concerns:
            d.health_additional_info && d.health_additional_info.concerns
              ? d.health_additional_info.concerns
              : "",
          other_concern:
            d.health_additional_info && d.health_additional_info.other_concern
              ? d.health_additional_info.other_concern
              : "",
          problem_solution:
            d.health_additional_info &&
              d.health_additional_info.problem_solution
              ? d.health_additional_info.problem_solution
              : "",
          other_problem_solution:
            d.health_additional_info &&
              d.health_additional_info.other_problem_solution
              ? d.health_additional_info.other_problem_solution
              : "",
          cope_up_stress:
            d.health_additional_info &&
              d.health_additional_info.cope_up_stress
              ? d.health_additional_info.cope_up_stress
              : "",
          other_cope_up_stress:
            d.health_additional_info &&
              d.health_additional_info.other_cope_up_stress
              ? d.health_additional_info.other_cope_up_stress
              : "",
          how_to_know_sapc:
            d.health_additional_info &&
              d.health_additional_info.how_to_know_sapc
              ? d.health_additional_info.how_to_know_sapc
              : "",
          other_how_to_know_sapc:
            d.health_additional_info &&
              d.health_additional_info.other_how_to_know_sapc
              ? d.health_additional_info.other_how_to_know_sapc
              : "",
          listahan:
            d.health_additional_info && d.health_additional_info.listahan
              ? d.health_additional_info.listahan
              : "",
          household_number:
            d.health_additional_info &&
              d.health_additional_info.household_number
              ? d.health_additional_info.household_number
              : "",
          disability:
            d.health_additional_info && d.health_additional_info.disability
              ? d.health_additional_info.disability
              : "",
          pwd_number:
            d.health_additional_info && d.health_additional_info.pwd_number
              ? d.health_additional_info.pwd_number
              : "",
          subsidy:
            d.health_additional_info && d.health_additional_info.subsidy
              ? d.health_additional_info.subsidy
              : "",
          subsidy_details:
            d.health_additional_info &&
              d.health_additional_info.subsidy_details
              ? d.health_additional_info.subsidy_details
              : "",
          student_type: d.student_type_id,
          remarks: d.remarks,
          subjects: d.student_subject,
          old_attachments: d.student_document ? d.student_document : [],
          date_of_appointment:
            d.health_additional_info &&
              d.health_additional_info.date_of_appointment
              ? d.health_additional_info.date_of_appointment
              : "",
          course: d.health_additional_info &&
            d.health_additional_info.admissionAnswers &&
            d.health_additional_info.admissionAnswers.tertiary_course
            ? d.health_additional_info.admissionAnswers.tertiary_course.label
            : ""
        });
      });
    }

    const summaryFinal = {
      cancel_count: studentSummary.cancel_count,
      pending_count: studentSummary.pending_count,
      certify_count: studentSummary.certify_count,
      enrolled_count: studentSummary.enrolled_count,
      last_update: studentSummary.user_log
        ? studentSummary.user_log.user.fullname
        : "N/A",
      updated_at: studentSummary.user_log
        ? moment(studentSummary.user_log.updated_at).format("MMMM DD, YYYY")
        : "N/A",
    };
    setData(dataArr);
    setSummary(summaryFinal);
    setLoading(false);
    setButtonSpinner(false)
  } catch (err) {
    console.log(err)
      notifyError("Error while fetching students list");
    setButtonSpinner(false)
  }
}

async function updateStudentDetails(
  data,
  setButtonSpinner,
  setEditDetails,
  setUpdateTable,
  updateTable
) {
  try {
    let res = await axios.post(ENDPOINT + `/update-record`, data, {
      headers: headers,
    });

    setUpdateTable(!updateTable);
    setEditDetails(false);
    setButtonSpinner(false);
    notifySuccess(res.data.message);
  } catch (err) {
    if (err.response) {
      notifyError("Error while updating record");
    }
    setButtonSpinner(false);
  }
}

async function getSections(setData, setLoading, courses, filters, semesterFilter, gradeLevelIdFilter, searchText) {
  try {
    let q = searchText.trim();
    let filter = `?q=${q}&grade_level=${filters.gradeLevel}&semester=${semesterFilter}&grade_type=${gradeLevelIdFilter}&start_year=${filters.start_year}&end_year=${filters.end_year}`
    let res = await axios.get(ENDPOINT + `/section-list` + filter, {
      headers: headers,
    });
    const sections = res.data.data.section_list;
    let sectionArr = sections.map(section => {
      return {
        id: section.section.id,
        grade_level: section.section.grade_level_id[0].name,
        grade_level_id: section.section.grade_level_id[0].id,
        grade_type: section.section.grade_level_id[0].grade_type_id.id,
        section: section.section.name,
        adviser: section.section.adviser ? section.section.adviser.fullname : "",
        course_type_id: section.section.course_type_id,
        totalStudents: section.total_student,
        studentSchedule: section.section.schedule ? section.section.schedule.bucket_link : "",
        courseCodeArr: courses.filter(course => {
          let courseName = course.name == "AB Psy" ? "abpsy" : course.name.toLowerCase();
          if (section.section.name.toLowerCase().includes(courseName)) {
            return course;
          }
        })
      }
    })
    setData(sectionArr);
    setLoading(false);
  } catch (err) {
    if (err.response) {
      notifyError("Error while fetching Section List");
    }
    setLoading(false);
  }
}

async function getSection(IDs, setAddOrEditStudentList, setSection, details) {
  try {
    let filter = `?grade_level_id=${IDs.grade_level_id}&grade_type=${IDs.grade_type}&section_id=${IDs.section_id}&semester=${IDs.semester}&start_year=${IDs.start_year}&end_year=${IDs.end_year}&course=${IDs.course}`
    let res = await axios.get(ENDPOINT + `/student-list` + filter, {
      headers: headers,
    });
    const students = res.data.data;
    let allStudents = [];
    let withSectionStudents = [];
    if (students.with_section.length >= 1) {
      students.with_section.map(section => {
        allStudents.push({ id: section.application_id, label: section.full_name })
        withSectionStudents.push({ id: section.application_id, label: section.full_name })
      })
    }
    if (students.no_section.length >= 1) {
      students.no_section.map(section => {
        allStudents.push({ id: section.application_id, label: section.full_name })
      })
    }

    students.teacher_list.push({ value: null, label: "-Select-" })

    const data = {
      id: details.id,
      grade_level_id: details.grade_level_id,
      grade_level: details.grade_level,
      grade_type: details.grade_type,
      semester: IDs.semester,
      section: details.section,
      allStudents: allStudents,
      withSection: withSectionStudents,
      adviser_id: students.adviser_coordinator[0].adviser_id,
      coordinator_id: students.adviser_coordinator[0].coordinator_id,
      adviser_lists: students.teacher_list,
    }

    setSection(data);
    setAddOrEditStudentList(true);

  } catch (err) {
    if (err.response) {
      notifyError("Error while fetching Section List");
    }
  }
}

async function updateStudentList(dataToSubmit, setEditStudents, setButtonSpinner, updateTable, setUpdateTable) {
  try {
    let res = await axios.post(ENDPOINT + `/update-student`, dataToSubmit, {
      headers: headers,
    });

    setEditStudents(false);
    setButtonSpinner(false);
    notifySuccess(res.data.message);
    setUpdateTable(!updateTable);

  } catch (err) {
    if (err.response && err.response.data && err.response.data.message && err.response.data.message.adviser_id) {
      notifyError("The adviser has already assigned to other section")
    } else if (err.response && err.response.data && err.response.data.message && err.response.data.message.coordinator_id) {
      notifyError("The coordinator has already assigned to other section")
    } else {
      notifyError("Error while updating student list");
    }
    setButtonSpinner(false);
  }
}

async function uploadSchedule(dataToSubmit, setUploadModal, setButtonSpinner, updateTable, setUpdateTable) {
  try {
    let res = await axios.post(ENDPOINT + `/upload-schedule`, dataToSubmit, {
      headers: headers,
    });

    setButtonSpinner(false);
    notifySuccess("Schedule uploaded successfully");
    setUpdateTable(!updateTable);
    setUploadModal(false)

  } catch (err) {
    if (err.response) {
      notifyError("Error while upload section schedule");
    }
    setButtonSpinner(false);
  }
}

async function downloadStudentList(dataToSubmit, setButtonSpinner) {
  try {
    let res = await axios.post(ENDPOINT + `/section-list-export`, dataToSubmit, {
      headers: headers,
    });
    setButtonSpinner(false)
    const exportUrl = res.data;
    let win = window.open(exportUrl, "_blank");
    win.focus();
  } catch (err) {
    if (err.response) {
      notifyError("Error while downloading section");
    }
    setButtonSpinner(false);
  }
}

async function downloadReport(dataToSubmit, setButtonSpinner) {
  try {
    let res = await axios.post(ENDPOINT + `/index-performance-export`, dataToSubmit, {
      headers: headers,
    });
    setButtonSpinner(false)
    const exportUrl = res.data;
    let win = window.open(exportUrl, "_blank");
    win.focus();
  } catch (err) {
    if (err.response) {
      notifyError("Error while downloading section performance report");
    }
    setButtonSpinner(false);
  }
}