// export const API_URL = "http://localhost:88";
// export const API_URL = "http://localhost:8000";

/* DEV API */
// export const API_URL = "http://ec2-13-250-37-222.ap-southeast-1.compute.amazonaws.com";
// export const API_URL = "https://sapc-backend.ml"; //development

export const API_URL = "https://d2m027hrey7m1j.cloudfront.net"; // production

//EDNPOINT
export const ENDPOINT = `${API_URL}/api`;

//S3 Url
export const S3_URL = "https://sapcbucket.s3.amazonaws.com/dynamic_images/";
