import { ENDPOINT } from "../constants/api";
import axios from "axios";
import { notifyError, notifySuccess } from "./Toaster";

export const coordinatorActions = {
  getSubjects,
  getStudents,
  updateStudentGrades,
  approveStudentGrades,
  disapproveStudentGrades,
  approveAllGrades,
  disapproveAllGrades,
  exportSF10,
  adminGetStudents,
  getSingleStudent,
  getStudentSections,
  exportGeneralGrading
};

const token = localStorage.getItem('jwtToken') ? localStorage.getItem('jwtToken') : "";
const headers = {
  "Content-Type": "application/json",
  "X-Requested-With": "XMLHttpRequest",
  "Access-Control-Allow-Origin": "*",
  "Authorization": `Bearer ${token}`
};

async function getSubjects(setSubjects, semester) {
  try {
    const res = await axios.get(ENDPOINT + '/get-subject-list' + `?semester=${semester}`, { headers: headers })

    setSubjects(res.data.data)
  } catch (err) {
    notifyError("Error while getting subject list")
  }
}

async function getStudents(newFilters, setData, setLoading, setButtonSpinner, sizePerPageQuery, page1, setTotalSize) {
  try {
    let q = newFilters.search_text.trim();
    let filter = `?q=${q}&sizePerPage=${sizePerPageQuery}&page=${page1}&sy_from=${newFilters.sy_from}&sy_to=${newFilters.sy_to}&semester=${newFilters.semester}&grade_level=${newFilters.grade_level}&course=${newFilters.course}&section_id=${newFilters.section_id}`;

    const res = await axios.get(ENDPOINT + '/coordinator/student-list' + filter, { headers: headers })
    let data = res.data.data.data.map(d => {
      console.log(d)
      return {
        fullname: d.student_detail_id ? d.student_detail_id.fullname : "-",
        grade_level: d.grade_level_id ? d.grade_level_id.name : "-",
        id: d.id,
        course: d.course_id ? d.course_id.name : "",
      }
    })

    setData(data)
    setTotalSize(res.data.data.total)
    setLoading(false)
    setButtonSpinner(false)
  } catch (err) {
    notifyError("Error while getting student list")
  }
}

async function getSingleStudent(setStudentDetails, setAddOrEditModal, addOrEditModal, semester, application_id, setLoadingGradeTable, setUpdateTable, gradeTypeId) {
  try {
    let filter = `?semester=${semester}&application_id=${application_id}&grade_type=${gradeTypeId}`
    const res = await axios.get(ENDPOINT + '/coordinator/student-grade' + filter, { headers: headers })

    let newData = res.data.data.map(d=>{
      return {
        id: d.id,
        subject_name: d.subject_id ? d.subject_id.description : "-",
        subject_id: d.subject_id ? d.subject_id.id : 0,
        grading_period: d.grading_period,
        grade: d.grade,
        is_approved: d.is_approved,
        semester: d.semester === 1 ? "First" : "Second",
        semester_value: d.semester
      }
    })
    setStudentDetails(newData)
    setUpdateTable(false)
    if(setAddOrEditModal) setAddOrEditModal(!addOrEditModal)
    setLoadingGradeTable(false)
  } catch (err) {
    notifyError("Error while updating student grades")
  }
}

async function updateStudentGrades(studentDetails, setButtonSpinner, setUpdateTable, updateTable, setSingleGradeModal) {
  try {
    const res = await axios.post(ENDPOINT + '/update-student-grades', studentDetails, { headers: headers })

    notifySuccess("Successfully updated student grades");
    setButtonSpinner(false)
    setUpdateTable(!updateTable)
    setSingleGradeModal(false)
  } catch (err) {
    notifyError("Error while updating student grades")
  }
}

async function approveStudentGrades(dataToSubmit, setButtonSpinner, setUpdateTable, updateTable) {
  try {
    const res = await axios.post(ENDPOINT + '/approved-grade', dataToSubmit, { headers: headers })

    notifySuccess("Successfully approved student grade");
    setButtonSpinner(false)
    setUpdateTable(!updateTable)

  } catch (err) {
    notifyError("Error while approving student grade")
  }
}

async function disapproveStudentGrades(dataToSubmit, setButtonSpinner, setUpdateTable, updateTable) {
  try {
    const res = await axios.post(ENDPOINT + '/declined-grade', dataToSubmit, { headers: headers })

    notifySuccess("Successfully disapproved student grade");
    setButtonSpinner(false)
    setUpdateTable(!updateTable)

  } catch (err) {
    notifyError("Error while approving student grade")
  }
}

async function approveAllGrades(subject, setButtonSpinner, setApprovalModal, setUpdateTable, updateTable) {
  try {
    const res = await axios.post(ENDPOINT + '/batch-approved', { subject_id: subject }, { headers: headers })

    notifySuccess("Successfully approved ALL student grade");
    setUpdateTable(!updateTable)
    setApprovalModal(false)
    setButtonSpinner(false)
  } catch (err) {
    notifyError("Error while approving ALL student grade")
  }
}

async function disapproveAllGrades(subject, setButtonSpinner, setApprovalModal, setUpdateTable, updateTable) {
  try {
    const res = await axios.post(ENDPOINT + '/batch-declined', { subject_id: subject }, { headers: headers })

    notifySuccess("Successfully disapproved ALL student grade");
    setUpdateTable(!updateTable)
    setApprovalModal(false)
    setButtonSpinner(false)
  } catch (err) {
    notifyError("Error while disapproval of ALL student grade")
  }
}

async function exportSF10(dataToSubmit, setExportButtonSpinner, setDisableGradeType) {
  try {
    const res = await axios.post(ENDPOINT + '/sf10-export', dataToSubmit, { headers: headers })

    notifySuccess("Export processing...")
    const exportUrl = res.data;
    window.location.href = exportUrl
  } catch (err) {
    console.log(err)
    notifyError("Error while exporting...")
  }

  setExportButtonSpinner(false)
  setDisableGradeType(false)
}

async function adminGetStudents(
  setData,
  selectedGradeType,
  setDisableGradeType,
  setTotalSize,
  page1,
  sizePerPageQuery,
  searchText,
  setLoading,
  semester,
  newFilters
) {
  try {
    let q = searchText.trim();
    let filter = `?q=${q}&grade_type_id=${selectedGradeType}&sizePerPage=${sizePerPageQuery}&page=${page1}&semester=${semester}&sy_from=${newFilters.sy_from}&sy_to=${newFilters.sy_to}`;
    const res = await axios.get(ENDPOINT + `/sf10/get-student-list` + filter, { headers: headers })
    res.data.data.data.map(d => {
      d.id = d.student_detail_id
      return d;
    })
    setData(res.data.data.data)
    setTotalSize(res.data.data.total)
    setLoading(false)
  } catch (err) {
    notifyError("Error while getting students")
  }
  setDisableGradeType(false)
}

async function getStudentSections(setSectionLists)
{
  try {
    const res = await axios.get(ENDPOINT + `/coordinator/get-student-section`, { headers: headers })
    const sectionListsArr = res.data.data.map(d => {
      return {
        value: d.section_id,
        label: d.section_name
      }
    }) 
    setSectionLists(sectionListsArr)
  } catch(err) {
    notifyError("Error while fetching section lists")
  }
}

async function exportGeneralGrading(newFilters, setButtonSpinner)
{
  try {
    const filters = `?sy_from=${newFilters.sy_from}&sy_to=${newFilters.sy_to}&semester=${newFilters.semester}&year_level=${newFilters.grade_level}&course=${newFilters.course}&section_id=${newFilters.section_id}&grade_type=${newFilters.grade_type}`
    const res = await axios.get(ENDPOINT + `/general-grading-export` + filters, { headers: headers })
    notifySuccess("Export sucessful")
    const exportUrl = res.data;
    window.location.href = exportUrl
    setButtonSpinner(false)
  } catch(err) {
    notifyError("Error while exporting students")
  }
}