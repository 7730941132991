import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import overlayFactory from "react-bootstrap-table2-overlay";
import filterFactory from "react-bootstrap-table2-filter";
import { isBrowser, isDesktop, isMobile } from "react-device-detect";
import {
  Button,
  Col,
  Container,
  InputGroup,
  InputGroupAddon,
  Row,
  Input,
  UncontrolledTooltip,
  Label,
  Form,
  FormGroup,
} from "reactstrap";
import View from "../../../assets/img/view.png";
import Edit from "../../../assets/img/edit.png";
import File from "../../../assets/img/UPLOAD.png";
import { studentActions } from "../../../services/StudentPortalServices";
import MyUploadedFiles from "../Admission/MyUploadedFiles";
import Select from "react-select";
import StudentPortalModal from "./StudentPortalModal";
import MobileSideBar from "../Admission/MobileSidebar";
import { educationActions } from "../../../services/EducationSelectionService.js";
import { useForm, Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { registrarActions } from "../../../services/RegistrarServices";
import optionList from "../Admission/optionList";
import { applicationActions } from "../../../services/ApplicationServices";

const StudentPortal = ({ setAuthenticated }) => {
  const [data, setData] = useState([]);
  const [sortFieldQuery, setSortFieldQuery] = useState("id");
  const [sortOrderQuery, setSortOrderQuery] = useState("desc");
  const [sizePerPageQuery, setSizePerPageQuery] = useState(10);
  const [page1, setPage1] = useState(1);
  const [gradeLevels, setGradeLevels] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [gradeLevelFilter, setGradeLevelFilter] = useState("");
  const [courses, setCourses] = useState([]);
  const [coursesSelection, setCoursesSelection] = useState([]);
  const [totalSize, setTotalSize] = useState(0);
  const { register, handleSubmit, errors, control, setValue, getValues } =
    useForm();
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const withSemester = [
    "Grade 11",
    "Grade 12",
    "First year",
    "Second year",
    "Third year",
    "Fourth year",
  ];

  const blankDetails = {
    id: "",
    application_id: "",
    ref_no: "",
    student_id: "",
    last_name: "",
    given_name: "",
    middle_name: "",
    religion: "",
    lrn: "",
    grade_level: "",
    email_address: "",
    suffix: "",
    birth_place: "",
    birth_date: "",
    house_bldg_street: "",
    province: "",
    city_municipality: "",
    barangay: "",
    semester: "",
    course: "",
    grade_year_level: "",
  };

  const [filters, setFilters] = useState({
    syFrom: "",
    syTo: "",
    student_status: "enrolled",
    gradeLevel: "",
    semester: "",
    course: "",
  });

  const [details, setDetails] = useState(blankDetails);
  const [gradeLevelSelection, setGradeLevelSelection] = useState([]);
  useEffect(() => {
    if (filters.syFrom != "" && filters.syTo != "") {
      setLoading(true);
      let newFilters = {
        sy_from: moment(filters.syFrom).format("YYYY"),
        sy_to: moment(filters.syTo).format("YYYY"),
        student_status: filters.student_status,
        gradeLevel: filters.gradeLevel,
        semester: filters.semester,
        course: filters.course,
      };
      applicationActions.getApplications(
        setData,
        setLoading,
        setTotalSize,
        page1,
        sizePerPageQuery,
        searchText,
        newFilters,
        setButtonSpinner
      );
    }
  }, [page1, sizePerPageQuery]);

  const onSubmit = (data) => {
    setButtonSpinner(true);
    setLoading(true);
    let newFilters = {
      sy_from: moment(data.sy_from).format("YYYY"),
      sy_to: moment(data.sy_to).format("YYYY"),
      student_status: filters.student_status,
      gradeLevel: filters.gradeLevel,
      semester: filters.semester,
      course: filters.course,
    };
    applicationActions.getApplications(
      setData,
      setLoading,
      setTotalSize,
      page1,
      sizePerPageQuery,
      searchText,
      newFilters,
      setButtonSpinner
    );
  };

  useEffect(() => {
    if (gradeLevels.length >= 1) {
      let level = gradeLevels.map((grade) => {
        return { value: grade.id, label: grade.name };
      });
      setGradeLevelSelection(level);
    }
  }, [gradeLevels]);

  useEffect(() => {
    if (courses.length >= 1) {
      let c = courses.map((course) => {
        return { value: course.id, label: course.name };
      });
      setCoursesSelection(c);
    }
  }, [courses]);

  const columns = [
    {
      dataField: "ref_no",
      text: "Reference No",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
      formatter: (cell) => {
        return cell.toUpperCase();
      },
    },
    {
      dataField: "student_id",
      text: "Student ID",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "grade_level",
      text: "Grade Level",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "last_name",
      text: "Last Name",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "first_name",
      text: "First Name",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "course",
      text: "Course",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "semester",
      text: "Semester",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "id",
      text: "Action",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
      formatter: (cell, row) => {
        return (
          <div className="px-2 text-center" style={{ minWidth: "50px" }}>
            <Button
              id={"view-btn" + cell}
              onClick={() => toggleViewDetails(cell, row)}
              className="btn-warning mr-2"
            >
              <img
                src={View}
                style={{
                  height: isDesktop ? "1.5rem" : "1.1rem",
                  objectFit: "fill",
                }}
              />
            </Button>
            <UncontrolledTooltip placement="top" target={"view-btn" + cell}>
              View Details
            </UncontrolledTooltip>
          </div>
        );
      },
    },
  ];

  const [viewDetails, setViewDetails] = useState(false);
  const toggleViewDetails = (id, details) => {
    setDetails(blankDetails);
    if (!viewDetails) {
      let dataToSubmit = {
        application_id: id,
        education_type: details.education_type,
      };
      studentActions.getStudent(
        dataToSubmit,
        setViewDetails,
        viewDetails,
        setDetails
      );
    } else {
      setViewDetails(!viewDetails);
    }
  };

  const getOptionLabelByValue = (options, value) => {
    const option = options.find((option) => option.value == value);
    return option ? option.label : "";
  };

  const changeQuery = (e) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    educationActions.getApplications(
      null,
      null,
      null,
      setGradeLevels,
      null,
      setCourses,
      null,
      null
    );
  }, []);

  // useEffect(() => {
  //   if (data.length >= 1) {
  //     let filteredStudents = data;
  //     if (searchText != "") {
  //       filteredStudents = data.filter((d) => {
  //         return (
  //           d.student_id.includes(searchText.toLowerCase()) ||
  //           d.last_name.toLowerCase().includes(searchText.toLowerCase())
  //         );
  //       });
  //     }
  //     if (gradeLevelFilter != "") {
  //       let filteredGrade = filteredStudents.filter((f) => {
  //         return f.grade_level.toLowerCase() == gradeLevelFilter.toLowerCase();
  //       });
  //       setFilteredData(filteredGrade)
  //     } else {
  //       setFilteredData(filteredStudents)
  //     }
  //   }
  // }, [gradeLevelFilter, searchText, data]);

  const RemoteAll = ({
    loading,
    data,
    page,
    sizePerPage,
    onTableChange,
    totalSize,
  }) => (
    <div>
      <BootstrapTable
        wrapperClasses="table-responsive text-center"
        bootstrap4
        striped
        remote
        loading={loading}
        keyField="id"
        data={data}
        columns={columns}
        defaultSorted={defaultSorted}
        filter={filterFactory()}
        pagination={paginationFactory({ page, sizePerPage, totalSize })}
        onTableChange={onTableChange}
        overlay={overlayFactory({
          spinner: true,
          styles: {
            overlay: (base) => ({
              ...base,
              background: "rgba(0, 0, 0, 0.5)",
            }),
          },
        })}
        noDataIndication={() => <NoDataIndication />}
      />
    </div>
  );

  // DATA TABLE
  const handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder }
  ) => {
    if (page !== page1) {
      setPage1(page);

      setSortFieldQuery(sortField ? sortField : sortFieldQuery);
      setSortOrderQuery(sortOrder ? sortOrder : sortOrderQuery);
      setLoading(true);
    }

    if (
      (sortField != sortFieldQuery && sortField != undefined) ||
      (sortOrder != sortOrderQuery && sortOrder != undefined)
    ) {
      setSortFieldQuery(sortField ? sortField : sortFieldQuery);
      setSortOrderQuery(sortOrder ? sortOrder : sortOrderQuery);
      setLoading(true);
    }

    if (sizePerPage != sizePerPageQuery) {
      setSizePerPageQuery(sizePerPage);
      setLoading(true);
    }
  };

  const defaultSorted = [
    {
      dataField: sortFieldQuery,
      order: sortOrderQuery,
    },
  ];
  const NoDataIndication = () => <div>No Data</div>;

  return (
    <div className="p-4">
      {viewDetails ? (
        <StudentPortalModal
          viewDetails={viewDetails}
          setViewDetails={setViewDetails}
          toggleViewDetails={toggleViewDetails}
          details={details}
        />
      ) : (
        ""
      )}

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md="2" xs="12">
            <FormGroup>
              <Label>
                <small className="font-weight-bolder">S.Y. From</small>
              </Label>
              <Controller
                control={control}
                name="sy_from"
                defaultValue=""
                rules={{ required: true }}
                invalid={errors.sy_from ? true : false}
                render={({ onChange, value, onBlur, name }) => (
                  <DatePicker
                    className="form-control bg-light"
                    selected={
                      moment(filters.syFrom).isValid()
                        ? moment(filters.syFrom).toDate()
                        : filters.syFrom
                    }
                    onChange={(date) => {
                      // setStartDate(date);
                      setValue("sy_from", date);
                      setFilters({ ...filters, syFrom: date });
                      return date;
                    }}
                    showYearPicker
                    dateFormat="yyyy"
                    yearItemNumber={10}
                    name="sy_from"
                    defaultValue=""
                  />
                )}
              />
              {errors.sy_from && errors.sy_from.type == "required" && (
                <div
                  style={{
                    marginTop: "0.25rem",
                    fontSize: "80%",
                    color: "#f86c6b",
                  }}
                >
                  School Year From is required!
                </div>
              )}
            </FormGroup>
          </Col>
          <Col md="2" xs="12">
            <FormGroup>
              <Label>
                <small className="font-weight-bolder">S.Y. To</small>
              </Label>
              <Controller
                control={control}
                name="sy_to"
                defaultValue=""
                rules={{
                  required: true,
                  validate: (value) => value > getValues("sy_from"),
                }}
                invalid={errors.sy_to ? true : false}
                render={({ onChange, value, onBlur, name }) => (
                  <DatePicker
                    className="form-control bg-light"
                    selected={
                      moment(filters.syTo).isValid()
                        ? moment(filters.syTo).toDate()
                        : filters.syTo
                    }
                    onChange={(date) => {
                      setValue("sy_to", date);
                      setFilters({ ...filters, syTo: date });
                      return date;
                    }}
                    showYearPicker
                    dateFormat="yyyy"
                    yearItemNumber={10}
                    name="sy_to"
                    defaultValue=""
                  />
                )}
              />
              {errors.sy_to && errors.sy_to.type == "required" && (
                <div
                  style={{
                    marginTop: "0.25rem",
                    fontSize: "80%",
                    color: "#f86c6b",
                  }}
                >
                  School Year To is required!
                </div>
              )}
              {errors.sy_to && errors.sy_to.type == "validate" && (
                <div
                  style={{
                    marginTop: "0.25rem",
                    fontSize: "80%",
                    color: "#f86c6b",
                  }}
                >
                  School Year To cannot be lower or equal to S.Y. From!
                </div>
              )}
            </FormGroup>
          </Col>
          <Col md="4" xs="12" className="ml-auto mb-2">
            <Label>
              <small className="font-weight-bolder">
                <i className="fa fa-lightbulb-o"></i>&nbsp;Search by Last Name
              </small>
            </Label>
            <InputGroup>
              <InputGroupAddon addonType="prepend" style={{ zIndex: "0" }}>
                <Button type="button" color="primary" id="search-button">
                  <i className="fa fa-search"></i>
                </Button>
              </InputGroupAddon>
              <Input
                type="text"
                id="search-text"
                name="search-text"
                placeholder="Search"
                onChange={changeQuery}
              />
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col md="2" xs="12" className="mb-2">
            <Label>
              <small className="font-weight-bolder">
                <i className="fa fa-lightbulb-o"></i>&nbsp;Filter by Grade Level
              </small>
            </Label>
            <Controller
              control={control}
              name="gradeLevel"
              rules={{ required: false }}
              invalid={errors.gradeLevel ? true : false}
              defaultValue=""
              render={({ onChange, value, onBlur, name }) => (
                <Select
                  options={gradeLevelSelection}
                  onChange={(e) => {
                    setValue("semester", "");
                    setValue("course", "");
                    setGradeLevelFilter(e.label);
                    setFilters({
                      ...filters,
                      gradeLevel: e.value,
                      course: "",
                      semester: "",
                    });
                    return e;
                  }}
                  defaultValue=""
                />
              )}
            />
          </Col>
          {withSemester.includes(gradeLevelFilter) ? (
            <>
              <Col md="2" xs="12" className="mb-2">
                <Label>
                  <small className="font-weight-bolder">
                    <i className="fa fa-lightbulb-o"></i>&nbsp;Filter by Courses
                  </small>
                </Label>
                <Controller
                  control={control}
                  name="course"
                  rules={{ required: false }}
                  invalid={errors.course ? true : false}
                  defaultValue={
                    filters.course != "" ? { value: filters.course } : ""
                  }
                  render={({ onChange, value, onBlur, name }) => (
                    <Select
                      options={coursesSelection}
                      onChange={(e) => {
                        setFilters({ ...filters, course: e.value });
                        return e;
                      }}
                      defaultValue={
                        filters.course != ""
                          ? {
                              value: filters.course,
                              label: getOptionLabelByValue(
                                coursesSelection,
                                filters.course
                              ),
                            }
                          : ""
                      }
                    />
                  )}
                />
              </Col>
              <Col md="2" xs="12" className="mb-2">
                <Label>
                  <small className="font-weight-bolder">
                    <i className="fa fa-lightbulb-o"></i>&nbsp;Filter by
                    Semester
                  </small>
                </Label>
                <Controller
                  control={control}
                  name="semester"
                  rules={{ required: false }}
                  invalid={errors.semester ? true : false}
                  defaultValue=""
                  render={({ onChange, value, onBlur, name }) => (
                    <Select
                      options={optionList.semester}
                      onChange={(e) => {
                        setFilters({ ...filters, semester: e.value });
                        return e;
                      }}
                      defaultValue=""
                    />
                  )}
                />
              </Col>
            </>
          ) : (
            ""
          )}
        </Row>
        <Row className="pb-5 pt-4">
          <Col xs="12" md="12">
            <Button
              disabled={buttonSpinner}
              className="bg-danger-edit text-white border-0"
            >
              Filter
            </Button>
          </Col>
        </Row>
      </Form>
      <RemoteAll
        loading={loading}
        data={data}
        page={page1}
        sizePerPage={sizePerPageQuery}
        totalSize={totalSize}
        onTableChange={handleTableChange}
        register={register}
      />
    </div>
  );
};
export default StudentPortal;
