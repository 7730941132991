import React, { useState } from 'react';
import { Button, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, FormGroup, Form, Spinner } from 'reactstrap';
import MultiSelect from "@kenshooui/react-multi-select";
import "@kenshooui/react-multi-select/dist/style.css";
import { useForm, Controller } from "react-hook-form";
import { registrarActions } from "../../../services/RegistrarServices";
import Select from "react-select";

const EditStudentList = ({editStudentLists, setEditStudents, toggleEditStudentLists, section, setUpdateTable, updateTable}) => {
    const { handleSubmit, errors, control, setValue } = useForm();
    const [buttonSpinner, setButtonSpinner] = useState(false);
    
    const [selectedStudents, setSelectedStudents] = useState(section.withSection)
    const handleStudentListChange = (e) => {
        setSelectedStudents(e);
      };
    const onSubmit = (data) => {
        setButtonSpinner(true);

        const IDs = selectedStudents.map((student)=>{
          return student.id;
        })
        const dataToSubmit = {
          section_id: section.id,
          application_id: IDs ? IDs : [],
          adviser_id: data.adviser_id ? data.adviser_id.value : null,
          coordinator_id: data.coordinator_id ? data.coordinator_id.value : null,
          semester: section.semester,
          grade_type: section.grade_type
        };
        registrarActions.updateStudentList(dataToSubmit, setEditStudents, setButtonSpinner, updateTable, setUpdateTable);
    }

    const getOptionLabelByValue = (options, value) => {
      const option = options.find((option) => option.value == value);
      return option ? option.label : "";
  };

    return (
        <Modal isOpen={editStudentLists} toggle={toggleEditStudentLists} size="lg" backdrop="static">
        <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader toggle={toggleEditStudentLists} className="bg-danger-edit text-white" charCode="">Edit Student List</ModalHeader>
        <ModalBody className="mr-3">
          <Row className="mb-5">
              <Col md="4" xs="12" className="mr-auto">
                    <h5 className="text-danger-edit">Grade Level : {section.grade_level}</h5>
              </Col>
              <Col md="6" xs="12" className="ml-auto">
                    <h5 className="text-danger-edit">Section Name : {section.section}</h5>
              </Col>
          </Row>
            <Row>

            <Col md="12" xs="12">
              <FormGroup>
              <Label htmlFor="student_list" className="text-danger-edit">
                  <h5>Student List</h5>
                </Label>
                <Controller
                  control={control}
                  name="student_list"
                  rules={{ required: true }}
                  invalid={errors.student_list ? true : false}
                  defaultValue={[]}
                  render={({ onChange, value, onBlur, name }) => (
                    <MultiSelect
                      selectedItems={selectedStudents}
                      items={section.allStudents}
                      defaultValue={[]}
                      onChange={(option) => {
                        handleStudentListChange(option);
                        return option;
                      }}
                      />
                  )}
                />
                {errors.student_list && (
                  <div
                    style={{
                      marginTop: "0.25rem",
                      fontSize: "80%",
                      color: "#f86c6b",
                    }}
                  >
                    Students are required!
                  </div>
                )}
              </FormGroup>
            </Col>
            <Col md="6" xs="12">
              <FormGroup>
                <Label
                  htmlFor="adviser_id"
                  className="text-secondary font-weight-bolder"
                >
                  Section Adviser
                </Label>
                <Controller
                  control={control}
                  name="adviser_id"
                  rules={{ required: false }}
                  invalid={errors.adviser_id ? true : false}
                  defaultValue={
                    section.adviser_id != ""
                      ? {
                        value: section.adviser_id,
                        label: getOptionLabelByValue(
                          section.adviser_lists,
                          section.adviser_id
                        ),
                      }
                      : ""
                  }
                  render={({ onChange, value, onBlur, name }) => (
                    <Select
                      options={section.adviser_lists}
                      onChange={(e) => {
                        setValue("adviser_id", e);

                        return e;
                      }}
                      defaultValue={
                        section.adviser_id != ""
                          ? {
                            value: section.adviser_id,
                            label: getOptionLabelByValue(
                              section.adviser_lists,
                              section.adviser_id
                            ),
                          }
                          : ""
                      }
                    />
                  )}
                />
                {errors.adviser_id && (
                  <div
                    style={{
                      marginTop: "0.25rem",
                      fontSize: "80%",
                      color: "#f86c6b",
                    }}
                  >
                    Adviser is required!
                  </div>
                )}
              </FormGroup>
            </Col>
            {/* <Col md="6" xs="12">
              <FormGroup>
                <Label
                  htmlFor="coordinator_id"
                  className="text-secondary font-weight-bolder"
                >
                  Section Coordinator
                </Label>
                <Controller
                  control={control}
                  name="coordinator_id"
                  rules={{ required: false }}
                  invalid={errors.coordinator_id ? true : false}
                  defaultValue={
                    section.coordinator_id !== ""
                      ? {
                        value: section.coordinator_id,
                        label: getOptionLabelByValue(
                          section.coordinator_lists,
                          section.coordinator_id
                        ),
                      }
                      : ""
                  }
                  render={({ onChange, value, onBlur, name }) => (
                    <Select
                      options={section.coordinator_lists}
                      onChange={(e) => {
                        setValue("coordinator_id", e);

                        return e;
                      }}
                      defaultValue={
                        section.coordinator_id !== ""
                          ? {
                            value: section.coordinator_id,
                            label: getOptionLabelByValue(
                              section.coordinator_lists,
                              section.coordinator_id
                            ),
                          }
                          : ""
                      }
                    />
                  )}
                />
                {errors.coordinator_id && (
                  <div
                    style={{
                      marginTop: "0.25rem",
                      fontSize: "80%",
                      color: "#f86c6b",
                    }}
                  >
                    Section Coordinator is required!
                  </div>
                )}
              </FormGroup>
            </Col> */}
          </Row>
        </ModalBody>
        <ModalFooter>
        <Button className="bg-warning-edit border-0" disabled={buttonSpinner}>
            {buttonSpinner ? (
              <>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </>
            ) : (
              "Save"
            )}
          </Button>
          <Button color="secondary" disabled={buttonSpinner} onClick={toggleEditStudentLists}>Close</Button>
        </ModalFooter>
        </Form>
      </Modal>
    )
}
export default EditStudentList;