import React, { useEffect, useState } from "react";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Row,
    Col,
    Label,
    Input,
    FormGroup,
    Form,
    Spinner,
    Table,
    CardHeader,
    Card,
    CardBody,
} from "reactstrap";
import { useForm, Controller, FormProvider } from "react-hook-form";
import Select from "react-select";
import { teacherActions } from "../../../services/TeacherServices";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const ViewStudents = ({
    updateGradeModal,
    setUpdateGradeModal,
    toggleUpdateGrade,
    subjectStudents,
    studentGrade,
    setStudentGrade,
    gradingId,
    setGradingId,
    filters,
    setFilters
}) => {
    const { register, handleSubmit, errors, control, setValue, getValues } = useForm();
    const [buttonSpinner, setButtonSpinner] = useState(false);
    const [studentGradingPeriod, setStudentGradingPeriod] = useState([])
    const [studentList, setStudentList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [updateGrade, setUpdateGrade] = useState(false);

    const gradingPeriod = [
        { value: "1st", label: "First Grading" },
        { value: "2nd", label: "Second Grading" },
        { value: "3rd", label: "Third Grading" },
        { value: "4th", label: "Fourth Grading" },
    ];

    const collegePeriod = [
        { value: "1st", label: "Prelim" },
        { value: "2nd", label: "Midterm" },
        { value: "3rd", label: "Finals" },
        // { value: "4th", label: "Finals" },
    ];

    // const collegePeriod = [
    //     { value: "final", label: "Final Grade" },
    // ];

    const shsFirstPeriod = [
        { value: "1st", label: "1st Quarter" },
        { value: "2nd", label: "2nd Quarter" },
    ];

    const shsSecondPeriod = [
        { value: "3rd", label: "3rd Quarter" },
        { value: "4th", label: "4th Quarter" },
    ];

    const withSemester = [4, 5];



    useEffect(() => {
        if (withSemester.includes(subjectStudents.grade_type_id)) {
            if (subjectStudents.grade_type_id == 4 && subjectStudents.semester == 0) {
                setStudentGradingPeriod(shsFirstPeriod)
            } else if (subjectStudents.grade_type_id == 4 && subjectStudents.semester == 1) {
                setStudentGradingPeriod(shsSecondPeriod)
            } else {
                setStudentGradingPeriod(collegePeriod)
            }
        } else {
            setStudentGradingPeriod(gradingPeriod)
        }
    }, [subjectStudents])

    useEffect(() => {
        if (studentList.length >= 1) {
            const studentArr = studentList.map(s => {
                return {
                    id: s.id,
                    fullname: s.student_detail_id.fullname,
                    grade_id: s.student_grade.length >= 1 ? s.student_grade[0].id : "",
                    grade: s.student_grade.length >= 1 ? s.student_grade[0].grade : ""
                }
            })

            const sortedStudents = studentArr.sort((a, b) => {
                var nameA = a.fullname; // ignore upper and lowercase
                var nameB = b.fullname; // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1; //nameA comes first
                }
                if (nameA > nameB) {
                    return 1; // nameB comes first
                }
                return 0;  // names must be equal
            });
            setStudentGrade(sortedStudents)
        } else {
            setStudentGrade([])
        }
    }, [studentList]);

    const handleChange = (id, e) => {
        const updateStudentArr = studentGrade.map(sArr => {
            if (sArr.id == id) {
                return { id: sArr.id, fullname: sArr.fullname, grade: e.target.value, grade_id: sArr.grade_id }
            } else {
                return sArr
            }
        })
        setStudentGrade(updateStudentArr)
    }

    useEffect(() => {
        if (updateGrade) {
            setLoading(true)
            const newData = {
                subject_id: subjectStudents.subject_id,
                year_level_id: subjectStudents.grade_level_id,
                section_id: subjectStudents.section_id,
                grade_type_id: subjectStudents.grade_type_id,
                grading_period: gradingId,
                semester: subjectStudents.semester??"",
                course_type_id: subjectStudents.course_type_id??"",
                sy_from: moment(filters.syFrom).format("YYYY"),
                sy_to: moment(filters.syTo).format("YYYY"),
            }
            teacherActions.getStudentList(newData, setStudentList, setButtonSpinner, setLoading, setUpdateGrade);
        }
    }, [updateGrade])

    const onSubmit = () => {
        setButtonSpinner(true)
        const dataToSubmit = {
            subject_id: subjectStudents.subject_id,
            semester: subjectStudents.semester,
            grading_period: gradingId,
            student_subjects: studentGrade,
            sy_from: moment(filters.syFrom).format("YYYY"),
            sy_to: moment(filters.syTo).format("YYYY"),
        }
        teacherActions.saveStudentGrade(dataToSubmit, setButtonSpinner, setUpdateGrade);
    }

    const filterSubmit = (data) => {
        setLoading(true)
        const newData = {
            subject_id: subjectStudents.subject_id,
            year_level_id: subjectStudents.grade_level_id,
            section_id: subjectStudents.section_id,
            grade_type_id: subjectStudents.grade_type_id,
            grading_period: gradingId,
            semester: subjectStudents.semester??"",
            course_type_id: subjectStudents.course_type_id??"",
            sy_from: moment(data.sy_from).format("YYYY"),
            sy_to: moment(data.sy_to).format("YYYY"),
        }
        console.log(newData)
        teacherActions.getStudentList(newData, setStudentList, setButtonSpinner, setLoading, setUpdateGrade);
    }

    return (
        <Modal isOpen={updateGradeModal} toggle={toggleUpdateGrade} size="lg" backdrop="static">
            <ModalHeader toggle={toggleUpdateGrade}>Student Grades</ModalHeader>
            <ModalBody>
                <Card>
                    <CardHeader><h4>{subjectStudents.subject_name}</h4></CardHeader>
                    <CardBody>
                        <Table className="mb-5" bordered>
                            <tr>
                                <td className="font-weight-bolder text-danger-edit">Section</td>
                                <td>{subjectStudents.section_name}</td>
                            </tr>
                            <tr>
                                <td className="font-weight-bolder text-danger-edit">Semester</td>
                                <td>{withSemester.includes(subjectStudents.grade_type_id) ? (subjectStudents.semester == 1 ? "Second" : "First") : "NA"}</td>
                            </tr>
                        </Table>

                        <Form onSubmit={handleSubmit(filterSubmit)}>
                            <Row>
                                <Col md="4" xs="12">
                                    <FormGroup>
                                        <Label className="text-left w-100 text-danger-edit font-weight-bolder">
                                            S.Y. From
                                        </Label>
                                        <Controller
                                            control={control}
                                            name="sy_from"
                                            defaultValue=""
                                            rules={{ required: true }}
                                            invalid={errors.sy_from ? true : false}
                                            render={({ onChange, value, onBlur, name }) => (
                                                <DatePicker
                                                    className="form-control bg-light"
                                                    selected={
                                                        moment(filters.syFrom).isValid()
                                                            ? moment(filters.syFrom).toDate()
                                                            : filters.syFrom
                                                    }
                                                    onChange={(date) => {
                                                        // setStartDate(date);
                                                        setValue("sy_from", date);
                                                        setFilters({ ...filters, syFrom: date });
                                                        return date;
                                                    }}
                                                    showYearPicker
                                                    dateFormat="yyyy"
                                                    yearItemNumber={10}
                                                    name="sy_from"
                                                    defaultValue=""
                                                />
                                            )}
                                        />
                                        {errors.sy_from &&
                                            errors.sy_from.type == "required" && (
                                                <div
                                                    style={{
                                                        marginTop: "0.25rem",
                                                        fontSize: "80%",
                                                        color: "#f86c6b",
                                                    }}
                                                >
                                                    School Year From is required!
                                                </div>
                                            )}
                                    </FormGroup>
                                </Col>
                                <Col md="4" xs="12">
                                    <FormGroup>
                                        <Label className="text-left w-100 text-danger-edit font-weight-bolder">
                                            S.Y. To
                                        </Label>
                                        <Controller
                                            control={control}
                                            name="sy_to"
                                            defaultValue=""
                                            rules={{ required: true, validate: value => value > getValues('sy_from') }}
                                            invalid={errors.sy_to ? true : false}
                                            render={({ onChange, value, onBlur, name }) => (
                                                <DatePicker
                                                    className="form-control bg-light"
                                                    selected={
                                                        moment(filters.syTo).isValid()
                                                            ? moment(filters.syTo).toDate()
                                                            : filters.syTo
                                                    }
                                                    onChange={(date) => {
                                                        setValue("sy_to", date);
                                                        setFilters({ ...filters, syTo: date });
                                                        return date;
                                                    }}
                                                    showYearPicker
                                                    dateFormat="yyyy"
                                                    yearItemNumber={10}
                                                    name="sy_to"
                                                    defaultValue=""
                                                />
                                            )}
                                        />
                                        {errors.sy_to &&
                                            errors.sy_to.type == "required" && (
                                                <div
                                                    style={{
                                                        marginTop: "0.25rem",
                                                        fontSize: "80%",
                                                        color: "#f86c6b",
                                                    }}
                                                >
                                                    School Year To is required!
                                                </div>
                                            )}
                                        {errors.sy_to &&
                                            errors.sy_to.type == "validate" && (
                                                <div
                                                    style={{
                                                        marginTop: "0.25rem",
                                                        fontSize: "80%",
                                                        color: "#f86c6b",
                                                    }}
                                                >
                                                    School Year To cannot be lower or equal to S.Y. From!
                                                </div>
                                            )}
                                    </FormGroup>
                                </Col>
                                <Col md="12" xs="12">
                                    <FormGroup>
                                        <Label
                                            htmlFor="grading_period"
                                            className="text-left w-100 text-danger-edit font-weight-bolder"
                                        >
                                            Select Grading Period
                                        </Label>
                                        <Controller
                                            control={control}
                                            name="grading_period"
                                            defaultValue=""
                                            rules={{ required: true }}
                                            invalid={errors.grading_period ? true : false}
                                            render={({ onChange, value, onBlur, name }) => (
                                                <Select
                                                    options={studentGradingPeriod}
                                                    name="grading_period"
                                                    onChange={(e) => {
                                                        setValue("grading_period", e);
                                                        setStudentGrade([])
                                                        setGradingId(e.value)
                                                        return e;
                                                    }}
                                                    defaultValue=""
                                                />
                                            )}
                                        />
                                        {errors.grading_period &&
                                            errors.grading_period.type == "required" && (
                                                <small className="text-danger">
                                                    Grading Period is required!
                                                </small>
                                            )}
                                    </FormGroup>
                                </Col>
                                <Col xs="12" md="12">
                                    <Button disabled={buttonSpinner} className="bg-danger-edit text-white border-0">Filter</Button>
                                </Col>
                            </Row>
                        </Form>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Row className="mt-4">
                                <Col md="12" xs="12" className="text-center">
                                    {!loading ?
                                        <Table striped bordered>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Full Name</th>
                                                    <th>Grade</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {studentGrade && studentGrade.length >= 1 ?
                                                    studentGrade.map((student, key) => {
                                                        return (
                                                            <tr key={key}>
                                                                <td>{key + 1}</td>
                                                                <td>{student.fullname}</td>
                                                                <td className="w-25">
                                                                    <Input
                                                                        type={subjectStudents.grade_type_id == 1 ? "text" : "number"}
                                                                        step="any"
                                                                        name={"grade" + key}
                                                                        id={"grade" + key}
                                                                        onChange={(e) => {
                                                                            handleChange(student.id, e)
                                                                            return e.target.value;
                                                                        }}
                                                                        defaultValue={student.grade}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    <tr>
                                                        <td colSpan="4">No Available Students</td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </Table>
                                        :
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    }
                                </Col>
                                <Col md="12" xs="12">
                                    <Button className="bg-warning-edit border-0" disabled={buttonSpinner || !studentGrade.length}>
                                        {buttonSpinner ? (
                                            <>
                                                <Spinner
                                                    as="span"
                                                    animation="grow"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                            </>
                                        ) : (
                                            "Submit Grades"
                                        )}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </CardBody>
                </Card>
            </ModalBody>
            <ModalFooter>
                <Button disabled={buttonSpinner} color="secondary" onClick={toggleUpdateGrade}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal >
    )
}

export default ViewStudents;