import { ENDPOINT, S3_URL } from "../constants/api";
import axios from "axios";
import { notifyError, notifySuccess } from "./Toaster";
import moment from "moment";

export const dynamicAboutUsActions = {
  saveTemplate,
  getCurrentTemplate,
  getSelectedTemplate,
  publishTemplate,
  getCurrentTemplateFrontPage
  // updateUser
};

const token = localStorage.getItem("jwtToken")
  ? localStorage.getItem("jwtToken")
  : "";
const headers = {
  Authorization: `Bearer ${token}`,
  "Content-Type": "application/json",
  "X-Requested-With": "XMLHttpRequest",
};

async function saveTemplate(data, setLoading, setButtonSpinner, setButtonSpinner2) {
  try {
    let res = await axios.post(ENDPOINT + `/dynamic-aboutus`, data, {
      headers: headers,
    });

    notifySuccess("Successfully created new template");
    setLoading(false)
    setButtonSpinner(false)
    setButtonSpinner2(false)
    setTimeout(() => window.location.reload(), 2000);
  } catch (err) {
    if (err.response && err.response.data && err.response.data.error) {
      notifyError(err.response.data.error);
    } else if (err.response) {
      notifyError("Error while saving template");
    }
    setLoading(false)
    setButtonSpinner(false)
    setButtonSpinner2(false)
  }
}

async function getCurrentTemplate(
  setAboutUsData,
  aboutUsData,
  setCurrentVersion,
  setSelectedVersion,
  setLoading,
  setVersionSelection,
  aboutUsBase,
  Chairman,
  AmeliaTrustee,
  President,
  SecretaryTrustee,
  ViceChairman,
  VictoriaTrustee,
  Treasurer,
  aboutUsBaseMobile,
  BaseFooter,
) {
  try {
    let res = await axios.get(ENDPOINT + `/dynamic-aboutus`, {
      headers: headers,
    });

    const main = res.data.current.main;
    const mission_vision = res.data.current.mission_vision;
    const trustees_members = res.data.current.trustees_members;
    const versions = res.data.versions;
    setCurrentVersion(res.data.current.version)
    setVersionSelection(versions)
    setSelectedVersion(res.data.current.version)

    let trusteesArr = [];
    if (trustees_members.trustees.length >= 1) {
      trusteesArr =trustees_members.trustees.map(trustee => {
        if (trustee.image.includes("static/media/")) {
          if (trustee.image.includes("Chairman.8185917a")) {
            trustee.image = Chairman;
            return trustee
          }
          if (trustee.image.includes("Vice-Chairman")) {
            trustee.image = ViceChairman;
            return trustee
          }
          if (trustee.image.includes("President")) {
            trustee.image = President;
            return trustee
          }
          if (trustee.image.includes("Secretary")) {
            trustee.image = SecretaryTrustee;
            return trustee
          }
          if (trustee.image.includes("Cecilia-Treasurer")) {
            trustee.image = Treasurer;
            return trustee
          }
          if (trustee.image.includes("Amelia-Trustee")) {
            trustee.image = AmeliaTrustee;
            return trustee
          }
          if (trustee.image.includes("Victoria-Trustee")) {
            trustee.image = VictoriaTrustee;
            return trustee
          }
        }else{
          return trustee
        }
      })
    }

    trustees_members.trustees = trusteesArr;
    setAboutUsData({
      ...aboutUsData,
      mainImageDesktop: main.mainImageDesktop.includes("static/media/") ? aboutUsBase : main.mainImageDesktop,
      mainImageDesktopFile: "",
      mainImageTablet: main.mainImageTablet.includes("static/media/") ? aboutUsBase : main.mainImageTablet,
      mainImageTabletFile: "",
      mainImageMobile: main.mainImageMobile.includes("static/media/") ? aboutUsBaseMobile : main.mainImageMobile,
      mainImageMobileFile: "",
      mainBaseImage: main.mainBaseImage.includes("static/media/") ? BaseFooter : main.mainBaseImage,
      mainBaseImageFile: "",
      mainTitle: main.mainTitle,
      mainDescription: main.mainDescription,
      achievements: main.achievements,
      subTitle: main.subTitle,
      trusteesTitle: trustees_members.trusteesTitle,
      trustees: trustees_members.trustees,
      bomTitle: trustees_members.bomTitle,
      bomData: trustees_members.bomData,
      philosophy: mission_vision.philosophy,
      mission: mission_vision.mission,
      vision: mission_vision.vision,
      coreValues: mission_vision.coreValues,
      coreValuesData: mission_vision.coreValuesData
    })

    setLoading(false)
  } catch (err) {
    if (err.response) {
      notifyError("Error while getting latest template.");
    }
    setLoading(false)
  }
}

async function getSelectedTemplate(
  setAboutUsData,
  aboutUsData,
  setCurrentVersion,
  selectedVersion,
  setLoading,
  setVersionSelection,
  aboutUsBase,
  Chairman,
  AmeliaTrustee,
  President,
  SecretaryTrustee,
  ViceChairman,
  VictoriaTrustee,
  Treasurer,
  aboutUsBaseMobile,
  BaseFooter,
) {
  try {
    let res = await axios.get(ENDPOINT + `/get-dynamic-aboutus/${selectedVersion}`, {
      headers: headers,
    });

    const main = res.data.main;
    const mission_vision = res.data.mission_vision;
    const trustees_members = res.data.trustees_members;

    let trusteesArr = [];
    if (trustees_members.trustees.length >= 1) {
      trusteesArr =trustees_members.trustees.map(trustee => {
        if (trustee.image.includes("static/media/")) {
          if (trustee.image.includes("Chairman.8185917a")) {
            trustee.image = Chairman;
            return trustee
          }
          if (trustee.image.includes("Vice-Chairman")) {
            trustee.image = ViceChairman;
            return trustee
          }
          if (trustee.image.includes("President")) {
            trustee.image = President;
            return trustee
          }
          if (trustee.image.includes("Secretary")) {
            trustee.image = SecretaryTrustee;
            return trustee
          }
          if (trustee.image.includes("Cecilia-Treasurer")) {
            trustee.image = Treasurer;
            return trustee
          }
          if (trustee.image.includes("Amelia-Trustee")) {
            trustee.image = AmeliaTrustee;
            return trustee
          }
          if (trustee.image.includes("Victoria-Trustee")) {
            trustee.image = VictoriaTrustee;
            return trustee
          }
        }else{
          return trustee
        }
      })
    }

    trustees_members.trustees = trusteesArr;
    setAboutUsData({...aboutUsData,
      mainImageDesktop: main.mainImageDesktop.includes("static/media/") ? aboutUsBase : main.mainImageDesktop,
      mainImageDesktopFile: "",
      mainImageTablet: main.mainImageTablet.includes("static/media/") ? aboutUsBase : main.mainImageTablet,
      mainImageTabletFile: "",
      mainImageMobile: main.mainImageMobile.includes("static/media/") ? aboutUsBaseMobile : main.mainImageMobile,
      mainImageMobileFile: "",
      mainBaseImage: main.mainBaseImage.includes("static/media/") ? BaseFooter : main.mainBaseImage,
      mainBaseImageFile: "",
      mainTitle: main.mainTitle,
      mainDescription: main.mainDescription,
      achievements: main.achievements,
      subTitle: main.subTitle,
      trusteesTitle: trustees_members.trusteesTitle,
      trustees: trustees_members.trustees,
      bomTitle: trustees_members.bomTitle,
      bomData: trustees_members.bomData,
      philosophy: mission_vision.philosophy,
      mission: mission_vision.mission,
      vision: mission_vision.vision,
      coreValues: mission_vision.coreValues,
      coreValuesData: mission_vision.coreValuesData
    })
    
    setLoading(false)
  } catch (err) {
    if (err.response) {
      notifyError("Error while getting template.");
    }
    setLoading(false)
  }
}

async function publishTemplate(selectedVersion, setButtonSpinner, setLoading, setButtonSpinner2) {
  try {
    let res = await axios.get(ENDPOINT + `/dynamic-aboutus-publish-template/${selectedVersion}`, {
      headers: headers,
    });

    setButtonSpinner(false)
    notifySuccess("Successfully published new version");
    setTimeout(() => window.location.reload(), 2000);
  } catch (err) {
    if (err.response) {
      notifyError("Error while publishing new version.");
    }
    setButtonSpinner(false)
  }
}

async function getCurrentTemplateFrontPage(
  setAboutUsData,
  aboutUsData,
  setCurrentVersion,
  setSelectedVersion,
  setLoading,
  setVersionSelection,
  aboutUsBase,
  Chairman,
  AmeliaTrustee,
  President,
  SecretaryTrustee,
  ViceChairman,
  VictoriaTrustee,
  Treasurer,
  aboutUsBaseMobile,
  BaseFooter,
) {
  try {
    let res = await axios.get(ENDPOINT + `/dynamic-aboutus-frontpage`, {
      headers: headers,
    });

    const main = res.data.current.main;
    const mission_vision = res.data.current.mission_vision;
    const trustees_members = res.data.current.trustees_members;
    const versions = res.data.versions;
    setCurrentVersion(res.data.current.version)
    setVersionSelection(versions)
    setSelectedVersion(res.data.current.version)

    let trusteesArr = [];
    if (trustees_members.trustees.length >= 1) {
      trusteesArr =trustees_members.trustees.map(trustee => {
        if (trustee.image.includes("static/media/")) {
          if (trustee.image.includes("Chairman.8185917a")) {
            trustee.image = Chairman;
            return trustee
          }
          if (trustee.image.includes("Vice-Chairman")) {
            trustee.image = ViceChairman;
            return trustee
          }
          if (trustee.image.includes("President")) {
            trustee.image = President;
            return trustee
          }
          if (trustee.image.includes("Secretary")) {
            trustee.image = SecretaryTrustee;
            return trustee
          }
          if (trustee.image.includes("Cecilia-Treasurer")) {
            trustee.image = Treasurer;
            return trustee
          }
          if (trustee.image.includes("Amelia-Trustee")) {
            trustee.image = AmeliaTrustee;
            return trustee
          }
          if (trustee.image.includes("Victoria-Trustee")) {
            trustee.image = VictoriaTrustee;
            return trustee
          }
        }else{
          return trustee
        }
      })
    }

    trustees_members.trustees = trusteesArr;
    setAboutUsData({
      ...aboutUsData,
      mainImageDesktop: main.mainImageDesktop.includes("static/media/") ? aboutUsBase : main.mainImageDesktop,
      mainImageDesktopFile: "",
      mainImageTablet: main.mainImageTablet.includes("static/media/") ? aboutUsBase : main.mainImageTablet,
      mainImageTabletFile: "",
      mainImageMobile: main.mainImageMobile.includes("static/media/") ? aboutUsBaseMobile : main.mainImageMobile,
      mainImageMobileFile: "",
      mainBaseImage: main.mainBaseImage.includes("static/media/") ? BaseFooter : main.mainBaseImage,
      mainBaseImageFile: "",
      mainTitle: main.mainTitle,
      mainDescription: main.mainDescription,
      achievements: main.achievements,
      subTitle: main.subTitle,
      trusteesTitle: trustees_members.trusteesTitle,
      trustees: trustees_members.trustees,
      bomTitle: trustees_members.bomTitle,
      bomData: trustees_members.bomData,
      philosophy: mission_vision.philosophy,
      mission: mission_vision.mission,
      vision: mission_vision.vision,
      coreValues: mission_vision.coreValues,
      coreValuesData: mission_vision.coreValuesData
    })

    setLoading(false)
  } catch (err) {
    if (err.response) {
      notifyError("Error while getting latest template.");
    }
    setLoading(false)
  }
}
