import { ENDPOINT } from "../constants/api";
import axios from "axios";
import { notifyError, notifySuccess } from "./Toaster";
import moment from "moment";

export const adminActions = {
  getEducationType,
  updateEducationType,
  addEducationType,
};

const token = localStorage.getItem("jwtToken")
  ? localStorage.getItem("jwtToken")
  : "";
const headers = {
  Authorization: `Bearer ${token}`,
  "Content-Type": "application/json",
  "X-Requested-With": "XMLHttpRequest",
};

// Fetch Education Type Data
async function getEducationType(setData, setLoading) {
  try {
    let res = await axios.get(ENDPOINT + `/get-education-type`, {
      headers: headers
    })

    const education = res.data.data.education_type

    // console.log(education)

    let educationArr = education.map((data) => {
      return {
        id: data.id,
        name: data.name,
        enabled: data.enabled
      }
    })

    setData(educationArr)
    setLoading(false)
    // console.log(educationArr)

  } catch (error) {

  }
}

async function updateEducationType(
  data,
  setButtonSpinner,
  setModal,
  setUpdateTable,
  updateTable
) {
  try {

    let res = await axios.post(ENDPOINT + `/update-education-type`, data, {
      headers: headers,
    });

    setUpdateTable(!updateTable);
    setButtonSpinner(false);
    setModal(false)
    notifySuccess(res.data.message);
  } catch (err) {
      notifyError("Error while updating education type!");
    setButtonSpinner(false);
  }
}

async function addEducationType(
  data,
  setButtonSpinner,
  setModal,
  setUpdateTable,
  updateTable
) {
  try {
    let res = await axios.post(ENDPOINT + `/add-education-type`, data, {
      headers: headers,
    });

    if (res.data.success) {
      setUpdateTable(!updateTable);
      setButtonSpinner(false);
      setModal(false);
      notifySuccess(res.data.message);
    }else{
      notifyError(res.data.data.Error);
    }

  } catch (err) {
    if (err.response) {
      notifyError("Error while adding education type!");
    }
    setButtonSpinner(false);
  }
}