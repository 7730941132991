import React, { useState } from 'react';
import { Button, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, FormGroup, Form, Spinner, Input } from 'reactstrap';
import "@kenshooui/react-multi-select/dist/style.css";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import {userActions} from '../../../services/UserServices'

const AddEdit = ({
    addOrEditModal, 
    setAddOrEditModal, 
    toggleAddOrEdit, 
    user,
    action,
    setUpdateTable,
    updateTable,
    selectOptions
}) => {
    const { handleSubmit, errors, control, setValue } = useForm();
    const [buttonSpinner, setButtonSpinner] = useState(false);
    
    const getOptionLabelByValue = (options, value) => {
        const option = options.find((option) => option.value == value);
        return option ? option.label : "";
    };

    const getOptionLabelByRole = (options, label) => {
      const option = options.find((option) => option.label == label);
      return option ? option.label : "";
  };

    const onSubmit = (data) => {
        setButtonSpinner(true);
        let dataToSubmit = {
          email_address: data.email_address,
          given_name: data.given_name,
          last_name: data.last_name,
          middle_name: data.middle_name,
          status: data.status ? data.status.value : 0,
          // suffix: data.suffix,
          sys_role_id: data.sys_role_id ? data.sys_role_id.value : 0
        }

        if(data.password !== ""){
          dataToSubmit.password = data.password;
        }

        if(action == "add"){
          userActions.addUser(dataToSubmit, setButtonSpinner, setUpdateTable, updateTable, setAddOrEditModal)
        }else if("edit"){
          dataToSubmit.user_id = user.id;
          userActions.updateUser(dataToSubmit, setButtonSpinner, setUpdateTable, updateTable, setAddOrEditModal)
        }
    }

    return (
        <Modal isOpen={addOrEditModal} toggle={toggleAddOrEdit} size="lg" backdrop="static">
        <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader toggle={toggleAddOrEdit} className="bg-danger-edit text-white" charCode="">{action == "add" ? "Add User" : "Edit User"}</ModalHeader>
        <ModalBody>
            <Row>
            <Col md="12" xs="12">
                    <FormGroup>
                      <Label
                        htmlFor="sys_role_id"
                        className="text-secondary w-100 font-weight-bolder"
                      >
                        Select Role
                      </Label>
                      <Controller
                        control={control}
                        name="sys_role_id"
                        defaultValue={
                            user.sys_role_id != ""
                              ? {
                                value: user.sys_role_id,
                                label: getOptionLabelByValue(
                                  selectOptions.role,
                                  user.sys_role_id
                                ),
                              }
                              : ""
                            }
                        rules={{ required: true }}
                        invalid={errors.sys_role_id ? true : false}
                        render={({ onChange, value, onBlur, name }) => (
                          <Select
                            options={selectOptions.role}
                            name="sys_role_id"
                            onChange={(e) => {
                              setValue("sys_role_id", e);
                              return e;
                            }}
                            defaultValue={
                                user.role != ""
                              ? {
                                  value: user.sys_role_id,
                                  label: getOptionLabelByValue(
                                    selectOptions.role,
                                    user.sys_role_id
                                  ),
                                }
                              : ""
                            }
                          />
                        )}
                      />
                      {errors.sys_role_id &&
                        errors.sys_role_id.type == "required" && (
                          <small className="text-danger">
                            Role is required!
                          </small>
                        )}
                    </FormGroup>
                </Col>
                <Col md="6" xs="12">
                    <Label className="text-secondary font-weight-bolder">
                        Given Name
                    </Label>
                    <FormGroup>
                        <Controller
                          control={control}
                          name="given_name"
                          rules={{ required: true }}
                          invalid={errors.given_name ? true : false}
                          defaultValue={user.given_name}
                          render={({ onChange, value, onBlur, name }) => (
                            <Input
                              className="form-control bg-light"
                              name="given_name"
                              type="text"
                              onChange={(event) => {
                                setValue("given_name", event.target.value);
                                return event.target.value;
                              }}
                              defaultValue={user.given_name}
                            />
                          )}
                        />
                        {errors.given_name && errors.given_name.type == "required" && (
                          <small className="text-danger">
                            Given Name is required!
                          </small>
                        )}
                    </FormGroup>
                </Col>
                <Col md="6" xs="12">
                    <Label className="text-secondary font-weight-bolder">
                        Last Name
                    </Label>
                    <FormGroup>
                        <Controller
                          control={control}
                          name="last_name"
                          rules={{ required: true }}
                          invalid={errors.last_name ? true : false}
                          defaultValue={user.last_name}
                          render={({ onChange, value, onBlur, name }) => (
                            <Input
                              className="form-control bg-light"
                              name="last_name"
                              type="text"
                              onChange={(event) => {
                                setValue("last_name", event.target.value);
                                return event.target.value;
                              }}
                              defaultValue={user.last_name}
                            />
                          )}
                        />
                        {errors.last_name && errors.last_name.type == "required" && (
                          <small className="text-danger">
                            Last Name is required!
                          </small>
                        )}
                    </FormGroup>
                </Col>
                <Col md="6" xs="12">
                    <Label className="text-secondary font-weight-bolder">
                        Middle Name
                    </Label>
                    <FormGroup>
                        <Controller
                          control={control}
                          name="middle_name"
                          rules={{ required: true }}
                          invalid={errors.middle_name ? true : false}
                          defaultValue={user.middle_name}
                          render={({ onChange, value, onBlur, name }) => (
                            <Input
                              className="form-control bg-light"
                              name="middle_name"
                              type="text"
                              onChange={(event) => {
                                setValue("middle_name", event.target.value);
                                return event.target.value;
                              }}
                              defaultValue={user.middle_name}
                            />
                          )}
                        />
                        {errors.middle_name && errors.middle_name.type == "required" && (
                          <small className="text-danger">
                            Middle Name is required!
                          </small>
                        )}
                    </FormGroup>
                </Col>
                {/* <Col md="6" xs="12">
                    <Label className="text-secondary font-weight-bolder">
                        Suffix
                    </Label>
                    <FormGroup>
                        <Controller
                          control={control}
                          name="suffix"
                          rules={{ required: false }}
                          invalid={errors.suffix ? true : false}
                          defaultValue={user.suffix}
                          render={({ onChange, value, onBlur, name }) => (
                            <Input
                              className="form-control bg-light"
                              name="suffix"
                              type="text"
                              onChange={(event) => {
                                setValue("suffix", event.target.value);
                                return event.target.value;
                              }}
                              defaultValue={user.suffix}
                            />
                          )}
                        />
                        {errors.suffix && errors.suffix.type == "required" && (
                          <small className="text-danger">
                            Suffix is required!
                          </small>
                        )}
                    </FormGroup>
                </Col> */}
                <Col md="6" xs="12">
                    <Label className="text-secondary font-weight-bolder">
                        Email Address
                    </Label>
                    <FormGroup>
                        <Controller
                          control={control}
                          name="email_address"
                          rules={{ required: true }}
                          invalid={errors.email_address ? true : false}
                          defaultValue={user.email_address}
                          render={({ onChange, value, onBlur, name }) => (
                            <Input
                              className="form-control bg-light"
                              name="email_address"
                              type="text"
                              onChange={(event) => {
                                setValue("email_address", event.target.value);
                                return event.target.value;
                              }}
                              defaultValue={user.email_address}
                            />
                          )}
                        />
                        {errors.email_address && errors.email_address.type == "required" && (
                          <small className="text-danger">
                            Email address is required!
                          </small>
                        )}
                    </FormGroup>
                </Col>
                <Col md="6" xs="12">
                    <Label className="text-secondary font-weight-bolder">
                        Password
                    </Label>
                    <FormGroup>
                        <Controller
                          control={control}
                          name="password"
                          rules={{ required: action == "add" ? true : false, minLength: action == "add" ? 8 : 0 }}
                          invalid={errors.password ? true : false}
                          defaultValue=""
                          render={({ onChange, value, onBlur, name }) => (
                            <Input
                              className="form-control bg-light"
                              name="password"
                              type="password"
                              onChange={(event) => {
                                setValue("password", event.target.value);
                                return event.target.value;
                              }}
                              defaultValue=""
                            />
                          )}
                        />
                        {errors.password && errors.password.type == "required" && (
                          <small className="text-danger">
                            Password is required!
                          </small>
                        )}
                        {errors.password && errors.password.type == "minLength" && (
                          <div style={{marginTop: "0.25rem", fontSize: "80%", color: "#f86c6b" }}>
                            Password must be atleast 8 characters
                          </div>
                        )}
                    </FormGroup>
                </Col>

                <Col md="6" xs="12">
                    <FormGroup>
                      <Label
                        htmlFor="status"
                        className="text-secondary w-100 font-weight-bolder"
                      >
                        Status
                      </Label>
                      <Controller
                        control={control}
                        name="status"
                        defaultValue={
                            user.status !== ""
                              ? {
                                value: user.status,
                                label: getOptionLabelByValue(
                                  selectOptions.status,
                                  user.status
                                ),
                              }
                              : ""
                            }
                        rules={{ required: true }}
                        invalid={errors.status ? true : false}
                        render={({ onChange, value, onBlur, name }) => (
                          <Select
                            options={selectOptions.status}
                            name="status"
                            onChange={(e) => {
                              setValue("status", e);
                              return e;
                            }}
                            defaultValue={
                                user.status !== ""
                              ? {
                                  value: user.status,
                                  label: getOptionLabelByValue(
                                    selectOptions.status,
                                    user.status
                                  ),
                                }
                              : ""
                            }
                          />
                        )}
                      />
                      {errors.status &&
                        errors.status.type == "required" && (
                          <small className="text-danger">
                            Status is required!
                          </small>
                        )}
                    </FormGroup>
                </Col>
            </Row>
        </ModalBody>
        <ModalFooter>
        <Button className="bg-warning-edit border-0" disabled={buttonSpinner}>
            {buttonSpinner ? (
              <>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </>
            ) : (
              "Save"
            )}
          </Button>
          <Button color="secondary" disabled={buttonSpinner} onClick={toggleAddOrEdit}>Close</Button>
        </ModalFooter>
        </Form>
      </Modal>
    )
}
export default AddEdit;