import React, { useState, useEffect } from "react";
import { globalFilterActions } from "../../../../services/GlobalFilterService";
import {
  Button,
  Col,
  Container,
  InputGroup,
  InputGroupAddon,
  Row,
  UncontrolledTooltip,
  Input,
  ModalHeader,
  ModalBody,
  Modal,
  FormGroup,
  Label,
  ModalFooter,
  Form,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import overlayFactory from "react-bootstrap-table2-overlay";
import { isBrowser, isDesktop, isMobile } from "react-device-detect";
import { AiFillEdit } from "react-icons/ai";
import { useForm, Controller } from "react-hook-form";

const GlobalFilters = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [action, setAction] = useState("");
  const [updateTable, setUpdateTable] = useState(false);
  const { handleSubmit, errors, control, setValue, getValues } = useForm();
  const blankData = {
    id: "",
    name: "",
    description: "",
  };
  const [singleData, setSingleData] = useState(blankData);

  useEffect(() => {
    setLoading(true);
    globalFilterActions.getFilters(setData, setLoading);
  }, [updateTable]);

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "description",
      text: "Description",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "id",
      text: "Action",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
      formatter: (cell, row) => {
        return (
          <div
            className="px-2 justify-content-center d-flex"
            style={{ minWidth: "50px" }}
          >
            <Button
              id={"upload-btn" + cell}
              onClick={() => toggleAddOrEdit(row, "edit")}
              className="btn-warning mr-2"
            >
              <AiFillEdit style={{ fontSize: "1.3rem" }} />
            </Button>
            <UncontrolledTooltip placement="top" target={"upload-btn" + cell}>
              Edit
            </UncontrolledTooltip>
          </div>
        );
      },
    },
  ];

  const [addOrEditModal, setAddOrEditModal] = useState(false);
  const toggleAddOrEdit = (details, action) => {
    setSingleData(blankData);
    setAction("");
    if (!addOrEditModal) {
      setAction(action);
      if (action == "edit") {
        setSingleData(details);
      }
      setAddOrEditModal(!addOrEditModal);
    } else {
      setAddOrEditModal(!addOrEditModal);
    }
  };

  const NoDataIndication = () => <div>No Data</div>;

  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    if (data.length >= 1) {
      //search INPUT
      let filteredUsers = data.filter((d) => {
        return d.name.toLowerCase().includes(searchText.toLowerCase());
      });

      setFilteredData(filteredUsers);
    }
  }, [searchText, data]);

  const onSubmit = (d) => {
    setButtonSpinner(true);
    let dataToSubmit = {
      id: singleData.id,
      name: d.name,
      description: d.description,
    };
    globalFilterActions.updateFilter(
      dataToSubmit,
      setButtonSpinner,
      setUpdateTable,
      updateTable,
      setAddOrEditModal
    );
  };

  return (
    <div className="p-4">
      <Row>
        <Col md="4" xs="12" className="ml-auto mb-2">
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <Button type="button" color="primary" id="search-button">
                <i className="fa fa-search"></i>
              </Button>
            </InputGroupAddon>
            <Input
              type="text"
              id="search-text"
              name="search-text"
              placeholder="Search"
              onChange={(e) => setSearchText(e.target.value)}
            />
          </InputGroup>
          <p>
            <small className="font-weight-bolder">
              <i className="fa fa-lightbulb-o"></i>&nbsp;Search by Name
            </small>
          </p>
        </Col>
      </Row>

      <BootstrapTable
        wrapperClasses="table-responsive mb-3 text-center"
        bootstrap4
        striped
        keyField="id"
        data={filteredData}
        columns={columns}
        loading={loading}
        classes={isMobile ? "mobileTable" : "desktopTable"}
        pagination={paginationFactory()}
        overlay={overlayFactory({
          spinner: true,
          styles: {
            overlay: (base) => ({
              ...base,
              background: "rgba(0, 0, 0, 0.5)",
            }),
          },
        })}
        noDataIndication={() => <NoDataIndication />}
      />
      <Modal isOpen={addOrEditModal} backdrop="static">
        <ModalHeader toggle={toggleAddOrEdit}>Update</ModalHeader>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <Row className="border-danger">
              <Col md="12" xs="12">
                <FormGroup>
                  <Label className="text-secondary font-weight-bolder w-100">
                    Name
                  </Label>
                  <Controller
                    control={control}
                    name="name"
                    rules={{ required: true }}
                    invalid={errors.name ? true : false}
                    defaultValue={singleData.name}
                    render={({ onChange, value, onBlur, name }) => (
                      <Input
                        className="form-control bg-light"
                        name="name"
                        onChange={(event) => {
                          // setSingleData({ ...singleData, name: event.target.value })
                          setValue("name", event.target.value);
                          return event.target.value;
                        }}
                        defaultValue={singleData.name}
                      />
                    )}
                  />
                  {errors.name && (
                    <div
                      style={{
                        marginTop: "0.25rem",
                        fontSize: "80%",
                        color: "#f86c6b",
                      }}
                    >
                      Name is required!
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col md="12" xs="12">
                <FormGroup>
                  <Label className="text-secondary font-weight-bolder w-100">
                    Description
                  </Label>
                  <Controller
                    control={control}
                    name="description"
                    rules={{ required: true }}
                    invalid={errors.description ? true : false}
                    defaultValue={singleData.description}
                    render={({ onChange, value, onBlur, name }) => (
                      <Input
                        className="form-control bg-light"
                        name="description"
                        onChange={(event) => {
                          // setSingleData({ ...singleData, description: event.target.value })
                          setValue("description", event.target.value);
                          return event.target.value;
                        }}
                        defaultValue={singleData.description}
                      />
                    )}
                  />
                  {errors.description && (
                    <div
                      style={{
                        marginTop: "0.25rem",
                        fontSize: "80%",
                        color: "#f86c6b",
                      }}
                    >
                      Description is required!
                    </div>
                  )}
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button disabled={buttonSpinner} color="success">
              Save
            </Button>
            <Button
              disabled={buttonSpinner}
              onClick={() => setAddOrEditModal(!addOrEditModal)}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default GlobalFilters;
