import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Row,
  Input,
  Label,
  Form,
  FormGroup,
  Card,
  CardHeader,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import Select from "react-select";
import { educationActions } from "../../../services/EducationSelectionService.js";
import { useForm, Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import optionList from "../Admission/optionList";
import { studentGradeImportActions } from "../../../services/StudentGradeImportServices.js";
import { DownloadTemplate } from "./DownloadTemplate.js";
import { UploadGrades } from "./UploadGrades.js";
import classnames from "classnames";

export const StudentGradeImport = () => {
  const [gradeLevels, setGradeLevels] = useState([]);
  const [courses, setCourses] = useState([]);
  const [coursesSelection, setCoursesSelection] = useState([]);
  const [gradeLevelSelection, setGradeLevelSelection] = useState([]);
  const [sections, setSections] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const [gradeTypes, setGradeTypes] = useState([]);

  useEffect(() => {
    if (gradeLevels.length >= 1) {
      let level = gradeLevels.map((grade) => {
        return {
          value: grade.id,
          label: grade.name,
          grade_type: grade.grade_type_id.id,
        };
      });
      setGradeLevelSelection(level);
    }

    if (courses.length >= 1) {
      let c = courses.map((course) => {
        return { value: course.id, label: course.name };
      });
      setCoursesSelection(c);
    }
  }, [gradeLevels, courses]);

  useEffect(() => {
    educationActions.getApplications(
      null,
      null,
      setGradeTypes,
      setGradeLevels,
      null,
      setCourses,
      null,
      null,
      setSections
    );
  }, []);

  return (
    <div className="p-4">
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === 1 })}
            onClick={() => setActiveTab(1)}
          >
            Download Template
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === 2 })}
            onClick={() => setActiveTab(2)}
          >
            Upload Student Grades
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId={1}>
          <DownloadTemplate
            gradeLevelSelection={gradeLevelSelection}
            coursesSelection={coursesSelection}
            sections={sections}
          />
        </TabPane>
        <TabPane tabId={2}>
          <UploadGrades
            gradeLevelSelection={gradeLevelSelection}
            coursesSelection={coursesSelection}
            sections={sections}
            gradeTypes={gradeTypes}
          />
        </TabPane>
      </TabContent>
    </div>
  );
};
