import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Label, Input, FormGroup, Form, Spinner } from 'reactstrap';
import { useForm, Controller } from "react-hook-form";
import { adminActions } from '../../../services/GradeLevelServices';
import Select from "react-select";

const GradeLevelModal = ({
    modal,
    setModal,
    handleEditGradeLevel,
    editGradeLevel,
    updateTable,
    setUpdateTable,
    action,
    selectEducationOptions,
    selectGradeTypeOptions,
    educationId,
    statusSelection
}) => {

    // console.log(editEducationType)
    // console.log(action)

    const { register, handleSubmit, errors, control, setValue, getValues } = useForm();
    const [buttonSpinner, setButtonSpinner] = useState(false);
    const [educationType, setEducationType] = useState("");
    const [gradeTypeSelection, setGradeTypeSelection] = useState([]);
    const [educationIdFilter, setEducationIdFilter] = useState([]);

    // console.log(educationType);

    useEffect(() => {
        let gradeTypeArr = selectGradeTypeOptions.grade_type.filter((gradeType) => {
            // console.log(gradeType)
            return gradeType.education_type == educationType
        })
        setGradeTypeSelection(gradeTypeArr);
    }, [educationType])

    const getOptionLabelByValue = (options, value) => {
        const option = options.find((option) => option.value == value);
        return option ? option.label : "";
    };

    const submitForm = (data) => {
        setButtonSpinner(true)
        data.enabled = data.enabled ? data.enabled.value : ""
        if (action == "add") {
            // data.education_type_id = data.education_type ? data.education_type.value : ""
            data.grade_type_id = data.grade_type ? data.grade_type.value : ""
            data.grade_level_name = data.name;
            // console.log(data)
            adminActions.addGradeLevel(data,
                setButtonSpinner,
                setModal,
                setUpdateTable,
                updateTable)

        } else if (action == "edit") {
            // data.education_type_id = data.education_type ? data.education_type.value : ""
            data.grade_type_id = data.grade_type ? data.grade_type.value : ""
            data.grade_level_id = editGradeLevel.id;
            data.grade_level_name = data.name;
            // console.log(data)
            adminActions.updateGradeLevel(data,
                setButtonSpinner,
                setModal,
                setUpdateTable,
                updateTable)
        }

    }

    return (
        <Modal isOpen={modal} toggle={handleEditGradeLevel} backdrop="static" size="lg">
            <Form onSubmit={handleSubmit(submitForm)}>
                <ModalHeader toggle={handleEditGradeLevel}>{action == 'add' ? "Add" : "Update"} Grade Level</ModalHeader>
                <ModalBody>
                    <Row>
                        {/* <Col md="12" xs="12">
                            <FormGroup>
                                <Label
                                    htmlFor="education_type"
                                    className="text-left w-100 text-danger-edit font-weight-bolder"
                                >
                                    Select Education Type
                                </Label>
                                <Controller
                                    control={control}
                                    name="education_type"
                                    defaultValue={
                                        editGradeLevel.education_type_id != ""
                                            ? {
                                                value: editGradeLevel.education_type_id,
                                                label: getOptionLabelByValue(
                                                    selectEducationOptions.education_type,
                                                    editGradeLevel.education_type_id
                                                ),
                                            }
                                            : ""
                                    }
                                    rules={{ required: true }}
                                    invalid={errors.education_type ? true : false}
                                    render={({ onChange, value, onBlur, name }) => (
                                        <Select
                                            options={selectEducationOptions.education_type}
                                            name="education_type"
                                            onChange={(e) => {
                                                setEducationType(e.value);
                                                setValue("education_type", e);
                                                setValue("grade_type", "");
                                                return e;
                                            }}
                                            defaultValue={
                                                editGradeLevel.education_type_id != ""
                                                    ? {
                                                        value: editGradeLevel.education_type_id,
                                                        label: getOptionLabelByValue(
                                                            selectEducationOptions.education_type,
                                                            editGradeLevel.education_type_id
                                                        ),
                                                    }
                                                    : ""
                                            }
                                        />
                                    )}
                                />
                                {errors.education_type &&
                                    errors.education_type.type == "required" && (
                                        <small className="text-danger">
                                            Education type is required!
                                        </small>
                                    )}
                            </FormGroup>
                        </Col> */}
                        <Col md="12" xs="12">
                            <FormGroup>
                                <Label
                                    htmlFor="grade_type"
                                    className="text-left w-100 text-danger-edit font-weight-bolder"
                                >
                                    Select Grade Type
                                </Label>
                                <Controller
                                    control={control}
                                    name="grade_type"
                                    defaultValue={
                                        editGradeLevel.grade_type_id != ""
                                            ? {
                                                value: editGradeLevel.grade_type_id,
                                                label: getOptionLabelByValue(
                                                    selectGradeTypeOptions.grade_type,
                                                    editGradeLevel.grade_type_id
                                                ),
                                            }
                                            : ""
                                    }
                                    rules={{ required: true }}
                                    invalid={errors.grade_type ? true : false}
                                    render={({ onChange, value, onBlur, name }) => (
                                        <Select
                                            options={selectGradeTypeOptions.grade_type}
                                            name="grade_type"
                                            onChange={(e) => {
                                                setValue("grade_type", e);
                                                return e;
                                            }}
                                            defaultValue={
                                                editGradeLevel.grade_type_id != ""
                                                    ? {
                                                        value: editGradeLevel.grade_type_id,
                                                        label: getOptionLabelByValue(
                                                            selectGradeTypeOptions.grade_type,
                                                            editGradeLevel.grade_type_id
                                                        ),
                                                    }
                                                    : ""
                                            }
                                        />
                                    )}
                                />
                                {errors.grade_type &&
                                    errors.grade_type.type == "required" && (
                                        <small className="text-danger">
                                            Grade type is required!
                                        </small>
                                    )}
                            </FormGroup>
                        </Col>
                        <Col md="12" xs="12">
                            <FormGroup>
                                <Label htmlFor="name">Grade Level:</Label>
                                <Controller
                                    control={control}
                                    name="name"
                                    id="name"
                                    defaultValue={editGradeLevel.name}
                                    rules={{ required: true }}
                                    invalid={errors.name ? true : false}
                                    render={({ onChange, value, onBlur, name }) => (
                                        <Input
                                            name="name"
                                            defaultValue={editGradeLevel.name}
                                            onChange={(e) => {
                                                setValue('name', e.target.value)
                                                // console.log(e.target.value);
                                            }}
                                        />
                                    )}
                                />
                                {errors.name && errors.name.type == "required" ?
                                    <small className="text-danger">
                                        Grade type is required!
                                    </small>
                                    :
                                    ""
                                }
                            </FormGroup>
                        </Col>
                        <Col md="12" xs="12">
                            <FormGroup>
                                <Label
                                    htmlFor="enabled"
                                    className="text-secondary w-100 font-weight-bolder"
                                >
                                    Status
                                </Label>
                                <Controller
                                    control={control}
                                    name="enabled"
                                    defaultValue={
                                        editGradeLevel.enabled !== ""
                                        ? {
                                            value: editGradeLevel.enabled,
                                            label: getOptionLabelByValue(
                                            statusSelection,
                                            editGradeLevel.enabled
                                            ),
                                        }
                                        : ""
                                        }
                                    rules={{ required: true }}
                                    invalid={errors.enabled ? true : false}
                                    render={({ onChange, value, onBlur, name }) => (
                                    <Select
                                        options={statusSelection}
                                        name="enabled"
                                        onChange={(e) => {
                                        setValue("enabled", e);
                                        return e;
                                        }}
                                        defaultValue={
                                            editGradeLevel.enabled !== ""
                                        ? {
                                            value: editGradeLevel.enabled,
                                            label: getOptionLabelByValue(
                                                statusSelection,
                                                editGradeLevel.enabled
                                            ),
                                            }
                                        : ""
                                        }
                                    />
                                    )}
                                />
                                {errors.enabled &&
                                    errors.enabled.type == "required" && (
                                    <small className="text-danger">
                                        Status is required!
                                    </small>
                                    )}
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button className="bg-warning-edit border-0" disabled={buttonSpinner}>
                        {buttonSpinner ? (
                            <>
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            </>
                        ) : (
                            "Submit"
                        )}
                    </Button>
                    <Button color="secondary" onClick={handleEditGradeLevel} disabled={buttonSpinner}>Cancel</Button>
                </ModalFooter>
            </Form>
        </Modal>
    )
}

export default GradeLevelModal;