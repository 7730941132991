import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  FormGroup,
  Input,
  Form,
  FormFeedback,
  Spinner,
  Label,
  Row,
  Col,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { isDesktop } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { authActions } from "../../../services/AuthService";

const ResetPassword = ({
  emailAdress,
  setForgotPwProcess,
  verificationCode,
  verificationId,
}) => {
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    register,
    errors,
    reset,
    getValues,
    setValue,
  } = useForm();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [modal, setModal] = useState(true);
  const toggle = () => {
    if (!modal) {
      setModal(!modal);
    } else {
      setModal(!modal);
      navigate("/login");
    }
  };

  const onSubmit = (data) => {
    setButtonSpinner(true);
    let newData = {
      password: data.password,
      password_confirm: data.confirm_password,
      email_address: emailAdress,
      verification_id: verificationId,
    };

    authActions.updatePassword(
      newData,
      setError,
      setSuccess,
      setButtonSpinner,
      setForgotPwProcess
    );
  };

  return (
    <div className="justify-content-center align-items-center d-flex">
      <Modal
        isOpen={modal}
        toggle={toggle}
        backdrop="static"
        className="landingPage modal-center"
      >
        <ModalBody>
          <span
            style={{ fontSize: "25px" }}
            className="font-weight-bolder hand-pointer"
            placeholder="Back"
            onClick={() => navigate("/login")}
          >
            &#8592;
          </span>
          <div
            className={
              isDesktop ? "text-center mt-5 mb-5 mx-5" : "text-center mt-5 mb-5"
            }
          >
            {!success ? (
              <>
                <h3 className="font-weight-bolder">
                  <i className="fa fa-lock" style={{ fontSize: "50px" }}></i>{" "}
                  RESET PASSWORD
                </h3>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    <Col xs="12" md="12">
                      <FormGroup>
                        <Label htmlFor="password" className="text-secondary">
                          New Password
                        </Label>
                        <Controller
                          as={<Input className="form-control rounded-0" />}
                          onChange={([event]) => {
                            return event;
                          }}
                          value=""
                          defaultValue=""
                          type="password"
                          id="password"
                          className="text-center"
                          name="password"
                          control={control}
                          // rules={{ required: true, pattern: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&-+=()])(?=\\S+$).{8, 20}$/ }}
                          rules={{ required: true, minLength: 8 }}
                          invalid={errors.password ? true : false}
                        />
                        {errors.password &&
                          errors.password.type == "required" && (
                            <div
                              style={{
                                marginTop: "0.25rem",
                                fontSize: "80%",
                                color: "#f86c6b",
                              }}
                            >
                              New password is required!
                            </div>
                          )}
                        {errors.password &&
                          errors.password.type == "minLength" && (
                            <div
                              style={{
                                marginTop: "0.25rem",
                                fontSize: "80%",
                                color: "#f86c6b",
                              }}
                            >
                              Password must be atleast 8 characters
                            </div>
                          )}
                      </FormGroup>
                    </Col>
                    <Col xs="12" md="12">
                      <FormGroup>
                        <Label
                          htmlFor="confirm_password"
                          className="text-secondary"
                        >
                          Repeat Password
                        </Label>
                        <Controller
                          as={<Input className="form-control rounded-0" />}
                          onChange={([event]) => {
                            return event;
                          }}
                          value=""
                          defaultValue=""
                          type="password"
                          id="confirm_password"
                          className="text-center"
                          name="confirm_password"
                          control={control}
                          rules={{
                            required: true,
                            validate: (value) => value == getValues("password"),
                          }}
                          invalid={errors.confirm_password ? true : false}
                        />
                        {errors.confirm_password &&
                          errors.confirm_password.type == "required" && (
                            <div
                              style={{
                                marginTop: "0.25rem",
                                fontSize: "80%",
                                color: "#f86c6b",
                              }}
                            >
                              Confirm password is required!
                            </div>
                          )}
                        {errors.confirm_password &&
                          errors.confirm_password.type == "validate" && (
                            <div
                              style={{
                                marginTop: "0.25rem",
                                fontSize: "80%",
                                color: "#f86c6b",
                              }}
                            >
                              New password and confirm password does not
                              matched!
                            </div>
                          )}
                      </FormGroup>
                    </Col>
                  </Row>

                  <Button
                    disabled={buttonSpinner}
                    className="bg-warning-edit text-white font-weight-bolder border-0 mb-2 w-100 button-transition"
                  >
                    {buttonSpinner ? (
                      <>
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        &nbsp;Processing...
                      </>
                    ) : (
                      "SUBMIT"
                    )}
                  </Button>
                </Form>
              </>
            ) : (
              <>
                <h5 className="font-weight-bolder text-secondary">
                  Password reset successful
                </h5>
                <Button
                  className="btn bg-warning rounded-pill py-2 px-4 border-0"
                  onClick={() => {
                    navigate("./login");
                  }}
                >
                  Login
                </Button>
              </>
            )}
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};
export default ResetPassword;
