import { ENDPOINT } from "../constants/api";
import axios from "axios";
import { notifyError, notifySuccess } from "./Toaster";
import moment from "moment";

export const adminActions = {
  getEducationType,
  getGradeType,
  updateGradeType,
  addGradeType,
};

const token = localStorage.getItem("jwtToken")
  ? localStorage.getItem("jwtToken")
  : "";
const headers = {
  Authorization: `Bearer ${token}`,
  "Content-Type": "application/json",
  "X-Requested-With": "XMLHttpRequest",
};

// Fetch Education Type Data
async function getEducationType(selectOptions, setSelectOptions) {
  try {
    let res = await axios.get(ENDPOINT + `/get-education-type`, {
      headers: headers
    })

    let education = res.data.data.education_type;

    let educationSelections = education.filter((educationType) => {
      return educationType.enabled
    })

    let educationTypeArr = educationSelections.map((educationType) => {
        return {
          value: educationType.id,
          label: educationType.name
        }
    })

    setSelectOptions({...selectOptions, education_type: educationTypeArr})
    // console.log(educationArr)

  } catch (error) {
    notifyError("Error while fetching Data");
  }
}

// Fetch Grade Type Data
async function getGradeType(setData, setCoordinators, setLoading) {
  try {
    let res = await axios.get(ENDPOINT + `/get-grade-type`, {
      headers: headers
    })

    // console.log(res.data.data.grade_type)

    const grade = res.data.data.grade_type
    const coordinator_list = res.data.data.coordinator_list

    // console.log(grade)

    let gradeArr = grade.map((data) => {
      return {
        education_type_id: data.education_type_id.id,
        education_type_name: data.education_type_id.name,
        id: data.id,
        name: data.name,
        coordinator_id: data.coordinator_id,
        enabled: data.enabled
      }
    })

    // console.log(gradeArr)
    setData(gradeArr)
    setCoordinators(coordinator_list)
    setLoading(false)

  } catch (error) {
    notifyError("Error while fetching Data");
  }
}

async function updateGradeType(
  data,
  setButtonSpinner,
  setModal,
  setUpdateTable,
  updateTable
) {
  try {

    let res = await axios.post(ENDPOINT + `/update-grade-type`, data, {
      headers: headers,
    });

    // console.log(res)

    setUpdateTable(!updateTable);
    setButtonSpinner(false);
    setModal(false)
    notifySuccess(res.data.message);
  } catch (err) {
    if (err.response) {
      notifyError("Error while updating grade type!");
    }
    setButtonSpinner(false);
  }
}

async function addGradeType(
  data,
  setButtonSpinner,
  setModal,
  setUpdateTable,
  updateTable
) {
  try {
    let res = await axios.post(ENDPOINT + `/add-grade-type`, data, {
      headers: headers,
    });

    if (res.data.success) {
      setUpdateTable(!updateTable);
      setButtonSpinner(false);
      setModal(false);
      notifySuccess(res.data.message);
    }else{
      notifyError(res.data.data.Error);
    }

  } catch (err) {
    if (err.response) {
      notifyError("Error while adding grade type!");
    }
    setButtonSpinner(false);
  }
}