import React, { useState, useEffect } from "react";
import { userActions } from "../../../services/UserServices";
import { useForm } from "react-hook-form";
import {
  Button,
  Col,
  Container,
  InputGroup,
  InputGroupAddon,
  Row,
  UncontrolledTooltip,
  Input,
} from "reactstrap";
import View from "../../../assets/img/view.png";
import Edit from "../../../assets/img/edit.png";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import overlayFactory from "react-bootstrap-table2-overlay";
import { isBrowser, isDesktop, isMobile } from "react-device-detect";
import Select from "react-select";
import AddEdit from "./AddEdit";
import ViewUser from "./ViewUser";
import Add from "../../../assets/img/ADMISSION.png";
import MobileSideBar from "../Admission/MobileSidebar";
import states from "../States/States";

const Users = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const { register, handleSubmit, errors, control, setValue, getValues } =
    useForm();
  const [userRoleFilter, setUserRoleFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [action, setAction] = useState("");
  const [updateTable, setUpdateTable] = useState(false);
  const [selectOptions, setSelectOptions] = useState({
    role: [],
    status: [
      { value: 0, label: "InActive" },
      { value: 1, label: "Active" },
    ],
  });

  const [user, setUser] = useState(states.blankUser);

  useEffect(() => {
    setLoading(true);
    userActions.getUsers(setData, setLoading);
  }, [updateTable]);

  useEffect(() => {
    userActions.getRoles(selectOptions, setSelectOptions);
  }, []);

  const columns = [
    {
      dataField: "role",
      text: "Role",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "email_address",
      text: "Email",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "last_name",
      text: "Last Name",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "given_name",
      text: "Given Name",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "is_verify",
      text: "Email Verify",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
      formatter: (cell) => {
        return cell ? "Yes" : "No";
      },
    },
    {
      dataField: "status",
      text: "Status",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
      formatter: (cell) => {
        return cell ? "Active" : "InActive";
      },
    },
    {
      dataField: "created_at",
      text: "Date Created",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "id",
      text: "Action",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
      formatter: (cell, row) => {
        return (
          <div
            className="px-2 justify-content-center d-flex"
            style={{ minWidth: "50px" }}
          >
            <Button
              id={"paid-btn" + cell}
              onClick={() => toggleView(row, "view")}
              className="btn-warning mr-2"
            >
              <img src={View} style={{ height: "1.5rem", objectFit: "fill" }} />
            </Button>
            <UncontrolledTooltip placement="top" target={"paid-btn" + cell}>
              View
            </UncontrolledTooltip>

            <Button
              id={"upload-btn" + cell}
              onClick={() => toggleAddOrEdit(row, "edit")}
              className="btn-warning mr-2"
            >
              <img src={Edit} style={{ height: "1.5rem", objectFit: "fill" }} />
            </Button>
            <UncontrolledTooltip placement="top" target={"upload-btn" + cell}>
              Edit
            </UncontrolledTooltip>
          </div>
        );
      },
    },
  ];

  const [viewModal, setViewModal] = useState(false);
  const toggleView = (details, action) => {
    setUser(states.blankUser);
    setAction("");
    if (!viewModal) {
      setAction(action);
      setUser(details);
      setViewModal(!viewModal);
    } else {
      setViewModal(!viewModal);
    }
  };

  const [addOrEditModal, setAddOrEditModal] = useState(false);
  const toggleAddOrEdit = (details, action) => {
    setUser(states.blankUser);
    setAction("");
    if (!addOrEditModal) {
      setAction(action);
      if (action == "edit") {
        setUser(details);
      }
      setAddOrEditModal(!addOrEditModal);
    } else {
      setAddOrEditModal(!addOrEditModal);
    }
  };

  const NoDataIndication = () => <div>No Data</div>;

  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    if (data.length >= 1) {
      let newArr = [];

      //search INPUT
      let filteredUsers = data.filter((d) => {
        return (
          d.email_address.toLowerCase().includes(searchText.toLowerCase()) ||
          d.given_name.toLowerCase().includes(searchText.toLowerCase()) ||
          d.last_name.toLowerCase().includes(searchText.toLowerCase())
        );
      });
      newArr = filteredUsers;

      //filter
      if (userRoleFilter !== "") {
        let filtereduserRole = newArr.filter((f) => {
          return f.role.toLowerCase() == userRoleFilter.toLowerCase();
        });

        newArr = filtereduserRole;
      }

      if (statusFilter !== "") {
        let filteredStatus = newArr.filter((f) => {
          return f.status == statusFilter;
        });

        newArr = filteredStatus;
      }

      setFilteredData(newArr);
    }
  }, [userRoleFilter, statusFilter, searchText, data]);

  return (
    <div className="p-4">
      {addOrEditModal ? (
        <AddEdit
          addOrEditModal={addOrEditModal}
          setAddOrEditModal={setAddOrEditModal}
          toggleAddOrEdit={toggleAddOrEdit}
          user={user}
          action={action}
          setUpdateTable={setUpdateTable}
          updateTable={updateTable}
          selectOptions={selectOptions}
        />
      ) : (
        ""
      )}

      {viewModal ? (
        <ViewUser viewModal={viewModal} toggleView={toggleView} user={user} />
      ) : (
        ""
      )}

      <Row>
        <Col md="12" xs="12" className="mb-2">
          <Button
            className="btn bg-danger-edit"
            onClick={() => toggleAddOrEdit("", "add")}
          >
            <img src={Add} style={{ height: "1rem" }} />
          </Button>
        </Col>
        <Col md="3" xs="12" className="mr-auto mb-2">
          <Select
            options={selectOptions.role}
            onChange={(e) => {
              setUserRoleFilter(e.label);
              return e;
            }}
            defaultValue=""
          />
          <p>
            <small className="font-weight-bolder">
              <i className="fa fa-lightbulb-o"></i>&nbsp;Filter by Role
            </small>
          </p>
        </Col>
        <Col md="3" xs="12" className="mr-auto mb-2">
          <Select
            options={selectOptions.status}
            onChange={(e) => {
              setStatusFilter(e.value);
              return e;
            }}
            defaultValue=""
          />
          <p>
            <small className="font-weight-bolder">
              <i className="fa fa-lightbulb-o"></i>&nbsp;Filter by Status
            </small>
          </p>
        </Col>
        <Col md="4" xs="12" className="ml-auto mb-2">
          <InputGroup>
            <InputGroupAddon addonType="prepend" style={{ zIndex: "0" }}>
              <Button type="button" color="primary" id="search-button">
                <i className="fa fa-search"></i>
              </Button>
            </InputGroupAddon>
            <Input
              type="text"
              id="search-text"
              name="search-text"
              placeholder="Search"
              onChange={(e) => setSearchText(e.target.value)}
            />
          </InputGroup>
          <p>
            <small className="font-weight-bolder">
              <i className="fa fa-lightbulb-o"></i>&nbsp;Search by Email, First
              and Last Name
            </small>
          </p>
        </Col>
      </Row>

      <BootstrapTable
        wrapperClasses="table-responsive mb-3 text-center"
        bootstrap4
        striped
        keyField="id"
        data={filteredData}
        columns={columns}
        loading={loading}
        classes={isMobile ? "mobileTable" : "desktopTable"}
        pagination={paginationFactory()}
        overlay={overlayFactory({
          spinner: true,
          styles: {
            overlay: (base) => ({
              ...base,
              background: "rgba(0, 0, 0, 0.5)",
            }),
          },
        })}
        noDataIndication={() => <NoDataIndication />}
      />
    </div>
  );
};

export default Users;
