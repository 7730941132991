import React, { useState, useEffect } from "react";
import { sectionActions } from "../../../../services/SectionServices";
import { useForm } from "react-hook-form";
import {
  Button,
  Col,
  Container,
  InputGroup,
  InputGroupAddon,
  Row,
  UncontrolledTooltip,
  Input,
} from "reactstrap";
import View from "../../../../assets/img/view.png";
import Edit from "../../../../assets/img/edit.png";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import overlayFactory from "react-bootstrap-table2-overlay";
import { isBrowser, isDesktop, isMobile } from "react-device-detect";
import Select from "react-select";
import AddEdit from "./AddEdit";
import Add from "../../../../assets/img/ADMISSION.png";
import { educationActions } from "../../../../services/EducationSelectionService";
import states from "../../States/States";

const Sections = () => {
  const [data, setData] = useState([]);
  const [gradeLevels, setGradeLevels] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [gradeLevelFilter, setGradeLevelFilter] = useState("");
  const [action, setAction] = useState("");
  const [updateTable, setUpdateTable] = useState(false);
  const [gradeLevelSelection, setGradeLevelSelection] = useState([]);
  const [coursesSelection, setCoursesSelection] = useState([]);
  const [courses, setCourses] = useState([]);
  const [teachersSelection, setTeachersSelection] = useState([]);
  const [coordinatorsSelection, setCoordinatorsSelection] = useState([]);

  const [selectOptions, setSelectOptions] = useState({
    status: [
      { value: 0, label: "InActive" },
      { value: 1, label: "Active" },
    ],
  });

  const [section, setSection] = useState(states.blankSingleSection);

  useEffect(() => {
    educationActions.getApplications(
      null,
      null,
      null,
      setGradeLevels,
      null,
      setCourses,
      null,
      null
    );
  }, []);

  useEffect(() => {
    setLoading(true);
    sectionActions.getSections(
      setData,
      setLoading,
      setTeachersSelection,
      setCoordinatorsSelection
    );
  }, [updateTable]);

  useEffect(() => {
    if (gradeLevels.length >= 1) {
      let grLevels = gradeLevels.map((type) => {
        return {
          value: type.id,
          label: type.name,
          grade_type_id: type.grade_type_id.id,
        };
      });
      setGradeLevelSelection(grLevels);
    }
  }, [gradeLevels]);

  useEffect(() => {
    if (courses.length >= 1) {
      let coursesArr = courses.map((type) => {
        return {
          value: type.id,
          label: type.name,
          grade_type: type.grade_type_id,
        };
      });
      setCoursesSelection(coursesArr);
    }
  }, [courses]);

  const getOptionLabelByValue = (options, value) => {
    const option = options.find((option) => option.value == value);
    return option ? option.label : "";
  };

  const columns = [
    {
      dataField: "grade_level",
      text: "Grade/Year Level",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "name",
      text: "Name",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "adviser_id",
      text: "Adviser",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
      formatter: (cell) => {
        return cell ? getOptionLabelByValue(teachersSelection, cell) : "-";
      },
    },
    {
      dataField: "enabled",
      text: "Status",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
      formatter: (cell) => {
        return cell ? "Active" : "InActive";
      },
    },
    {
      dataField: "created_at",
      text: "Date Created",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
    },
    {
      dataField: "id",
      text: "Action",
      sort: false,
      headerStyle: {
        backgroundColor: "#800000",
        color: "white",
        whiteSpace: "wrap",
      },
      formatter: (cell, row) => {
        return (
          <div
            className="px-2 justify-content-center d-flex"
            style={{ minWidth: "50px" }}
          >
            <Button
              id={"upload-btn" + cell}
              onClick={() => toggleAddOrEdit(row, "edit")}
              className="btn-warning mr-2"
            >
              <img src={Edit} style={{ height: "1.5rem", objectFit: "fill" }} />
            </Button>
            <UncontrolledTooltip placement="top" target={"upload-btn" + cell}>
              Edit
            </UncontrolledTooltip>
          </div>
        );
      },
    },
  ];

  const [addOrEditModal, setAddOrEditModal] = useState(false);
  const toggleAddOrEdit = (details, action) => {
    setSection(states.blankSingleSection);
    setAction("");
    if (!addOrEditModal) {
      setAction(action);
      if (action == "edit") {
        setSection(details);
      }
      setAddOrEditModal(!addOrEditModal);
    } else {
      setAddOrEditModal(!addOrEditModal);
    }
  };

  const NoDataIndication = () => <div>No Data</div>;

  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (data.length >= 1) {
      let newArr = [];
      //search INPUT
      let filteredSections = data.filter((d) => {
        return d.name.toLowerCase().includes(searchText.toLowerCase());
      });

      newArr = filteredSections;
      //filter
      if (gradeLevelFilter !== "") {
        let filteredGradeLevels = newArr.filter((f) => {
          return f.grade_level == gradeLevelFilter;
        });

        newArr = filteredGradeLevels;
      }

      setFilteredData(newArr);
    }
  }, [gradeLevelFilter, searchText, data]);

  return (
    <div className="p-4">
      {addOrEditModal ? (
        <AddEdit
          addOrEditModal={addOrEditModal}
          setAddOrEditModal={setAddOrEditModal}
          toggleAddOrEdit={toggleAddOrEdit}
          section={section}
          action={action}
          setUpdateTable={setUpdateTable}
          updateTable={updateTable}
          selectOptions={selectOptions}
          gradeLevelSelection={gradeLevelSelection}
          coursesSelection={coursesSelection}
          teachersSelection={teachersSelection}
        />
      ) : (
        ""
      )}
      <Row>
        <Col md="12" xs="12" className="mb-3">
          <Button
            className="btn bg-danger-edit"
            onClick={() => toggleAddOrEdit("", "add")}
          >
            <img src={Add} style={{ height: "1rem" }} />
          </Button>
        </Col>
        <Col md="4" xs="12">
          <Select
            options={gradeLevelSelection}
            onChange={(e) => {
              setGradeLevelFilter(e.label);
              return e;
            }}
            defaultValue=""
          />
          <p>
            <small className="font-weight-bolder">
              <i className="fa fa-lightbulb-o"></i>&nbsp;Filter by Grade Level
            </small>
          </p>
        </Col>
        <Col md="4" xs="12">
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <Button
                type="button"
                color="primary"
                id="search-button"
                style={{ zIndex: "0" }}
              >
                <i className="fa fa-search"></i>
              </Button>
            </InputGroupAddon>
            <Input
              type="text"
              id="search-text"
              name="search-text"
              placeholder="Search"
              onChange={(e) => setSearchText(e.target.value)}
            />
          </InputGroup>
          <p>
            <small className="font-weight-bolder">
              <i className="fa fa-lightbulb-o"></i>&nbsp;Search by Section Name
            </small>
          </p>
        </Col>
      </Row>

      <BootstrapTable
        wrapperClasses="table-responsive mb-3 text-center"
        bootstrap4
        striped
        keyField="id"
        data={filteredData}
        columns={columns}
        loading={loading}
        classes={isMobile ? "mobileTable" : "desktopTable"}
        pagination={paginationFactory()}
        overlay={overlayFactory({
          spinner: true,
          styles: {
            overlay: (base) => ({
              ...base,
              background: "rgba(0, 0, 0, 0.5)",
            }),
          },
        })}
        noDataIndication={() => <NoDataIndication />}
      />
    </div>
  );
};

export default Sections;
