import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { authActions } from "../../../services/AuthService";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const VerifyEmail = () => {
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("token");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      authActions.verifyEmail(id, setMessage);
    }
  }, [id]);
  return (
    <>
      {message ? (
        <div
          className="d-flex flex-column align-items-center justify-content-center"
          style={{ height: "100vh" }}
        >
          <h1 className="text-center text-danger-edit">{message}</h1>
          <Button
            className="btn bg-warning rounded-pill py-2 px-4 border-0"
            onClick={() => {
              navigate("./login");
            }}
          >
            Login
          </Button>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default VerifyEmail;
