import React, { useState } from "react";
import {
  Container,
  Collapse,
  Navbar,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Label,
  Card,
  Button,
  CardImg,
  CardText,
  CardDeck,
  CardBody,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  Modal,
  ModalBody,
  Spinner,
  Fade,
  ModalHeader,
  Input,
  ModalFooter,
  Table,
} from "reactstrap";
import {
  isBrowser,
  isDesktop,
  isIOS,
  isTablet,
  isMobile,
} from "react-device-detect";
import news1 from "../../../../assets/img/news-1.png";
import news2 from "../../../../assets/img/news-2.png";
import news3 from "../../../../assets/img/news-3.png";
import news4 from "../../../../assets/img/news-4.png";
import news5 from "../../../../assets/img/news-5.png";
import { useNavigate } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { notifyError } from "../../../../services/Toaster";

const HomepageNews = ({
  homepage,
  setHomepage,
  handleChangeModal,
  handleArrangeModal,
}) => {
  const navigate = useNavigate();
  const [addOrEditModal, setAddOrEditModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState("");
  const [updateNewsModal, setUpdateNewsModal] = useState(false);
  const blankNews = {
    image: "",
    imageFile: "",
    title: "",
    url_link: "",
  };

  const [tempNews, setTempNews] = useState(blankNews);
  const [action, setAction] = useState("");
  const [newsAll, setNewsAll] = useState(false);

  const handleUpdateNews = () => {
    if (!updateNewsModal) {
      setUpdateNewsModal(!updateNewsModal);
    } else {
      setUpdateNewsModal(!updateNewsModal);
    }
  };
  const handleSeeAllNews = () => {
    if (!newsAll) {
      setNewsAll(!newsAll);
    } else {
      setNewsAll(!newsAll);
    }
  };

  const toggleAddEdit = (act, index, news) => {
    setTempNews(blankNews);
    setAction("");
    setCurrentIndex("");
    if (!addOrEditModal) {
      setAction(act);
      if (act == "edit") {
        setCurrentIndex(index);
        setTempNews(news);
      }
      setAddOrEditModal(!addOrEditModal);
    } else {
      setAddOrEditModal(!addOrEditModal);
    }
  };

  const acceptedImageExtentions = ["jpg", "jpeg", "png"];
  const handleChangeImage = async (e, data) => {
    let extension = e[0].name.split(".")[1];
    if (acceptedImageExtentions.includes(extension)) {
      let imageFile = {
        filename: e[0].name,
        document: await convertBase64(e[0]),
      };
      setTempNews({
        ...tempNews,
        image: URL.createObjectURL(e[0]),
        imageFile: imageFile,
      });
    } else {
      notifyError("Invalid file type for image");
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleDeleteData = (i) => {
    let homepageNews = homepage.news;
    let newHomePageNews = homepageNews.filter((d, key) => {
      return i != key;
    });
    setHomepage({ ...homepage, news: newHomePageNews });
  };

  const handleSaveEditNews = () => {
    let homepageNews = homepage.news;
    if (action == "add") {
      homepageNews.push(tempNews);
      setHomepage({ ...homepage, news: homepageNews });
    }

    if (action == "edit") {
      let updateHomepageNews = homepageNews.map((news, i) => {
        if (i == currentIndex) {
          news = tempNews;
          return news;
        } else {
          return news;
        }
      });
      setHomepage({ ...homepage, news: updateHomepageNews });
    }
    setAddOrEditModal(false);
  };

  return (
    <>
      {/* <Slide left> */}
      <Row lg="12" md="12" xs="12" className="w-100 lastSection mx-0">
        <Col
          lg="3"
          md="3"
          xs="12"
          className="bg-danger-edit d-flex justify-content-center"
        >
          <Label
            className="font-weight-bolder p-4 text-warning-edit"
            style={{ fontSize: "1.3rem" }}
          >
            SAPC LATEST NEWS
          </Label>
        </Col>
      </Row>
      {/* </Slide> */}

      <Row
        lg="12"
        md="12"
        xs="12"
        className="lastSectionImage lastSectionImage2 w-100 mx-0"
      >
        <Container className="mt-5 pt-5 text-center">
          {homepage.news.length > 6 ? (
            !newsAll ? (
              <div
                className="text-right mb-3"
                style={{ color: "#800000", cursor: "pointer" }}
              >
                <h5
                  className="font-weight-bolder"
                  onClick={() => handleSeeAllNews()}
                >
                  See All News
                </h5>
              </div>
            ) : (
              <div
                className="text-right mb-3"
                style={{ color: "#800000", cursor: "pointer" }}
              >
                <h5
                  className="font-weight-bolder"
                  onClick={() => handleSeeAllNews()}
                >
                  See Less
                </h5>
              </div>
            )
          ) : (
            ""
          )}
          <CardDeck>
            {homepage.news.length >= 1
              ? homepage.news.map((news, index) => {
                  if (!newsAll) {
                    if (index <= 5) {
                      return (
                        <Col md="4" xs="12" className="mb-5" key={index}>
                          <Card
                            className={
                              isDesktop
                                ? "shadow zooming-transition border-0"
                                : "shadow border-0"
                            }
                          >
                            <CardImg
                              style={{ height: "13rem" }}
                              top
                              width="100%"
                              src={news.image}
                              alt="Card image cap"
                            />
                            <CardBody
                              style={{
                                height: "13rem",
                                backgroundColor: "#f0f0f0",
                              }}
                              className="text-center py-5 d-flex flex-column align-items-center"
                            >
                              <CardText className="mb-4 text-danger-edit">
                                <strong>{news.title}</strong>
                              </CardText>
                              <Button
                                className="mt-auto bg-danger-edit border border-0"
                                onClick={() =>
                                  navigate(window.open(news.url_link, "_blank"))
                                }
                              >
                                See More
                              </Button>
                            </CardBody>
                          </Card>
                        </Col>
                      );
                    }
                  } else {
                    return (
                      <Col md="4" xs="12" className="mb-5" key={index}>
                        <Card
                          className={
                            isDesktop
                              ? "shadow zooming-transition border-0"
                              : "shadow border-0"
                          }
                        >
                          <CardImg
                            style={{ height: "13rem" }}
                            top
                            width="100%"
                            src={news.image}
                            alt="Card image cap"
                          />
                          <CardBody
                            style={{
                              height: "13rem",
                              backgroundColor: "#f0f0f0",
                            }}
                            className="text-center py-5 d-flex flex-column align-items-center"
                          >
                            <CardText className="mb-4 text-danger-edit">
                              <strong>{news.title}</strong>
                            </CardText>
                            <Button
                              className="mt-auto bg-danger-edit border border-0"
                              onClick={() =>
                                navigate(window.open(news.url_link, "_blank"))
                              }
                            >
                              See More
                            </Button>
                          </CardBody>
                        </Card>
                      </Col>
                    );
                  }
                })
              : ""}
          </CardDeck>
          {homepage.news.length == 0 ? (
            <h3 className="text-danger-edit">No available SAPC NEWS</h3>
          ) : (
            ""
          )}
          <Button
            className="mb-5"
            color="success"
            onClick={() => handleUpdateNews()}
          >
            UPDATE NEWS
          </Button>
        </Container>
      </Row>

      <Modal isOpen={updateNewsModal} backdrop="static" size="xl">
        <ModalHeader>Update SAPC News</ModalHeader>
        <ModalBody>
          <div className="text-right mb-3">
            <Button
              onClick={() => toggleAddEdit("add", null, null)}
              color="primary"
              disabled={homepage.news.length <= 8 ? false : true}
            >
              <AiIcons.AiOutlinePlus
                style={{ fontSize: "1rem", fontWeight: "bold" }}
              />
            </Button>
          </div>
          <Table bordered striped className="text-center">
            <thead>
              <tr>
                <th>#</th>
                <th>Image</th>
                <th>Title</th>
                <th>Url Link</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {homepage.news.length >= 1 ? (
                homepage.news.map((news, index) => {
                  return (
                    <tr key={index}>
                      <td style={{ verticalAlign: "middle" }}>{index + 1}</td>
                      <td style={{ verticalAlign: "middle" }}>
                        <img
                          src={news.image}
                          style={{
                            height: "120px",
                            width: "200px",
                            objectFit: "center",
                          }}
                        />
                      </td>
                      <td style={{ verticalAlign: "middle" }}>{news.title}</td>
                      <td style={{ verticalAlign: "middle" }}>
                        {news.url_link}
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        <div className="d-flex align-items-center text-center justify-content-center">
                          <Button
                            color="warning"
                            className="text-center text-white mr-2"
                            onClick={() => toggleAddEdit("edit", index, news)}
                          >
                            {<FaIcons.FaEdit style={{ fontSize: "1rem" }} />}
                          </Button>
                          <Button
                            color="danger"
                            onClick={() => handleDeleteData(index)}
                          >
                            X
                          </Button>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="5">No Available SAPC News</td>
                </tr>
              )}
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => setUpdateNewsModal(!updateNewsModal)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={addOrEditModal} backdrop="static">
        <ModalHeader>{action == "add" ? "Add" : "Update"}</ModalHeader>
        <ModalBody>
          <div className="mb-4">
            <img
              src={tempNews.image}
              style={{ height: "100px", width: "200px" }}
            />
            <Input
              type="file"
              onChange={(e) => handleChangeImage(e.target.files)}
            />
          </div>
          <div className="mb-4">
            <Label>Title</Label>
            <Input
              type="text"
              defaultValue={tempNews.title}
              onChange={(e) =>
                setTempNews({ ...tempNews, title: e.target.value })
              }
            />
          </div>
          <div className="mb-4">
            <Label>Url Link</Label>
            <Input
              type="text"
              defaultValue={tempNews.url_link}
              onChange={(e) =>
                setTempNews({ ...tempNews, url_link: e.target.value })
              }
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="success"
            className="mr-2"
            onClick={() => handleSaveEditNews()}
          >
            Save
          </Button>
          <Button onClick={() => setAddOrEditModal(!addOrEditModal)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default HomepageNews;
