import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Label, Input, FormGroup, Form, Spinner } from 'reactstrap';
import { useForm, Controller } from "react-hook-form";
import { adminActions } from '../../../services/EducationTypeServices';
import Select from "react-select";

const EducationTypeModal = ({
    modal,
    setModal,
    handleEditEducationType,
    editEducationType,
    updateTable,
    setUpdateTable,
    action,
    selectOptions,

}) => {

    const getOptionLabelByValue = (options, value) => {
        const option = options.find((option) => option.value == value);
        return option ? option.label : "";
    };

    const { register, handleSubmit, errors, control, setValue, getValues } = useForm();
    const [buttonSpinner, setButtonSpinner] = useState(false);

    const submitForm = (data) => {
        setButtonSpinner(true)
        data.enabled = data.enabled ? data.enabled.value : 0;
        if (action == "add") {
            data.education_name = data.name;
            data.enabled = 1;
            // console.log(data)
            adminActions.addEducationType(data,
                setButtonSpinner,
                setModal,
                setUpdateTable,
                updateTable)
                
        } else if (action == "edit") {
            data.education_type_id = editEducationType.id;
            data.education_name = data.name;
            data.enabled = 1;

            adminActions.updateEducationType(data,
                setButtonSpinner,
                setModal,
                setUpdateTable,
                updateTable)
        }

    }

    return (
        <Modal isOpen={modal} toggle={handleEditEducationType} backdrop="static" size="lg">
            <Form onSubmit={handleSubmit(submitForm)}>
                <ModalHeader toggle={handleEditEducationType}>{action == 'add' ? "Add" : "Update"} Education Type</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col md="12" xs="12">
                            <FormGroup>
                            <Label htmlFor="name" className="text-secondary w-100 font-weight-bolder">Education Type:</Label>
                                <Controller
                                    control={control}
                                    name="name"
                                    id="name"
                                    defaultValue={editEducationType.name}
                                    rules={{ required: true }}
                                    invalid={errors.name ? true : false}
                                    render={({ onChange, value, onBlur, name }) => (
                                        <Input
                                            name="name"
                                            defaultValue={editEducationType.name}
                                            onChange={(e) => {
                                                setValue('name', e.target.value)
                                                // console.log(e.target.value);
                                            }}
                                        />
                                    )}
                                />
                                {errors.name && errors.name.type == "required" ?
                                    <small className="text-danger">
                                        Education type is required!
                                    </small>
                                    :
                                    ""
                                }
                            </FormGroup>
                        </Col>
                        {/* <Col md="6" xs="12">
                            <FormGroup>
                                <Label
                                    htmlFor="enabled"
                                    className="text-secondary w-100 font-weight-bolder"
                                >
                                    Status
                                </Label>
                                <Controller
                                    control={control}
                                    name="enabled"
                                    defaultValue={
                                        editEducationType.enabled !== ""
                                        ? {
                                            value: editEducationType.enabled,
                                            label: getOptionLabelByValue(
                                            selectOptions.status,
                                            editEducationType.enabled
                                            ),
                                        }
                                        : ""
                                        }
                                    rules={{ required: true }}
                                    invalid={errors.enabled ? true : false}
                                    render={({ onChange, value, onBlur, name }) => (
                                    <Select
                                        options={selectOptions.status}
                                        name="enabled"
                                        onChange={(e) => {
                                        setValue("enabled", e);
                                        return e;
                                        }}
                                        defaultValue={
                                            editEducationType.enabled !== ""
                                        ? {
                                            value: editEducationType.enabled,
                                            label: getOptionLabelByValue(
                                                selectOptions.status,
                                                editEducationType.enabled
                                            ),
                                            }
                                        : ""
                                        }
                                    />
                                    )}
                                />
                                {errors.enabled &&
                                    errors.enabled.type == "required" && (
                                    <small className="text-danger">
                                        Status is required!
                                    </small>
                                    )}
                                </FormGroup>
                        </Col> */}
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button className="bg-warning-edit border-0" disabled={buttonSpinner}>
                        {buttonSpinner ? (
                            <>
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            </>
                        ) : (
                            "Submit"
                        )}
                    </Button>
                    <Button color="secondary" onClick={handleEditEducationType} disabled={buttonSpinner}>Cancel</Button>
                </ModalFooter>
            </Form>
        </Modal>
    )
}

export default EducationTypeModal;