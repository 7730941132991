import React, { useEffect, useState } from 'react';
import { Button, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, FormGroup, Form, Spinner, Input } from 'reactstrap';
import "@kenshooui/react-multi-select/dist/style.css";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { sectionActions } from "../../../../services/SectionServices";

const AddEdit = ({
  addOrEditModal,
  setAddOrEditModal,
  toggleAddOrEdit,
  section,
  action,
  setUpdateTable,
  updateTable,
  selectOptions,
  gradeLevelSelection,
  coursesSelection,
  teachersSelection
}) => {
  const { handleSubmit, errors, control, setValue, getValues, watch } = useForm();
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [selectedGradeType, setSelectedGradeType] = useState("")

  const getOptionLabelByValue = (options, value) => {
    const option = options.find((option) => option.value == value);
    return option ? option.label : "";
  };

  const onSubmit = (data) => {
    setButtonSpinner(true);
    let dataToSubmit = {
      section_name: data.name,
      grade_level_id: data.grade_level_id ? data.grade_level_id.value : "",
      enabled: data.enabled ? data.enabled.value : "",
      course_type_id: data.course_type_id ? data.course_type_id.value : "",
      adviser_id: data.adviser_id ? data.adviser_id.value : null,
    }
    if (action == "add") {
      sectionActions.addSection(dataToSubmit, setButtonSpinner, setUpdateTable, updateTable, setAddOrEditModal)
    } else if ("edit") {
      dataToSubmit.section_id = section.id;
      sectionActions.updateSection(dataToSubmit, setButtonSpinner, setUpdateTable, updateTable, setAddOrEditModal)
    }
  }

  const withCourse = [4, 5];
  const [newCourseSelection, setNewCourseSelection] = useState([])
  useEffect(()=>{
    if(withCourse.includes(selectedGradeType)){
      let newCS = coursesSelection.filter(c=>{
        return c.grade_type == selectedGradeType
      })
      setNewCourseSelection(newCS)
    }

    if(withCourse.includes(section.grade_type_id)){
      let newCS = coursesSelection.filter(c=>{
        return c.grade_type == section.grade_type_id
      })
      setNewCourseSelection(newCS)
    }
  }, [selectedGradeType, section.grade_type_id])

  return (
    <Modal isOpen={addOrEditModal} toggle={toggleAddOrEdit} size="lg" backdrop="static">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader toggle={toggleAddOrEdit} className="bg-danger-edit text-white" charCode="">{action == "add" ? "Add Section" : "Edit Section"}</ModalHeader>
        <ModalBody>
          <Row>
            <Col md="12" xs="12">
              <Label className="text-secondary font-weight-bolder">
                Section Name
              </Label>
              <FormGroup>
                <Controller
                  control={control}
                  name="name"
                  rules={{ required: true }}
                  invalid={errors.name ? true : false}
                  defaultValue={section.name}
                  render={({ onChange, value, onBlur, name }) => (
                    <Input
                      className="form-control bg-light"
                      name="name"
                      type="text"
                      onChange={(event) => {
                        setValue("name", event.target.value);
                        return event.target.value;
                      }}
                      defaultValue={section.name}
                    />
                  )}
                />
                {errors.name && errors.name.type == "required" && (
                  <small className="text-danger">
                    Section Name is required!
                  </small>
                )}
              </FormGroup>
            </Col>
            <Col md="6" xs="12">
              <FormGroup>
                <Label
                  htmlFor="grade_level_id"
                  className="text-secondary w-100 font-weight-bolder"
                >
                  Select Grade/Year Level
                </Label>
                <Controller
                  control={control}
                  name="grade_level_id"
                  defaultValue={
                    section.grade_level_id != ""
                      ? {
                        value: section.grade_level_id,
                        label: getOptionLabelByValue(
                          gradeLevelSelection,
                          section.grade_level_id
                        ),
                      }
                      : ""
                  }
                  rules={{ required: true }}
                  invalid={errors.grade_level_id ? true : false}
                  render={({ onChange, value, onBlur, name }) => (
                    <Select
                      options={gradeLevelSelection}
                      name="grade_level_id"
                      onChange={(e) => {
                        setSelectedGradeType(e.grade_type_id)
                        setValue("grade_level_id", e);
                        return e;
                      }}
                      defaultValue={
                        section.grade_level_id != ""
                          ? {
                            value: section.grade_level_id,
                            label: getOptionLabelByValue(
                              gradeLevelSelection,
                              section.grade_level_id
                            ),
                          }
                          : ""
                      }
                    />
                  )}
                />
                {errors.grade_level_id &&
                  errors.grade_level_id.type == "required" && (
                    <small className="text-danger">
                      Grade/Year Level is required!
                    </small>
                  )}
              </FormGroup>
            </Col>
            {(watch('grade_level_id') && withCourse.includes(watch('grade_level_id').grade_type_id)) || withCourse.includes(section.grade_type_id) ?
              <Col md="6" xs="12">
                <FormGroup>
                  <Label
                    htmlFor="course_type_id"
                    className="text-secondary w-100 font-weight-bolder"
                  >
                    Select Course
                  </Label>
                  <Controller
                    control={control}
                    name="course_type_id"
                    defaultValue={
                      section.course_type_id != ""
                        ? {
                          value: section.course_type_id,
                          label: getOptionLabelByValue(
                            newCourseSelection,
                            section.course_type_id
                          ),
                        }
                        : ""
                    }
                    rules={{ required: true }}
                    invalid={errors.course_type_id ? true : false}
                    render={({ onChange, value, onBlur, name }) => (
                      <Select
                        options={newCourseSelection}
                        name="course_type_id"
                        onChange={(e) => {
                          setValue("course_type_id", e);
                          return e;
                        }}
                        defaultValue={
                          section.course_type_id != ""
                            ? {
                              value: section.course_type_id,
                              label: getOptionLabelByValue(
                                newCourseSelection,
                                section.course_type_id
                              ),
                            }
                            : ""
                        }
                      />
                    )}
                  />
                  {errors.course_type_id &&
                    errors.course_type_id.type == "required" && (
                      <small className="text-danger">
                        Course is required!
                      </small>
                    )}
                </FormGroup>
              </Col>
              : ""}
            <Col md="6" xs="12">
              <FormGroup>
                <Label
                  htmlFor="enabled"
                  className="text-secondary w-100 font-weight-bolder"
                >
                  Status
                </Label>
                <Controller
                  control={control}
                  name="enabled"
                  defaultValue={
                    section.enabled !== ""
                      ? {
                        value: section.enabled,
                        label: getOptionLabelByValue(
                          selectOptions.status,
                          section.enabled
                        ),
                      }
                      : ""
                  }
                  rules={{ required: true }}
                  invalid={errors.enabled ? true : false}
                  render={({ onChange, value, onBlur, name }) => (
                    <Select
                      options={selectOptions.status}
                      name="enabled"
                      onChange={(e) => {
                        setValue("enabled", e);
                        return e;
                      }}
                      defaultValue={
                        section.enabled !== ""
                          ? {
                            value: section.enabled,
                            label: getOptionLabelByValue(
                              selectOptions.status,
                              section.enabled
                            ),
                          }
                          : ""
                      }
                    />
                  )}
                />
                {errors.enabled &&
                  errors.enabled.type == "required" && (
                    <small className="text-danger">
                      Status is required!
                    </small>
                  )}
              </FormGroup>
            </Col>
            <Col md="6" xs="12">
              <FormGroup>
                <Label
                  htmlFor="adviser_id"
                  className="text-secondary font-weight-bolder"
                >
                  Section Adviser
                </Label>
                <Controller
                  control={control}
                  name="adviser_id"
                  rules={{ required: false }}
                  invalid={errors.adviser_id ? true : false}
                  defaultValue={
                    section.adviser_id != ""
                      ? {
                        value: section.adviser_id,
                        label: getOptionLabelByValue(
                          teachersSelection,
                          section.adviser_id
                        ),
                      }
                      : ""
                  }
                  render={({ onChange, value, onBlur, name }) => (
                    <Select
                      options={teachersSelection}
                      onChange={(e) => {
                        setValue("adviser_id", e);

                        return e;
                      }}
                      defaultValue={
                        section.adviser_id != ""
                          ? {
                            value: section.adviser_id,
                            label: getOptionLabelByValue(
                              teachersSelection,
                              section.adviser_id
                            ),
                          }
                          : ""
                      }
                    />
                  )}
                />
                {errors.adviser_id && (
                  <div
                    style={{
                      marginTop: "0.25rem",
                      fontSize: "80%",
                      color: "#f86c6b",
                    }}
                  >
                    Adviser is required!
                  </div>
                )}
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button className="bg-warning-edit border-0" disabled={buttonSpinner}>
            {buttonSpinner ? (
              <>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </>
            ) : (
              "Save"
            )}
          </Button>
          <Button color="secondary" disabled={buttonSpinner} onClick={toggleAddOrEdit}>Close</Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}
export default AddEdit;