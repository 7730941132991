import { ENDPOINT } from "../constants/api";
import axios from "axios";
import { notifyError, notifySuccess } from "./Toaster";
import moment from "moment";

export const sectionActions = {
  getSections,
  addSection,
  updateSection
};

const token = localStorage.getItem("jwtToken")
  ? localStorage.getItem("jwtToken")
  : "";
const headers = {
  Authorization: `Bearer ${token}`,
  "Content-Type": "application/json",
  "X-Requested-With": "XMLHttpRequest",
};

async function getSections(setData, setLoading, setTeachersSelection, setCoordinatorsSelection) {
  try {
    let res = await axios.get(ENDPOINT + `/get-section`, {
      headers: headers,
    });
    const sectionLists = res.data.data.section_list;
    const teacherLists = res.data.data.teacher_list;
    const coordinatorList = res.data.data.coordinator_list;
    teacherLists.push({ value: null, label: "-Select-" })
    coordinatorList.push({ value: null, label: "-Select-" })
    const newSectionList = sectionLists.map(section=>{
        return {
          id: section.id,
          grade_level_id: section.grade_level_id ? section.grade_level_id[0].id : "-",
          grade_level: section.grade_level_id ? section.grade_level_id[0].name : "-",
          name: section.name,
          enabled: section.enabled,
          course_type_id: section.course_type_id ?? "",
          adviser_id: section.adviser_id ?? "",
          coordinator_id: section.coordinator_id ?? "",
          grade_type_id: section.grade_level_id ? section.grade_level_id[0].grade_type_id.id : "-",
          created_at: section.created_at,
        }
    })
    setData(newSectionList);
    setTeachersSelection(teacherLists)
    setCoordinatorsSelection(coordinatorList)
    setLoading(false);
  } catch (err) {
    if (err.response) {
      notifyError("Error while fetching Data");
    }
  }
}

async function addSection(dataToSubmit, setButtonSpinner, setUpdateTable, updateTable, setAddOrEditModal) {
  try {
    let res = await axios.post(ENDPOINT + `/add-section`, dataToSubmit, {
      headers: headers,
    });
    
    if(res.data.success){
        notifySuccess(res.data.message);
        setUpdateTable(!updateTable);
        setAddOrEditModal(false);
    }else{
      notifyError("Error while adding section.");
    }
    setButtonSpinner(false)
  } catch (err) {
    if (err.response && err.response.data && err.response.data.message && err.response.data.message.adviser_id) {
      notifyError("The adviser has already assigned to other section")
    } else if (err.response && err.response.data && err.response.data.message && err.response.data.message.coordinator_id) {
      notifyError("The coordinator has already assigned to other section")
    }else {
      notifyError("Error while adding section.");
    }
    setButtonSpinner(false)
  }
}

async function updateSection(dataToSubmit, setButtonSpinner, setUpdateTable, updateTable, setAddOrEditModal) {
    try {
      let res = await axios.post(ENDPOINT + `/update-section`, dataToSubmit, {
        headers: headers,
      });
      if(res.data.success){
          notifySuccess(res.data.message);
          setUpdateTable(!updateTable);
          setAddOrEditModal(false);
      }else{
        notifyError("Error while updating section.");
      }
      setButtonSpinner(false)
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message && err.response.data.message.adviser_id) {
        notifyError("The adviser has already assigned to other section")
      } else if (err.response && err.response.data && err.response.data.message && err.response.data.message.coordinator_id) {
        notifyError("The coordinator has already assigned to other section")
      }else{
        notifyError("Error while updating section.");
      }
      setButtonSpinner(false)
    }
  }

  