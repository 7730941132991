import React, { Fragment, useEffect, useState } from 'react';
import {
    Container,
    Form,
    Row,
    Col,
    FormGroup,
    Label,
    Input,
    Button,
    CardBody,
    CardHeader,
    Card,
    Table
} from 'reactstrap';
import {
    isMobile,
} from "react-device-detect";
import { useForm, Controller } from "react-hook-form";
import '../../../App.css';
import Select from 'react-select';
import { educationActions } from "../../../services/EducationSelectionService.js";

const TertiaryAssessmentForm = ({ handleCancel, currentStep, setCurrentStep, paymentForm, setPaymentForm, newTuitionFee, setSchoolFees, selectOptions, schoolFees, basicFormEsc }) => {

    const admissionAnswers = localStorage.getItem('admissionAnswers') ? JSON.parse(localStorage.getItem('admissionAnswers')) : ""
    const { register, handleSubmit, errors, control, setValue, getValues } = useForm();
    const [totalFees, setTotalFees] = useState(0);

    const handlePreviousBtn = () => {
        setPaymentForm({ ...paymentForm, new_tuition_fee: newTuitionFee })
        setPaymentForm({ ...paymentForm, total_fees: totalFees })
        setCurrentStep(currentStep - 1)
    }

    const onSubmit = (data) => {
        setPaymentForm({ ...paymentForm, new_tuition_fee: newTuitionFee })
        setPaymentForm({ ...paymentForm, total_fees: totalFees })
        setCurrentStep(currentStep + 1);
    }

    // useEffect(() => {
    //     educationActions.getApplications(null, null, null, null, setSchoolFees, null);
    // }, []);

    const handleSelectPaymentMode = (e) => {
        // let total = 0;
        // if (e == 'annual_fee') {
        //     total = parseFloat(paymentForm.discounted_tuituion_fee);
        // } else {
        //     total = parseFloat(paymentForm.tuition_fee);
        // }
        // setNewTuitionFee(total)
    }

    useEffect(() => {
        if (paymentForm.paymentMode != "") {
            if (paymentForm.paymentMode && paymentForm.paymentMode.label == "Full Payment") {
                setPaymentForm({ ...paymentForm, paymentModeDetails: paymentForm.payment_mode.annual })
            } else if (paymentForm.paymentMode && paymentForm.paymentMode.label == "Monthly A") {
                setPaymentForm({ ...paymentForm, paymentModeDetails: paymentForm.payment_mode.monthly_a })
            } else if (paymentForm.paymentMode && paymentForm.paymentMode.label == "Monthly B") {
                setPaymentForm({ ...paymentForm, paymentModeDetails: paymentForm.payment_mode.monthly_b })
            }
        }
    }, [paymentForm.paymentMode])

    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Container className="my-5">
                    <Row className="border border-2 border-warning py-2 mb-5 px-3" style={{ overflowX: isMobile ? "auto" : "" }}>
                        <div className="w-100 px-2" style={{ marginTop: isMobile ? "0" : "-1.4rem" }}>
                            <Label className="text-danger-edit text-center font-weight-bolder mb-1 px-3" style={{ backgroundColor: "white" }}>ASSESSMENT</Label>
                        </div>
                        <Col md="12" xs="12" className="my-4">
                            <Table bordered>
                                <thead className="vertical-align-middle">
                                    <tr className="text-center">
                                        <th rowSpan="2">Courses</th>
                                        <th rowSpan="2">Items</th>
                                        <th rowSpan="2">Number of Units</th>
                                        <th rowSpan="2">Fees</th>
                                        <th rowSpan="2">Details</th>
                                        <th rowSpan="1" colSpan="4" style={{backgroundColor: "lightGray"}}>Payment Mode</th>
                                    </tr>
                                    <tr className="text-center">
                                        <th rowSpan="1" style={{backgroundColor: "lightGray"}}>Full Payment</th>
                                        <th rowSpan="1" style={{backgroundColor: "lightGray"}}>Monthly A</th>
                                        <th rowSpan="1" style={{backgroundColor: "lightGray"}}>Monthly B</th>
                                    </tr>
                                </thead>
                                <tbody className="text-center vertical-align-middle">
                                    <tr>
                                        <td rowSpan="20">{admissionAnswers.tertiary_course ? admissionAnswers.tertiary_course.label : ""}</td>
                                        <td>Tuition Fee ({paymentForm.base_tuition}/unit)</td>
                                        <td>{paymentForm.no_of_units}</td>
                                        <td>{paymentForm.tuition_fee > 999 ? paymentForm.tuition_fee.toLocaleString() : paymentForm.tuition_fee}</td>
                                        <td className={paymentForm.details ? "bg-danger-edit text-white" : ""}>{paymentForm.details ? paymentForm.details[0] : ""}</td>
                                        <td>{paymentForm.tuition_fee > 999 ? paymentForm.tuition_fee.toLocaleString() : paymentForm.tuition_fee}</td>
                                        <td>{paymentForm.payment_mode.monthly_a.tuition_fee > 999 ? paymentForm.payment_mode.monthly_a.tuition_fee.toLocaleString() : paymentForm.payment_mode.monthly_a.tuition_fee}</td>
                                        <td>{paymentForm.payment_mode.monthly_b.tuition_fee > 999 ? paymentForm.payment_mode.monthly_b.tuition_fee.toLocaleString() : paymentForm.payment_mode.monthly_b.tuition_fee}</td>
                                    </tr>
                                    <tr>
                                        <td>Miscellaneous Fee</td>
                                        <td></td>
                                        <td>{paymentForm.miscellaneous_fee > 999 ? paymentForm.miscellaneous_fee.toLocaleString() : paymentForm.miscellaneous_fee}</td>
                                        <td></td>
                                        <td>{paymentForm.payment_mode.annual.miscellaneous_fee > 999 ? paymentForm.payment_mode.annual.miscellaneous_fee.toLocaleString() : paymentForm.payment_mode.annual.miscellaneous_fee}</td>
                                        <td>{paymentForm.payment_mode.monthly_a.miscellaneous_fee > 999 ? paymentForm.payment_mode.monthly_a.miscellaneous_fee.toLocaleString() : paymentForm.payment_mode.monthly_a.miscellaneous_fee}</td>
                                        <td>{paymentForm.payment_mode.monthly_b.miscellaneous_fee > 999 ? paymentForm.payment_mode.monthly_b.miscellaneous_fee.toLocaleString() : paymentForm.payment_mode.monthly_b.miscellaneous_fee}</td>
                                    </tr>
                                    <tr>
                                        <td>Other fees</td>
                                        <td></td>
                                        <td>{paymentForm.other_fee > 999 ? paymentForm.other_fee.toLocaleString() : paymentForm.other_fee}</td>
                                        <td></td>
                                        <td>{paymentForm.payment_mode.annual.other_fee > 999 ? paymentForm.payment_mode.annual.other_fee.toLocaleString() : paymentForm.payment_mode.annual.other_fee}</td>
                                        <td>{paymentForm.payment_mode.monthly_a.other_fee > 999 ? paymentForm.payment_mode.monthly_a.other_fee.toLocaleString() : paymentForm.payment_mode.monthly_a.other_fee}</td>
                                        <td>{paymentForm.payment_mode.monthly_b.other_fee > 999 ? paymentForm.payment_mode.monthly_b.other_fee.toLocaleString() : paymentForm.payment_mode.monthly_b.other_fee}</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td><strong>{paymentForm.total_tuition > 999 ? paymentForm.total_tuition.toLocaleString() : paymentForm.total_tuition}</strong></td>
                                        <td><strong>{paymentForm.subjects_with_lab_units && paymentForm.subjects_with_lab_units.length >= 1 ? "Payment upon enrolment w/o lab to be paid in full upon enrollment" : "Payment upon enrolment"}</strong></td>
                                        <td><strong>{paymentForm.total_tuition > 999 ? paymentForm.payment_mode.annual.payment_upon_enrollment.toLocaleString() : paymentForm.payment_mode.annual.payment_upon_enrollment}</strong></td>
                                        <td><strong>{paymentForm.payment_mode.monthly_a.payment_upon_enrollment > 999 ? paymentForm.payment_mode.monthly_a.payment_upon_enrollment.toLocaleString() : paymentForm.payment_mode.monthly_a.payment_upon_enrollment}</strong></td>
                                        <td><strong>{paymentForm.payment_mode.monthly_b.payment_upon_enrollment > 999 ? paymentForm.payment_mode.monthly_b.payment_upon_enrollment.toLocaleString() : paymentForm.payment_mode.monthly_b.payment_upon_enrollment}</strong></td>
                                    </tr>
                                    {paymentForm.subjects_with_lab_units && paymentForm.subjects_with_lab_units.length >= 1 ?
                                        <>
                                            <tr>
                                                <td>{admissionAnswers.tertiary_course && admissionAnswers.tertiary_course.label == "BSHM" ? "Culinary Lab Fee" : ""}</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            {paymentForm.subjects_with_lab_units.map((lab, key) => {
                                                return (
                                                    <tr key={key}>
                                                        <td>{lab.description}</td>
                                                        <td></td>
                                                        <td>{lab.lab_unit_price > 999 ? lab.lab_unit_price.toLocaleString() : lab.lab_unit_price}</td>
                                                        <td></td>
                                                        <td>{lab.lab_unit_price > 999 ? lab.lab_unit_price.toLocaleString() : lab.lab_unit_price}</td>
                                                        <td>{lab.lab_unit_price > 999 ? lab.lab_unit_price.toLocaleString() : lab.lab_unit_price}</td>
                                                        <td>{lab.lab_unit_price > 999 ? lab.lab_unit_price.toLocaleString() : lab.lab_unit_price}</td>
                                                    </tr>
                                                )
                                            })}
                                        </>
                                        :
                                        <tr>
                                        </tr>
                                    }
                                    {paymentForm.subjects_with_lab_units && paymentForm.subjects_with_lab_units.length >= 1 ?
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td><strong>Payment upon enrolment</strong></td>
                                            <td><strong>{paymentForm.payment_mode.annual.payment_upon_enrollment_lab > 999 ? paymentForm.payment_mode.annual.payment_upon_enrollment_lab.toLocaleString() : paymentForm.payment_mode.annual.payment_upon_enrollment_lab}</strong></td>
                                            <td><strong>{paymentForm.payment_mode.monthly_a.payment_upon_enrollment_lab > 999 ? paymentForm.payment_mode.monthly_a.payment_upon_enrollment_lab.toLocaleString() : paymentForm.payment_mode.monthly_a.payment_upon_enrollment_lab}</strong></td>
                                            <td><strong>{paymentForm.payment_mode.monthly_b.payment_upon_enrollment_lab > 999 ? paymentForm.payment_mode.monthly_b.payment_upon_enrollment_lab.toLocaleString() : paymentForm.payment_mode.monthly_b.payment_upon_enrollment_lab}</strong></td>
                                        </tr>
                                        : <tr></tr>}
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td><strong>Discount @3% TF (applicable for full payment only)</strong></td>
                                        <td><strong>{paymentForm.discounted_tuituion_fee > 999 ? paymentForm.discounted_tuituion_fee.toLocaleString() : paymentForm.discounted_tuituion_fee}</strong></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td><strong>Payment upon billing period</strong></td>
                                        <td></td>
                                        <td className="bg-warning-edit"><strong>{paymentForm.payment_mode.monthly_a.per_billing > 999 ? paymentForm.payment_mode.monthly_a.per_billing.toLocaleString() : paymentForm.payment_mode.monthly_a.per_billing}</strong></td>
                                        <td className="bg-warning-edit"><strong>{paymentForm.payment_mode.monthly_b.per_billing > 999 ? paymentForm.payment_mode.monthly_b.per_billing.toLocaleString() : paymentForm.payment_mode.monthly_b.per_billing}</strong></td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>

                    </Row>
                    <Row className="border border-2 border-warning py-2 mb-5 px-3" style={{ overflowX: isMobile ? "auto" : "" }}>
                        <div className="w-100 px-2" style={{ marginTop: isMobile ? "0" : "-1.4rem" }}>
                            <Label className="text-danger-edit text-center font-weight-bolder mb-1 px-3" style={{ backgroundColor: "white" }}>SELECT PAYMENT MODE</Label>
                        </div>
                        <Col md="12" xs="12" className="my-4">
                            <FormGroup>
                                <Controller
                                    control={control}
                                    name="payment_mode"
                                    defaultValue={paymentForm.paymentMode}
                                    rules={{ required: true }}
                                    invalid={errors.payment_mode ? true : false}
                                    render={({ onChange, value, onBlur, name }) => (
                                        <Select
                                            options={selectOptions.payment_mode_tertiary}
                                            onChange={
                                                e => {
                                                    setPaymentForm({ ...paymentForm, paymentMode: e })
                                                    setValue('payment_mode', e)
                                                    return e
                                                }

                                            }
                                            defaultValue={paymentForm.paymentMode}
                                            name="payment_mode"
                                        />
                                    )}
                                />
                                {errors.payment_mode && errors.payment_mode.type == "required" && (
                                    <small className="text-danger-edit">
                                        Please Select!
                                    </small>
                                )}
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row className="mt-5 justify-content-center text-center">
                        <Col md="6" xs="12" className="text-center w-100">
                            <Button type="button" onClick={handlePreviousBtn} className="mb-2 bg-white border-warning text-warning-edit rounded-pill py-3 px-5 font-weight-bolder">Previous</Button>
                            <Button onClick={handleCancel} className="bg-white border-0 text-dark rounded-pill py-3 px-5 mb-2 font-weight-bolder">Cancel</Button>
                        </Col>

                        <Col md="6" xs="12" className="text-center w-100">
                            <Button className="bg-warning text-white rounded-pill border-0 py-3 px-5 mb-2 font-weight-bolder">Next</Button>
                        </Col>
                    </Row>
                </Container>
            </Form>

        </>
    )
}

export default TertiaryAssessmentForm;